import React, { useState } from "react";
import Styles from "./AdminLogin.module.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  Stack,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";

// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { auth } from '../../config/firebaseInitialize';
import axios from "axios";
import { useAuth } from "contexts/auth-context";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const AdminLogin = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();

  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const url = "https://smudev.smuapp.org/dev/api";
  const { setUser } = useAuth();
  const [passwordType, setPasswordType] = useState("password");

  const token = localStorage.getItem("token");
  // const deviceInfo = {
  //   userAgent: navigator.userAgent,
  //   platform: navigator.platform,
  //   language: navigator.language,
  // };

  // console.log("Device Information:", deviceInfo);

  React.useEffect(() => {
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [token]);
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (userName.length === 0) {
      setError({
        state: true,
        message: "Please enter your email",
      });
      return;
    } else if (password.length === 0) {
      setError({
        state: true,
        message: "Please enter your password",
      });
      return;
    }
    signIn(userName, password);
  };

  const signIn = async (email, password) => {
    //authenticating user with firebase
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
        { returnSecureToken: true,}
      );
      const user = userCredential.user.accessToken;
      // console.log("userCredential", user);

      //if user exist in firebase calling api

      try {
        const { data } = await axios.post(url + "/v1/auth/login/new", {
          // email: userName,
          role: "admin",
          // password: password,
        },{headers:{"Authorization":`Bearer ${user}`}});

        const loggedInOn = new Date();
        // console.log(loggedInOn);
        localStorage.setItem("token", data.token);
        localStorage.setItem("admin_name", data.payload.name);
        localStorage.setItem("role", "admin");
        localStorage.setItem("loggedInOn", loggedInOn);
        localStorage.setItem("Permission", data.permission);
        setUser(true);
        toast({
          title: "Login Sucessful.",
          description: "Logged in as admin.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/admin/dashboard", {
          state: {
            data,
          },
        });
      } catch (error) {
        toast({
          title: "Login Failed.",
          description: "User doesn't exist.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        setError("Invalid username/password");
        console.error(error);
      }
    } catch (error) {
      console.log("Failed");
      toast({
        title: "Login Failed.",
        description: "User doesn't exist.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.wrapper}>
        <div className={Styles.cover}></div>
        <div className={Styles.inputDiv}>
          <form>
            <p className={Styles.heading}>ADMIN LOGIN</p>
            <div className={Styles.inputContainer}>
              <Stack
                spacing={3}
                mb="3"
                mt="3"
                className={Styles.inputDivContainer}
              >
                <InputGroup className={Styles.InputDiv}>
                  <Input
                    pr="4.5rem"
                    value={userName}
                    type="text"
                    placeholder="Enter email address..."
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className={Styles.InputDiv}>
                  <Input
                    pr="4.5rem"
                    type={passwordType === "password" ? "password" : "text"}
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={togglePassword}>
                      {/* {passwordType === "password" ? <p>👁️</p> : <p>🙈</p>} */}
                      {passwordType === "password" ? (
                        <BsFillEyeFill style={{ fontSize: "20px" }} />
                      ) : (
                        <BsFillEyeSlashFill style={{ fontSize: "20px" }} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {error.state === true ? (
                  <p className={Styles.error} style={{ textAlign: "center" }}>
                    {error.message}
                  </p>
                ) : null}
              </Stack>
              <Button
                onClick={submitHandler}
                size="lg"
                width="40%"
                _hover={{ background: "#990f0d", color: "#fff" }}
              >
                LOGIN
              </Button>
              <NavLink to="/forgotPassword" className={Styles.forgetPassword}>
                Forgot password?
              </NavLink>
            </div>
            <NavLink className={Styles.changeType} to="/chooseLogin">
              Change UserType
            </NavLink>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;

import axios from "axios";
import moment from "moment";
import { getRequestOptions, BASE_URL } from "services/utils";

async function requestCircularById(payload = {}) {
  const { circular_id } = payload;

  if (!circular_id || circular_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/management/circular/getOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestSearchCircular(payload = {}) {
  const url = BASE_URL + "/management/circular/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

async function requestAddCircular(payload = {}) {
  let { media_url } = payload;
  const {
    title,
    description,
    dates,
    category,
    target_group,
    student_target_group,
    circular_number,
  } = payload;
  if (
    !title ||
    title === "" ||
    !description ||
    description === "" ||
    !dates ||
    dates === ""
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/circular/create";
  if (!media_url || media_url == null) media_url = [];

  try {
    const date =moment(dates).format("MM/DD/YYYY")
    if(date =="Invalid date") {
      const date =moment(dates,"DD/MM/YYYY").format("MM/DD/YYYY")
      var requestPayload = {
        title,
        description,
        date,
        category,
        target_group,
        media: [...media_url],
        student_target_group,
        circular_number,
      };
    }
    else{
      var requestPayload = {
        title,
        description,
        date,
        category,
        target_group,
        media: [...media_url],
        student_target_group,
        circular_number,
      };
    }

    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestUpdateCircular(requestPayload = {}) {
  // const {
  //   circular_id,
  //   title,
  //   description,
  //   date,
  //   media_url,
  //   new_media,
  //   category,
  //   target_group,
  //   student_target_group,
  //   circular_number,
  // } = payload;
  // if (
  //   !circular_id ||
  //   circular_id === "" ||
  //   !title ||
  //   title === "" ||
  //   !description ||
  //   description === "" ||
  //   !date ||
  //   date === ""
  // ) {
  //   return { error: "Field missing" };
  // }

  const url =
    BASE_URL + "/management/circular/update/" + requestPayload.circular_id;

  try {
    // let requestPayload;
    // console.log("payload payload",payload)
    //   if(new_media !== ""&& new_media != null){
    //     requestPayload = {
    //       title,
    //       description,
    //       date,
    //       category,
    //       target_group,
    //       new_media: [...media_url],
    //       student_target_group,
    //       circular_number,
    //     }
    //   }
    //   else if (media_url !== "" && media_url != null) {
    //     requestPayload = {
    //       title,
    //       description,
    //       date,
    //       category,
    //       target_group,
    //       new_media: [...media_url],
    //       media:[],
    //       student_target_group,
    //       circular_number,
    //     }

    // } else {
    //   requestPayload = {
    //     title,
    //     description,
    //     date,
    //     category,
    //     target_group,
    //     student_target_group,
    //     circular_number,
    //   };
    // }

    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestDeleteCircular(payload = {}) {
  const { circular_id } = payload;

  if (!circular_id || circular_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/circular/delete";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

const Services = {
  requestAddCircular,
  requestCircularById,
  requestSearchCircular,
  requestUpdateCircular,
  requestDeleteCircular,
};

export default Services;

import React, { useState, useEffect } from "react";
import Styles from "./Events.module.scss";
import { Select, Input, Spinner, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ManagementDashboard from "services/management-dashboard/events.service";
import { debounce } from "utilities/utils";
import { GiPartyPopper } from "react-icons/gi";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle, AiFillLock } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
const ManagementEvents = () => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    month: "",
    year: "",
    title: "",
  });
  const [events, setEvents] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalEvents, setTotalEvents] = useState(0);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("pageSize") || 10
  );
  const yearOptions = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 1 + index
  );
  useEffect(() => {
    if (pageSize) {
      localStorage.setItem("pageSize", pageSize);
    }
  }, [pageSize]);
  useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPageSize(parseInt(storedPageSize));
    }
  }, []);
  useEffect(() => {
    let input_data = localStorage.getItem("event_filter")
      ? JSON.parse(localStorage.getItem("event_filter"))
      : {
          year: localStorage.getItem("yearResult") || new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          title: "",
        };
    setInputFields(input_data);
    if (input_data.title !== "") {
      setShowSearchIcon(false);
    }
  }, []);
  useEffect(() => {
    if (inputFields.month || inputFields.title !== "") {
      localStorage.setItem("event_filter", JSON.stringify(inputFields));
      searchEventList();
    }
  }, [inputFields.month, inputFields.year]);
  const fetchEvents = async () => {
    try {
      setRequestInProgress(true);

      const payload = {
        ...inputFields,
      };
      const response = await ManagementDashboard.requestSearchEvents(payload);
      if (response.error) {
        console.error(response.error);
      }
      setEvents(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e === 403) {
        console.log("error catch", e);
        setErrorMessage(e);
      }
    }
  };
  useEffect(() => {
    const pageSizeFromLocalStorage = localStorage.getItem("pageSize");
    if (!pageSizeFromLocalStorage && inputFields.month === "All") {
      setPageSize(10);
    }
  }, [inputFields.month]);

  useEffect(() => {
    setTotalEvents(events.length);
  }, [events]);
  const fetchEventsWithoutTitle = async () => {
    try {
      setRequestInProgress(true);

      const { title, ...payloadWithoutTitle } = inputFields;
      const response = await ManagementDashboard.requestSearchEvents(
        payloadWithoutTitle
      );
      if (response.error) {
        console.error(response.error);
      }
      setEvents(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const searchEventList = debounce(function () {
    fetchEvents();
  }, 500);
  const handleReadMoreClick = () => {
    setPageSize((prevPageSize) => prevPageSize + 10);
    localStorage.setItem("pageSize", pageSize + 10);
  };
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "month" || name === "year") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        title: "",
      }));
      setPageSize(10);
      localStorage.setItem("pageSize", 10);
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.title !== "") {
      localStorage.setItem("event_filter", JSON.stringify(inputFields));
      fetchEvents();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("event_filter")) || {};
    localStorageData.title = "";
    localStorage.setItem("event_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      title: "",
    }));

    fetchEventsWithoutTitle();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={Styles.wrapper}>
      {errorMessage ? (
        <div className={Styles.errorContainer}>
          <div className={Styles.errorSubContainer}>
            <div className={Styles.errorCodeContainer}>
              <AiFillLock style={{ fontSize: "30px", color: "white" }} />
            </div>
            <p className={Styles.errorText}>Message :</p>
            <p className={Styles.errorText2}>
              You don't have permission to view the Student Module.
            </p>
            <div>
              <Link to="/management/dashboard">
                <Button colorScheme="blue" variant="solid" m={2}>
                  Go to Dashboard Page
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={Styles.headerFirst}>
            <div className={Styles.statsWrapper}>
              <p className={Styles.stats}>EVENTS |</p>
              <p className={Styles.stats}>Total Events : {events?.length}</p>
            </div>
            <div className={Styles.header}>
              <div className={Styles.filterWrapper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <Input
                      variant="filled"
                      placeholder="Search Title"
                      value={inputFields.title}
                      onChange={handleInputChange}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      name="title"
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  value={inputFields.month}
                  required
                  onChange={handleInputChange}
                  name="month"
                >
                  <option value="All">All</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Select>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  value={inputFields.year}
                  required
                  onChange={handleInputChange}
                  name="year"
                >
                  {yearOptions.map((year, index) => (
                    <option key={year} value={year}>
                      {`${year}-${(year + 1).toString().slice(-2)}`}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={Styles.addButton}>
                <Link to="add">
                  <Button colorScheme="teal">Create</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={Styles.body}>
            {requestInProgress ? (
              <Spinner />
            ) : (
              <>
                <div className={Styles.lists}>
                  {events?.length == 0 ? (
                    <div className={Styles.noResult}>No Events Found</div>
                  ) : (
                    ""
                  )}
                  {events.slice(0, pageSize).map((item) => (
                    // events?.map((item) => (
                    <Link to={item._id}>
                      <div className={Styles.list}>
                        {item.media[0]?.media_url ? (
                          <img
                            className={Styles.profileImage}
                            src={item.media[0]?.media_url}
                            alt="profile"
                          />
                        ) : (
                          <GiPartyPopper
                            className={Styles.profileImage}
                            style={{ fontSize: "100px", color: "#fc03b1" }}
                          />
                        )}
                        <p className={Styles.name}>{item.title}</p>
                        <p>{formatDate(item.date)}</p>
                        <p className={Styles.views}>
                          <FaEye className={Styles.eyeIcon} /> {item.view_count}{" "}
                          Views
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
                {totalEvents > pageSize && (
                  <div className={Styles.readMoreButton}>
                    <Button onClick={handleReadMoreClick} colorScheme="blue">
                      Read More
                    </Button>
                  </div>
                )}
              </>
            )}
            {showTopBtn && (
              <div
                onClick={scrollToTop}
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                  backgroundColor: "#990f0d",
                  borderRadius: "20px",
                  display: " flex",
                  alignItems: "flex-end",
                }}
              >
                <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ManagementEvents;

import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "@chakra-ui/react";
import { requestStudentDetailsById } from "services/student-dashboard/profile.service";
import { useClassAndSections } from "hooks/classAndSection";
import defaultUser from "../../../assets/icons/User.png";
import Styles from "./Profile.module.scss";
import { BsCheckSquare } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { FaBus, FaCalendarAlt } from "react-icons/fa";
import { MdFastfood } from "react-icons/md";
import moment from "moment";
import {
  AiOutlineMinusSquare,
  AiOutlinePlusSquare,
  AiFillDollarCircle,
} from "react-icons/ai";
import html2pdf from "html2pdf.js";

const Profile = () => {
  const { loading } = useClassAndSections();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [studentDetails, setStudentDetails] = useState(null);
  const [isAdmissionDetailsVisible, setIsAdmissionDetailsVisible] =
    useState(true);
  const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] =
    useState(true);
  const [isOtherDetailsVisible, setIsOtherDetailsVisible] = useState(true);
  const [isFatherDetailsVisible, setIsFatherDetailsVisible] = useState(true);
  const [isMotherDetailsVisible, setIsMotherDetailsVisible] = useState(true);
  const [isAcademicDetailsVisible, setIsAcademicDetailsVisible] =
    useState(true);
  useEffect(() => {
    fetchStudentProfileDetails();
  }, []);
  const fetchStudentProfileDetails = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestStudentDetailsById();
      if (response) {
        setRequestInProgress(false);
        setStudentDetails(response);
      }
      if (response.error) {
        console.error(response.error);
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  if (loading || requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!studentDetails) {
    return null;
  }
  const handleDownloadPDF = () => {
    const element = document.getElementById("profile");
    html2pdf(element, {
      margin: 10,
      filename: "profile.pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
      html2canvas: { scale: 0.9 },
      pagebreak: { mode: "avoid-all" },
    });
  };
  // const handleDownloadPDF = async () => {
  //   try {
  //     // Fetch profile image
  //     const profileImageBlob = await fetch(studentDetails.url).then(
  //       (response) => response.blob()
  //     );
  //     const reader = new FileReader();
  //     reader.readAsDataURL(profileImageBlob);
  //     reader.onloadend = () => {
  //       const profileImageDataURL = reader.result;

  //       // Get profile element
  //       const profileElement = document.getElementById("profile");

  //       // Clone profile element to avoid modifying original
  //       const clonedProfileElement = profileElement.cloneNode(true);

  //       // Replace image source with the fetched profile image
  //       const profileImage =
  //         clonedProfileElement.querySelector(".profileImage");
  //       if (profileImage) {
  //         profileImage.src = profileImageDataURL;
  //       } else {
  //         console.error("Profile image element not found");
  //       }

  //       // Create PDF
  //       html2pdf().from(clonedProfileElement).save("profile.pdf");
  //     };
  //   } catch (error) {
  //     console.error("Error downloading image or generating PDF:", error);
  //   }
  // };
  return (
    <div className={Styles.body}>
      <div className={Styles.profileDownloadContainer}>
        <Button colorScheme="green" onClick={handleDownloadPDF}>
          Download Profile
        </Button>
      </div>
      <div className={Styles.profiles}>
        <div className={Styles.profile}>
          <img
            className={Styles.profileImage}
            src={
              studentDetails?.url != "N/A" ? studentDetails?.url : defaultUser
            }
            alt="profile"
          />
        </div>
      </div>
      <div id="profile">
        <form>
          <div className={Styles.forms}>
            <div className={Styles.headerContainer}>
              <button type="button" className={Styles.heading}>
                Personal Details
              </button>
              {isPersonalDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsPersonalDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsPersonalDetailsVisible(true)}
                />
              )}
            </div>
            {isPersonalDetailsVisible && (
              <>
                <div className={Styles.row}>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Name</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.name}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Email</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.email}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Gender</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.personel?.gender}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Aadhaar Number</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.personel?.aadhar_number}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Permanent Address</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.personel?.permanent_address}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Present Address</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.personel?.present_address}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Blood Group</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {studentDetails?.personel?.blood_group}
                    </span>
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>Date of Birth</p>
                    <span
                      className={`${Styles.textInput} ${Styles.inputField}`}
                    >
                      {moment(studentDetails?.personel?.dob).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </>
            )}
            <hr
              style={{
                border: "2px solid black",
                width: "100%",
                margin: "5px 0",
              }}
            />
            <div className={Styles.headerContainer}>
              <button type="button" className={Styles.heading}>
                Admission Details
              </button>
              {isAdmissionDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsAdmissionDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsAdmissionDetailsVisible(true)}
                />
              )}
            </div>
            {isAdmissionDetailsVisible && (
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Admission ID</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.academic?.admission_number}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Admission Year</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.academic?.admission_year}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Class</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.academic?.class}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Section</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.academic?.section}
                  </span>
                </div>
              </div>
            )}
            <hr
              style={{
                border: "2px solid black",
                width: "100%",
                margin: "5px 0",
              }}
            />
            <div className={Styles.headerContainer}>
              <button type="button" className={Styles.heading}>
                Other Details
              </button>
              {isOtherDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsOtherDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsOtherDetailsVisible(true)}
                />
              )}
            </div>
            {isOtherDetailsVisible && (
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Nationality</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.personel?.nationlity}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Mother Tongue</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.personel?.mother_tongue}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Religion</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.personel?.religion}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Sub-Religion</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.personel?.sub_religion
                      ? studentDetails?.personel?.sub_religion
                      : "-"}
                  </span>
                </div>
              </div>
            )}
            <hr
              style={{
                border: "2px solid black",
                width: "100%",
                margin: "5px 0",
              }}
            />
            <div className={Styles.headerContainer}>
              <button className={Styles.heading} type="button">
                Father Details
              </button>
              {isFatherDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsFatherDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsFatherDetailsVisible(true)}
                />
              )}
            </div>
            {isFatherDetailsVisible && (
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Father Name</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.father?.father_name}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Qualification</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.father?.father_qualification
                      ? studentDetails?.father?.father_qualification
                      : "-"}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Occupation</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.father?.father_occupation}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Phone No.</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.father?.father_phone}
                  </span>
                </div>
              </div>
            )}
            <hr
              style={{
                border: "2px solid black",
                width: "100%",
                margin: "5px 0",
              }}
            />
            <div className={Styles.headerContainer}>
              <button className={Styles.heading} type="button">
                Mother Details
              </button>
              {isMotherDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsMotherDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsMotherDetailsVisible(true)}
                />
              )}
            </div>
            {isMotherDetailsVisible && (
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Mother Name</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.mother?.mother_name}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Qualification</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.mother?.mother_qualification
                      ? studentDetails?.mother?.mother_qualification
                      : "-"}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Occupation</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.mother?.mother_occupation
                      ? studentDetails?.mother?.mother_occupation
                      : "-"}
                  </span>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Phone No.</p>
                  <span className={`${Styles.textInput} ${Styles.inputField}`}>
                    {studentDetails?.mother?.mother_phone}
                  </span>
                </div>
              </div>
            )}
            <hr
              style={{
                border: "2px solid black",
                width: "100%",
                margin: "5px 0",
              }}
            />
            <div className={Styles.headerContainer}>
              <button className={Styles.heading} type="button">
                Academic Details
              </button>
              {isAcademicDetailsVisible ? (
                <AiOutlineMinusSquare
                  className={Styles.icons}
                  onClick={() => setIsAcademicDetailsVisible(false)}
                />
              ) : (
                <AiOutlinePlusSquare
                  className={Styles.icons}
                  onClick={() => setIsAcademicDetailsVisible(true)}
                />
              )}
            </div>
            {isAcademicDetailsVisible && (
              <>
                <div className={Styles.row}>
                  <div className={Styles.formc}>
                    <div className={Styles.statusContainer}>
                      <AiFillDollarCircle fontSize={20} />
                      <label>Account Status</label>
                      <BsCheckSquare style={{ color: "green" }} />
                    </div>
                    <div className={Styles.statusContainer}>
                      <MdFastfood fontSize={20} />
                      <label>Canteen Status</label>
                      {studentDetails.canteen_choices &&
                      studentDetails.canteen_choices.length > 0 &&
                      studentDetails.canteen_choices[0].opted_value ? (
                        <BsCheckSquare style={{ color: "green" }} />
                      ) : (
                        <FcCancel />
                      )}
                    </div>
                    <div className={Styles.statusContainer}>
                      <FaBus />
                      <label>Bus Status</label>
                      {studentDetails.transport_choices &&
                      studentDetails.transport_choices.length > 0 &&
                      studentDetails?.transport_choices[0]?.opted_value ? (
                        <BsCheckSquare style={{ color: "green" }} />
                      ) : (
                        <FcCancel />
                      )}
                    </div>
                    <div className={Styles.statusContainer}>
                      <FaCalendarAlt />
                      <label>Session Status</label>
                      {studentDetails.period_data &&
                      studentDetails.period_data?.is_period ? (
                        <BsCheckSquare style={{ color: "green" }} />
                      ) : (
                        <FcCancel />
                      )}
                    </div>
                  </div>
                </div>
                <h1 className={Styles.Elecheading}>Electives</h1>
                {studentDetails?.subject_choices?.length === 0 && (
                  <p className={Styles.noResult}>
                    No Elective Subjects are Assigned
                  </p>
                )}
                {studentDetails?.subject_choices?.map((sub, idx) => (
                  <div key={idx} className={Styles.row}>
                    <div className={Styles.form}>
                      <p style={{ fontWeight: 700, marginBottom: "10px" }}>
                        {sub?.elective_name || "--"}
                      </p>
                      <label style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="radio"
                          style={{ marginRight: 10, height: 22, width: 22 }}
                          name={sub?.elective_name}
                          value={sub}
                          disabled
                          checked={sub?.elective_name}
                        />
                        {sub.chosen_subject === "N/A"
                          ? "Not Selected"
                          : sub.chosen_subject}
                      </label>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;

import axios from "axios";
import { getRequestOptions, BASE_URL, logoutUser } from "services/utils";

export async function requestCreateNotification(payload = {}) {
  const url = BASE_URL + "/admin/notification/add";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestAllNotifications(payload = {}) {
  const url = BASE_URL + "/general/notification/getAll";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response && e?.response.status === 401) {
      throw e.response.status;
    }
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSingleNotificationDetail(payload = {}) {
  const url = BASE_URL + "/general/notification/getOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSingleNotificationDetailAdmin(payload = {}) {
  const url = BASE_URL + "/admin/notification/sentOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      // console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestRemoveNotification(payload = {}) {
  const url = BASE_URL + "/admin/notification/remove";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSearchAll(payload = {}) {
  const url = BASE_URL + "/admin/notification/users";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestSearchClassAndSection(payload = {}) {
  const url = BASE_URL + "/admin/notification/class";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestGetSendNotifications(payload = {}) {
  const url = BASE_URL + "/admin/notification/sent";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

import React, { useEffect, useState } from "react";
import Styles from "./Statistics.module.scss";
import Services from "services/management-dashboard/statistics.service";
import { Link, useNavigate } from "react-router-dom";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import moment from "moment";
import { useClassAndSections } from "hooks/classAndSection";
import { Select, Tooltip as Tooltips } from "@chakra-ui/react";
import {
  requestSearchStudentByFilter,
  requestAdmissionStudentDetails,
} from "services/admin-dashboard/admission.service.js";
import { requestSearchStudentDetails } from "services/admin-dashboard/student.service";
import defaultUser from "../../../assets/icons/Logged_In_users.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import "./Statistics.module.scss";
import Student from "assets/icons/student.png";
import TeacherImg from "assets/icons/teacher.png";
import noticeImg from "assets/icons/notice-statics.png";
import recentActivity from "assets/icons/recentActivity-statics.png";
import studentImage from "assets/icons/Admin .png";
import notebook from "assets/icons/notebook.gif";
import AdmissionImg from "assets/icons/Admission .png";
import ManagementImg from "assets/icons/Management.png";
import Feedocimg from "assets/icons/Full-rupee-indian.png";
import Fullpaid from "assets/icons/paid-rupee-indian.png";
import RemainingFee from "assets/icons/due-rupee-indian.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Legend,
  ArcElement,
  Tooltip,
} from "chart.js";
import { Line, Pie, Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  Tooltip
);

const Statistics = () => {
  const [studentCount, setStudentCount] = useState("");
  const [staffCount, setStaffCount] = useState("");
  const [addmissionCount, setAddmissionCount] = useState("");
  const [alladmissiondetails, setAllAdmissionDetails] = useState("");
  const [noticedata, setNoticeData] = useState("");
  const [adminDetails, setAdminDetails] = useState("");
  const [noticeCircular, setNoticeCircular] = useState("");
  const [sections, setSections] = useState([]);
  const { classAndSections, loading } = useClassAndSections();
  const [inputFields, setInputFields] = useState({});
  const [type, setType] = useState("Students");
  const [admissiondDatas, setAdmissionDatas] = useState("");
  const navigate = useNavigate();
  const [allStudentdetails, setAllStudentDetails] = useState("");
  const [studentDatas, setStudentDatas] = useState("");
  const [managementData, setManagementData] = useState("");
  const [signedImages, setSignedImages] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [datas, setDatas] = useState("");
  const [departmentdatas, setdepartmentDatas] = useState("");
  const [selectClass, setSelectClass] = useState("ALL");
  const [mainUserCount, setMainUserCount] = useState([]);
  const [feesDatas, setFeeDatas] = useState("");
  const [studentFeePie, setStudentFeePie] = useState([]);
  const [graphLoading, setGraphLoading] = useState(false);
  const [classGraphData, setClassGraphData] = useState(null);
  const [classRelegionData, setClassRelegionData] = useState(null);
  const [classFeeData, setClassFeeData] = useState(null);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  if (screenSize && screenSize?.width >= 500) {
    ChartJS.defaults.font.size = 13;
  } else {
    ChartJS.defaults.font.size = 6;
  }

  const addmissionStudentGraphData = async () => {
    setGraphLoading(true);
    try {
      const Addmissonres = await Services.getAddmissionCount();

      let totalStudent = [];
      let boysCount = [];
      let girlsCount = [];
      let unknownGender = [];
      let classLabel = [];
      let hinduReligion = [];
      let muslimRelegion = [];
      let christianRelegion = [];
      let unknownReligion = [];

      for (let i = 0; i < Addmissonres.length; i++) {
        let {
          boys,
          girls,
          unknown_gender,
          genderTotal,
          section,
          hindu,
          muslim,
          christian,
          unknown_religion,
        } = Addmissonres[i];
        if (
          selectClass === "ALL" &&
          section === "ALL" &&
          Addmissonres[i].class !== "ALL"
        ) {
          totalStudent.push(genderTotal);
          boysCount.push(boys);
          girlsCount.push(girls);
          unknownGender.push(unknown_gender);
          classLabel.push(Addmissonres[i].class);
          hinduReligion.push(hindu);
          muslimRelegion.push(muslim);
          christianRelegion.push(christian);
          unknownReligion.push(unknown_religion);
        }
        if (selectClass === Addmissonres[i].class && section !== "ALL") {
          totalStudent.push(genderTotal);
          boysCount.push(boys);
          girlsCount.push(girls);
          unknownGender.push(unknown_gender);
          classLabel.push(section);
          hinduReligion.push(hindu);
          muslimRelegion.push(muslim);
          christianRelegion.push(christian);
          unknownReligion.push(unknown_religion);
        }
      }
      const Classdata = {
        labels: classLabel,
        datasets: [
          {
            label: "Total students",
            data: totalStudent,
            borderColor: "rgb(131, 129, 129,1)",
            backgroundColor: "rgba(217, 217, 217, 1)",
          },
          {
            label: "Boys ",
            data: boysCount,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Girls ",
            data: girlsCount,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Unknown_Gender ",
            data: unknownGender,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(209, 224, 224)",
            backgroundColor: "rgb(209, 224, 224)",
          },
        ],
        // data2: setGraphLoading(true)
      };
      const feeData = {
        labels: classLabel,
        datasets: [
          {
            label: "Hindu",
            data: hinduReligion,
            borderColor: "black",
            backgroundColor: "rgb(255,165,0)",
          },
          {
            label: "Muslim ",
            data: muslimRelegion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "green",
            backgroundColor: "rgb(153, 255, 153)",
          },
          {
            label: "Christian ",
            data: christianRelegion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(214, 214, 194)",
            backgroundColor: "rgb(224, 224, 209)",
          },
          {
            label: "Unknown_Religion ",
            data: unknownReligion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "pink",
            backgroundColor: "rgb(214, 214, 194)",
          },
        ],
      };
      setClassRelegionData(feeData);
      setClassGraphData(Classdata);
      setGraphLoading(false);
    } catch (err) {
      setGraphLoading(false);
      console.log(err);
    }
  };
  const StudentGraphData = async () => {
    setGraphLoading(true);
    try {
      const Studentres = await Services.getStudentsCount();
      setStudentCount(Studentres[0].totalDoc);
      setAllStudentDetails(Studentres);
      let totalStudent = [];
      let boysCount = [];
      let girlsCount = [];
      let unknownGender = [];
      let classLabel = [];
      let hinduReligion = [];
      let muslimRelegion = [];
      let christianRelegion = [];
      let unknownReligion = [];
      for (let i = 0; i < Studentres.length; i++) {
        let {
          boys,
          girls,
          unknown_gender,
          genderTotal,
          section,
          hindu,
          muslim,
          christian,
          unknown_religion,
        } = Studentres[i];
        if (
          selectClass === "ALL" &&
          section === "ALL" &&
          Studentres[i].class !== "ALL"
        ) {
          totalStudent.push(genderTotal);
          boysCount.push(boys);
          girlsCount.push(girls);
          unknownGender.push(unknown_gender);
          classLabel.push(Studentres[i].class);
          hinduReligion.push(hindu);
          muslimRelegion.push(muslim);
          christianRelegion.push(christian);
          unknownReligion.push(unknown_religion);
        }
        if (selectClass === Studentres[i].class && section !== "ALL") {
          totalStudent.push(genderTotal);
          boysCount.push(boys);
          girlsCount.push(girls);
          unknownGender.push(unknown_gender);
          classLabel.push(section);
          hinduReligion.push(hindu);
          muslimRelegion.push(muslim);
          christianRelegion.push(christian);
          unknownReligion.push(unknown_religion);
        }
      }
      console.log(
        "values ",
        hinduReligion,
        muslimRelegion,
        christianRelegion,
        unknownReligion,
        selectClass
      );
      const Classdata = {
        labels: classLabel,
        datasets: [
          {
            label: "Total students",
            data: totalStudent,
            borderColor: "rgb(131, 129, 129,1)",
            backgroundColor: "rgba(217, 217, 217, 1)",
          },
          {
            label: "Boys ",
            data: boysCount,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            label: "Girls ",
            data: girlsCount,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Unknown_Gender ",
            data: unknownGender,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(209, 224, 224)",
            backgroundColor: "rgb(209, 224, 224)",
          },
        ],
        // data2: setGraphLoading(true)
      };
      const RelegionData = {
        labels: classLabel,
        datasets: [
          {
            label: "Hindu",
            data: hinduReligion,
            borderColor: "black",
            backgroundColor: "rgb(255,165,0)",
          },
          {
            label: "Muslim ",
            data: muslimRelegion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "green",
            backgroundColor: "rgb(153, 255, 153)",
          },
          {
            label: "Christian ",
            data: christianRelegion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "rgb(214, 214, 194)",
            backgroundColor: "rgb(224, 224, 209)",
          },
          {
            label: "Unknown_Religion ",
            data: unknownReligion,
            // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: "pink",
            backgroundColor: "rgb(214, 214, 194)",
          },
        ],
      };
      setClassRelegionData(RelegionData);
      setClassGraphData(Classdata);
      setGraphLoading(false);
    } catch (err) {
      setGraphLoading(false);
      console.log(err);
    }
  };

  // console.log("classRelegionData", classRelegionData)

  const getStaffsCount = async () => {
    const res = await Services.getSaffCount();
    if (res) {
      setStaffCount(res);

      let newArray = [];
      let departmentArry = [];
      res.map((item) => {
        if (item.department != "") {
          let obj = item.totalDoc;
          let departobj = item.department;

          newArray.push(obj);
          departmentArry.push(departobj);
          setDatas(newArray);
          setdepartmentDatas(departmentArry);
        }
      });
    }
    // console.log("StaffCount" , staffCount[0].totalDoc)
  };
  const getAddmissionCount = async () => {
    const Addmissonres = await Services.getAddmissionCount();
    if (Addmissonres) {
      setAddmissionCount(Addmissonres[0].totalDoc);
      setAllAdmissionDetails(Addmissonres);
    }
  };
  const noticeBoardDetails = async () => {
    const noticeResponse = await Services.getNoticeBoardDetails();
    if (noticeResponse) {
      setNoticeData(noticeResponse.events);

      setNoticeCircular(noticeResponse);
    }
    // console.log("noticeResponse", noticedata);
  };
  const adminUserInfo = async () => {
    const adminResponse = await Services.getAdminUserInfo();
    if (adminResponse) {
      // console.log("adminResponse,", adminResponse)
      setAdminDetails(adminResponse);
    }
  };
  const managementDataDetails = async () => {
    const managementresponse = await Services.managementUserInfo();
    if (managementresponse) {
      // console.log("managementresponse", managementresponse);
      setManagementData(managementresponse);
    }
  };

  const loggedinUserInfo = async () => {
    const loggedinUserInfo = await Services.getLoggedUserInfo();
    if (loggedinUserInfo) {
      setLoggedInUser(loggedinUserInfo);
      // console.log("loggedinUserInfo loggedinUserInfo",loggedinUserInfo)
    }
  };
  useEffect(() => {
    // getStudentsCount();
    getStaffsCount();
    getAddmissionCount();
    noticeBoardDetails();
    adminUserInfo();
    managementDataDetails();
    loggedinUserInfo();
  }, []);
  useEffect(() => {
    // getGraphData()
    if (type == "Students") {
      StudentGraphData();
    } else {
      addmissionStudentGraphData();
    }
  }, [selectClass, type]);

  const getFeeGraphDetails = async () => {
    try {
      const feeDetailsResponse = await Services.getFeeStatisticsDetails();
      if (feeDetailsResponse) {
        // console.log("feeDetailsResponse", feeDetailsResponse)
        setFeeDatas(feeDetailsResponse[0]);
        const feeArray = [];
        let feeData1 = { count: feeDetailsResponse[0].paid, status: "Paid" };
        let feeData2 = {
          count: feeDetailsResponse[0].unpaid,
          status: "Unpaid",
        };
        let feeData3 = {
          count: feeDetailsResponse[0].partial,
          status: "Partial",
        };
        let feeData4 = {
          count: feeDetailsResponse[0].unknown,
          status: "Unknown",
        };
        feeArray.push(feeData1, feeData2, feeData3, feeData4);
        setStudentFeePie(feeArray);
        setGraphLoading(true);
        const paidArray = [];
        const partialPaidArray = [];
        const unpaidArray = [];
        const unknownArray = [];
        const classLabel = [];
        for (let i = 0; i < feeDetailsResponse.length; i++) {
          let { paid, partial, unpaid, unknown, section } =
            feeDetailsResponse[i];
          if (
            selectClass === "ALL" &&
            section === "ALL" &&
            feeDetailsResponse[i].class !== "ALL"
          ) {
            paidArray.push(paid);
            partialPaidArray.push(partial);
            unpaidArray.push(unpaid);
            unknownArray.push(unknown);
            classLabel.push(feeDetailsResponse[i].class);
          }
          if (
            selectClass === feeDetailsResponse[i].class &&
            section !== "ALL"
          ) {
            paidArray.push(paid);
            partialPaidArray.push(partial);
            unpaidArray.push(unpaid);
            unknownArray.push(unknown);
            classLabel.push(feeDetailsResponse[i].class);
          }
        }
        const FessData = {
          labels: classLabel,
          datasets: [
            {
              label: "Paid Fee ",
              data: paidArray,
              borderColor: "rgb(152, 251, 152,1)",
              backgroundColor: "rgba(152, 251, 152)",
            },
            {
              label: "Unpaid Fee ",
              data: unpaidArray,
              // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
              label: "Partial Paid Fee  ",
              data: partialPaidArray,
              // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "Unknown Fee  ",
              data: unknownArray,
              // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
              borderColor: "rgb(209, 224, 224)",
              backgroundColor: "rgb(209, 224, 224)",
            },
          ],
        };
        setClassFeeData(FessData);
        setGraphLoading(false);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  useEffect(() => {
    getFeeGraphDetails();
  }, [selectClass]);

  useEffect(() => {
    // studentFilter();
    // let requiredSections = classAndSections.length
    //   ? classAndSections
    //       .filter((item) => item.class == inputFields.class)
    //       .map((item) => item.sections)
    //   : [["A"]];
    // setSections(requiredSections[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFields.class, classAndSections, inputFields.section, type]);

  const studentData = () => {
    navigate("/management/dashboard/students");
  };
  const teacherData = () => {
    navigate("/management/dashboard/staff");
  };
  const addmissionData = () => {
    navigate("/management/dashboard/admission");
  };

  const options4 = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        position: "bottom",
        text: "Staff",
      },
    },
  };
  const options5 = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        position: "bottom",
        text: "Admin-Management",
      },
    },
  };
  const options6 = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        position: "bottom",
        text: "Fees",
      },
    },
  };
  const options1 = {
    responsive: true,
    // maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        labels: {
          // This more specific font property overrides the global property
        },
      },
      title: {
        display: true,
        position: "top",
        text: "Student Count",
      },
    },
    scales: {
      x: {
        font: {
          size: 5,
        },
        grid: {
          display: false,
          // text:"count",
          // title:"count",
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    // drawOnChartArea	: false
  };
  const options2 = {
    responsive: true,

    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      title: {
        display: true,
        position: "top",
        text: "Religion Count",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          // text:"count",
          // title:"count",
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  const options3 = {
    responsive: true,

    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      title: {
        display: true,
        position: "top",
        text: "Fees",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          // text:"count",
          // title:"count",
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  const Piedata = {
    labels: departmentdatas,
    datasets: [
      {
        label: "  staff count",
        data: datas,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const mainCounts = () => {
    let mainCount = [];
    // const adminCountGraph = adminDetails.user_count
    // const managementCountGraph = managementData.user_count
    mainCount.push({ count: managementData.user_count, type: "Management" });
    mainCount.push({ count: adminDetails.user_count, type: "Admin" });
    setMainUserCount(mainCount);
  };
  useEffect(() => {
    if (managementData && adminDetails) {
      mainCounts();
    }
  }, [managementData, adminDetails]);
  // console.log("mainUserCount",mainUserCount)

  const Piedata1 = {
    labels: mainUserCount.map((item) => item.type),
    datasets: [
      {
        label: " count",
        data: mainUserCount.map((item) => item.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const Piedata2 = {
    labels: studentFeePie && studentFeePie.map((item) => item.status),
    datasets: [
      {
        label: " count",
        data: studentFeePie && studentFeePie.map((item) => item.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          // 'rgba(255, 206, 86, 0.2)',
          "rgba(195, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          // 'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          // 'rgba(255, 206, 86, 1)',
          "rgba(195, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          // 'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleSelectClass = (e) => {
    let { value } = e.target;
    setSelectClass(value);
  };

  return (
    <>
      <div>
        <div className={Styles.heading}>STATISTICS</div>
        <div className={Styles.upperComponent}>
          <div className={Styles.RightcomponentBody}>
            <div className={Styles.lowercomponentBody}>
              <div
                className={Styles.componentBody}
                onClick={() => studentData()}
                style={{ background: "rgb(226,245,245)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize}>
                    <img src={Student} />
                  </div>
                  <div>Students</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{studentCount}</p>
                </div>
              </div>
              <div
                className={Styles.componentBody}
                onClick={() => teacherData()}
                style={{ background: "rgb(225,239,251)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize}>
                    <img src={TeacherImg} />
                  </div>
                  <div>Teachers</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{staffCount && staffCount[0].totalDoc}</p>
                </div>
              </div>
              <div
                className={Styles.lowerSectioncomponentBody}
                onClick={() => addmissionData()}
                style={{ background: "rgb(255,232,209)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize}>
                    <img src={AdmissionImg} />
                  </div>
                  <div>Admissions</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{addmissionCount}</p>
                </div>
              </div>
              <div
                className={Styles.lowerSectioncomponentBody}
                style={{ background: "rgb(220,253,245)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize}>
                    <img src={studentImage} />
                  </div>
                  <div>Admin</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{adminDetails.user_count}</p>
                </div>
              </div>
              <div
                className={Styles.lowerSectioncomponentBody}
                style={{ background: "rgb(253,247,223)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize}>
                    <img src={ManagementImg} />
                  </div>
                  <div>Management</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{managementData.user_count}</p>
                </div>
              </div>
              <div
                className={Styles.lowerSectioncomponentBody}
                style={{ background: "rgb(243,225,243)" }}
              >
                <div className={Styles.containerData}>
                  <div className={Styles.imageSize2}>
                    <img src={Feedocimg} />
                  </div>
                  <div>Total Fee</div>
                </div>
                <div className={Styles.componentBodyLeft}>
                  <p>{feesDatas.feeDoc}</p>
                </div>
              </div>
              <Tooltips label="Fully paid">
                <div
                  className={Styles.lowerSectioncomponentBody}
                  style={{ background: "rgb(253,235,227)" }}
                >
                  <div className={Styles.containerData}>
                    <div className={Styles.imageSize3}>
                      <img src={Fullpaid} />
                    </div>
                    <div>Fee Paid</div>
                  </div>
                  <div className={Styles.componentBodyLeft}>
                    <p>{feesDatas.paid}</p>
                  </div>
                </div>
              </Tooltips>
              <Tooltips label="Not paid any amount">
                <div
                  className={Styles.lowerSectioncomponentBody}
                  style={{ background: "rgb(206,237,210)" }}
                >
                  <div className={Styles.containerData}>
                    <div className={Styles.imageSize4}>
                      <img src={RemainingFee} />
                    </div>
                    <div>Fee Due</div>
                  </div>
                  <div className={Styles.componentBodyLeft}>
                    <p>{+feesDatas.unpaid + +feesDatas.partial}</p>
                  </div>
                </div>
              </Tooltips>
            </div>
          </div>
          <div className={Styles.upperLeftSide}>
            <div className={Styles.GraphOptions}>
              <div
                className={Styles.filterWrapper}
                style={{ marginRight: "1vw" }}
              >
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  name="class"
                  // placeholder="Students"
                >
                  <option value="Admission">Admission</option>
                  <option value="Students">Students</option>
                </Select>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  value={selectClass}
                  // onChange={(e) => setSelectClass(e.target.value)}
                  onChange={handleSelectClass}
                  name="class"
                  // placeholder="All"
                >
                  <option value="ALL">ALL</option>
                  <option value="LKG">LKG</option>
                  <option value="PLAY SCHOOL">PLAY SCHOOL</option>
                  <option value="UKG">UKG</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                </Select>
              </div>

              {/* <div className={Styles.filterWrapper}>
                <Select
                  value={inputFields.class ? inputFields.class : ""}
                  onChange={handleInputChange}
                  name="class"
                  placeholder="Select Grade"
                >
                  {classAndSections.map((grade, idx) => (
                    <option key={grade.class + idx} value={grade.class}>
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>

              <div className={Styles.filterWrapper}>
                {console.log(sections)}
                <Select
                  value={inputFields.section ? inputFields.section : ""}
                  onChange={handleInputChange}
                  name="section"
                  placeholder="Select Section"
                >
                  {sections
                    ? sections.map((section, idx) => (
                        <option key={section + idx} value={section}>
                          Section {section}
                        </option>
                      ))
                    : ""}
                </Select>
              </div> */}
            </div>
            {graphLoading &&
            classGraphData &&
            classRelegionData &&
            classFeeData ? (
              "Loading"
            ) : (
              <>
              {
                type== "Students"?
                <Carousel
                  showThumbs={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: "absolute",
                          zIndex: 2,
                          top: "calc(50% - 15px)",
                          width: 15,
                          height: 15,
                          cursor: "pointer",
                          left: -5,
                          fontSize: "30px",
                          color: "#990f0d",
                        }}
                      >
                        <AiFillLeftCircle />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: "absolute",
                          zIndex: 2,
                          top: "calc(50% - 15px)",
                          width: 15,
                          height: 15,
                          cursor: "pointer",
                          right: 8,
                          fontSize: "30px",
                          color: "#990f0d",
                        }}
                      >
                        <AiFillRightCircle />
                      </button>
                    )
                  }
                >
                  <div className={Styles.graphUpperContainer}>
                    {classGraphData ? (
                      <Line
                        height={
                          screenSize && screenSize?.width <= 500
                            ? " 280px"
                            : "95px"
                        }
                        options={options1}
                        data={classGraphData}
                      />
                    ) : null}
                  </div>
                  <div className={Styles.graphUpperContainer}>
                    {classRelegionData ? (
                      <Bar
                         height={
                          screenSize && screenSize?.width <= 500
                            ? " 280px"
                            : "95px"
                        }
                        options={options2}
                        data={classRelegionData}
                      />
                    ) : null}
                  </div>
                    <div className={Styles.graphUpperContainer}>
{ classRelegionData ? (
  <Line
     height={
      screenSize && screenSize?.width <= 500
        ? " 280px"
        : "95px"
    }
    options={options3}
    data={classFeeData}
  />
) : null}
</div> 
                 
                </Carousel>: <Carousel
                  showThumbs={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: "absolute",
                          zIndex: 2,
                          top: "calc(50% - 15px)",
                          width: 15,
                          height: 15,
                          cursor: "pointer",
                          left: -5,
                          fontSize: "30px",
                          color: "#990f0d",
                        }}
                      >
                        <AiFillLeftCircle />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: "absolute",
                          zIndex: 2,
                          top: "calc(50% - 15px)",
                          width: 15,
                          height: 15,
                          cursor: "pointer",
                          right: 8,
                          fontSize: "30px",
                          color: "#990f0d",
                        }}
                      >
                        <AiFillRightCircle />
                      </button>
                    )
                  }
                >
                  <div className={Styles.graphUpperContainer}>
                    {classGraphData ? (
                      <Line
                        height={
                          screenSize && screenSize?.width <= 500
                            ? " 280px"
                            : "95px"
                        }
                        options={options1}
                        data={classGraphData}
                      />
                    ) : null}
                  </div>
                  <div className={Styles.graphUpperContainer}>
                    {classRelegionData ? (
                      <Bar
                         height={
                          screenSize && screenSize?.width <= 500
                            ? " 280px"
                            : "95px"
                        }
                        options={options2}
                        data={classRelegionData}
                      />
                    ) : null}
                  </div>
                </Carousel>
              }
              </>
            )}
          </div>
        </div>
        <div className={Styles.lowerComponent}>
          {screenSize && screenSize?.width <= 500 ? (
            <>
              <Carousel
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        top: "calc(50% - 15px)",
                        width: 15,
                        height: 15,
                        cursor: "pointer",
                        left: -5,
                        fontSize: "30px",
                        color: "#990f0d",
                      }}
                    >
                      <AiFillLeftCircle />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        position: "absolute",
                        zIndex: 2,
                        top: "calc(50% - 15px)",
                        width: 15,
                        height: 15,
                        cursor: "pointer",
                        right: 8,
                        fontSize: "30px",
                        color: "#990f0d",
                      }}
                    >
                      <AiFillRightCircle />
                    </button>
                  )
                }
              >
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata2} options={options6} />
                </div>
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata} options={options4} />
                  {/* <div>Staff</div> */}
                </div>
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata1} options={options5} />
                </div>
              </Carousel>
            </>
          ) : (
            <div className={Styles.lowerComponentDiv}>
              <div className={Styles.PieChartdesign}>
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata2} options={options6} />
                </div>
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata} options={options4} />
                  {/* <div>Staff</div> */}
                </div>
                <div className={Styles.graphUpperContainer2}>
                  <Pie data={Piedata1} options={options5} />
                </div>
              </div>
            </div>
          )}

          <div className={Styles.lowerComponentDiv}>
            <div className={Styles.lowerComponentDiv}>
              <div className={Styles.notice}>
                <div>
                  <p className={Styles.noticeBoardTitle}>Recent Activities</p>
                </div>
                <div className={Styles.noticeBoard1}>
                  <div>
                    {noticedata &&
                      noticedata.map((notice) => {
                        return (
                          <>
                            <Link
                              to={`event/` + notice._id}
                              className={Styles.perticularNotice1}
                            >
                              <div className={Styles.noticeBoardUserImage}>
                                <img
                                  src={
                                    notice.media[0]
                                      ? notice.media[0].media_url
                                      : recentActivity
                                  }
                                />
                              </div>
                              <div>
                                <p
                                  className={Styles.lowerComponentNoticeTitle1}
                                >
                                  {notice.title}
                                </p>
                                <p class={Styles.lowerComponentNoticeDate1}>
                                  {moment(notice.date).format("DD/MM/YYYY")}
                                </p>
                              </div>
                            </Link>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={Styles.notice}>
                <div>
                  <p className={Styles.noticeBoardTitle}>Notice Board</p>
                </div>
                <div className={Styles.noticeBoard}>
                  <div>
                    {noticeCircular &&
                      noticeCircular.circulars.map((notice) => {
                        return (
                          <>
                            <Link
                              to={notice._id}
                              className={Styles.perticularNotice2}
                            >
                              <div className={Styles.noticeBoardUserImage2}>
                                <img src={noticeImg} />
                              </div>
                              <div>
                                <p class={Styles.lowerComponentNoticeDate1}>
                                  {moment(notice.date).format("DD/MM/YYYY")} -
                                  24:00
                                </p>
                                <p className={Styles.lowerComponentNoticeTitle}>
                                  {notice.title}
                                </p>
                              </div>
                            </Link>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className={Styles.notice1}>
                <div>
                  <p className={Styles.noticeBoardTitle}>Logged in Users</p>
                </div>
                <div className={Styles.noticeBoard2}>
                  <div>
                    {loggedInUser &&
                      loggedInUser.map((loggedIn) => {
                        return (
                          <>
                            <div className={Styles.perticularNotice2}>
                              <div className={Styles.noticeBoardUserImage2}>
                                <img src={defaultUser} />
                              </div>
                              <div>
                                <p
                                  className={Styles.lowerComponentNoticeTitle2}
                                >
                                  {loggedIn.userObj.name}
                                </p>
                                <p class={Styles.lowerComponentNoticeDate2}>
                                  {loggedIn.userObj.email}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;

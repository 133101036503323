import { func } from "prop-types";

export function debounce(func, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      func.apply(that, args);
    }, delay);
  };
}

export const fileToURI = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });

export const isFilterQuery = (...args) => {
  return args.some((i) => i !== "all" || (i !== "" && i.length > 0));
};
export const academicYear = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  if (month >= 1 && month < 6) {
    return year - 1;
  }
  return year;
};

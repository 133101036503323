import React, { useState, useEffect, useRef } from "react";
import Styles from "./Permissions.module.scss";
import Events from "../../../assets/icons/Events .png";
import Circulars from "../../../assets/icons/Circulars.png";
import Classroom from "../../../assets/icons/Classroom .png";
import Library from "../../../assets/icons/Library.png";
import Exams from "../../../assets/icons/Exams.png";
import Attendance from "../../../assets/icons/Attendance.png";
import TimeTable from "../../../assets/icons/Time Table.png";
import AcademicCalendar from "../../../assets/icons/Academic Calendar.png";
import Student from "../../../assets/icons/student.png";
import Assignment from "../../../assets/icons/Assignment.png";
import Knowledge_Centre from "../../../assets/icons/Knowledge_Centre.png";
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {
  requestGetOneUser,
  requestUpdateUserPermission,
} from "services/admin-dashboard/permission.service";

const SingleTeacherPermissions = () => {
  const location = useLocation();
  const toast = useToast();
  const updateModal = useDisclosure();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [payloadData, setPayloadData] = useState({
    EVENTS: false,
    CIRCULAR: false,
    "MARK ENTRY": false,
    CLASSROOM: false,
    ASSIGNMENTS: false,
    "KNOWLEDGE CENTRE": false,
    "ACADEMIC CALENDAR": false,
    LIBRARY: false,
    CANTEEN: false,
    "EXAM REPORT": false,
    ATTENDANCE: false,
    TIMETABLE: false,
    STUDENTS: false,
  });
  const [uncheckedModules, setUncheckedModules] = useState([]);
  const [addedModules, setAddedModules] = useState([]);
  const modules = [
    "EVENTS",
    "CIRCULAR",
    "MARK ENTRY",
    "CLASSROOM",
    "KNOWLEDGE CENTRE",
    "ASSIGNMENTS",
    "ACADEMIC CALENDAR",
    "LIBRARY",
    "EXAM REPORT",
    "ATTENDANCE",
    "TIMETABLE",
    "STUDENTS",
  ];

  const handleUpdateUserPermission = async () => {
    const allowedKeys = Object.entries(payloadData)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);
    let payload = {
      user_id: location?.state?.id,
      allowed: allowedKeys,
    };
    try {
      const response = await requestUpdateUserPermission(payload);
      setRequestInProgress(true);
      setTimeout(() => {
        setRequestInProgress(false);
        toast({
          title: "Updated Succesfully",
          description: "Updated Staff",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }, 1000);
      getOneUserDetail();
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
    updateModal.onClose();
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setPayloadData({ ...payloadData, [name]: checked });

    if (!checked && !uncheckedModules.includes(name)) {
      setUncheckedModules([...uncheckedModules, name]);
    } else if (checked && uncheckedModules.includes(name)) {
      const updatedModules = uncheckedModules.filter(
        (module) => module !== name
      );
      setUncheckedModules(updatedModules);
    }
  };
  const prevPayloadData = useRef(payloadData);
  // useEffect(() => {
  //   const newAddedModules = modules.filter(
  //     (module) => payloadData[module] && !prevPayloadData.current[module]
  //   );
  //   setAddedModules(newAddedModules);

  //   prevPayloadData.current = payloadData;
  // }, [payloadData]);
  const getOneUserDetail = async () => {
    const payload = {
      user_id: location?.state?.id,
    };
    try {
      const response = await requestGetOneUser(payload);
      let tempModuleData = {};
      modules.forEach((val) => {
        if (response.allowed.includes(val)) {
          tempModuleData[val] = true;
        } else {
          tempModuleData[val] = false;
        }
      });
      setPayloadData(tempModuleData);
      prevPayloadData.current = tempModuleData;
    } catch (e) {
      console.log("error", e);
    }
  };
  const trueCount = Object.values(payloadData).filter(
    (value) => value === true
  ).length;
  useEffect(() => {
    getOneUserDetail();
  }, []);
  return (
    <div>
      <div className={Styles.headerWrapper}>
        <div className={Styles.heading}>
          PERMISSION&nbsp;| {location?.state?.name}&nbsp;
          {location?.state?.department !== "Teaching"
            ? `(${location?.state?.department})`
            : ""}
        </div>
        <p
          className={Styles.summaryheading}
          style={{ color: trueCount === modules.length ? "green" : "orange" }}
        >
          Permission Summary :- ({trueCount} / {modules.length})
        </p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Events} alt="iconImg" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Events</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  name="EVENTS"
                  colorScheme="red"
                  isChecked={payloadData.EVENTS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Circulars} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Circular</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CIRCULAR"
                  isChecked={payloadData.CIRCULAR}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Student} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Students</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="STUDENTS"
                  isChecked={payloadData.STUDENTS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Classroom} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Classroom</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CLASSROOM"
                  isChecked={payloadData.CLASSROOM}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Assignment} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Assignments</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ASSIGNMENTS"
                  isChecked={payloadData.ASSIGNMENTS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={Knowledge_Centre}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Knowledge Centre</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="KNOWLEDGE CENTRE"
                  isChecked={payloadData["KNOWLEDGE CENTRE"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={AcademicCalendar}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Academic Calendar</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Academic Calendar</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ACADEMIC CALENDAR"
                  isChecked={payloadData["ACADEMIC CALENDAR"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Library} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Library</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="LIBRARY"
                  isChecked={payloadData.LIBRARY}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Exams} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Exam Report</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="EXAM REPORT"
                  isChecked={payloadData["EXAM REPORT"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Exams} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>MARK ENTRY</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="MARK ENTRY"
                  isChecked={payloadData["MARK ENTRY"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Attendance} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Attendance</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ATTENDANCE"
                  isChecked={payloadData.ATTENDANCE}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={TimeTable} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Time table</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="TIMETABLE"
                  isChecked={payloadData.TIMETABLE}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.buttonRow}>
        {/* onClick={handleUpdateUserPermission} */}
        <Button
          colorScheme="green"
          onClick={() => updateModal.onOpen()}
          className={Styles.button}
        >
          {requestInProgress ? <Spinner /> : "Update"}
        </Button>
      </div>
      <Modal isOpen={updateModal.isOpen} onClose={updateModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Permission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to update the permission?
            {uncheckedModules.length > 0 && (
              <div>
                <p style={{ fontWeight: "bold", color: "red" }}>
                  Remove the following modules :
                </p>
                <ul>
                  {uncheckedModules.map((module) => (
                    <p key={module}>{module}</p>
                  ))}
                </ul>
              </div>
            )}
            {/* {addedModules.length > 0 && (
              <div>
                <p style={{ fontWeight: "bold" }}>Add the following modules:</p>
                <ul>
                  {addedModules.map((module) => (
                    <li key={module}>{module}</li>
                  ))}
                </ul>
              </div>
            )} */}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="solid"
              mr={3}
              onClick={() => handleUpdateUserPermission()}
            >
              OK
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                updateModal.onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SingleTeacherPermissions;

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Select,
  useToast,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Styles from "./AdminManagement.module.scss";
import { requestCreateUser } from "services/common.service";
import {
  requestAlladmin,
  requestAllmanagement,
} from "services/admin-dashboard/admin-management.service";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Admin_Management() {
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab.toString());
  }, [selectedTab]);
  return (
    <div>
      <div className={Styles.body}>
        <Tabs
          isFitted
          variant="enclosed"
          index={selectedTab}
          onChange={(index) => setSelectedTab(index)}
        >
          <TabList mb="1em">
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Admin</Tab>
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
              Management
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Admin />
            </TabPanel>
            <TabPanel>
              <Management />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}

export default Admin_Management;

const Admin = () => {
  const [admins, setAdmins] = useState([]);
  const fetchAdmins = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestAlladmin();
      if (response.error) {
        console.error(response.error);
      }
      console.log("admins", response);
      setAdmins(response);
    } catch (error) {
      setRequestInProgress(false);
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const location = useLocation();

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [adminInputFields, setadminInputFields] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleAdminInputChange = ({ target: { name, value } }) => {
    setadminInputFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleAdminSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    createAdminHandler();
  };
  const createAdminHandler = async () => {
    try {
      const { name, email, password } = adminInputFields;
      const payload = {
        role: "admin",
        email,
        name,
        password,
      };

      const response = await requestCreateUser(payload);
      console.log("admin management response", response)
      setRequestInProgress(false);
      if (response && response.user_id) {
        //toast message
        toast({
          title: "Admin added",
          description: "Created new Admin",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        fetchAdmins()
        onClose();
      }
      else{
        toast({
          title: "Request Failed",
          description: "Email already exist",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        description: "Admin creation failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <div>
      <div className={Styles.header}>
        <div className={Styles.add} onClick={onOpen}>
          <Button colorScheme="teal">Create</Button>
        </div>
      </div>
      <div className={Styles.lists}>
        {admins.map((item, idx) => {
          return (
            <Link to={item._id} key={item["_id"] + idx} className={Styles.list}>
              <div className={Styles.profile}></div>
              <div className={Styles.info}>
                <p className={Styles.name}>{item.name}</p>
                <p className={Styles.email}>{item.email}</p>
                <div className={Styles.buttonRow}>
                  <Button colorScheme="blue">Edit</Button>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <div className={Styles.formGroup}>
                <label htmlFor="admin_name" className={Styles.formLabel}>
                  Admin name
                </label>
                <input
                  type="text"
                  id="admin_name"
                  name="name"
                  placeholder="Admin Name"
                  value={adminInputFields.name}
                  onChange={handleAdminInputChange}
                  autoComplete="off"
                />
                <label htmlFor="admin_email" className={Styles.formLabel}>
                  Admin email
                </label>
                <input
                  type="email"
                  id="admin_email"
                  name="email"
                  placeholder="Admin Email"
                  value={adminInputFields.email}
                  onChange={handleAdminInputChange}
                  autoComplete="off"
                />
                <label htmlFor="admin_password" className={Styles.formLabel}>
                  Admin password
                </label>
                <input
                  type="password"
                  id="admin_password"
                  name="password"
                  placeholder="Admin Password"
                  value={adminInputFields.password}
                  onChange={handleAdminInputChange}
                  autoComplete="off"
                />
                <button
                  className={Styles.formButton}
                  onClick={handleAdminSubmit}
                >
                  Add Admin
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

const Management = () => {
  const [management, setManagement] = useState([]);
  const fetchManagement = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestAllmanagement();
      if (response.error) {
        console.error(response.error);
      }
      console.log("management", response);
      setManagement(response);
    } catch (error) {
      setRequestInProgress(false);
      console.log(error);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [managementInputFields, setmanagementInputFields] = useState({
    name: "",
    email: "",
    password: "",
  });
  const handleManagementInputChange = ({ target: { name, value } }) => {
    setmanagementInputFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleManagementSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    createManagementHandler();
  };
  const createManagementHandler = async () => {
    try {
      const { name, email, password } = managementInputFields;
      const payload = {
        role: "management",
        email,
        name,
        password,
      };

      const response = await requestCreateUser(payload);
      setRequestInProgress(false);
      if (response && response.user_id) {
        //toast message
        toast({
          title: "Management User added",
          description: "Created new User",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        description: "User creation failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchManagement();
  }, []);

  return (
    <div>
      <div className={Styles.header}>
        <Link to="add">
          <div className={Styles.add} onClick={onOpen}>
            <Button colorScheme="teal">Create</Button>
          </div>
        </Link>
      </div>
      <div className={Styles.lists}>
        {management.map((item, idx) => {
          return (
            <Link
              to={"management/" + item._id}
              key={item["_id"] + idx}
              className={Styles.list}
            >
              <div className={Styles.profile}></div>
              <div className={Styles.info}>
                <p className={Styles.name}>{item.name}</p>
                <p className={Styles.email}>{item.email}</p>
                <div className={Styles.buttonRow}>
                  <Button colorScheme="blue">Edit</Button>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Management</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <div className={Styles.formGroup}>
                <label htmlFor="management_name" className={Styles.formLabel}>
                  Management name
                </label>
                <input
                  type="text"
                  id="management_name"
                  name="name"
                  placeholder="management_name"
                  onChange={handleManagementInputChange}
                />
                <label htmlFor="management_email" className={Styles.formLabel}>
                  Management email
                </label>
                <input
                  type="email"
                  id="management_email"
                  name="email"
                  placeholder="management_email"
                  onChange={handleManagementInputChange}
                />
                <label
                  htmlFor="management_password"
                  className={Styles.formLabel}
                >
                  Management password
                </label>
                <input
                  type="password"
                  id="management_password"
                  name="password"
                  placeholder="management_password"
                  onChange={handleManagementInputChange}
                />
                <button
                  className={Styles.formButton}
                  onClick={handleManagementSubmit}
                >
                  Add Management
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

import axios from "axios";

import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";

const ServicesObject = {
  requestAddBook,
  requestAddLibraryIssue,
  requestAllLibraryIssue,
  requestBookByCategory,
  requestDeleteBook,
  requestLibraryBookReturn,
  requestReturnBook,
  requestSearchBook,
  requestUpdateBook,
  requestAllCategory,
  requestDeleteCategory,
  requestAddCategory,
  requestCategoryByID,
  requestUpdateCategory,
  requestGetOneBook,
};

export default ServicesObject;

// export async function requestAddBook (payload = {}) {
//   const { title, author, availableCopy, categoryId, categoryName } = payload

//   if (isInvalid([title, author, availableCopy, categoryId, categoryName])) {
//     return { error: 'Field missing' }
//   }

//   const url = BASE_URL + '/admin/library/add-book'

//   try {
//     const requestPayload = {
//       title,
//       author,
//       availableCopy,
//       category: [
//         {categoryId, category_name: categoryName}
//       ]
//     }

//     const response = await axios.post(url, { ...requestPayload }, getRequestOptions())
//     if (response.data.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }

//     throw e
//   }
// }

export async function requestUpdateBook(payload = {}) {
  const { _id, title, author, availableCopy, categoryName } = payload;

  if (isInvalid([_id, title, author, availableCopy, categoryName])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/update-book";

  try {
    const requestPayload = {
      bookId: _id,
      title,
      author,
      availableCopy,
      category: categoryName,
    };

    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestDeleteBook(payload = {}) {
  const { _id } = payload;

  if (isInvalid([_id])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/delete-book";

  try {
    const requestPayload = { bookId: _id };
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestGetOneBook(payload = {}) {
  const url = BASE_URL + "/admin/library/getone";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestSearchBook(payload = {}) {
  const url = BASE_URL + "/admin/library/search-book";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAddLibraryIssue(payload = {}) {
  const {
    studentId,
    bookId,
    studentName,
    bookTitle,
    _class,
    section,
    issueDate,
    dueDate,
    bookAuthor,
  } = payload;

  if (
    isInvalid([
      studentId,
      bookId,
      studentName,
      bookTitle,
      _class,
      section,
      issueDate,
      dueDate,
      bookAuthor,
    ])
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/add-issue";

  try {
    const requestPayload = {
      studentId,
      bookId,
      student_name: studentName,
      book_title: bookTitle,
      book_author: bookAuthor,
      class: _class,
      section,
      issue_date: issueDate,
      date_date: dueDate,
    };
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestAllLibraryIssue() {
  const url = BASE_URL + "/admin/library/get-issue";
  try {
    const response = await axios.post(url, {}, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestLibraryBookReturn(payload = {}) {
  const { searchText } = payload;

  if (!searchText || searchText === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/search-book";

  try {
    const requestPayload = { searchText };
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestBookByCategory(payload = {}) {
  const { categoryId } = payload;

  const url = BASE_URL + "/admin/library/book/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestReturnBook(payload = {}) {
  const { bookId, studentId, studentIssueId, isReturned, issueId } = payload;

  if (
    !bookId ||
    bookId === "" ||
    !studentId ||
    studentId === "" ||
    !studentIssueId ||
    studentIssueId === "" ||
    typeof isReturned === "undefined" ||
    !issueId ||
    issueId === ""
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/return-book";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAllCategory() {
  const url = BASE_URL + "/admin/library/category/getAll";

  try {
    const response = await axios.post(url, {}, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestCategoryByID(payload = {}) {
  const url = BASE_URL + "/admin/library/category/getOne";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestDeleteCategory(payload = {}) {
  const { category_id } = payload;

  if (!category_id || category_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/category/remove";

  try {
    const requestPayload = { category_id: category_id };
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data?.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data?.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAddCategory(payload = {}) {
  const { category_name } = payload;

  if (!category_name || category_name === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/library/category/add";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e.response.data.error) {
      throw e.response.data.error;
    }
    if (e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUpdateCategory(payload = {}) {
  const url = BASE_URL + "/admin/library/category/Update";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e.response.data.error) {
      throw e.response.data.error;
    }
    if (e.response.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAddBook (payload = {}) {
  // const { book_id, book_title, book_desc, book_copies, author_name, author_email} = payload

  // if (
  //   isInvalid([
  //     book_id,
  //     book_title,
  //     book_desc,
  //     book_copies,
  //     author_name,
  //     author_email,
  //   ])
  // ) {
  //   return { error: "Field missing" };
  // }

  const url = BASE_URL + "/admin/library/book/add/oneTitle";

  try {
    // const requestPayload = {
    //   book_id,
    //   book_title,
    //   book_desc,
    //   book_copies,
    //   author_details: {
    //     author_name,
    //     author_email,
    //   },
    // };

    const response = await axios.post(
      url,
      { ...payload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

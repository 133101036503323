import React, { useEffect, useState } from "react";
import Classroom from "assets/icons/Classroom .png";
import Styles from "./ReportCentre.module.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button, Checkbox, Select } from "@chakra-ui/react";
import { useClassAndSections } from "hooks/classAndSection";
import { requestClassDetails } from "services/admin-dashboard/classroom.service";
const ClassroomReport = () => {
  const { classAndSections } = useClassAndSections();
  const [inputFields, setInputFields] = useState({
    class: "",
  });
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const initialFieldsState = {
    class: true,
    section: true,
    classTeacherName: true,
    subjectName: true,
    subjectCategory: false,
    subjectTeacherName: true,
    subjectType: true,
    subjectCode: true,
    electiveName: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [showDetails, setShowDetails] = useState({
    subjectDetails: true,
  });
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const fileName = inputFields.teacherName
    ? `SMU-Class-Report-${inputFields.teacherName}`
    : `SMU-Class-Report-${inputFields.class}`;
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleExportClick = async () => {
    const payload = {
      class: inputFields.class,
    };
    const res = await requestClassDetails(payload);
    if (res) {
      let newArray = [];
      res.forEach((item) => {
        if (item.subject_details) {
          item.subject_details.forEach((subjectDetail) => {
            processDetails(item, subjectDetail, newArray);
          });
        }
        if (item.elective_details) {
          item.elective_details.forEach((electiveDetail) => {
            processDetails(item, electiveDetail, newArray);
          });
        }
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    setFields(initialFieldsState);
    setSelectedFileFormat("xlsx");
  };
  const processDetails = (item, detail, newArray) => {
    let obj = {};
    if (fields.class) {
      obj["Class"] = item.class;
    }
    if (fields.section) {
      obj["Section"] = item.section;
    }
    if (fields.classTeacherName) {
      obj["Class Teacher Name"] = item.class_teacher.name;
    }
    if (fields.subjectName) {
      obj["Subject Name"] = detail.subject;
    }
    if (fields.subjectTeacherName) {
      obj["Teacher Name"] = detail.teacher_name;
    }
    if (fields.subjectCode) {
      obj["Subject Code"] = detail.subject_code;
    }
    if (fields.subjectType) {
      obj["Subject Type"] = detail.is_scholastic
        ? "Scholastic"
        : "Non-Scholastic";
    }
    if (fields.subjectCategory) {
      obj["Subject Category"] = item.subject_details
        ? "Regular Subject"
        : "Elective Subject";
      if (item.elective_details && item.elective_details.includes(detail)) {
        obj["Subject Category"] = "Elective Subject";
        const electiveDetail = item.elective_details.find(
          (elective) => elective === detail
        );
        obj["Elective Name"] = electiveDetail
          ? electiveDetail.elective_name
          : "-";
      }
    }
    newArray.push({ ...obj });
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const wb = XLSX.utils.book_new();
    const indexData = apiData.map((item) => ({
      Class: item["Class"],
      Section: item["Section"],
      "Class Teacher Name": item["Class Teacher Name"],
    }));
    const wsIndex = XLSX.utils.json_to_sheet(indexData, {
      header: Object.keys(indexData[0]),
    });
    XLSX.utils.book_append_sheet(wb, wsIndex, "Index");
    const uniqueClasses = [
      ...new Set(
        apiData.map((item) => `${item["Class"]} - ${item["Section"]}`)
      ),
    ];
    uniqueClasses.forEach((classSection) => {
      const [className, sectionName] = classSection.split(" - ");
      const classData = apiData.filter(
        (item) => item["Class"] === className && item["Section"] === sectionName
      );
      const nonIndexData = classData.map((item) => {
        const { Class, Section, "Class Teacher Name": _, ...rest } = item;
        return rest;
      });
      const wsClass = XLSX.utils.json_to_sheet(nonIndexData, {
        header: Object.keys(nonIndexData[0]),
      });
      XLSX.utils.book_append_sheet(wb, wsClass, `${className}-${sectionName}`);
    });
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img
                src={Classroom}
                alt="Classroom Icon"
                className={Styles.moduleIcon}
              />
              <p className={Styles.moduleName}>CLASSROOM MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class}
                    onChange={handleInputChange}
                    name="class"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Subject Details
                  </button>
                </div>
              </div>
              {showDetails.subjectDetails && (
                <>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.subjectCode}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCode: !fields.subjectCode,
                        });
                      }}
                    >
                      Subject Code
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subjectType}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectType: !fields.subjectType,
                        });
                      }}
                    >
                      Subject Type
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.subjectCategory}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCategory: !fields.subjectCategory,
                        });
                      }}
                    >
                      Subject Category
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.electiveName}
                      onChange={() => {
                        setFields({
                          ...fields,
                          electiveName: !fields.electiveName,
                        });
                      }}
                    >
                      Elective Name
                    </Checkbox>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Classroom}
              alt="Classroom Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>CLASSROOM MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.mobilefilterWrapper}>
                <Select
                  value={inputFields.class}
                  onChange={handleInputChange}
                  name="class"
                  className={Styles.dropdownContainer}
                >
                  {classAndSections.map((grade, idx) => (
                    <option key={grade.class + idx} value={grade.class}>
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Subject Details
                  </button>
                </div>
              </div>
              {showDetails.subjectDetails && (
                <>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.subjectCode}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCode: !fields.subjectCode,
                        });
                      }}
                    >
                      Subject Code
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subjectType}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectType: !fields.subjectType,
                        });
                      }}
                    >
                      Subject Type
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.subjectCategory}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCategory: !fields.subjectCategory,
                        });
                      }}
                    >
                      Subject Category
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.electiveName}
                      onChange={() => {
                        setFields({
                          ...fields,
                          electiveName: !fields.electiveName,
                        });
                      }}
                    >
                      Elective Name
                    </Checkbox>
                  </div>
                </>
              )}
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClassroomReport;

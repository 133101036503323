import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function requestSearchStudent(payload = {}) {
  const url = BASE_URL + "/management/student/search-student";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestSearchStudentByFilter(payload = {}) {
  const url = BASE_URL + "/management/student/searchStudentWithFilter";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    } else {
      console.log("Response status:", response.status);
    }
  } catch (e) {
    if (e?.response && e?.response.status === 403) {
      throw e.response.status;
    }
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSearchStudentDetails(payload = {}) {
  const url = BASE_URL + "/management/student/downloadStudentWithFilter";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestStudentDetailsById(payload = {}) {
  const { studentId } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/getStudent/" + studentId;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateStudentDetailsById(payload = {}) {
  const { studentId, ...rest } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/update-student/" + studentId;

  const requestBody = rest;

  try {
    const response = await axios.post(
      url,
      { ...requestBody },
      getRequestOptions()
    );
    console.log("response", response);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateElectiveById(payload = {}) {
  const { studentId, ...rest } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/updateElective/" + studentId;

  const requestBody = {
    ...rest,
  };
  try {
    const response = await axios.post(
      url,
      { ...requestBody },
      getRequestOptions()
    );
    console.log("response", response);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestDeleteStudentById(payload = {}) {
  const { studentId } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/delete-student";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateTransportDetails(payload = {}) {
  const { studentId, ...rest } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/updateTransport/" + studentId;

  const requestBody = {
    ...rest,
  };

  try {
    const response = await axios.post(
      url,
      { ...requestBody },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateCanteenDetails(payload = {}) {
  const { studentId, ...rest } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/student/updateCanteen/" + studentId;

  const requestBody = {
    ...rest,
  };

  try {
    const response = await axios.post(
      url,
      { ...requestBody },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUpdatePeriodChoice(payload = {}, id) {
  const url = BASE_URL + "/management/student/updatePeriodChoices/" + id;
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestGetCanteenDuration(payload = {}) {
  const url = BASE_URL + "/management/student/canteen/getOne";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestClassesAndSectionStudent(payload = {}) {
  const url = BASE_URL + "/management/student/getclassAndsection";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e?.response.status === 403) {
      throw e.response.status;
    }
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestGetTranspoartDetails(payload = {}) {
  const url = BASE_URL + "/management/student/transport/getOne";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUpdateStudentEmail(payload = {}) {
  const url = BASE_URL + "/management/student/email-update";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestCheckStudentPhoneNumber(payload = {}) {
  const url = BASE_URL + "/management/student//getstudent/phoneNumber";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
const Services = {
  requestSearchStudent,
  requestStudentDetailsById,
  requestUpdateStudentDetailsById,
  requestDeleteStudentById,
  requestUpdateTransportDetails,
  requestSearchStudentByFilter,
  requestUpdateElectiveById,
  requestUpdateCanteenDetails,
  requestSearchStudentDetails,
  requestUpdatePeriodChoice,
  requestGetCanteenDuration,
  requestGetTranspoartDetails,
  requestClassesAndSectionStudent,
  requestUpdateStudentEmail,
  requestCheckStudentPhoneNumber,
};

export default Services;

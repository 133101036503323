import { Input, Tab, TabList, TabPanel, TabPanels, Tabs,Button, Textarea } from "@chakra-ui/react";
import React from "react";
import Styles from "./About.module.scss";
// import {} from "firebase/app/"

const ManagementAbout = () => {
  const sendSMS =()=>{
    
  }
  return (
    <div>
      <p className={Styles.heading}>Contact us</p>
      <div className={Styles.mainContainer}>
      <div className ={Styles.leftSection}>
        <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
                Email
              </div>
              <p className={Styles.Description}>	smuapp.developer@gmail.com </p>
                </div>
              </div>
              <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
              Phone
              </div>
              <p className={Styles.Description}>	9481509322, 0820-2524455 </p>
                </div>
              </div>
              <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
              Address
              </div>
              <p className={Styles.Description}>	Kannarpady, Udupi-576103, Karnataka, India </p>
                </div>
              </div>
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.componentBody2}>
            <div className={Styles.NameBox}>
              <div>
                <p>
                  First Name
                </p>
                <Input required/>
              </div>
              <div>
                <p>
                  Last Name
                </p>
                <Input required/>
              </div>
            </div>
            <div>
              <p>Email</p>
              <Input required/>
            </div>
            <div>
              <p>Message</p>
              <Textarea required/>
            </div>
            
            <Button colorScheme="blue" style={{marginTop:"2vw"}} >Submit</Button>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};
export default ManagementAbout;
import React from 'react'
import Birthday from '../../../components/Birthday/Birthday'
import DashboardFeatures from '../../../components/DashboardFeatures/DashboardFeatures'
import {useLocation} from "react-router-dom"

const ManagementDashboardLanding = () => {
  const location = useLocation()
  const renderList = location
  return (
    <>
        <Birthday />
        <DashboardFeatures page="management" id = {renderList} />
    </>
  )
}

export default ManagementDashboardLanding;
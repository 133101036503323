import React, { useState } from "react";
import { Select, Input, useToast, Switch, Tooltip } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./SchoolFee.module.scss";
// import { useClassAndSections } from "hooks/classAndSection";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import {
  getAllFeePayments,
  getAllFeesMaster,
  updateFeesMaster,
  updateCanteenFees,
  feeStatistics,
  paidFeeDetails,
  ConcessionFeeDetails,
  partialPaidFeeDetails,
  getClassAndSectionForFees,
} from "services/management-dashboard/fee.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { requestAllClassroom } from "services/management-dashboard/classroom.service";
import defaultUser from "../../../assets/icons/User.png";
import { useEffect } from "react";

const ManagementSchoolFee = () => {
  const location = useLocation();
  let category = location.pathname.split("/")[4];
  // let gradesList = [];
  // if (category === "k") {
  //   gradesList = ["PLAY SCHOOL", "LKG", "UKG"];
  // } else if (category === "h1") {
  //   gradesList = ["1", "2", "3", "4", "5"];
  // } else if (category === "h2") {
  //   gradesList = ["6", "7", "8", "9", "10"];
  // } else {
  //   gradesList = ["11", "12", "13"];
  // }

  const [canteenFees, setCanteenFees] = useState({ fees: "" });
  const [gradesList, setGradeList] = useState("");
  const [classs, selectClass] = useState("");
  const [section, selectSection] = useState(
    localStorage.getItem("selectedSection") || "A"
  );
  const [searchQuery, setSearchQuery] = useState(
    localStorage.getItem("searchQuery") || ""
  );
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [feeDetails, setFeeDetails] = useState("");
  const [feesMaster, setFeesMaster] = useState("");
  const [allClassroom, setAllClassroom] = useState("");
  // const { classAndSections } = useClassAndSections();
  const [inputFields, setInputFields] = React.useState(null);
  const [updateStatus, setUpdateStatus] = React.useState(false);
  const [feeStatisticss, setFeeStatisticss] = useState("");
  const [disable, setDisable] = useState(true);
  const [partialFeeDownload, setPartialFeeDownload] = useState("");
  const [concessionFeeDownload, setConcessionFeeDownload] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateTutionFees = useDisclosure();

  const getClassandSection = async () => {
    try {
      const payload = {
        category: category,
      };
      const response = await getClassAndSectionForFees(payload);
      if (response) {
        // console.log("response",response)
        setGradeList(response);
        selectClass(
          localStorage.getItem("selectedClass")
            ? localStorage.getItem("selectedClass")
            : response[0].class
        );
      }
    } catch (e) {
      console.log(e.msg);
    }
  };
  useEffect(() => {
    getClassandSection();
  }, []);
  const fetchFeeDetails = async () => {
    // console.log("requirex  d_classID",required_classID)
    const feeData = await getAllFeePayments({
      class: classs && classs,
      section: section,
      ...(showSearchIcon && searchQuery !== "" ? { name: searchQuery } : {}),
    });
    setFeeDetails(feeData);
  };
  const getTutionFees = async () => {
    const masterData = await getAllFeesMaster({
      class: classs && classs,
      section: section,
    });
    setFeesMaster(masterData);
  };

  //   const fetchClassrooms = async () => {
  //     const allClassrooms = await requestAllClassroom();
  //     setAllClassroom(allClassrooms);
  //   };
  // console.log("allClassrooms",allClassroom)
  const handleCanteenInputChange = ({ target: { name, value } }) => {
    setCanteenFees((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e, parentField) => {
    setInputFields({ ...inputFields, [parentField]: e.target.value });
  };
  React.useEffect(() => {
    if (classs && section) {
      fetchFeeDetails();
    }
  }, [classs, section]);
  const handleUpdateFeesMaster = async () => {
    if (canteenFees && canteenFees?.fees?.length > 0) {
      let cantResp = await updateCanteenFees({
        canteen_fees: canteenFees.fees,
      });
      toast({
        title: "Canteen Fees Updated Succesfully",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      setUpdateStatus(!updateStatus);
      setCanteenFees({ fees: "" });
    }
    if (inputFields && inputFields?.tution_fees?.length > 0) {
      let data = {
        ...inputFields,
        class: classs,
        section: section,
        // year: "2022",
        other_fees: 0,
      };
      let response = await updateFeesMaster({ data });
      setUpdateStatus(!updateStatus);
      toast({
        title: "Fees Updated Succesfully",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleinputDisabled = (e, inputName) => {
    if (disable == false) {
      setDisable(true);
    } else if (disable == true) {
      setDisable(false);
    }
  };

  const allFeeStatisticsReport = async () => {
    if (disable == true) {
      console.log("penalty true");
      try {
        const payload = {
          class: classs,
          section: section,
          include_penalty_data: false,
        };
        const feeStatisticsResponse = await feeStatistics(payload);
        if (feeStatisticsResponse.success == true) {
          let newArray1 = [];
          if (feeStatisticsResponse.data.length != 0) {
            feeStatisticsResponse &&
              feeStatisticsResponse.data.map((data) => {
                let obj1 = {
                  Name: data.student_name,
                  Class: data.class,
                  Section: data.section,
                  "Total Fees":
                    +data.fees_metadata.canteen_total +
                    +data.fees_metadata.tution_total +
                    +data.fees_metadata.transport_total,
                  "Transport Total": data.transport_fees_summary.total,
                  "Transport Paid": data.transport_fees_summary.paid,
                  "Transport Pending": data.transport_fees_summary.pending,
                  "Canteen Total": data.canteen_fees_summary.total,
                  "Canteen Paid": data.canteen_fees_summary.paid,
                  "Canteen Pending": data.canteen_fees_summary.pending,
                  "Tution Total ": data.tution_fees_summary.total,
                  "Tution Paid": data.tution_fees_summary.paid,
                  "Tution Pending": data.tution_fees_summary.pending,
                  Status:
                    data.tution_fees_summary.pending == 0 &&
                    data.canteen_fees_summary.pending == 0 &&
                    data.transport_fees_summary.pending == 0
                      ? "Paid"
                      : "Unpaid",
                };
                newArray1.push(obj1);
                setFeeStatisticss(newArray1);
              });
          }
          const fileName = `SMU-Fees-Statistics-${classs} ${section}`;
          exportToCSV2(newArray1, fileName);
        }
      } catch (e) {
        console.log("err", e);
      }
    } else {
      console.log("penalty flase");
      const payload = {
        class: classs,
        section: section,
        include_penalty_data: true,
      };
      const feeStatisticsResponse = await feeStatistics(payload);
      if (feeStatisticsResponse.success == true) {
        let newArray1 = [];
        feeStatisticsResponse &&
          feeStatisticsResponse.data.map((data) => {
            let obj1 = {
              Name: data.student_name,
              Class: data.class,
              Section: data.section,
              "Total Fees":
                +data.fees_metadata.canteen_total +
                +data.fees_metadata.tution_total +
                +data.fees_metadata.transport_total,
              "Transport Total": data.transport_fees_summary.total,
              "Transport Paid": data.transport_fees_summary.paid,
              "Transport Pending": data.transport_fees_summary.pending,
              "Canteen Total": data.canteen_fees_summary.total,
              "Canteen Paid": data.canteen_fees_summary.paid,
              "Canteen Pending": data.canteen_fees_summary.pending,
              "Tution Total ": data.tution_fees_summary.total,
              "Tution Paid": data.tution_fees_summary.paid,
              "Tution Pending": data.tution_fees_summary.pending,
              "Penalty Total": data.penalty_fees_summary.total,
              "Penalty Paid": data.penalty_fees_summary.paid,
              "Penalty Pending": data.penalty_fees_summary.pending,
              Status:
                data.tution_fees_summary.pending == 0 &&
                data.canteen_fees_summary.pending == 0 &&
                data.transport_fees_summary.pending == 0
                  ? "Paid"
                  : "Unpaid",
            };
            newArray1.push(obj1);
            setFeeStatisticss(newArray1);
          });
        const fileName = `SMU-Fees-Statistics-${classs} ${section} with Penalty`;
        exportToCSV2(newArray1, fileName);
      }
    }
  };
  const exportToCSV2 = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const PaidFeeDataDownload = async () => {
    if (disable == true) {
      const payload = {
        class: classs,
        section: section,
      };
      try {
        const partialResponse = await paidFeeDetails(payload);
        if (partialResponse) {
          let partialArray = [];
          partialResponse.data &&
            partialResponse.data.partialPaidSummaryList.map((data) => {
              let partialObj = {
                Name: data.student_name,
                Class: data.class,
                Section: data.section,
                "Total Fees":
                  +data.fees_metadata.canteen_total +
                  +data.fees_metadata.tution_total +
                  +data.fees_metadata.transport_total,
                "Transport Total": data.transport_fees_summary.total,
                "Transport Paid": data.transport_fees_summary.paid,
                "Transport Pending": data.transport_fees_summary.pending,
                "Canteen Total": data.canteen_fees_summary.total,
                "Canteen Paid": data.canteen_fees_summary.paid,
                "Canteen Pending": data.canteen_fees_summary.pending,
                "Tution Total ": data.tution_fees_summary.total,
                "Tution Paid": data.tution_fees_summary.paid,
                "Tution Pending": data.tution_fees_summary.pending,
                Status:
                  data.tution_fees_summary.pending == 0 &&
                  data.canteen_fees_summary.pending == 0 &&
                  data.transport_fees_summary.pending == 0
                    ? "Paid"
                    : "Unpaid",
              };
              partialArray.push(partialObj);
              setPartialFeeDownload(partialArray);
            });
          const fileName = `SMU-Fees-Statistics-${classs} ${section}`;
          exportToCSV2(partialArray, fileName);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      const payload = {
        class: classs,
        section: section,
        include_penalty_data: true,
      };
      try {
        const partialResponse = await paidFeeDetails(payload);
        if (partialResponse) {
          let partialArray = [];
          partialResponse.data &&
            partialResponse.data.partialPaidSummaryList.map((data) => {
              let partialObj = {
                Name: data.student_name,
                Class: data.class,
                Section: data.section,
                "Total Fees":
                  +data.fees_metadata.canteen_total +
                  +data.fees_metadata.tution_total +
                  +data.fees_metadata.transport_total,
                "Transport Total": data.transport_fees_summary.total,
                "Transport Paid": data.transport_fees_summary.paid,
                "Transport Pending": data.transport_fees_summary.pending,
                "Canteen Total": data.canteen_fees_summary.total,
                "Canteen Paid": data.canteen_fees_summary.paid,
                "Canteen Pending": data.canteen_fees_summary.pending,
                "Tution Total ": data.tution_fees_summary.total,
                "Tution Paid": data.tution_fees_summary.paid,
                "Tution Pending": data.tution_fees_summary.pending,
                "Penalty Total": data.penalty_fees_summary.total,
                "Penalty Paid": data.penalty_fees_summary.paid,
                "Penalty Pending": data.penalty_fees_summary.pending,
                Status:
                  data.tution_fees_summary.pending == 0 &&
                  data.canteen_fees_summary.pending == 0 &&
                  data.transport_fees_summary.pending == 0
                    ? "Paid"
                    : "Unpaid",
              };
              partialArray.push(partialObj);
              setPartialFeeDownload(partialArray);
            });
          const fileName = `SMU-Fees-Statistics-${classs} ${section} with Penalty`;
          exportToCSV2(partialArray, fileName);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const ConcessionFeeDataRecord = async () => {
    if (disable == true) {
      const payload = {
        class: classs,
        section: section,
      };
      const concessionResponse = await ConcessionFeeDetails(payload);
      if (concessionResponse) {
        let concessionArray = [];
        concessionResponse.data &&
          concessionResponse.data.map((data) => {
            let concenssionObj = {
              Name: data.student_name,
              Class: data.class,
              Section: data.section,
              "Total Fees":
                +data.fees_metadata.canteen_total +
                +data.fees_metadata.tution_total +
                +data.fees_metadata.transport_total,
              "Transport Total": data.transport_fees_summary.total,
              "Transport Paid": data.transport_fees_summary.paid,
              "Transport Pending": data.transport_fees_summary.pending,
              "Canteen Total": data.canteen_fees_summary.total,
              "Canteen Paid": data.canteen_fees_summary.paid,
              "Canteen Pending": data.canteen_fees_summary.pending,
              "Tution Total ": data.tution_fees_summary.total,
              "Tution Paid": data.tution_fees_summary.paid,
              "Tution Pending": data.tution_fees_summary.pending,
              Status:
                data.tution_fees_summary.pending == 0 &&
                data.canteen_fees_summary.pending == 0 &&
                data.transport_fees_summary.pending == 0
                  ? "Paid"
                  : "Unpaid",
            };
            concessionArray.push(concenssionObj);
            setConcessionFeeDownload(concessionArray);
          });
        const fileName = `SMU-Fees-Statistics-${classs} ${section}`;
        exportToCSV2(concessionArray, fileName);
      }
    } else {
      const payload = {
        class: classs,
        section: section,
        include_penalty_data: true,
      };
      try {
        const concessionResponse = await ConcessionFeeDetails(payload);
        if (concessionResponse) {
          let concessionArray = [];
          concessionResponse.data &&
            concessionResponse.data.map((data) => {
              let concenssionObj = {
                Name: data.student_name,
                Class: data.class,
                Section: data.section,
                "Total Fees":
                  +data.fees_metadata.canteen_total +
                  +data.fees_metadata.tution_total +
                  +data.fees_metadata.transport_total,
                "Transport Total": data.transport_fees_summary.total,
                "Transport Paid": data.transport_fees_summary.paid,
                "Transport Pending": data.transport_fees_summary.pending,
                "Canteen Total": data.canteen_fees_summary.total,
                "Canteen Paid": data.canteen_fees_summary.paid,
                "Canteen Pending": data.canteen_fees_summary.pending,
                "Tution Total ": data.tution_fees_summary.total,
                "Tution Paid": data.tution_fees_summary.paid,
                "Tution Pending": data.tution_fees_summary.pending,
                "Penalty Total": data.penalty_fees_summary.total,
                "Penalty Paid": data.penalty_fees_summary.paid,
                "Penalty Pending": data.penalty_fees_summary.pending,
                Status:
                  data.tution_fees_summary.pending == 0 &&
                  data.canteen_fees_summary.pending == 0 &&
                  data.transport_fees_summary.pending == 0
                    ? "Paid"
                    : "Unpaid",
              };
              concessionArray.push(concenssionObj);
              setConcessionFeeDownload(concessionArray);
            });
          const fileName = `SMU-Fees-Statistics-${classs} ${section} with Penalty`;
          exportToCSV2(concessionArray, fileName);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const PartialPaidFeeDataRecord = async () => {
    if (disable == true) {
      const payload = {
        class: classs,
        section: section,
      };
      try {
        const partialResponse = await partialPaidFeeDetails(payload);
        if (partialResponse) {
          let partialArray = [];
          partialResponse.data &&
            partialResponse.data.partialPaidSummaryList.map((data) => {
              let partialObj = {
                Name: data.student_name,
                Class: data.class,
                Section: data.section,
                "Total Fees":
                  +data.fees_metadata.canteen_total +
                  +data.fees_metadata.tution_total +
                  +data.fees_metadata.transport_total,
                "Transport Total": data.transport_fees_summary.total,
                "Transport Paid": data.transport_fees_summary.paid,
                "Transport Pending": data.transport_fees_summary.pending,
                "Canteen Total": data.canteen_fees_summary.total,
                "Canteen Paid": data.canteen_fees_summary.paid,
                "Canteen Pending": data.canteen_fees_summary.pending,
                "Tution Total ": data.tution_fees_summary.total,
                "Tution Paid": data.tution_fees_summary.paid,
                "Tution Pending": data.tution_fees_summary.pending,
                Status:
                  data.tution_fees_summary.pending == 0 &&
                  data.canteen_fees_summary.pending == 0 &&
                  data.transport_fees_summary.pending == 0
                    ? "Paid"
                    : "Unpaid",
              };
              partialArray.push(partialObj);
              setPartialFeeDownload(partialArray);
            });
          const fileName = `SMU-Fees-Statistics-${classs} ${section}`;
          exportToCSV2(partialArray, fileName);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      const payload = {
        class: classs,
        section: section,
        include_penalty_data: true,
      };
      try {
        const partialResponse = await partialPaidFeeDetails(payload);
        if (partialResponse) {
          let partialArray = [];
          partialResponse.data &&
            partialResponse.data.partialPaidSummaryList.map((data) => {
              let partialObj = {
                Name: data.student_name,
                Class: data.class,
                Section: data.section,
                "Total Fees":
                  +data.fees_metadata.canteen_total +
                  +data.fees_metadata.tution_total +
                  +data.fees_metadata.transport_total,
                "Transport Total": data.transport_fees_summary.total,
                "Transport Paid": data.transport_fees_summary.paid,
                "Transport Pending": data.transport_fees_summary.pending,
                "Canteen Total": data.canteen_fees_summary.total,
                "Canteen Paid": data.canteen_fees_summary.paid,
                "Canteen Pending": data.canteen_fees_summary.pending,
                "Tution Total ": data.tution_fees_summary.total,
                "Tution Paid": data.tution_fees_summary.paid,
                "Tution Pending": data.tution_fees_summary.pending,
                "Penalty Total": data.penalty_fees_summary.total,
                "Penalty Paid": data.penalty_fees_summary.paid,
                "Penalty Pending": data.penalty_fees_summary.pending,
                Status:
                  data.tution_fees_summary.pending == 0 &&
                  data.canteen_fees_summary.pending == 0 &&
                  data.transport_fees_summary.pending == 0
                    ? "Paid"
                    : "Unpaid",
              };
              partialArray.push(partialObj);
              setPartialFeeDownload(partialArray);
            });
          const fileName = `SMU-Fees-Statistics-${classs} ${section} with Penalty`;
          exportToCSV2(partialArray, fileName);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  useEffect(() => {
    if (classs && section) {
      // allFeeStatisticsReport();
      // PaidFeeDataDownload();
      // ConcessionFeeDataRecord();
      // PartialPaidFeeDataRecord();
    }
  }, [classs, section, disable]);

  const fileName =
    disable == true
      ? `SMU-Fees-Statistics-${classs} ${section}`
      : `SMU-Fees-Statistics-${classs} ${section} with Penalty`;

  // inputFields.class && inputFields.section
  //   ? `smu-student-class-${inputFields.class}-section-${inputFields.section}`
  //   : "smu-students";
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleSearchClick = () => {
    if (searchQuery !== "") {
      localStorage.setItem("searchQuery", searchQuery);
      fetchFeeDetails();
      setShowSearchIcon(false);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleCloseIconClick = () => {
    setSearchQuery("");
    localStorage.removeItem("searchQuery");
    fetchFeeDetails();
    setShowSearchIcon(true);
  };
  useEffect(() => {
    const storedSearchQuery = localStorage.getItem("searchQuery");
    if (storedSearchQuery && storedSearchQuery.trim() !== "") {
      setSearchQuery(storedSearchQuery);
      setShowSearchIcon(false);
    }
  }, []);
  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    selectClass(selectedClass);
    localStorage.setItem("selectedClass", selectedClass);
  };

  const handleSectionChange = (e) => {
    const selectedSection = e.target.value;
    selectSection(selectedSection);
    localStorage.setItem("selectedSection", selectedSection);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerWrapper}>
        <div>
          <p className={Styles.headerHeading}>
            Fee Details | Total Students ({feeDetails && feeDetails.length})
          </p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.filterWrapperWithLine}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  variant="filled"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowSearchIcon(true);
                    } else {
                      setShowSearchIcon(false);
                    }
                  }}
                  className={Styles.search}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              className={Styles.RemovePlaceHolder}
              // onChange={(e) => selectClass(e.target.value)}
              onChange={handleClassChange}
              name="classs"
              value={classs}
              placeholder={classs ? "Select Class" : classs}
            >
              {gradesList &&
                gradesList.map((item) => (
                  <>
                    <option value={item.class}>Grade {item.class}</option>
                  </>
                ))}
            </Select>
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              // onChange={(e) => selectSection(e.target.value)}
              onChange={handleSectionChange}
              name="section"
              value={section}
              placeholder="
         Select Section"
            >
              {gradesList &&
                gradesList
                  .filter((item) => item.class == classs)
                  .map((item) =>
                    item.sections.map((i) => (
                      <>
                        <option value={i}>Section {i}</option>
                      </>
                    ))
                  )}
            </Select>
          </div>
          {classs && section && feeDetails.length ? (
            <>
              <div className={Styles.filterWrapper}>
                <div className={Styles.buttonRow}>
                  <Tooltip label="Update Tution Fees">
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        getTutionFees();
                        updateTutionFees.onOpen();
                      }}
                    >
                      Update Tution Fees
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className={Styles.filterWrapper}>
                <div className={Styles.buttonRow} onClick={onOpen}>
                  <Tooltip label="Fee Details Download">
                    <Button colorScheme="green">Archive</Button>
                  </Tooltip>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={Styles.body}>
        {feeDetails.length ? (
          <div className={Styles.lists}>
            {feeDetails &&
              feeDetails.map((fee) => (
                <Link to={fee.student_id} className={Styles.list}>
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={
                        fee.profile_url != "N/A" ? fee.profile_url : defaultUser
                      }
                      alt="profile"
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>
                      {truncateText(fee.student_name, 15)}
                    </p>
                    <p className={Styles.class}>
                      {fee.class} | {fee.section}
                    </p>
                    <p className={Styles.number}>
                      ₹ {fee.pending} pending fees
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        ) : (
          <div className={Styles.noResult}>No Results</div>
        )}
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div>
              Download Fees Report
              <div style={{ display: "flex", marginTop: "5px" }}>
                <p style={{ fontSize: "15px" }}>Include Penalty :</p>

                <Switch
                  onChange={handleinputDisabled}
                  style={{ marginLeft: "10px", marginTop: "3px" }}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.formCheck} style={{ width: "100%" }}>
              <div className={Styles.checkboxes}>
                <div className={Styles.buttonBox}>
                  <button
                    onClick={(e) => {
                      allFeeStatisticsReport();
                      onClose();
                    }}
                  >
                    Overall Fee Report
                  </button>
                </div>
                <div className={Styles.buttonBox}>
                  <button
                    onClick={(e) => {
                      PaidFeeDataDownload();
                      onClose();
                    }}
                  >
                    Fully-Paid Fee Report
                  </button>
                </div>
                <div className={Styles.buttonBox}>
                  <button
                    onClick={(e) => {
                      PartialPaidFeeDataRecord();
                      onClose();
                    }}
                  >
                    Partial-Paid Fee Report
                  </button>
                </div>
                <div className={Styles.buttonBox}>
                  <button
                    onClick={(e) => {
                      ConcessionFeeDataRecord();
                      onClose();
                    }}
                  >
                    Concession Fee Report
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={updateTutionFees.isOpen}
        onClose={updateTutionFees.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Canteen Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Tution Fees (INR) :</p>
            <div className={Styles.filterWrapper2}>
              <Input
                variant="filled"
                value={`Tution fee ${
                  feesMaster.tution_fees ? "- " + feesMaster.tution_fees : ""
                }`}
                disabled
              />
            </div>
            <p>Update Tution Fees (INR) :</p>
            <div className={Styles.filterWrapper2}>
              <Input
                variant="filled"
                value={inputFields ? inputFields.tution_fees : ""}
                onChange={(e) => handleInputChange(e, "tution_fees")}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vw",
              }}
            >
              <Button
                style={{ backgroundColor: "#990f0d", color: "white" }}
                onClick={() => handleUpdateFeesMaster()}
              >
                Update
              </Button>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" onClick={()=>updateTutionFees.onClose()}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManagementSchoolFee;

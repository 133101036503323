import axios from "axios";
import { getRequestOptions, BASE_URL, isInvalid } from "services/utils";
import { _createUserWithEmailAndPassword as createUserWithEmailAndPassword } from "services/firebase.service";

export async function requestCreateUser(payload = {}) {
  const { email, name, father, role } = payload;
  const { father_name, father_phone } = father || {};

  if (role === "teacher") {
    if (isInvalid([email, name, role])) {
      return { type: "validation", error: "Field missing" };
    }
  }

  if (role === "student") {
    if (isInvalid([email, name, father_phone, father_name, role])) {
      return { type: "validation", error: "Field missing" };
    }
  }

  if (role === "admin") {
    if (isInvalid([email, name, role])) {
      return { type: "validation", error: "Field missing" };
    }
  }
  if (role === "management") {
    if (isInvalid([email, name, role])) {
      return { type: "validation", error: "Field missing" };
    }
  }

  const url = BASE_URL + "/auth/create";

  try {
    const resp = await createUserWithEmailAndPassword(payload);
    console.log("response firebase", resp)
    if (resp) {
      const response = await axios.post(
        url,
        { ...payload },
        getRequestOptions()
      );
      if (response.data?.error) {
        return { error: response.data.error };
      }

      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } else {
      return { error: "Error while creating firebase profile" };
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUploadStudentImages(payload = {}) {
  const {
    studentId,
    student_photo_file,
    father_photo_file,
    mother_photo_file,
    phone,
  } = payload;

  if (isInvalid([student_photo_file, father_photo_file, mother_photo_file])) {
    return { error: "Field missing" };
  }

  // const url = BASE_URL + '/general/upload/student/' + studentId
  const url =
    BASE_URL +
    "/general/upload/student?username=" +
    studentId +
    "&phoneNumber=" +
    phone;

  const form = new FormData();
  form.append("student", student_photo_file);
  form.append("mother", mother_photo_file);
  form.append("father", father_photo_file);

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUploadEvents(payload = {}) {
  const { title, file } = payload;

  if (isInvalid([title, file])) {
    return { error: "Field missing" };
  }

  // const url = BASE_URL + '/general/upload/student/' + studentId
  const url = BASE_URL + "/general/upload/event?title=" + title;

  const form = new FormData();
  file.forEach((element) => {
    form.append("files", element);
  });

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUploadCirculars(payload = {}) {
  const { title, file } = payload;

  if (isInvalid([title, file])) {
    return { error: "Field missing" };
  }

  // const url = BASE_URL + '/general/upload/student/' + studentId
  const url = BASE_URL + "/general/upload/circuilar?title=" + title;

  const form = new FormData();
  file.forEach((element) => {
    form.append("files", element);
  });

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUploadNotification(payload = {}) {
  const { title, file } = payload;

  if (isInvalid([title, file])) {
    return { error: "Field missing" };
  }

  // const url = BASE_URL + '/general/upload/student/' + studentId
  const url = BASE_URL + "/general/upload/notification?userid=" + title;

  const form = new FormData();
  file.forEach((element) => {
    form.append("files", element);
  });

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUploadHelpImages(payload = {}) {
  const { moduleName, stepName, file } = payload;

  if (isInvalid([moduleName, stepName, file])) {
    return { error: "Field missing" };
  }

  const url = `${BASE_URL}/general/upload/help?module=${moduleName}&step=${stepName}`;

  const form = new FormData();
  file.forEach((element) => {
    form.append("files", element);
  });

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUploadTeacherImages(payload = {}) {
  const { teacherId, profile_file, EMP_ID } = payload;

  if (isInvalid([profile_file])) {
    return { error: "Field missing" };
  }

  const url =
    BASE_URL +
    "/general/upload/teacher/?username=" +
    teacherId +
    "&emp_id=" +
    EMP_ID;

  const form = new FormData();
  form.append("teacher", profile_file);

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUploadManagementImages(payload = {}) {
  const { managementId, profile_file, EMP_ID } = payload;

  if (isInvalid([profile_file])) {
    return { error: "Field missing" };
  }

  const url =
    BASE_URL +
    "/general/upload/management/?username=" +
    managementId +
    "&emp_id=" +
    EMP_ID;

  const form = new FormData();
  form.append("management", profile_file);

  try {
    const response = await axios.post(
      url,
      form,
      getRequestOptions("multipart/form-data")
    );

    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestClassesAndSection(payload = {}) {
  const url = BASE_URL + "/general/getclassAndsection";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSearchStudentByFilterCommon(payload = {}) {
  const url = BASE_URL + "/general/searchStudentWithFilter";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSearchTeacherByFilterCommon(payload = {}) {
  const url = BASE_URL + "/admin/teacher/search-teacher";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    console.log("error", e);
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestCheckValidNumber(payload) {
  const { mobile, country_code } = payload;
  const access_key = "b976193c47e92cce27937aa79076e7ec";
  const format = 1;
  const url = `http://apilayer.net/api/validate?access_key=${access_key}&number=${mobile}&country_code=${country_code}&format=${format}`;

  try {
    const response = await axios.post(url);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    console.log("error", e);
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAllNotifications(payload = {}) {
  const url = BASE_URL + "/general/notification/getAll";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e?.response.status === 401) {
      throw e.response.status;
    }
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestSingleNotificationDetail(payload = {}) {
  const url = BASE_URL + "/general/notification/getOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
const Services = {
  requestCreateUser,
  requestUploadTeacherImages,
  requestUploadStudentImages,
  requestUploadManagementImages,
  requestClassesAndSection,
  requestUploadCirculars,
  requestUploadEvents,
  requestUploadNotification,
  requestSearchStudentByFilterCommon,
  requestSearchTeacherByFilterCommon,
  requestCheckValidNumber,
  requestUploadHelpImages,
  requestAllNotifications,
  requestSingleNotificationDetail,
};

export default Services;

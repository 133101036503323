import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Select,
  Input,
  Spinner,
  Tooltip,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import {
  AiOutlinePlusSquare,
  AiFillLock,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import {
  requestSearchStudentByFilter,
  requestSearchStudentDetails,
} from "services/admin-dashboard/student.service";
import { debounce } from "utilities/utils";
import { useClassAndSectionsStudent } from "hooks/classAndSection";
import Styles from "./Students.module.scss";
import defaultUser from "../../../assets/icons/User.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import moment from "moment";
const Students = () => {
  const { classAndSections, loading, error } = useClassAndSectionsStudent();
  const downloadModal = useDisclosure();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [data, setData] = useState([]);
  const [stuCount, setStuCount] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const initialFieldsState = {
    admissionNumber: false,
    admissionYear: false,
    name: true,
    class: true,
    section: true,
    email: true,
    gender: true,
    fatherName: true,
    fatherPhone: true,
    fatherQualification: false,
    fatherOccupation: false,
    motherName: true,
    motherPhone: true,
    motherQualification: false,
    motherOccupation: false,
    dob: true,
    nationality: false,
    motherTongue: false,
    religion: false,
    subReligion: false,
    bloodGroup: true,
    address: false,
    aadhaarNo: false,
    electiveSubject: true,
    canteen: false,
    bus: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [showDetails, setShowDetails] = useState({
    admission: true,
    personal: false,
    father: false,
    mother: false,
    other: false,
    academic: false,
  });
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [showDelayedSpinner, setShowDelayedSpinner] = useState(false);
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);

    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);

  // useEffect(() => {
  //   if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
  //     localStorage.setItem("student_filter", JSON.stringify(inputFields));
  //     searchStudentByQuery();
  //   }
  // }, [inputFields.class, inputFields.section]);
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;
  const totalStudentsCount = studentList.length;
  const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);

  useEffect(() => {
    let timeoutId;
    const debouncedFetchStudents = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (isQueried) {
          localStorage.setItem("student_filter", JSON.stringify(inputFields));
          fetchStudents();
        }
      }, 2000);
    };
    debouncedFetchStudents();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputFields.class, inputFields.section]);

  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setShowDelayedSpinner(true);
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      setStuCount(response);
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      // if (e === 403) {
      //   setErrorMessage(e);
      // }
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    } finally {
      setRequestInProgress(false);
      setTimeout(() => {
        setShowDelayedSpinner(false);
      }, 2000);
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      setStuCount(response);
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const maleCount =
    Array.isArray(stuCount) &&
    stuCount.reduce((count, student) => {
      if (
        student.personel.gender === "Male" ||
        student.personel.gender === "male"
      ) {
        return count + 1;
      }
      return count;
    }, 0);

  const femaleCount =
    Array.isArray(stuCount) &&
    stuCount.reduce((count, student) => {
      if (
        student.personel.gender === "Female" ||
        student.personel.gender === "female"
      ) {
        return count + 1;
      }
      return count;
    }, 0);

  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  useEffect(() => {
    if (error === 403) {
      setRequestInProgress(false);
      setErrorMessage(error);
    }
  }, [error]);
  // if (loading) {
  //   return (
  //     <div className={Styles.loaderWrapper}>
  //       <Spinner />
  //     </div>
  //   );
  // }
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-Student-${inputFields.class}-${inputFields.section}`
      : "SMU-Students";

  const ExportToExcel = () => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        <Tooltip label="Download Student Details">
          <Button colorScheme="green">Archive</Button>
        </Tooltip>
      </div>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestSearchStudentDetails(payload);
    setStuCount(res);
    if (res) {
      let newArray = [];
      res.map((item) => {
        let obj = {};
        if (fields.admissionNumber) {
          obj["Admission ID"] = handleNAValue(item.academic.admission_number);
        }
        if (fields.admissionYear) {
          obj["Admission Year"] = handleNAValue(item.academic.admission_year);
        }
        if (fields.class) {
          obj["Class"] = handleNAValue(item.academic.class);
        }
        if (fields.section) {
          obj["Section"] = handleNAValue(item.academic.section);
        }
        if (fields.name) {
          obj["Name"] = handleNAValue(item.name);
        }
        if (fields.email) {
          obj["Email"] = handleNAValue(item.email);
        }
        if (fields.gender) {
          obj["Gender"] = handleNAValue(item.personel.gender);
        }
        if (fields.aadhaarNo) {
          obj["Aadhaar No."] = handleNAValue(item.personel.aadhar_number);
        }
        if (fields.address) {
          obj["Address"] = handleNAValue(item.personel.present_address);
        }
        if (fields.bloodGroup) {
          obj["Blood Group"] = handleNAValue(item.personel.blood_group);
        }
        if (fields.dob) {
          obj["DOB"] = handleNAValue(
            moment(item.personel.dob, "MM/DD/YYYY").format("DD/MM/YYYY")
          );
        }
        if (fields.nationality) {
          obj["Nationality"] = handleNAValue(item.personel.nationality);
        }
        if (fields.motherTongue) {
          obj["Mother Tongue"] = handleNAValue(item.personel.mother_tongue);
        }
        if (fields.religion) {
          obj["Religion"] = handleNAValue(item.personel.religion);
        }
        if (fields.subReligion) {
          obj["Sub-Religion"] = handleNAValue(item.personel.sub_religion);
        }
        if (fields.fatherName) {
          obj["Father Name"] = handleNAValue(item.father.father_name);
        }
        if (fields.fatherPhone) {
          obj["Father Phone No."] = handleNAValue(item.father.father_phone);
        }
        if (fields.fatherQualification) {
          obj["Father Qualification"] = handleNAValue(
            item.father.father_qualification
          );
        }
        if (fields.fatherOccupation) {
          obj["Father Occupation"] = handleNAValue(
            item.father.father_occupation
          );
        }
        if (fields.motherName) {
          obj["Mother Name"] = handleNAValue(item.mother.mother_name);
        }
        if (fields.motherPhone) {
          obj["Mother Phone No."] = handleNAValue(item.mother.mother_phone);
        }
        if (fields.motherQualification) {
          obj["Mother Qualification"] = handleNAValue(
            item.mother.mother_qualification
          );
        }
        if (fields.motherOccupation) {
          obj["Mother Occupation"] = handleNAValue(
            item.mother.mother_occupation
          );
        }
        if (fields.canteen) {
          if (item.canteen_choices && item.canteen_choices.length > 0) {
            const canteenChoice = item.canteen_choices[0];
            if (canteenChoice.opted_value) {
              obj["Canteen"] = "Opted";
            } else {
              obj["Canteen"] = "Not Opted";
            }
          } else {
            obj["Canteen"] = "Not Opted";
          }
        }
        if (fields.bus) {
          if (item.transport_choices && item.transport_choices.length > 0) {
            const busChoice = item.transport_choices[0];
            if (busChoice.opted_value) {
              obj["Bus"] = "Opted";
            } else {
              obj["Bus"] = "Not Opted";
            }
          } else {
            obj["Bus"] = "Not Opted";
          }
        }
        if (fields.electiveSubject) {
          const electiveSubjects = {};
          item.subject_choices.forEach((choice) => {
            electiveSubjects[`Elective - ${choice.elective_name}`] =
              choice.chosen_subject === "N/A" ? "-" : choice.chosen_subject;
          });
          newArray.push({ ...obj, ...electiveSubjects });
        } else {
          newArray.push({ ...obj });
        }
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      admission: true,
      personal: false,
      father: false,
      mother: false,
      other: false,
      academic: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.wrapper}>
      {(loading || showDelayedSpinner) && !error ? (
        <div className={Styles.loaderWrapper}>
          <Spinner />
        </div>
      ) : errorMessage ? (
        <div className={Styles.errorContainer}>
          <div className={Styles.errorSubContainer}>
            <div className={Styles.errorCodeContainer}>
              <AiFillLock style={{ fontSize: "30px", color: "white" }} />
            </div>
            <p className={Styles.errorText}>Message :</p>
            <p className={Styles.errorText2}>
              You don't have permission to view the Student Module.
            </p>
            <div>
              <Link to="/admin/dashboard">
                <Button colorScheme="blue" variant="solid" m={2}>
                  Go to Dashboard Page
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={Styles.wrapper}>
          <div className={Styles.headerFirst}>
            <div className={Styles.header}>
              <div>
                <div className={Styles.statsWrapper}>
                  <p className={Styles.stats}>STUDENTS |</p>
                  <div className={Styles.iconMale}>
                    <IoMdMale />
                  </div>
                  <p className={Styles.stats}>Boys -{maleCount || 0}</p>
                  <div
                    className={Styles.iconFemale}
                    style={{ marginLeft: "10px" }}
                  >
                    <IoMdFemale />
                  </div>
                  <p className={Styles.stats}>Girls -{femaleCount || 0}</p>
                </div>
              </div>
            </div>
            <div className={Styles.headerFilterWrapper}>
              <div className={Styles.filterWrapper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <Input
                      name="studentName"
                      variant="filled"
                      placeholder="Search"
                      value={inputFields.studentName}
                      onChange={handleInputChange}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      paddingRight="3rem"
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={Styles.filterWrapper}>
                <Select
                  value={inputFields.class ? inputFields.class : ""}
                  onChange={handleInputChange}
                  name="class"
                  placeholder="Select Grade"
                >
                  {classAndSections.map((grade, idx) => (
                    <option key={grade.class + idx} value={grade.class}>
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  value={inputFields.section ? inputFields.section : ""}
                  onChange={handleInputChange}
                  name="section"
                  placeholder="Select Section"
                >
                  {sections
                    ? sections.map((section, idx) => (
                        <option key={section + idx} value={section}>
                          Section {section}
                        </option>
                      ))
                    : ""}
                </Select>
              </div>
              <div className={Styles.optionWrapper}>
                <Tooltip label="Add Student">
                  <div className={Styles.addButton}>
                    <Link to="add">
                      <Button colorScheme="teal">Create</Button>
                    </Link>
                  </div>
                </Tooltip>
                {studentList.length >= 1 ? (
                  <ExportToExcel apiData={data} fileName={fileName} />
                ) : null}
              </div>
            </div>
          </div>

          <div className={Styles.body}>
            <div className={Styles.lists}>
              {requestInProgress ? (
                <div className={Styles.loaderWrapper}>
                  <Spinner />
                </div>
              ) : studentList.length === 0 ? (
                <div className={Styles.noResult}>No Result Found</div>
              ) : (
                studentList.map((student, idx) => (
                  <Link
                    to={student._id}
                    key={student["_id"] + idx}
                    className={Styles.listing}
                  >
                    <div
                      style={{ alignSelf: "end", fontSize: 20, color: "red" }}
                    >
                      {student.personel.gender === "Female" ||
                      student.personel.gender === "female" ? (
                        <IoMdFemale />
                      ) : null}
                    </div>
                    <div
                      style={{
                        alignSelf: "end",
                        fontSize: 20,
                        color: "blue",
                      }}
                    >
                      {student.personel.gender === "Male" ||
                      student.personel.gender === "male" ? (
                        <IoMdMale />
                      ) : null}
                    </div>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.url != "N/A" ? student.url : defaultUser}
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>
                          {truncateText(student?.name, 15)}
                        </p>
                        <p className={Styles.class}>
                          {(student?.academic?.class &&
                            student?.academic?.class) ||
                            "--"}{" "}
                          | {student.academic?.section?.toUpperCase() || "--"}
                        </p>
                        <p className={Styles.number}>
                          +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
            {showTopBtn && (
              <div
                onClick={scrollToTop}
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                  backgroundColor: "#990f0d",
                  borderRadius: "20px",
                  display: " flex",
                  alignItems: "flex-end",
                }}
              >
                <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
              </div>
            )}
          </div>
          <Modal
            isOpen={downloadModal.isOpen}
            onClose={() => {
              downloadModal.onClose();
              setFields(initialFieldsState);
              setShowDetails((prevDetails) => ({
                admission: true,
                personal: false,
                father: false,
                mother: false,
                other: false,
                academic: false,
              }));
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Please check the fields that you want to include in Downloading
                File
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <>
                  <form>
                    <div className={Styles.headerContainer}>
                      <button type="button" className={Styles.heading}>
                        Admission Details
                      </button>
                      {showDetails.admission ? null : (
                        <AiOutlinePlusSquare
                          className={Styles.icons}
                          onClick={() => toggleSection("admission")}
                        />
                      )}
                    </div>
                    {showDetails.admission && (
                      <div className={Styles.row}>
                        <Checkbox
                          isChecked={fields.admissionNumber}
                          onChange={() => {
                            setFields({
                              ...fields,
                              admissionNumber: !fields.admissionNumber,
                            });
                          }}
                        >
                          Admission ID
                        </Checkbox>
                        <Checkbox
                          isChecked={fields.admissionYear}
                          onChange={() => {
                            setFields({
                              ...fields,
                              admissionYear: !fields.admissionYear,
                            });
                          }}
                        >
                          Admission Year
                        </Checkbox>
                      </div>
                    )}
                    <div className={Styles.headerContainer}>
                      <button type="button" className={Styles.heading}>
                        Student Details
                      </button>
                      {showDetails.personal ? null : (
                        <AiOutlinePlusSquare
                          className={Styles.icons}
                          onClick={() => toggleSection("personal")}
                        />
                      )}
                    </div>
                    {showDetails.personal && (
                      <>
                        <div className={Styles.row}>
                          <Checkbox
                            isChecked={fields.email}
                            onChange={() => {
                              setFields({ ...fields, email: !fields.email });
                            }}
                          >
                            Email
                          </Checkbox>
                          <Checkbox
                            isChecked={fields.aadhaarNo}
                            onChange={() => {
                              setFields({
                                ...fields,
                                aadhaarNo: !fields.aadhaarNo,
                              });
                            }}
                          >
                            Aadhaar No.
                          </Checkbox>
                          <Checkbox
                            isChecked={fields.address}
                            onChange={() => {
                              setFields({
                                ...fields,
                                address: !fields.address,
                              });
                            }}
                          >
                            Address
                          </Checkbox>
                        </div>
                        <div className={Styles.row}>
                          <Checkbox
                            isChecked={fields.dob}
                            onChange={() => {
                              setFields({
                                ...fields,
                                dob: !fields.dob,
                              });
                            }}
                          >
                            DOB
                          </Checkbox>
                          <Checkbox
                            isChecked={fields.nationality}
                            onChange={() => {
                              setFields({
                                ...fields,
                                nationality: !fields.nationality,
                              });
                            }}
                          >
                            Nationality
                          </Checkbox>
                          <Checkbox
                            isChecked={fields.motherTongue}
                            onChange={() => {
                              setFields({
                                ...fields,
                                motherTongue: !fields.motherTongue,
                              });
                            }}
                          >
                            Mother Tongue
                          </Checkbox>
                        </div>
                        <div className={Styles.row}>
                          <Checkbox
                            isChecked={fields.religion}
                            onChange={() => {
                              setFields({
                                ...fields,
                                religion: !fields.religion,
                              });
                            }}
                          >
                            Religion
                          </Checkbox>
                          <Checkbox
                            isChecked={fields.subReligion}
                            onChange={() => {
                              setFields({
                                ...fields,
                                subReligion: !fields.subReligion,
                              });
                            }}
                          >
                            Sub-Religion
                          </Checkbox>
                        </div>
                      </>
                    )}
                    <div className={Styles.headerContainer}>
                      <button type="button" className={Styles.heading}>
                        Father Details
                      </button>
                      {showDetails.father ? null : (
                        <AiOutlinePlusSquare
                          className={Styles.icons}
                          onClick={() => toggleSection("father")}
                        />
                      )}
                    </div>
                    {showDetails.father && (
                      <div className={Styles.row}>
                        <Checkbox
                          isChecked={fields.fatherQualification}
                          onChange={() => {
                            setFields({
                              ...fields,
                              fatherQualification: !fields.fatherQualification,
                            });
                          }}
                        >
                          Qualification
                        </Checkbox>
                        <Checkbox
                          isChecked={fields.fatherOccupation}
                          onChange={() => {
                            setFields({
                              ...fields,
                              fatherOccupation: !fields.fatherOccupation,
                            });
                          }}
                        >
                          Occupation
                        </Checkbox>
                      </div>
                    )}
                    <div className={Styles.headerContainer}>
                      <button type="button" className={Styles.heading}>
                        Mother Details
                      </button>
                      {showDetails.mother ? null : (
                        <AiOutlinePlusSquare
                          className={Styles.icons}
                          onClick={() => toggleSection("mother")}
                        />
                      )}
                    </div>
                    {showDetails.mother && (
                      <div className={Styles.row}>
                        <Checkbox
                          isChecked={fields.motherQualification}
                          onChange={() => {
                            setFields({
                              ...fields,
                              motherQualification: !fields.motherQualification,
                            });
                          }}
                        >
                          Qualification
                        </Checkbox>
                        <Checkbox
                          isChecked={fields.motherOccupation}
                          onChange={() => {
                            setFields({
                              ...fields,
                              motherOccupation: !fields.motherOccupation,
                            });
                          }}
                        >
                          Occupation
                        </Checkbox>
                      </div>
                    )}
                    <div className={Styles.headerContainer}>
                      <button type="button" className={Styles.heading}>
                        Academic Details
                      </button>
                      {showDetails.academic ? null : (
                        <AiOutlinePlusSquare
                          className={Styles.icons}
                          onClick={() => toggleSection("academic")}
                        />
                      )}
                    </div>
                    {showDetails.academic && (
                      <div className={Styles.row}>
                        <Checkbox
                          isChecked={fields.canteen}
                          onChange={() => {
                            setFields({
                              ...fields,
                              canteen: !fields.canteen,
                            });
                          }}
                        >
                          Canteen
                        </Checkbox>
                        <Checkbox
                          isChecked={fields.bus}
                          onChange={() => {
                            setFields({
                              ...fields,
                              bus: !fields.bus,
                            });
                          }}
                        >
                          Bus
                        </Checkbox>
                        <Checkbox
                          isChecked={fields.electiveSubject}
                          onChange={() => {
                            setFields({
                              ...fields,
                              electiveSubject: !fields.electiveSubject,
                            });
                          }}
                        >
                          Elective
                        </Checkbox>
                      </div>
                    )}
                  </form>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginBottom: "5px",
                    }}
                  >
                    File Format
                  </p>
                  <div className={Styles.fileFormatContainer}>
                    <Checkbox
                      isChecked={selectedFileFormat === "xlsx"}
                      onChange={() => {
                        setSelectedFileFormat("xlsx");
                      }}
                    >
                      XLSX File
                    </Checkbox>
                    <Checkbox
                      ml={4}
                      isChecked={selectedFileFormat === "csv"}
                      onChange={() => {
                        setSelectedFileFormat("csv");
                      }}
                    >
                      CSV File
                    </Checkbox>
                  </div>
                </>
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleExportClick} colorScheme="green">
                  Archive
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Students;

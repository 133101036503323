//
import React, { useState, useEffect } from "react";
import Styles from "./Notification.module.scss";
import "./Notification.css";
import {
  Button,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import defaultUser from "../../../assets/icons/User.png";
import {
  requestSingleNotificationDetailAdmin,
} from "services/admin-dashboard/notification.service";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { requestUploadNotification } from "services/common.service";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

const ViewNotification = () => {
    const notificationId= useParams()
  const [NewInputFields, setNewInputFields] = useState({
    New_target_group: [],
    New_student_target_group: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [student, setStudent] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedManagement, setSelectedManagement] = useState("");
  const [inputFeilds, setInputFeilds] = useState({
    heading: "",
    Description: "",
  });
  const [requestInProgress, setRequestInProgress] = useState(false);
  const toast = useToast();
  const [selectedPerson, setSelectedPerson] = useState("");
  const navigate = useNavigate();
  const [classSearch, setSearchClass] = useState("");
  const [sectionValue, setSectionValues] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassExist, setSelectedClassExist] = useState(false);
  const [showClassDetails, setShowClassDetails] = useState("");
  const [excludedStudentId, setExcludedStudentId] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");
  const [selectedIncludeStaff, setSelectedIncludeStaff] = useState("");
  const [excludeStaffId, setExcludeStaffId] = useState("");
  const [image, setImage] = useState("");
  const [selectAllStudent, setSelectAllStudent] = useState("");
  const [selectAllTeacher, setSelectAllTeacher] = useState("");
  const [excludeStudentDetails,setExcludeStudentDetails] = useState("")
  const [excludeStaffDetails,setExculdeStaffDetails] = useState("")

  const viewSingleNotification = async()=>{
    try{
        const payload = {
            notification_id: notificationId.id,
          };
          const response = await requestSingleNotificationDetailAdmin(payload)
          if(response){
            const {title,description,students,staffs,class_values,staff_department,
                exclude_staffs,exclude_students} = response.notification
            setInputFeilds({
                heading:title,
                Description:description
            }) 
            setDepartmentValue(staff_department)
            setExculdeStaffDetails(exclude_staffs)
            setSelectedIncludeStaff(staffs)
            setImage(response.notification.media)
            setSelectedPerson(students)
            setShowClassDetails(class_values)
            setExcludeStudentDetails(exclude_students)
            setStudent(students)
            setSelected(students)
            setSelectedStaff(staffs)
            setSelectedAdmin()
          }
    }
    catch(err){
        console.log("err", err)
    }
  }
  useEffect(() => {
    if(notificationId.id){
        viewSingleNotification()
    }
  },[notificationId.id] );


  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className={Styles.stats}>NOTIFICATION</p>
        
        </div>
      
      </div>
      <div>
        {selectedPerson.length >= 1 ||
        selectedStaff.length >= 1 ||
        showClassDetails.length >= 1 ||
        departmentValue.length >= 1 ||
        selectedIncludeStaff.length >= 1 ? (
          <div className={Styles.lists2}>
          <div>
          {showClassDetails.length>=1?<p style={{paddingLeft:"2vw"}}>Grade</p>:""}
          <div className={Styles.listParts}>
          {showClassDetails &&
              showClassDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.info}>
                        <p className={Styles.name}>
                          class :{student.class || "--"} | Section :
                          {student.section}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
         
          </div>
            <div>
            {departmentValue.length>=1?<p style={{paddingLeft:"2vw"}}>Departments</p>:""}
            <div className={Styles.listParts}> {departmentValue &&
              departmentValue.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.info}>
                        <p className={Styles.name}>Department : {student}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}</div>
           
            </div>
            <div>
            {selectedPerson.length>=1?<p style={{paddingLeft:"2vw"}}>Students</p>:""}
            <div className={Styles.listParts}>
            {selectedPerson &&
              selectedPerson.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.profile_url != "N/A" ? student.profile_url : defaultUser}
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            </div>
            <div>
            {excludeStudentDetails.length>=1?<p style={{paddingLeft:"2vw"}}>Exclude Students</p>:""}
            <div className={Styles.listParts}>
            {excludeStudentDetails &&
              excludeStudentDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.profile_url != "N/A" ? student.profile_url : defaultUser}
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            </div>
            <div>
           {selectedIncludeStaff.length>=1?<p style={{paddingLeft:"2vw"}}>Staff</p>:""}
           <div className={Styles.listParts}>
           {selectedIncludeStaff &&
              selectedIncludeStaff.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.profile_url != "N/A" ? student.profile_url : defaultUser}
                          alt="Staff-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
           </div> 
            </div>
            <div>
           {excludeStaffDetails.length>=1?<p style={{paddingLeft:"2vw"}}>Exclude Staff</p>:""}
           <div className={Styles.listParts}>
           {excludeStaffDetails &&
            excludeStaffDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.profile_url != "N/A" ? student.profile_url : defaultUser}
                          alt="Staff-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
           </div> 
            
            </div>
          </div>
        ) : (
          <p className={Styles.selectedBox}>
            No select Student/Staff/Admin/Management found for this notification
          </p>
        )}
      </div>
      <div className={Styles.createBox}>
        <div className={Styles.inputContainer}>
          <p className={Styles.heading}>
            Heading<span className={Styles.asterisk}>*</span> :
          </p>
          <Input
            className={Styles.inputbox}
            placeholder="Enter Heading..."
            name="heading"
            value={inputFeilds.heading}
          />
        </div>
        <div className={Styles.inputContainer}>
          <p className={Styles.heading}>
            Description<span className={Styles.asterisk}>*</span> :
          </p>
          <Textarea
            className={Styles.inputbox}
            name="Description"
            placeholder="Enter Description..."
            value={inputFeilds.Description}
          />
        </div>
        <div className={Styles.coverImage}>
          <Carousel
            showThumbs={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    left: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.leftCircle}
                >
                  <AiFillLeftCircle />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    right: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.rightCircle}
                >
                  <AiFillRightCircle />
                </button>
              )
            }
            swipeable={true}
            emulateTouch={true}
          >
             {
                 image&&image.map((img)=>(
                  <>
                  <div style={{ border: "2px solid black" }}>
                  <img 
                      src={img}
                      className={Styles.carouselImg}
                      alt="attachment-img"
                     />
                  </div>
                  </>
                 ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;

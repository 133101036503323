import React, { useState } from "react";
import Styles from "./Help.module.scss";
import StaffExam from "../../../assets/helpModule/StaffExam.png";
import MarksEntry from "assets/helpModule/MarksEntry.png";
import ReportCard from "../../../assets/helpModule/ReportCard.png";

const ExamsHelp = () => {
  const [selectedStep, setSelectedStep] = useState(1);

  const handleStepClick = (step) => {
    setSelectedStep(step);
  };
  return (
    <div className={Styles.helpmenu}>
      <div className={Styles.wrap}>
        <div className={Styles.forWidthLeft}>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 1 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(1)}
          >
            <div className={Styles.stepNo}>1</div>
            <div className={Styles.stepTitle}>Select Marks Entry</div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 2 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(2)}
          >
            <div className={Styles.stepNo}>2</div>
            <div className={Styles.stepTitle}>
              Select subject and exam semester
            </div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 3 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(3)}
          >
            <div className={Styles.stepNo}>3</div>
            <div className={Styles.stepTitle}>Select Report Card</div>
          </div>
        </div>
        {selectedStep === 1 && (
          <div className={Styles.forWidthRight}>
            <img src={StaffExam} alt="StaffExam" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                In Exam & Assessment module press on Marks Entry for enter the
                marks.
              </p>
            </div>
          </div>
        )}
        {selectedStep === 2 && (
          <div className={Styles.forWidthRight}>
            <img src={MarksEntry} alt="MarksEntry" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you have to select the subject in which you have to enter
                the marks where grade and section is also there and then you
                have to select exam semester.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                After selecting them student list with serial no. and marks
                obtained column shown.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you have to see in the bottom left their is an save and edit
                button.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you have to click on edit option.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you are able to enter the marks and edit the marks, after
                enter the marks you have to click on save button.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now the entered or edit marks are reflecting in the report card.
              </p>
            </div>
          </div>
        )}

        {selectedStep === 3 && (
          <div className={Styles.forWidthRight}>
            <img src={ReportCard} alt="ReportCard" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                If you select report card shown in step 1 image.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Only that class report card will be visible for which you are
                the class teacher.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you have to select class and exam semester.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now the student list and subjects are shown.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now you have to click on Preview All button in bottom left to
                see the report card of all students.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Otherwise you can also see the selected students report card for
                that you have to select the students and press on Preview
                Selected Button.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamsHelp;

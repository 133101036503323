export const BOOK_LIST_INPUT_FIELDS = [
    { id: 'title', label: 'Book Name', placeholder: "Enter Book Name" },
    { id: 'category', label: 'Category', placeholder: "Enter Book Category", type: 'select' },
    { id: 'author', label: 'Author', placeholder: "Enter Author Name" },
    { id: 'availableCopy', label: 'Available Copies', placeholder: "Enter Available Copies", type: 'number' }
]

export const STUDENT_FIELDS_LIST = [
    { id: 'studentName', label: 'Student Name' },
    { id: 'studentGrade', label: 'Grade' },
    { id: 'title', label: 'Book Name' },
    { id: 'category', label: 'Book Category' },
    { id: 'issue_date', label: 'Issue Date' },
    { id: 'due_datae', label: 'Due Date' },
]

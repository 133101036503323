import React, { useEffect, useState } from "react";
import Styles from "./SMS.module.scss";
import {
  Input,
  Select,
  Spinner,
  Switch,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Modal,
  ModalFooter,
  Button,
  Tooltip,
  Box,
  Center,
} from "@chakra-ui/react";
import { requestSearchStudentDetails } from "services/admin-dashboard/student.service";
import { debounce } from "utilities/utils";
import { useClassAndSections } from "hooks/classAndSection";
import { useToast } from "@chakra-ui/react";
import defaultUser from "../../../assets/icons/User.png";
import ServicesAdmission from "services/admin-dashboard/admission.service.js";
import {
  requestListByTeacherDepartment,
  requestSearchTeacher,
} from "services/admin-dashboard/staff.service";
import ServicesSMS from "services/admin-dashboard/sms.service";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { requestSearchStudentByFilterCommon } from "services/common.service";
const SMS = () => {
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [requestInProgress2, setRequestInProgress2] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [type, setType] = useState("student");
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const [data, setData] = React.useState([]);
  const [teachersList, setTeachersList] = useState([]);
  const [inputFields1, setInputFields1] = useState({
    teacherName: "",
    teacherDepart: "All",
  });
  const [isSearchQuery, setSearchQuery] = useState();
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [data1, setData1] = React.useState([]);
  const [disable, setDisable] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedTeacher, setSeletedTeacher] = useState("");
  const [messageInputfield1, setMessageInputfield1] = useState("");
  const [messageInputfield2, setMessageInputfield2] = useState();
  const [messageBox, setMessageBox] = useState("");
  const [userType, setUserType] = useState("");
  const [placeHolders, setPlaceHolders] = useState(
    "You can enter a text upto 32 character"
  );
  const [plivo1info, setPlivo1info] = useState([]);
  const [plivo2info, setPlivo2info] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showTeacherSearchIcon, setShowTeacherSearchIcon] = useState(true);
  const toast = useToast();
  const handleChangeUserTypeStudent = useDisclosure();
  const handleChangeUserTypeTeacher = useDisclosure();
  const plivoDetails = useDisclosure();
  const sendMessageValidations = useDisclosure();
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);
  }, [type]);

  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);

  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
        setShowSearchIcon(true);
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    if (type == "student") {
      try {
        setRequestInProgress(true);
        const payload = {
          ...(inputFields.studentName !== ""
            ? { name: inputFields.studentName }
            : {}),
          ...(isClassQuery
            ? { class: inputFields.class.toLocaleUpperCase() }
            : {}),
          ...(isSectionQuery
            ? { section: inputFields.section.toLocaleUpperCase() }
            : {}),
        };
        const response = !saveDefault
          ? await requestSearchStudentByFilterCommon(payload)
          : {};
        if (response.error) {
          console.error(response.error);
        }
        if (response) {
          if (saveDefault) {
            setStudentsList(response);
          } else {
            setSearchResultList(response);
          }
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
        if (e.msg === "This Combination is Invalid") {
          toast({
            title: e.msg,
            variant: "left-accent",
            position: "bottom-right",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
          setSearchResultList([]);
        }
      }
    } else if (type == "admission") {
      try {
        setRequestInProgress(true);
        const payload = {
          ...(inputFields.studentName !== ""
            ? { name: inputFields.studentName }
            : {}),
          ...(isClassQuery
            ? { class: inputFields.class.toLocaleUpperCase() }
            : {}),
          ...(isSectionQuery
            ? { section: inputFields.section.toLocaleUpperCase() }
            : {}),
        };

        const response =
          await ServicesAdmission.requestSearchStudentByFilterCommon(payload);
        if (response.error) {
          console.error(response.error);
        }
        if (response) {
          setSearchResultList(response);
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
        if (e.msg === "This Combination is Invalid") {
          toast({
            title: e.msg,
            variant: "left-accent",
            position: "bottom-right",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
          setSearchResultList([]);
        }
      }
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    if (type == "student") {
      try {
        setRequestInProgress(true);
        const payload = {
          ...(isClassQuery
            ? { class: inputFields.class.toLocaleUpperCase() }
            : {}),
          ...(isSectionQuery
            ? { section: inputFields.section.toLocaleUpperCase() }
            : {}),
        };
        const response = !saveDefault
          ? await requestSearchStudentByFilterCommon(payload)
          : {};
        if (response.error) {
          console.error(response.error);
        }
        if (response) {
          if (saveDefault) {
            setStudentsList(response);
          } else {
            setSearchResultList(response);
          }
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
        if (e.msg === "This Combination is Invalid") {
          toast({
            title: e.msg,
            variant: "left-accent",
            position: "bottom-right",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
          setSearchResultList([]);
        }
      }
    } else if (type == "admission") {
      try {
        setRequestInProgress(true);
        const payload = {
          ...(isClassQuery
            ? { class: inputFields.class.toLocaleUpperCase() }
            : {}),
          ...(isSectionQuery
            ? { section: inputFields.section.toLocaleUpperCase() }
            : {}),
        };

        const response =
          await ServicesAdmission.requestSearchStudentByFilterCommon(payload);
        if (response.error) {
          console.error(response.error);
        }
        if (response) {
          setSearchResultList(response);
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
        if (e.msg === "This Combination is Invalid") {
          toast({
            title: e.msg,
            variant: "left-accent",
            position: "bottom-right",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
          setSearchResultList([]);
        }
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;

  const studentDetails = async () => {
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestSearchStudentDetails(payload);
    if (res) {
      let newArray = [];
      res.map((item) => {
        let obj = {
          Name: item.name,
          Class: item.academic.class,
          Section: item.academic.section,
          " Phone Number": item.phoneNumber,
          "Father Name": item.father.father_name,
          "Father Phone No.": item.father.father_phone,
          "Mother Name": item.mother.mother_name,
          "Mother Phone No.": item.mother.mother_phone,
        };
        newArray.push(obj);
        setData(newArray);
      });
    }
  };

  useEffect(() => {
    studentDetails();
  }, [inputFields.class, inputFields.section, type]);

  const searching = () => {
    setSearchQuery(
      inputFields1.teacherName.length === 1 ||
        inputFields1.teacherDepart.length === 1
    );
  };
  // React.useEffect(() => {
  //   fetchTeachers({ saveDefault: true });
  //   let input_data1 = localStorage.getItem("staff_filter")
  //     ? JSON.parse(localStorage.getItem("staff_filter"))
  //     : { teacherName: "", teacherDepart: "" };
  //   setInputFields1(input_data1);
  // }, []);

  const fetchTeachers = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress2(true);
      const payload = {
        ...(inputFields1.teacherName !== ""
          ? { name: inputFields1.teacherName }
          : {}),
        ...(inputFields1.teacherDepart !== ""
          ? { department: inputFields1.teacherDepart }
          : {}),
      };
      const response = await requestSearchTeacher(payload);
      setTeachersList(response.result);
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];

      let newArray = [];
      _result.forEach((item) => {
        let obj = {
          Employee_Id: item.emp_id,
          Name: item.name,
          Phone_Number: item.phoneNumber,
          Email_Id: item.email,
        };
        newArray.push(obj);
        setData1(newArray);
      });
      setRequestInProgress2(false);
    } catch (e) {
      setRequestInProgress2(false);
      console.error(e);
    }
  };
  const fetchTeachersWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress2(true);
      const payload = {
        ...(inputFields1.teacherDepart !== ""
          ? { department: inputFields1.teacherDepart }
          : {}),
      };
      const response = await requestSearchTeacher(payload);
      setTeachersList(response.result);
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];

      let newArray = [];
      _result.forEach((item) => {
        let obj = {
          Employee_Id: item.emp_id,
          Name: item.name,
          Phone_Number: item.phoneNumber,
          Email_Id: item.email,
        };
        newArray.push(obj);
        setData1(newArray);
      });
      setRequestInProgress2(false);
    } catch (e) {
      setRequestInProgress2(false);
      console.error(e);
    }
  };
  React.useEffect(() => {
    fetchTeachers();
  }, [inputFields1.teacherDepart]);

  const handleInputChange1 = ({ target: { name, value } }) => {
    if (name === "teacherDepart") {
      setInputFields1((prev) => ({
        ...prev,
        [name]: value,
        teacherName: "",
      }));
      setShowTeacherSearchIcon(true);
    } else {
      setInputFields1((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowTeacherSearchIcon(true);
      } else {
        setShowTeacherSearchIcon(false);
      }
    }
  };
  const handleSearchTeacherClick = () => {
    if (inputFields1.teacherName !== "") {
      fetchTeachers();
      setShowTeacherSearchIcon(false);
    }
  };

  const handleCloseIconTeacherClick = () => {
    setInputFields1((prev) => ({
      ...prev,
      teacherName: "",
    }));

    fetchTeachersWithoutName();
    setShowTeacherSearchIcon(true);
  };
  const handleTeacherEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchTeacherClick();
    }
  };
  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };
  React.useEffect(() => {
    getTeacherDepartment();
  }, []);

  // if (loading) {
  //   return (
  //     <div className={Styles.loaderWrapper}>
  //       <Spinner />
  //     </div>
  //   );
  // }
  const handleinputDisabled = (e, inputName) => {
    if (disable == false) {
      setDisable(true);
      setMessageInputfield2("");
      setPlaceHolders("you can enter a text upto 32 character");
    } else if (disable == true) {
      setDisable(false);
      setMessageInputfield1("");
      setPlaceHolders("write a message");
    }
  };
  const handleCheckAll = () => {
    if (studentList.length === selectedStudent.length) {
      setSelectedStudent([]);
    } else {
      let tempStudentList = studentList.map((item) => item._id);
      setSelectedStudent(tempStudentList);
    }
  };
  const handleCheckBox = (id) => {
    let student = [...selectedStudent];
    if (student.includes(id)) {
      let index = student.indexOf(id);
      student.splice(index, 1);
      setSelectedStudent(student);
    } else {
      setSelectedStudent([...student, id]);
    }
    handleRemoveidChange(id);
  };
  const handleRemoveidChange = (id) => {
    let student = [...selectedStudent];
    if (student.includes(id)) {
      let index = student.indexOf(id);
      student.splice(index, 1);
      setSelectedStudent(student);
    }
  };
  const handleCheckBoxTeacher = (id) => {
    let teacher = [...selectedTeacher];
    if (teacher.includes(id)) {
      let index = teacher.indexOf(id);
      teacher.splice(index, 1);
      setSeletedTeacher(teacher);
    } else {
      setSeletedTeacher([...teacher, id]);
    }
    handleRemoveteacheridChange(id);
  };
  const handleRemoveteacheridChange = (id) => {
    let teacher = [...selectedTeacher];
    if (teacher.includes(id)) {
      let index = teacher.indexOf(id);
      teacher.splice(index, 1);
      setSeletedTeacher(teacher);
    }
  };
  const handleTeacherCheckAll = () => {
    if (teachersList.length === selectedTeacher.length) {
      setSeletedTeacher([]);
    } else {
      let tempTeacherList = teachersList.map((item) => item._id);
      setSeletedTeacher(tempTeacherList);
    }
  };

  const handleSendSMS = async () => {
    if (selectedStudent.length >= 1 || selectedTeacher.length >= 1) {
      if (disable == true) {
        if (userType == "student") {
          try {
            const payload = {
              text: messageInputfield1,
              studentList: selectedStudent,
              userType: "student",
            };
            const responseSMS = await ServicesSMS.sendSMS(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          } catch (error) {
            toast({
              title: "Message",
              description: "Message sending failed",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          }
        }
        if (userType == "staff") {
          try {
            const payload = {
              text: messageInputfield1,
              studentList: selectedTeacher,
              userType: "teacher",
            };
            const responseSMS = await ServicesSMS.sendSMS(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              console.log("message does not show");
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          } catch (error) {
            toast({
              title: "Message",
              description: "Message sending failed",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          }
        }
      } else {
        if (userType == "student") {
          try {
            const payload = {
              text: messageInputfield1,
              studentList: selectedStudent,
              userType: "student",
            };
            const responseSMS = await ServicesSMS.sendSMS2(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          } catch (error) {
            const payload = {
              text: messageInputfield1,
              studentList: selectedTeacher,
              userType: "teacher",
            };
            const responseSMS = await ServicesSMS.sendSMS2(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          }
        }
        if (userType == "staff") {
          try {
            const payload = {
              text: messageInputfield1,
              studentList: selectedTeacher,
              userType: "teacher",
            };
            const responseSMS = await ServicesSMS.sendSMS2(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          } catch (error) {
            const payload = {
              text: messageInputfield1,
              studentList: selectedTeacher,
              userType: "teacher",
            };
            const responseSMS = await ServicesSMS.sendSMS2(payload);
            if (responseSMS.success == true) {
              toast({
                title: "Message",
                description: "Message send Successfully",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              toast({
                title: "Message",
                description: "Message sending failed",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
            }
          }
        }
      }
    } else {
      toast({
        title: "",
        description: "Please select any student or teacher",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handlecheckupper = () => {
    if (selectedStudent.length >= 1) {
      handleChangeUserTypeTeacher.onOpen();
    }
  };
  const handlecheckstudents = () => {
    if (selectedTeacher.length >= 1) {
      handleChangeUserTypeStudent.onOpen();
    }
  };
  const handlePressYes = () => {
    setSelectedStudent("");
    setSeletedTeacher("");
    handleChangeUserTypeTeacher.onClose();
  };
  const validationSMS = async () => {
    if (selectedStudent.length >= 1 || selectedTeacher.length >= 1) {
      if (selectedStudent.length >= 50 || selectedTeacher.length >= 50) {
        toast({
          title: "",
          description: "You can't select student or teacher more then 50",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        if (messageInputfield1 !== "") {
          if (disable == true) {
            await handlePlivo1();
          }
          if (disable == false) {
            await handlePlivo2();
          }
          sendMessageValidations.onOpen();
        } else {
          toast({
            title: "",
            description: "Please Enter a Message",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } else {
      toast({
        title: "",
        description: "Please select any student or teacher",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handlePlivoDetails = async () => {
    if (disable == true) {
      await handlePlivo1();
    }
    if (disable == false) {
      await handlePlivo2();
    }
    plivoDetails.onOpen();
  };

  const handlePlivo1 = async () => {
    try {
      const response = await ServicesSMS.pilvoAccount1({});
      if (response) {
        setPlivo1info(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handlePlivo2 = async () => {
    try {
      const response = await ServicesSMS.pilvoAccount2({});
      if (response) {
        setPlivo2info(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  console.log("disabled from line 870", plivo2info);
  return (
    <div className={Styles.Containerbody}>
      <div className={Styles.container1}>
        <div className={Styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                name="class"
                disabled={selectedStudent.length >= 1 ? true : false}
                placeholder="Select Type"
              >
                <option value="admission">Admission</option>
                <option value="student">Student</option>
              </Select>
            </div>
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                  <Input
                    name="studentName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields.studentName}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                  {showSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>

            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
              >
                {sections
                  ? sections.map((section, idx) => (
                      <option key={section + idx} value={section}>
                        Section {section}
                      </option>
                    ))
                  : ""}
              </Select>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "1vw" }}
          >
            <input
              className={Styles.checkbox}
              type="checkbox"
              onChange={handleCheckAll}
              disabled={selectedTeacher.length ? true : false}
            />
            <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              Student Name ({studentList.length})
            </div>
          </div>
        </div>
        <div className={Styles.body}>
          <div className={Styles.lists}>
            {studentList.length === 0 ? (
              <div className={""}></div>
            ) : (
              <div></div>
            )}

            {requestInProgress ? (
              <div className={Styles.loaderWrapper}>
                <Spinner />
              </div>
            ) : studentList.length === 0 ? (
              <div className={""}>No result found</div>
            ) : (
              studentList.map((student, idx) => (
                <>
                  <div></div>
                  <div className={Styles.student} onClick={handlecheckstudents}>
                    <input
                      className={Styles.checkbox}
                      disabled={selectedTeacher.length ? true : false}
                      checked={selectedStudent.includes(student._id)}
                      type="checkbox"
                      onChange={() => {
                        handleCheckBox(student._id);
                        setUserType("student");
                      }}
                    />

                    <div className={Styles.profile}>
                      <img
                        className={Styles.profileImg}
                        src={student.url != "N/A" ? student.url : defaultUser}
                        alt="student-profile"
                      />
                    </div>
                    <div className={Styles.info}>
                      <p className={Styles.name}>{student.name || "--"}</p>
                      <p className={Styles.class}>
                        {(student?.academic?.class &&
                          student?.academic?.class) ||
                          "--"}{" "}
                        | {student.academic?.section?.toUpperCase() || "--"}
                      </p>
                    </div>
                  </div>
                </>
              ))
            )}
          </div>
        </div>
      </div>
      <div className={Styles.container2}>
        <div className={Styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                  <Input
                    name="teacherName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields1.teacherName}
                    onChange={handleInputChange1}
                    onKeyDown={handleTeacherEnterKeyPress}
                    autoComplete="off"
                  />
                  {showTeacherSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchTeacherClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconTeacherClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields1.teacherDepart}
                onChange={handleInputChange1}
                name="teacherDepart"
                placeholder="Select Department"
              >
                <option>All</option>
                {teacherDepartment.map((data) => (
                  <option value={data.department}>{data.department}</option>
                ))}
              </Select>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "1vw" }}
          >
            <input
              className={Styles.checkbox}
              type="checkbox"
              onChange={handleTeacherCheckAll}
              disabled={selectedStudent.length >= 1 ? true : false}
            />
            <div
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              Teacher Name ({teachersList.length})
            </div>
          </div>
        </div>
        <div className={Styles.body}>
          <div className={Styles.lists}>
            {teachersList.length === 0 ? (
              <div className={""}></div>
            ) : (
              <div></div>
            )}
            {requestInProgress2 ? (
              <div className={Styles.loaderWrapper2}>
                <Spinner />
              </div>
            ) : teachersList && teachersList.length === 0 ? (
              <div className={""}>No result found</div>
            ) : (
              teachersList &&
              teachersList.map((teacher, idx) => {
                return (
                  <>
                    <div
                      className={Styles.student}
                      onClick={() => handlecheckupper()}
                    >
                      <div>
                        <input
                          className={Styles.checkbox}
                          checked={selectedTeacher.includes(teacher._id)}
                          disabled={selectedStudent.length >= 1 ? true : false}
                          onChange={() => {
                            handleCheckBoxTeacher(teacher._id);
                            setUserType("staff");
                          }}
                          type="checkbox"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div className={Styles.profile}>
                          <img
                            className={Styles.profileImg}
                            src={
                              teacher?.url != "N/A" ? teacher?.url : defaultUser
                            }
                            alt="profile"
                          />
                        </div>
                        <div className={Styles.info}>
                          <p className={Styles.name}>{teacher?.name || "--"}</p>
                          <p className={Styles.number}>
                            EMP ID : {teacher?.emp_id || "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className={Styles.container3}>
        <div className={Styles.header3}>
          <div className={Styles.switchContainer}>
            <div className={Styles.switchContainerText}>
              <p>SMS Sender ID : </p>
            </div>
            <Switch
              onChange={handleinputDisabled}
              isChecked={disable == false ? false : true}
            />
          </div>
          <Tooltip label="Plivo Account Details">
            <Button
              colorScheme="green"
              onClick={() => handlePlivoDetails()}
              style={{ marginLeft: "10px" }}
            >
              Account Info
            </Button>
          </Tooltip>
        </div>
        <div className={Styles.messageBoxContainer}>
          <h1 className={Styles.container3Text}>Write your message</h1>
          <p className={Styles.messageHeader}>Dear, </p>
          <textarea
            value={messageInputfield1}
            onChange={(e) => {
              setMessageInputfield1(e.target.value);
              setMessageBox(true);
            }}
            maxlength={disable == true ? 32 : 1000}
            className={Styles.textarea}
            placeholder={placeHolders}
          ></textarea>
          <p className={Styles.messageHeader}>Regards SMESUD</p>
        </div>
        <div className={Styles.buttonRow}>
          <button className={Styles.button} onClick={() => validationSMS()}>
            SEND
          </button>
        </div>
      </div>
      <Modal
        isOpen={handleChangeUserTypeTeacher.isOpen}
        onClose={handleChangeUserTypeTeacher.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to change user type?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleChangeUserTypeTeacher.onClose()}
            >
              No
            </Button>
            <Button colorScheme="red" onClick={() => handlePressYes()}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={handleChangeUserTypeStudent.isOpen}
        onClose={handleChangeUserTypeStudent.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to change user type?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleChangeUserTypeStudent.onClose()}
            >
              No
            </Button>
            <Button colorScheme="red" onClick={() => handlePressYes()}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={plivoDetails.isOpen} onClose={plivoDetails.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ alignSelf: "center", fontWeight: "bold" }}>
            Plivo Account Details
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex" }}>
              <div className={Styles.plivoKey}>
                <p>Account Name </p>
                <p>Credits Available (In USD) </p>
                <p>Auto Recharge Applicable </p>
                <p>Sender ID Active </p>
              </div>
              <div className={Styles.plivoColon}>
                <p> : </p>
                <p> : </p>
                <p> : </p>
                <p> : </p>
              </div>
              {disable == true ? (
                <div className={Styles.plivoValue}>
                  <p>{plivo1info.name}</p>
                  <p
                    style={{
                      color: plivo1info.cashCredits < 5 ? "red" : "green",
                      fontWeight: "bold",
                    }}
                  >
                    $ {parseFloat(plivo1info.cashCredits).toFixed(4)}
                  </p>
                  <p>{plivo1info.autoRecharge ? "Yes" : "No"}</p>
                  <p> {disable == true ? "Yes" : "No"}</p>
                </div>
              ) : (
                <div style={{ width: "40%" }}>
                  <p>{plivo2info.name}</p>
                  <p
                    style={{
                      color: plivo2info.cashCredits < 5 ? "red" : "green",
                      fontWeight: "bold",
                    }}
                  >
                    $ {parseFloat(plivo2info.cashCredits).toFixed(4)}
                  </p>
                  <p>{plivo2info.autoRecharge ? "Yes" : "No"}</p>
                  <p> {disable == true ? "Yes" : "No"}</p>
                </div>
              )}
            </div>
            <p style={{ marginTop: "10px" }}>
              <span
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                For more information contact to our developer account
              </span>
            </p>
            {disable == true ? (
              <p style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {" "}
                  Note :{" "}
                </span>
                The cost applicable is{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>
                  $0.003
                </span>{" "}
                per sms sent to one user.
              </p>
            ) : (
              <p style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {" "}
                  Note :{" "}
                </span>
                The cost applicable is{" "}
                <span style={{ fontWeight: "bold", color: "red" }}>
                  $0.08
                </span>{" "}
                per sms sent to one user.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => plivoDetails.onClose()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={sendMessageValidations.isOpen}
        onClose={sendMessageValidations.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ alignSelf: "center", fontWeight: "bold" }}>
            {disable ? "Message with Sender Id" : "Message without Sender Id"}
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex" }}>
              <div className={Styles.plivoKey2}>
                <p>
                  {selectedStudent.length
                    ? "Total No. Student"
                    : "Total No. Teacher"}
                </p>
                <p>Message Charge (In USD) </p>
                <p>Total Message Charge (In USD) </p>
                <p>Total Balance (In USD) </p>
                <p>Remaining Balance (In USD) </p>
              </div>
              <div className={Styles.plivoColon}>
                <p> : </p>
                <p> : </p>
                <p> : </p>
                <p> : </p>
                <p> : </p>
              </div>

              <div className={Styles.plivoValue}>
                <p
                  style={{
                    // color: plivo1info.cashCredits < 5 ? "red" : "green",
                    fontWeight: "bold",
                  }}
                >
                  {selectedStudent.length
                    ? selectedStudent.length
                    : selectedTeacher.length}
                </p>
                <p
                  style={{
                    // color: plivo1info.cashCredits < 5 ? "red" : "green",
                    fontWeight: "bold",
                  }}
                >
                  $ {disable ? 0.0038 : 0.08}
                </p>
                <p
                  style={{
                    // color: plivo1info.cashCredits < 5 ? "red" : "green",
                    fontWeight: "bold",
                  }}
                >
                  ${" "}
                  {disable
                    ? selectedStudent.length
                      ? 0.0038 * selectedStudent.length
                      : 0.0038 * selectedTeacher.length
                    : selectedStudent.length
                    ? 0.08 * selectedStudent.length
                    : 0.08 * selectedTeacher.length}
                </p>
                <p
                  style={{
                    color: plivo1info.cashCredits < 5 ? "red" : "green",
                    fontWeight: "bold",
                  }}
                >
                  ${" "}
                  {disable
                    ? parseFloat(plivo1info.cashCredits).toFixed(4)
                    : parseFloat(plivo2info.cashCredits).toFixed(4)}
                </p>
                <p
                  style={{
                    // color: plivo1info.cashCredits < 5 ? "red" : "green",
                    fontWeight: "bold",
                  }}
                >
                  ${" "}
                  {disable
                    ? selectedStudent.length
                      ? (
                          parseFloat(plivo1info.cashCredits) -
                          0.0038 * selectedStudent.length
                        ).toFixed(4)
                      : (
                          parseFloat(plivo1info.cashCredits).toFixed(4) -
                          0.0038 * selectedTeacher.length
                        ).toFixed(4)
                    : selectedStudent.length
                    ? (
                        parseFloat(plivo2info.cashCredits).toFixed(4) -
                        0.08 * selectedStudent.length
                      ).toFixed(4)
                    : (
                        parseFloat(plivo2info.cashCredits).toFixed(4) -
                        0.08 * selectedTeacher.length
                      ).toFixed(4)}
                </p>
              </div>
            </div>
            <p
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Center
                bg={
                  (disable
                    ? parseFloat(plivo1info.cashCredits).toFixed(4)
                    : parseFloat(plivo2info.cashCredits).toFixed(4)) -
                    (disable
                      ? selectedStudent.length
                        ? 0.0038 * selectedStudent.length
                        : 0.0038 * selectedTeacher.length
                      : selectedStudent.length
                      ? 0.08 * selectedStudent.length
                      : 0.08 * selectedTeacher.length) >=
                  0
                    ? "green"
                    : "red"
                }
                w="93%"
                p={3}
                color="white"
              >
                {(disable
                  ? parseFloat(plivo1info.cashCredits).toFixed(4)
                  : parseFloat(plivo2info.cashCredits).toFixed(4)) -
                  (disable
                    ? selectedStudent.length
                      ? 0.0038 * selectedStudent.length
                      : 0.0038 * selectedTeacher.length
                    : selectedStudent.length
                    ? 0.08 * selectedStudent.length
                    : 0.08 * selectedTeacher.length) >=
                0
                  ? "You have Sufficient Amount to Send a Message"
                  : "You have Insufficient Amount to Send a Message"}
              </Center>
            </p>
            <p
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" "}
                Message :{" "}
              </span>
              <textarea
                style={{
                  width: "70%",
                  outline: "none",
                  border: "1px solid black",
                }}
                disabled
                value={messageInputfield1}
              />
            </p>
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              colorScheme="green"
              onClick={() => {
                handleSendSMS();
                sendMessageValidations.onClose();
              }}
            >
              Send
            </Button>
            <Button
              style={{ marginLeft: "8px" }}
              colorScheme="blue"
              onClick={() => sendMessageValidations.onClose()}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SMS;

import React from "react";
import smuWatermark from "./watermarkOpacity2.png";

const Watermarkfor7rows = () => {
  return (
    <img
      src={smuWatermark}
      alt="Snow"
      style={{
        width: "100mm",
        height: "206px",
        opacity: 0.3,
        padding: "20px 0px 0px 175px",
      }}
    />
  );
};

const Watermarkfor9rows = () => {
  return (
    <img
      src={smuWatermark}
      alt="Snow"
      style={{
        width: "110mm",
        height: "257px",
        opacity: 0.3,
        padding: "20px 0px 0px 157px",
      }}
    />
  );
};

const Watermarkfor10rows = () => {
  return (
    <img
      src={smuWatermark}
      alt="Snow"
      style={{
        width: "110mm",
        height: "283.5px",
        opacity: 0.3,
        padding: "40px 0px 0px 157px",
      }}
    />
  );
};

const Watermarkfor10aboverows = () => {
  return (
    <img
      src={smuWatermark}
      alt="Snow"
      style={{
        width: "110mm",
        height: "285px",
        opacity: 0.3,
        padding: "40px 0px 0px 157px",
      }}
    />
  );
};

const WatermarkforHighHeaders = () => {
  return (
    <img
      src={smuWatermark}
      alt="Snow"
      style={{
        width: "98mm",
        height: "238px",
        opacity: 0.3,
        padding: "80px 0px 0px 200px",
      }}
    />
  );
};

const Watermark = ({ gradeCategory }) => {
  return gradeCategory === "Grade1to5" ||
    gradeCategory === "Grade7" ||
    gradeCategory === "Grade9" ? (
    <Watermarkfor9rows />
  ) : gradeCategory === "Grade6" || gradeCategory === "Grade8" ? (
    <Watermarkfor10rows />
  ) : gradeCategory === "Grade9" ? (
    <Watermarkfor10aboverows /> //not needed
  ) : gradeCategory === "Grade10" ? (
    <Watermarkfor7rows />
  ) : gradeCategory.includes("Grade11") || gradeCategory.includes("Grade12") ? (
    <WatermarkforHighHeaders />
  ) : (
    ""
  );
};
export default Watermark;

import axios from 'axios'
import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

async function requestEventsById (payload = {}) {
  const { eventId } = payload
  
  if ((!eventId || eventId === '')) {
    return { error: 'Field missing' }
  }
  const url = BASE_URL + '/student/event/id'
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions())
    if (response.data.error) {
      return { error: response.data.error }
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error
    }
    if (e?.response?.data) {
      throw e.response.data
    }

    throw e
  }
}

async function requestSearchEvents (payload = {}) {
  const url = BASE_URL + '/student/event/search'

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions())
    if (response.data.error) {
      return { error: response.data.error }
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error
    }
    if (e?.response?.data) {
      throw e.response.data
    }

    throw e
  }
}

const Services = {
  requestEventsById,
  requestSearchEvents
}


export default Services

import React, { useEffect, useRef, useState } from "react";
import Styles from "./Calendar.module.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Icons } from "utilities/assets";
import editIcon from "../../../assets/icons/editIcon.png";
import deleteIcon from "../../../assets/icons/deleteIcon.png";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import { HiAcademicCap } from "react-icons/hi";
import DatePicker from "react-datepicker";
import {
  requestCreateEvent,
  requestDateEvents,
  requestDeleteEvents,
  requestGetAcademicSession,
  requestMonthEvents,
  requestUpdateEvents,
} from "services/management-dashboard/calendar.service";
import moment from "moment";
import { requestHolidays } from "services/management-dashboard/attendance.service";
const ManagementCalendar = () => {
  const modalSession = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [isAlertOpen, setAlertOpen] = useState();
  const [editData, setEditData] = useState("");
  const [deleteData, setDeleteData] = useState("");
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const modalEdit = useDisclosure();
  const modalAdd = useDisclosure();
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const toast = useToast();
  const [inputFields, setInputFields] = useState({
    date: "",
    event: {
      title: "",
      description: "",
      category: "",
      event_visible: false,
    },
  });
  const [monthEvent, setMonthEvent] = useState([]);
  const [dateEvent, setDateEvent] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedMidTermDate, setSelectedMidTermDate] = useState("");
  const [year, setYear] = useState(localStorage.getItem("yearResult") || "");
  const [academicData, setAcademicData] = useState("");
  const [updatedFields, setUpdatedFields] = useState({});
  const [holidays, setHolidays] = useState([]);
  const events = [];
  const yearOptions = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 1 + index
  );
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setInputFields((prev) => ({
      ...prev,
      [name]: inputValue,
      event: {
        ...prev.event,
        [name]: inputValue,
      },
    }));
  };
  const calendarRef = useRef(null);
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "7px",
      height: "10px",
    };
    const eventsOnDate = monthEvent.find(
      (event) => new Date(event.date).toDateString() === date.toDateString()
    );
    let eventContent = "";
    if (eventsOnDate && eventsOnDate.events > 0) {
      const dotColor = "#4e13c8";
      const eventCount = eventsOnDate.events;
      const remainingCount = eventCount - 1;
      const dotStyle = `
        background-color: ${dotColor};
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
      `;
      const remainingCountStyle = `
        display: inline-block;
        vertical-align: middle;
        line-height: 10px;
        font-size: 15px;
        font-weight: bold;
        color: ${dotColor};
      `;

      eventContent = `
        <div class="${Styles.eventContainer}">
          <div class="${Styles.eventDot}" style="${dotStyle}"></div>
          ${
            remainingCount > 0
              ? `<div class="${Styles.remainingCount}" style="${remainingCountStyle}">+${remainingCount}</div>`
              : ""
          }
        </div>
      `;

      if (eventCount === 1) {
        eventContent = `
          <div class="${Styles.eventContainer}">
            <div class="${Styles.eventDot}" style="${dotStyle}"></div>
            <div class="${Styles.remainingCount}" style="${remainingCountStyle}">+0</div>
          </div>
        `;
      }
    }
    return {
      html: `
        <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start; height: 50px;">
          <div class="${Styles.date}" style="height: ${
        isSelected ? "auto" : "auto"
      };">${date.getDate()}</div>
          ${eventContent}
        </div>
      `,
    };
  };
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };

  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setDateEvent([]);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setDateEvent([]);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };

  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setDateEvent({});
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setDateEvent({});
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
    const selectedEvents = events.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getFullYear() === year &&
        eventDate.getMonth() === month &&
        eventDate.getDate() === date
      );
    });
    setSelectedEvents(selectedEvents);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  const updateEventDetails = () => {
    if (selectedDate) {
      const selectedDateString = selectedDate.toLocaleDateString();
      const eventsOnSelectedDate = events.filter((event) => {
        const eventDateString = new Date(event.date).toLocaleDateString();
        return eventDateString === selectedDateString;
      });
      if (eventsOnSelectedDate.length > 0) {
        const selectedEventProps = {
          name: eventsOnSelectedDate[0].name,
          des: eventsOnSelectedDate[0].des,
          date: eventsOnSelectedDate[0].date,
        };
        setSelectedEvent(selectedEventProps);
      } else {
        setSelectedEvent(null);
      }

      setSelectedEvents(eventsOnSelectedDate);
    }
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);

    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    updateEventDetails();
  }, [selectedDate]);
  const handleEditData = (item, date) => {
    modalEdit.onOpen();
    const formattedDate = new Date(date).toLocaleDateString("en-GB");
    setEditData({ item, formattedDate });
  };
  const highlightSundays = (date) => {
    return date.getDay() === 0;
  };
  const currentMonth = new Date().getMonth();
  const getMonthDates = (month) => {
    const dates = [];
    const currentYear = new Date().getFullYear();
    const daysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
    }
    return dates;
  };
  const highlightDates = getMonthDates(currentMonth);
  const createEvent = () => {
    if (!inputFields.title || !inputFields.category || !inputFields.date) {
      toast({
        title: "Please fill in all required fields.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      date: moment(inputFields.date).format("YYYY-MM-DD"),
      event: {
        title: inputFields.title,
        description: inputFields.description || "-",
        category: inputFields.category,
        event_visible: inputFields.event_visible,
      },
    };
    const res = requestCreateEvent(payload);
    res
      .then((response) => {
        toast({
          title: "Event Created Successfully",
          variant: "left-accent",
          position: "top-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        handleDateEvents();
        handleMonthsEvents();
        updateCalendar(selectedYear, selectedMonth);
      })
      .catch((e) => {
        toast({
          title: e.message,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      })
      .finally(() => {
        modalAdd.onClose();
      });
  };
  const handleMonthsEvents = async () => {
    var date = new Date();
    const startDate = new Date(selectedYear, selectedMonth, 1);
    const endDate = new Date(selectedYear, selectedMonth + 1, 0);
    const payload = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    const res = await requestMonthEvents(payload);
    setMonthEvent(res.data);
  };
  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi.batchRendering(() => {
      highlightDates();
      calendarApi.render();
    });
  }, [holidays]);
  useEffect(() => {
    handleMonthsEvents();
    handleHolidays();
  }, [selectedMonth, selectedYear]);

  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;

    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;

    // Update the state to the clicked date, month, and year
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    const selectedDateString = clickedDate.toLocaleDateString();
    const eventsOnSelectedDate = events.filter((event) => {
      const eventDateString = new Date(event.date).toLocaleDateString();
      return eventDateString === selectedDateString;
    });

    setSelectedEvent(null);
    setSelectedEvents(eventsOnSelectedDate);
    calendarRef.current.getApi().unselect(); // Unselect the clicked date

    setTimeout(() => {
      calendarRef.current.getApi().updateSize(); // Update the calendar size after a delay
    }, 0);
  };

  const handleDateEvents = async () => {
    const payload = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = await requestDateEvents(payload);
    setDateEvent(res.data);
  };
  useEffect(() => {
    if (selectedDate) {
      handleDateEvents();
    }
  }, [selectedDate]);

  const handleEditInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    if (type === "checkbox") {
      setEditData((prevEditData) => ({
        ...prevEditData,
        item: {
          ...prevEditData.item,
          [name]: checked,
        },
      }));
    } else {
      setEditData((prevEditData) => ({
        ...prevEditData,
        item: {
          ...prevEditData.item,
          [name]: value,
        },
      }));
    }
  };
  const updateEvent = () => {
    const formattedDate = moment(editData.formattedDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    const payload = {
      date: formattedDate,
      event: {
        title: editData.item.title,
        description: editData.item.description,
        category: editData.item.category,
        event_visible: editData.item.event_visible,
        _id: editData.item._id,
      },
    };

    const response = requestUpdateEvents(payload);
    response
      .then((res) => {
        toast({
          title: "Event Updated Successfully",
          variant: "left-accent",
          position: "top-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        handleDateEvents();
      })
      .catch((e) => {
        toast({
          title: e,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      })
      .finally(() => {
        modalEdit.onClose();
      });
  };
  const handleDeleteData = (id, date) => {
    setAlertOpen(true);
    const formattedDate = new Date(date).toISOString().split("T")[0];
    setDeleteData({ id, formattedDate });
  };
  const handleDeleteEvent = async () => {
    const payload = {
      date: deleteData.formattedDate,
      event_id: deleteData.id,
    };
    try {
      const response = await requestDeleteEvents(payload);
      toast({
        title: "Event Deleted Successfully",
        variant: "left-accent",
        position: "top-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });

      await handleMonthsEvents();
      await handleDateEvents();
      updateCalendar(selectedYear, selectedMonth);
    } catch (error) {
      toast({
        title: error.message,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } finally {
      onAlertClose();
    }
  };

  const handleStartDateChange = (start_date) => {
    setSelectedStartDate(start_date);
  };

  const handleMidTermDateChange = (mid_term_date) => {
    setSelectedMidTermDate(mid_term_date);
  };

  const handleEndDateChange = (end_date) => {
    setSelectedEndDate(end_date);
  };

  const handleGetSession = async () => {
    const payload = {
      session: year,
    };
    const response = await requestGetAcademicSession(payload);
    setAcademicData(response);
  };

  useEffect(() => {
    if (year) {
      handleGetSession();
      setAcademicData("");
    }
  }, [year]);

  const handleDateChange = (date, field) => {
    // Update the state with the new date value
    setAcademicData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        specification: [
          {
            ...prevState.data.specification[0],
            [field]: date,
          },
        ],
      },
    }));
    setUpdatedFields((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const handleTotalDaysChange = (event, field) => {
    const newTotalDays = event.target.value;
    setAcademicData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        specification: [
          {
            ...prevData.data.specification[0],
            [field]: newTotalDays,
          },
        ],
      },
    }));
    setUpdatedFields((prevState) => ({
      ...prevState,
      [field]: newTotalDays,
    }));
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.statsWrapper}>
        <p className={Styles.stats}>ACADEMIC CALENDAR</p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.mainContainer}>
          <div className={Styles.calendarWrapper}>
            <div
              style={{
                border: "2px solid black",
                maxHeight: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid black",
                  padding: "5px",
                }}
              >
                <div>
                  <button onClick={handlePreviousMonth}>
                    <FaCaretSquareLeft className={Styles.arrows} />
                  </button>
                </div>
                <div>
                  {renderHeader()} {/* Display the month and year */}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className={Styles.todayButton}
                    onClick={handleTodayButtonClick}
                    style={{ display: isToday() ? "none" : "block" }}
                  >
                    TODAY
                  </button>
                  <button onClick={handleNextMonth}>
                    <FaCaretSquareRight className={Styles.arrows} />
                  </button>
                </div>
              </div>
              <div style={{ overflowY: "auto", maxHeight: "280px" }}>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  selectable={true}
                  dateClick={handleDateClick}
                  dayCellContent={dayCellContent}
                  dayHeaderContent={dayHeaderContent}
                />
              </div>
            </div>
            <div
              style={{
                display: " flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "10px",
                fontWeight: "650",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#9c9695",
                    marginRight: "10px",
                  }}
                ></div>
                <p>Holiday </p>
              </div>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#4e13c8",
                    marginRight: "10px",
                    borderRadius: "100%",
                  }}
                ></div>
                <p>An Event</p>
              </div>
            </div>
          </div>
          <div className={Styles.rightContainer}>
            {dateEvent?.events && dateEvent?.events?.length !== 0 ? (
              <div className={Styles.dateContainer}>
                <p className={Styles.heading}>
                  Date: {moment(dateEvent.date).format("DD-MM-YYYY")} | No. of
                  Events: &nbsp;{dateEvent.events.length}
                </p>
              </div>
            ) : (
              <div className={Styles.dateContainer}>
                {selectedDate ? (
                  <p className={Styles.heading}>
                    Date: {moment(selectedDate).format("DD-MM-YYYY")} | No. of
                    Events: 0
                  </p>
                ) : (
                  <div className={Styles.selectDateContainer}>
                    <span className={Styles.centerText}>
                      Please Select Date
                    </span>
                  </div>
                )}
              </div>
            )}

            {dateEvent?.events &&
              dateEvent?.events?.map((item) => (
                <div className={Styles.eventWrap} key={item._id}>
                  <div>
                    <p className={Styles.subheading}>
                      Title:{" "}
                      <span className={Styles.subheadingDetail}>
                        {item.title}
                      </span>
                    </p>
                    <p className={Styles.subheading}>
                      Description:{" "}
                      <span className={Styles.subheadingDetail}>
                        {item?.description ? item.description : "-"}
                      </span>
                    </p>
                    {item.category === "HOLIDAY" ? (
                      <p className={Styles.subheading}>
                        Category:{" "}
                        <span className={Styles.holiday}>{item.category}</span>
                      </p>
                    ) : (
                      <p className={Styles.subheading}>
                        Category:{" "}
                        <span className={Styles.subheadingDetail}>
                          {item.category}
                        </span>
                      </p>
                    )}
                    <p className={Styles.subheading}>
                      Show in Events:
                      <Switch
                        colorScheme="green"
                        disabled
                        style={{ margin: "5px" }}
                        isChecked={item.event_visible}
                        size="md"
                      />
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img
                      className={Styles.editImage}
                      src={editIcon}
                      alt="edit"
                      onClick={() => handleEditData(item, dateEvent.date)}
                    />
                    <img
                      className={Styles.editImage}
                      src={deleteIcon}
                      alt="del"
                      onClick={() => handleDeleteData(item._id, dateEvent.date)}
                    />
                  </div>
                </div>
              ))}
            {(selectedDate && dateEvent?.events?.length == 0) ||
            dateEvent == null ? (
              <div
                className={Styles.eventWrap}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                No Events on Selected Date
              </div>
            ) : null}
          </div>
          <Tooltip label="Add Event">
            <div className={Styles.addButton}>
              <img
                onClick={modalAdd.onOpen}
                className={Styles.add}
                src={Icons.add}
                alt="add"
              />
            </div>
          </Tooltip>
          <Tooltip label="Academic Year" placement="top">
            <div className={Styles.academicYear} onClick={modalSession.onOpen}>
              <HiAcademicCap className={Styles.add} />
            </div>
          </Tooltip>
        </div>
        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onAlertClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Event
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={() => handleDeleteEvent()}
                >
                  Delete
                </Button>
                <Button
                  colorScheme="blue"
                  ref={cancelRef}
                  onClick={onAlertClose}
                >
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <Modal isOpen={modalEdit.isOpen} onClose={modalEdit.onClose}>
          <ModalOverlay />
          <ModalContent
            position="fixed"
            top="0"
            transform="translateX(-50%)"
            maxHeight="80vh"
            overflowY="auto"
          >
            <ModalHeader>Update Event</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {editData && (
                <div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>
                      Title <span className={Styles.asterisk}>*</span>
                    </p>

                    <input
                      required
                      id="title"
                      name="title"
                      placeholder="Enter Title..."
                      className={Styles.textInput}
                      value={editData.item.title}
                      onChange={handleEditInputChange}
                    />
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}> Description </p>
                    <Textarea
                      onChange={handleEditInputChange}
                      required
                      id="description"
                      name="description"
                      placeholder="Enter Description..."
                      className={Styles.textInput}
                      value={editData.item.description}
                      size="lg"
                      resize="vertical"
                    />
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>
                      Category <span className={Styles.asterisk}>*</span>{" "}
                    </p>
                    <select
                      onChange={handleEditInputChange}
                      required
                      id="category"
                      name="category"
                      className={Styles.textInput}
                      value={editData.item.category}
                    >
                      <option value="" disabled selected hidden>
                        Enter Category...
                      </option>
                      <option value="ACADEMIC">ACADEMIC</option>
                      <option value="GENERAL">GENERAL</option>
                      <option value="SPORTS">SPORTS</option>
                      <option value="CULTURAL">CULTURAL</option>
                      <option value="OTHER">OTHER</option>
                      <option value="HOLIDAY">HOLIDAY</option>
                      <option value="NON-ACADEMIC">NON-ACADEMIC</option>
                    </select>
                  </div>
                  <div className={Styles.form}>
                    Show in Events
                    <Switch
                      colorScheme="green"
                      style={{ margin: "5px" }}
                      name="event_visible"
                      isChecked={editData.item.event_visible}
                      size="md"
                      onChange={handleEditInputChange}
                    />
                  </div>
                  <div className={Styles.form}>
                    <p className={Styles.placeholder}>
                      Date <span className={Styles.asterisk}>*</span>{" "}
                    </p>
                    <DatePicker
                      required
                      selected={moment(
                        editData.formattedDate,
                        "DD/MM/YYYY"
                      ).toDate()}
                      dateFormat="dd/MM/yyyy"
                      dayClassName={(date) =>
                        highlightSundays(date)
                          ? "react-datepicker__day--sunday"
                          : null
                      }
                      highlightDates={highlightDates}
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                variant="solid"
                colorScheme="green"
                onClick={updateEvent}
              >
                Update
              </Button>
              <Button colorScheme="blue" onClick={modalEdit.onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={modalAdd.isOpen} onClose={modalAdd.onClose}>
          <ModalOverlay />
          <ModalContent
            position="fixed"
            top="0"
            transform="translateX(-50%)"
            maxHeight="80vh"
            overflowY="auto"
          >
            <ModalHeader>Add Event</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Title <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleInputChange(e)}
                    required
                    id="title"
                    name="title"
                    placeholder="Enter Title..."
                    className={Styles.textInput}
                    value={inputFields.title}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}> Description </p>
                  <Textarea
                    onChange={(e) => handleInputChange(e)}
                    required
                    id="description"
                    name="description"
                    placeholder="Enter Description..."
                    className={Styles.textInput}
                    value={inputFields.description}
                    size="lg"
                    resize="vertical"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Category <span className={Styles.asterisk}>*</span>
                  </p>
                  <select
                    onChange={(e) => handleInputChange(e)}
                    required
                    id="category"
                    name="category"
                    className={Styles.textInput}
                    value={inputFields.category}
                  >
                    <option value="" disabled selected hidden>
                      Enter Category...
                    </option>
                    <option value="ACADEMIC">ACADEMIC</option>
                    <option value="GENERAL">GENERAL</option>
                    <option value="SPORTS">SPORTS</option>
                    <option value="CULTURAL">CULTURAL</option>
                    <option value="OTHER">OTHER</option>
                    <option value="HOLIDAY">HOLIDAY</option>
                    <option value="NON-ACADEMIC">NON-ACADEMIC</option>
                  </select>
                </div>
                <div className={Styles.form}>
                  Show in Events
                  <Switch
                    colorScheme="green"
                    style={{ margin: "5px" }}
                    checked={inputFields.event_visible}
                    onChange={handleInputChange}
                    name="event_visible"
                    size="md"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Date <span className={Styles.asterisk}>*</span>
                  </p>
                  <DatePicker
                    required
                    selected={inputFields.date}
                    id="date"
                    name="date"
                    value={inputFields.date}
                    onChange={(date) =>
                      handleInputChange({
                        target: { name: "date", value: date },
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    dayClassName={(date) =>
                      highlightSundays(date)
                        ? "react-datepicker__day--sunday"
                        : null
                    }
                    highlightDates={highlightDates}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                variant="solid"
                colorScheme="green"
                onClick={createEvent}
              >
                Add
              </Button>
              <Button colorScheme="blue" onClick={modalAdd.onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          size="xl"
          isOpen={modalSession.isOpen}
          onClose={modalSession.onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent className={Styles.customModalContent}>
            <ModalHeader>Academic Session</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <div className={Styles.academicSession}>
                  <div>
                    <Select
                      value={year}
                      required
                      onChange={(e) => setYear(e.target.value)}
                      name="year"
                      placeholder="Academic Year"
                    >
                      {yearOptions.map((year, index) => (
                        <option key={year} value={year}>
                          {`${year}-${(year + 1).toString().slice(-2)}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                {academicData.error ? (
                  <div className={Styles.noResult}>No Data Found</div>
                ) : academicData ? (
                  <div className={Styles.sessionBody}>
                    <div className={Styles.sessionLeft}>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          Start-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].start_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          Mid-term End-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].mid_term_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          End-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].end_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                    </div>
                    <div className={Styles.sessionRight}>
                      <div className={Styles.sessionLeftHead}>
                        <p className={Styles.daysText}>Total Mid-term Days</p>
                        <div>
                          <p>
                            {academicData?.data?.specification[0].mid_term_days}
                          </p>
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p className={Styles.daysText}>Total Annual Days</p>
                        <div style={{ marginLeft: "20px" }}>
                          <p>
                            {" "}
                            {academicData?.data?.specification[0].total_days}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.noResult}>
                    Please Select Academic Year
                  </div>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={modalSession.onClose}
                >
                  Close
                </Button>
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default ManagementCalendar;

import React from 'react';
import Styles from './InstitutionDashboard.module.scss';


const InstitutionDashboard = () => {
  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <div className={Styles.logo}>
          <img className={Styles.logoImage} src="https://picsum.photos/200" alt="logo" />
        </div>
        <h1 className={Styles.title}>Institute Dashboard</h1>
      </div>
      <div className={Styles.bodyContainer}>
        <h1 className={Styles.bodyContainerHeader}>
          Welcome, St Mary English Medium CBSE School
        </h1>
        <form>
          <div className={Styles.inputContainer}>
            <div className={Styles.inputRow}>
              <p className={Styles.inputLabel}>Institute Name</p>
              <input className={Styles.inputBox} type="text"  />
            </div>
            <div className={Styles.inputRow}>
              <p className={Styles.inputLabel}>Primary color</p>
              <input className={Styles.inputBox} type="text"  />
            </div>
            <div className={Styles.inputRow}>
              <p className={Styles.inputLabel}>Secondary color</p>
              <input className={Styles.inputBox} type="text"  />
            </div>
            <div className={Styles.inputRow}>
              <p className={Styles.inputLabel}>Logo</p>
              <input  type="file" accept="image/*"/>
            </div>
            <div className={Styles.inputRow}>
              <p className={Styles.inputLabel}>Cover Image</p>
              <input  type="file" accept="image/*"/>
            </div>
            <div className={Styles.submitRow}>
              <button className={Styles.submitButton}>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default InstitutionDashboard
import React from "react";
import { requestClassesAndSectionStudent } from "services/admin-dashboard/student.service";
import { requestClassesAndSection } from "services/common.service";

export const useClassAndSections = () => {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetchClassrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClassrooms = async () => {
    try {
      setRequestInProgress(true);
      let response = await requestClassesAndSection();
      response.map((item) => item.sections.push("All"));
      response.unshift({ class: "ALL", sections: ["ALL"] });
      // response.push({ class: "Staff", sections: ["STAFF"] });
      if (response.error) {
        console.error(response.error);
      }
      setDetails({ response });
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };

  return {
    loading: requestInProgress,
    classAndSections: details?.response || [],
  };
};
export const useClassAndSections2 = () => {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetchClassrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClassrooms = async () => {
    try {
      setRequestInProgress(true);
      let response = await requestClassesAndSection();
      response.map((item) => item.sections.push("All"));
      response.unshift({ class: "ALL", sections: ["ALL"] });
      response.push({ class: "STAFF", sections: ["STAFF"] });
      if (response.error) {
        console.error(response.error);
      }

      setDetails({ response });
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };

  return {
    loading: requestInProgress,
    classAndSections: details?.response || [],
  };
};
export const useClassAndSectionsWithoutAll = () => {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  React.useEffect(() => {
    fetchClassrooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClassrooms = async () => {
    try {
      setRequestInProgress(true);
      let response = await requestClassesAndSection();
      // response.push({ class: "Staff", sections: ["STAFF"] });
      if (response.error) {
        console.error(response.error);
      }

      setDetails({ response });
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };

  return {
    loading: requestInProgress,
    classAndSections: details?.response || [],
  };
};
export const useClassAndSectionsStudent = () => {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [details, setDetails] = React.useState(null);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    fetchClassrooms();
  }, []);

  const fetchClassrooms = async () => {
    try {
      setRequestInProgress(true);
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      let response = await requestClassesAndSectionStudent();
      response.map((item) => item.sections.push("All"));
      response.unshift({ class: "ALL", sections: ["ALL"] });
      if (response.error) {
        console.error(response.error);
      }
      setDetails({ response });
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      if (e === 403) {
        setRequestInProgress(true);
        setError(e);
        setTimeout(() => {
          setRequestInProgress(false);
        }, 1000);
        throw e;
      }

      setRequestInProgress(false);
    }
  };
  // React.useEffect(() => {
  //   console.log("hooks error", error);
  // }, [error]);

  return {
    loading: requestInProgress,
    classAndSections: details?.response || [],
    error,
  };
};
const hooks = {
  useClassAndSections,
  useClassAndSections2,
  useClassAndSectionsWithoutAll,
  useClassAndSectionsStudent,
};

export default hooks;

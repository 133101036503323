import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Spinner,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CategoriesBook from "./CategoriesBook";
import Books from "./Books"
import AdminDashboard from "services/admin-dashboard/library.service";

import { Icons } from "utilities/assets";
import { debounce } from "utilities/utils";
import Styles from "../Library.module.scss";

const operationTypeText = {
  EDIT: ["Edit", "Edit Category"],
  ADD: ["Add", "Add Category"],
};

export default function Categories() {
  // const [requestUpdateInProgress, setRequestUpdateInProgress] = useState(false)
  // const [requestGetInProgress, setRequestGetInProgress] = useState(false)
  // const [categories, setCategories] = useState([])
  // const [currentOperation, setCurrentOperation] = useState("ADD")
  // const [deletedCategoryId, setDeletedCategoryId] = useState('')
  // const [searchResult, setSearchResultList] = useState([]);
  // const [searchInput, setSearchInput] = useState("");
  // const [categoryNameInput, setCategoryNameInput] = useState("")
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // React.useEffect(() => {
  //   getBooksCategories();
  // }, []);

  // const getSearchCategory = async () => {
  //   setRequestGetInProgress(true);
  //   try {
  //     const data = await AdminDashboard.requestAllCategory({ category: searchInput });
  //     if (data.error) {
  //       console.error(data.error)
  //     }
  //     if (data && data.result && Array.isArray(data.result)) {
  //       setSearchResultList(data.result);
  //     }
  //     setRequestGetInProgress(false);
  //   } catch (e) {
  //     setRequestGetInProgress(false);
  //     console.error(e);
  //   }
  // }

  // const getBooksCategories = async () => {
  //   setRequestGetInProgress(true);
  //   try {
  //     const data = await AdminDashboard.requestAllCategory();
  //     if (data.error) {
  //       console.error(data.error);
  //     }
  //     if (data && Array.isArray(data)) {
  //       setCategories(data);
  //     }
  //     setRequestGetInProgress(false);
  //   } catch (e) {
  //     setRequestGetInProgress(false);
  //     console.error(e);
  //   }
  // };

  // const handleDeleteCategory = async (_id) => {
  //   setRequestUpdateInProgress(true)
  //   setDeletedCategoryId(_id)
  //   try {
  //     const data = await AdminDashboard.requestDeleteCategory({ _id });
  //     if (data) {
  //       if (data?.error) {
  //         console.error(data.error);
  //       } else {
  //         setRequestUpdateInProgress(false)
  //         setDeletedCategoryId('')
  //         getBooksCategories()
  //       }
  //     }
  //   } catch (e) {
  //     setRequestUpdateInProgress(false)
  //     setDeletedCategoryId('')
  //     console.error(e);
  //   }
  // };

  // const handleAddCategory = () => {
  //   onOpen();
  //   setCurrentOperation("ADD");
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setRequestUpdateInProgress(true);
  //     const response = await AdminDashboard.requestAddCategory({
  //       categoryName: categoryNameInput,
  //     });
  //     if (response.error) {
  //       console.error(response.error);
  //     }
  //     if (response) {
  //       setCategoryNameInput('')
  //       getBooksCategories();
  //       onClose()
  //     }
  //     setRequestUpdateInProgress(false);
  //   } catch (e) {
  //     setRequestUpdateInProgress(false);
  //     console.error(e);
  //   }
  // };

  // const handleInputChange = ({ target: { value } }) => {
  //   setSearchInput(value)
  // }

  // const searchCategory = debounce(function() {
  //   getSearchCategory()
  // }, 500)

  // React.useEffect(() => {
  //   if (searchInput.length > 0) {
  //     searchCategory()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchInput])

  // const { 0: modalTitle, 1: modalBtnTitle } = operationTypeText[currentOperation] || []
  // const categoriesList = (searchInput !== '' && searchInput.length > 0) ? searchResult : categories
  // const noResultFoundOnSearch = (searchInput !== '' && searchInput.length > 0) && searchResult.length === 0

  return (
    <div className={Styles.subWrapper}>
     <Tabs isLazy isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Books</Tab>
          <Tab>Category</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
           <Books/>
          </TabPanel>
          <TabPanel>
            <CategoriesBook />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <div className={Styles.header}>
        <p className={Styles.title}>All Categories</p>
        <div className={Styles.search}>
          <Input value={searchInput} onChange={handleInputChange} variant="filled" placeholder="Search category" />
          <div className={Styles.add} onClick={handleAddCategory}>
            <img className={Styles.addImage} src={Icons.add} alt="add" />
          </div>
        </div>
      </div>

      <div className={Styles.lists}>
        {requestGetInProgress ? (
          <div className={Styles.loaderWrapper}>
            <Spinner />
          </div>
        ) : (
          noResultFoundOnSearch ? (
            <div className={''}>No result found</div>
          ) : (
            categoriesList.map((i, idx) => (
              <div key={i["_id"] + idx} className={Styles.list}>
                <h1>{i.name}</h1>
                <div className={Styles.buttonRow}>
                  <button
                    onClick={() => handleDeleteCategory(i["_id"])}
                    className={Styles.button}
                  >
                    {i['_id'] === deletedCategoryId && requestUpdateInProgress ? (
                      <Spinner />
                    ) : (
                      <>{'Delete'}</>
                    )}
                  </button>
                </div>
              </div>
            ))
          ))
        }
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <div className={Styles.formGroup}>
                <label htmlFor="categoryName" className={Styles.formLabel}>
                  Category name
                </label>
                <input
                  required
                  value={categoryNameInput}
                  onChange={({ target: {value} }) => setCategoryNameInput(value)}
                  type="text"
                  id="categoryName"
                  name="categoryName"
                  placeholder="Category Name"
                />
                <button type="submit" className={Styles.formButton}>
                  {requestUpdateInProgress ? (
                    <Spinner />
                  ) : (
                    <>{modalBtnTitle}</>
                  )}
                </button>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </div>
  );
}

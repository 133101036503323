import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import Styles from "../Certificate/DownloadManual.module.scss";
import { Button, useToast } from "@chakra-ui/react";
import moment from "moment";
import HeaderA5 from "./HeaderMain/Header";
import Header from "./BonafideHeader/Header";
import jsPDF from "jspdf";
const AutoConductDownload = () => {
  const [values, setValues] = useState("");
  const [inputFields, setInputFields] = useState(null);
  const [data, setdata] = useState(null);
  const [edit, setEdit] = useState(false);
  const [downloadA5,setDownloadA5] = useState(false)
  const toast = useToast();
  const location = useLocation();
  useEffect(() => {
    setdata(location.state.inputFields);
  }, []);

  console.log("data", data && data);
  const date = new Date();
  const dates = moment(date).format("DD/MM/YYYY");

  const handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a4");
    let pdf = await document.getElementById("pdfcerti");
    view
      .html(pdf)
      .then(() => {
        view.save(` ${inputFields && inputFields.name} conduct.pdf`);
        // PdfRequestUpdate();
      })
      .catch((err) => console.log(err, "err"));
  };
  const handleCertiDownloadA5 = async () => {
    let view = new jsPDF("portrait", "pt", "a5");
    let pdf = await document.getElementById("pdfcerti");
    view
      .html(pdf)
      .then(() => {
        view.save(` ${inputFields && inputFields.name} conduct.pdf`);
        // PdfRequestUpdate();
      })
      .catch((err) => console.log(err, "err"));
  };
  const upadtedData = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };
  const handleEditMode = () => {
    setEdit(true);
    toast({
      title: "Edit",
      description: "Now you can edit Certificate",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
  };
  const handleEditModeDisabled = () => {
    setEdit(false);
    toast({
      title: "Save",
      description: "Certificate edit saved ",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
  };
   
  const fetchStudentProfileDetails = async () => {
    try {
      const response = await requestStudentDetailsById({
        studentId: location.state.id,
      });
      // setdata(response);
      // if (response.error) {
      //   console.error(response.error);
      // }
      // console.log(data);
      // const classroom_response = await requestAllSubjects({ class_id: response._class_id });
      // console.log('calss', classroom_response)
      // if (response && classroom_response) {
      if (response) {
        // console.log("student resp", response);
        // const DOB = response?.personel?.dob;
        // let dob = DOB && new Date(DOB).toLocaleDateString();
        // if (dob == "Invalid Date") {
        //   if (DOB.includes("-")) {
        //     dob = new Date(DOB.split("-").reverse().join("/"));
        //   } else if (DOB.includes("/")) {
        //     const [day, month, year] = DOB.split("/");
        //     const dateString = [month, day, year].join("/");
        //     dob = new Date(dateString);
        //   }
        // } else {
        //   if (typeof new Date(DOB) === "object") {
        //     dob = new Date(DOB);
        //   } else {
        //     dob = DOB;
        //   }
        // }

        // const _response = {
        //   ...response,
        //   name: response.name,
        //   email: response.email,
        //   personel: {
        //     ...response.personel,
        //     dob,
        //     gender: response.personel.gender,
        //     present_address: response.personel.present_address,
        //     permanent_address: response.personel.permanent_address,
        //     mother_tongue: response.personel.mother_tongue,
        //     religion: response.personel.religion,
        //     sub_religion: response.personel.sub_religion,
        //     blood_group: response.personel.blood_group,
        //     aadhar_number: response.personel.aadhar_number,
        //     nationlity: response.personel.nationlity,
        //     signed_display_image: await getSignedUrlLink(
        //       response.personel.profile_url
        //     ),
        //   },
        //   academic: {
        //     ...response.academic,
        //     class: response.academic.class,
        //     section: response.academic.section,
        //     admission_number: response.academic.admission_number,
        //     admission_year: response.academic.admission_year,
        //   },
        //   father: {
        //     ...response.father,
        //     father_name: response.father.father_name,
        //     father_email: response.father.father_email,
        //     father_phone: response.father.father_phone,
        //     father_occupation: response.father.father_occupation,
        //     father_qualification: response.father.father_qualification,
        //     signed_father_display_image: await getSignedUrlLink(
        //       response.father.father_photo_url
        //     ),
        //   },
        //   mother: {
        //     ...response.mother,
        //     mother_name: response.mother.mother_name,
        //     mother_phone: response.mother.mother_phone,
        //     mother_occupation: response.mother.mother_occupation,
        //     mother_qualification: response.mother.mother_qualification,
        //     signed_mother_display_image: await getSignedUrlLink(
        //       response.mother.mother_photo_url
        //     ),
        //   },
        //   canteen_choices: response.canteen_choices,
        //   account_status: response.account_status,
        //   transport_choices: response.transport_choices,
        //   // electives: classroom_response.electives,
        //   subject_choices: response.subject_choices,
        // };
        setInputFields(response);
        // return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  useEffect(() => {
    fetchStudentProfileDetails();
    // setcertificateType(location.state.data);
  }, []);
  return (
    <>
      <div className={Styles.mainContainer}>
      {
        downloadA5 == false ?
        <div className={Styles.conductCerticateWrapper} id="pdfcerti">
          <div>
            <Header />
          </div>
          <div>
            <div className={Styles.HeadingContainer}>
              <p className={Styles.ConductcertiHeading}>CONDUCT CERTIFICATE</p>
            </div>
            <div className={Styles.conductData}>
              <p>
                I hereby certify that&nbsp;{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="name"
                    value={data?.name}
                  />
                ) : (
                  <span className={Styles.dynaicData}>{inputFields?.name}</span>
                )}
                &nbsp;was a student in this school from&nbsp;
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="start_date"
                    value={data?.start_date}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicData}>{data?.start_date}</span>
                )}
                &nbsp;to{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="end_date"
                    value={data?.end_date}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicData}>{data?.end_date}</span>
                )}{" "}
                - Grade{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="startingGrade"
                    value={data?.startingGrade}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicData}>
                    {data?.startingGrade}
                  </span>
                )}{" "}
                to Grade{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="lastGrade"
                    value={data?.lastGrade}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicData}>{inputFields?.academic?.class}</span>
                )}
                .
              </p>
            </div>
            <div className={Styles.dynaicData}>
              <p style={{ marginTop: "1vw" }}>
                {inputFields?.personel?.gender === "male" || "Male" || "MALE" ? "His" : "Her"}{" "}
                conduct and character were good
              </p>
            </div>
            <div style={{ marginTop: "1vw" }}>
              <p>
                {" "}
                <span className={Styles.dynaicData}>Date</span>: {dates}
              </p>
            </div>
            <p style={{ marginTop: "1vw" }} className={Styles.principalSign}>
              <span className={Styles.dynaicData}>Principal</span>
            </p>
          </div>
        </div>
        :
        <div className={Styles.conductCerticateWrapperA5} id="pdfcerti">
          <div>
            <HeaderA5 />
          </div>
          <div>
            <div className={Styles.HeadingContainer}>
              <p className={Styles.ConductcertiHeadingA5}>CONDUCT CERTIFICATE</p>
            </div>
            <div className={Styles.conductDataA5}>
              <p>
                I hereby certify that&nbsp;{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="name"
                    value={data?.name}
                  />
                ) : (
                  <span className={Styles.dynaicDataA5}>{inputFields?.name}</span>
                )}
                &nbsp;was a student in this school from&nbsp;
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="start_date"
                    value={data?.start_date}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicDataA5}>{data?.start_date}</span>
                )}
                &nbsp;to{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="end_date"
                    value={data?.end_date}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicDataA5}>{data?.end_date}</span>
                )}{" "}
                - Grade{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="startingGrade"
                    value={data?.startingGrade}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicDataA5}>
                    {data?.startingGrade}
                  </span>
                )}{" "}
                to Grade{" "}
                {edit == true ? (
                  <input
                    className={Styles.inputFieldTag}
                    name="lastGrade"
                    value={data?.lastGrade}
                    onChange={(e) => upadtedData(e)}
                  />
                ) : (
                  <span className={Styles.dynaicDataA5}>{inputFields?.academic?.class}</span>
                )}
                .
              </p>
            </div>
            <div className={Styles.dynaicDataA5}>
              <p style={{ marginTop: "1vw" }}>
                {inputFields?.personel?.gender === "male" || "Male" || "MALE" ? "His" : "Her"}{" "}
                conduct and character were good
              </p>
            </div>
            <div style={{ marginTop: "1vw" }}>
              <p>
                {" "}
                <span className={Styles.dynaicDataA5}>Date : {dates}</span>
              </p>
            </div>
            <p style={{ marginTop: "1vw" }} className={Styles.principalSign}>
              <span className={Styles.dynaicDataA5}>Principal</span>
            </p>
          </div>
        </div>
      }
       
        <div>
          <Button
            className={Styles.downloadButoon}
            onClick={()=>{handleCertiDownload();setDownloadA5(false)}}
          >
            Download (A4 Recommended)
          </Button>
          <Button
            className={Styles.downloadButoon}
            onClick={()=>{handleCertiDownloadA5();setDownloadA5(true)}}
          >
            Download (A5)
          </Button>
          {edit == true ? (
            <Button
              className={Styles.downloadButoon}
              onClick={handleEditModeDisabled}
            >
              {" "}
              Save{" "}
            </Button>
          ) : (
            <Button className={Styles.downloadButoon} onClick={handleEditMode}>
              Edit
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AutoConductDownload;

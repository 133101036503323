import React,{ useState } from 'react'
import Styles from "../Certificate/ManualTcPage.module.scss"
import {useNavigate,useParams} from "react-router-dom"
const initailvalue = {
    name:"",
    start_date:"",
    end_date:"",
    startingGrade:"",
    lastGrade:"",
    gender:""

}
const ManagementAutoConductCertificate = () => {
  const [inputFields, setInputFields] = useState(null);
const navigate = useNavigate()
const params = useParams()
const id = params.id
  const transferData = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };
  const onSubmit = () => {
    //   console.log("id is here",id)
    //   console.log("id", studentData)
    navigate(`download`, {
      state: {
        inputFields,id
      },
    });
  };
  return <>
    <div className={Styles.allField}>
    <div className={Styles.tagContanier}>
        <p>Start date :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="Start date"
          type="text"
          name="start_date"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>End date :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="End date"
          type="text"
          name="end_date"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>First Grade :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="First Grade"
          type="text"
          name="startingGrade"
          onChange={(e) => transferData(e)}
        />
          </div>
      </div>
      <div>
        <button
          onClick={() => {
            onSubmit();
          }}
          style={{
            marginTop: "2vw",
            backgroundColor: "red",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
        >
          Generate
        </button>
      </div>
      </div>
  </>
}

export default ManagementAutoConductCertificate


import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function requestAddAttendance(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/add";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestGetAttendance(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/get";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestGetClassStats(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/stat";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUpdateAttendance(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/update";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestDeleteAttendance(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/delete";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAttendanceClass(payload = {}) {
  const url = BASE_URL + "/admin/attendance/class/days";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestHolidays(payload = {}) {
  const url = BASE_URL + "/admin/attendance/holidays";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestStatsOne(payload = {}) {
  const url = BASE_URL + "/admin/attendance/statistics/getOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUpdateOne(payload = {}) {
  const url = BASE_URL + "/admin/attendance/statistics/updateOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestStatisticsStudentDetails(payload = {}) {
  const url = BASE_URL + "/admin/attendance/statistics/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

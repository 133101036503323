import React, { useState } from "react";
import Styles from "./ManualTcPage.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";

const initailvalue = {
  name: "",
  Fathername: "",
  Mothername: "",
  Grade: "",
  dob: "",
  gender: "",
  address: "",
};
const ManualBonafide = () => {
  const [inputFields, setInputFields] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const transferData = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };
  const onSubmit = () => {
    if (
      !inputFields?.name ||
      !inputFields?.Fathername ||
      !inputFields?.Mothername ||
      !inputFields?.Grade ||
      !inputFields?.dob ||
      !inputFields?.gender ||
      !inputFields?.address
    ) {
      toast({
        title: "Please fill in required fields",
        description: "All fields marked with an asterisk (*) are required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    navigate(`download`, {
      state: {
        inputFields,
      },
    });
  };
  return (
    <>
      <div className={Styles.allField}>
        <div className={Styles.tagContanier}>
          <p>
            Name <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Name"
              type="text"
              name="name"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Father Name <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Father Name"
              type="text"
              name="FatherName"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Mother Name <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Mother Name"
              type="text"
              name="Mothername"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Grade <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Grade 1"
              type="text"
              name="Grade"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Date of Birth <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="15-12-2015 (Fiftheenth day of December Two thousand fifteen)"
              type="text"
              name="dob"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Gender <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Gender"
              type="text"
              name="gender"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Address <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Kannarpady, Udupi"
              type="text"
              name="address"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              onSubmit();
            }}
            colorScheme="green"
            mt={2}
            // style={{
            //   marginTop: "2vw",
            //   backgroundColor: "red",
            //   color: "white",
            //   borderRadius: "4px",
            //   padding: "5px 8px 5px 8px",
            //   fontWeight: "bold",
            // }}
          >
            Generate
          </Button>
        </div>
      </div>
    </>
  );
};

export default ManualBonafide;

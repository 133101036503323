import React, { useState } from 'react'
import { useEffect } from 'react'
import Services from 'services/admin-dashboard/birthday.service'
import Styles from './Birthday.module.scss'
import moment from 'moment'
import defaultUser from "assets/icons/User.png";

const UpcomingBirthday = () => {
    const [upcomingbirthday,setComingBirthday] = useState('')
    const upComingBirthdayList = async()=>{
        const upcomingResponse = await Services.getUpcomingBirthday()
        if(upcomingResponse){
            // console.log("upcomingResponse",upcomingResponse)
            setComingBirthday(upcomingResponse.users)
        }
    }
    useEffect(()=>{
        upComingBirthdayList()
    },[])
  return <>
   <div className={Styles.body}>
      <p className={Styles.header}>Upcoming Birthdays</p>
      <div className={Styles.upcomingcards}>
        {upcomingbirthday &&
            upcomingbirthday.map((data,id) => {
            return (
              <div className={Styles.upcomingcard1} id ={id}>
                <div className={Styles.imageContainer}>
                  <img
                    className={Styles.profileImage}
                    src={data?.signed_url ||
                        data?.personel?.profile_url.includes("http")
                        ? data.url
                          ? data.url
                          : data?.personel?.profile_url
                        : defaultUser}
                    alt="profile"
                  />
                </div>
                <p className={Styles.upcomingname}>{data.name}</p>
                <p className={Styles.class}>{moment(data.personel.dob).format("DD-MM-YYYY")} </p>
                <p className={Styles.class}>Grade
                  {data.academic.class}-
                  {data.academic.section}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  </>
}

export default UpcomingBirthday

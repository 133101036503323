import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Spinner, Tooltip, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  requestCreateUser,
  requestUploadTeacherImages,
} from "services/common.service";
import { fileToURI } from "utilities/utils";
import moment from "moment";
import { requestListByTeacherDepartment } from "services/admin-dashboard/staff.service";
import "react-datepicker/dist/react-datepicker.css";
import Styles from "./AddStaffProfile.module.scss";
import { AiOutlineCheck } from "react-icons/ai";
import { MdContentPaste } from "react-icons/md";

const PLACEHOLDER_IMAGE_URL =
  "https://betachon.com/wp-content/uploads/2019/03/upload-your-logo-here-21052-p-800x800.png";

const ManagementAddStaffProfile = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [imageInputField, setImageInputFields] = useState({
    profile_url: "",
    file: null,
  });

  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    employeeId: "",
    designation: "",
    department: "",
    phoneNumber: "",
    password: "",
    personel: {
      gender: "",
      dob: "",
      present_address: "",
      permanent_address: "",
      mother_tongue: "",
      religion: "",
      sub_religion: "",
      aadhar_number: "",
      nationlity: "",
      qualification: "",
      martial_status: "",
    },
  });

  const uploadImagesToCloud = async () => {
    try {
      const response = await requestUploadTeacherImages({
        profile_file: imageInputField.file,
        teacherId: inputFields.name,
        EMP_ID: inputFields.employeeId,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return { profile_url: response.teacher_profile || "" };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    let imageResponse = {};
    if (imageInputField.file) {
      imageResponse = await uploadImagesToCloud();
    }

    createTeacherHandler(imageResponse);
  };

  const createTeacherHandler = async (imageUrls) => {
    try {
      const {
        email,
        name,
        personel,
        password,
        phoneNumber,
        employeeId,
        department,
        designation,
      } = inputFields;
      const { nationlity, dob, ...rest } = personel;
      const { profile_url } = imageUrls;
      const payload = {
        role: "teacher",
        email,
        name,
        password,
        department,
        designation,
        phoneNumber,
        emp_id: employeeId,
        personel: {
          ...rest,
          profile_url: profile_url,
          nationlity: personel.nationlity,
          dob: dob && moment(dob).format("MM/DD/YYYY"),
        },
      };
      const response = await requestCreateUser(payload);
      setRequestInProgress(false);
      if (response && response.user_id) {
        toast({
          title: "Staff added",
          description: "Created new staff",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/admin/dashboard/staff");
      }
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: error,
        description: "Staff creation failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error(error);
    }
  };

  const handleSetImage = (e, fieldName) => {
    if (fieldName === "isUpload") {
      setIsUpload(true);
    }
    const {
      target: { name, files },
    } = e;
    if (files && files[0]) {
      setImageInputFields((prev) => ({
        ...prev,
        [name]: files && files[0],
      }));
      fileToURI(files[0]).then((uri) => {
        setImageInputFields((prev) => ({
          ...prev,
          profile_url: uri,
        }));
      });
    }
  };

  const handleInputChange = (e, parentField) => {
    if (parentField === "pastePermanentAddress") {
      setInputFields((prev) => ({
        ...prev,
        personel: {
          ...prev.personel,
          present_address: prev.personel.permanent_address,
        },
      }));
      return;
    }

    if (typeof e.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, type, checked },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        password: value,
        "select-one": value,
      }[type];

      if (parentField && typeof parentField === "string") {
        setInputFields((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else {
      if (parentField && typeof parentField === "object") {
        const [parent, child] = parentField;
        setInputFields((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: e,
          },
        }));
      }
    }
  };

  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };

  useEffect(() => {
    getTeacherDepartment();
  }, []);

  return (
    <div className={Styles.body}>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>STAFF ADD FORM</p>
      <div className={Styles.profile}>
        <img
          className={Styles.profileImage}
          src={imageInputField.profile_url || PLACEHOLDER_IMAGE_URL}
          alt="profile"
        />
        {isUpload ? (
          <span className={Styles.pencilIcon}>
            <AiOutlineCheck />
          </span>
        ) : (
          ""
        )}
        <label htmlFor="student">
          <div
            style={{
              borderRadius: "5px",
              marginTop: "8px",
              color: "white",
              borderRadius: "5px",
              padding: "8px",
              marginTop: "8px",
              cursor: "pointer",
              background: "#228b15",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              fontWeight: "700",
            }}
          >
            Add Profile
          </div>
          <input
            type="file"
            id="student"
            name="student"
            accept="image/*"
            hidden
            onChange={(e) => handleSetImage(e, "isUpload")}
          />
        </label>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <button type="button" className={Styles.heading}>
            Personal Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Name <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.name}
                name="name"
                type="text"
                placeholder="Enter Teacher Name"
                className={Styles.textInput}
                minlength="4"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Email <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                name="email"
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.email}
                type="email"
                placeholder="Enter Email"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Password <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                name="password"
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.password}
                type="text"
                placeholder="Enter Password"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Phone No. <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex" }}>
                <select className={Styles.optionLists}>
                  <option>+91</option>
                </select>
                <input
                  name="phoneNumber"
                  onChange={(e) => handleInputChange(e, "")}
                  value={inputFields.phoneNumber}
                  required
                  type="number"
                  placeholder="XXXXX XXXXX"
                  className={Styles.textInput}
                  maxLength="10"
                />
              </div>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Gender <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                name="gender"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.gender}
                required
                placeholder="Select Gender"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Qualification <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                type="text"
                placeholder="Enter Qualification"
                name="qualification"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.qualification}
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Blood Group <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                onChange={(e) => handleInputChange(e, "personel")}
                name="blood_group"
                value={inputFields.personel?.blood_group}
                placeholder="Select Blood Group"
                required
              >
                <option value="A(+VE)">A(+VE)</option>
                <option value="B(+VE)">B(+VE)</option>
                <option value="A(-VE)">A(-VE)</option>
                <option value="B(-VE)">B(-VE)</option>
                <option value="O(+ve)">O(+ve)</option>
                <option value="O(-ve)">O(-ve)</option>
                <option value="AB(+VE)">AB(+VE)</option>
                <option value="AB(-VE)">AB(-VE)</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Permanent Address <span className={Styles.asterisk}>*</span>
              </p>
              <textarea
                required
                name="permanent_address"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.permanent_address}
                rows="4"
                cols="50"
                className={Styles.textInput}
                placeholder="Enter Permanent Address"
              />
            </div>
            <div className={Styles.form}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p className={Styles.placeholder}>
                  Present Address <span className={Styles.asterisk}>*</span>
                </p>
                <Tooltip
                  placement="top"
                  label="Paste the same data of Permanent Address"
                >
                  <div
                    onClick={() =>
                      handleInputChange(null, "pastePermanentAddress")
                    }
                  >
                    <MdContentPaste fontSize={22} cursor="pointer" />
                  </div>
                </Tooltip>
              </div>
              <textarea
                required
                name="present_address"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.present_address}
                rows="4"
                cols="50"
                className={Styles.textInput}
                placeholder="Enter Present Address"
              />
            </div>
          </div>
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <button type="button" className={Styles.heading}>
            Employee Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Employee ID <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.employeeId}
                name="employeeId"
                placeholder="Enter Employee ID"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Designation <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.designation}
                name="designation"
                type="text"
                placeholder="Enter Designation"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Department <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                value={inputFields.department}
                onChange={(e) => handleInputChange(e, "")}
                name="department"
                placeholder="Select Department"
                required
              >
                <option value="Driver">Driver</option>
                <option value="Office">Office</option>
                <option value="Teaching">Teaching</option>
                <option value="Non-Teaching">Non-Teaching</option>
                <option value="Test Teacher">Test Teacher</option>
              </Select>
            </div>
          </div>

          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <button className={Styles.heading} type="button">
            Other Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Aadhaar Number <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                name="aadhar_number"
                type="text"
                placeholder="XXXX XXXX XXXX"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.aadhar_number}
                className={Styles.textInput}
                minlength="12"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Martial Status <span className={Styles.asterisk}>*</span>
              </p>

              <Select
                required
                name="martial_status"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.martial_status}
                placeholder="Select Marital Status"
              >
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Other">Other</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Nationality <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                required
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.nationlity}
                placeholder="Select Nationality"
                name="nationlity"
              >
                <option value="INDIAN">INDIAN</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Mother Tongue <span className={Styles.asterisk}>*</span>
              </p>
              <input
                name="mother_tongue"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.mother_tongue}
                required
                type="text"
                className={Styles.textInput}
                placeholder="Enter Mother Tongue"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Religion <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                name="religion"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.religion}
                placeholder="Select Religion"
              >
                <option value="HINDU">HINDU</option>
                <option value="MUSLIM">MUSLIM</option>
                <option value="CHRISTIAN">CHRISTIAN</option>
                <option value="OTHER">OTHER</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Sub-Religion</p>
              <input
                name="sub_religion"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.sub_religion}
                // required
                type="text"
                placeholder="Enter Sub-Religion"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Date of Birth <span className={Styles.asterisk}>*</span>
              </p>
              <DatePicker
                required
                name="dob"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                onChange={(e) => handleInputChange(e, ["personel", "dob"])}
                value={inputFields.personel.dob}
                selected={inputFields.personel.dob}
              />
            </div>
          </div>
        </div>
        <div className={Styles.buttonRow}>
          <button type="submit" className={Styles.button}>
            {requestInProgress ? <Spinner /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManagementAddStaffProfile;

import React, { useEffect, useState } from "react";
import Styles from "./ReportCentre.module.scss";
import Bus from "assets/icons/School Bus.png";
import { Button, Checkbox, Select, useToast } from "@chakra-ui/react";
import { useClassAndSections } from "hooks/classAndSection";
import { requestEnrolledStudentsDetails } from "services/management-dashboard/transport.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import moment from "moment";
const EnrolledBusStudentReport = () => {
  const toast = useToast();
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const initialFieldsState = {
    studentName: true,
    class: true,
    section: true,
    busNumber: true,
    routeNumber: true,
    amount: true,
    paid: true,
    stop: true,
    phoneNumber: false,
    term1: true,
    term2: true,
    morning: true,
    evening: true,
    duration: false,
    trialPeriod: false,
    date: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [data, setData] = useState([]);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const fileName = `SMU-Enrolled-Students-Report-${inputFields.class}${inputFields.section}`;
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleExportClick = async () => {
    if (!inputFields.class || !inputFields.section) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      class: inputFields.class,
      section: inputFields.section,
    };
    const res = await requestEnrolledStudentsDetails(payload);
    if (res) {
      let newArray = [];
      res &&
        res.map((dataValue) => {
          let obj = {};
          if (fields.studentName) {
            obj["Name"] = dataValue.name;
          }
          if (fields.class) {
            obj["Class"] = dataValue.class;
          }
          if (fields.section) {
            obj["Section"] = dataValue.section;
          }
          if (fields.busNumber) {
            obj["Bus Number"] = dataValue.bus_number;
          }
          if (fields.routeNumber) {
            obj["Route Number"] = dataValue.route_number;
          }
          if (fields.stop) {
            obj["Stop"] = dataValue.stop;
          }
          if (fields.amount) {
            obj["Amount"] = dataValue.amount;
          }
          if (fields.paid) {
            obj["Fees Status"] = dataValue.paid == false ? "Unpaid" : "Paid";
          }
          if (fields.phoneNumber) {
            obj["Phone Number"] = dataValue.phone_code + dataValue.phoneNumber;
          }
          if (fields.term1) {
            obj["Term 1 Fee"] = dataValue.term1;
          }
          if (fields.term2) {
            obj["Term 2 Fee"] = dataValue.term2;
          }
          if (fields.morning) {
            obj["Morning Timing"] = dataValue.morning;
          }
          if (fields.evening) {
            obj["Evening Timing"] = dataValue.evening;
          }
          if (fields.duration) {
            obj["Duration(in months)"] = dataValue.duration;
          }
          if (fields.trialPeriod) {
            obj["Trial Period"] = dataValue.is_trial == false ? "No" : "True";
          }
          if (fields.date) {
            obj["Date of Joining"] = moment(dataValue.date).format(
              "DD/MM/YYYY"
            );
          }
          newArray.push(obj);
        });
      setData(newArray);
      if (newArray.length === 0) {
        toast({
          title: "No students found.",
          variant: "left-accent",
          position: "bottom-left",
          status: "warning",
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img src={Bus} alt="Bus Icon" className={Styles.moduleIcon} />
              <p className={Styles.moduleName}>BUS TRANSPORT MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Other Details
                  </button>
                </div>
              </div>
              <>
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.routeNumber}
                    onChange={() => {
                      setFields({
                        ...fields,
                        routeNumber: !fields.routeNumber,
                      });
                    }}
                  >
                    Route Number
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term1}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term1: !fields.term1,
                      });
                    }}
                  >
                    Term 1 Fee
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term2}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term2: !fields.term2,
                      });
                    }}
                  >
                    Term 2 Fee
                  </Checkbox>
                </div>
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.morning}
                    onChange={() => {
                      setFields({
                        ...fields,
                        morning: !fields.morning,
                      });
                    }}
                  >
                    Morning Timing
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.evening}
                    onChange={() => {
                      setFields({
                        ...fields,
                        evening: !fields.evening,
                      });
                    }}
                  >
                    Evening Timing
                  </Checkbox>
                </div>
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.duration}
                    onChange={() => {
                      setFields({
                        ...fields,
                        duration: !fields.duration,
                      });
                    }}
                  >
                    Duration
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.trialPeriod}
                    onChange={() => {
                      setFields({
                        ...fields,
                        trialPeriod: !fields.trialPeriod,
                      });
                    }}
                  >
                    Trial Period
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.date}
                    onChange={() => {
                      setFields({
                        ...fields,
                        date: !fields.date,
                      });
                    }}
                  >
                    Date of Joining
                  </Checkbox>
                </div>
              </>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img src={Bus} alt="Bus Icon" className={Styles.mobilemoduleIcon} />
            <p className={Styles.mobilemoduleName}>BUS TRANSPORT MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Other Details
                  </button>
                </div>
              </div>
              <>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.routeNumber}
                    onChange={() => {
                      setFields({
                        ...fields,
                        routeNumber: !fields.routeNumber,
                      });
                    }}
                  >
                    Route Number
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term1}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term1: !fields.term1,
                      });
                    }}
                  >
                    Term 1 Fee
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term2}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term2: !fields.term2,
                      });
                    }}
                  >
                    Term 2 Fee
                  </Checkbox>
                </div>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.morning}
                    onChange={() => {
                      setFields({
                        ...fields,
                        morning: !fields.morning,
                      });
                    }}
                  >
                    Morning Timing
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.evening}
                    onChange={() => {
                      setFields({
                        ...fields,
                        evening: !fields.evening,
                      });
                    }}
                  >
                    Evening Timing
                  </Checkbox>
                </div>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.duration}
                    onChange={() => {
                      setFields({
                        ...fields,
                        duration: !fields.duration,
                      });
                    }}
                  >
                    Duration
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.trialPeriod}
                    onChange={() => {
                      setFields({
                        ...fields,
                        trialPeriod: !fields.trialPeriod,
                      });
                    }}
                  >
                    Trial Period
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.date}
                    onChange={() => {
                      setFields({
                        ...fields,
                        date: !fields.date,
                      });
                    }}
                  >
                    Date of Joining
                  </Checkbox>
                </div>
              </>
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnrolledBusStudentReport;

import React, { useEffect, useState } from "react";
import Styles from "./StaffTimetable.module.scss";
import { Select } from "@chakra-ui/react";
import { requestAllTeacherTimeTable } from "services/staff-dashboard/timetable.service";

const StaffTimetable = () => {
  const [selectedDay, setSelectedDay] = useState("");
  const [timeTable, setTimeTable] = useState("");
  const weekday = [
    "Sunday",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  const days = new Date();
  const day = weekday[days.getDay()];

  const teacherTimeTable = async () => {
    if (selectedDay === "") {
      setSelectedDay(day);
      return;
    }
    const payload = { day: selectedDay };
    const res = await requestAllTeacherTimeTable(payload);
    setTimeTable(res.TimeTable);
  };

  useEffect(() => {
    teacherTimeTable();
  }, [selectedDay]);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>TIMETABLE</p>
        </div>
      </div>
      <div className={Styles.body}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "20%",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Select Day</p>
            <Select
              name="day"
              placeholder="Select Day"
              value={selectedDay}
              style={{ border: "2px solid black", marginTop: "10px" }}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              <option value="MONDAY">Monday</option>
              <option value="TUESDAY">Tuesday</option>
              <option value="WEDNESDAY">Wednesday</option>
              <option value="THURSDAY">Thursday</option>
              <option value="FRIDAY">Friday</option>
              <option value="SATURDAY">Saturday</option>
            </Select>
          </div>
        </div>
        <div className={Styles.cards}>
          <div className={Styles.card}>
            <div className={Styles.header}>
              <p className={Styles.title}>{selectedDay}</p>
            </div>
            <div className={Styles.content}>
              <div className={Styles.contentItem}>
                {timeTable &&
                  timeTable.map((data) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8px",
                          }}
                        >
                          <div className={Styles.left}>
                            <p className={Styles.name}>{data.subject_name}</p>
                            <p className={Styles.class}>
                              {data.startTime} - {data.endTime}
                            </p>
                          </div>
                          <div className={Styles.right}>
                            <p className={Styles.name}>
                              Grade-{data.class}&nbsp;|&nbsp;{data.section}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffTimetable;

import React, { useEffect, useState } from "react";
import Styles from "./EditTimetable.module.scss";
import Edit from "../../../assets/icons/edit.png";
import Add from "../../../assets/icons/addWhite.png";
import TimePicker from "react-time-picker";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  useToast,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import AdminDashboard from "services/admin-dashboard/timetable.service";
import { useParams } from "react-router-dom";
import deleteIcon from "assets/icons/deleteIcon.png";
import { MdEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styles.css";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
const EditTimetable = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const params = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditOpen, setEditOpen] = useState(false);
  const onEditClose = () => setEditOpen(false);
  const [date, setDate] = React.useState(new Date());
  const [inputFields, setInputFields] = useState({});
  const [subjectList, setSubjectList] = useState("");
  const [updateInputFields, setUpdateInputFields] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [timetable, setTimetable] = useState({});
  const [isOther, setOther] = useState(false);
  const [isUpdateOther, setUpdateOther] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [disable, setDisable] = useState(true);
  const deleteTeacherTimeTable = useDisclosure();
  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
    setDisable(false);
  };
  const handleUpdateInputChange = ({ target: { name, value } }) => {
    setUpdateInputFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateStartTimeChange = (event) => {
    let idx = event.indexOf(":");
    let hour = event.substring(0, idx);
    let startHour = hour;
    let minutes = event.substring(5, idx);
    hour = Number(hour);
    let ampm;
    if (hour >= 0 && hour <= 11) {
      ampm = "AM";
    } else {
      if (hour == 12) {
        ampm = "PM";
        hour = parseInt(event);
        event = hour + minutes;
      } else {
        ampm = "PM";
        hour = parseInt(event) - 12;
        event = hour + minutes;
      }
    }
    let fullTime = event + " " + ampm;
    setUpdateInputFields({
      ...updateInputFields,
      startTime: fullTime,
      hour: startHour,
    });
  };
  const handleUpdateEndTimeChange = (event) => {
    let idx = event.indexOf(":");
    let hour = event.substring(0, idx);
    let endingHour = hour;
    let minutes = event.substring(5, idx);
    hour = Number(hour);
    let ampm;
    if (hour >= 0 && hour <= 11) {
      ampm = "AM";
    } else {
      if (hour == 12) {
        ampm = "PM";
        hour = parseInt(event);
        event = hour + minutes;
      } else {
        ampm = "PM";
        hour = parseInt(event) - 12;
        event = hour + minutes;
      }
    }
    let fullTime = event + " " + ampm;
    setUpdateInputFields({
      ...updateInputFields,
      endTime: fullTime,
      ehour: endingHour,
    });
  };
  const handleStartTimeChange = (event) => {
    let idx = event.indexOf(":");
    let hour = event.substring(0, idx);
    let startHour = hour;
    let minutes = event.substring(5, idx);
    hour = Number(hour);
    let ampm;
    if (hour >= 0 && hour <= 11) {
      ampm = "AM";
    } else {
      if (hour == 12) {
        ampm = "PM";
        hour = parseInt(event);
        event = hour + minutes;
      } else {
        ampm = "PM";
        hour = parseInt(event) - 12;
        event = hour + minutes;
      }
    }

    let fullTime = event + " " + ampm;
    setInputFields({ ...inputFields, startTime: fullTime, hour: startHour });
  };

  const handleEndTimeChange = (event) => {
    let idx = event.indexOf(":");
    let hour = event.substring(0, idx);
    let endingHour = hour;
    let minutes = event.substring(5, idx);
    hour = Number(hour);
    let ampm;
    if (hour >= 0 && hour <= 11) {
      ampm = "AM";
    } else {
      if (hour == 12) {
        ampm = "PM";
        hour = parseInt(event);
        event = hour + minutes;
      } else {
        ampm = "PM";
        hour = parseInt(event) - 12;
        event = hour + minutes;
      }
    }
    let fullTime = event + " " + ampm;
    setInputFields({ ...inputFields, endTime: fullTime, ehour: endingHour });
  };
  useEffect(() => {
    if (inputFields?.subject_name === "Other") setOther(true);
    else setOther(false);
  }, [inputFields?.subject_name]);
  useEffect(() => {
    if (updateInputFields?.subject_name === "Other") setUpdateOther(true);
    else setUpdateOther(false);
  }, [updateInputFields?.subject_name]);
  const resetInputFields = () => {
    setInputFields({});
  };
  const handleCloseModal = () => {
    resetInputFields();
    onClose();
  };
  const handleUpdateCloseModal = () => {
    setUpdateInputFields({});
    setOriginalValues({});
    onEditClose();
  };
  useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchTimetableDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const EditSchedule = (day, subject) => {
    setOriginalValues({ day: day, ...subject });
    let idx = subject.startTime.indexOf(":");
    let hour = subject.startTime.substring(0, idx);
    let minutes = subject.startTime.substring(5, idx);
    let StartAM = subject.startTime.substring(8, 5);
    let Endhour = subject.endTime.substring(0, idx);
    let Endminutes = subject.endTime.substring(5, idx);
    let EndStartAM = subject.endTime.substring(8, 5);

    if (StartAM == " PM") {
      const newStart = +hour + 12;
      var newStartTime = newStart + minutes;
    } else {
      var newStartTime = subject.startTime;
    }
    if (EndStartAM == " PM") {
      const newEnd = +Endhour + 12;
      var newEndTime = newEnd + Endminutes;
      setUpdateInputFields({ ...updateInputFields });
    } else {
      var newEndTime = subject.endTime;
    }
    setUpdateInputFields({
      day: day,
      ...subject,
      newStartTime: newStartTime,
      newEndTime: newEndTime,
    });
    setEditOpen(true);
  };
  const handleUpdate = async () => {
    try {
      let Subject_to_add;
      if (isUpdateOther) {
        Subject_to_add = updateInputFields?.other_subject_name;
      } else Subject_to_add = updateInputFields?.subject_name;
      // console.log("inside try part",originalValues, updateInputFields.ehour)
      // if (updateInputFields.hour >= 6 || originalValues?.startTime >= 6 && updateInputFields.ehour <= 18||originalValues?.endTime>=6) {
      const response = await AdminDashboard.requestUpdateSubjectAndTime({
        timetable_id: params.id,
        day: originalValues?.day,
        is_other_sub: isUpdateOther,
        subject_name: originalValues?.subject_name,
        startTime: originalValues?.startTime,
        endTime: originalValues?.endTime,
        updateBody: {
          subject_name: Subject_to_add,
          startTime: updateInputFields?.startTime,
          endTime: updateInputFields?.endTime,
        },
      });
      if (response) {
        if (response.error) {
          console.error(response.error);
        } else {
          handleUpdateCloseModal();
          toast({
            title: "Updated Succesfully",
            description: "Updated Schedule",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          fetchTimetableDetails();
        }
      }
      // }
      //  else {
      //   toast({
      //     title: "Please Enter a Vaild Time",
      //     // description: e.msg,
      //     status: "error",
      //     duration: 4000,
      //     isClosable: true,
      //     position: "top-right",
      //   });
      // }
    } catch (e) {
      console.error(e);
      toast({
        title: "Updating failed",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setRequestInProgress(false);
      return false;
    }
  };
  const handleDelete = async (day, subject) => {
    try {
      const response = await AdminDashboard.requestDeleteSubjectandTime({
        timetable_id: params.id,
        day: deleteData.item,
        subject_name: deleteData.sub.subject_name,
      });

      if (response) {
        if (response.error) {
          console.error(response.error);
        } else {
          handleUpdateCloseModal();
          toast({
            title: "Deleted Succesfully",
            description: "Deleted Schedule",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "bottom-right",
          });
          fetchTimetableDetails();
          deleteTeacherTimeTable.onClose();
        }
      }
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleAppendSubject = async () => {
    try {
      console.log("inside try part");
      let Subject_to_add;
      let timetable_id = params.id;
      if (isOther) {
        Subject_to_add = inputFields?.other_subject_name;
      } else Subject_to_add = inputFields?.subject_name;
      console.log("inside try part", inputFields, inputFields.ehour);
      if (inputFields.hour >= 6 && inputFields.ehour <= 18) {
        const response = await AdminDashboard.requestAddSubjectAndTime(
          {
            day: inputFields?.day,
            subject_name: Subject_to_add,
            startTime: inputFields?.startTime,
            endTime: inputFields?.endTime,
            is_other_sub: isOther,
          },
          timetable_id
        );

        if (response) {
          if (response.error) {
            console.error(response.error);
            toast({
              title: "Error",
              description: response.error,
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            handleCloseModal();
            toast({
              title: "Added Succesfully",
              description: "Added Schedule",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
            fetchTimetableDetails();
          }
        }
      }
      // }
      else {
        toast({
          title: "Please Enter a Vaild Time",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (e) {
      toast({
        title: "Error",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const fetchTimetableDetails = async () => {
    try {
      const response = await AdminDashboard.requestTimetableById({
        timetable_id: params.id,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        //removing sunday from the api response
        let sundayRemoved = response.dayTimeTable;
        sundayRemoved.shift();
        setTimetable({ ...response, dayTimeTable: sundayRemoved });
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const fetchSubjects = async () => {
    const res = await AdminDashboard.getSubjectByClassID({
      class_id: timetable?.class_id,
    });
    setSubjectList(res);
  };
  useEffect(() => {
    if (timetable.class_id && inputFields?.day) {
      fetchSubjects();
    }
  }, [timetable && inputFields?.day]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <>
      <div className={Styles.bodyContainer}>
        <div className={Styles.body}>
          <div className={Styles.ModalnameContainer}>
            <p className={Styles.Modalname}>
              TIMETABLE ({timetable.class} | {timetable.section})
            </p>
            <Tooltip label="Add Schedule">
              <div onClick={onOpen} className={Styles.add}>
                <img className={Styles.addIcon} src={Add} alt="add" />
              </div>
            </Tooltip>
          </div>

          <div className={Styles.cards}>
            <Swiper
              slidesPerView={screenSize && screenSize?.width >= 550 ? 1 : 1.2}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={10}
              // slidesPerView={2}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {timetable?.dayTimeTable?.map((item) => (
                <SwiperSlide className="mySwiper21">
                  <div className={Styles.card}>
                    <div className={Styles.header}>
                      <p className={Styles.title}>{item.day}</p>
                    </div>
                    <div className={Styles.AllSubjects}>
                      {item?.subjects?.map((sub) => (
                        <div className={Styles.content}>
                          <div className={Styles.contentItem}>
                            <div className={Styles.left}>
                              <p className={Styles.name}>{sub.subject_name}</p>
                              <p className={Styles.class}>
                                {sub.startTime} - {sub.endTime}
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Tooltip label="Edit Schedule">
                                <div
                                  className={Styles.right}
                                  onClick={() => {
                                    EditSchedule(item.day, sub);
                                    fetchSubjects();
                                  }}
                                >
                                  {/* <img className={Styles.edit} src={Edit} alt="edit" /> */}
                                  <MdEdit className={Styles.edit} />
                                </div>
                              </Tooltip>
                              <Tooltip label="Delete Schedule">
                                <div
                                  className={Styles.right}
                                  onClick={() => {
                                    deleteTeacherTimeTable.onOpen();
                                    setDeleteData({ item: item.day, sub: sub });
                                  }}
                                >
                                  {/* <img
                            className={Styles.edit}
                            src={deleteIcon}
                            alt="edit"
                          /> */}
                                  <AiOutlineDelete className={Styles.edit} />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {screenSize && screenSize?.width >= 550 ? (
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={10}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {timetable?.dayTimeTable?.map((item) => (
                  <SwiperSlide className="mySwiper21">
                    <div className={Styles.card2}>
                      <div className={Styles.header2}>
                        <p className={Styles.title2}>{item.day}</p>
                      </div>
                      <div className={Styles.AllSubjects2}>
                        {item?.subjects?.map((sub) => (
                          <div className={Styles.content2}>
                            <div className={Styles.contentItem2}>
                              <div className={Styles.left2}>
                                <p className={Styles.name2}>
                                  {sub.subject_name}
                                </p>
                                <p className={Styles.class2}>
                                  {sub.startTime} - {sub.endTime}
                                </p>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div
                                  className={Styles.right2}
                                  onClick={() => EditSchedule(item.day, sub)}
                                >
                                  {/* <img className={Styles.edit} src={Edit} alt="edit" /> */}
                                  <MdEdit className={Styles.edit2} />
                                </div>
                                <div
                                  className={Styles.right2}
                                  onClick={() => {
                                    deleteTeacherTimeTable.onOpen();
                                    setDeleteData({ item: item.day, sub: sub });
                                  }}
                                >
                                  {/* <img
                            className={Styles.edit}
                            src={deleteIcon}
                            alt="edit"
                          /> */}
                                  <AiOutlineDelete className={Styles.edit2} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              ""
            )}
          </div>

          <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <div className={Styles.addForm}>
                  <p className={Styles.addFormTitle}>Add Period</p>
                  <div className={Styles.filterWrapper}>
                    <p className={Styles.formLabel}>
                      Day <span className={Styles.asterisk}>*</span>
                    </p>
                    <Select
                      name="day"
                      placeholder="Select Day"
                      value={inputFields?.day}
                      onChange={handleInputChange}
                      // onClick={setDisable(true)}
                    >
                      <option value="MONDAY">Monday</option>
                      <option value="TUESDAY">Tuesday</option>
                      <option value="WEDNESDAY">Wednesday</option>
                      <option value="THURSDAY">Thursday</option>
                      <option value="FRIDAY">Friday</option>
                      <option value="SATURDAY">Saturday</option>
                    </Select>
                    <p className={Styles.formLabel}>
                      Subject <span className={Styles.asterisk}>*</span>
                    </p>
                    <Select
                      name="subject_name"
                      placeholder="Select Subject"
                      onChange={handleInputChange}
                      value={inputFields?.subject_name}
                      disabled={disable ? true : false}
                    >
                      {subjectList.SubjectNameList &&
                        subjectList.SubjectNameList.map((sub) => {
                          return (
                            <>
                              <option value={sub}>{sub}</option>
                            </>
                          );
                        })}
                      <option value="Other">Other</option>
                    </Select>
                  </div>
                  {isOther ? (
                    <input
                      className={Styles.addFormInput}
                      type="text"
                      placeholder="Other (Please Specify)"
                      name="other_subject_name"
                      value={inputFields?.other_subject_name}
                      onChange={handleInputChange}
                      required
                    />
                  ) : (
                    ""
                  )}
                  <p className={Styles.addFormTitle}>
                    Time <span className={Styles.asterisk}>*</span>
                  </p>
                  <p>Start</p>
                  <TimePicker
                    onChange={handleStartTimeChange}
                    name="startTime"
                    format="h:m a"
                    value={inputFields?.startTime}
                    hourPlaceholder="HH"
                    minutePlaceholder="MM"
                    disabled={disable ? true : false}
                  />

                  <p>End</p>
                  <TimePicker
                    onChange={handleEndTimeChange}
                    name="endTime"
                    value={inputFields?.endTime}
                    format="h:m a"
                    hourPlaceholder="HH"
                    minutePlaceholder="MM"
                    disabled={disable ? true : false}
                  />

                  <button
                    className={Styles.addButton}
                    onClick={handleAppendSubject}
                    // disabled={disable?true:false}
                  >
                    Add
                  </button>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal isOpen={isEditOpen} onClose={handleUpdateCloseModal}>
            <ModalOverlay />
            <ModalContent>
              {/* <ModalHeader>Add</ModalHeader> */}
              <ModalCloseButton />
              <ModalBody>
                <div className={Styles.addForm}>
                  <p className={Styles.addFormTitle}>Update Period</p>
                  <div className={Styles.filterWrapper}>
                    <p className={Styles.formLabel}>
                      Day <span className={Styles.asterisk}>*</span>
                    </p>
                    <input
                      readOnly
                      className={Styles.addFormInput}
                      name="day"
                      placeholder="Select Day"
                      value={updateInputFields?.day}
                    ></input>
                    <p className={Styles.formLabel}>
                      Subject <span className={Styles.asterisk}>*</span>
                    </p>
                    <Select
                      name="subject_name"
                      placeholder="Select New Subject"
                      onChange={handleUpdateInputChange}
                      value={updateInputFields?.subject_name}
                    >
                      {subjectList.SubjectNameList &&
                        subjectList.SubjectNameList.map((sub) => {
                          return (
                            <>
                              <option value={sub}>{sub}</option>
                            </>
                          );
                        })}
                      <option value="Other">Other</option>
                    </Select>
                  </div>
                  {isUpdateOther ? (
                    <input
                      className={Styles.addFormInput}
                      type="text"
                      placeholder="Other (Please Specify)"
                      name="other_subject_name"
                      value={updateInputFields?.other_subject_name}
                      onChange={handleUpdateInputChange}
                      required
                    />
                  ) : (
                    ""
                  )}
                  <p className={Styles.addFormTitle}>
                    Time <span className={Styles.asterisk}>*</span>
                  </p>
                  <p>Start</p>
                  <TimePicker
                    onChange={handleUpdateStartTimeChange}
                    name="startTime"
                    value={updateInputFields?.newStartTime}
                    format="h:m a"
                    hourPlaceholder="HH"
                    minutePlaceholder="MM"
                  />
                  <p>End</p>
                  <TimePicker
                    onChange={handleUpdateEndTimeChange}
                    name="endTime"
                    value={updateInputFields?.newEndTime}
                    format="h:m a"
                    hourPlaceholder="HH"
                    minutePlaceholder="MM"
                  />
                  <div className={Styles.buttonRow}>
                    <button className={Styles.addButton} onClick={handleUpdate}>
                      Update
                    </button>
                    {/* <button className={Styles.addButton} >
                  Delete
                </button> */}
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={deleteTeacherTimeTable.isOpen}
            onClose={deleteTeacherTimeTable.onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader> </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div>
                  <p>
                    Are you sure you want to delete this subject from timetable?
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={handleDelete}
                >
                  Yes
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  onClick={() => deleteTeacherTimeTable.onClose()}
                >
                  No
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
      
    </>
  );
};

export default EditTimetable;

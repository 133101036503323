import React, { useEffect, useState } from "react";
import { requestGetAddedModule } from "services/student-dashboard/help.service";
import Styles from "./Help.module.scss";
import { Spinner, Tooltip } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { Icons } from "utilities/assets";
import defaultUser from "assets/icons/User.png";
import { Link } from "react-router-dom";
const StudentHelp = () => {
  const moduleIcons = {
    "ACADEMIC CALENDAR": Icons.AcademicCalendar,
    "ADMIN/ MANAGEMENT": Icons.Admin,
    ADMISSION: Icons.Admission,
    ASSIGNMENTS: Icons.Assignment,
    ATTENDANCE: Icons.Attendance,
    BACKUP: Icons.Backup,
    BRANDING: Icons.Branding,
    "BUS TRANSPORT": Icons.SchoolBus,
    CANTEEN: Icons.Canteen,
    CERTIFICATE: Icons.Certificate,
    CIRCULAR: Icons.Circular,
    CLASSROOM: Icons.Classroom,
    EVENTS: Icons.Events,
    "EXAM REPORT": Icons.Exams,
    HELP: Icons.Help,
    "KNOWLEDGE CENTRE": Icons.Knowledge_Centre,
    LIBRARY: Icons.Library,
    LOGOUT: Icons.Logout,
    NOTIFICATION: Icons.Notification,
    PERMISSION: Icons.Permissions,
    PROFILE: [Icons.Student, Icons.Teacher, Icons.Admin, Icons.Management],
    "RECYCLE BIN": Icons.RecycleBin,
    "REPORT CENTRE": Icons.Report_Centre,
    "SCHOOL FEES": Icons.Fees,
    SMS: Icons.SMS,
    STUDENTS: Icons.Student,
    STAFF: Icons.Teacher,
    STATISTICS: Icons.Statistics,
    TIMETABLE: Icons.TimeTable,
    TOOLS: Icons.Tools,
  };
  const [addedModule, setAddedModule] = useState("");
  const [selectedModuleDetail, setSelectedModuleDetail] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleGetAddedModule = async () => {
    try {
      setRequestInProgress(true);
      const payload = {};
      const response = await requestGetAddedModule(payload);
      setAddedModule(response);
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    handleGetAddedModule();
  }, []);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>HELP |</p>
        <p className={Styles.stats}>
          Total Added Modules : {addedModule?.length}
        </p>
      </div>
      <div className={Styles.body}>
        {requestInProgress ? (
          <Spinner />
        ) : (
          <div className={Styles.lists}>
            {addedModule && addedModule.length > 0
              ? addedModule.map((module) => (
                  <Link
                    to={module._id}
                    className={Styles.list}
                    key={module._id}
                  >
                    <div className={Styles.profile}>
                      {module.module_name === "PROFILE" ? (
                        <div className={Styles.profileIconsContainer}>
                          {Icons.Student && (
                            <img
                              src={Icons.Student}
                              alt="Student Icon"
                              className={Styles.profileStudentIcon}
                            />
                          )}
                          {Icons.Teacher && (
                            <img
                              src={Icons.Teacher}
                              alt="Teacher Icon"
                              className={Styles.profileTeacherIcon}
                            />
                          )}
                          {Icons.Admin && (
                            <img
                              src={Icons.Admin}
                              alt="Admin Icon"
                              className={Styles.profileAdminIcon}
                            />
                          )}
                          {Icons.Management && (
                            <img
                              src={Icons.Management}
                              alt="Management Icon"
                              className={Styles.profileManagementIcon}
                            />
                          )}
                        </div>
                      ) : (
                        <img
                          src={
                            moduleIcons[module.module_name]
                              ? moduleIcons[module.module_name]
                              : defaultUser
                          }
                          alt={module.module_name}
                          className={Styles.profileImg}
                        />
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                      }}
                    >
                      <div className={Styles.info}>
                        <p className={Styles.name}>{module.module_name}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Tooltip label="View Details" placement="top">
                          <div style={{ marginRight: "5px" }}>
                            <FaEye
                              onClick={(event) => {
                                event.preventDefault();
                                setSelectedModuleDetail(module.module_name);
                              }}
                              fontSize={25}
                              cursor="pointer"
                            />
                          </div>
                        </Tooltip>
                        {selectedModuleDetail === module.module_name && (
                          <div className={Styles.tooltipStyles}>
                            <div>
                              <IoIosClose
                                className={Styles.closeButton}
                                fontSize={30}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setSelectedModuleDetail(null);
                                }}
                              />
                            </div>
                            <div
                              style={{ marginTop: "14px", fontWeight: "bold" }}
                            >
                              <p>{module.module_name}</p>
                              <p>{module.user_role}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                ))
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentHelp;

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import "react-datepicker/dist/react-datepicker.css";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import Cerificateservice from "services/admin-dashboard/certificate.service";
import "./certificatepdf.module.css";
import Header from "./BonafideHeader/Header";
import jsPDF from "jspdf";
import DatePicker from "react-date-picker";
import moment from "moment";
import { ToWords } from 'to-words';
const ManagementBonafide = () => {
  const params = useParams();
  const [inputFields, setInputFields] = useState(null);
  const [data, setdata] = useState(null);
  const [certificateType, setcertificateType] = useState(null);
  const [downloadButton , setDownloadButton] = useState(true)


  const location = useLocation();
  console.log("location", location.state.data);

  const fetchStudentProfileDetails = async () => {
    try {
      const response = await requestStudentDetailsById({
        studentId: params.id,
      });
      setdata(response);
      if (response.error) {
        console.error(response.error);
      }
      console.log(data);
      // const classroom_response = await requestAllSubjects({ class_id: response._class_id });
      // console.log('calss', classroom_response)
      // if (response && classroom_response) {
      if (response) {
        console.log("student resp", response);
        const DOB = response?.personel?.dob;
        let dob = DOB && new Date(DOB).toLocaleDateString();
        if (dob == "Invalid Date") {
          if (DOB.includes("-")) {
            dob = new Date(DOB.split("-").reverse().join("/"));
          } else if (DOB.includes("/")) {
            const [day, month, year] = DOB.split("/");
            const dateString = [month, day, year].join("/");
            dob = new Date(dateString);
          }
        } else {
          if (typeof new Date(DOB) === "object") {
            dob = new Date(DOB);
          } else {
            dob = DOB;
          }
        }

        const _response = {
          ...response,
          name: response.name,
          email: response.email,
          personel: {
            ...response.personel,
            dob,
            gender: response.personel.gender,
            present_address: response.personel.present_address,
            permanent_address: response.personel.permanent_address,
            mother_tongue: response.personel.mother_tongue,
            religion: response.personel.religion,
            sub_religion: response.personel.sub_religion,
            blood_group: response.personel.blood_group,
            aadhar_number: response.personel.aadhar_number,
            nationlity: response.personel.nationlity,
            signed_display_image: await getSignedUrlLink(
              response.personel.profile_url
            ),
          },
          academic: {
            ...response.academic,
            class: response.academic.class,
            section: response.academic.section,
            admission_number: response.academic.admission_number,
            admission_year: response.academic.admission_year,
          },
          father: {
            ...response.father,
            father_name: response.father.father_name,
            father_email: response.father.father_email,
            father_phone: response.father.father_phone,
            father_occupation: response.father.father_occupation,
            father_qualification: response.father.father_qualification,
            signed_father_display_image: await getSignedUrlLink(
              response.father.father_photo_url
            ),
          },
          mother: {
            ...response.mother,
            mother_name: response.mother.mother_name,
            mother_phone: response.mother.mother_phone,
            mother_occupation: response.mother.mother_occupation,
            mother_qualification: response.mother.mother_qualification,
            signed_mother_display_image: await getSignedUrlLink(
              response.mother.mother_photo_url
            ),
          },
          canteen_choices: response.canteen_choices,
          account_status: response.account_status,
          transport_choices: response.transport_choices,
          // electives: classroom_response.electives,
          subject_choices: response.subject_choices,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  useEffect(() => {
    fetchStudentProfileDetails();
    setcertificateType(location.state.data);
  }, []);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  // let currentDate = `${day}-${month}-${year}`;
let currentDate = moment(date,"MM/DD/YYYY").format("DD/MM/YYYY")




  const handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a4");
    let pdf = await document.getElementById("pdfcerti");
    view
      .html(pdf)
      .then(() => {
        view.save(`${inputFields.name} ${certificateType}.pdf`);
        PdfRequestUpdate();
      })
      .catch((err) => console.log(err, "err"));
   
  };

  const PdfRequestUpdate = async () => {
    let certificateData = {
      certificate_name: certificateType,
      issue_date: currentDate,
      student_id: inputFields?._id,
    };
    const response = await Cerificateservice.certificateDownload(
      certificateData
    );
    console.log("response", response);
  };

    //

  // const DOB = data?.personel?.dob
  const formattedDate = moment(inputFields?.personel?.dob, "MM/DD/YYYY").format(
    "DD/MM/YYYY"
  );
  
const toWords = new ToWords();
if(inputFields?.personel?.dob){
   const convertedYear = moment(inputFields?.personel?.dob, "MM/DD/YYYY").format("YYYY");
  var wordsYear = toWords.convert(convertedYear);
}
if(inputFields?.personel?.dob){
  const convertedDay = moment(inputFields?.personel?.dob&&inputFields?.personel?.dob, "MM/DD/YYYY").format("DD");
  var wordsDay = toWords.convert(convertedDay);
}
if(inputFields?.personel?.dob){
  const d = moment(inputFields?.personel?.dob&&inputFields?.personel?.dob, "MM/DD/YYYY").format("MMMM")
  var monthName = d
}
// const convertedDay = moment(inputFields?.personel?.dob&&inputFields?.personel?.dob, "MM/DD/YYYY").format("DD");
// const wordsDay = toWords.convert(convertedDay);
  // var words = toWords(formattedDate);
  // console.log("words?.words.words", words);
  //  if (certificateType==="Caste Certificate" && inputFields?.personel?.sub_religion === "-"
  //    ) {
  //     setDownloadButton(false)
  //   }
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center", 
          marginLeft: "10vw",
          // flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "66%",
            }}
            id="pdfcerti"
          >
            <div style={{ width: "96%" }}>
              <Header />
              <div style={{ marginTop: "1vw" }}>
                <p style={{ textAlign: "right" ,fontSize: ".6rem",}}>{currentDate}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1rem",
                      textAlign: "center",
                      fontWeight: 700,
                      marginTop: "1vw",
                      fontFamily: "sans",
                      borderBottom: "1px solid black",
                      paddingBottom: "5px",
                    }}
                  >
                    {certificateType}
                  </p>
                </div>
              </div>
              <div
                style={{
                  marginTop: "1vw",
                  fontSize: ".8rem",
                  fontFamily: "sans",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  width:"100%"
                }}
              >
                <p>
                  This is to certify that&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {inputFields?.name}
                  </span>
                  &nbsp;{inputFields?.personel.gender == "Male"
                          ? "S/o" 
                          : "D/o"}&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    MR {inputFields?.father?.father_name} and MRS&nbsp;
                    {inputFields?.mother?.mother_name}
                  </span>
                  &nbsp; is a bonafide student of our school presently studying
                  in grade&nbsp;
                  <span style={{ fontWeight: "bold" }}> 
                    {inputFields?.academic?.class}
                  </span>
                  . As per our records {inputFields?.personel.gender == "Male"
                          ? "his"
                          : "her"} date of birth is &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {formattedDate&&formattedDate} ({wordsDay} {monthName} {wordsYear})
                  </span>. 
                  <span>
                  &nbsp;
                    {certificateType == "BONAFIDE CERTIFICATE" ? (
                      <span>
                        {inputFields?.personel.gender == "Male"
                          ? "His "
                          : "Her "} 
                        Conduct is good.&nbsp;
                        {inputFields?.personel.gender == "Male"
                          ? "His"
                          : "Her"}
                        &nbsp;residential address is&nbsp;
                        <span style={{ fontWeight: "bold" }}>
                         
                          {inputFields?.personel?.present_address}
                        </span>
                        .
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </p>
              </div>
              <div
                style={{
                  marginTop: "1.5vw",
                  fontSize: ".7rem",
                  fontFamily: "sans",
                }}
              >
                {certificateType === "Caste Certificate" ? (
                  <p>
                  {inputFields?.personel.gender == "Male"
                          ? "He"
                          : "She"} belongs to {inputFields?.personel?.religion} religion,&nbsp;
                    {inputFields?.personel?.sub_religion} caste and {inputFields?.personel.gender == "Male"
                          ? "His"
                          : "Her"} mother
                    tongue is&nbsp;
                    <span style={{ fontWeight: "bold" }}>
                      {inputFields?.personel?.mother_tongue}
                    </span>
                    &nbsp;as per our records.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  // textAlign: "right",
                  marginTop: "1.5vw",
                  fontSize: ".8rem",
                  fontFamily: "sans",
                }}
              >
                <p style={{ fontWeight: "bold" }}>
                  Rev. Fr Vijay J. D'Souza
                </p>
                <p>Principal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
      {downloadButton?
      <button
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
          onClick={() => handleCertiDownload()}
        >
          Download
        </button>:
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
          // onClick={() => handleCertiDownload()}
        >
          Download
        </button>
      }
        {/* <button
          style={{
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
          onClick={() => handleCertiDownload()}
        >
          Download
        </button> */}
      </div>
    </>
  );
};

export default ManagementBonafide;

import React from 'react';
import { Link } from 'react-router-dom';
import Styles from '../StaffExams.module.scss';

const MarksEntry = () => {
  return (
    <div className={Styles.body}>
        
        <div className={Styles.wrapper}>
            <div className={Styles.row}>
                <Link to='mid'>
                    <div className={Styles.button}>Mid-Term Exam</div>
                </Link>
                <Link to='annual'>
                    <div className={Styles.button}>Annual Exam</div>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default MarksEntry
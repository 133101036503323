import { Checkbox, Divider, useEditable } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Styles from "./ReportCard.module.scss";

export const Marks6to7 = ({
  results,
  selectedResult,
  setSelectedResult,
  grade,
}) => {
  let isMidterm = results.every((item) =>
    Object.keys(item).includes("mid_term")
  );
  let isAnnual = results.every((item) => Object.keys(item).includes("anual"));
  let term = isMidterm ? "mid_term" : "anual";
  let sorted_data = results;
  let sorted_subjects = results.map((item) => {
    let el = [];
    let sub = [];
    item[term].map((m) => {
      el = Object.keys(m).includes("elective_name") ? [...el, m] : el;
      sub = !Object.keys(m).includes("elective_name") ? [...sub, m] : sub;
    });
    el.sort((a, b) => a.elective_name.localeCompare(b.elective_name));
    sub.sort((a, b) => a.subject_name.localeCompare(b.subject_name));
    item[term] = [...sub, ...el];
    return item;
  });
  let dummyvariable;
  let marksHeaderAnnual = [];

  for (let i = 0; i < results[0][term].length; i++) {
    sorted_subjects[0].class != 10 && sorted_subjects[0].class != 12
      ? (sorted_subjects[0].class == 11 &&
          results[0][term][i].subject_name == "ENGLISH CORE") ||
        results[0][term][i].subject_name == "MATHEMATICS" ||
        results[0][term][i].subject_name == "ECONOMICS" ||
        results[0][term][i].subject_name == "BUSINESS STUDIES" ||
        results[0][term][i].subject_name == "ACCOUNTANCY"
        ? marksHeaderAnnual.push(<th>IA (20)</th>)
        : sorted_subjects[0].class == 1 ||
          sorted_subjects[0].class == 2 ||
          sorted_subjects[0].class == 3 ||
          sorted_subjects[0].class == 4 ||
          sorted_subjects[0].class == 5
        ? marksHeaderAnnual.push(<th>IA (10)</th>)
        : sorted_subjects[0].class == 6 ||
          sorted_subjects[0].class == 7 ||
          sorted_subjects[0].class == 8 ||
          sorted_subjects[0].class == 9
        ? marksHeaderAnnual.push(<th>IA (20)</th>)
        : marksHeaderAnnual.push(<th>IA (30)</th>)
      : (dummyvariable = 1);

    (sorted_subjects[0].class == 11 &&
      results[0][term][i].subject_name == "ENGLISH CORE") ||
    results[0][term][i].subject_name == "MATHEMATICS" ||
    results[0][term][i].subject_name == "ECONOMICS" ||
    results[0][term][i].subject_name == "BUSINESS STUDIES" ||
    results[0][term][i].subject_name == "ACCOUNTANCY"
      ? marksHeaderAnnual.push(<th>FE (80)</th>)
      : sorted_subjects[0].class == 1 ||
        sorted_subjects[0].class == 2 ||
        sorted_subjects[0].class == 3 ||
        sorted_subjects[0].class == 4 ||
        sorted_subjects[0].class == 5
      ? marksHeaderAnnual.push(<th>FE (40)</th>)
      : sorted_subjects[0].class == 6 ||
        sorted_subjects[0].class == 7 ||
        sorted_subjects[0].class == 8 ||
        sorted_subjects[0].class == 9
      ? marksHeaderAnnual.push(<th> FE (80)</th>)
      : marksHeaderAnnual.push(<th>FE (70)</th>);

    sorted_subjects[0].class != 10 && sorted_subjects[0].class != 12
      ? sorted_subjects[0].class == 1 ||
        sorted_subjects[0].class == 2 ||
        sorted_subjects[0].class == 3 ||
        sorted_subjects[0].class == 4 ||
        sorted_subjects[0].class == 5
        ? marksHeaderAnnual.push(<th>ToT (50)</th>)
        : marksHeaderAnnual.push(<th>ToT (100)</th>)
      : marksHeaderAnnual.push(<th>ToT (80)</th>);
    marksHeaderAnnual.push(<th>Grade</th>);
  }
  let marksHeaderMid = [];
  // for (let i = 0; i < results[0][term].length; i++) {
  //     marksHeaderMid.push(<th>MID (40)</th>);
  //     marksHeaderMid.push(<th>Grade</th>);
  // }

  const handleCheckbox = (checked, student) => {
    let selectedRows = selectedResult;
    if (checked) {
      // selectedRows.push(student);
      selectedRows = [...selectedResult, student];
      setSelectedResult(selectedRows);
    } else {
      selectedRows = selectedRows.filter((item) => item !== student);
      setSelectedResult(selectedRows);
    }
  };

  useEffect(() => {
    results.map((student, index) => {
      if (document.getElementById(`checkbox_${index}`).checked === true) {
        document.getElementById(`checkbox_${index}`).click();
      }
    });
    setSelectedResult([]);
  }, [results]);
  return (
    <table className={Styles.table}>
      <tbody>
        <tr>
          <th rowSpan={2}>Select</th>
          <th rowSpan={2}>SL. No.</th>
          <th rowSpan={2}>Name</th>
          {sorted_subjects
            ? sorted_subjects &&
              sorted_subjects[0][term].map((student) => {
                return term == "anual" ? (
                  sorted_subjects[0].class != 10 &&
                  sorted_subjects[0].class != 12 ? (
                    <th colSpan={4}>
                      {Object.keys(student).includes("elective_name")
                        ? student.elective_name.toUpperCase()
                        : student.subject_name}
                    </th>
                  ) : (
                    <th colSpan={3}>
                      {Object.keys(student).includes("elective_name")
                        ? student.elective_name.toUpperCase()
                        : student.subject_name}
                    </th>
                  )
                ) : sorted_subjects[0].class != 10 &&
                  sorted_subjects[0].class != 12 &&
                  grade <= 5 ? (
                  <th colSpan={4}>
                    {Object.keys(student).includes("elective_name")
                      ? student.elective_name.toUpperCase()
                      : student.subject_name}
                  </th>
                ) : (
                  <th colSpan={1}>
                    {Object.keys(student).includes("elective_name")
                      ? student.elective_name.toUpperCase()
                      : student.subject_name}
                  </th>
                );
              })
            : ""}
        </tr>

        <tr>{term == "anual" ? marksHeaderAnnual : marksHeaderMid}</tr>

        {sorted_subjects &&
          sorted_subjects.map((student, index) => {
            const details = [];
            details.push(
              <td>
                <Checkbox
                  id={`checkbox_${index}`}
                  colorScheme="red"
                  onChange={(e) => handleCheckbox(e.target.checked, student)}
                  style={{ border: "1px solid" }}
                />
              </td>
            );
            details.push(<td>{index + 1}</td>);
            details.push(<td>{student.name}</td>);

            const displayMarks = (marks) => {
              if (term == "mid_term") {
                if (grade <= 5) {
                  for (let i = 0; i < marks.length; i++) {
                    sorted_subjects[0].class != 10 &&
                    sorted_subjects[0].class != 12
                      ? details.push(<td>{marks[i][0] || "-"}</td>) //internal
                      : (dummyvariable = 1);
                    details.push(<td>{marks[i][1] || "-"}</td>); //external
                    details.push(<td>{marks[i][2]}</td>); //total
                    details.push(<td>{marks[i][3]}</td>); //grade
                  }
                } else {
                  for (let i = 0; i < marks.length; i++) {
                    details.push(<td>{marks[i][0]}</td>);
                  }
                }
              }
            };
            const displayMarksAnnual = (marks) => {
              if (term == "anual") {
                for (let i = 0; i < marks.length; i++) {
                  sorted_subjects[0].class != 10 &&
                  sorted_subjects[0].class != 12
                    ? details.push(<td>{marks[i][0]}</td>) //internal
                    : (dummyvariable = 1);
                  details.push(<td>{marks[i][1]}</td>); //external
                  details.push(<td>{marks[i][2]}</td>); //total
                  details.push(<td>{marks[i][3]}</td>); //grade
                }
              }
            };

            if (term == "mid_term") {
              if (grade <= 5) {
                displayMarks(
                  student &&
                    student.mid_term.map((m) => [
                      m.internal,
                      m.external,
                      m.total,
                      m.grade,
                    ])
                );
              } else {
                displayMarks(
                  student &&
                    student.mid_term.map((m) =>
                      m.subject_name !== "DISCIPLINE" &&
                      m.subject_name !== "ART EDUCATION" &&
                      m.subject_name !== "HEALTH AND PHYSICAL EDUCATION" &&
                      m.subject_name !== "GENERAL STUDIES" &&
                      m.subject_name !== "WORK EDUCATION"
                        ? // m.subject_name !== "LIFE SKILL"
                          [m.total]
                        : [m.grade]
                    )
                );
              }
            } else {
              displayMarksAnnual(
                student &&
                  student.anual.map((i) => [
                    i.internal,
                    i.external,
                    i.total,
                    i.grade,
                  ])
              );
            }

            return <tr>{details}</tr>;
          })}
      </tbody>
    </table>
  );
};

import React, { useEffect,useState } from "react";
import Styles from "./ChooseLogin.module.scss";
// importing icons

import { Icons } from "utilities/assets";

import { Link, useNavigate } from "react-router-dom";
import {getStorage,ref,listAll,getDownloadURL,getMetadata  } from "firebase/storage"
import {firebasestorage} from "../../config/firebaseInitialize"
const ChooseLogin = () => {
  const { Admin, Management, Student, Teacher } = Icons;
  const [data,setData] = useState("")
  const getImages =()=>{
   listAll(ref(firebasestorage,'Images/Background')).then(imgs=>{
    console.log("imag",imgs)
    imgs.items.forEach(val=>{
      getDownloadURL(val).then(url=>{
        getMetadata(ref(firebasestorage,url))
  .then((metadata) => {
    console.log("data with meta data ", metadata.contentType,url)
    // Metadata now contains the metadata for 'images/forest.jpg'
  })
  .catch((error) => {
    // Uh-oh, an error occurred!
  })
        setData(data =>[...data,url])
      })
    })
   })
  }
  useEffect(()=>{
    getImages()
  },[])
  console.log("data, data", data&&data  )
  // const navigate = useNavigate();
  // const days = (date_1, date_2) => {
  //   let difference = date_1.getTime() - date_2.getTime();
  //   let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  //   return TotalDays;
  // };
  // useEffect(() => {
  //   function pushPage() {
  //     const isLoggedIn = localStorage.getItem("token");
  //     const loggedInOn = localStorage.getItem("loggedInOn");

  //     if (isLoggedIn && loggedInOn) {
  //       //check how many days has passed since user has logged in as token may be expired
  //       const loggedInDate = new Date(loggedInOn);
  //       const currentDate = new Date();
  //       let daysInBetween = days(currentDate, loggedInDate);
  //       console.log("daysbetween", daysInBetween);
  //       if (!(daysInBetween >= 0 && daysInBetween <= 5)) {
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("role");
  //         localStorage.removeItem("loggedInOn");
  //         navigate("/chooseLogin");
  //       }
  //       const userRole = localStorage.getItem("role");
  //       switch (userRole) {
  //         case "admin":
  //           navigate("/admin/dashboard");
  //           break;
  //         case "teacher":
  //           navigate("/staff/dashboard");
  //           break;
  //         case "student":
  //           navigate("/student/dashboard");
  //           break;
  //         case "management":
  //           navigate("/management/dashboard");
  //           break;

  //         default:
  //           navigate("/chooseLogin");
  //       }
  //     } else {
  //       navigate("/chooseLogin");
  //     }
  //   }

  //   pushPage();
  // }, []);

  return (
    <div className={Styles.body}>
      <div className={Styles.boxContainer}>
        <Link to="/studentLogin">
          <div className={Styles.loginBox}>
            <img className={Styles.icon} src={Student} alt="Student" />
            <p className={Styles.loginText}>Student</p>
          </div>
        </Link>

        <Link to="/teacherLogin">
          <div className={Styles.loginBox}>
            <img className={Styles.icon} src={Teacher} alt="Teacher" />
            <p className={Styles.loginText}>Teacher</p>
          </div>
        </Link>

        <Link to="/adminLogin">
          <div className={Styles.loginBox}>
            <img className={Styles.icon} src={Admin} alt="Admin" />
            <p className={Styles.loginText}>Admin</p>
          </div>
        </Link>

        <Link to="/managementLogin">
          <div className={Styles.loginBox}>
            <img className={Styles.icon} src={Management} alt="Manager" />
            <p className={Styles.loginText}>Management</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ChooseLogin;

import React, { useEffect, useState } from "react";
import Styles from "./SingleStudentFeePage.module.scss";
import { Icons } from "utilities/assets";
import { Link, useNavigate, useLocation, NavLink } from "react-router-dom";
import { BiUpArrowCircle } from "react-icons/bi";
import { AiOutlineDollarCircle, AiFillDelete } from "react-icons/ai";
import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useDisclosure,
  useToast,
  ModalFooter,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import Add_new from "assets/icons/Add_new.png";
import deleteIcon from "../../../assets/icons/deleteIcon.png";
import editIcon from "../../../assets/icons/editIcon.png";
// import editIcon from ass
import {
  getStudentPayment,
  addStudentPayment,
  deleteFeesPayment,
} from "services/admin-dashboard/fee.service";
import Services from "services/admin-dashboard/fee.service";
import moment from "moment";
import defaultUser from "../../../assets/icons/User.png";
import Fullpaid from "assets/icons/paid-rupee-indian.png"
import Feedocimg from "assets/icons/Full-rupee-indian.png"
import RemainingFee from "assets/icons/due-rupee-indian.png"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
const intialvalue = {
  bill_number: "",
  amount: "",
  description: "",
  penalty_type: "",
};
const SingleStudentFeePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAddPenalty = useDisclosure();
  const handleUpdatePenalty = useDisclosure();
  const handleAddPenaltyModal = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const stud_id = location.pathname.split("/")[5];
  const [paymentDetails, setPaymentDetails] = React.useState("");
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [penaltyAmount, setPenaltyAmount] = useState("");
  const [penaltyBillNo, setPenaltyBillNo] = useState("");
  const [penaltyDescription, setPenaltyDescription] = useState("");
  const [penaltyType, setPenaltyType] = useState("");
  const [penaltyList, setPenaltyList] = useState("");
  const [updatePenalty, setUpdatePenalty] = useState("");
  const [penaltyId, setPenaltyId] = useState();
  const [totalPenaltyAmount, setTotalPenaltyAmount] = useState("");
  const [totalPendingAmount, setTotalPendingAmount] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const paymentCard = useDisclosure();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  React.useEffect(() => {
    fetchPaymentDetails(stud_id);
  }, [requestInProgress]);
  const fetchPaymentDetails = async (stud_id) => {
    let data = await getStudentPayment(stud_id);
    setPaymentDetails(data);
  };

  const addPenalty = async () => {
    const payload = {
      student_id: stud_id,
      amount: penaltyAmount,
      bill_number: penaltyBillNo,
      description: penaltyDescription,
      penalty_type: penaltyType,
    };
    const response = await Services.addStudentPenalty(payload);
    if (response) {
      toast({
        title: "penalty Added Succesfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      studentPenalty();
      handleAddPenaltyModal.onClose();
      fetchPaymentDetails(stud_id)
    }
  };

  const studentPenalty = async () => {
    const payload = {
      student_id: stud_id,
    };
    const response = await Services.feePaneltyDetails(payload);
    if (response) {
      setPenaltyList(response.data.penalty_list);
      // response && response.map((data)=>{

      // })

      let PenaltyLength = response.data.penalty_list.length;
      //  let i=0
      let totalAmount = 0;
      for (let i = 0; i < PenaltyLength; i++) {
        let amount = response.data.penalty_list[i].amount;
        totalAmount = totalAmount + amount;
        setTotalPenaltyAmount(totalAmount);
      }
      let pendingTotalAmount = 0;
      for (let i = 0; i < PenaltyLength; i++) {
        if (response.data.penalty_list[i].payment_status == "Unpaid") {
          let pendingAmount = response.data.penalty_list[i].amount;
          pendingTotalAmount = pendingTotalAmount + pendingAmount;
          setTotalPendingAmount(pendingTotalAmount);
        }
      }
    }
  };

  useEffect(() => {}, []);

  const penaltyPayment = async (id) => {
    const payload = {
      student_id: stud_id,
      penaltyId: id,
    };
    const paidResponse = await Services.paidPenaltyUpdate(payload);
    if (paidResponse) {
      toast({
        title: "Penalty Paid Successfully",
        variant: "left-accent",
        position: "top-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      studentPenalty();
      fetchPaymentDetails(stud_id)
    }
  };
  const updateEnable = (id) => {
    if (id.data.payment_status == "Paid") {
      toast({
        title: "Paid penalty can't be update",
        variant: "left-accent",
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setUpdatePenalty(id.data);
      setPenaltyId(id.data._id);
      handleUpdatePenalty.onOpen();
      // handleAddPenalty.onClose();
    }
  };

  const penaltyModales = () => {
    handleAddPenaltyModal.onOpen();
    // handleAddPenalty.onClose();
  };

  const deletePenalty = async (id) => {
    if (id.data.payment_status == "Paid") {
      toast({
        title: "Paid penalty can't be deleted",
        variant: "left-accent",
        position: "top-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } else {
      const payload = {
        student_id: stud_id,
        penaltyId: id.data._id,
      };
      const deleteResponse = Services.deleteStudentPenalty(payload);
      if (deleteResponse) {
        toast({
          title: "Penalty Deleted Successfully",
          variant: "left-accent",
          position: "top-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        studentPenalty();
        fetchPaymentDetails(stud_id)
      }
    }
    // studentPenalty();
    // fetchPaymentDetails(stud_id)

  };
  const handleDeletePayment = async (bill_id) => {
    let data = { bill_id: bill_id };
    let response = await deleteFeesPayment({ stud_id, data });
    if (response) {
      setRequestInProgress(true);
      toast({
        title: "Payment Deleted Succesfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      studentPenalty();
      paymentCard.onClose();
    }
    setRequestInProgress(false);
  };
  const handlePaymentCard = (bill_id) => {
    paymentCard.onOpen();
    setPaymentId(bill_id);
  };
  const onValueChange = (e) => {
    setUpdatePenalty({ ...updatePenalty, [e.target.name]: e.target.value });
  };
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const updatePenaltyDetails = async () => {
    const payload = {
      amount: +updatePenalty.amount,
      bill_number: updatePenalty.bill_number,
      description: updatePenalty.description,
      penalty_type: updatePenalty.penalty_type,
      student_id: stud_id,
      penaltyId: penaltyId,
    };
    const updateRespone = await Services.penaltyUpdate(payload);
    if (updateRespone) {
      studentPenalty();
      toast({
        title: "Penalty Updated Successfully",
        variant: "left-accent",
        position: "top-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      handleUpdatePenalty.onClose();
      handleAddPenalty.onOpen();
      fetchPaymentDetails(stud_id)
    }
  };

  if (requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <h1 className={Styles.header}>
          Fee Details
           {/* | {paymentDetails?.student_name} - {paymentDetails?.class} */}
          {/* -{paymentDetails?.section}{" "} */}
        </h1>
        <div className={Styles.add}>
          <Tooltip label="Penalty/Fine">
            <p
              className={Styles.fine}
              onClick={() => {
                handleAddPenalty.onOpen();
                studentPenalty();
              }}
            >
              P/F
            </p>
          </Tooltip>
          <div onClick={onOpen} style={{ marginLeft: "10px" }}>
            <Tooltip label="Add Fees">
              <img className={Styles.addImage} src={Add_new} alt="add" />
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <p className={Styles.fessFormetdesign}>
          * All the fee amount are in INR (Indian National Rupee)
        </p>
      </div>
      <div className={Styles.container1}>
        <div className={Styles.profileDescription}>
              <div>
              <img 
              className={Styles.profileImg}
              src={paymentDetails?.profile_url?paymentDetails?.profile_url:defaultUser} />
              </div>
              <div style={{marginLeft:"10px"}}>
              <p className={Styles.containerFontSize}>
                Name: {paymentDetails?.student_name}
              </p>
              <p className={Styles.containerFontSize2}>
                Grade : {paymentDetails?.class}
              </p>
              <p className={Styles.containerFontSize2}>
                Section : {paymentDetails?.section}
              </p>
              </div>
        </div>
        <div className={Styles.paidContainer}>
        <div className={Styles.paidUpper}>
        <div className={Styles.PaidFee}
         style={{background:"rgb(225,239,251)"}}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={Feedocimg} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Total Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
              {paymentDetails &&
                paymentDetails.fees_metadata.tution_total +
                paymentDetails.fees_metadata.canteen_total +
                paymentDetails.fees_metadata.transport_total
              }
            </p>
           
           
          </div>
        </div>
         
        <div className={Styles.PaidFee} style={{backgroundColor:"#fae0e0e0"}}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={RemainingFee} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Pending Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
        {paymentDetails &&
                paymentDetails.fees_metadata.tution_total_pending +
                  paymentDetails.fees_metadata.canteen_total_pending +
                  paymentDetails.fees_metadata.transport_total_pending}
            </p>
           
           
          </div>
        </div>
        </div>
       <div className={Styles.paidFeeConatiner}>
       <div className={Styles.PaidFee} style={{
          width:"70%",
          backgroundColor:"rgb(101 246 108 / 47%)"
        }}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={Fullpaid} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Paid Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
        {paymentDetails &&
                +paymentDetails.fees_metadata.tution_total_paid +
                  paymentDetails.fees_metadata.canteen_total_paid +
                  paymentDetails.fees_metadata.transport_total_paid}
            </p>
           
           
          </div>
        </div>
       </div>
        
        
        </div>
      </div>
      
      {paymentDetails ? (
        <div className={Styles.horizontalContainer}>
        <Swiper
        slidesPerView={screenSize&&screenSize?.width>=800?2:screenSize&&screenSize?.width>=550?1.5:1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        // className="mySwiper"
      >
      <SwiperSlide  >
          <div className={Styles.lists}>
            <h2 className={Styles.listHeader}>Tuition Fee Details</h2>

            <div className={Styles.list}>
              <p className={Styles.name}>Total</p>
              <p className={Styles.value}>
                {paymentDetails.fees_metadata.tution_total}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePaid}>Paid</p>
              <p className={Styles.valuePaid}>
                {paymentDetails.fees_metadata.tution_total_paid}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePending}>Pending</p>
              <p className={Styles.valuePending}>
                {paymentDetails.fees_metadata.tution_total_pending}
              </p>
            </div>
          </div>
           </SwiperSlide>
           <SwiperSlide  >
          <div className={Styles.lists}>
            <h2 className={Styles.listHeader}>Canteen Fee Details</h2>

            <div className={Styles.list}>
              <p className={Styles.name}>Total</p>
              <p className={Styles.value}>
                {paymentDetails.fees_metadata.canteen_total}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePaid}>Paid</p>
              <p className={Styles.valuePaid}>
                {paymentDetails.fees_metadata.canteen_total_paid}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePending}>Pending</p>
              <p className={Styles.valuePending}>
                {paymentDetails.fees_metadata.canteen_total_pending}
              </p>
            </div>
          </div>
           </SwiperSlide>
           <SwiperSlide >
          <div className={Styles.lists}>
            <h2 className={Styles.listHeader}>Transport Fee Details</h2>

            <div className={Styles.list}>
              <p className={Styles.name}>Total</p>
              <p className={Styles.value}>
                {paymentDetails.fees_metadata.transport_total}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePaid}>Paid</p>
              <p className={Styles.valuePaid}>
                {paymentDetails.fees_metadata.transport_total_paid}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePending}>Pending</p>
              <p className={Styles.valuePending}>
                {paymentDetails.fees_metadata.transport_total_pending}
              </p>
            </div>
          </div>
           </SwiperSlide>
           <SwiperSlide>
          <div className={Styles.lists}>
            <h2 className={Styles.listHeader}>Penalty Fee Details</h2>

            <div className={Styles.list}>
              <p className={Styles.name}>Total</p>
              <p className={Styles.value}>
              {paymentDetails && paymentDetails.penalty_summary.total}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePaid}>Paid</p>
              <p className={Styles.valuePaid}>
              {paymentDetails && paymentDetails.penalty_summary.paid}
              </p>
            </div>
            <div className={Styles.list}>
              <p className={Styles.namePending}>Pending</p>
              <p className={Styles.valuePending}>
              {paymentDetails && paymentDetails.penalty_summary.pending}
              </p>
            </div>
          </div>
           </SwiperSlide>
       
     
          
           
         </Swiper>
        </div>
      ) : (
        <div className={Styles.noResult}>No Results</div>
      )}
<div className={Styles.newContainer}>
  
          <div className={Styles.newReciptContainer}>
          <h2 className={Styles.subHeading}>
        Payment Receipt | Total Receipt (
        {paymentDetails.fees && paymentDetails.fees.length})
      </h2>

      <div className={Styles.verticalContainer}>
      
        {paymentDetails && paymentDetails.fees.length ? (<>

      {paymentDetails.fees.map((data) => (
        <div className={Styles.receiptCard} id={data._id}>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Bill Number</p>
                <p className={Styles.value}>{data.bill_number}</p>
              </div>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Date Paid</p>
                <p className={Styles.value}>
                  {new Date(data.paid_date).toLocaleDateString("en-GB")}
                </p>
              </div>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Fees Paid </p>
                <p className={Styles.value}>₹ {data.total_amount}</p>
              </div>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Tution Fee Paid </p>
                <p className={Styles.value}>₹ {data.tution_amount}</p>
              </div>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Transport Fee Paid </p>
                <p className={Styles.value}>₹ {data.transport_amount}</p>
              </div>
              <div className={Styles.receiptCardRow}>
                <p className={Styles.heading}>Canteen Fee Paid </p>
                <p className={Styles.value}>₹ {data.canteen_amount}</p>
              </div>
              
              <div className={Styles.del}>
              <Tooltip label="Delete">
                  <AiFillDelete
                    className={Styles.delImage}
                    onClick={() => handlePaymentCard(data)}
                    // onClick={() => handleDeletePayment(data._id)}
                  />    
                  </Tooltip>         
              </div>
            </div>
            ))}
      </>
        ) : (
          <div className={Styles.noResult}>No Results</div>
        )}
      </div>
          </div>
</div>

      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal isOpen={paymentCard.isOpen} onClose={paymentCard.onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Payment Receipt Delete</ModalHeader> */}
          <ModalHeader style={{ color: "red", textAlign: "center" }}>
              <span className={Styles.asterisk}>*****</span> CRITICAL OPERATION{" "}
              <span className={Styles.asterisk}>*****</span>
            </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <p style={{
                    color: "red",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }} 
                  >
            Are you sure you want to delete the payment Receipt?
                  </p>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => handleDeletePayment(paymentId._id)}
            >
              Yes
            </Button>
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => paymentCard.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs isLazy isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab style={{ fontWeight: "bold" }}>Fees</Tab>
                <Tab style={{ fontWeight: "bold" }}>Concession</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Fees
                    onClose={onClose}
                    is_concession_bill={false}
                    setRequestInProgress={setRequestInProgress}
                  />
                </TabPanel>
                <TabPanel>
                  <Fees
                    onClose={onClose}
                    is_concession_bill={true}
                    setRequestInProgress={setRequestInProgress}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={handleAddPenalty.isOpen}
        onClose={handleAddPenalty.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent className={Styles.customModalContent}>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton style={{ background: "black", color: "white" }} />
          <ModalBody>
            <div>
              <div className={Styles.headerSectionModal}>
                <p className={Styles.fineSection1Heading}>S. No.</p>
                <p className={Styles.fineSectionDesHeading}>Category</p>
                <p className={Styles.fineSectionDesHeading}>Description</p>
                <p className={Styles.fineSection2Heading}>Amount (INR)</p>
                <p className={Styles.fineSectionDesHeading}>Penalty Date</p>
                <p className={Styles.fineSection2Heading}>Bill No.</p>
                <p className={Styles.fineSectionDesHeading}>Payment Date</p>
                <p className={Styles.fineSection3}> </p>
              </div>
              <div className={Styles.penaltyDataOverflow}>
                {penaltyList &&
                  penaltyList.map((penaltyData, id) => {
                    return (
                      <div className={Styles.headerSectionModal2}>
                        <p className={Styles.fineSection1} disabled={true}>
                          {id + 1}
                        </p>
                        <p className={Styles.fineSectionDes} disabled={true}>
                          {penaltyData.penalty_type}
                        </p>
                        <textarea
                          className={Styles.fineSectionDes}
                          value={penaltyData.description}
                          disabled={true}
                        />
                        <input
                          className={Styles.fineSection2}
                          value={penaltyData.amount}
                          disabled={true}
                          style={{
                            color:
                              penaltyData.payment_status === "Unpaid"
                                ? "#e33529"
                                : "#37c932",
                            fontWeight: "bold",
                          }}
                        />
                        <p className={Styles.fineSectionDes} disabled={true}>
                          {moment(penaltyData.date).format("DD/MM/YYYY")}
                        </p>
                        <input
                          className={Styles.fineSection2}
                          value={
                            penaltyData.payment_status == "Paid"
                              ? penaltyData.bill_number
                              : "-"
                          }
                          disabled={true}
                        />
                        <p className={Styles.fineSectionDes}>
                          {penaltyData.payment_pay_date == null
                            ? penaltyData.payment_status
                            : moment(penaltyData.payment_pay_date).format(
                                "DD/MM/YYYY"
                              )}
                        </p>
                        <div className={Styles.fineSection3}>
                          {penaltyData.payment_status === "Unpaid" ? (
                            <>
                              <div
                                className={Styles.fineSectionIcon}
                                onClick={() =>
                                  updateEnable((id = { data: penaltyData }))
                                }
                                style={{ marginRight: "2px" }}
                              >
                                <img src={editIcon} />
                              </div>
                              <div
                                className={Styles.fineSectionIcon}
                                onClick={() =>
                                  deletePenalty((id = { data: penaltyData }))
                                }
                              >
                                <img src={deleteIcon} />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {penaltyData.payment_status === "Unpaid" ? (
                            <div
                              onClick={() =>
                                penaltyPayment((id = penaltyData._id))
                              }
                            >
                              <p>
                                <AiOutlineDollarCircle
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "5px",
                                  }}
                                />
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button colorScheme="blue" onClick={() => penaltyModales()}>
              Add Penalty
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={handleUpdatePenalty.isOpen}
        onClose={handleUpdatePenalty.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.modalContainer}>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Amount: <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  name="amount"
                  onChange={(e) => onValueChange(e)}
                  value={updatePenalty.amount}
                  required
                  placeholder="Amount"
                />
              </div>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Description: <span className={Styles.asterisk}>*</span>
                </p>
                <textarea
                  name="description"
                  required
                  style={{ width: "35%" }}
                  onChange={(e) => onValueChange(e)}
                  value={updatePenalty.description}
                  placeholder="Description"
                />
              </div>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Type: <span className={Styles.asterisk}>*</span>
                </p>
                <div style={{ width: "35%" }}>
                  <Select
                    name="penalty_type"
                    placeholder="Type"
                    required
                    onChange={(e) => onValueChange(e)}
                    value={updatePenalty.penalty_type}
                  >
                    <option value="Lab">Lab</option>
                    <option value="Library">Library</option>
                    <option vlaue="Other">Other</option>
                  </Select>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button colorScheme="red" onClick={() => updatePenaltyDetails()}>
              Update Penalty
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={handleAddPenaltyModal.isOpen}
        onClose={handleAddPenaltyModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.modalContainer}>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Amonut: <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  name="amount"
                  onChange={(e) => setPenaltyAmount(e.target.value)}
                  required
                  placeholder="Amount"
                />
              </div>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Bill No.: <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  name="billNo"
                  required
                  onChange={(e) => setPenaltyBillNo(e.target.value)}
                  placeholder="Bill No."
                />
              </div>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Description: <span className={Styles.asterisk}>*</span>
                </p>
                <textarea
                  style={{ width: "35%" }}
                  name="Description"
                  required
                  onChange={(e) => setPenaltyDescription(e.target.value)}
                  placeholder="Description"
                />
              </div>
              <div className={Styles.addField}>
                <p className={Styles.heading}>
                  Type: <span className={Styles.asterisk}>*</span>
                </p>
                <div style={{ width: "35%" }}>
                  <Select
                    name="penalty_type"
                    placeholder="Type"
                    required
                    onChange={(e) => setPenaltyType(e.target.value)}
                    value={penaltyType}

                    // style={{width:"35%"}}
                  >
                    <option value="Lab">Lab</option>
                    <option value="Library">Library</option>
                    <option vlaue="Other">Other</option>
                  </Select>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button colorScheme="green" onClick={() => addPenalty()}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const Fees = ({ onClose, is_concession_bill, setRequestInProgress }) => {
  const location = useLocation();
  const toast = useToast();
  let stud_id = location.pathname.split("/")[5];
  const [inputFields, setInputFields] = React.useState(null);

  const handleInputChange = (e, parentField) => {
    setInputFields({ ...inputFields, [parentField]: e.target.value });
  };

  const handleAddPayment = async () => {
    console.log("inputFields", inputFields);
    if (
      inputFields.transport_amount ||
      inputFields.canteen_amount ||
      inputFields.tution_amount !== ""
    ) {
      let data = {
        ...inputFields,
        is_concession_bill: is_concession_bill,
        paid_date: new Date(inputFields.date).toISOString(),
      };
      let response = await addStudentPayment({ stud_id, data });
      // console.log("response",response)
      if (response) {
        // console.log("response inside  ",response)
        onClose();
        setRequestInProgress(true);
        // fetchPaymentDetails()
        toast({
          title: "Payment Added Succesfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } else {
      toast({
        title: "Please enter a details",
        variant: "left-accent",
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setRequestInProgress(false);
  };
  return (
    <div>
      <form>
        <div className={Styles.formGroup}>
          <p className={Styles.heading} style={{ marginBottom: "5px" }}>
            Tution Amount
          </p>
          <input
            className={Styles.formInput}
            required
            type="number"
            placeholder={is_concession_bill ? "Eg. 20000" : "Eg. 20000"}
            onChange={(e) => handleInputChange(e, "tution_amount")}
          />
          <p className={Styles.heading} style={{ marginBottom: "5px" }}>
            Canteen Amount
          </p>
          <input
            className={Styles.formInput}
            required
            type="number"
            placeholder={is_concession_bill ? "Eg. 5000" : "Eg. 5000"}
            onChange={(e) => handleInputChange(e, "canteen_amount")}
          />
          <p className={Styles.heading} style={{ marginBottom: "5px" }}>
            Transport Amount
          </p>
          <input
            className={Styles.formInput}
            required
            type="number"
            placeholder={is_concession_bill ? "Eg. 10000" : "Eg. 10000"}
            onChange={(e) => handleInputChange(e, "transport_amount")}
          />
          <label className={Styles.heading} style={{ marginBottom: "5px" }}>
            Paid Date <span className={Styles.asterisk}>*</span>
          </label>
          <input
            className={Styles.formInput}
            type="date"
            required
            placeholder="Paid Date"
            style={{ textTransform: "uppercase", color: "#a8b5c6" }}
            onChange={(e) => handleInputChange(e, "date")}
          />
          <button
            type="button"
            className={Styles.formButton}
            onClick={() => {
              handleAddPayment();
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const Concession = () => {
  return (
    <div>
      <form>
        <div className={Styles.formGroup}>
          <input
            className={Styles.formInput}
            required
            type="text"
            placeholder="Concession Tution Amount"
          />
          <input
            className={Styles.formInput}
            required
            type="text"
            placeholder="Concession Canteen Amount"
          />
          <input
            className={Styles.formInput}
            required
            type="text"
            placeholder="Concession Transport Amount"
          />
          <label className={Styles.formLabel}>Paid date</label>
          <input
            className={Styles.formInput}
            type="date"
            placeholder="Paid date"
          />
          <button type="submit" className={Styles.formButton}>
            submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SingleStudentFeePage;

import axios from 'axios'
import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

export async function requestCircularById (payload = {}) {
  const { circular_id } = payload
  
  if (isInvalid([circular_id])) {
    return { error: 'Field missing' }
  }
  const url = BASE_URL + '/student/circular/getOne'
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions())
    if (response.data.error) {
      return { error: response.data.error }
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e.response.data.error) {
      throw e.response.data.error
    }
    if (e.response.data) {
      throw e.response.data
    }
  }
}

export async function requestSearchCircular (payload = {}) {
  const url = BASE_URL + '/student/circular/getAll'
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions())
    if (response.data.error) {
      return { error: response.data.error }
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error
    }
    if (e?.response?.data) {
      throw e.response.data
    }

    throw e
  }
}

const Services = {
  requestCircularById,
  requestSearchCircular
}

export default Services

import React from "react";
import smuBlack from "./smuBlack.png";

const Header = () => {
  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "3px solid black",
        borderStyle: "double",
        paddingBottom: "10px",
      }}
    >
      <div style={{ width: "9vw" }}>
        <img style={{ width: "95%", height: "95%" }} src={smuBlack} />
      </div>
      <div
        style={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          marginLeft: "1vw",
          fontFamily: "sans",
        }}
      >
        <h1 style={{ fontSize: "1.6rem", fontWeight: 700 }}>
          ST MARY'S ENGLISH MEDIUM SCHOOL
        </h1>
        <p style={{ fontSize: "1rem", fontWeight: "light" }}>
          6th Cross, Balaji Layout, Kannarpady, Udupi - 576 103, Karnataka,
          India <br />
          <h4>
            
            Phone: 0820-2524455, 9481509322, Website: www.stmarysudupi.org
          </h4>
        </p>

        <p>
          <span style={{ fontWeight: "bold", fontSize: ".7rem" }}>
            Recognised by the Govt of Karnataka and affiliated to the Central
            Board of Secondary Education, New Delhi
          </span>
        </p>
        <p>
          <span
            style={{
              fontWeight: "bold",
              fontSize: ".7rem",
            }}
          >
            School Dise Code: 29160213207 Affiliation No: 830490 School Code:
            45410
          </span>
        </p>
      </div>
    </section>
  );
};
export default Header;

import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Select,
  Input,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import {
  requestSearchStudent,
  requestSearchStudentByFilter,
} from "services/admin-dashboard/student.service";
import { debounce } from "utilities/utils";
import { useClassAndSections } from "hooks/classAndSection";
import { useToast } from "@chakra-ui/react";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import defaultUser from "../../../assets/icons/User.png";

import Styles from "./Certificate.module.scss";
const ManagementCertificatePage = () => {
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});

  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const navigate = useNavigate();
  const toast = useToast();
  const param = useParams();
  const transferRoute = "transferc";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleTransferpage = (student) => {
    // console.log("student",student.student._id)
    if (transferRoute === param.id) {
      navigate(`${student.student._id}`);
    }
    if ("birthc" === param.id) {
      // console.log("inside condition");
      const data = "Date of Birth Certificate";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("studyc" === param.id) {
      const data = "STUDY CERTIFICATE";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("castec" === param.id) {
      const data = "CASTE CERTIFICATE";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("bonafide" === param.id) {
      const data = "BONAFIDE CERTIFICATE";
      navigate(`bonafide/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("conduct" === param.id) {
      const data = "CONDUCT CERTIFICATE";
      navigate(`${student.student._id}`, {
        state: {
          data,
        },
      });
    }
  };
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFields.class, inputFields.section, inputFields.studentName]);

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFields.class, classAndSections]);

  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);

  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };

      //   const response = await requestSearchStudentByFilter(payload);
      //   if (response.error) {
      //     console.error(response.error)
      //   }

      //   const _result = ((response?.result && Array.isArray(response?.result) && response?.result) ||
      //   (response?.result1 && Array.isArray(response?.result1) && response?.result1) ||
      //   (response && Array.isArray(response) && response)) || []
      // let dataWithSignedProfileURL = await Promise.all(_result.map(async (item) => {
      //   let signedURL = await getSignedUrlLink(item.personel.profile_url);
      //   let data = { ...item, "signed_url": signedURL }
      //   return data;}));
      //       console.log('resp', dataWithSignedProfileURL)
      //       setSearchResultList(dataWithSignedProfileURL);

      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          let dataWithSignedProfileURL = await Promise.all(
            response.map(async (item) => {
              let signedURL = await getSignedUrlLink(item.personel.profile_url);
              let data = { ...item, signed_url: signedURL };
              return data;
            })
          );
          // console.log("resp", dataWithSignedProfileURL);
          setSearchResultList(dataWithSignedProfileURL);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };

  const birthnotification = () => {
    return (
      <>
        <div>Date of birth certificate</div>
      </>
    );
  };

  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <button onClick={onOpen}>Open Modal</button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>hiii</ModalBody>

            <ModalFooter>
              <button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </button>
              <button variant="ghost">Secondary Action</button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  const handleManualTC = () => {
    if (transferRoute === param.id) {
      navigate("manualTc");
    }
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <div className={Styles.filterWrapper}>
          <Input
            name="studentName"
            variant="filled"
            placeholder="Search"
            value={inputFields.studentName}
            onChange={handleInputChange}
          />
        </div>

        <div className={Styles.filterWrapper}>
          <Select
            value={inputFields.class ? inputFields.class : ""}
            onChange={handleInputChange}
            name="class"
            placeholder="Select Grade"
          >
            {classAndSections.map((grade, idx) => (
              <option key={grade.class + idx} value={grade.class}>
                Grade {grade.class}
              </option>
            ))}
          </Select>
        </div>

        <div className={Styles.filterWrapper}>
          {console.log(sections)}
          <Select
            value={inputFields.section ? inputFields.section : ""}
            onChange={handleInputChange}
            name="section"
            placeholder="Select Section"
          >
            {sections
              ? sections.map((section, idx) => (
                  <option key={section + idx} value={section}>
                    Section {section}
                  </option>
                ))
              : ""}
          </Select>
        </div>
        <div className={Styles.filterWrapper}>
          <NavLink
            className={Styles.ManualButton}
            to={transferRoute === param.id ? "manualTc" : "manualconductcerti"}
          >
            Manual
          </NavLink>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            studentList.map((student, idx) => (
              <div
                onClick={() => handleTransferpage({ student })}
                key={student["_id"] + idx}
                className={Styles.list}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    // src={student.signed_url ? student.signed_url : defaultUser}
                    src={
                      student?.url ||
                      student?.personel?.profile_url.includes("http")
                        ? student.url
                          ? student.url
                          : student?.personel?.url
                        : defaultUser
                    }
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info} onClick={onOpen}>
                  <p className={Styles.name}>{student.name || "--"}</p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagementCertificatePage;

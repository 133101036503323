import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function requestAllTeachers() {
  const url = BASE_URL + "/management/teacher/get-teacher";

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestSearchTeacher(payload = {}) {
  const url = BASE_URL + "/management/teacher/search-teacher";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    // console.log("Search payload", payload);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestSearchTeacherDetails(payload = {}) {
  const url = BASE_URL + "/management/teacher/downloadTeacher";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestTeacherDetailsById(payload = {}) {
  const { teacherId } = payload;
  if (!teacherId && teacherId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/teacher/getTeacher/" + teacherId;

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestDeleteStaff(payload = {}) {
  const { teacher_id } = payload;

  if (!teacher_id || teacher_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/teacher/delete-teacher";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateTeacherDetails(payload = {}) {
  const { teacherId, ...rest } = payload;

  if (!teacherId && teacherId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/teacher/update-teacher/" + teacherId;
  const requestPayload = rest;

  try {
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestListByTeacherDepartment(payload = {}) {
  const { ...rest } = payload;

  const url = BASE_URL + "/management/teacher/list-teacher-department";
  const requestPayload = rest;

  try {
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    // console.log(response.data.result);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

const Services = {
  requestSearchTeacher,
  requestAllTeachers,
  requestSearchTeacherDetails,
  requestTeacherDetailsById,
  requestDeleteStaff,
  requestUpdateTeacherDetails,
  requestListByTeacherDepartment,
};

export default Services;

import React from "react";
import Styles from "./Notification.module.scss";
import { useEffect } from "react";
import {
  requestAllNotifications,
  requestSingleNotificationDetail,
} from "services/student-dashboard/notification.service";
import { useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { BiUpArrowCircle } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

const StaffNotification = () => {
  const singleNotificationModal = useDisclosure();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [skip, setSkip] = useState(0);
  const [showLessVisible, setShowLessVisible] = useState(false);
  const [singleNotification, setSingleNotifcation] = useState([]);
  const [singleNotificationID, setSingleNotificationID] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleRefresh = () => {
    window.location.reload(true);
  };
  const handleNotifications = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        skip: skip,
      };
      const response = await requestAllNotifications(payload);
      setNotifications(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleSingleNotification = async () => {
    try {
      const payload = {
        notification_id: singleNotificationID,
      };
      const response = await requestSingleNotificationDetail(payload);
      setSingleNotifcation(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (singleNotificationID && singleNotificationID) {
      handleSingleNotification();
    }
  }, [singleNotificationID]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const timeFormat = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${formattedHours}:${minutes}:${seconds} ${timeFormat}`;
  };

  useEffect(() => {
    handleNotifications();
    setShowLessVisible(skip > 0);
  }, [skip]);
  const loadMoreNotifications = () => {
    if (skip > 0) {
      setSkip((prevSkip) => prevSkip - 1);
    } else {
      setSkip((prevSkip) => prevSkip + 1);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <p className={Styles.stats}>
          NOTIFICATIONS (
          {notifications &&
            notifications.notifications_count &&
            notifications.notifications_count}
          )
        </p>
        <div className={Styles.headerFilterWrapper}>
          <Tooltip label="Refresh" placement="left">
            <Button onClick={handleRefresh} colorScheme="blue">
              <FiRefreshCw fontSize={30} />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <Spinner />
          ) : notifications && notifications?.notifications?.length === 0 ? (
            <div className={Styles.noResult}>No Notification Available</div>
          ) : (
            notifications &&
            notifications?.notifications &&
            notifications.notifications.map((notification) => (
              <div
                className={
                  notification.is_read ? Styles.list : Styles.listWithoutRead
                }
                onClick={() => {
                  singleNotificationModal.onOpen();
                  setSingleNotificationID(notification._id);
                }}
                key={notification._id}
              >
                <AiOutlineUser
                  fontSize={30}
                  style={{ border: "1px solid black", borderRadius: "100%" }}
                />
                <div className={Styles.content}>
                  <p className={Styles.fontSize}>
                    {notification.user_name} |{" "}
                    {notification.user_role.toUpperCase()}
                  </p>
                  <p className={Styles.fontSize}>{notification.title}</p>
                  <p className={Styles.date}>
                    {formatDate(notification.updatedAt)}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
        <div className={Styles.button}>
          {showLessVisible &&
            notifications &&
            notifications?.notifications?.length >= 1 && (
              <div>
                <Button onClick={loadMoreNotifications} colorScheme="red">
                  Show Less
                </Button>
              </div>
            )}
          {!showLessVisible &&
            notifications &&
            notifications?.notifications?.length >= 1 && (
              <div>
                <Button onClick={loadMoreNotifications} colorScheme="teal">
                  Show More
                </Button>
              </div>
            )}
        </div>
      </div>
      <Modal
        isOpen={singleNotificationModal.isOpen}
        onClose={() => {
          handleNotifications();
          singleNotificationModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p>Notification Detail</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.list}>
              <AiOutlineUser
                fontSize={30}
                style={{ border: "1px solid black", borderRadius: "100%" }}
              />
              <div className={Styles.content}>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.notifier?.user_name.toUpperCase()}{" "}
                  |{" "}
                  {singleNotification?.notification?.notifier?.user_role.toUpperCase()}
                </p>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.title}
                </p>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.description}
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

export default StaffNotification;

import axios from "axios";
import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";

export async function requestAllClassroom(payload = {}) {
  const url = BASE_URL + "/admin/class/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
  }
}
export async function requestAllSubjects(payload = {}) {
  const { class_id } = payload;

  if (!class_id || class_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/class/getSubjects";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response", response.data);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
  }
}
export async function requestAddClass(payload = {}) {
  const { class: _class, section, class_teacher } = payload;
  const { name, id } = class_teacher;

  if (isInvalid([_class, section, class_teacher, name, id])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/class/create";

  try {
    const requestPayload = {
      class: _class,
      section,
      class_teacher,
    };

    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAddSubject(payload = {}) {
  const { class_id, subject } = payload;
  const {
    section,
    teacher_name,
    teacher_id,
    class: _class,
    subject: _subject,
    class_id: _class_id,
    is_scholastic,
    subject_code,
  } = subject;

  if (
    isInvalid([
      class_id,
      section,
      teacher_id,
      teacher_name,
      _class,
      _subject,
      _class_id,
      is_scholastic,
      subject_code,
    ])
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/class/addSubject";

  try {
    const requestPayload = {
      class_id,
      subject,
    };
    console.log("payload", requestPayload);
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAddElectiveSubject(payload = {}) {
  const { class_id, subjects, elective_name } = payload;

  if (isInvalid([class_id, subjects, elective_name])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/class/addElectiveSubject";

  try {
    const requestPayload = { class_id, subjects, elective_name };
    console.log("payloadd", requestPayload);
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestDeleteClassroom(payload = {}) {
  const { classId } = payload;

  if (!classId || classId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/class/delete-class";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestSearchClassRoom(payload = {}) {
  const url = BASE_URL + "/admin/class/searchByname";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestEditSubject(payload = {}) {
  const url = BASE_URL + "/admin/class/updateSubject";

  try {
    console.log("payload", payload);
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestDeleteSubject(payload = {}) {
  const url = BASE_URL + "/admin/class/removeSubject";

  try {
    console.log("payload", payload);
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestDeleteElective(payload = {}) {
  const url = BASE_URL + "/admin/class/removeElectiveSubject";

  try {
    console.log("payload", payload);
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestEditElective(payload = {}) {
  const url = BASE_URL + "/admin/class/updateElectiveSubject";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUpdateClassTeacher(payload = {}) {
  const { class_id, teacher_id } = payload;

  if (!class_id || class_id === "" || !teacher_id || teacher_id === "") {
    return { error: "Field missing" };
  }
  const requestPayload = { teacher_id: teacher_id };
  const url = BASE_URL + "/admin/class/updateClass/" + class_id;

  try {
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response", response.data);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
  }
}
export async function requestUpdateSubjectMarks(classId, payload = {}) {
  if (!classId && classId === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/subjectMarks/update-marks/" + classId;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAllSubjectMarks(classId, payload = {}) {
  if (!classId && classId === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/subjectMarks/getOne-subject/" + classId;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestClassDetails(payload = {}) {
  const url = BASE_URL + "/admin/class/getDetails";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
const Services = {
  requestAllClassroom,
  requestAllSubjects,
  requestAddClass,
  requestAddSubject,
  requestAddElectiveSubject,
  requestDeleteClassroom,
  requestSearchClassRoom,
  requestEditSubject,
  requestDeleteSubject,
  requestDeleteElective,
  requestEditElective,
  requestUpdateClassTeacher,
  requestUpdateSubjectMarks,
  requestAllSubjectMarks,
  requestClassDetails,
};
export default Services;

import axios from "axios";
import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";

export async function requestAllRoutes(payload = {}) {
  const url = BASE_URL + "/admin/transport/route/all";
  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAddRoute(payload = {}) {
  const { route, route_number, particulars, source, destination } = payload;

  if (isInvalid([route, route_number, particulars, source, destination])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/route/new";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestDeleteRouteById(payload = {}) {
  const { routeId } = payload;

  if (isInvalid([routeId])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/route/remove/" + routeId;

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestRouteById(payload = {}) {
  const { routeId } = payload;
  if (!routeId || routeId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/route/one/" + routeId;

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestRoutesByRouteNumber(payload = {}) {
  const { routeNumber } = payload;

  if (!routeNumber || routeNumber === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/route/number/" + routeNumber;

  try {
    const response = await axios.get(url, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestUpdateRouteById(payload = {}) {
  const { routeId, route_number, ...rest } = payload;
  const { route, buses, destination, particulars } = rest;

  if (isInvalid([routeId, route, buses, destination, particulars])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/route/update/" + routeId;

  try {
    const response = await axios.post(url, { ...rest }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAllBuses(payload = {}) {
  const url = BASE_URL + "/admin/transport/bus/all";

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestAddBus(payload = {}) {
  const {
    source,
    destination,
    routeNumber,
    busNumber,
    driverName,
    driverPhone,
    busPlateNumber,
  } = payload;

  if (
    isInvalid([
      source,
      destination,
      routeNumber,
      busNumber,
      driverName,
      driverPhone,
      busPlateNumber,
    ])
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/bus/new";

  const requestPayload = {
    routeNumber,
    data: {
      bus_number: busNumber,
      bus_plate_number: busPlateNumber,
      from: source,
      to: destination,
      driver_name: driverName,
      driver_phone: driverPhone,
    },
  };

  try {
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestUpdateBusById(payload = {}) {
  const { busId, driverName, driverPhone, busPlateNumber, busNumber } = payload;

  if (isInvalid([busId, driverName, driverPhone, busPlateNumber, busNumber])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/bus/update/" + busId;

  const requestPayload = {
    driver_name: driverName,
    driver_phone: driverPhone,
    bus_plate_number: busPlateNumber,
    bus_number: busNumber,
  };

  try {
    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function requestBusById(payload = {}) {
  const { busId } = payload;
  if (!busId || busId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/bus/one/" + busId;

  try {
    const response = await axios.get(url, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestTransportEnrolledStudents(payload = {}) {
  const url = BASE_URL + "/admin/transport/enroll/all";
  try {
    const response = await axios.get(url, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestSearchEnrolledStudents(payload = {}) {
  const url =
    BASE_URL + "/admin/transport/enroll/searchEnrollStudentWithFilter";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestEnrolledStudentById(payload = {}) {
  const { studentId } = payload;
  const url = BASE_URL + "/admin/transport/enroll/one/" + studentId;
  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestDeleteBusById(payload = {}) {
  const { busId } = payload;

  if (isInvalid([busId])) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/transport/bus/remove/" + busId;

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestEnrolledStudentsDetails(payload = {}) {
  const url = BASE_URL + "/admin/transport/enroll/download";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
const Services = {
  requestAddRoute,
  requestAllRoutes,
  requestRouteById,
  requestRoutesByRouteNumber,
  requestUpdateRouteById,
  requestDeleteRouteById,
  requestAllBuses,
  requestAddBus,
  requestUpdateBusById,
  requestBusById,
  requestTransportEnrolledStudents,
  requestSearchEnrolledStudents,
  requestDeleteBusById,
  requestEnrolledStudentsDetails,
};

export default Services;

import React, { useEffect, useState } from "react";
import Styles from "./Header.module.scss";
import Logo from "../../assets/logos/smu.png";
import { useNavigate, useLocation } from "react-router-dom";
import { TbArrowBackUp } from "react-icons/tb";
import {
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { RiUserShared2Line } from "react-icons/ri";
import axios from "axios";
import { getRequestOptions } from "services/utils";
import SwitchStepper from "./SwitchStepper";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isBackVisible, setBackVisible] = useState(true);
  const userSwitch = useDisclosure();
  //checking current path to render back button
  useEffect(() => {
    if (location.pathname === "/admin/dashboard") setBackVisible(false);
    else if (location.pathname === "/staff/dashboard") setBackVisible(false);
    else if (location.pathname === "/student/dashboard") setBackVisible(false);
    else if (location.pathname === "/management/dashboard")
      setBackVisible(false);
    else setBackVisible(true);
  }, [location.pathname]);

  const handleClick = () => {
    navigate(-1);
  };
  const multipleUserFound = localStorage.getItem("multipleStudent");
  return (
    <div className={Styles.headerDiv} style={{}}>
      <div className={Styles.wrappers}>
        <div className={Styles.wrapper}>
          {isBackVisible ? (
            <Icon
              className={Styles.backIcon}
              as={TbArrowBackUp}
              onClick={handleClick}
            />
          ) : (
            ""
          )}
          <div className={Styles.iconDiv}>
            <img className={Styles.icon} src={Logo} alt="logo" />
          </div>
          <div className={Styles.textDiv}>
            <h1 className={Styles.header}>
              St Mary's English Medium CBSE School
            </h1>
            <p className={Styles.subTitle}>
              [Affiliated to the CBSE, Delhi Affn. No. 830490 School Code.
              45410]
            </p>
            <p className={Styles.subTitle}>Kannarpady, Udupi</p>
          </div>
        </div>
        {multipleUserFound ? (
          <div
            className={Styles.poweredtext}
            onClick={() => userSwitch.onOpen()}
          >
            <RiUserShared2Line />
          </div>
        ) : null}
      </div>
      <Modal isOpen={userSwitch.isOpen} onClose={userSwitch.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            Switch Student
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ width: "100%", overflow: "hidden" }}>
              <SwitchStepper />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Header;

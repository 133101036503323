import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Select,
  Input,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import {
  requestSearchStudent,
  requestSearchStudentByFilter,
} from "services/admin-dashboard/student.service";
import { debounce } from "utilities/utils";
import { useClassAndSections } from "hooks/classAndSection";
import { useToast } from "@chakra-ui/react";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import defaultUser from "../../../assets/icons/User.png";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Styles from "./Certificate.module.scss";
const CertificatePage = () => {
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const navigate = useNavigate();
  const toast = useToast();
  const param = useParams();
  const transferRoute = "transferc";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certificateName, setCertifiacteName] = useState("");
  const handleTransferpage = (student) => {
    // console.log("student",student.student._id)
    if (transferRoute === param.id) {
      navigate(`${student.student._id}`);
      setCertifiacteName("Transfer Certificate");
    }
    if ("birthc" === param.id) {
      // console.log("inside condition");
      setCertifiacteName("Birthday Certificate");
      const data = "Date of Birth Certificate";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("studyc" === param.id) {
      setCertifiacteName("Study Certificate");
      const data = "STUDY CERTIFICATE";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("castec" === param.id) {
      setCertifiacteName("Caste Certificate");
      const data = "CASTE CERTIFICATE";
      navigate(`certi/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("bonafide" === param.id) {
      setCertifiacteName("Bonafide Certificate");
      const data = "BONAFIDE CERTIFICATE";
      navigate(`bonafide/${student.student._id}`, {
        state: {
          data,
        },
      });
    }
    if ("conduct" === param.id) {
      setCertifiacteName("Conduct Certificate");
      const data = "CONDUCT CERTIFICATE";
      navigate(`${student.student._id}`, {
        state: {
          data,
        },
      });
    }
  };
  const checkCertificate = () => {
    if (transferRoute === param.id) {
      setCertifiacteName("Transfer Certificate");
    }
    if ("birthc" === param.id) {
      // console.log("inside condition");
      setCertifiacteName("Birthday Certificate");
    }
    if ("studyc" === param.id) {
      setCertifiacteName("Study Certificate");
    }
    if ("castec" === param.id) {
      setCertifiacteName("Caste Certificate");
    }
    if ("bonafide" === param.id) {
      setCertifiacteName("Bonafide Certificate");
    }
    if ("conduct" === param.id) {
      setCertifiacteName("Conduct Certificate");
    }
  };
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);
    checkCertificate();
    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);

  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);

  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };

  const birthnotification = () => {
    return (
      <>
        <div>Date of birth certificate</div>
      </>
    );
  };

  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <button onClick={onOpen}>Open Modal</button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>hiii</ModalBody>

            <ModalFooter>
              <button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </button>
              <button variant="ghost">Secondary Action</button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  const handleManualTC = () => {
    if (transferRoute === param.id) {
      navigate("manual");
    }
    if ("conduct" === param.id) {
      // const data = "CONDUCT CERTIFICATE";
      navigate("manual");
    }
    if ("studyc" === param.id) {
      // const data = "STUDY CERTIFICATE";
      navigate("manual");
    }
    if ("bonafide" === param.id) {
      // const data = "BONAFIDE CERTIFICATE";
      navigate("manual");
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <div className={Styles.certificateName}>
          Certifcate | {certificateName && certificateName}
        </div>
        <div className={Styles.filterOptions}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative", marginLeft: "20px" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={inputFields.studentName}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                  paddingRight="3rem"
                  style={{ width: "100%" }}
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
            {/* <Input
              name="studentName"
              variant="filled"
              placeholder="Search"
              value={inputFields.studentName}
              onChange={handleInputChange}
            /> */}
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.class ? inputFields.class : ""}
              onChange={handleInputChange}
              name="class"
              placeholder="Select Grade"
            >
              {classAndSections.map((grade, idx) => (
                <option key={grade.class + idx} value={grade.class}>
                  Grade {grade.class}
                </option>
              ))}
            </Select>
          </div>

          <div className={Styles.filterWrapper}>
            {console.log(sections)}
            <Select
              value={inputFields.section ? inputFields.section : ""}
              onChange={handleInputChange}
              name="section"
              placeholder="Select Section"
            >
              {sections
                ? sections.map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section}
                    </option>
                  ))
                : ""}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Tooltip label="Create Manual Certificate">
              <Button
                className={Styles.ManualButton}
                onClick={handleManualTC}
                // to={transferRoute === param.id ? "manualTc" : "manualconductcerti"}
              >
                Manual
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            studentList.map((student, idx) => (
              <div
                onClick={() => handleTransferpage({ student })}
                key={student["_id"] + idx}
                className={Styles.list}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={student?.url != "N/A" ? student.url : defaultUser}
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info} onClick={onOpen}>
                  <p className={Styles.name}>{ truncateText(student.name || "--",15)}</p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;

import { Select } from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Styles from './StaffExams.module.scss';

const StaffExams = () => {

    useEffect(() => {
        localStorage.setItem('report', '');
    }, []);

    return (
        <div className={Styles.body}>
            <div className={Styles.header}>
                <h1 className={Styles.headTitle}>Exams</h1>
                {/* <div className={Styles.dropDown}>
                <Select onClick={(e) => console.log(e.target.value)} name="class" placeholder='Select Subject'>
                    <option value='0'>Science - 4A</option>
                    <option value='1'>Math - 5B</option>
                    <option value='2'>Class Teacher - 6A</option>
                    <option value='3'>English - 7A</option>
                    <option value='4'>History - 8A</option>
                </Select>
            </div> */}
            </div>
            <div className={Styles.wrapper}>
                <div className={Styles.row}>
                    {/* <Link to='marksentry'> */}
                    <Link to='marksentry/mid'>
                        <div className={Styles.button}>Marks Entry</div>
                    </Link>
                    <Link to='report'>
                        <div className={Styles.button}>Report Card</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default StaffExams;
// import React, { useEffect } from "react";
// import { Route, Link, Routes, useParams } from "react-router-dom";
// import Styles from "./PreviewReportCard.module.scss";
// import ReportCardPdf from "./ReportCardPdf/Components/Main/Main";
// import MarksNotReleased from "./MarksNotReleased";
// import { jsPDF } from "jspdf";
// import axios from "axios";

// const StudentPreviewReportCard = () => {
//   const config = {
//     headers: {
//       Authorization: `bearer ${window.localStorage.getItem("token")}`,
//     },
//     // headers: { "Authorization": `bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMjFkZGE5ODNhZDU1ODg1ZWRjY2JmYyIsImVtYWlsIjoic3R1ZGVudEBnbWFpbC5jb20iLCJyb2xlIjoic3R1ZGVudCIsInBob25lTnVtYmVyIjoiMTExMTExMTExMSIsImp3dEtleSI6ImFkZTEyOTJkLTBjYjItNGIwYi1iZTc1LTEyYWNmNTljZjFkNSIsImlhdCI6MTY2NDM0MDQ0MSwiZXhwIjoxNjk1ODc2NDQxfQ.VUMxo88ZMms6VZOk1F-xowOvyvI91nsRD_nk9BFxub8` }
//   };

//   const [report, setReport] = React.useState(null);
//   const params = useParams();

//   useEffect(() => {
//     axios
//       .post(
//         `${process.env.REACT_APP_BASE_URL}/student/exam/get`,
//         {
//           class: localStorage.getItem("student_class"),
//           exam: params.id === "mid" ? "mid_term" : "anual",
//         },
//         config
//       )
//       .then((response) => {
//         setReport(response.data);
//       })
//       .catch((err) => {
//         setReport(null);
//       });
//   }, []);

//   const createPDF = async () => {
//     const pdf = new jsPDF("portrait", "pt", "a4");
//     const data = await document.querySelector("#reportCardPdf");
//     pdf.html(data).then(() => {
//       pdf.save("reportcard.pdf");
//     });
//   };
//   console.log(report, "report");

//   return (
//     <>
//       {report ? (
//         <div className={Styles.body}>
//           <div className={Styles.header}>
//             <p className={Styles.headerText}>
//               Selected Student : {report.name} | Grade : {report.class}
//               {report.section}
//             </p>
//           </div>
//           <div className={Styles.header}>
//             <button className={Styles.button} onClick={() => createPDF()}>
//               Download
//             </button>
//           </div>
//           <div className={Styles.wrapper}>
//             <div id="reportCardTemp">
//               <ReportCardPdf data={report} type="template" />
//             </div>
//           </div>
//           <div style={{ display: "none" }}>
//             <div id="reportCardPdf">
//               <ReportCardPdf data={report} type="pdf" />
//             </div>
//           </div>
//         </div>
//       ) : (
//         <MarksNotReleased />
//       )}
//     </>
//   );
// };

// export default StudentPreviewReportCard;

import React, { useEffect } from "react";
import { Route, Link, Routes, useParams } from "react-router-dom";
import Styles from "./PreviewReportCard.module.scss";
import ReportCardPdf from "./ReportCardPdf/Components/Main/Main";
import MarksNotReleased from "./MarksNotReleased";
import { jsPDF } from "jspdf";
import axios from "axios";
const StudentPreviewReportCard = () => {
  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
    // headers: { "Authorization": `bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMjFkZGE5ODNhZDU1ODg1ZWRjY2JmYyIsImVtYWlsIjoic3R1ZGVudEBnbWFpbC5jb20iLCJyb2xlIjoic3R1ZGVudCIsInBob25lTnVtYmVyIjoiMTExMTExMTExMSIsImp3dEtleSI6ImFkZTEyOTJkLTBjYjItNGIwYi1iZTc1LTEyYWNmNTljZjFkNSIsImlhdCI6MTY2NDM0MDQ0MSwiZXhwIjoxNjk1ODc2NDQxfQ.VUMxo88ZMms6VZOk1F-xowOvyvI91nsRD_nk9BFxub8` }
  };
  const [report, setReport] = React.useState(null);
  const [reportCondition, setReportCondition] = React.useState(null);
  const params = useParams();
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/student/exam/get`,
        {
          class: localStorage.getItem("student_class"),
          exam: params.id === "mid" ? "mid_term" : "anual",
        },
        config
      )
      .then((response) => {
        setReport(response.data);
        setReportCondition(response.data.name);
      })
      .catch((err) => {
        setReport(null);
      });
  }, []);

  const createPDF = async () => {
    let pdf = new jsPDF("portrait", "pt", "a4");
    let data = await document.querySelector(`#reportCardPdf`);
    pdf.html(data).then(() => {
      pdf.save(`ExamReportCard_${report.name}.pdf`);
    });
  };

  //   const createPDF = async () => {
  //     const pdf = new jsPDF("portrait", "pt", "a4");
  //     const data = await document.querySelector("#reportCardPdf");
  //     pdf.html(data).then(() => {
  //       const fileName = "reportcard.pdf"; // default filename
  //       // Assuming the backend response contains the desired filename in a variable called `newFileName`
  //       if (newFileName) {
  //         const extIndex = fileName.lastIndexOf("."); // get the index of the file extension
  //         fileName = newFileName + fileName.substring(extIndex); // concatenate the new filename with the original file extension
  //       }
  //       pdf.save(fileName);
  //     });
  //   };
  //   if (reportCondition != null) {
  //     console.log("response response", reportCondition);
  //     console.log("marks res");
  //   } else {
  //     console.log("marks not res");
  //   }
  return (
    <>
      {reportCondition != null ? (
        <div className={Styles.body}>
          <div className={Styles.header}>
            <p className={Styles.headerText}>
              Student : {report.name} | Grade : {report.class}
              {report.section}
            </p>
          </div>
          <div className={Styles.header}>
            <button className={Styles.button} onClick={() => createPDF()}>
              Download
            </button>
          </div>
          <div className={Styles.wrapper}>
          <div className={Styles.reportCardTemp}>
            <div id="reportCardTemp" >
              <ReportCardPdf data={report} type="template" />
              </div>
            </div>
          </div>
          <div style={{ display: "none" }}>
            <div id="reportCardPdf">
              <ReportCardPdf data={report} type="pdf" />
            </div>
          </div>
        </div>
      ) : (
        <MarksNotReleased />
      )}
    </>
  );
};
export default StudentPreviewReportCard;

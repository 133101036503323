import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useDisclosure,
  Button,
  ModalFooter,
  useToast,
  Tooltip,
  Switch,
  Checkbox
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Icons } from "utilities/assets";
import {
  requestAllRoutes,
  requestAllBuses,
  requestAddBus,
  requestUpdateBusById,
  requestAddRoute,
  requestSearchEnrolledStudents,
  requestDeleteBusById,
  requestDeleteRouteById,
  requestUpdateRouteById,
  requestRouteById,
  requestEnrolledStudentsDetails
} from "services/admin-dashboard/transport.service";
import { useClassAndSections } from "hooks/classAndSection";
import { Link } from "react-router-dom";
import Styles from "./Transport.module.scss";
import { debounce } from "utilities/utils";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TimePicker from "react-time-picker";
import defaultUser from "../../../assets/icons/User.png";
import { BsCheckSquare } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import Papa from "papaparse";
import moment from "moment";
const PARTICULAR_INPUT_FIELDS = {
  stopNumber: 1,
  morningTiming: "",
  eveningTiming: "",
  termOneFee: "",
  termTwoFee: "",
};
const ADD_BUS_INPUT_FIELD_INITIAL_STATE = {
  route: "",
  driverPhone: "",
  driverName: "",
  busNumber: "",
  busPlateNumber: "",
  firstPart: "",
  secondPart: "",
  thirdPart: "",
  fourthPart: "",
};
const BUS_MODAL_CONTENT = {
  ADD: {
    head: "Add Bus",
    cta: "Add Bus",
  },
  EDIT: {
    head: "Edit Bus",
    cta: "Update",
  },
};
// const localValue = localStorage.getItem("student_filter");
const SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE = {
  route: "",
  bus: "",
  class: localStorage.getItem("seletedClass")
    ? localStorage.getItem("seletedClass")
    : 1,
  section: "A",
  studentName: "",
};
const InputSelect = ({
  labelKey = "label",
  valueKey = "value",
  isCustomLabel = false,
  labelChild = null,
  options,
  ...props
}) => {
  return (
    <Select {...props}>
      {options.map((v, idx) => (
        <option key={v[valueKey] + idx} value={v[valueKey]}>
          {isCustomLabel ? labelChild(v) : v[labelKey]}
        </option>
      ))}
    </Select>
  );
};

const Transport = () => {
  return (
    <div>
      <div className={Styles.body}>
        <Tabs isLazy isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
              Enrolled Students
            </Tab>
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
              Bus Details
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TranspostEnrolledStudents />
            </TabPanel>
            <TabPanel>
              <BusDetails />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

const BusDetails = () => {
  return (
    <div className={Styles.wrapper}>
      <Tabs isLazy isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Buses</Tab>
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Routes</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AddBus />
          </TabPanel>
          <TabPanel>
            <AddRoute />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

const AddBus = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allBuses, setBuses] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [operationType, setOperationType] = useState("");
  const [inputFields, setInputFields] = useState(
    ADD_BUS_INPUT_FIELD_INITIAL_STATE
  );
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [busDetailsDownload, setBusDetailsDownload] = useState("");
  const [delbusId, setDelBusId] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);
  const busDownload = useDisclosure();
  const busDelete = useDisclosure();
  const toast = useToast();
  useEffect(() => {
    requestPageDetails();
  }, []);

  const requestPageDetails = () => {
    setRequestInProgress(true);
    Promise.all([fetchAllBuses(), fetchAllRoutes()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  };

  const fetchAllRoutes = async () => {
    try {
      const response = await requestAllRoutes();
      if (response.error) {
        console.error(response.error);
      }
      if (response && Array.isArray(response)) {
        setRoutes(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAllBuses = async () => {
    setRequestInProgress(true);
    try {
      const response = await requestAllBuses();
      if (response.error) {
        console.error(response.error);
      }
      if (response && Array.isArray(response)) {
        setBuses(response);
        let newArray1 = [];
        response &&
          response.map((data) => {
            let obj1 = {
              "Bus No.": data.bus_number,
              "Bus No. Plate": data.bus_plate_number,
              "Starting Location": data.from,
              "Ending Location": data.to,
              "Driver Name": data.driver_name,
              "Driver Phone No.": data.driver_phone,
            };
            newArray1.push(obj1);
            setBusDetailsDownload(newArray1);
          });
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };

  const resetInputFields = () => {
    setInputFields(ADD_BUS_INPUT_FIELD_INITIAL_STATE);
  };

  const updateBusOperations = async () => {
    try {
      switch (operationType) {
        case "ADD": {
          const route = routes.find((i) => i._id === inputFields.route) || {};
          const { source, destination, route_number } = route;
          const combinedBusPlateNumber = `${inputFields.firstPart}${inputFields.secondPart}${inputFields.thirdPart}${inputFields.fourthPart}`;
          const payload = {
            ...inputFields,
            source,
            destination,
            routeNumber: route_number,
            busPlateNumber: combinedBusPlateNumber,
          };
          const resp = await requestAddBus(payload);
          if (resp) {
            setOperationType("");
            resetInputFields();
            onClose();
            setRequestInProgress(false);
            fetchAllBuses();
            toast({
              title: "Added Bus Successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "bottom-right",
            });
          }
          break;
        }
        case "EDIT": {
          const resp = await requestUpdateBusById({ ...inputFields });
          if (resp) {
            setOperationType("");
            resetInputFields();
            onClose();
            setRequestInProgress(false);
            fetchAllBuses();
            toast({
              title: resp.msg,
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "bottom-right",
            });
          }
          break;
        }
        default: {
        }
      }
    } catch (e) {
      console.error(e);
      toast({
        title: e.msg || e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const handleInputChange = (e) => {
    const { type, value, name, valueAsNumber } = e.target;
    let updatedInputFields = { ...inputFields };

    if (
      name === "firstPart" ||
      name === "secondPart" ||
      name === "thirdPart" ||
      name === "fourthPart"
    ) {
      updatedInputFields = {
        ...updatedInputFields,
        [name]: value,
      };
    } else {
      const _values = {
        number: valueAsNumber,
        text: value,
      };

      updatedInputFields = {
        ...updatedInputFields,
        [name]: _values[type] || value,
      };
    }
    const { firstPart, secondPart, thirdPart, fourthPart } = updatedInputFields;
    updatedInputFields.busPlateNumber = `${firstPart || ""}${secondPart || ""}${
      thirdPart || ""
    }${fourthPart || ""}`;

    setInputFields(updatedInputFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateBusOperations();
  };

  const handleAddBusClick = () => {
    setOperationType("ADD");
    onOpen();
  };
  const handleEditBus = (busDetails) => {
    const { driver_name, driver_phone, bus_plate_number, bus_number } =
      busDetails;
    const fourthPart = bus_plate_number.slice(-4);
    const busPlateNumber = bus_plate_number.slice(0, -4);
    const parts = [];
    for (let i = 0; i < busPlateNumber.length; i += 2) {
      parts.push(busPlateNumber.slice(i, i + 2));
    }
    let [firstPart, secondPart, thirdPart] = parts;
    const updatedBusPlateNumber = `${firstPart || ""}${secondPart || ""}${
      thirdPart || ""
    }${fourthPart}`;
    const _busDetails = {
      driverName: driver_name,
      driverPhone: driver_phone,
      busPlateNumber: updatedBusPlateNumber,
      busId: busDetails["_id"],
      firstPart: firstPart || "",
      secondPart: secondPart || "",
      thirdPart: thirdPart || "",
      fourthPart: fourthPart,
      busNumber: bus_number,
    };
    setOperationType("EDIT");
    setInputFields((prev) => ({
      ...prev,
      ..._busDetails,
      route: prev.route,
      // busNumber: prev.busNumber,
    }));
    onOpen();
  };
  const editBus = (bus) => {
    if (routes?.length != 0) {
      handleEditBus(bus);
    } else {
      toast({
        title: "Bus",
        description: "Please create route first",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const deleteBus = async (bus) => {
    setRequestInProgress(true);
    try {
      const response = await requestDeleteBusById({ busId: bus });
      if (response) {
        setRequestInProgress(false);
        fetchAllBuses();
        toast({
          title: response.msg,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast({
        title: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error(e);
      setRequestInProgress(false);
    }
    busDelete.onClose();
  };
  const busDel = (bus) => {
    busDelete.onOpen();
    setDelBusId(bus);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const fileName = "Bus Details";

  const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <div className={Styles.downloadButton}>
        <Button
          onClick={(e) => {
            exportToCSV(apiData, fileName);
            busDownload.onClose();
          }}
          colorScheme="green"
        >
          Yes
        </Button>
      </div>
    );
  };

  return (
    <div className={Styles.subWrapper}>
      <div className={Styles.header}>
        <p className={Styles.title}>Total Buses : {allBuses?.length}</p>
        <div style={{ display: "flex" }}>
          <Tooltip label="Add Bus">
            <div className={Styles.add} onClick={handleAddBusClick}>
              <Button colorScheme="teal" mr={3}>
                Create
              </Button>
            </div>
          </Tooltip>
          {allBuses?.length >= 1 ? (
            <Tooltip label="Download Bus Details">
              <div className={Styles.add} onClick={() => busDownload.onOpen()}>
                <Button colorScheme="green">Archive</Button>
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <div className={Styles.lists}>
        {requestInProgress ? (
          <div className={Styles.loaderWrapper}>
            <Spinner />
          </div>
        ) : allBuses?.length === 0 ? (
          <div className={Styles.noResult}>No Bus Found</div>
        ) : (
          allBuses.map((bus, index) => (
            <div key={bus["_id"] + index} className={Styles.list}>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Bus No.</p>
                <p className={Styles.listItemVal}>{bus.bus_number}</p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Route</p>
                <p className={Styles.listItemVal}>
                  {bus.from} - {bus.to}
                </p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>No. Plate</p>
                <p className={Styles.listItemVal}>{bus.bus_plate_number}</p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Driver Name</p>
                <p className={Styles.listItemVal}>{bus.driver_name}</p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Driver Phone No.</p>
                <p className={Styles.listItemVal}>{bus.driver_phone}</p>
              </div>
              <div className={Styles.edit}>
                <img
                  className={Styles.editImage}
                  onClick={() => editBus(bus)}
                  src={Icons.edit}
                  alt="edit"
                />
                <img
                  className={Styles.editImage}
                  onClick={() => busDel(bus)}
                  src={Icons.deleteIcon}
                  alt="delete"
                />
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetInputFields();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {BUS_MODAL_CONTENT[operationType || "ADD"]?.head || ""}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {routes?.length >= 1 ? (
              <form onSubmit={handleSubmit}>
                <div className={Styles.formGroup}>
                  {operationType !== "EDIT" && (
                    <>
                      <label className={Styles.formLabel}>
                        Route Name <span className={Styles.asterisk}>*</span>
                      </label>
                      <InputSelect
                        className={Styles.formInput}
                        onChange={handleInputChange}
                        name="route"
                        value={inputFields.route}
                        placeholder="Select Route"
                        options={routes}
                        isCustomLabel
                        labelChild={(prop) => (
                          <>
                            {prop.route} - {prop.route_number}
                          </>
                        )}
                        valueKey="_id"
                      />
                    </>
                  )}
                  <label className={Styles.formLabel}>
                    Bus Number <span className={Styles.asterisk}>*</span>
                  </label>
                  <input
                    className={Styles.formInput}
                    type="text"
                    name="busNumber"
                    value={inputFields.busNumber}
                    onChange={handleInputChange}
                    placeholder="Enter Bus No."
                  />
                  <label className={Styles.formLabel}>
                    Bus Number Plate <span className={Styles.asterisk}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      className={Styles.formInput}
                      type="text"
                      placeholder="KA"
                      name="firstPart"
                      value={inputFields.firstPart}
                      onChange={handleInputChange}
                      pattern="[A-Za-z]{2}"
                      maxLength={2}
                      autoComplete="off"
                      onInput={(e) => {
                        if (e.target.value.length >= 2) {
                          e.target.value = e.target.value.slice(0, 2);
                          e.target.blur();
                        }
                      }}
                      onKeyPress={(e) => {
                        const isAlphabet = /^[A-Za-z]+$/.test(e.key);
                        if (!isAlphabet) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <input
                      className={Styles.formInput}
                      type="number"
                      placeholder="20"
                      autoComplete="off"
                      name="secondPart"
                      value={inputFields.secondPart}
                      onChange={handleInputChange}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 2);
                      }}
                    />
                    <input
                      className={Styles.formInput}
                      type="text"
                      placeholder="AB"
                      name="thirdPart"
                      value={inputFields.thirdPart}
                      onChange={handleInputChange}
                      pattern="[A-Za-z]{2}"
                      maxLength={2}
                      autoComplete="off"
                      onInput={(e) => {
                        if (e.target.value.length >= 2) {
                          e.target.value = e.target.value.slice(0, 2);
                          e.target.blur();
                        }
                      }}
                      onKeyPress={(e) => {
                        const isAlphabet = /^[A-Za-z]+$/.test(e.key);
                        if (!isAlphabet) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <input
                      className={Styles.formInput}
                      type="number"
                      placeholder="3456"
                      name="fourthPart"
                      value={inputFields.fourthPart}
                      onChange={handleInputChange}
                      autoComplete="off"
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9]/g, "")
                          .slice(0, 4);
                      }}
                    />

                    {/* <input
                      type="hidden"
                      name="busPlateNumber"
                      value={combinedValue}
                    /> */}
                  </div>
                  <label className={Styles.formLabel}>
                    Driver Name <span className={Styles.asterisk}>*</span>
                  </label>
                  <input
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Driver Name"
                    name="driverName"
                    value={inputFields.driverName}
                    onChange={handleInputChange}
                  />
                  <label className={Styles.formLabel}>
                    Driver Phone No. <span className={Styles.asterisk}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      className={Styles.formInput}
                      style={{ width: "15%" }}
                      placeholder="+91"
                      readOnly
                      value="+91"
                    />
                    <input
                      className={Styles.formInput}
                      style={{ width: "80%" }}
                      type="number"
                      placeholder="Enter Driver Phone No."
                      name="driverPhone"
                      value={inputFields.driverPhone}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button type="submit" className={Styles.formButton}>
                    {requestInProgress && operationType !== "" ? (
                      <Spinner />
                    ) : (
                      BUS_MODAL_CONTENT[operationType || "ADD"]?.cta || ""
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "red",
                }}
              >
                Please Create Routes First.
              </p>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={busDownload.isOpen} onClose={busDownload.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Would you like to download bus details?</ModalBody>
          <ModalFooter>
            <ExportToExcel apiData={busDetailsDownload} fileName={fileName} />
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => busDownload.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={busDelete.isOpen} onClose={busDelete.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bus Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete the bus?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={() => deleteBus(delbusId?._id)}>
              Yes
            </Button>
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => busDelete.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const AddRoute = () => {
  const PARTICULAR_INPUT_FIELDS = {
    stopNumber: 1,
    morningTiming: "",
    eveningTiming: "",
    termOneFee: "",
    termTwoFee: "",
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [operationType, setOperationType] = useState("");
  const [inputFields, setInputFields] = useState({
    routeName: "",
    routeNumber: "",
  });
  const [particulars, setParticulars] = useState([
    { ...PARTICULAR_INPUT_FIELDS, stopNumber: 1 },
    { ...PARTICULAR_INPUT_FIELDS, stopNumber: 2 },
  ]);

  const [routeId, setrouteId] = useState();
  const [allRoutes, setRoutes] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [delRouteId, setDelRouteId] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [routeDetailsDownload, setRouteDetailsDownload] = useState("");
  const routeDelete = useDisclosure();
  const routeDownload = useDisclosure();
  const toast = useToast();
  useEffect(() => {
    fetchAllRoutes();
  }, []);

  const fetchAllRoutes = async () => {
    setRequestInProgress(true);
    try {
      const response = await requestAllRoutes();
      if (response.error) {
        console.error(response.error);
      }
      if (response && Array.isArray(response)) {
        setRoutes(response);
        let newArray1 = [];
        response &&
          response.map((data) => {
            let obj1 = {
              "Route No.": data.route_number,
              "Route Name": data.route,
              "Source Location": data.source,
              "Destination Location": data.destination,
              "Buses No.":
                data.buses && data.buses.length > 0
                  ? data.buses.join(", ")
                  : "-",
            };
            newArray1.push(obj1);
            setRouteDetailsDownload(newArray1);
          });
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleParticularsInputChange = (
    { target: { name, value, valueAsNumber, type } },
    i
  ) => {
    let _particulars = [...particulars];
    const _value = type === "time" ? value : valueAsNumber || value;

    if ((name === "termOneFee" || name === "termTwoFee") && value === "") {
      _particulars[i][name] = null;
    } else {
      _particulars[i][name] = _value;
    }

    setParticulars([..._particulars]);
  };

  const handleInputFieldsChange = ({
    target: { value, name, valueAsNumber, valueAsDate, type },
  }) => {
    const _value = { text: value, number: valueAsNumber, time: valueAsDate }[
      type
    ];
    setInputFields((prev) => ({ ...prev, [name]: _value }));
  };

  const addParticular = () => {
    let _particular = [...particulars];
    _particular.push({
      PARTICULAR_INPUT_FIELDS,
      stopNumber: particulars?.length + 1,
    });
    setParticulars([..._particular]);
  };
  const resetInputFields = () => {
    setInputFields({ routeName: "", routeNumber: "" });
    setParticulars([PARTICULAR_INPUT_FIELDS]);
  };

  const removeParticular = (i) => {
    if (particulars.length > 2) {
      let _particulars = [...particulars];
      _particulars.splice(i, 1);
      setParticulars(_particulars);
    } else {
      toast({
        title: "Cannot delete the last two stops.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const handleAdd = () => {
    setOperationType("ADD");
    setInputFields({
      routeName: "",
      routeNumber: "",
    });

    setParticulars([
      { ...PARTICULAR_INPUT_FIELDS, stopNumber: 1 },
      { ...PARTICULAR_INPUT_FIELDS, stopNumber: 2 },
    ]);

    onOpen();
  };

  const fetchRouteDetails = async (id) => {
    setRequestInProgress(true);
    try {
      const response = await requestRouteById({ routeId: id });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        const otherFields = {
          routeName: response.route,
          routeNumber: response.route_number,
          source: response.source,
          destination: response.destination,
          ...response,
        };
        const particulars = response.particulars.map(
          ({ stop, location, term1, term2, timing, ...rest }) => ({
            ...rest,
            stopNumber: stop,
            termOneFee: term1,
            termTwoFee: term2,
            morningTiming: timing.morning,
            eveningTiming: timing.evening,
            location: location,
          })
        );
        setInputFields(otherFields);
        setParticulars(particulars);
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleEdit = (routeId) => {
    setOperationType("EDIT");
    setrouteId(routeId);
    fetchRouteDetails(routeId);
    onOpen();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (particulars?.length < 2) {
      return;
    }
    const { 0: source, length, [length - 1]: destination } = particulars;
    const payload = {
      route: inputFields.routeName,
      route_number: inputFields.routeNumber,
      source: source.location,
      destination: destination.location,
      particulars: particulars.map(
        ({
          morningTiming,
          eveningTiming,
          termOneFee,
          termTwoFee,
          stopNumber,
          location,
          ...i
        }) => ({
          ...i,
          stop: stopNumber,
          term1: termOneFee == "" || termOneFee == null ? "N/A" : termOneFee,
          term2: termTwoFee === "" || termTwoFee === null ? "N/A" : termTwoFee,
          location: location,
          timing: {
            morning: morningTiming,
            evening: eveningTiming,
          },
        })
      ),
    };
    setRequestInProgress(true);
    switch (operationType) {
      case "ADD":
        try {
          const res = await requestAddRoute(payload);
          if (res) {
            setOperationType("");
            resetInputFields();
            onClose();
            setRequestInProgress(false);
            fetchAllRoutes();
          }
        } catch (e) {
          console.error(e);
          setRequestInProgress(false);
        }
        break;
      case "EDIT":
        try {
          const _payload = { ...payload, routeId };
          const res = await requestUpdateRouteById(_payload);
          if (res) {
            setOperationType("");
            resetInputFields();
            onClose();
            setRequestInProgress(false);
            fetchAllRoutes();
          }
        } catch (e) {
          console.error(e);
          setRequestInProgress(false);
        }
        break;
      default:
        break;
    }
  };

  const deleteRoute = async (route) => {
    setRequestInProgress(true);
    try {
      const response = await requestDeleteRouteById({ routeId: route });
      if (response) {
        setRequestInProgress(false);
        fetchAllRoutes();
        toast({
          title: response.msg,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast({
        title: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error(e);
      setRequestInProgress(false);
    }
    routeDelete.onClose();
  };
  const routeDel = (route) => {
    routeDelete.onOpen();
    setDelRouteId(route);
  };
  const handleCloseModal = () => {
    resetInputFields();
    setrouteId(null);
    onClose();
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(":");
    let period = "AM";
    let hour12 = parseInt(hour, 10);

    if (hour12 >= 12) {
      period = "PM";
      if (hour12 > 12) {
        hour12 -= 12;
      }
    }

    return `${hour12}:${minute} ${period}`;
  }

  const fileName = "Route Details";
  const ExportToExcel = ({ apiData, fileName, allRoutes }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = async () => {
      const sheets = {};
      const indexData = apiData.map((data) => {
        const moreInfo = `Route No. ${data["Route No."]}`;
        return {
          "Route No.": data["Route No."],
          "Route Name": data["Route Name"],
          "Source Location": data["Source Location"],
          "Destination Location": data["Destination Location"],
          "Buses No.": data["Buses No."],
          "More Info": {
            v: moreInfo,
            l: { Target: `'${moreInfo}'!A1` },
          },
        };
      });

      sheets["Index"] = XLSX.utils.json_to_sheet(indexData);
      sheets["Index"]["!cols"] = [
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      for (let i = 0; i < allRoutes.length; i++) {
        const route = allRoutes[i];
        try {
          const response = await requestRouteById({ routeId: route._id });
          if (response.error) {
            console.error(`Error for Route ${i + 1}:`, response.error);
            continue;
          }
          if (response) {
            const routeDetails = Array.isArray(response)
              ? response
              : [response];

            let routeData = [];
            routeDetails.forEach((data) => {
              data.particulars.forEach((particular) => {
                const term1 =
                  particular.term1 === "N/A" ? 0 : Number(particular.term1);
                const term2 =
                  particular.term2 === "N/A" ? 0 : Number(particular.term2);
                const morningTiming = convertTo12HourFormat(
                  particular.timing.morning
                );
                const eveningTiming = convertTo12HourFormat(
                  particular.timing.evening
                );
                let particularsInfo = {
                  "Stop No.": particular.stop,
                  "Stop Location": particular.location,
                  "Morning Timing": morningTiming,
                  "Evening Timing": eveningTiming,
                  "Term1 Fee": term1,
                  "Term2 Fee": term2,
                  "Total Amount": term1 + term2,
                };
                routeData.push(particularsInfo);
              });
            });

            sheets[`Route No. ${route.route_number}`] =
              XLSX.utils.json_to_sheet(routeData);
            sheets[`Route No. ${route.route_number}`]["!cols"] = [
              { wch: 10 },
              { wch: 20 },
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 20 },
            ];
          }
        } catch (error) {
          console.error(`Error for Route ${i + 1}:`, error);
        }
      }

      const wb = {
        Sheets: sheets,
        SheetNames: [
          "Index",
          ...allRoutes.map((route) => `Route No. ${route.route_number}`),
        ],
      };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <div className={Styles.downloadButton}>
        <Button
          onClick={(e) => {
            exportToCSV(apiData, fileName);
            routeDownload.onClose();
          }}
          colorScheme="green"
        >
          Yes
        </Button>
      </div>
    );
  };

  // const ExportToExcel = ({ apiData, fileName, allRoutes }) => {
  //   const fileType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";

  //   const exportToCSV = async () => {
  //     const sheets = {};
  //     sheets["Index"] = XLSX.utils.json_to_sheet(apiData);
  //     sheets["Index"]["!cols"] = [
  //       { wch: 10 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 20 },
  //       { wch: 20 },
  //     ];
  //     for (let i = 0; i < allRoutes.length; i++) {
  //       const route = allRoutes[i];
  //       try {
  //         const response = await requestRouteById({ routeId: route._id });
  //         if (response.error) {
  //           console.error(`Error for Route ${i + 1}:`, response.error);
  //           continue;
  //         }
  //         if (response) {
  //           const routeDetails = Array.isArray(response)
  //             ? response
  //             : [response];

  //           let routeData = [];

  //           routeDetails.forEach((data) => {
  //             data.particulars.forEach((particular) => {
  //               const term1 =
  //                 particular.term1 === "N/A" ? 0 : Number(particular.term1);
  //               const term2 =
  //                 particular.term2 === "N/A" ? 0 : Number(particular.term2);
  //               const morningTiming = convertTo12HourFormat(
  //                 particular.timing.morning
  //               );
  //               const eveningTiming = convertTo12HourFormat(
  //                 particular.timing.evening
  //               );
  //               let particularsInfo = {
  //                 "Stop No.": particular.stop,
  //                 "Stop Location": particular.location,
  //                 "Morning Timing": morningTiming,
  //                 "Evening Timing": eveningTiming,
  //                 "Term1 Fee": term1,
  //                 "Term2 Fee": term2,
  //                 "Total Amount": term1 + term2,
  //               };
  //               routeData.push(particularsInfo);
  //             });
  //           });

  //           sheets[`Route ${i + 1}`] = XLSX.utils.json_to_sheet(routeData);
  //           sheets[`Route ${i + 1}`]["!cols"] = [
  //             { wch: 10 },
  //             { wch: 20 },
  //             { wch: 15 },
  //             { wch: 15 },
  //             { wch: 15 },
  //             { wch: 15 },
  //             { wch: 15 },
  //           ];
  //         }
  //       } catch (error) {
  //         console.error(`Error for Route ${i + 1}:`, error);
  //       }
  //     }

  //     const wb = {
  //       Sheets: sheets,
  //       SheetNames: [
  //         "Index",
  //         ...Array.from(
  //           { length: allRoutes.length },
  //           (_, i) => `Route ${i + 1}`
  //         ),
  //       ],
  //     };

  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const data = new Blob([excelBuffer], { type: fileType });
  //     FileSaver.saveAs(data, fileName + fileExtension);
  //   };

  //   return (
  //     <div className={Styles.downloadButton}>
  //       <Button
  //         onClick={(e) => {
  //           exportToCSV(apiData, fileName);
  //           routeDownload.onClose();
  //         }}
  //         colorScheme="green"
  //       >
  //         Yes
  //       </Button>
  //     </div>
  //   );
  // };

  return (
    <div className={Styles.subWrapper}>
      <div className={Styles.header}>
        <p className={Styles.title}>Total Routes : {allRoutes?.length}</p>
        <div style={{ display: "flex" }}>
          <Tooltip label="Add Route">
            <div className={Styles.add} onClick={handleAdd}>
              <Button colorScheme="teal" mr={3}>
                Create
              </Button>
            </div>
          </Tooltip>
          {allRoutes?.length >= 1 ? (
            <Tooltip label="Download Route Details">
              <div
                className={Styles.add}
                onClick={() => routeDownload.onOpen()}
              >
                <Button colorScheme="green">Archive</Button>
              </div>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className={Styles.lists}>
        {requestInProgress ? (
          <div className={Styles.loaderWrapper}>
            <Spinner />
          </div>
        ) : allRoutes?.length === 0 ? (
          <div className={Styles.noResult}>No Routes Found</div>
        ) : (
          allRoutes.map((route, idx) => (
            <div key={route["_id"] + idx} className={Styles.list}>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Route No.</p>
                <p className={Styles.listItemVal}>
                  {`${route.route_number}` || "--"}
                </p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Route Name</p>
                <p className={Styles.listItemVal}>{route.route || "--"}</p>
              </div>
              <div className={Styles.listItem}>
                <p className={Styles.listItemText}>Buses No.</p>
                <p className={Styles.listItemVal}>
                  {route.buses && route.buses.length > 0
                    ? route.buses.join(", ")
                    : "-"}
                </p>
              </div>
              <div className={Styles.directionRow}>
                <div className={Styles.directionCol}>
                  <p className={Styles.listItemText}>{route.source || "--"}</p>
                </div>
                <img
                  className={Styles.direction}
                  src={Icons.direction}
                  alt="direction"
                />
                <div className={Styles.directionCol}>
                  <p className={Styles.listItemText}>
                    {route.destination || "--"}
                  </p>
                </div>
              </div>
              <div className={Styles.edit}>
                <img
                  className={Styles.editImage}
                  onClick={() => handleEdit(route._id)}
                  src={Icons.edit}
                  alt="edit"
                />
                <img
                  className={Styles.editImage}
                  onClick={() => routeDel(route)}
                  src={Icons.deleteIcon}
                  alt="delete"
                />
              </div>
            </div>
          ))
        )}
      </div>
      <Modal isOpen={routeDownload.isOpen} onClose={routeDownload.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Would you like to download route details?</ModalBody>
          <ModalFooter>
            <ExportToExcel
              allRoutes={allRoutes}
              apiData={routeDetailsDownload}
              fileName={fileName}
            />
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => routeDownload.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent
          position="fixed"
          top="10"
          transform="translateX(-50%)"
          maxWidth="80vw"
          width="80vw"
        >
          <ModalHeader>{operationType ? operationType : ""} Route</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.note}>
              <p>
                <b>Note:</b> NA/NYD - Transport Fee for this term is Not
                Applicable / Not Yet Decided
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={Styles.formGroupRoute}>
                <label className={Styles.formLabel}>
                  Route Name <span className={Styles.asterisk}>*</span>
                </label>
                <input
                  onChange={handleInputFieldsChange}
                  className={Styles.formInput}
                  type="text"
                  name="routeName"
                  value={inputFields.routeName}
                  placeholder="Enter Route Name"
                />
                <label className={Styles.formLabel}>
                  Route No. <span className={Styles.asterisk}>*</span>
                </label>
                <input
                  onChange={handleInputFieldsChange}
                  className={Styles.formInput}
                  type="text"
                  name="routeNumber"
                  value={inputFields.routeNumber}
                  placeholder="Enter Route No."
                />
                <div className={Styles.busDetailsTime}>
                  {particulars.map((item, i) => (
                    <div key={i} className={Styles.formRow}>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>
                          Stop No. <span className={Styles.asterisk}>*</span>
                        </label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.stopNumber}
                          name="stopNumber"
                          className={Styles.formInput}
                          type="number"
                          placeholder="Enter Stop No."
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>
                          Location <span className={Styles.asterisk}>*</span>
                        </label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.location}
                          name="location"
                          className={Styles.formInput}
                          type="text"
                          placeholder={
                            i === 0 ? "Eg. School Campus" : "Enter Location"
                          }
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>
                          Morning <span className={Styles.asterisk}>*</span>
                        </label>
                        {/* <input
                        onChange={(e) => handleParticularsInputChange(e, i)}
                        value={item.morningTiming}
                        className={Styles.formInput}
                        name="morningTiming"
                        type="time"
                        placeholder="Enter Morning timing"
                      /> */}
                        <TimePicker
                          onChange={(time) =>
                            handleParticularsInputChange(
                              {
                                target: { name: "morningTiming", value: time },
                              },
                              i
                            )
                          }
                          value={
                            item.morningTiming == "N/A"
                              ? ""
                              : item.morningTiming
                          }
                          disableClock
                          clearIcon={null}
                          className={Styles.customTimepickerInput}
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>
                          Evening <span className={Styles.asterisk}>*</span>
                        </label>
                        {/* <input
                        onChange={(e) => handleParticularsInputChange(e, i)}
                        value={item.eveningTiming}
                        className={Styles.formInput}
                        name="eveningTiming"
                        type="time"
                        placeholder="Enter Evening timing"
                      /> */}
                        <TimePicker
                          onChange={(time) =>
                            handleParticularsInputChange(
                              {
                                target: { name: "eveningTiming", value: time },
                              },
                              i
                            )
                          }
                          value={
                            item.eveningTiming == "N/A"
                              ? ""
                              : item.eveningTiming
                          }
                          disableClock
                          clearIcon={null}
                          className={Styles.customTimepickerInput}
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Term 1 Fee</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.termOneFee}
                          className={Styles.formInput}
                          name="termOneFee"
                          type="number"
                          // placeholder="Enter Term One Fee"
                          placeholder="0"
                          timeFormat="h:mm A"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Term 2 Fee</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.termTwoFee}
                          name="termTwoFee"
                          type="number"
                          className={Styles.formInput}
                          // placeholder="Enter Term Two Fee"
                          placeholder="0"
                        />
                      </div>
                      <div
                        onClick={() => removeParticular(i)}
                        className={Styles.delete}
                      >
                        <img
                          className={Styles.deleteImage}
                          src={Icons.deleteIcon}
                          alt="delete"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className={Styles.buttonRow}>
                  <button
                    type="button"
                    onClick={addParticular}
                    className={Styles.formButton}
                  >
                    Add Stop
                  </button>
                  <button type="submit" className={Styles.formButton}>
                    {requestInProgress && operationType !== "" ? (
                      <Spinner />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={routeDelete.isOpen} onClose={routeDelete.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Route Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete the route?</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => deleteRoute(delRouteId._id)}
            >
              Yes
            </Button>
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => routeDelete.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

const TranspostEnrolledStudents = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchFilterInputFields, setSearchFilterInputFields] = useState(
    SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE
  );
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [operationType, setOperationType] = useState("");
  const [busList, setBusList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [enrolledStudentList, setEnrolledStudentList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [showRouteBusFields, setShowRouteBusFields] = useState(false);
  const isClassQuery =
    searchFilterInputFields.class !== "" &&
    searchFilterInputFields.class !== "all";
  const isSectionQuery =
    searchFilterInputFields.section !== "" &&
    searchFilterInputFields.section !== "all";
  const enrolledBusStudents = useDisclosure();
  const otherOptions = useDisclosure();
  const [enrollStudentdetailsDownload, setEnrollStudentDetailsDownload] =
    useState();
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const initialFieldsState = {
    studentName: true,
    class: true,
    section: true,
    busNumber: true,
    routeNumber: true,
    amount: true,
    paid: true,
    stop: true,
    phoneNumber: false,
    term1: true,
    term2: true,
    morning: true,
    evening: true,
    duration: false,
    trialPeriod: false,
    date: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [inputFields, setInputFields] = useState({});
  const [data, setData] = useState([]);
  const toast = useToast();
  useEffect(() => {
    requestDetails();
  }, []);
  const handleSwitchChange = () => {
    setShowRouteBusFields(!showRouteBusFields);
    otherOptions.onClose();
  };
  useEffect(() => {
    let requiredSections = classAndSections?.length
      ? classAndSections
          .filter((item) => item.class == searchFilterInputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [searchFilterInputFields.class, classAndSections]);

  useEffect(() => {
    if (
      isClassQuery ||
      isSectionQuery ||
      searchFilterInputFields.bus !== "" ||
      searchFilterInputFields.route !== "" ||
      searchFilterInputFields.studentName !== ""
    ) {
      _getEnrolledStudentsBySearch();
      localStorage.setItem("seletedClass", searchFilterInputFields.class);
      localStorage.setItem("seletedSection", searchFilterInputFields.section);
    }
  }, [
    isOpen,
    searchFilterInputFields.class,
    searchFilterInputFields.bus,
    searchFilterInputFields.route,
    // searchFilterInputFields.studentName,
    searchFilterInputFields.section,
  ]);

  const resetSearchFilterInputFields = () => {
    setSearchFilterInputFields(SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE);
  };

  const requestDetails = () => {
    setRequestInProgress(true);
    Promise.all([
      fetchAllBuses(),
      fetchAllRoutes(),
      _getEnrolledStudentsBySearch(),
    ])
      .then((resp) => {
        const { 0: buses, 1: routes, 2: enrolledStudents } = resp || [];
        if (buses && routes && enrolledStudents) {
          const __enrolledStudents = enrolledStudents.map((stud) => {
            const route = routes.find((i) => i._id === stud.router_id) || {};
            const bus =
              buses.find((i) => {
                let busNumber =
                  !!stud.bus_number &&
                  typeof stud.bus_number === "string" &&
                  stud.bus_number;
                if (i.bus_number === busNumber) {
                  return true;
                }
                return false;
              }) || {};
            const { source, destination } = route;
            const { bus_plate_number } = bus;
            return {
              ...stud,
              ...(source ? { source } : {}),
              ...(destination ? { destination } : {}),
              ...(bus_plate_number ? { bus_plate_number } : {}),
            };
          });
          setEnrolledStudentList(__enrolledStudents);
        }
        setRequestInProgress(false);
      })
      .catch((e) => {
        console.error(e);
        setRequestInProgress(false);
      });
  };

  const fetchAllBuses = async () => {
    try {
      const response = await requestAllBuses();
      if (response.error) {
        console.error(response.error);
      }
      if (response && Array.isArray(response)) {
        // response.unshift({ _id: "", bus_number: "All" });
        setBusList(response);
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAllRoutes = async () => {
    try {
      const response = await requestAllRoutes();
      if (response.error) {
        console.error(response.error);
      }
      if (response && Array.isArray(response)) {
        setRouteList(response);
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  };

  const _getEnrolledStudentsBySearch = debounce(function () {
    getEnrolledStudentsBySearch();
  }, 500);

  const getEnrolledStudentsBySearch = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(searchFilterInputFields.studentName !== ""
          ? { name: searchFilterInputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: searchFilterInputFields.class.toUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: searchFilterInputFields.section.toUpperCase() }
          : {}),
        ...(searchFilterInputFields.route !== ""
          ? { route_number: searchFilterInputFields.route }
          : {}),
        ...(searchFilterInputFields.bus !== ""
          ? { bus_number: searchFilterInputFields.bus }
          : {}),
      };
      const response = await requestSearchEnrolledStudents(payload);
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      if (saveDefault) {
        setEnrolledStudentList(_result);
        let newArray1 = [];
        _result &&
          _result.map((data) => {
            let obj1 = {
              Name: data.name,
              Grade: data.class,
              Section: data.section,
              Duration: data.duration,
              Amount: data.amount,
            };
            newArray1.push(obj1);
            setEnrollStudentDetailsDownload(newArray1);
          });
      } else {
        setSearchResultList(_result);
        let newArray1 = [];
        _result &&
          _result.map((data) => {
            let obj1 = {
              Name: data.name,
              Grade: data.class,
              Section: data.section,
              Duration: data.duration,
              Amount: data.amount,
            };
            newArray1.push(obj1);
            setEnrollStudentDetailsDownload(newArray1);
          });
      }

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const getEnrolledStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: searchFilterInputFields.class.toUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: searchFilterInputFields.section.toUpperCase() }
          : {}),
        ...(searchFilterInputFields.route !== ""
          ? { route_number: searchFilterInputFields.route }
          : {}),
        ...(searchFilterInputFields.bus !== ""
          ? { bus_number: searchFilterInputFields.bus }
          : {}),
      };
      const response = await requestSearchEnrolledStudents(payload);
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      if (saveDefault) {
        setEnrolledStudentList(_result);
        let newArray1 = [];
        _result &&
          _result.map((data) => {
            let obj1 = {
              Name: data.name,
              Grade: data.class,
              Section: data.section,
              Duration: data.duration,
              Amount: data.amount,
            };
            newArray1.push(obj1);
            setEnrollStudentDetailsDownload(newArray1);
          });
      } else {
        setSearchResultList(_result);
        let newArray1 = [];
        _result &&
          _result.map((data) => {
            let obj1 = {
              Name: data.name,
              Grade: data.class,
              Section: data.section,
              Duration: data.duration,
              Amount: data.amount,
            };
            newArray1.push(obj1);
            setEnrollStudentDetailsDownload(newArray1);
          });
      }

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleSearchClick = () => {
    if (searchFilterInputFields.studentName !== "") {
      getEnrolledStudentsBySearch();
      setShowSearchIcon(false);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleCloseIconClick = () => {
    setSearchFilterInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    getEnrolledStudentsWithoutName();
    setShowSearchIcon(true);
  };

  const handleSearchFilterInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setSearchFilterInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setSearchFilterInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
        setShowSearchIcon(true);
      }
    } else {
      setSearchFilterInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  if (loading || (requestInProgress && operationType === "")) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  const isQueried =
    isClassQuery ||
    isSectionQuery ||
    (searchFilterInputFields.bus !== "" &&
      searchFilterInputFields.bus?.length > 0) ||
    (searchFilterInputFields.route !== "" &&
      searchFilterInputFields.route?.length > 0) ||
    (searchFilterInputFields.studentName !== "" &&
      searchFilterInputFields.studentName?.length > 0);
  const _enrolledStudentList = isQueried
    ? searchResultList
    : enrolledStudentList;


  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const fileName = `SMU-Enrolled-Students-Report-${searchFilterInputFields.class.toUpperCase()}${searchFilterInputFields.section.toUpperCase()}`;
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Archive
      </Button>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    if (!searchFilterInputFields.class.toUpperCase() || !searchFilterInputFields.section.toUpperCase()) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      ...(searchFilterInputFields.studentName.toUpperCase() !== ""
        ? { name: searchFilterInputFields.studentName.toUpperCase() }
        : {}),
      class: searchFilterInputFields.class.toUpperCase(),
      section: searchFilterInputFields.section.toUpperCase(),
    };
    const res = await requestEnrolledStudentsDetails(payload);
    if (res) {
      let newArray = [];
      res &&
        res.map((dataValue) => {
          let obj = {};
          if (fields.studentName) {
            obj["Name"] = handleNAValue(dataValue.name);
          }
          if (fields.class) {
            obj["Class"] = handleNAValue(dataValue.class);
          }
          if (fields.section) {
            obj["Section"] = handleNAValue(dataValue.section);
          }
          if (fields.busNumber) {
            obj["Bus Number"] = handleNAValue(dataValue.bus_number);
          }
          if (fields.routeNumber) {
            obj["Route Number"] = handleNAValue(dataValue.route_number);
          }
          if (fields.stop) {
            obj["Stop"] = handleNAValue(dataValue.stop);
          }
          if (fields.amount) {
            obj["Amount"] = handleNAValue(dataValue.amount);
          }
          if (fields.paid) {
            obj["Fees Status"] = handleNAValue(
              dataValue.paid == false ? "Unpaid" : "Paid"
            );
          }
          if (fields.phoneNumber) {
            obj["Phone Number"] = handleNAValue(
              dataValue.phone_code + dataValue.phoneNumber
            );
          }
          if (fields.term1) {
            obj["Term 1 Fee"] = handleNAValue(dataValue.term1);
          }
          if (fields.term2) {
            obj["Term 2 Fee"] = handleNAValue(dataValue.term2);
          }
          if (fields.morning) {
            obj["Morning Timing"] = handleNAValue(dataValue.morning);
          }
          if (fields.evening) {
            obj["Evening Timing"] = handleNAValue(dataValue.evening);
          }
          if (fields.duration) {
            obj["Duration(in months)"] = handleNAValue(dataValue.duration);
          }
          if (fields.trialPeriod) {
            obj["Trial Period"] = handleNAValue(
              dataValue.is_trial == false ? "No" : "True"
            );
          }
          if (fields.date) {
            obj["Date of Joining"] = handleNAValue(
              moment(dataValue.date).format("DD/MM/YYYY")
            );
          }
          newArray.push(obj);
        });
      setData(newArray);
      if (newArray.length === 0) {
        toast({
          title: "No students found.",
          variant: "left-accent",
          position: "bottom-left",
          status: "warning",
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div className={Styles.subWrapper}>
      <div className={Styles.header}>
        <p className={Styles.title}>Enrolled Students</p>
        <div className={Styles.headerOption}>
          {!showRouteBusFields && (
            <>
              <div className={Styles.filterWrapper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <Input
                      variant="filled"
                      placeholder="Search"
                      value={searchFilterInputFields.studentName}
                      onChange={handleSearchFilterInputChange}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      name="studentName"
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  onChange={handleSearchFilterInputChange}
                  name="class"
                  value={searchFilterInputFields.class}
                  placeholder="Select Grade"
                >
                  {classAndSections.map((grade, idx) => (
                    <option key={grade.class + idx} value={grade.class}>
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  onChange={handleSearchFilterInputChange}
                  name="section"
                  value={searchFilterInputFields.section}
                  placeholder="Select Section"
                >
                  {sections
                    ? sections.map((section, idx) => (
                        <option key={section + idx} value={section}>
                          Section {section}
                        </option>
                      ))
                    : ""}
                </Select>
              </div>
            </>
          )}
          {showRouteBusFields && (
            <>
              <div className={Styles.filterWrapper}>
                <InputSelect
                  onChange={handleSearchFilterInputChange}
                  name="bus"
                  value={searchFilterInputFields.bus}
                  placeholder="Select Bus"
                  options={busList}
                  labelKey="bus_number"
                  valueKey="bus_number"
                />
              </div>
              <div className={Styles.filterWrapper}>
                <InputSelect
                  onChange={handleSearchFilterInputChange}
                  name="route"
                  value={searchFilterInputFields.route}
                  placeholder="Select Route"
                  options={routeList}
                  labelKey="route"
                  valueKey="route_number"
                />
              </div>
            </>
          )}
          <Button
            colorScheme="green"
            mr={2}
            onClick={() => otherOptions.onOpen()}
            className={Styles.filterButton}
          >
            Filter Options
          </Button>
          {_enrolledStudentList?.length >= 1 ? (
            <Button
              colorScheme="blue"
              mt={1}
              onClick={() => enrolledBusStudents.onOpen()}
            >
              Archive
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={Styles.enrolledLists}>
        {requestInProgress && operationType === "" ? (
          <div className={Styles.loaderWrapper}>
            <Spinner />
          </div>
        ) : !requestInProgress && _enrolledStudentList?.length === 0 ? (
          <div className={Styles.noResult}>No Enrolled Student Found</div>
        ) : (
          _enrolledStudentList.map((student, idx) => (
            <div className={Styles.enrolledList}>
              <Link to={"enroll/one/" + student._id} key={student["_id"] + idx}>
                <div style={{ display: "flex" }}>
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={
                        student.profile_url != "N/A"
                          ? student.profile_url
                          : defaultUser
                      }
                      alt="student-profile"
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>
                      {truncateText(student.name || "--", 20)}
                    </p>
                    <p className={Styles.class}>
                      {student?.class || "--"} |{" "}
                      {student.section?.toUpperCase() || "--"}
                    </p>
                    <p className={Styles.number}>
                      +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                    </p>
                  </div>
                </div>
              </Link>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <p className={Styles.checkboxText}>Paid</p>

                  {student.paid === true ? (
                    <BsCheckSquare
                      disabled
                      style={{ "margin-left": "6px", color: "green" }}
                    />
                  ) : (
                    <FcCancel style={{ "margin-left": "6px" }} />
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className={Styles.checkboxText}>Trial Period</p>
                  {student.is_trial === true ? (
                    <BsCheckSquare
                      disabled
                      style={{ "margin-left": "6px", color: "green" }}
                    />
                  ) : (
                    <FcCancel style={{ "margin-left": "6px" }} />
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal
        isOpen={enrolledBusStudents.isOpen}
        onClose={enrolledBusStudents.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Please check the fields that you want to include in Downloading File </ModalHeader>
          <ModalCloseButton />
          <div className={Styles.mobileselectionContainer}>
            <form>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Other Details
                  </button>
                </div>
              </div>
              <>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.routeNumber}
                    onChange={() => {
                      setFields({
                        ...fields,
                        routeNumber: !fields.routeNumber,
                      });
                    }}
                  >
                    Route Number
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term1}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term1: !fields.term1,
                      });
                    }}
                  >
                    Term 1 Fee
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.term2}
                    onChange={() => {
                      setFields({
                        ...fields,
                        term2: !fields.term2,
                      });
                    }}
                  >
                    Term 2 Fee
                  </Checkbox>
                </div>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.morning}
                    onChange={() => {
                      setFields({
                        ...fields,
                        morning: !fields.morning,
                      });
                    }}
                  >
                    Morning Timing
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.evening}
                    onChange={() => {
                      setFields({
                        ...fields,
                        evening: !fields.evening,
                      });
                    }}
                  >
                    Evening Timing
                  </Checkbox>
                </div>
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.duration}
                    onChange={() => {
                      setFields({
                        ...fields,
                        duration: !fields.duration,
                      });
                    }}
                  >
                    Duration
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.trialPeriod}
                    onChange={() => {
                      setFields({
                        ...fields,
                        trialPeriod: !fields.trialPeriod,
                      });
                    }}
                  >
                    Trial Period
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.date}
                    onChange={() => {
                      setFields({
                        ...fields,
                        date: !fields.date,
                      });
                    }}
                  >
                    Date of Joining
                  </Checkbox>
                </div>
              </>
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
            File Format
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
           
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom:"15px", marginRight:"10px" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
        </ModalContent>
      </Modal>
      <Modal isOpen={otherOptions.isOpen} onClose={otherOptions.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <p>
                Would you like to view the options for selecting a bus and a
                route?{" "}
                <Switch
                  isChecked={showRouteBusFields}
                  ml={2}
                  onChange={handleSwitchChange}
                />
              </p>
            </div>
            <p style={{ marginTop: "5px" }}>
              <span style={{ fontWeight: "bold", color: "red" }}>Note :</span>{" "}
              It will temporarily remove the search, select grade, and select
              sections fields.
            </p>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Transport;

import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(!!localStorage.getItem("token"));
  const [isSearch, setIsSearch] = useState("");
  return (
    <AuthContext.Provider value={{ user, setUser, isSearch, setIsSearch }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };

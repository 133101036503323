import React, { useState } from "react";
import Styles from "./Help.module.scss";
import ExamReport from "../../../assets/helpModule/ExamReport.png";
import ExamreportPreview from "../../../assets/helpModule/ExamreportPreview.png";
import BackupExam from "../../../assets/helpModule/BackupExam.png";
import ExistBackup from "../../../assets/helpModule/ExistBackup.png";
import PreviewBackup from "../../../assets/helpModule/PreviewBackup.png";

const ExamReportBackup = () => {
  const [selectedStep, setSelectedStep] = useState(1);

  const handleStepClick = (step) => {
    setSelectedStep(step);
  };
  return (
    <div className={Styles.helpmenu}>
      <div className={Styles.wrap}>
        <div className={Styles.forWidthLeft}>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 1 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(1)}
          >
            <div className={Styles.stepNo}>1</div>
            <div className={Styles.stepTitle}>
              Select Grade, Section, and Semester
            </div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 2 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(2)}
          >
            <div className={Styles.stepNo}>2</div>
            <div className={Styles.stepTitle}>
              {/* Click on Backup button */}
              Select Grade, Section, and Semester
            </div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 3 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(3)}
          >
            <div className={Styles.stepNo}>3</div>
            <div className={Styles.stepTitle}>
              Steps to follow when data Doesn't Exist for Backup
            </div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 4 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(4)}
          >
            <div className={Styles.stepNo}>4</div>
            <div className={Styles.stepTitle}>Creating grade for backup</div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 5 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(5)}
          >
            <div className={Styles.stepNo}>5</div>
            <div className={Styles.stepTitle}>
              Preview and download Report Card after backup
            </div>
          </div>
        </div>
        {selectedStep === 1 && (
          <div className={Styles.forWidthRight}>
            <img src={ExamReport} alt="ExamReport" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                In the Exam Report module, select the grade, section, and
                semester from the drop-down options shown in the image.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Then click on Preview All(Preview selected) button.
              </p>
            </div>
          </div>
        )}
        {selectedStep === 2 && (
          <div className={Styles.forWidthRight}>
            <img src={ExamreportPreview} alt="ExamreportPreview" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}> Now click on BACKUP button.</p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                A new popup will be shown select academic year and click on
                submit.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                If the selected grade and section doesn't exist for backup, then
                a popup will be shown saying - Exam Backup Document Not Exists.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Perform steps explained in step-3.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                If the selected class and section exists for backup, then a
                popup saying Exam Backup Document Exists will be shown.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now wait and stay on this page till the Report Card uploads the
                data into Digital Ocean's Server. After successfully uploading
                the Report Card it gives another popup saying Backup Complete.
              </p>
            </div>
          </div>
        )}

        {selectedStep === 3 && (
          <div className={Styles.forWidthRight}>
            <img src={BackupExam} alt="BackupExam" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                If the selected class and section does not exist for backup,
                then go to the BACKUP Module.
              </p>
            </div>

            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                In Backup Module, select the EXAM Panel. After selecting the
                Exam panel, you have to click on the Plus icon.
              </p>
            </div>
          </div>
        )}

        {selectedStep === 4 && (
          <div className={Styles.forWidthRight}>
            <img src={ExistBackup} alt="ExistBackup" />
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Click on the Plus icon to see the popup.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                In this popup select the grade, section, and the backup year
                from the dropdowns.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Then press on Submit button. Now the selected class and grade is
                exists for backup.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now to complete the backup process or uploading Report Card to
                the digital ocean servers repeat the step-1 and step-2.
              </p>
            </div>
          </div>
        )}
        {selectedStep === 5 && (
          <div className={Styles.forWidthRight}>
            <img src={PreviewBackup} alt="PreviewBackup" />

            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                In the Backup Module, select the Academic year, grade and
                section.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                Now the student list can be viewed with show Preview and
                download button.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                To download the reports, click on the download button, and it
                will downlad the report card of that particular student.
              </p>
            </div>
            <div className={Styles.des}>
              <p className={Styles.dot}></p>
              <p className={Styles.forMargin}>
                If download and preview button is not shown, it means that the
                grade exists but backup is not done yet.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamReportBackup;

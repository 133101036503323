// import axios from 'axios'
// import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

// export async function NewrequestUpdateAttendance (payload = {}) {

//   const url = BASE_URL + "/teacher/class/attendance/update"
//   try {
//         const response = await axios.post(url,{...payload},getRequestOptions())
//         if (response.data?.error) {
//           return { error: response.data.error }
//         }
//         if (response.status >= 200 && response.status < 400 && response.data) {
//           return response.data
//         }
//       } catch (e) {
//         if (e?.response?.data?.error) {
//           throw e.response.data.error
//         }
//         if (e?.response?.data) {
//           throw e.response.data
//         }
//         throw e
//       }
// }

// export async function NewrequestClassroomAttendence (payload ={}){
//   const {class_id,date} = payload
//   if (isInvalid([class_id, date])) {
//     return { error: 'Field missing' }
//   }
//   const url = BASE_URL + '/teacher/class/attendance/get'
//   try {
//     const response = await axios.post(url,{...payload},getRequestOptions())
//     if (response.data.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       console.log('response ',response)
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }

//     throw e
//   }
// }

// export async function newAddAttendence (payload={}){
//   const url = BASE_URL + '/teacher/class/attendance/add'
//   try {
//     const response = await axios.post(url,{...payload},getRequestOptions())
//      if (response.data?.error) {
//       console.log("response.data.error",response.data.error)
//       return { error: response.data.error }

//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   }
//   catch(e){
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }
//     throw e
//   }
// }

// export async function requestClassroomAttendence (payload = {}) {
//   const { class: _class, section } = payload

//   if (isInvalid([_class, section])) {
//     return { error: 'Field missing' }
//   }

//   const url = BASE_URL + '/teacher/class/get-attendence'

//   try {
//     const response = await axios.post(url, { ...payload }, getRequestOptions())
//     if (response.data.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }

//     throw e
//   }
// }

// export async function requestStudentAttendence (payload = {}) {
//   const { class_id, student_id } = payload

//   if (isInvalid([class_id, student_id])) {
//     return { error: 'Field missing' }
//   }

//   const url = BASE_URL + '/teacher/class/attendence/student'

//   try {
//     const response = await axios.post(url, { ...payload }, getRequestOptions())
//     if (response.data.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }

//     throw e
//   }
// }

// export async function requestSearchAttendence (payload = {}) {
//   const url = BASE_URL + '/teacher/class/get-attendence'

//   try {
//     const response = await axios.post(url, { ...payload },getRequestOptions())
//     if (response.data?.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }
//     throw e
//   }
// }

// export async function requestAddAttendence (payload = {}) {
//   const { class_id, teacher_id, presentStudents, class: _class, stream, section, teacher_name, date } = payload

//   if (isInvalid([class_id, teacher_id, presentStudents, _class, stream, section, teacher_name, date])) {
//     return { error: 'Field missing' }
//   }

//   const url = BASE_URL + '/teacher/class/attendence'
//   const requestPayload = {
//     attendence: {
//       class_id,
//       faculty: teacher_id,
//       presentStudents,
//       class: _class,
//       stream,
//       section,
//       facultyName: teacher_name,
//       date
//     }
//   }
//   try {
//     const response = await axios.post(url, { ...requestPayload },getRequestOptions())
//     if (response.data?.error) {
//       return { error: response.data.error }
//     }
//     if (response.status >= 200 && response.status < 400 && response.data) {
//       return response.data
//     }
//   } catch (e) {
//     if (e?.response?.data?.error) {
//       throw e.response.data.error
//     }
//     if (e?.response?.data) {
//       throw e.response.data
//     }
//     throw e
//   }
// }

// const Services = {
//   requestClassroomAttendence,
//   requestStudentAttendence,
//   requestSearchAttendence,
//   NewrequestClassroomAttendence,
//   NewrequestUpdateAttendance,
//   newAddAttendence
// }

// export default Servicesimport axios from "axios";
import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function requestAddAttendance(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/add";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestGetAttendance(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/get";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestGetClassStats(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/stat";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUpdateAttendance(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/update";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestDeleteAttendance(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/delete";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestAttendanceClass(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/class/days";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestHolidays(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/holidays";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestGetClass(payload = {}) {
  const url = BASE_URL + "/teacher/class/head-classes";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      // console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestStatsOne(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/statistics/getOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUpdateOne(payload = {}) {
  const url = BASE_URL + "/teacher/attendance/statistics/updateOne";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      console.log("response ", response);
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function requestSearchStudent(payload = {}) {
  const url = BASE_URL + "/student/studentRoute/search-student";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestStudentDetailsById() {
  const studentId = localStorage.getItem("studentId") || "";
  const url = BASE_URL + "/student/profile/getStudent/" + studentId;

  try {
    const response = await axios.post(url, {}, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

const Services = {
  requestSearchStudent,
  requestStudentDetailsById,
};

export default Services;

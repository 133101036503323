import { Select } from '@chakra-ui/react';
import React from 'react'

import MarksEntryClassTeacher from './Category/ClassTeacher/MarksEntryClassTeacher';
import MarksEntryDiscipline from './Category/ClassTeacher/MarksEntryDiscipline';
import MarksEntrySubjectTeacher from './Category/SubjectTeacher/MarksEntrySubjectTeacher';
import MarksEntrySubjectTeacherSem from './Category/SubjectTeacher/MarksEntrySubjectTeacherSem';
//import MarksEntryClassTeacher from './Category/ClassTeacher/MarksEntryClassTeacher';
// import MarksEntrySubjectTeacher from './Category/SubjectTeacher/MarksEntrySubjectTeacher';
// import MarksEntrySubjectTeacherSem from './Category/SubjectTeacher/MarksEntrySubjectTeacherSem';
import Styles from './MarksEntrySheets.module.scss';

const MarksEntrySheetsAnnual = () => {


  return (
    <div className={Styles.body}>
      
        <MarksEntrySubjectTeacher />

    </div>
  )
}

export default MarksEntrySheetsAnnual
import React, { useState, useRef, useEffect } from "react";
import Styles from "./StudentAttendance.module.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import moment from "moment";
import {
  requestHolidays,
  requestStatsOne,
} from "services/student-dashboard/attendence.service";
import { Button, Tooltip } from "@chakra-ui/react";

const StudentAttendance = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isAbsentOpen, setIsAbsentOpen] = useState(false);
  const [isHalfDayOpen, setIsHalfDayOpen] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [oneStatus, setOneStatus] = useState([]);
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const calendarRef = useRef(null);
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedMorningPresent = oneStatus.morning_present_array
      ? oneStatus.morning_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAfternoonPresent = oneStatus.afternoon_present_array
      ? oneStatus.afternoon_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedPresent = oneStatus.present_array
      ? oneStatus.present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAbsent = oneStatus.absent_array
      ? oneStatus.absent_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (formattedMorningPresent.includes(date)) {
          dayEl.style.backgroundColor = "orange";
          dayEl.style.color = "white";
        } else if (formattedAfternoonPresent.includes(date)) {
          dayEl.style.backgroundColor = "#c625df8f";
          dayEl.style.color = "white";
        } else if (formattedPresent.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedAbsent.includes(date)) {
          dayEl.style.backgroundColor = "red";
          dayEl.style.color = "white";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi?.batchRendering(() => {
      highlightDates();
      calendarApi?.render();
    });
  }, [selectedMonth, holidays, oneStatus]);
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const getOneStudentStat = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const res = await requestStatsOne(payload);
    setOneStatus(res);
  };
  useEffect(() => {
    if (selectedYear && selectedMonth !== undefined) {
      handleHolidays();
      getOneStudentStat();
      setHolidays(null);
    }
  }, [selectedMonth, selectedYear]);
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
              <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                <div class="${Styles.date}">${date.getDate()}</div>
              </div>
            `,
    };
  };
  function renderAbsentDays(absentDays) {
    const sortedAbsentDays = absentDays?.sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const groupedByMonth = groupAbsentDaysByMonth(sortedAbsentDays);
    return Object.keys(groupedByMonth).map((month) => {
      const monthName = new Date(month).toLocaleDateString("default", {
        month: "long",
      });
      const dates = groupedByMonth[month];
      const dateText = dates.join(", ");
      return (
        <div key={month}>
          <p style={{ fontWeight: "bold" }}>
            Month : <span style={{ fontWeight: 500 }}>{monthName}</span>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Dates :{" "}
            <span style={{ fontWeight: 500, color: "red" }}>{dateText}</span>
          </p>
          <hr
            style={{
              border: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
        </div>
      );
    });
  }
  function groupAbsentDaysByMonth(absentDays) {
    const grouped = {};
    for (const date of absentDays) {
      const month = new Date(date).toISOString().split("T")[0].substring(0, 7);
      if (grouped[month]) {
        grouped[month].push(new Date(date).getDate());
      } else {
        grouped[month] = [new Date(date).getDate()];
      }
    }
    return grouped;
  }
  function renderHalfDayPresentDays(
    morningPresentDates,
    afternoonPresentDates
  ) {
    const allHalfDayPresentDates = morningPresentDates.concat(
      afternoonPresentDates
    );
    const sortedHalfDayPresentDates = allHalfDayPresentDates?.sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const groupedByMonth = groupHalfDayPresentDatesByMonth(
      sortedHalfDayPresentDates
    );
    return Object.keys(groupedByMonth).map((month) => {
      const monthName = new Date(month).toLocaleDateString("default", {
        month: "long",
      });
      const dates = groupedByMonth[month];
      const dateText = dates.join(", ");
      return (
        <div key={month}>
          <p style={{ fontWeight: "bold" }}>
            Month : <span style={{ fontWeight: 500 }}>{monthName}</span>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Dates :{" "}
            <span style={{ fontWeight: 500, color: "red" }}>{dateText}</span>
          </p>
          <hr
            style={{
              border: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
        </div>
      );
    });
  }
  function groupHalfDayPresentDatesByMonth(halfDayPresentDates) {
    const grouped = {};
    for (const date of halfDayPresentDates) {
      const month = new Date(date).toISOString().split("T")[0].substring(0, 7);
      if (grouped[month]) {
        grouped[month].push(new Date(date).getDate());
      } else {
        grouped[month] = [new Date(date).getDate()];
      }
    }
    return grouped;
  }

  return (
    <div>
      <div className={Styles.updateHeadingContainer}>
        <div className={Styles.percentageContainer}>
          <p
            className={`${Styles.percentageSubContainer} ${Styles.forMarginBottom}`}
          >
            <span className={Styles.percentageText}>
              Attendance Percentage :&nbsp;
            </span>
            <Tooltip
              placement="right"
              label={`Total Present : ${oneStatus.attendance_final_present}, Total Absent : ${oneStatus.attendance_final_absent}`}
            >
              <span
                style={{
                  backgroundColor:
                    oneStatus.attendance_percentage > 85
                      ? "green"
                      : oneStatus.attendance_percentage >= 75 &&
                        oneStatus.attendance_percentage <= 84
                      ? "orange"
                      : "red",
                }}
                className={Styles.percentDigit}
              >
                {oneStatus.attendance_percentage !== undefined &&
                oneStatus.attendance_percentage !== null
                  ? oneStatus.attendance_percentage % 1 !== 0
                    ? oneStatus.attendance_percentage.toFixed(2)
                    : oneStatus.attendance_percentage.toFixed(0)
                  : ""}
                %
              </span>
            </Tooltip>
          </p>
          <div
            onMouseEnter={() => setIsAbsentOpen(true)}
            onMouseLeave={() => setIsAbsentOpen(false)}
          >
            <Button colorScheme="red" className={Styles.forMarginBottom}>
              Absent Days
            </Button>
            {isAbsentOpen ? (
              <div className={Styles.absentHoverContainer}>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  onClick={() => setIsAbsentOpen(false)}
                >
                  <IoMdCloseCircleOutline fontSize={20} />
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Academic Absent Dates
                </p>
                {renderAbsentDays(oneStatus.total_absent_array)}
              </div>
            ) : null}
          </div>
          <div
            onMouseEnter={() => setIsHalfDayOpen(true)}
            onMouseLeave={() => setIsHalfDayOpen(false)}
          >
            <Button colorScheme="blue" className={Styles.forMarginBottom}>
              Half Day Present
            </Button>
            {isHalfDayOpen ? (
              <div className={Styles.halfDayHoverContainer}>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  onClick={() => setIsHalfDayOpen(false)}
                >
                  <IoMdCloseCircleOutline fontSize={20} />
                </div>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Half Day Present Dates
                </p>
                {renderHalfDayPresentDays(
                  oneStatus.total_morning_present_array,
                  oneStatus.total_afternoon_present_array
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={Styles.calendarMain}>
        <div className={Styles.leftContainer}>
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              padding: "8px",
              fontWeight: "bold",
              marginRight: "20px",
              fontSize: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p style={{ width: "50%", fontWeight: "bold" }}>
                  Academic Session
                </p>
                <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                <p style={{ width: "40%" }}>
                  {moment(oneStatus.start_date).format("DD/MM/YYYY")}-
                  {moment(oneStatus.end_date).format("DD/MM/YYYY")}
                </p>
                {/* <p style={{ fontWeight: "bold" }}>Academic Session :</p>
                <p style={{ fontWeight: "bold" }}>Mid-Term Date :</p>
                <p style={{ fontWeight: "bold" }}>
                  Total Number of Days in the Session :
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Total Number of Midterm Days in the Session :
                </p> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p style={{ width: "50%", fontWeight: "bold" }}>
                  Mid-Term Date
                </p>
                <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                <p style={{ width: "40%" }}>
                  {moment(oneStatus.mid_term_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p style={{ width: "50%", fontWeight: "bold" }}>
                  Total Number of Days in the Session
                </p>
                <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                <p style={{ width: "40%" }}>{oneStatus.total_days}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p style={{ width: "50%", fontWeight: "bold" }}>
                  Total Number of Midterm Days in the Session
                </p>
                <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                <p style={{ width: "40%" }}>{oneStatus.mid_term_days}</p>
              </div>
            </div>
          </div>
          <Tooltip label="Academic Attendance Summary" placement="top">
            <div className={Styles.attendanceData}>
              <p>Total Added Days : {oneStatus.total_count}</p>
              <p>
                Present :
                <span style={{ color: "green" }}>
                  &nbsp;{oneStatus.total_present_count}
                </span>
              </p>
              <p>
                Absent :
                <span style={{ color: "red" }}>
                  &nbsp;{oneStatus.total_absent_count}
                </span>
              </p>
              <p>Half Day Present : {oneStatus.total_internal_count}</p>
            </div>
          </Tooltip>
          <Tooltip label="Monthly Attendance Summary" placement="bottom">
            <div className={Styles.attendanceData}>
              <p>
                Monthly Added Days :{" "}
                <span>
                  {oneStatus.absent_array?.length +
                    oneStatus.present_array?.length +
                    oneStatus.morning_present_array?.length +
                    oneStatus.afternoon_present_array?.length}
                </span>
              </p>
              <p>
                Present :{" "}
                <span style={{ color: "green" }}>
                  {oneStatus.present_array?.length}
                </span>
              </p>
              <p>
                Absent :{" "}
                <span style={{ color: "red" }}>
                  {oneStatus.absent_array?.length}
                </span>
              </p>
              <p>
                Half Day Present :{" "}
                {oneStatus.morning_present_array?.length +
                  oneStatus.afternoon_present_array?.length}
              </p>
            </div>
          </Tooltip>
          <div
            style={{
              display: " flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              marginTop: "15px",
              padding: "10px",
              fontWeight: "bold",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "orange",
                  marginRight: "5px",
                }}
              ></div>
              <p>Morning Present</p>
            </div>
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#c625df8f",
                  marginRight: "5px",
                }}
              ></div>
              <p>Afternoon Present</p>
            </div>
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "rgb(51 190 51)",
                  marginRight: "10px",
                }}
              ></div>
              <p>Present</p>
            </div>
          </div>
          <div
            style={{
              display: " flex",
              justifyContent: "space-between",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              marginTop: "15px",
              padding: "10px",
              fontWeight: "bold",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "red",
                  marginRight: "5px",
                }}
              ></div>
              <p>Absent</p>
            </div>

            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#9c9695",
                  marginRight: "10px",
                }}
              ></div>
              <p>Holiday / Sunday </p>
            </div>
          </div>
        </div>
        <div className={Styles.modalCalendar}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid black",
              padding: "5px",
            }}
          >
            <div>
              <button onClick={handlePreviousMonth}>
                <FaCaretSquareLeft style={{ fontSize: "30px" }} />
              </button>
            </div>
            <div>{renderHeader()}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button onClick={handleNextMonth}>
                <FaCaretSquareRight style={{ fontSize: "30px" }} />
              </button>
            </div>
          </div>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dayCellContent={dayCellContent}
            dayHeaderContent={dayHeaderContent}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentAttendance;

// export const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const isInvalid = (fields) =>
//   fields.every((i) => typeof i === "undefined" || !i || i === "");

// export function getRequestOptions(contentType) {
//   const token = window.localStorage.getItem("token");
//   const requestOptions = {
//     headers: {
//       Authorization: `bearer ${token || ""}`,
//       "Content-Type": contentType || "application/json",
//       Accept: "application/json",
//     },
//   };

//   return requestOptions;
// }

// export const logoutUser = (error) => {
//   if (error.response.status === 401 || error.response.status === 400) {
//     localStorage.clear();
//     window.location.href = "/";
//   }
// };

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const isInvalid = (fields) =>
  fields.every((i) => typeof i === "undefined" || !i || i === "");

export function getRequestOptions(contentType) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    headers: {
      Authorization: `bearer ${token || ""}`,
      "Content-Type": contentType || "application/json",
      Accept: "application/json",
    },
  };

  return requestOptions;
}

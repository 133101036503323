import React, { useState } from "react";
import { Link } from "react-router-dom";
import Styles from "./AdminTimeTable.module.scss";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AdminDashboard from "services/management-dashboard/timetable.service";
import { requestAllClassroom } from "services/management-dashboard/classroom.service";
import { useEffect } from "react";
import { requestSearchTeacher } from "services/management-dashboard/staff.service";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import defaultUser from "../../../assets/icons/User.png";
import { useClassAndSections } from "hooks/classAndSection";
import { BiUpArrowCircle } from "react-icons/bi";
const ManagementTimeTable = () => {
  const toast = useToast();
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [teachers, setTeachers] = useState([]);
  const [searchByNameGrade, setSearchByNameGrade] = useState({
    search_term: "",
  });
  const [inputFields, setInputFields] = useState({ class: "All" });
  const [timetables, setTimetables] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);

  function compare(a, b) {
    if (a.class < b.class) {
      return -1;
    }
    if (a.class > b.class) {
      return 1;
    }
    return 0;
  }

  const fetchTimetables = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.class ? { class: inputFields.class } : { class: "1" }),
      };
      const response = await AdminDashboard.requestSearchTimetable(payload);
      if (response.error) {
        console.error(response.error);
      }
      setRequestInProgress(false);
      const data = response;
      setTimetables(data);
      return data;
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await requestSearchTeacher();
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setTeachers(_result);
      return _result;
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateTimetable = async () => {
    try {
      const response = await requestAllClassroom({
        class: inputFields?.class,
      });
      console.log("response,response", response);
      let required_classID;
      if (response.error) {
        console.error(response.error);
      }
      for (let i = 0; i < response.length; i++) {
        if (
          response[i].class === inputFields?.class &&
          response[i].section === inputFields?.section
        ) {
          required_classID = response[i]._id;
          break;
        }
      }
      console.log("required_classID", required_classID);
      const resp = required_classID
        ? await AdminDashboard.requestCreateTimeTable({
            class_id: required_classID,
          })
        : "";
      if (resp.error) {
        console.error(resp.error);
      }
      if (response && resp) {
        toast({
          title: "Created Succesfully",
          description: "Created Timetable",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
        fetchTimetables();
        setInputFields({ class: "All" });
      }
    } catch (e) {
      toast({
        title: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(e);
      onClose();
      setInputFields({ class: "All" });
    }
  };

  // const requestPageDetails = () => {
  //   setRequestInProgress(true);
  //   Promise.all([fetchTeachers()])
  //     .then(async (resp) => {
  //       const [classRoomResp, datatimetable] = resp;
  //       const activeTeachers = datatimetable.filter((i) => i.is_active);
  //       let cls = await Promise.all(
  //         classRoomResp.map(async (item) => {
  //           let profile_url = activeTeachers
  //             .filter((i) => i._id == item.class_teacher.id)
  //             .map((i) => i.personel.profile_url);
  //           item.class_teacher.teacher_profile_url = profile_url[0];
  //           item.class_teacher.signed_teacher_profile_url =
  //             await getSignedUrlLink(profile_url[0]);
  //           return item;
  //         })
  //       );
  //       setRequestInProgress(false);
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       setRequestInProgress(false);
  //     });
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     requestPageDetails();
  //   }, 500);
  // }, []);
  useEffect(() => {
    fetchTimetables();
  }, [inputFields.class, inputFields.section]);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>
            TIMETABLE | Total Timetable : {timetables?.length}
          </p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class}
                onChange={handleInputChange}
                name="class"
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <Tooltip label="Create Timetable">
            <div className={Styles.addButton} onClick={onOpen}>
              <Button colorScheme="teal">Create</Button>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className={Styles.body}>
        <div className={Styles.lists}>
          {timetables?.length === 0 ? (
            <div className={Styles.noResult}>No Timetable Found</div>
          ) : (
            ""
          )}
          {timetables?.map((item) => (
            <Link className={Styles.list} to={item._id}>
              <div className={Styles.profile}>
                <img
                  className={Styles.profileImg}
                  src={
                    item?.class_teacher?.teacher_profile_url == "N/A"
                      ? defaultUser
                      : item?.class_teacher?.teacher_profile_url
                  }
                  alt="profile"
                />
              </div>
              <div className={Styles.info}>
                <p className={Styles.class}>
                  Grade : {item.class} | Section : {item.section}
                </p>
                <hr
                  style={{ color: "red", borderColor: "red", height: "10px" }}
                />
                <p className={Styles.name}>
                  Class Teacher : {item?.class_teacher?.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Timetable</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.modalContainer}>
              <div className={Styles.filterWrapper}>
                <p className={Styles.formLabel}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  value={inputFields.class ? inputFields.class : ""}
                  onChange={handleInputChange}
                  name="class"
                  placeholder="Select Grade"
                >
                  {classAndSections.map((grade, idx) => (
                    <option
                      disabled={grade.class == "All"}
                      key={grade.class + idx}
                      value={grade.class}
                    >
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>

              <div className={Styles.filterWrapper}>
                <p className={Styles.formLabel}>
                  Section <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  value={inputFields.section ? inputFields.section : ""}
                  onChange={handleInputChange}
                  name="section"
                  placeholder="Select Section"
                >
                  {sections
                    ? sections.map((section, idx) => (
                        <option
                          disabled={section == "All"}
                          key={section + idx}
                          value={section}
                        >
                          Section {section}
                        </option>
                      ))
                    : ""}
                </Select>
              </div>
              <Button
                colorScheme="red"
                disabled={!(inputFields.section && inputFields.class)}
                onClick={handleCreateTimetable}
              >
                Create
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

export default ManagementTimeTable;

import add from "assets/icons/add.png";
import edit from "assets/icons/edit.png";
import Attachment from "assets/icons/attachment.png";
import deleteIcon from "assets/icons/delete.png";
import direction from "assets/icons/direction.png";
import donwload from "assets/icons/download.png";
import Circular from "assets/icons/Circulars.png";
import Student from "assets/icons/student.png";
import Teacher from "assets/icons/teacher.png";
import TimeTable from "assets/icons/Time Table.png";
import SchoolBus from "assets/icons/School Bus.png";
import SMS from "assets/icons/SMS.png";
import Admin from "assets/icons/Admin .png";
import Admission from "assets/icons/Admission .png";
import Attendance from "assets/icons/Attendance.png";
import AcademicCalendar from "assets/icons/Academic Calendar.png";
import Events from "assets/icons/Events .png";
import Classroom from "assets/icons/Classroom .png";
import Logout from "assets/icons/Logout .png";
import Fees from "assets/icons/Fees.png";
import Exams from "assets/icons/Exams.png";
import Library from "assets/icons/Library.png";
import RecycleBin from "assets/icons/Recycle Bin .png";
import Management from "assets/icons/Management.png";
import Canteen from "assets/icons/Canteen .png";
import Statistics from "assets/icons/statistics.png";
import Certificate from "assets/icons/Certificates.png";
import Backup from "assets/icons/Backup.png";
import Permissions from "assets/icons/Permissions.png";
import Tools from "assets/icons/Tools.png";
import Help from "assets/icons/Help.png";
import Notification from "assets/icons/Notification.png";
import Assignment from "assets/icons/Assignment.png";
import Knowledge_Centre from "assets/icons/Knowledge_Centre.png";
import Report_Centre from "assets/icons/Report_Centre.png";
import Branding from "assets/icons/Branding.png";
import Managementimg from "assets/icons/Management.png"
export const Icons = {
  add,
  edit,
  Attachment,
  Certificate,
  deleteIcon,
  direction,
  donwload,
  Circular,
  Management,
  Student,
  Teacher,
  TimeTable,
  SMS,
  SchoolBus,
  AcademicCalendar,
  Admin,
  Admission,
  Attendance,
  Events,
  Classroom,
  Logout,
  Fees,
  Exams,
  Library,
  RecycleBin,
  Canteen,
  Statistics,
  del: deleteIcon,
  Backup,
  Permissions,
  Branding,
  Help,
  Notification,
  Assignment,
  Knowledge_Centre,
  Report_Centre,
  Tools,
  Managementimg
};

import React, { useState, useEffect } from "react";
import Styles from "./Event.module.scss";
import { useParams } from "react-router-dom";
import StaffDashboard from "services/staff-dashboard/events.service";
import { MdOutlineAttachFile } from "react-icons/md";
import { useClassAndSections2 } from "hooks/classAndSection";
import { Checkbox, Textarea } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { VscFilePdf } from "react-icons/vsc";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
const StaffSingleEvent = () => {
  const { classAndSections } = useClassAndSections2();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    description: "",
    date: "",
    media_url: [],
    target_group: [],
  });
  const params = useParams();
  const [existingFile, setExistingFile] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchEventDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const fetchEventDetails = async () => {
    try {
      const response = await StaffDashboard.requestEventsById({
        eventId: params.id,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        const DATE = response?.date;
        let date = DATE && new Date(DATE).toLocaleDateString();
        if (date == "Invalid Date") {
          if (DATE.includes("-")) {
            date = new Date(DATE.split("-").reverse().join("/"));
          } else if (DATE.includes("/")) {
            const [day, month, year] = DATE.split("/");
            const dateString = [month, day, year].join("/");
            date = new Date(dateString);
          }
        } else {
          if (typeof new Date(DATE) === "object") {
            date = new Date(DATE);
          } else {
            date = DATE;
          }
        }
        setExistingFile(response.media);
        let allImages = [];
        for (let i = 0; i < response.media.length; i++) {
          if (!response.media[i]?.media_url?.includes(".pdf")) {
            allImages.push(response.media[i].media_url);
          }
        }
        setImages(allImages);
        setInputFields({
          title: response.title,
          description: response.description,
          date: date,
          target_group: response.target_group,
          media_url: response.media,
        });
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  return (
    <div className={Styles.body}>
      <div className={Styles.coverImage}>
        {inputFields.media_url?.length > 0 ? (
          <Carousel
            showThumbs={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    left: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.leftCircle}
                >
                  <AiFillLeftCircle />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    right: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.rightCircle}
                >
                  <AiFillRightCircle />
                </button>
              )
            }
            swipeable={true}
            emulateTouch={true}
          >
            {inputFields.media_url.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    src={item.media_url}
                    className={Styles.carouselImg}
                    alt="attachment-img"
                  ></img>
                </div>
              );
            })}
          </Carousel>
        ) : null}
      </div>
      <div className={Styles.form}>
        <p style={{ fontWeight: "bolder", fontSize: "20px" }}>Title</p>

        <p className={`${Styles.textInput} ${Styles.inputField}`}>
          {inputFields?.title}
        </p>
      </div>
      <div className={Styles.form}>
        <p style={{ fontWeight: "bolder", fontSize: "20px" }}>Description</p>
        <Textarea
          readOnly
          id="description"
          name="description"
          value={inputFields?.description}
          placeholder="Enter description..."
          size="lg"
          resize="vertical"
          className={Styles.textInput}
          required
        />
      </div>
      <div className={Styles.formCheck}>
        <p style={{ fontWeight: "bolder", fontSize: "20px" }}>Target Classes</p>
        <div className={Styles.checkboxes}>
          {classAndSections.map((grade, idx) => (
            <Checkbox
              id="target_group"
              name="target_group"
              isChecked={inputFields?.target_group.includes(grade.class)}
              colorScheme="red"
              key={grade.class + idx}
              value={grade.class}
              readOnly
            >
              <p className={Styles.checkboxText}>{grade.class}</p>
            </Checkbox>
          ))}
        </div>
      </div>

      <div className={Styles.formDate}>
        <p style={{ fontWeight: "bolder", fontSize: "20px" }}>Date</p>
        <DatePicker
          required
          selected={inputFields.date}
          value={inputFields?.date}
          disabled
          dateFormat="dd/MM/yyyy"
          className={Styles.inputField}
        />
      </div>

      {existingFile
        ? existingFile.map((element, idx) => {
            if (typeof element === "string" && element.includes(".pdf")) {
              return (
                <a
                  className={Styles.attachment}
                  href={element}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <p className={Styles.placeholder}>
                    Attachment {idx + 1}
                    <MdOutlineAttachFile style={{ display: "inline" }} />
                  </p>
                  <VscFilePdf className={Styles.pdfLink} />
                </a>
              );
            }
          })
        : null}
    </div>
  );
};

export default StaffSingleEvent;

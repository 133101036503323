import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import StudentReport from "./StudentReport";
import StaffReport from "./StaffReport";
import AdmissionReport from "./AdmissionReport";
import FeesReport from "./FeesReport";
import CanteenReport from "./CanteenReport";
import ClassroomReport from "./ClassroomReport";
import ExamReport from "./ExamReport";
import EnrolledBusStudentReport from "./EnrolledBusStudentReport";
import AttendanceReport from "./AttendanceReport";

const ManagementReportCentre = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        <SwiperSlide key="student">
          <StudentReport />
        </SwiperSlide>
        <SwiperSlide key="staff">
          <StaffReport />
        </SwiperSlide>
        <SwiperSlide key="admission">
          <AdmissionReport />
        </SwiperSlide>
        <SwiperSlide key="fees">
          <FeesReport />
        </SwiperSlide>
        <SwiperSlide key="canteen">
          <CanteenReport />
        </SwiperSlide>
        <SwiperSlide key="enrolled">
          <EnrolledBusStudentReport />
        </SwiperSlide>
        <SwiperSlide key="attendance">
          <AttendanceReport />
        </SwiperSlide>
        <SwiperSlide key="classroom">
          <ClassroomReport />
        </SwiperSlide>
        <SwiperSlide key="exam_report">
          <ExamReport />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ManagementReportCentre;

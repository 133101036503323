import React, { useState } from "react";
import Styles from "./ManualTcPage.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
const initailvalue = {
  name: "",
  start_date: "",
  end_date: "",
  startingGrade: "",
  lastGrade: "",
  gender: "",
};
const ConductCertifiacte = () => {
  const [inputFields, setInputFields] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const transferData = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };

  const onSubmit = () => {
    if (
      !inputFields?.name ||
      !inputFields?.start_date ||
      !inputFields?.end_date ||
      !inputFields?.startingGrade ||
      !inputFields?.lastGrade ||
      !inputFields?.gender
    ) {
      toast({
        title: "Please fill in required fields",
        description: "All fields marked with an asterisk (*) are required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    navigate(`download`, {
      state: {
        inputFields,
      },
    });
  };
  return (
    <>
      <div className={Styles.allField}>
        <div className={Styles.tagContanier}>
          <p>
            Name <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Name"
              type="text"
              name="name"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Start Date <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="15-12-2015"
              type="text"
              name="start_date"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            End Date <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="15-05-2020"
              type="text"
              name="end_date"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            First Grade <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Grade 1"
              type="text"
              name="startingGrade"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Last Grade <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Grade 5"
              type="text"
              name="lastGrade"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div className={Styles.tagContanier}>
          <p>
            Gender <span className={Styles.asterisk}>*</span> :
          </p>
          <div className={Styles.inputTags}>
            <input
              required
              placeholder="Gender"
              type="text"
              name="gender"
              onChange={(e) => transferData(e)}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              onSubmit();
            }}
            colorScheme="green"
            mt={2}
            // style={{
            //   marginTop: "2vw",
            //   backgroundColor: "red",
            //   color: "white",
            //   borderRadius: "4px",
            //   padding: "5px 8px 5px 8px",
            //   fontWeight: "bold",
            // }}
          >
            Generate
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConductCertifiacte;

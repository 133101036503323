import Styles from "./SchoolFeeCategories.module.scss";
import { Link } from "react-router-dom";

const ManagementSchoolFeeCategories = () => {
  return (
    <div>
      <div className={Styles.statsWrapper}>
        <p className={Styles.stats}>SCHOOL FEES</p>
      </div>
      <div className={Styles.body}>
        <h1 className={Styles.heading}>Select a category</h1>
        <div className={Styles.row}>
          <Link to="k" className={Styles.category}>
            <h1 className={Styles.heading}>K</h1>
            <p>K having LKG and UKG sub categories</p>
          </Link>
          <Link to="h1" className={Styles.category}>
            <h1 className={Styles.heading}>H1</h1>
            <p>H1 having 1st to 5th sub categories</p>
          </Link>
          <Link to="h2" className={Styles.category}>
            <h1 className={Styles.heading}>H2</h1>
            <p>H2 having 6th to 10th sub categories</p>
          </Link>
          <Link to="p" className={Styles.category}>
            <h1 className={Styles.heading}>P</h1>
            <p>P having 11th and 12th sub categories</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ManagementSchoolFeeCategories;

import {
  Button,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import Styles from "./ManagementAttendance.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import { debounce } from "utilities/utils";
import { requestSearchStudentByFilter } from "services/management-dashboard/student.service";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { AiOutlinePlusSquare, AiOutlineCloseCircle } from "react-icons/ai";
import defaultUser from "assets/icons/User.png";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import {
  requestAttendanceClass,
  requestGetAttendance,
  requestGetClassStats,
  requestHolidays,
  requestStatisticsStudentDetails,
} from "services/management-dashboard/attendance.service";
import { Link } from "react-router-dom";

const ManagementAttendance = () => {
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab.toString());
  }, [selectedTab]);
  return (
    <div>
      <Tabs
        isLazy
        isFitted
        variant="enclosed"
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
      >
        <TabList mb="1em">
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Attendance</Tab>
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
            Attendance Statistics
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Attendance />
          </TabPanel>
          <TabPanel>
            <Statistics />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};
export default ManagementAttendance;

const Attendance = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputFields, setInputFields] = useState({});
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  const [students, setStudentsList] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const { classAndSections } = useClassAndSectionsWithoutAll();
  const [sections, setSections] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [morningList, setMorningList] = useState([]);
  const [afternoonList, setAfternoonList] = useState([]);
  const [presentList, setPresentList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [classStatus, setClassStatus] = useState(null);
  const [attendanceDays, setAttendanceDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const calendarRef = useRef(null);
  const modalUpdateAttendance = useDisclosure();
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();
    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
              <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                <div class="${Styles.date}">${date.getDate()}</div>
              </div>
            `,
    };
  };
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    if (clickedDate < new Date(2023, 5, 1)) {
      toast({
        title: "The dates beyond the session is invalid",
        description: "The academic session date range is startdate- end date. ",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // Calculate the difference in months between the clicked date and the selected month
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;

    // Calculate the new month and year based on the clicked date
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;

    // Update the state to the clicked date, month, and year
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    calendarRef.current.getApi().unselect(); // Unselect the clicked date

    setTimeout(() => {
      calendarRef.current.getApi().updateSize(); // Update the calendar size after a delay
    }, 0);
  };
  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const studentList = isQueried ? searchResultList : students;
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  useEffect(() => {
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : "";
    setInputFields(input_data);
  }, []);
  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section, inputFields.studentName]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleAttendanceForClass = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      class: inputFields.class,
      section: inputFields.section,
      year: selectedYear,
    };
    const response = await requestAttendanceClass(payload);
    setAttendanceDays(response);
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  const selectDate = moment(selectedDate).format("YYYY-MM-DD");
  const formattedHolidays = holidays
    ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  const isTodayHoliday = formattedHolidays.includes(selectDate);
  const isSunday = selectedDate && selectedDate?.getDay() === 0;
  const isFutureDate = selectedDate > new Date();
  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedAttendanceDays = attendanceDays
      ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();

        if (formattedAttendanceDays.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi.batchRendering(() => {
      highlightDates();
      calendarApi.render();
    });
  }, [
    attendanceDays,
    inputFields.class,
    inputFields.section,
    selectedMonth,
    holidays,
  ]);
  useEffect(() => {
    if (
      selectedYear &&
      selectedMonth !== undefined &&
      inputFields.class &&
      inputFields.section
    ) {
      handleAttendanceForClass();
      setAttendanceDays(null);
      handleHolidays();
      setHolidays(null);
    }
  }, [selectedMonth, inputFields.class, inputFields.section]);
  const handleGetAttendance = () => {
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = requestGetAttendance(data);
    res
      .then((response) => {
        setAttendanceData(response);
      })
      .catch((error) => {});
  };
  const handleGetClassStats = async () => {
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = await requestGetClassStats(data);
    setClassStatus(res);
  };
  const handleAttendanceData = () => {
    setMorningList(attendanceData.morning_present);
    setAfternoonList(attendanceData.afternoon_present);
    setPresentList(attendanceData.present);
    setAbsentList(attendanceData.absent);
  };
  useEffect(() => {
    if (selectedDate && attendanceData) {
      handleAttendanceData();
    }
  }, [attendanceData]);
  useEffect(() => {
    if ((inputFields.class && inputFields.section) || selectedDate) {
      handleGetClassStats();
      setClassStatus("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  const chhoseDate = moment(selectedDate).format("YYYY-MM-DD");
  const addedAttendance = attendanceDays
    ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  useEffect(() => {
    if (
      inputFields.class &&
      inputFields.section &&
      addedAttendance.includes(chhoseDate)
    ) {
      handleGetAttendance();
      setAttendanceData("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  return (
    <>
      <div className={Styles.WholeContainer}>
        <div className={Styles.calenderWidth}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid black",
                padding: "5px",
              }}
            >
              <div>
                <button onClick={handlePreviousMonth}>
                  <FaCaretSquareLeft className={Styles.arrows} />
                </button>
              </div>
              <div>
                {renderHeader()} {/* Display the month and year */}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className={Styles.todayButton}
                  onClick={handleTodayButtonClick}
                  style={{ display: isToday() ? "none" : "block" }}
                >
                  Today
                </button>
                <button onClick={handleNextMonth}>
                  <FaCaretSquareRight className={Styles.arrows} />
                </button>
              </div>
            </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              selectable={true}
              dateClick={handleDateClick}
              dayCellContent={dayCellContent}
              dayHeaderContent={dayHeaderContent}
            />
          </div>
        </div>
        <div className={Styles.BoxContainer}>
          <div className={Styles.header}>
            <div className={Styles.dateContainer}>
              <p>
                Date : &nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className={Styles.headerFilterWrapper}>
              <div className={Styles.filterWrapper} style={{ width: "100%" }}>
                <Select
                  value={inputFields.class ? inputFields.class : ""}
                  onChange={handleInputChange}
                  name="class"
                  placeholder="Select Grade"
                  style={{ border: "1px solid black" }}
                >
                  {classAndSections.map((grade, idx) => (
                    <option key={grade.class + idx} value={grade.class}>
                      Grade {grade.class}
                    </option>
                  ))}
                </Select>
              </div>
              <div className={Styles.filterWrapper} style={{ width: "100%" }}>
                <Select
                  value={inputFields.section ? inputFields.section : ""}
                  onChange={handleInputChange}
                  name="section"
                  placeholder="Select Section"
                  style={{ border: "1px solid black" }}
                >
                  {sections
                    ? sections.map((section, idx) => (
                        <option key={section + idx} value={section}>
                          Section {section}
                        </option>
                      ))
                    : ""}
                </Select>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {classStatus ? (
              <div
                style={{
                  backgroundColor: "white",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  marginTop: "15px",
                  padding: "11px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p style={{ width: "50%", fontWeight: "bold" }}>
                      Academic Session
                    </p>
                    <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                    <p style={{ width: "40%" }}>
                      {moment(classStatus.start_date).format("DD/MM/YYYY")}-
                      {moment(classStatus.end_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                    }}
                  >
                    <p style={{ width: "50%", fontWeight: "bold" }}>
                      Total Number of Days in the Session
                    </p>
                    <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                    <p style={{ width: "40%" }}>{classStatus.total_days}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "row",
                    }}
                  >
                    <p style={{ width: "50%", fontWeight: "bold" }}>
                      Total Number of Midterm Days in the Session
                    </p>
                    <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                    <p style={{ width: "40%" }}>{classStatus.mid_term_days}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "row",
                    }}
                  >
                    <p style={{ width: "50%", fontWeight: "bold" }}>
                      Total Number of Days Attendance Entered
                    </p>
                    <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                    <p style={{ width: "40%" }}>{classStatus.marked_days}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className={Styles.selectGrade}>Please Select Grade</div>
              </div>
            )}
          </div>
          {selectedDate && classStatus && (
            <div
              style={{
                width: "95%",
                display: " flex",
                flexDirection: "column",
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                marginTop: "25px",
                padding: "11px",
                fontWeight: "bold",
              }}
            >
              {isTodayHoliday || isSunday ? (
                <p style={{ color: "red" }}>
                  The Selected Date is a Holiday / Sunday
                </p>
              ) : isFutureDate ? (
                <p
                  style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}
                >
                  Attendance not present for the upcoming date from today.
                </p>
              ) : (
                <>
                  <p>
                    <strong>Total Number of Students :</strong>
                    <span style={{ fontWeight: "bold" }}>
                      &nbsp;
                      {classStatus?.total_count
                        ? classStatus?.total_count
                        : "N/A"}
                    </span>
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>
                      Present Students :&nbsp;
                      <span style={{ color: "green" }}>
                        <span style={{ color: "green" }}>
                          {classStatus?.presents
                            ? classStatus?.presents
                            : "N/A"}
                        </span>
                      </span>
                    </p>
                    <p>
                      Absent Students :&nbsp;
                      <span style={{ color: "red" }}>
                        {classStatus.absents ? classStatus?.absents : "N/A"}
                      </span>
                    </p>
                    <p>
                      Halfday Present Students :&nbsp;
                      <span>
                        {classStatus?.partial_present
                          ? classStatus?.partial_present
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          <div
            style={{
              width: "95%",
              display: " flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              marginTop: "25px",
              padding: "11px",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#9c9695",
                  marginRight: "10px",
                }}
              ></div>
              <p>Holiday / Sunday</p>
            </div>
            <div
              style={{
                display: " flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "rgb(51 190 51)",
                  marginRight: "10px",
                }}
              ></div>
              <p>Attendance Entered</p>
            </div>
          </div>
        </div>
        {addedAttendance.includes(chhoseDate) &&
          selectedDate &&
          inputFields.class &&
          inputFields.section && (
            <Tooltip label={"View Attendance"} aria-label="A tooltip">
              <div
                className={Styles.academicYear}
                onClick={modalUpdateAttendance.onOpen}
              >
                <IoMdCheckmarkCircleOutline className={Styles.add} />
              </div>
            </Tooltip>
          )}
        <Modal
          isOpen={modalUpdateAttendance.isOpen}
          onClose={modalUpdateAttendance.onClose}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent
            position="fixed"
            top="10"
            transform="translateX(-50%)"
            maxWidth="70vw"
            width="70vw"
            maxHeight="150vw"
            overflowY="scroll"
          >
            <ModalHeader>View Attendance</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Date:
                    {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
                    &nbsp;|&nbsp; Total Students:{studentList.length}
                  </p>
                </div>
                <div className={Styles.body}>
                  <div
                    className={Styles.lists}
                    style={{ maxHeight: "42vh", overflowY: "auto" }}
                  >
                    {attendanceData && (
                      <>
                        {studentList.map((student) => {
                          const studentId = student._id;
                          return (
                            <div key={studentId} className={Styles.list}>
                              <div className={Styles.profile}>
                                <img
                                  className={Styles.profileImg}
                                  src={
                                    student.url != "N/A"
                                      ? student.url
                                      : defaultUser
                                  }
                                  alt="student-profile"
                                />
                              </div>
                              <div className={Styles.info}>
                                <p className={Styles.name}>
                                  {student.name || "--"}
                                </p>
                                <div style={{ display: "flex" }}>
                                  <Checkbox
                                    colorScheme="red"
                                    isChecked={
                                      morningList.includes(studentId) ||
                                      presentList.includes(studentId)
                                    }
                                  >
                                    <p className={Styles.checkboxText}>
                                      Morning
                                    </p>
                                  </Checkbox>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <Checkbox
                                    colorScheme="red"
                                    isChecked={
                                      afternoonList.includes(studentId) ||
                                      presentList.includes(studentId)
                                    }
                                  >
                                    <p className={Styles.checkboxText}>
                                      Afternoon
                                    </p>
                                  </Checkbox>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={modalUpdateAttendance.onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};
const Statistics = () => {
  const downloadModal = useDisclosure();
  const { classAndSections, loading } = useClassAndSectionsWithoutAll();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({
    academic: true,
    student: false,
  });
  const initialFieldsState = {
    name: true,
    class: true,
    section: true,
    totalDays: true,
    startDate: false,
    endDate: false,
    percentage: true,
    addedDays: true,
    halfDay: false,
    absent: true,
    present: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter2")
      ? JSON.parse(localStorage.getItem("student_filter2"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);
    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter2", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);
  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };

      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilter(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter2", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter2")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter2", JSON.stringify(localStorageData));
    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));
    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const studentList = isQueried ? searchResultList : students;
  const totalStudentsCount = studentList.length;
  const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-Attendance-Statistics-${inputFields.class}-${inputFields.section}`
      : "SMU-Attendance-Statistics";
  const ExportToExcel = ({ apiData, fileName }) => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        <Tooltip label="Download Student Details">
          <Button colorScheme="green">Archive</Button>
        </Tooltip>
      </div>
    );
  };
  const handleExportClick = async () => {
    const payload = {
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestStatisticsStudentDetails(payload);
    if (res) {
      let newArray = res.flatMap((item) => {
        return item.data.map((studentInfo) => {
          let obj = {};
          if (fields.class) {
            obj["Class"] = studentInfo.class;
          }
          if (fields.section) {
            obj["Section"] = studentInfo.section;
          }
          if (fields.name) {
            obj["Name"] = studentInfo.student_name;
          }
          if (fields.totalDays) {
            obj["Total Days"] = item.index.total_days;
          }
          if (fields.startDate) {
            obj["Start-Date"] = moment(item.index.start_date).format(
              "DD-MM-YYYY"
            );
          }
          if (fields.endDate) {
            obj["End-Date"] = moment(item.index.end_date).format("DD-MM-YYYY");
          }
          if (fields.percentage) {
            obj["Percentage"] = studentInfo.attendance_percentage;
          }
          if (fields.addedDays) {
            obj["Attendance Added Days"] = item.index.exec_days;
          }
          if (fields.halfDay) {
            obj["Total Halfday"] = studentInfo.half_day_present;
          }
          if (fields.absent) {
            obj["Total Absent"] = studentInfo.total_absent_count;
          }
          if (fields.present) {
            obj["Total Present"] = studentInfo.total_present_count;
          }
          return obj;
        });
      });

      setData(newArray);

      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }

      toast({
        title: "Downloaded Successfully.",
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });

      setFields(initialFieldsState);
      setShowDetails((prevDetails) => ({
        academic: true,
        student: false,
      }));
      setSelectedFileFormat("xlsx");
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      academic: true,
      student: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>Total Students : {studentList.length}</p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative", marginLeft: "20px" }}>
                  <Input
                    name="studentName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields.studentName}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                  {showSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
              >
                {sections
                  ? sections.map((section, idx) => (
                      <option key={section + idx} value={section}>
                        Section {section}
                      </option>
                    ))
                  : ""}
              </Select>
            </div>
            {studentList.length >= 1 ? (
              <ExportToExcel apiData={data} fileName={fileName} />
            ) : null}
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No result found</div>
          ) : (
            studentList.map((student, idx) => (
              <Link
                className={Styles.list}
                to={student._id}
                state={{ studentId: student._id }}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={student.url != "N/A" ? student.url : defaultUser}
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>{student.name || "--"}</p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <Modal
        isOpen={downloadModal.isOpen}
        onClose={() => {
          downloadModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Academic Session Details
                  </button>
                  {showDetails.academic ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("academic")}
                    />
                  )}
                </div>
                {showDetails.academic && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.startDate}
                      onChange={() => {
                        setFields({
                          ...fields,
                          startDate: !fields.startDate,
                        });
                      }}
                    >
                      Start Date
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.endDate}
                      onChange={() => {
                        setFields({
                          ...fields,
                          endDate: !fields.endDate,
                        });
                      }}
                    >
                      End Date
                    </Checkbox>
                  </div>
                )}
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Student Details
                  </button>
                  {showDetails.student ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("student")}
                    />
                  )}
                </div>
                {showDetails.student && (
                  <>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.addedDays}
                        onChange={() => {
                          setFields({
                            ...fields,
                            addedDays: !fields.addedDays,
                          });
                        }}
                      >
                        Attendance Added Days
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.absent}
                        onChange={() => {
                          setFields({
                            ...fields,
                            absent: !fields.absent,
                          });
                        }}
                      >
                        Total Absent
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.halfDay}
                        onChange={() => {
                          setFields({
                            ...fields,
                            halfDay: !fields.halfDay,
                          });
                        }}
                      >
                        Total Halfday
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.present}
                        onChange={() => {
                          setFields({
                            ...fields,
                            present: !fields.present,
                          });
                        }}
                      >
                        Total Present
                      </Checkbox>
                    </div>
                  </>
                )}
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

import {
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Spinner,
    Select,
  } from "@chakra-ui/react";
  import React, { useCallback, useEffect, useState } from "react";
  import AdminDashboard from "services/admin-dashboard/library.service";
  
  // Utils
  import { Icons } from 'utilities/assets'
  import { debounce } from "utilities/utils";
  import { BOOK_LIST_INPUT_FIELDS } from "./constants";
  
  import Styles from "./Library.module.scss";
  const LibrartCheckout =()=>{
    const [requestUpdateInProgress, setRequestUpdateInProgress] = useState(false)
    const [requestGetInProgress, setRequestGetInProgress] = useState(false)
    const [currentOperation, setCurrentOperation] = useState('')
    const [currentBook, setCurrentBook] = useState({})
    const [allBooks, setBooks] = useState([])
    const [searchResult, setSearchResultList] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [categoriesList, setCategoriesList] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    useEffect(() => {
      Promise.all([
        getSearchBookResult({ saveDefault: true }),
        // getBooksCategories()
      ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const searchBooksByQuery = debounce(function() {
      getSearchBookResult()
    }, 500)
  
    const _getBooksByCategory = debounce(function() {
      getBooksByCategory()
    }, 500)
  
    useEffect(() => {
      if (searchInput.length > 0) {
        searchBooksByQuery(searchInput)
      }
      if ((selectedCategory.length > 0 && selectedCategory !== '') && (currentOperation === '' && currentOperation.length === 0)) {
        _getBooksByCategory()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, selectedCategory])
  
    const getBooksByCategory = async () => {
      setRequestGetInProgress(true)
      try {
        const response = await AdminDashboard.requestBookByCategory({ categoryId: selectedCategory })
        if (response.error) {
          console.error(response.error)
        }
        if (response && Array.isArray(response)) {
          setSearchResultList(response)
          setRequestGetInProgress(false)
        }
      } catch (e) {
        setRequestGetInProgress(false)
        console.error(e)
      }
    }
  
    const getSearchBookResult = async ({ saveDefault } = {}) => {
      setRequestGetInProgress(true)
      try {
        const data = await AdminDashboard.requestSearchBook({
          ...(searchInput !== '' ? { search: searchInput } : {})
        })
        if (data.error) {
          console.error(data.error)
        }
        if (data && data.result && Array.isArray(data.result)) {
          setRequestGetInProgress(false)
          setCurrentBook({})
          if (saveDefault) {
            setBooks(data.result)
          } else {
            setSearchResultList(data.result)
          }
        }
      } catch (e) {
        setRequestGetInProgress(false)
        console.error(e)
      }
    }
  
    const getBooksCategories = async () => {
      setRequestGetInProgress(true)
      try {
        const data = await AdminDashboard.requestAllCategory()
        if (data.error) {
          console.error(data.error)
        }
        if (data && Array.isArray(data)) {
          setRequestGetInProgress(false)
          setCategoriesList(data)
        }
      } catch (e) {
        setRequestGetInProgress(false)
        console.error(e)
      }
    }
  
    const _requestUpdateBook = async () => {
      setRequestUpdateInProgress(true)
      const { name: categoryName } = categoriesList.find(({_id}) => _id === selectedCategory)
      try {
        switch (currentOperation) {
          case 'ADD':
            await AdminDashboard.requestAddBook({
              ...currentBook,
              categoryName,
              categoryId: selectedCategory
            })
            break
          case 'EDIT':
            await AdminDashboard.requestUpdateBook({...currentBook, categoryName})
            break
          default: {}
        }
        setRequestUpdateInProgress(false)
        getSearchBookResult({ saveDefault: true })
        onClose()
      } catch (e) {
        console.error(e)
      } finally {
        setSelectedCategory('')
        setCurrentOperation('')
      }
    }
  
    const updateBook = (e) => {
      e.preventDefault()
      _requestUpdateBook()
    }
  
    const handleInputChange = useCallback(({ currentTarget: { name, value, valueAsNumber, type } } = {}) => {
      const __value = { number: valueAsNumber, text: value }
      setCurrentBook({ ...currentBook, [name]: __value[type] })
    }, [currentBook, setCurrentBook])
  
    const deleteBook = async (bookDetails) => {
      setRequestGetInProgress(true)
      try {
        await AdminDashboard.requestDeleteBook(bookDetails)
        getSearchBookResult({ saveDefault: true })
      } catch (e) {
        console.error(e)
      } finally {
        setRequestGetInProgress(false)
      }
    }
  
    const addBook = () => {
      setCurrentOperation('ADD')
      onOpen()
    }
  
    const editBook = (bookDetails = {}) => {
      setCurrentOperation('EDIT')
      setCurrentBook({ ...bookDetails })
      setSelectedCategory((bookDetails?.category[0] && bookDetails?.category[0]?.categoryId) || '')
      onOpen()
    }
  
    const getModalBtnContent = () => {
      return (
        currentOperation === 'ADD' ? 
          <>Add Book</> : 
          requestUpdateInProgress ? <Spinner /> : <>Edit Book</>
      )
    }
  
    const getContentForBookObject = (key, row) => {
      switch (key) {
        case 'title':
          return row?.title || '--'
        case 'category':
          return (row?.category && row?.category[0]?.name) || '--';
        case 'author':
          return row?.author || '--'
        case 'availableCopy':
          return row?.availableCopy || '--'
        default:
          return '--'
      }
    }
  
    let bookList = (
      currentOperation === '' && 
      (
        (searchInput !== '' && searchInput.length > 0) || 
        (selectedCategory !== '' && selectedCategory.length > 0)
      )
    ) ? searchResult : allBooks
  
    bookList = bookList.filter(i => i.isActive == 0)
  
    return (
      <div className={Styles.subWrapper}>
        <div className={Styles.header}>
          <p className={Styles.title}>Issue</p>
          <div className={Styles.search}>
            <Input
              variant="filled"
              placeholder="Search book"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className={Styles.filterWrapper}>
              <Select onChange={(e) => setSelectedCategory(e.target.value)} name="searchCategory" placeholder="Select Category">
                {categoriesList.map((category, idx) => (
                  <option key={category['_id'] + idx} value={category['_id']}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </div>
            {/* <div className={Styles.add} 
              onClick={addBook}
            >
              <img className={Styles.addImage} src={Icons.add} alt="add" />
            </div> */}
          </div>
        </div>
        <div className={Styles.lists}>
          {requestGetInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : (
            bookList.length === 0 ? (
              <div className={''}>No book found</div>
            ) : (
              bookList.map((book, idx) => (
                <div key={book['_id'] + idx} className={Styles.list}>
                  {/* {BOOK_LIST_INPUT_FIELDS.map((field, _idx) => ( */}
                    {/* <div key={book['_id'] + idx + _idx} className={Styles.listItem}> */}
                      <p className={Styles.listItemText}>Issue Doc 1</p>
                      {/* <p className={Styles.listItemVal}>{getContentForBookObject(field.id, book)}</p> */}
                    {/* </div> */}
                  {/* ))} */}
                  {/* <div className={Styles.edit}>
                    <img className={Styles.editImage} onClick={() => editBook(book)} src={Icons.edit} alt="edit" />
                    <img className={Styles.editImage} onClick={() => deleteBook(book)} src={Icons.deleteIcon} alt="delete" />
                  </div> */}
                </div>
              ))
            )
          )}
        </div>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{currentOperation === 'ADD' ? 'Add Book' : 'Edit Book'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form>
                <div className={Styles.formGroup}>
                  {BOOK_LIST_INPUT_FIELDS.map((field, idx) => (
                    <React.Fragment key={field.id + idx}>
                      <label htmlFor={field.id} className={Styles.formLabel}>{field.label}</label>
                      {field.type === 'select' ? (
                        <Select
                          isRequired
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          name='addCategory'
                          placeholder="Select category"
                        >
                          {categoriesList.map((category, idx) => (
                            <option key={category['_id'] + idx} value={category['_id']}>
                              {category.name}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          isRequired
                          className={Styles.formInput}
                          id={field.id}
                          name={field.id}
                          onChange={handleInputChange}
                          value={currentBook[field.id]}
                          type={field.type || "text"}
                          placeholder={field.placeholder}
                        />  
                      )}
                    </React.Fragment>
                  ))}
                  <button className={Styles.formButton} onClick={updateBook}>{getModalBtnContent()}</button>
                </div>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  export default LibrartCheckout
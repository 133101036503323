import axios from "axios";
import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";
import moment from "moment";

async function requestEventsById(payload = {}) {
  const { eventId } = payload;

  if (!eventId || eventId === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/management/event/id";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestSearchEvents(payload = {}) {
  const url = BASE_URL + "/management/event/search";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    console.group("mg", response);
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestAddEvent(payload = {}) {
  let { media_url } = payload;
  const { title, description, dates, target_group } = payload;
  if (
    !title ||
    title === "" ||
    !description ||
    description === "" ||
    !dates ||
    dates === ""
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/event";
  if (!media_url || media_url == null) media_url = [];

  try {
    const date = moment(dates).format("MM/DD/YYYY");
    if (date == "Invalid date") {
      const date = moment(dates, "DD/MM/YYYY").format("MM/DD/YYYY");
      const requestPayload = {
        title,
        description,
        date,
        target_group,
        media: [...media_url],
      };

      const response = await axios.post(
        url,
        { ...requestPayload },
        getRequestOptions()
      );
      if (response.data.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } else {
      const requestPayload = {
        title,
        description,
        date,
        target_group,
        media: [...media_url],
      };

      const response = await axios.post(
        url,
        { ...requestPayload },
        getRequestOptions()
      );
      if (response.data.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestUpdateEvent(payload = {}) {
  const { eventID, title, description, date, media_url, target_group } =
    payload;
  if (
    !eventID ||
    eventID === "" ||
    !title ||
    title === "" ||
    !description ||
    description === "" ||
    !date ||
    date === ""
  ) {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/event/update";

  try {
    let requestPayload;
    if (media_url && media_url.length > 0) {
      requestPayload = {
        eventID,
        updateBody: {
          title,
          description,
          date,
          target_group,
          media: [...media_url],
        },
      };
    } else {
      requestPayload = {
        eventID,
        updateBody: {
          title,
          description,
          date,
          target_group,
          media: [],
        },
      };
    }

    const response = await axios.post(
      url,
      { ...requestPayload },
      getRequestOptions()
    );
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestDeleteEvent(payload = {}) {
  const { eventId } = payload;

  if (!eventId || eventId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/management/event/delete";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

const Services = {
  requestAddEvent,
  requestEventsById,
  requestSearchEvents,
  requestUpdateEvent,
  requestDeleteEvent,
};

export default Services;

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import React from "react";
import AddBook from "./components/AddBooks";
import EnrolledStudents from "./components/EnrolledStudents";
import Categories from "./components/Categories";

// Assets and styles
import Styles from "./Library.module.scss";

function AdminLibrary() {
  return <>
    <div className={Styles.body}>
    {/* <Categories /> */}
      <Tabs isLazy isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Issue</Tab>
          <Tab>Book</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AddBook />
          </TabPanel>
          <TabPanel>
            <Categories />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <div className={Styles.mainLibraryComponent}>
      <div className={Styles.LibraryComponent1}>
      <div>
        <p className={Styles.BooksType}>
          Account
        </p>
        <p className={Styles.BooksType}>
          Managemet
        </p>
        <p className={Styles.BooksType}>
          Science
        </p>
        <p className={Styles.BooksType}>
          Biology
        </p>
        <p className={Styles.BooksType}>
          Maths
        </p>
        <p className={Styles.BooksType}>
          History
        </p>
        <p className={Styles.BooksType}>
          Comic
        </p>
        <p className={Styles.BooksType}>
          Novels
        </p>
        <p className={Styles.BooksType}>
          Account
        </p>
        <p className={Styles.BooksType}>
          Managemet
        </p>
        <p className={Styles.BooksType}>
          Science
        </p>
        <p className={Styles.BooksType}>
          Biology
        </p>
        <p className={Styles.BooksType}>
          Maths
        </p>
        <p className={Styles.BooksType}>
          History
        </p>
        <p className={Styles.BooksType}>
          Comic
        </p>
        <p className={Styles.BooksType}>
          Novels
        </p>
        </div>
      </div>
      <div>

      </div>
    </div> */}
    </div>
    </>
}

export default AdminLibrary;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BiUpArrowCircle } from "react-icons/bi";
import {
  requestAllSubjects,
  requestAllSubjectMarks,
} from "services/management-dashboard/classroom.service";
import defaultUser from "assets/icons/User.png";
import { TbReportAnalytics, TbExternalLink } from "react-icons/tb";
import Styles from "./Classroom.module.scss";
import { requestSearchTeacher } from "services/management-dashboard/staff.service";

const ManagementSingleClass = () => {
  const toast = useToast();
  const params = useParams();
  const {
    isOpen: maxIsOpen,
    onOpen: maxOnOpen,
    onClose: maxOnClose,
  } = useDisclosure();
  const [classroomDetails, setClassroomDetails] = useState(null);
  const [searchTeachers, setSearchTeachers] = useState("");
  const [subjectInModal, setSubjectInModal] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [requestInProgressModal, setRequestInProgressModal] = useState(false);
  const [allTeachers, setAllTeachers] = useState([]);
  const [searchTeacherList, setSearchTeacherList] = useState([]);
  const [maxAnual, setMaxAnual] = useState({
    total: 100,
    internal: 20,
    external: 80,
  });
  const [maxMid, setMaxMid] = useState({
    total: 50,
    internal: 10,
    external: 40,
  });

  const [marksArray, setMarksArray] = useState([
    { maxRange: 100, minRange: 0, grade: "" },
  ]);
  const [marksMidArray, setMarksMidArray] = useState([
    { maxMidRange: 50, minMidRange: 0, grade: "" },
  ]);
  const handleAllSubjectMarks = async () => {
    try {
      setRequestInProgressModal(true);
      const payload = {
        subject_name: subjectInModal.subject,
      };
      const response = await requestAllSubjectMarks(params.id, payload);
      if (response) {
        setRequestInProgressModal(true);
        setRequestInProgressModal(false);
      }
      setMaxAnual({
        total: response.max_marks.annual,
        internal: response.internal_marks.annual,
        external: response.external_marks.annual,
      });
      setMaxMid({
        total: response.max_marks.mid_term,
        internal: response.internal_marks.mid_term,
        external: response.external_marks.mid_term,
      });

      const annualGrading = response.grading.filter(
        (item) => item.exam_type === "ANNUAL"
      );
      const midTermGrading = response.grading.filter(
        (item) => item.exam_type === "MID_TERM"
      );

      setMarksArray(
        annualGrading.map((item) => ({
          maxRange: item.upper_limit,
          minRange: item.lower_limit,
          grade: item.grade,
        }))
      );

      setMarksMidArray(
        midTermGrading.map((item) => ({
          maxMidRange: item.upper_limit,
          minMidRange: item.lower_limit,
          grade: item.grade,
        }))
      );
      setRequestInProgressModal(false);
    } catch (e) {}
  };
  useEffect(() => {
    if (subjectInModal && subjectInModal.is_scholastic) {
      handleAllSubjectMarks();
    }
  }, [subjectInModal]);
  const requestPageDetails = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        class_id: params.id,
      };
      const response = await requestAllSubjects(payload);
      setClassroomDetails(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  const fetchTeachers = async () => {
    try {
      const response = await requestSearchTeacher({
        name: searchTeachers,
      });
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setAllTeachers(_result);
      setSearchTeacherList(searchTeachers == "" ? [] : _result);
      return _result;
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    requestPageDetails();
    fetchTeachers();
  }, [params.id]);
  const handleMaxModal = (subj) => {
    setSubjectInModal(subj);
    maxOnOpen();
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  if (requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!classroomDetails) {
    return null;
  }
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.body}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>
          Class : {classroomDetails?.class} | Section :{" "}
          {classroomDetails?.section}
        </p>
      </div>
      <div>
        <h1 className={Styles.heading}>Class Teacher</h1>
        <div className={Styles.Classlists}>
          <div className={Styles.list}>
            <div className={Styles.profile}>
              <img
                className={Styles.profileImg}
                src={
                  classroomDetails?.class_teacher?.teacher_profile_url != "N/A"
                    ? classroomDetails?.class_teacher?.teacher_profile_url
                    : defaultUser
                }
                alt="profile"
              />
              <Tooltip label="Staff Profile" placement="bottom">
                <Link to={classroomDetails?.class_teacher?.teacher_id}>
                  <TbExternalLink fontSize={25} style={{ marginTop: "10px" }} />
                </Link>
              </Tooltip>
            </div>
            <div className={Styles.info}>
              <p className={Styles.name}>
                {classroomDetails?.class_teacher?.name}
              </p>
            </div>
          </div>
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Regular Subjects({classroomDetails?.subjects.length})
        </h1>
        <div className={Styles.lists}>
          {classroomDetails?.subjects.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            classroomDetails?.subjects?.map((subj, index) => (
              <div key={subj["teacher_id"] + index} className={Styles.list}>
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={
                      subj.teacher_profile_url !== "N/A"
                        ? subj.teacher_profile_url
                        : defaultUser
                    }
                    alt="profile"
                  />
                  <Tooltip label="Staff Profile" placement="bottom">
                    <Link to={subj.teacher_id}>
                      <TbExternalLink fontSize={25} />
                    </Link>
                  </Tooltip>
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>
                    {truncateText(subj.teacher_name, 15)}
                  </p>
                  <hr
                    style={{ color: "red", borderColor: "red", height: "10px" }}
                  />
                  <p className={Styles.class}>{subj.subject}</p>
                  <div className={Styles.editbox}>
                    <p
                      className={Styles.code}
                      style={{ color: subj.is_scholastic ? "blue" : "green" }}
                    >
                      {subj.is_scholastic ? "Scholastic" : "Non Scholastic"}
                    </p>
                    <Tooltip label="Customize Grading" placement="bottom">
                      <div
                        className={Styles.icon}
                        onClick={() => {
                          handleMaxModal(subj);
                        }}
                      >
                        <TbReportAnalytics cursor="pointer" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Elective Subjects({classroomDetails.electives.length})
        </h1>
        <div className={Styles.electives}>
          {classroomDetails &&
          classroomDetails.electives &&
          classroomDetails.electives.length > 0 ? (
            classroomDetails.electives.map((elective) => {
              return (
                <div
                  className={Styles.electives_container}
                  key={elective.elective_id}
                >
                  <div className={Styles.elecBox}>
                    <h1 className={Styles.elective_heading}>
                      {elective.elective_name}
                    </h1>
                  </div>
                  <hr
                    style={{
                      color: "red",
                      borderColor: "black",
                      height: "10px",
                    }}
                  />
                  {elective.subjects.map((subject) => {
                    return (
                      <div
                        className={Styles.lists_elective}
                        key={subject.subject}
                      >
                        <div className={Styles.list}>
                          {/* <Link to={subject.teacher_id}> */}
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                subject?.teacher_profile_url !== "N/A"
                                  ? subject?.teacher_profile_url
                                  : defaultUser
                              }
                              alt="profile"
                            />
                            <Tooltip label="Staff Profile" placement="bottom">
                              <Link to={subject.teacher_id}>
                                <TbExternalLink
                                  fontSize={25}
                                  style={{ marginTop: "10px" }}
                                />
                              </Link>
                            </Tooltip>
                          </div>
                          {/* </Link> */}
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {truncateText(subject.teacher_name, 15)}
                            </p>
                            <hr
                              style={{
                                color: "red",
                                borderColor: "red",
                                height: "10px",
                              }}
                            />
                            <p className={Styles.class}>{subject.subject}</p>
                            <div className={Styles.editbox}>
                              <p
                                className={Styles.code}
                                style={{
                                  color: subject.is_scholastic
                                    ? "blue"
                                    : "green",
                                }}
                              >
                                {subject.is_scholastic
                                  ? "Scholastic"
                                  : "Non Scholastic"}
                              </p>
                              <Tooltip
                                label="Customize Grading"
                                placement="bottom"
                              >
                                <div
                                  className={Styles.icon}
                                  onClick={() => {
                                    handleMaxModal(subject);
                                  }}
                                >
                                  <TbReportAnalytics cursor="pointer" />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className={Styles.noResult}>No Results</div>
          )}
        </div>
      </div>
      <Modal
        isOpen={maxIsOpen}
        onClose={() => {
          setMaxAnual({
            total: 100,
            internal: 20,
            external: 80,
          });
          setMaxMid({
            total: 50,
            internal: 10,
            external: 40,
          });
          setMarksArray([{ maxRange: 100, minRange: 0, grade: "" }]);
          setMarksMidArray([{ maxMidRange: 50, minMidRange: 0, grade: "" }]);
          setSubjectInModal("");
          maxOnClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            CUSTOMIZE GRADING : {subjectInModal?.subject}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {requestInProgressModal ? (
              <div className={Styles.loaderWrapper}>
                <Spinner />
              </div>
            ) : subjectInModal && subjectInModal.is_scholastic ? (
              <div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Mid-Term
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <p className={Styles.textInputInternal}>
                            {maxMid.total}
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <p className={Styles.textInputInternal}>
                            {maxMid.external}
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxMid.total - maxMid.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksMidArray.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <p className={Styles.textInputInternal}>
                            {item.minMidRange}
                          </p>
                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxMidRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className={Styles.textInputInternal}>
                            {item.grade}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p
                    style={{
                      borderLeft: "2px solid black",
                      marginRight: "10px",
                    }}
                  ></p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Annual
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <p className={Styles.textInputInternal}>
                            {maxAnual.total}
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <p className={Styles.textInputInternal}>
                            {maxAnual.external}
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxAnual.total - maxAnual.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksArray.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <p className={Styles.textInputInternal}>
                            {item.minRange}
                          </p>
                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className={Styles.textInputInternal}>
                            {item.grade}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ fontWeight: "bold", color: "red" }}>
                The Max Marks feature does not apply to Non-Scholastic Subjects.
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

export default ManagementSingleClass;

import React from "react";
import { NavLink } from "react-router-dom";
import Styles from "./CertificateTypes.module.scss";
import { useToast } from "@chakra-ui/react";

const CertificateTypes = () => {
  const toast = useToast();
  const handletosted = () => {
    toast({
      title: "This certificate was disabled",
      variant: "left-accent",
      position: "top-right",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
  };

  return (
    <>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>CERTIFICATE | Certificate Total 6 </p>
        </div>
      </div>
   
      <div className={Styles.mainCartSection}>
      <div className={Styles.CartInLine}>
      <NavLink to="transferc">
          <div className={Styles.certificateCart}>
            <div className={Styles.certificate}>
              <img src="	https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
            </div>
            <p className={Styles.certificatename}>Transfer Certificate</p>
          </div>
        </NavLink>
        <NavLink to="bonafide">
          <div className={Styles.certificateCart}>
            <div className={Styles.certificate}>
              <img src="https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
            </div>
            <p className={Styles.certificatename}>Bonafide Certificate</p>
          </div>
        </NavLink>
        <NavLink to="conduct">
          <div className={Styles.certificateCart}>
            <div className={Styles.certificate}>
              <img src="https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
            </div>
            <p className={Styles.certificatename}>Conduct Certificate</p>
          </div>
        </NavLink>
      </div>
        <div  className={Styles.CartInLine}>
        <div onClick={() => handletosted()} className={Styles.certificateCart}>
          <div className={Styles.certificate}>
            <img src="	https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
          </div>
          <p className={Styles.certificatename}>Date of Birth Certificate</p>
        </div>

        <NavLink to="studyc">
          <div className={Styles.certificateCart}>
            <div className={Styles.certificate}>
              <img src="https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
            </div>
            <p className={Styles.certificatename}>Study Certificate</p>
          </div>
        </NavLink>

        <div onClick={() => handletosted()} className={Styles.certificateCart}>
          <div className={Styles.certificate}>
            <img src="https://smu-saas.web.app/static/media/Certificates.c0d212be1805f9779a92.png" />
          </div>
          <p className={Styles.certificatename}>Caste Certificate</p>
        </div>
        </div>

       
      
      </div>
    </>
  );
};

export default CertificateTypes;

import React from 'react'
import Styles from '../../MarksEntrySheets.module.scss';
import { Link } from 'react-router-dom';
import { Select } from '@chakra-ui/react';
// import NumericInput from 'react-numeric-input';

import axios from 'axios';

const MarksEntrySubjectTeacher = () => {

    const [students, setStudents] = React.useState([{
        'id': '6318b12d3994a7a0f29ec618',
        'name': 'Test',
        'internal': '',
        'external': '',
        'total': '',
        'grade': '',
    }])

    const [post, setPost] = React.useState(null);

    const [subject, setSubject] = React.useState('english');


    const handleFormChange = (event, index) => {

        // let { min, max } = event.target;
        const data = [...students];
        data[index][event.target.name] = event.target.value;

        data[index]['internal'] = Math.max(0, Math.min(20, Number(data[index]['internal'])));
        data[index]['external'] = Math.max(0, Math.min(80, Number(data[index]['external'])));

        if (data[index]['internal'] && data[index]['external']) {
            data[index]['total'] = Number(data[index]['internal']) + Number(data[index]['external']);

            if (data[index]['total'] > 90)
                data[index]['grade'] = 'A1'
            else if (data[index]['total'] > 80)
                data[index]['grade'] = 'A2'
            else if (data[index]['total'] > 70)
                data[index]['grade'] = 'B1'
            else if (data[index]['total'] > 60)
                data[index]['grade'] = 'B2'
            else if (data[index]['total'] > 50)
                data[index]['grade'] = 'C1'
            else if (data[index]['total'] > 40)
                data[index]['grade'] = 'C2'
            else if (data[index]['total'] > 32)
                data[index]['grade'] = 'D'
            else
                data[index]['grade'] = 'E'
        }

        setStudents(data);
    }

    React.useEffect(() => {
        console.log(post);
    }, [post]);

    const config = {
        headers: { "Authorization": `bearer ${window.localStorage.getItem('token')}` }
    };

    function updateMarks() {

        students.map((student) => {
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/teacher/exam/V1/update/${student.id}`, {
                    "year": 2020,
                    "exam": "anual",
                    "class": 2,
                    "subject": subject,
                    "marks": {
                        "internal": student.internal,
                        "external": student.external,
                        "total": student.total,
                        "grade": student.grade,
                    }
                },
                    config
                )
                .then((response) => {
                    setPost(response.data);
                    // console.log(post);
                })
                .catch((err) => { console.log(err) });
        })
    }

    return (
        <div>
            <div className={Styles.header}>
                <h1 className={Styles.headTitle}>Marks Entry Sheets</h1>

                <div className={Styles.dropDown}>
                    <Select onChange={(e) => setSubject(e.target.value)} name="class" placeholder='Select Subject'>
                        <option value='english'>English - 4A</option>
                        <option value='hindi'>Hindi - 5B</option>
                        <option value='kannada'>Kannada - 7A</option>
                        <option value='maths'>Maths - 8A</option>
                        <option value='science'>E.V.S. - 7B</option>
                        <option value='2'>Class Guide - 6A</option>
                        <option value='4'>Class Teacher - Maths - 6A</option>
                        <option value='4'>Class Teacher - Discipline - 6A</option>
                    </Select>
                </div>
                {/* <div className={Styles.dropDown}>
                    <Select initialValues={'mid term'} onClick={(e) => console.log(e.target.value)} name="class" placeholder='Select Exam Semester'>
                        <option value='0'>Mid-Term Exam</option>
                        <option value='1'>Annual Exam</option>
                    </Select>
                </div> */}
                <div className={Styles.indicator}>
                    <p>Completion :</p>
                    <div className={Styles.progress} />
                </div>
            </div>

            <div className={Styles.tableWrapper}>
                <form>
                    <table className={Styles.table}>
                        <tr>
                            <th>SL. No.</th>
                            <th>Name</th>
                            <th>Internal Assessment (20)</th>
                            <th>Final Exam (80)</th>
                            <th>Total (100)</th>
                            <th>Grade</th>
                        </tr>

                        {
                            students.map((student, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{student.name}</td>
                                        <td><input name='internal' type="number" value={student.internal} onChange={(e) => handleFormChange(e, index)} /></td>
                                        <td><input name='external' type='number' value={student.external} onChange={(e) => handleFormChange(e, index)} /></td>
                                        <td><input name='total' type='text' value={student.total} onChange={(e) => handleFormChange(e, index)} readOnly /></td>
                                        <td><input name='grade' type='text' value={student.grade} onChange={(e) => handleFormChange(e, index)} readOnly /></td>
                                    </tr>
                                )
                            })
                        }

                    </table>
                </form>
            </div>

            <div className={Styles.buttonWrapper}>
                <div className={Styles.buttonSubWrapper}>
                    <button className={Styles.button} onClick={() => { updateMarks() }} >Save</button>
                    <button className={Styles.button}>Edit</button>
                </div>
                <Link to='/staff/dashboard/exams/report' className={Styles.button}>Report card</Link>
            </div>
        </div>
    )
}

export default MarksEntrySubjectTeacher
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { requestEnrolledStudentById } from "services/admin-dashboard/transport.service";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "./SingleEnrolledStudent.module.scss";
import moment from "moment";
import { Button,
   Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, 
  ModalFooter,
  useDisclosure,
useToast} from "@chakra-ui/react";
import { requestUpdateTransportDetails} from "services/admin-dashboard/student.service";

function ManagementSingleEnrolledStudentPage() {
  const [inputFields, setInputFields] = useState({});
  const [eveningTime,setEveningTime] = useState("")
  const handleRemoveTransport = useDisclosure();
  const params = useParams();
  const toast = useToast()
  const navigate = useNavigate()
  const fetchEnrolledStudent = async () => {
    try {
      const response = await requestEnrolledStudentById({
        studentId: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        setInputFields(response);
        let event= response?.enroll?.evening&&response?.enroll?.evening
        let idx =event.indexOf(":");
        let hour = event.substring(0, idx);
        let minutes = event.substring(5, idx);
        // hour = Number(hour);
        let ampm;
        if (hour >= 0 && hour <= 11) {
          ampm = "AM";
        } else {
          if (hour == 12) {
            ampm = "PM";
            hour = parseInt(event);
            event = hour + minutes;
          } else {
            ampm = "PM";
            hour = parseInt(event) - 12;
            event = hour + minutes;
          }
        }
        let fullTime = event + " " + ampm;
        setEveningTime(fullTime)
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchEnrolledStudent();
  }, []);

  const handleRemoveStudentTransport = async()=>{
    try {
      const resp = await requestUpdateTransportDetails({
        studentId: inputFields.enroll.student_id._id,
        opted_value: false,
      });
      if (resp) {
        toast({
          // title: "Removed Succesfully",
          description: resp.msg,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "bottom-right",
        });
        navigate("/admin/dashboard/transport")
      }
    } catch (e) {
      toast({
        title: "Failed to remove",
        description:  e.msg ,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error(e);
    }

  }

  return (
    <div>
    <div className={Styles.containerHeader}>  <p className={Styles.Modalname}>
        {inputFields?.enroll?.name} | {inputFields?.enroll?.class}{" "}
        {inputFields?.enroll?.section}
      </p>
      <div>
        <Button className={Styles.button} onClick={()=>handleRemoveTransport.onOpen()}>Remove</Button>
      </div>
    </div>
    
      <div className={Styles.container}>
        <div className={Styles.containerItem}>
          <span className={Styles.itemHead}>Student Details</span>
          <div className={Styles.list}>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Date</p>
              <p>{moment(inputFields?.enroll?.date).format("DD-MM-YYYY")}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Morning Time</p>
              <p>{inputFields?.enroll?.morning} AM</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Evening Time</p>
              <p>{eveningTime}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}> Fee</p>
              <p>₹ {inputFields?.enroll?.amount}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Duration</p>
              <p>{inputFields?.enroll?.duration} Months</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Stop</p>
              <p>{inputFields?.enroll?.stop}</p>
            </div>
          </div>
        </div>
        <div className={Styles.containerItem}>
          <span className={Styles.itemHead}>Transport Details</span>
          <div className={Styles.list}>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Route Number</p>
              <p>{inputFields?.enroll?.route_number}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Bus Number</p>
              <p>{inputFields?.bus?.bus_number}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Bus Plate Number</p>
              <p>{inputFields?.bus?.bus_plate_number}</p>
            </div>
            {/* <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Route</p>
              <p>
                {inputFields?.bus?.from} to {inputFields?.bus?.to}
              </p>
            </div> */}
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Driver Name</p>
              <p>{inputFields?.bus?.driver_name}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Driver Phone</p>
              <p>{inputFields?.bus?.driver_phone}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Starting Location</p>
              <p>{inputFields?.bus?.from}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Ending Location</p>
              <p>{inputFields?.bus?.to}</p>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={handleRemoveTransport.isOpen} onClose={handleRemoveTransport.onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Payment Receipt Delete</ModalHeader> */}
          <ModalHeader style={{ color: "red", textAlign: "center" }}>
              <span className={Styles.asterisk}>*****</span> CRITICAL OPERATION{" "}
              <span className={Styles.asterisk}>*****</span>
            </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <p style={{
                    color: "red",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }} 
                  >
            Are you sure you want to remove the student from transport?
                  </p>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => handleRemoveStudentTransport()}
            >
              Yes
            </Button>
            <Button
              colorScheme="blue"
              ml={2}
              onClick={() => handleRemoveTransport.onClose()}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ManagementSingleEnrolledStudentPage;

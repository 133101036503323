import React,{ useState } from 'react'
import Styles from "./ManualTcPage.module.scss";
import {useNavigate} from "react-router-dom"
const initailvalue = {
    name:"",
    start_date:"",
    end_date:"",
    startingGrade:"",
    lastGrade:"",
    gender:""

}
const ManagementConductCertifiacte = () => {
  const [inputFields, setInputFields] = useState(null);
const navigate = useNavigate()
  const transferData = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };

  const onSubmit = () => {
    //   console.log("id is here",id)
    //   console.log("id", studentData)
    navigate(`conductdownload`, {
      state: {
        inputFields,
      },
    });
  };
  return <>
    <div className={Styles.allField}>
    <div className={Styles.tagContanier}>
        <p>Name :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="Name"
          type="text"
          name="name"
          onChange={(e) => transferData(e)}
        />
      </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>Start date :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="Start date"
          type="text"
          name="start_date"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>End date :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="End date"
          type="text"
          name="end_date"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>First Grade :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="First Grade"
          type="text"
          name="startingGrade"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>Last Grade :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="Last Grade"
          type="text"
          name="lastGrade"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>Gender :</p>
      <div className={Styles.inputTags}>
        <input
          required
          placeholder="Gender"
          type="text"
          name="gender"
          onChange={(e) => transferData(e)}
        />
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            onSubmit();
          }}
          style={{
            marginTop: "2vw",
            backgroundColor: "red",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
        >
          Generate
        </button>
      </div>
      </div>
  </>
}

export default ManagementConductCertifiacte

import React, { useState, useCallback, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Spinner,
  Textarea,
  Checkbox,
  Select,
  useToast,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useClassAndSections2 } from "hooks/classAndSection";
import ManagementDashboard from "services/management-dashboard/circular.service";
import {
  requestSearchStudentByFilterCommon,
  requestUploadCirculars,
} from "services/common.service";
import Styles from "./SingleCircular.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { VscFilePdf } from "react-icons/vsc";
import { MdOutlineAttachFile, MdPreview } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  AiOutlineDownload,
  AiFillLeftCircle,
  AiFillRightCircle,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { GrEdit } from "react-icons/gr";
import { GiCancel } from "react-icons/gi";
import defaultUser from "../../../assets/icons/User.png";
import moment from "moment";
import { BiUpArrowCircle } from "react-icons/bi";

const ManagementSingleCircular = () => {
  const params = useParams();
  const modalGrade = useDisclosure();
  const modalStudent = useDisclosure();
  const modalPreview = useDisclosure();
  const { classAndSections, loading } = useClassAndSections2();
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    description: "",
    date: "",
    media_url: [],
    new_media_url: [],
    category: "",
    target_group: [],
    circular_number: "",
    student_target_group: [],
  });

  const [searchStudents, setSearchStudents] = useState("");
  const [searchStudentList, setSearchStudentList] = useState([]);
  const [selectStudent, setSelectStudent] = useState([]);

  const toast = useToast();
  const [file, setFile] = useState(null);
  const [existingFile, setExistingFile] = useState([]);
  const [images, setImages] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [NewInputFields, setNewInputFields] = useState({
    New_target_group: [],
    New_student_target_group: [],
  });
  const [OriginalTargetGroup, setOriginalTargetGroup] = useState([]);
  const [isNewTGUpdated, setNewTGUpdated] = useState(false);
  const [OriginalStudentTargetGroup, setOriginalStudentTargetGroup] = useState(
    []
  );
  const [isNewTGStudentUpdated, setNewTGStudentUpdated] = useState(false);
  const [replace, setreplace] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const [isReplace, setIsReplace] = useState(false);
  const [isAppend, setIsAppend] = useState(false);
  const [newAdd, setNewAdd] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  const uploadFilesToCloud = async () => {
    try {
      const response = await requestUploadCirculars({
        title: inputFields.title,
        file: file,
        circular_number: inputFields.circular_number,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          file_url: Object.values(response),
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const handleSave = () => {
    if (NewInputFields.New_target_group.length > 0) {
      setInputFields({
        ...inputFields,
        target_group: NewInputFields.New_target_group,
      });
      setNewTGUpdated(true);
      modalGrade.onClose();
    }
  };
  const handleStudentsSave = () => {
    if (selectStudent.length > 0) {
      setInputFields({
        ...inputFields,
        student_target_group: selectStudent,
      });
      setNewTGStudentUpdated(true);
      modalStudent.onClose();
    }
  };
  const handleDiscardChanges = () => {
    setInputFields({ ...inputFields, target_group: OriginalTargetGroup });
    setNewTGUpdated(false);
    setNewInputFields({ New_student_target_group: [] });
  };

  const handleDiscardStudentChanges = () => {
    setInputFields({
      ...inputFields,
      student_target_group: OriginalStudentTargetGroup,
    });
    setNewTGStudentUpdated(false);
    setNewInputFields({ New_target_group: [] });
  };

  const handleCloseModal = () => {
    setNewInputFields({ New_target_group: [] });
    modalGrade.onClose();
    modalStudent.onClose();
  };

  const currentMonth = new Date().getMonth();

  const getMonthDates = (month) => {
    const dates = [];
    const currentYear = new Date().getFullYear();
    const daysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
    }
    return dates;
  };

  const highlightDates = getMonthDates(currentMonth);

  useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchCircularDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const fetchCircularDetails = async () => {
    try {
      const response = await ManagementDashboard.requestCircularById({
        circular_id: params.id,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        const DATE = response?.date;
        let date = DATE && new Date(DATE).toLocaleDateString();
        if (date == "Invalid Date") {
          if (DATE.includes("-")) {
            date = new Date(DATE.split("-").reverse().join("/"));
          } else if (DATE.includes("/")) {
            const [day, month, year] = DATE.split("/");
            const dateString = [month, day, year].join("/");
            date = new Date(dateString);
          }
        } else {
          if (typeof new Date(DATE) === "object") {
            date = new Date(DATE);
          } else {
            date = DATE;
          }
        }
        const mediaUrls = response?.media || [];
        let allImages = [];
        let allFiles = [];

        for (let i = 0; i < response.media.length; i++) {
          const item = response.media[i];
          const fileUrl = item.media_url;

          if (fileUrl) {
            if (fileUrl.includes(".pdf")) {
              allFiles.push(fileUrl);
            } else {
              allImages.push(fileUrl);
            }
          }
        }
        setExistingFile(allFiles);
        setImages(allImages);

        const category =
          response.category[0].toUpperCase() +
          response.category.slice(1).toLowerCase();
        setInputFields({
          title: response.title,
          description: response.description,
          date: date,
          target_group: response.target_group,
          category: category,
          media_url: mediaUrls,
          circular_number: response.circular_number,
          student_target_group: response.student_target_group,
        });
        setOriginalTargetGroup(response.target_group);
        setOriginalStudentTargetGroup(response.student_target_group);
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const handleDelete = async (event) => {
    event.preventDefault();
    setRequestInProgress(true);
    try {
      const response = await ManagementDashboard.requestDeleteCircular({
        circular_id: params.id,
      });
      if (response) {
        setRequestInProgress(false);
        toast({
          title: "Deleted Succesfully",
          description: "Deleted Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/management/dashboard/circular");
      }
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Delete request failed",
        description: "Delete Failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const fetchStudent = async () => {
    const response = await requestSearchStudentByFilterCommon({
      name: searchStudents,
      class: "ALL",
      section: "ALL",
    });
    setSearchStudentList(searchStudents == "" ? [] : response);
  };

  const handleCheckStudents = (student) => {
    if (selectStudent.includes(student)) {
      let tempStudent = [...selectStudent];
      let index = tempStudent.indexOf(student);
      tempStudent.splice(index, 1);
      setSelectStudent(tempStudent);
    } else {
      setSelectStudent([...selectStudent, student]);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      fetchStudent();
    }, 3000);
  }, [searchStudents]);

  const checkedStudent = (student) => {
    let isPresent = selectStudent.filter((item) => item._id == student._id);
    console.log(isPresent);
  };

  const handleInputChange = useCallback(
    (e) => {
      if (typeof e?.currentTarget !== "undefined") {
        const {
          currentTarget: { name, value, files },
        } = e;
        const values = { [name]: value };
        if (files !== null && typeof files !== "undefined") {
          values[name] = files;
        }
        setInputFields({ ...inputFields, [name]: values[name] });
      } else {
        setInputFields({ ...inputFields, date: e });
      }
    },
    [inputFields, setInputFields]
  );
  const handleCheckNewTargetGroup = (event) => {
    let class_array = [...NewInputFields.New_target_group];
    if (event.target.checked) {
      if (event.target.value === "All") class_array = [event.target.value];
      else {
        if (class_array.includes("All")) class_array = ["All"];
        else
          class_array = [
            ...NewInputFields.New_target_group,
            event.target.value,
          ];
      }
    } else {
      class_array.splice(class_array.indexOf(event.target.value), 1);
    }

    setNewInputFields({ ...NewInputFields, New_target_group: class_array });
  };

  const handleCheck = (event) => {
    event.preventDefault();
    let class_array = [...inputFields.target_group];
    if (event.target.checked) {
      if (event.target.value === "All") class_array = [event.target.value];
      else {
        if (class_array.includes("All")) class_array = ["All"];
        else class_array = [...inputFields.target_group, event.target.value];
      }
    } else {
      inputFields.target_group.splice(
        inputFields.target_group.indexOf(event.target.value),
        1
      );
    }

    setInputFields({ ...inputFields, target_group: class_array });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let fileResponse;
    if (file?.length > 0) {
      fileResponse = await uploadFilesToCloud();
    }

    try {
      setRequestInProgress(true);
      if (replace === true) {
        const requestPayload = {
          circular_id: params.id,
          title: inputFields.title,
          description: inputFields.description,
          date:
            inputFields.date && moment(inputFields.date).format("MM/DD/YYYY"),
          media: [],
          new_media: fileResponse?.file_url,
          student_target_group: [
            ...selectStudent,
            ...inputFields.student_target_group,
          ],
          category: inputFields.category,
          target_group: inputFields.target_group,
          circular_number: inputFields.circular_number,
        };
        const response = await ManagementDashboard.requestUpdateCircular(
          requestPayload
        );
        if (response) {
          if (response.error) {
            console.error(response.error);
          } else {
            toast({
              title: "Updated Succesfully",
              description: "Updated Circular",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
            navigate("/management/dashboard/circular");
          }
        }
      }
      if (removeImage == true) {
        const requestPayload = {
          circular_id: params.id,
          title: inputFields.title,
          description: inputFields.description,
          date:
            inputFields.date && moment(inputFields.date).format("MM/DD/YYYY"),
          new_media: [],
          media: [],
          student_target_group: [
            ...selectStudent,
            ...inputFields.student_target_group,
          ],
          category: inputFields.category,
          target_group: inputFields.target_group,
          circular_number: inputFields.circular_number,
        };
        const response = await ManagementDashboard.requestUpdateCircular(
          requestPayload
        );
        if (response) {
          if (response.error) {
            console.error(response.error);
          } else {
            toast({
              title: "Updated Succesfully",
              description: "Updated Circular",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
            navigate("/management/dashboard/circular");
          }
        }
      }
      if (replace == false) {
        const requestPayload = {
          circular_id: params.id,
          title: inputFields.title,
          description: inputFields.description,
          date:
            inputFields.date && moment(inputFields.date).format("MM/DD/YYYY"),
          new_media: fileResponse?.file_url,
          student_target_group: [
            ...selectStudent,
            ...inputFields.student_target_group,
          ],
          category: inputFields.category,
          target_group: inputFields.target_group,
          circular_number: inputFields.circular_number,
        };
        const response = await ManagementDashboard.requestUpdateCircular(
          requestPayload
        );
        if (response) {
          if (response.error) {
            console.error(response.error);
          } else {
            toast({
              title: "Updated Succesfully",
              description: "Updated Circular",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
            navigate("/management/dashboard/circular");
          }
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };

  const handleFileChange = (e, fieldName) => {
    if (fieldName === "isReplace") {
      setIsReplace(true);
      setIsAppend(false);
    }
    if (fieldName === "isAppend") {
      setIsReplace(false);
      setIsAppend(true);
    }
    if (fieldName === "isAddNew") {
      setNewAdd(true);
    }
    const chosenFile = Array.prototype.slice.call(e.target.files);
    setFile(chosenFile);
    const chosenFiles = Array.from(e.target.files);
    setSelectedImages(chosenFiles);
  };

  const highlightSundays = (date) => {
    return date.getDay() === 0;
  };

  const handleDownload = (imageUrl, index) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const title = inputFields.title;
        const filename = `${title} Circular image ${index + 1}.jpg`;
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image: ", error);
      });
  };
  const handleRemoveAttachment = () => {
    setImages([]);
    setAllFiles([]);
    setExistingFile([]);
    setSelectedImages([]);
    setIsReplace(false);
    setIsAppend(false);
    setNewAdd(true);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isAllSelected = inputFields.target_group.every((targetClass) =>
    classAndSections.some(
      (grade) => grade.class.toLowerCase() === targetClass.toLowerCase()
    )
  );
  return (
    <div className={Styles.body}>
      <div className={Styles.row}>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>Circular No.: </p>
          <input
            onChange={handleInputChange}
            type="text"
            id="circular_number"
            name="circular_number"
            placeholder="Enter Circular No...."
            className={`${Styles.textInput} ${Styles.inputField}`}
            value={inputFields.circular_number}
          />
        </div>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>
            Title <span className={Styles.asterisk}>*</span>
          </p>
          <Textarea
            onChange={handleInputChange}
            required
            type="text"
            id="title"
            name="title"
            placeholder="Enter Title..."
            className={`${Styles.textInput} ${Styles.titleArea}`}
            value={inputFields?.title}
            resize="vertical"
            minHeight="30px"
            overflow="hidden"
          />
        </div>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>
            Description <span className={Styles.asterisk}>*</span>
          </p>
          <Textarea
            id="description"
            name="description"
            className={Styles.textInput}
            value={inputFields?.description}
            onChange={handleInputChange}
            placeholder="Enter Description..."
            size="lg"
            resize="vertical"
            required
          />
        </div>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>
            Category <span className={Styles.asterisk}>*</span>
          </p>
          <Select
            value={inputFields?.category}
            required
            onChange={handleInputChange}
            name="category"
            placeholder="Select Category"
          >
            <option value="General">General</option>
            <option value="Fees">Fees</option>
            <option value="Exam">Exam</option>
            <option value="Event">Event</option>
            <option value="Other">Other</option>
          </Select>
        </div>
        {console.log("classAndSections", classAndSections)}
        {isNewTGUpdated ? (
          <div className={Styles.formCheck}>
            <p className={Styles.placeholder}>Target Classes</p>
            <div className={Styles.checkboxes}>
              {classAndSections.map((grade, idx) => (
                <Checkbox
                  readOnly
                  id="target_group"
                  name="target_group"
                  // isChecked={inputFields?.target_group.some(
                  //   (targetClass) =>
                  //     targetClass.toLowerCase() === grade.class.toLowerCase()
                  // )}
                  isChecked={inputFields?.target_group.includes(grade.class)}
                  colorScheme="red"
                  key={grade.class + idx}
                  value={grade.class}
                >
                  <p className={Styles.checkboxText}>{grade.class}</p>
                </Checkbox>
              ))}
            </div>
            <div onClick={handleDiscardChanges} className={Styles.editIcon}>
              <span className={Styles.pencilIcon}>
                <GiCancel />
              </span>
              <span>Discard Changes</span>
            </div>
          </div>
        ) : (
          <div className={Styles.formCheck}>
            <p className={Styles.placeholder}>Targets Classes</p>
            <div className={Styles.checkboxes}>
              {classAndSections.map((grade, idx) => (
                <Checkbox
                  disabled
                  id="target_group"
                  name="target_group"
                  isChecked={
                    isAllSelected && grade.class.toLowerCase() === "all"
                  }
                  // isChecked={inputFields?.target_group.includes(grade.class)}
                  onChange={handleCheck}
                  colorScheme="red"
                  key={grade.class + idx}
                  value={grade.class}
                >
                  <p className={Styles.checkboxText}>{grade.class}</p>
                </Checkbox>
              ))}
            </div>
            <div onClick={modalGrade.onOpen} className={Styles.editIcon}>
              <span className={Styles.pencilIcon}>
                <GrEdit />
              </span>
              <span>Edit Target Group</span>
            </div>
          </div>
        )}
        {isNewTGStudentUpdated ? (
          <div className={Styles.formCheck}>
            <p className={Styles.placeholder}>Target Students</p>
            <div className={Styles.checkboxes}>
              {selectStudent.map((student, idx) => (
                <Checkbox
                  id="target_group"
                  name="target_group"
                  isChecked={inputFields?.student_target_group}
                  colorScheme="red"
                >
                  <p
                    className={Styles.checkboxText}
                    style={{ marginRight: "5px", width: "200px" }}
                  >
                    {student.name || "--"} | {student.academic.class || "--"}
                    {student.academic.section || "--"} | {student.phoneNumber}
                  </p>
                </Checkbox>
              ))}
            </div>
            <div
              onClick={handleDiscardStudentChanges}
              className={Styles.editIcon}
            >
              <span className={Styles.pencilIcon}>
                <GiCancel />
              </span>
              <span>Discard Changes</span>
            </div>
          </div>
        ) : (
          <div className={Styles.formCheck}>
            <p className={Styles.placeholder}>Target Students</p>
            <div className={Styles.checkboxes}>
              {inputFields?.student_target_group.map((student, idx) => (
                <Checkbox
                  disabled
                  id="target_group"
                  name="target_group"
                  isChecked={inputFields?.student_target_group}
                  colorScheme="red"
                >
                  <p
                    className={Styles.checkboxText}
                    style={{ marginRight: "5px", width: "200px" }}
                  >
                    {student.name || "--"} | {student.academic.class || "--"}
                    {student.academic.section || "--"} | {student.phoneNumber}
                  </p>
                </Checkbox>
              ))}
            </div>
            <div className={Styles.editIcon} onClick={modalStudent.onOpen}>
              <span className={Styles.pencilIcon}>
                <GrEdit />
              </span>
              <span>Edit Student Group</span>
            </div>
          </div>
        )}

        <div className={Styles.form}>
          <p className={Styles.placeholder}>Update Circular Attachment</p>
          {images?.length > 0 || existingFile?.length > 0 ? (
            <div className={Styles.buttonImage}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                {isAppend ? (
                  <Tooltip
                    label="Preview Selected Images/PDF."
                    placement="bottom"
                  >
                    <span className={Styles.pencilIcon}>
                      <MdPreview
                        onClick={modalPreview.onOpen}
                        style={{ fontSize: "35px" }}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
                <label htmlFor="append_image">
                  <div
                    style={{
                      backgroundColor: "#f9cbca",
                      border: "1px solid orchid",
                      borderRadius: "5px",
                      padding: " 5px",
                      marginTop: "8px",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  >
                    Append Attachment
                  </div>
                  <input
                    id="append_image"
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      handleFileChange(e, "isAppend");
                    }}
                  />
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "10px",
                }}
              >
                {isReplace ? (
                  <span className={Styles.pencilIcon}>
                    <MdPreview
                      onClick={modalPreview.onOpen}
                      style={{ fontSize: "40px" }}
                    />
                  </span>
                ) : (
                  ""
                )}
                <label htmlFor="replace_image">
                  <div
                    style={{
                      backgroundColor: "#f9cbca",
                      border: "1px solid orchid",
                      borderRadius: "5px",
                      padding: " 5px",
                      marginTop: "8px",
                      cursor: "pointer",
                    }}
                  >
                    Replace Attachment
                  </div>
                  <input
                    id="replace_image"
                    type="file"
                    multiple
                    hidden
                    onChange={(e) => {
                      handleFileChange(e, "isReplace");
                      setreplace(true);
                    }}
                  />
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <label>
                  <div
                    style={{
                      backgroundColor: "#f9cbca",
                      border: "1px solid orchid",
                      borderRadius: "5px",
                      padding: " 5px",
                      marginTop: "8px",
                      cursor: "pointer",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      handleRemoveAttachment();
                      setRemoveImage(true);
                    }}
                  >
                    Remove Attachment
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {isAppend ||
              (newAdd && (allFiles.length > 0 || selectedImages.length > 0)) ? (
                <Tooltip
                  label="Preview Selected Images/PDF."
                  placement="bottom"
                >
                  <span className={Styles.pencilIcon}>
                    <MdPreview
                      onClick={modalPreview.onOpen}
                      style={{ fontSize: "35px" }}
                    />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
              <label htmlFor="addNew_image">
                <div
                  style={{
                    backgroundColor: "#f9cbca",
                    border: "1px solid orchid",
                    borderRadius: "5px",
                    padding: " 5px",
                    marginTop: "8px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                >
                  Add Attachment
                </div>
                <input
                  id="addNew_image"
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => {
                    handleFileChange(e, "isAddNew");
                  }}
                />
              </label>
            </div>
          )}
        </div>
        <div className={Styles.formDate}>
          <p className={Styles.placeholder}>
            Date <span className={Styles.asterisk}>*</span>
          </p>
          <DatePicker
            required
            selected={inputFields.date}
            value={inputFields?.date}
            onChange={handleInputChange}
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            dayClassName={(date) =>
              highlightSundays(date) ? "react-datepicker__day--sunday" : null
            }
            highlightDates={highlightDates}
            className={Styles.inputField}
          />
        </div>
        <div className={Styles.coverImage}>
          {images.length > 0 ? (
            <Carousel
              showThumbs={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                      left: 25,
                      fontSize: "50px",
                      color: "#990f0d",
                    }}
                    className={Styles.leftCircle}
                  >
                    <AiFillLeftCircle />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                      right: 25,
                      fontSize: "50px",
                      color: "#990f0d",
                    }}
                    className={Styles.rightCircle}
                  >
                    <AiFillRightCircle />
                  </button>
                )
              }
            >
              {images.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item}
                      className={Styles.carouselImg}
                      alt="attachment-img"
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "25px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDownload(item, index)}
                    >
                      <AiOutlineDownload style={{ fontSize: "40px" }} />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          ) : (
            ""
          )}
        </div>
        {existingFile
          ? existingFile.map((element, idx) => {
              if (element.includes(".pdf")) {
                const pdfUrl = element;
                return (
                  <a
                    href={pdfUrl}
                    target={idx === 0 ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    className={Styles.attachment}
                    key={idx}
                  >
                    <p className={Styles.placeholder}>
                      Attachment {idx + 1}
                      <MdOutlineAttachFile style={{ display: "inline" }} />
                    </p>
                    <VscFilePdf className={Styles.pdfLink} />
                  </a>
                );
              }
            })
          : ""}
        <div className={Styles.buttonRow}>
          <button onClick={handleFormSubmit} className={Styles.button2}>
            {requestInProgress ? <Spinner /> : "Update"}
          </button>
          <button onClick={() => setAlertOpen(true)} className={Styles.button}>
            {requestInProgress ? <Spinner /> : "Delete"}
          </button>
        </div>
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Circular
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={modalPreview.isOpen} onClose={modalPreview.onClose}>
        <ModalOverlay />
        <ModalContent style={{ position: "sticky", top: "50px" }}>
          <ModalHeader>Selected images/pdf</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            {selectedImages.map((file, index) => (
              <div key={index}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Selected Image ${index}`}
                    style={{
                      border: "2px solid black",
                      width: "400px",
                      height: "200px",
                      marginBottom: "20px",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                      marginBottom: "20px",
                      border: "2px solid black",
                    }}
                  >
                    <AiOutlineFilePdf
                      style={{
                        marginRight: "5px",
                        color: "red",
                        fontSize: "20px",
                      }}
                    />
                    {file.name}
                  </div>
                )}
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalPreview.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalGrade.isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Target Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.formCheck} style={{ width: "100%" }}>
              <p className={Styles.placeholder}>Target Classes</p>
              <div className={Styles.checkboxes}>
                {classAndSections.map((grade, idx) => (
                  <Checkbox
                    disabled={
                      NewInputFields?.New_target_group?.includes("All") &&
                      grade.class !== "All"
                        ? true
                        : false
                    }
                    id="New_target_group"
                    name="New_target_group"
                    colorScheme="red"
                    key={grade.class + idx}
                    value={grade.class}
                    onChange={handleCheckNewTargetGroup}
                  >
                    <p className={Styles.checkboxText}>{grade.class}</p>
                  </Checkbox>
                ))}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="ghost" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalStudent.isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Student Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div className={Styles.formCheck} style={{ width: "100%" }}>
                <div style={{ marginBottom: "5px" }}>
                  <p style={{ fontWeight: "bold" }}>Search Students</p>
                  <input
                    onChange={(e) => setSearchStudents(e.target.value)}
                    type="text"
                    id="search_student"
                    name="search_student"
                    placeholder="Search "
                    className={Styles.textInput}
                    value={searchStudents}
                  />
                </div>
                {searchStudentList.length === 0 ? (
                  <div></div>
                ) : (
                  <div
                    style={{
                      maxHeight: "120px",
                      overflow: "auto",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {searchStudentList.map((student) => (
                      <div className={Styles.searchStudentList}>
                        <div style={{ display: "flex", gap: 2 }}>
                          <img
                            className={Styles.profileImg}
                            src={
                              student?.url != "N/A" ? student.url : defaultUser
                            }
                            alt="student-profile"
                          />
                          <p>
                            {student.name || "--"} |{" "}
                            {student.academic.class || "--"}{" "}
                            {student.academic.section || "--"} |{" "}
                            {student.phoneNumber}
                          </p>
                        </div>
                        <div>
                          <Checkbox
                            colorScheme="red"
                            onChange={() => handleCheckStudents(student)}
                            isChecked={selectStudent.includes(student)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {selectStudent.length === 0 ? (
                <div></div>
              ) : (
                <>
                  <hr
                    style={{
                      width: "94%",
                      marginLeft: "10px",
                      marginTop: "10px",
                      border: "2px solid",
                      marginBottom: "10px",
                    }}
                  />
                  <div className={Styles.formCheck} style={{ width: "100%" }}>
                    <p style={{ fontWeight: "bold" }}>Selected Student</p>
                    <div
                      style={{
                        maxHeight: "120px",
                        overflow: "auto",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {selectStudent.map((student) => (
                        <div className={Styles.searchStudentList}>
                          {console.log("selectStudent", selectStudent)}
                          <div style={{ display: "flex", gap: 2 }}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student?.url != "N/A"
                                  ? student.url
                                  : defaultUser
                              }
                              alt="student-profile"
                            />
                            <p>
                              {student.name || "--"} |{" "}
                              {student.academic.class || "--"}
                              {student.academic.section || "--"} |{" "}
                              {student.phoneNumber}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleStudentsSave}>
              Save
            </Button>
            <Button colorScheme="blue" onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManagementSingleCircular;

import React, { useEffect } from "react";
import Styles from "./Profile.module.scss";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import {
  Select,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Services from "services/staff-dashboard/teacher.service";
import {
  updatePassword,
  getAuth,
  updateEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import defaultUser from "../../../assets/icons/User.png";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { useState } from "react";

const intialValue = {
  emp_id: "",
  name: "",
  designation: "",
  aadhar_number: "",
};
const StaffProfile = () => {
  const toast = useToast();
  const [teacherData, setTeacherData] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const classesHandled = useDisclosure();
  const [isEmployeeDetailsVisible, setIsEmployeeDetailsVisible] =
    useState(true);
  const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] =
    useState(true);
  const [isOtherDetailsVisible, setIsOtherDetailsVisible] = useState(true);
  const [isAcademicDetailsVisible, setIsAcademicDetailsVisible] =
    useState(true);
  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/teacher/profile/getTeacher/${window.localStorage.getItem(
          "teacher_id"
        )}`,
        {},
        config
      )
      .then((response) => {
        setTeacherData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onTeacherValueChange = (e, parentField) => {
    if (typeof e.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, type, checked },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        password: value,
        "select-one": value,
      }[type];

      if (parentField && typeof parentField === "string") {
        setTeacherData((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setTeacherData((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else {
      if (parentField && typeof parentField === "object") {
        const [parent, child] = parentField;
        setTeacherData((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: e,
          },
        }));
      }
    }
  };

  const updateTeacherProfiles = async (e) => {
    const payload = {
      personel: {
        gender: teacherData.personel.gender,
        mother_tongue: teacherData.personel.mother_tongue,
        religion: teacherData.personel.religion,
        sub_religion: teacherData.personel.sub_religion,
        present_address: teacherData.personel.present_address,
        permanent_address: teacherData.personel.permanent_address,
        blood_group: teacherData.personel.blood_group,
        aadhar_number: teacherData.personel.aadhar_number,
        qualification: teacherData.personel.qualification,
        martial_status: teacherData.personel.martial_status,
        profile_url: teacherData.personel.profile_url,
        emergency_contact_no: teacherData.personel.emergency_contact_no,
        nationlity: teacherData.personel.nationlity,
        dob: teacherData.personel.dob,
      },
      email: teacherData.email,
      emp_id: teacherData.emp_id,
      department: teacherData.department,
      designation: teacherData.designation,
      phoneNumber: teacherData.phoneNumber,
      name: teacherData.name,
      academic: teacherData.academic,
      class_teacher: teacherData.class_teacher,
    };
    try {
      const updateresponse = await Services.requestUpdateTeacherrProfile(
        payload
      );
      if (updateresponse) {
        toast({
          title: "Staff details updated",
          description: "Updated details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      console.error(err);
      toast({
        title: "Request Failed",
        description: "Update Request failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleUpadtePassword = async () => {
    const Auth = getAuth();
    const user = Auth.currentUser;
    const Password = newPassword;
    try {
      const response = await updatePassword(user, Password)
        .then(() => {
          toast({
            title: "Staff details updated",
            description: "Password Upadted",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Request Failed",
            description: " Password Update Request failed",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handleEmailUpdate = async () => {
    const Auth = getAuth();
    const user = Auth.currentUser;
    const Email = teacherData.email;
    updateEmail(user, Email)
      .then(() => {
        toast({
          title: "Staff details updated",
          description: "Email Updated",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        toast({
          title: "Request Failed",
          description: "Update Request failed",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        console.error(error);
      });
  };
  const updatePasswordViaEmail = async () => {
    const Auth = getAuth();
    const user = Auth.currentUser;
    const Email = teacherData.email;
    sendPasswordResetEmail(Auth, Email)
      .then(() => {
        toast({
          title: "We've sent an email on your account",
          // description: "Email Updated",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        toast({
          title: "having a some while sending a email",
          // description: "Update Request failed",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };
  return teacherData ? (
    <div className={Styles.body}>
      <div className={Styles.profile}>
        <img
          className={Styles.profileImage}
          src={teacherData.url != "N/A " ? teacherData.url : defaultUser}
          alt="profile"
        />
      </div>
      <form>
        <div className={Styles.forms}>
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Personal Details
            </button>
            {isPersonalDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(true)}
              />
            )}
          </div>
          {isPersonalDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Name</p>
                  <input
                    name="name"
                    onChange={(e) => onTeacherValueChange(e, "")}
                    value={teacherData.name}
                  />
                </div>

                <div className={Styles.pform}>
                  <p className={Styles.placeholder}>Email</p>
                  <div className={Styles.passwordCol}>
                    <input
                      name="email"
                      onChange={(e) => onTeacherValueChange(e, "")}
                      value={teacherData.email}
                      type="text"
                      placeholder="value"
                    />
                    {/* <Button onClick={()=>handleEmailUpdate()}><AiFillRightCircle/></Button> */}
                  </div>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Phone</p>
                  <input
                    name="phoneNumber"
                    onChange={(e) => onTeacherValueChange(e, "")}
                    value={teacherData.phoneNumber}
                    required
                    type="text"
                    placeholder="XXXXX XXXXX"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Gender</p>
                  <Select
                    name="gender"
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.gender}
                    required
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Qualification</p>
                  <input
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    type="text"
                    placeholder="value"
                    name="qualification"
                    value={teacherData.personel.qualification}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Aadhaar Number</p>
                  <input
                    name="aadhar_number"
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.aadhar_number}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Permanent Address</p>
                  <textarea
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    name="permanent_address"
                    value={teacherData.personel.permanent_address}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Present Address</p>
                  <textarea
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    name="present_address"
                    value={teacherData.personel.present_address}
                    className={Styles.textInput}
                  />
                </div>
              </div>
            </>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Employee Details
            </button>
            {isEmployeeDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(true)}
              />
            )}
          </div>
          {isEmployeeDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>Employee ID</p>
                <input
                  name="emp_id"
                  onChange={(e) => onTeacherValueChange(e, "")}
                  value={teacherData.emp_id}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>Designation</p>
                <input
                  name="designation"
                  onChange={(e) => onTeacherValueChange(e, "")}
                  value={teacherData.designation}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>Department</p>
                <input value={teacherData.department} />
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button className={Styles.heading} type="button">
              Other Details
            </button>
            {isOtherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(true)}
              />
            )}
          </div>
          {isOtherDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Blood Group</p>
                  <Select
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    name="blood_group"
                    value={teacherData.personel?.blood_group}
                    placeholder="Select Blood Group"
                  >
                    <option value="A(+VE)">A(+VE)</option>
                    <option value="B(+VE)">B(+VE)</option>
                    <option value="A(-VE)">A(-VE)</option>
                    <option value="B(-VE)">B(-VE)</option>
                    <option value="O(+VE)">O(+VE)</option>
                    <option value="O(-VE)">O(-VE)</option>
                    <option value="AB(+VE)">AB(+VE)</option>
                    <option value="AB(-VE)">AB(-VE)</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Martial Status</p>
                  <Select
                    name="martial_status"
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.martial_status}
                    placeholder="Select marital status"
                  >
                    <option value="Married">Married</option>
                    <option value="Unmarried">Unmarried</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Date of Birth</p>
                  <input
                    name="dob"
                    onChange={(e) => onTeacherValueChange(e, ["personel"])}
                    value={
                      teacherData.personel.dob
                        ? new Date(teacherData.personel.dob).toLocaleDateString(
                            "en-GB"
                          )
                        : "-"
                    }
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Nationality</p>
                  <Select
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.nationlity}
                    placeholder="Select Nationality"
                    name="nationlity"
                  >
                    <option value="INDIAN">INDIAN</option>
                    <option value="IRAQ">IRAQ</option>
                    <option value="ITALY">ITALY</option>
                    <option value="JAPAN">JAPAN</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Mother Tongue</p>
                  <input
                    name="mother_tongue"
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.mother_tongue}
                    required
                    type="text"
                    placeholder="value"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Religion</p>
                  <Select
                    value={teacherData.personel.religion}
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    placeholder="Select Religion"
                  >
                    <option value="HINDU">HINDU</option>
                    <option value="MUSLIM">MUSLIM</option>
                    <option value="CHRISTIAN">CHRISTIAN</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Sub-Religion</p>
                  <input
                    name="sub_religion"
                    onChange={(e) => onTeacherValueChange(e, "personel")}
                    value={teacherData.personel.sub_religion}
                    required
                    type="text"
                    placeholder="value"
                  />
                </div>
              </div>
            </>
          )}
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Academic Details
            </button>
            {isAcademicDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsAcademicDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsAcademicDetailsVisible(true)}
              />
            )}
          </div>
          {isAcademicDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.formClassConatiner}>
                <Button
                  mr={3}
                  colorScheme="blue"
                  onClick={() => classesHandled.onOpen()}
                >
                  Subjects Handled
                </Button>
              </div>
              <div className={Styles.pform}>
                <p className={Styles.placeholder}>Password</p>
                <div className={Styles.passwordCol}>
                  {/* <input 
              name="aadhar_number"
                onChange={(e)=>setNewPassword(e.target.value)}
                placeholder="***********"
              />
              <Button onClick={()=>handleUpadtePassword()}><AiFillRightCircle/></Button> */}
                  <Button onClick={() => updatePasswordViaEmail()}>
                    change password
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className={Styles.updateButton}>
            {/* <Button onClick={()=>updateTeacherProfiles()} className={Styles.updateTeacher}>Update</Button> */}
          </div>
        </div>
      </form>
      <Modal isOpen={classesHandled.isOpen} onClose={classesHandled.onClose}>
        <ModalOverlay />
        <ModalContent className={Styles.customModalContent}>
          <ModalHeader style={{ textAlign: "center" }}>
            Subjects Handled ({teacherData?.academic?.length})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {teacherData?.academic?.length > 0 ? (
              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                {teacherData?.academic?.map((teacher, index) => (
                  <div className={Styles.handleClassList}>
                    <div key={index} className={Styles.handleClasses}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          Subject Name :
                        </span>{" "}
                        {teacher.subject}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Class : </span>
                        {teacher.class} | {teacher.section}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ fontWeight: "bold" }}>Not handled any class.</p>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  ) : (
    ""
  );
};

export default StaffProfile;

import React, { useState, useEffect } from "react";
import Admission from "assets/icons/Admission .png";
import Styles from "./ReportCentre.module.scss";
import { Select, Checkbox, useToast, Button } from "@chakra-ui/react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import { requestAdmissionStudentDetails } from "services/management-dashboard/admission.service";
const AdmissionReport = () => {
  const { classAndSections } = useClassAndSectionsWithoutAll();
  const [inputFields, setInputFields] = useState({});
  const [sections, setSections] = useState([]);
  const [showDetails, setShowDetails] = useState({
    personal: true,
    father: false,
    mother: false,
  });
  const initialFieldsState = {
    admissionNumber: true,
    admissionYear: true,
    name: true,
    class: true,
    section: true,
    email: true,
    gender: true,
    fatherName: true,
    fatherPhone: true,
    fatherQualification: false,
    fatherOccupation: false,
    motherName: true,
    motherPhone: true,
    motherQualification: false,
    motherOccupation: false,
    dob: false,
    nationality: false,
    motherTongue: false,
    religion: false,
    subReligion: false,
    bloodGroup: true,
    address: false,
    aadhaarNo: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    if (!inputFields.class || !inputFields.section) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestAdmissionStudentDetails(payload);
    if (res) {
      let newArray = [];
      res.map((item) => {
        let obj = {};
        if (fields.admissionNumber) {
          obj["Admission ID"] = handleNAValue(item.academic.admission_number);
        }
        if (fields.admissionYear) {
          obj["Admission Year"] = handleNAValue(item.academic.admission_year);
        }
        if (fields.class) {
          obj["Class"] = handleNAValue(item.academic.class);
        }
        if (fields.section) {
          obj["Section"] = handleNAValue(item.academic.section);
        }
        if (fields.name) {
          obj["Name"] = handleNAValue(item.name);
        }
        if (fields.email) {
          obj["Email"] = handleNAValue(item.email);
        }
        if (fields.gender) {
          obj["Gender"] = handleNAValue(item.personel.gender);
        }
        if (fields.aadhaarNo) {
          obj["Aadhaar No."] = handleNAValue(item.personel.aadhar_number);
        }
        if (fields.address) {
          obj["Address"] = handleNAValue(item.personel.present_address);
        }
        if (fields.bloodGroup) {
          obj["Blood Group"] = handleNAValue(item.personel.blood_group);
        }
        if (fields.dob) {
          obj["DOB"] = handleNAValue(
            moment(item.personel.dob, "MM/DD/YYYY").format("DD/MM/YYYY")
          );
        }
        if (fields.nationality) {
          obj["Nationality"] = handleNAValue(item.personel.nationality);
        }
        if (fields.motherTongue) {
          obj["Mother Tongue"] = handleNAValue(item.personel.mother_tongue);
        }
        if (fields.religion) {
          obj["Religion"] = handleNAValue(item.personel.religion);
        }
        if (fields.subReligion) {
          obj["Sub-Religion"] = handleNAValue(item.personel.sub_religion);
        }
        if (fields.fatherName) {
          obj["Father Name"] = handleNAValue(item.father.father_name);
        }
        if (fields.fatherPhone) {
          obj["Father Phone No."] = handleNAValue(item.father.father_phone);
        }
        if (fields.fatherQualification) {
          obj["Father Qualification"] = handleNAValue(
            item.father.father_qualification
          );
        }
        if (fields.fatherOccupation) {
          obj["Father Occupation"] = handleNAValue(
            item.father.father_occupation
          );
        }
        if (fields.motherName) {
          obj["Mother Name"] = handleNAValue(item.mother.mother_name);
        }
        if (fields.motherPhone) {
          obj["Mother Phone No."] = handleNAValue(item.mother.mother_phone);
        }
        if (fields.motherQualification) {
          obj["Mother Qualification"] = handleNAValue(
            item.mother.mother_qualification
          );
        }
        if (fields.motherOccupation) {
          obj["Mother Occupation"] = handleNAValue(
            item.mother.mother_occupation
          );
        }
        newArray.push({ ...obj });
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      personal: true,
      father: false,
      mother: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-Admission-Report-${inputFields.class}-${inputFields.section}`
      : "SMU-Admission-Report";
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img
                src={Admission}
                alt="Admission Icon"
                className={Styles.moduleIcon}
              />
              <p className={Styles.moduleName}>ADMISSION MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Student Details
                  </button>
                  {showDetails.personal ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("personal")}
                    />
                  )}
                </div>
              </div>
              {showDetails.personal && (
                <>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.email}
                      onChange={() => {
                        setFields({ ...fields, email: !fields.email });
                      }}
                    >
                      Email
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.aadhaarNo}
                      onChange={() => {
                        setFields({
                          ...fields,
                          aadhaarNo: !fields.aadhaarNo,
                        });
                      }}
                    >
                      Aadhaar No.
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.address}
                      onChange={() => {
                        setFields({
                          ...fields,
                          address: !fields.address,
                        });
                      }}
                    >
                      Address
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.dob}
                      onChange={() => {
                        setFields({
                          ...fields,
                          dob: !fields.dob,
                        });
                      }}
                    >
                      DOB
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.nationality}
                      onChange={() => {
                        setFields({
                          ...fields,
                          nationality: !fields.nationality,
                        });
                      }}
                    >
                      Nationality
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.motherTongue}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherTongue: !fields.motherTongue,
                        });
                      }}
                    >
                      Mother Tongue
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.religion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          religion: !fields.religion,
                        });
                      }}
                    >
                      Religion
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subReligion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subReligion: !fields.subReligion,
                        });
                      }}
                    >
                      Sub-Religion
                    </Checkbox>
                  </div>
                </>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Father Details
                  </button>
                  {showDetails.father ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("father")}
                    />
                  )}
                </div>
              </div>
              {showDetails.father && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.fatherQualification}
                    onChange={() => {
                      setFields({
                        ...fields,
                        fatherQualification: !fields.fatherQualification,
                      });
                    }}
                  >
                    Qualification
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.fatherOccupation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        fatherOccupation: !fields.fatherOccupation,
                      });
                    }}
                  >
                    Occupation
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Mother Details
                  </button>
                  {showDetails.mother ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("mother")}
                    />
                  )}
                </div>
              </div>
              {showDetails.mother && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.motherQualification}
                    onChange={() => {
                      setFields({
                        ...fields,
                        motherQualification: !fields.motherQualification,
                      });
                    }}
                  >
                    Qualification
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.motherOccupation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        motherOccupation: !fields.motherOccupation,
                      });
                    }}
                  >
                    Occupation
                  </Checkbox>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Admission}
              alt="Admission Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>ADMISSION MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Student Details
                  </button>
                  {showDetails.personal ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("personal")}
                    />
                  )}
                </div>
              </div>
              {showDetails.personal && (
                <>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.email}
                      onChange={() => {
                        setFields({ ...fields, email: !fields.email });
                      }}
                    >
                      Email
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.aadhaarNo}
                      onChange={() => {
                        setFields({
                          ...fields,
                          aadhaarNo: !fields.aadhaarNo,
                        });
                      }}
                    >
                      Aadhaar No.
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.address}
                      onChange={() => {
                        setFields({
                          ...fields,
                          address: !fields.address,
                        });
                      }}
                    >
                      Address
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.dob}
                      onChange={() => {
                        setFields({
                          ...fields,
                          dob: !fields.dob,
                        });
                      }}
                    >
                      DOB
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.nationality}
                      onChange={() => {
                        setFields({
                          ...fields,
                          nationality: !fields.nationality,
                        });
                      }}
                    >
                      Nationality
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.motherTongue}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherTongue: !fields.motherTongue,
                        });
                      }}
                    >
                      Mother Tongue
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.religion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          religion: !fields.religion,
                        });
                      }}
                    >
                      Religion
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subReligion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subReligion: !fields.subReligion,
                        });
                      }}
                    >
                      Sub-Religion
                    </Checkbox>
                  </div>
                </>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Father Details
                  </button>
                  {showDetails.father ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("father")}
                    />
                  )}
                </div>
              </div>
              {showDetails.father && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.fatherQualification}
                    onChange={() => {
                      setFields({
                        ...fields,
                        fatherQualification: !fields.fatherQualification,
                      });
                    }}
                  >
                    Qualification
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.fatherOccupation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        fatherOccupation: !fields.fatherOccupation,
                      });
                    }}
                  >
                    Occupation
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Mother Details
                  </button>
                  {showDetails.mother ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("mother")}
                    />
                  )}
                </div>
              </div>
              {showDetails.mother && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.motherQualification}
                    onChange={() => {
                      setFields({
                        ...fields,
                        motherQualification: !fields.motherQualification,
                      });
                    }}
                  >
                    Qualification
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.motherOccupation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        motherOccupation: !fields.motherOccupation,
                      });
                    }}
                  >
                    Occupation
                  </Checkbox>
                </div>
              )}
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdmissionReport;

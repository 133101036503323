import axios from 'axios'
import { getRequestOptions, BASE_URL, isInvalid } from 'services/utils'

export async function getBirthdayStudent (payload={}) {
    const url = BASE_URL + "/general/birthday"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data.error){
            return{error:response.data.error}
        }
        if(response.status >= 200 && response.status<400 && response.data){
            return response.data
        }
    }catch(e){
        if(e?.response?.data?.error){
            throw e.response.data.error
        }
        if (e?.response?.data){
            throw e.response.data
        }
        throw e
    }
    }

    export async function getUpcomingBirthday (payload={}) {
        const url = BASE_URL + "/general/upcomingbirthday"
        try{
            const response = await axios.post(url,{...payload},getRequestOptions())
            if(response.data.error){
                return{error:response.data.error}
            }
            if(response.status >= 200 && response.status<400 && response.data){
                return response.data
            }
        }catch(e){
            if(e?.response?.data?.error){
                throw e.response.data.error
            }
            if (e?.response?.data){
                throw e.response.data
            }
            throw e
        }
        }

    const Services ={
        getBirthdayStudent,
        getUpcomingBirthday,
    }
    
    export default Services
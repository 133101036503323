import { React, useEffect, useState } from "react";
import Styles from "./DashboardFeatures.module.scss";
import { Link } from "react-router-dom";
import { DashboardFeatureList } from "./DashboardFeatureList";
import { useAuth } from "contexts/auth-context";

const DashboardFeatures = (props) => {
  // console.log("renderList", props.id);
  if (props.page === "student") {
    return <StudentDashboard />;
  } else if (props.page === "staff") {
    return <StaffDashboard id={props.id} />;
  } else if (props.page === "management") {
    return <ManagementDashboard id={props.id} />;
  } else {
    return <AdminDashboard id={props.id} />;
  }
};

export default DashboardFeatures;

const StudentDashboard = () => {
  const [featuresdata, SetFeaturesData] = useState("");
  const { isSearch, setIsSearch } = useAuth();
  const [searchbarType, setSearchbarType] = useState(true);
  useEffect(() => {
    localStorage.removeItem("event_filter");
    localStorage.removeItem("circular_filter");
    localStorage.removeItem("pageSize");
    SetFeaturesData(window.localStorage.getItem("Permission"));
    const searchbar = localStorage.getItem("SearchType");
    if (searchbar == "SingleModule") {
      setSearchbarType(true);
    } else {
      setSearchbarType(false);
    }
  }, []);
  const featureMatchesSearch = (featureName) => {
    if (
      isSearch &&
      featureName.toLowerCase().includes(isSearch.toLowerCase())
    ) {
      return true;
    }
    return false;
  };
  const matchedModules = DashboardFeatureList.student.filter((val) => {
    const isMatchingSearch = featureMatchesSearch(val.name);
    return (
      (featuresdata ? featuresdata.includes(val.name.toUpperCase()) : null) &&
      (!isSearch || isMatchingSearch)
    );
  });
  return (
    <>
      {searchbarType == false ? (
        <div className={Styles.body}>
          <div className={isSearch ? Styles.filtercards : Styles.cards}>
            {matchedModules.length > 0 ? (
              matchedModules.map((val) => (
                <Link id={val.id} to={val.path} key={val.id}>
                  <div className={Styles.card}>
                    <img className={Styles.icon} src={val.icon} alt={"icon"} />
                    <p className={Styles.title}>{val.name}</p>
                  </div>
                </Link>
              ))
            ) : (
              <p className={Styles.noResult}>
                No modules match the search query. Please verify it.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className={Styles.body}>
          <div className={Styles.cards}>
            {DashboardFeatureList.student.map((val) => {
              const isMatchingSearch = featureMatchesSearch(val.name);
              if (
                featuresdata
                  ? featuresdata.includes(val.name.toUpperCase())
                  : null
              ) {
                return (
                  <>
                    <Link id={val.id} to={val.path}>
                      <div
                        className={`${Styles.card} ${
                          isMatchingSearch ? Styles.highlighted : ""
                        }`}
                      >
                        <img
                          className={Styles.icon}
                          src={val.icon}
                          alt={"icon"}
                        />
                        <p className={Styles.title}>{val.name}</p>
                      </div>
                    </Link>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const StaffDashboard = (id) => {
  const [featuresdata, SetFeaturesData] = useState("");
  const { isSearch, setIsSearch } = useAuth();
  const [searchbarType, setSearchbarType] = useState(true);
  useEffect(() => {
    localStorage.setItem("student_filter", "");
    localStorage.removeItem("event_filter");
    localStorage.removeItem("circular_filter");
    localStorage.removeItem("pageSize");
    localStorage.removeItem("selectedTab");
    SetFeaturesData(window.localStorage.getItem("Permission"));
    const searchbar = localStorage.getItem("SearchType");
    if (searchbar == "SingleModule") {
      setSearchbarType(true);
    } else {
      setSearchbarType(false);
    }
  }, []);

  const featureMatchesSearch = (featureName) => {
    if (
      isSearch &&
      featureName.toLowerCase().includes(isSearch.toLowerCase())
    ) {
      return true;
    }
    return false;
  };
  const matchedModules = DashboardFeatureList.staff.filter((val) => {
    const isMatchingSearch = featureMatchesSearch(val.name);
    return (
      (featuresdata ? featuresdata.includes(val.name.toUpperCase()) : null) &&
      (!isSearch || isMatchingSearch)
    );
  });

  return (
    <>
      {searchbarType ? (
        <div className={Styles.body}>
          <div className={isSearch ? Styles.filtercards : Styles.cards}>
            {matchedModules.length > 0 ? (
              matchedModules.map((val) => (
                <Link id={val.id} to={val.path} key={val.id}>
                  <div className={Styles.card}>
                    <img className={Styles.icon} src={val.icon} alt={"icon"} />
                    <p className={Styles.title}>{val.name}</p>
                  </div>
                </Link>
              ))
            ) : (
              <p className={Styles.noResult}>
                No modules match the search query. Please verify it.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className={Styles.body}>
          <div className={Styles.cards}>
            {DashboardFeatureList.staff.map((val) => {
              const isMatchingSearch = featureMatchesSearch(val.name);
              if (
                featuresdata
                  ? featuresdata.includes(val.name.toUpperCase())
                  : null
              ) {
                return (
                  <>
                    <Link id={val.id} to={val.path}>
                      <div
                        className={`${Styles.card} ${
                          isMatchingSearch ? Styles.highlighted : ""
                        }`}
                      >
                        <img
                          className={Styles.icon}
                          src={val.icon}
                          alt={"icon"}
                        />
                        <p className={Styles.title}>{val.name}</p>
                      </div>
                    </Link>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const AdminDashboard = (id) => {
  const [featuresdata, SetFeaturesData] = useState("");
  const { isSearch, setIsSearch } = useAuth();
  const [searchbarType, setSearchbarType] = useState(true);
  useEffect(() => {
    localStorage.setItem("report", "");
    localStorage.setItem("student_filter", "");
    localStorage.setItem("staff_filter", "");
    localStorage.removeItem("event_filter");
    localStorage.removeItem("circular_filter");
    localStorage.removeItem("classroom_filter");
    localStorage.setItem("showSearchField", "");
    localStorage.removeItem("userType");
    localStorage.removeItem("pageSize");
    localStorage.removeItem("student_filter2");
    localStorage.removeItem("selectDepartment");
    localStorage.removeItem("teacherName");
    localStorage.removeItem("showFilteredList");
    localStorage.removeItem("selectedTab");
    SetFeaturesData(window.localStorage.getItem("Permission"));
    const searchbar = localStorage.getItem("SearchType");
    if (searchbar == "SingleModule") {
      setSearchbarType(true);
    } else {
      setSearchbarType(false);
    }
  }, []);

  const featureMatchesSearch = (featureName) => {
    if (
      isSearch &&
      featureName.toLowerCase().includes(isSearch.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const matchedModules = DashboardFeatureList.admin.filter((val) => {
    const isMatchingSearch = featureMatchesSearch(val.name);
    return (
      (featuresdata ? featuresdata.includes(val.name.toUpperCase()) : null) &&
      (!isSearch || isMatchingSearch)
    );
  });
  return (
    <>
      {searchbarType == false ? (
        <div className={Styles.body}>
          <div className={isSearch ? Styles.filtercards : Styles.cards}>
            {matchedModules.length > 0 ? (
              matchedModules.map((val) => (
                <Link id={val.id} to={val.path} key={val.id}>
                  <div className={Styles.card}>
                    <img className={Styles.icon} src={val.icon} alt={"icon"} />
                    <p className={Styles.title}>{val.name}</p>
                  </div>
                </Link>
              ))
            ) : (
              <p className={Styles.noResult}>
                No modules match the search query. Please verify it.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className={Styles.body}>
          <div className={Styles.cards}>
            {DashboardFeatureList.admin.map((val) => {
              const isMatchingSearch = featureMatchesSearch(val.name);
              if (
                featuresdata
                  ? featuresdata.includes(val.name.toUpperCase())
                  : null
              ) {
                return (
                  <>
                    <Link id={val.id} to={val.path}>
                      <div
                        className={`${Styles.card} ${
                          isMatchingSearch ? Styles.highlighted : ""
                        }`}
                      >
                        <img
                          className={Styles.icon}
                          src={val.icon}
                          alt={"icon"}
                        />
                        <p className={Styles.title}>{val.name}</p>
                      </div>
                    </Link>
                  </>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

const ManagementDashboard = (id) => {
  const [featuresdata, SetFeaturesData] = useState("");
  const { isSearch, setIsSearch } = useAuth();
  const [searchbarType, setSearchbarType] = useState(true);

  useEffect(() => {
    localStorage.setItem("report", "");
    localStorage.setItem("student_filter", "");
    localStorage.setItem("staff_filter", "");
    localStorage.removeItem("event_filter");
    localStorage.removeItem("circular_filter");
    localStorage.removeItem("classroom_filter");
    localStorage.setItem("showSearchField", "");
    localStorage.removeItem("userType");
    localStorage.removeItem("pageSize");
    localStorage.removeItem("student_filter2");
    localStorage.removeItem("selectDepartment");
    localStorage.removeItem("selectedTab");
    SetFeaturesData(window.localStorage.getItem("Permission"));
    const searchbar = localStorage.getItem("SearchType");
    if (searchbar == "SingleModule") {
      setSearchbarType(true);
    } else {
      setSearchbarType(false);
    }
  }, []);
  const featureMatchesSearch = (featureName) => {
    if (
      isSearch &&
      featureName.toLowerCase().includes(isSearch.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const matchedModules = DashboardFeatureList.management.filter((val) => {
    const isMatchingSearch = featureMatchesSearch(val.name);
    return (
      (featuresdata ? featuresdata.includes(val.name.toUpperCase()) : null) &&
      (!isSearch || isMatchingSearch)
    );
  });
  return (
    <div className={Styles.body}>
      {/* <div className={Styles.cards}>
        {DashboardFeatureList.management.map((val) => {
          if (
            featuresdata ? featuresdata.includes(val.name.toUpperCase()) : null
          ) {
            return (
              <>
                <Link id={val.id} to={val.path}>
                  <div className={Styles.card}>
                    <img className={Styles.icon} src={val.icon} alt={"icon"} />
                    <p className={Styles.title}>{val.name}</p>
                  </div>
                </Link>
              </>
            );
          }
        })}
      </div> */}

      {searchbarType == false ? (
        <div className={isSearch ? Styles.filtercards : Styles.cards}>
          {matchedModules.length > 0 ? (
            matchedModules.map((val) => (
              <Link id={val.id} to={val.path} key={val.id}>
                <div className={Styles.card}>
                  <img className={Styles.icon} src={val.icon} alt={"icon"} />
                  <p className={Styles.title}>{val.name}</p>
                </div>
              </Link>
            ))
          ) : (
            <p className={Styles.noResult}>
              No modules match the search query. Please verify it.
            </p>
          )}
        </div>
      ) : (
        <div className={Styles.cards}>
          {DashboardFeatureList.management.map((val) => {
            const isMatchingSearch = featureMatchesSearch(val.name);
            if (
              featuresdata
                ? featuresdata.includes(val.name.toUpperCase())
                : null
            ) {
              return (
                <>
                  <Link id={val.id} to={val.path}>
                    <div
                      className={`${Styles.card} ${
                        isMatchingSearch ? Styles.highlighted : ""
                      }`}
                    >
                      <img
                        className={Styles.icon}
                        src={val.icon}
                        alt={"icon"}
                      />
                      <p className={Styles.title}>{val.name}</p>
                    </div>
                  </Link>
                </>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

import React, { useState } from "react";
import Styles from "./TeacherLogin.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { auth } from '../../config/firebaseInitialize';
import axios from "axios";
import { useAuth } from "contexts/auth-context";
import { useToast, Button,
  Input,
  InputGroup,
  Stack,
  InputRightElement } from "@chakra-ui/react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const TeacherLogin = () => {
  const toast = useToast();
  const auth = getAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useAuth();
  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const token = localStorage.getItem("token")
  React.useEffect(()=>{
    if(token){
      navigate("/staff/dashboard")
    }
  },[token])
  const url = "https://smudev.smuapp.org/dev/api";
  const [passwordType, setPasswordType] = useState("password");

  const submitHandler = (e) => {
    e.preventDefault();

    if (userName.length === 0) {
      setError({
        state: true,
        message: "Please enter your email",
      });
      return;
    } else if (password.length === 0) {
      setError({
        state: true,
        message: "Please enter your password",
      });
      return;
    }
    signIn(userName, password);
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
        { returnSecureToken: true,}
      );
      const user = userCredential.user.accessToken;
      try {
        const { data } = await axios.post(url + "/v1/auth/login/new", {
          role: "teacher",
        },{headers:{"Authorization":`Bearer ${user}`}});
        console.log(data);
        localStorage.setItem("token", data.token);
        localStorage.setItem("teacher_name", data.payload.name);
        localStorage.setItem("teacher_id", data.payload.id);
        localStorage.setItem("role", "teacher");
        localStorage.setItem("Permission",data.permission)
        const loggedInOn = new Date();
        localStorage.setItem("loggedInOn", loggedInOn);
        setUser(true);
        toast({
          title: "Login Successful.",
          description: "Logged in as teacher.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/staff/dashboard",{
          state:{
            data
          }
        });
      } catch (error) {
        setError("Invalid username/password");

        console.error(error);
      }
    } catch (error) {
      console.log("Failed");
      toast({
        title: "Login Failed.",
        description: "User doesn't exist.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.wrapper}>
        <div className={Styles.cover}></div>
        <div className={Styles.inputDiv}>
          <form>
            <p className={Styles.heading}>TEACHER LOGIN</p>
            <div className={Styles.inputContainer}>
              <input
                className={Styles.input}
                value={userName}
                type="text"
                placeholder={"Enter email address..."}
                onChange={(e) => setUserName(e.target.value)}
              />
            <InputGroup className={Styles.InputDiv}>
                  <Input
                    pr="4.5rem"
                    type={passwordType === "password" ? "password" : "text"}
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={togglePassword}>
                      {/* {passwordType === "password" ? <p>👁️</p> : <p>🙈</p>} */}
                      {passwordType === "password" ? (
                        <BsFillEyeFill style={{ fontSize: "20px" }} />
                      ) : (
                        <BsFillEyeSlashFill style={{ fontSize: "20px" }} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
            </div>
            {error.state === true ? (
              <p className={Styles.error}>{error.message}</p>
            ) : null}
            <button onClick={submitHandler} className={Styles.submitButton}>
              <p className={Styles.submitText}>LOGIN</p>
            </button>
            <NavLink to="/forgotPassword" className={Styles.forgetPassword}>Forgot password?</NavLink>
          </form>
          <NavLink className={Styles.changeType} to="/chooseLogin">Change UserType</NavLink>
        </div>
      </div>
    </div>
  );
};

export default TeacherLogin;

import React from "react";
import Styles from "./PreviewReportCard.module.scss";
import caution from "../../../assets/icons/caution.png";

const MarksNotReleased = () => {
  console.log("localStorage.getItem", localStorage.getItem);
  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <p className={Styles.headerText}>
          Student : {localStorage.getItem("student_name")} | Grade :{" "}
          {localStorage.getItem("student_class")}
          {localStorage.getItem("student_section")}{" "}
        </p>
      </div>

      <div className={Styles.wrapperCenter}>
        <img className={Styles.cautionImg} src={caution} alt="caution" />
        <p className={Styles.cautionText}>Marks not released yet</p>
      </div>
    </div>
  );
};

export default MarksNotReleased;

import React, { useEffect } from "react";
import Styles from "./PreviewReportCard.module.scss";
import ReportCardPdf from "./ReportCardPdf/Components/Main/Main";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import { useToast } from "@chakra-ui/react";

const StaffPreviewReportCard = () => {
  const { state } = useLocation();
  const toast = useToast();

  const createPDF = async (index) => {
    let pdf = new jsPDF("portrait", "pt", "a4");
    let data = await document.querySelector(`#reportCardPdf_${index}`);
    pdf.html(data).then(() => {
      pdf.save(`ExamReportCard_${index + 1}.pdf`);
    });
  };

  const handlePdfGeneration = () => {
    const delay = 2;
    const limit = state.data.length;
    let i = 0;
    const limitedInterval = setInterval(() => {
      createPDF(i++);
      if (i > limit) {
        toast({
          title: "Download Complete",
          position: "bottom-right",
          description: "You can now open the downloaded files",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        clearInterval(limitedInterval);
      }
    }, delay * 1000);
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <p className={Styles.headerText}>
          Selected Students : {state.data.map((i) => i.name + " | ")}
        </p>
      </div>
      <div className={Styles.header}>
        <button
          id="downloadReportButton"
          className={Styles.button}
          onClick={() => {
            toast({
              title: "Downloading...",
              position: "bottom-right",
              description:
                "Please open the files once the entire download process is completed",
              status: "info",
              duration: 2500,
              isClosable: true,
            });
            handlePdfGeneration();
          }}
        >
          Download
        </button>
        {/* <button className={Styles.button}>Download All Previewed</button> */}
      </div>
      <div className={Styles.wrapper}>
        <div
          id="reportCardTemp"
          className={Styles.reportCardTemp1}
          style={{ width: "100%" }}
        >
          <div className={Styles.newReportCardTemp1}>
            {state.data.map((i) => (
              <ReportCardPdf data={i} type="template" />
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        {state.data.map((i, index) => (
          <div id={`reportCardPdf_${index}`}>
            <ReportCardPdf data={i} type="pdf" />
          </div>
        ))}
      </div>
      {/* <p className={Styles.page}>[1/3]</p> */}
    </div>
  );
};

export default StaffPreviewReportCard;

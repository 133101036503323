import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

export async function sendSMS(payload = {}) {
  const url = BASE_URL + "/admin/sms/send_sms/client1";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function sendSMS2(payload = {}) {
  const url = BASE_URL + "/admin/sms/send_sms/client2";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function pilvoAccount1(payload = {}) {
  const url = BASE_URL + "/admin/sms/get_account1";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function pilvoAccount2(payload = {}) {
  const url = BASE_URL + "/admin/sms/get_account2";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
const ServicesSMS = {
  sendSMS,
  sendSMS2,
  pilvoAccount1,
  pilvoAccount2,
};
export default ServicesSMS;

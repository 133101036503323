import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SMU from "assets/logos/smu.png";
import Styles from "./Home.module.scss";

const Home = () => {
  const days = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const navigate = useNavigate();
  useEffect(() => {
    function pushPage() {
      const isLoggedIn = localStorage.getItem("token");
      const loggedInOn = localStorage.getItem("loggedInOn");
      setTimeout(() => {
        if (isLoggedIn && loggedInOn) {
          //check how many days has passed since user has logged in as token may be expired
          const loggedInDate = new Date(loggedInOn);
          const currentDate = new Date();
          let daysInBetween = days(currentDate, loggedInDate);
          console.log("daysbetween", daysInBetween);
          if (!(daysInBetween >= 0 && daysInBetween <= 5)) {
            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("loggedInOn");
            navigate("/chooseLogin");
          }
          const userRole = localStorage.getItem("role");
          switch (userRole) {
            case "admin":
              navigate("/admin/dashboard");
              break;
            case "teacher":
              navigate("/staff/dashboard");
              break;
            case "student":
              navigate("/student/dashboard");
              break;
            case "management":
              navigate("/management/dashboard");
              break;

            default:
              navigate("/chooseLogin");
          }
        } else {
          navigate("/chooseLogin");
        }
      }, 2000);
    }

    pushPage();
  }, []);

  return (
    <div className={Styles.body}>
      <img className={Styles.logo} src={SMU} alt={"logo"} />
    </div>
  );
};

export default Home;

import React, { useEffect, useRef, useState } from "react";
import Styles from "./Birthday.module.scss";
import Services from "services/admin-dashboard/birthday.service";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import defaultUser from "assets/icons/User.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/react";
import nextbtn from "assets/icons/right-arrow.png";
import prebtn from "assets/icons/left-arrow.png";
import "./SlickSlider.css";
import "./Birthday.module.scss";
import { accordionAnatomy } from "@chakra-ui/anatomy";

const Birthday = () => {
  const [birthdayStudent, setBirthdayStudent] = useState("");
  const [showTodayBirthday, setShowTodayBirthday] = useState("");
  const [showUpcomingBirthday, setShowUpcomingBirthday] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [showSetting, setShowSetting] = useState("");
  const [callBirthDayApi, setCallBirthDayApi] = useState(false);
  const [callUpcomingBirthDayApi, setCallUpcomingBirthDayApi] = useState(false);
  const slider = useRef();
  const navigate = useNavigate();
  // let slideToShow = 6.5;
  const birthdayStudentData = async () => {
    const birthdayRes = await Services.getBirthdayStudent();
    if (birthdayRes) {
      setBirthdayStudent(birthdayRes);
    }
  };
  useEffect(() => {
    if (callBirthDayApi) {
      birthdayStudentData();
    }
  }, [callBirthDayApi]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  React.useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const [upcomingbirthday, setComingBirthday] = useState("");
  const upComingBirthdayList = async () => {
    const upcomingResponse = await Services.getUpcomingBirthday();
    if (upcomingResponse) {
      setComingBirthday(upcomingResponse);
    }
  };
  useEffect(() => {
    if (callUpcomingBirthDayApi) {
      upComingBirthdayList();
    }
  }, [callUpcomingBirthDayApi]);

  const settingSlick = () => {
    if (screenSize.width <= 500) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 1.8,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 550) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 2,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 600) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 2.5,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 650) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 3,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 700) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 3.5,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 750) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 4,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 800) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 4.5,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 850) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 5,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 900) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 5.5,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else if (screenSize.width <= 950) {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 6,
        slidesToScroll: 1,
      };

      setShowSetting(settings);
    } else {
      const settings = {
        infinite: false,
        speed: 900,
        slidesToShow: 8,
        slidesToScroll: 4,
      };
      setShowSetting(settings);
    }
  };

  useEffect(() => {
    if (screenSize.width) {
      settingSlick();
    }
  }, [screenSize.width]);
  useEffect(() => {
    const birthadyValue = localStorage.getItem("UpcomingBirthday");
    if (birthadyValue == "enable") {
      setShowUpcomingBirthday(true);
    } else {
      setShowUpcomingBirthday(false);
    }
  }, []);
  useEffect(() => {
    const todaybirthadyValue = localStorage.getItem("TodayBirthday");
    if (todaybirthadyValue == "disable") {
      setShowTodayBirthday(false);
    } else {
      setShowTodayBirthday(true);
    }
  }, []);
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.body}>
      <Accordion allowToggle>
        <AccordionItem style={{ border: "none", outline: "none" }}>
          {showTodayBirthday ? (
            <AccordionButton onClick={() => setCallBirthDayApi(true)}>
              <p className={Styles.header}>Today's Birthdays</p>
            </AccordionButton>
          ) : null}
          <AccordionPanel>
            {showTodayBirthday ? (
              showSetting ? (
                <Slider
                  ref={slider}
                  className={Styles.cards}
                  {...(showSetting && showSetting)}
                >
                  {birthdayStudent &&
                    birthdayStudent?.staff?.map((data, id) => {
                      return (
                        <div className={Styles.card} id={id}>
                          <div className={Styles.imageContainer}>
                            <img
                              className={Styles.profileImage}
                              src={data.url !== "N/A" ? data.url : defaultUser}
                              alt="profile"
                            />
                          </div>
                          <p className={Styles.name}>
                            {truncateText(data.name, 17)}
                          </p>
                        </div>
                      );
                    })}
                  {birthdayStudent &&
                    birthdayStudent?.students?.map((data, id) => {
                      return (
                        <div className={Styles.card} id={id}>
                          <div className={Styles.imageContainer}>
                            <img
                              className={Styles.profileImage}
                              src={data.url !== "N/A" ? data.url : defaultUser}
                              alt="profile"
                            />
                          </div>
                          <p className={Styles.name}>
                            {truncateText(data.name, 17)}
                          </p>
                          <p className={Styles.class}>
                            {data.academic.class}&nbsp;
                            {data.academic.section}
                          </p>
                        </div>
                      );
                    })}
                </Slider>
              ) : (
                ""
              )
            ) : null}

            {showUpcomingBirthday ? (
              <Accordion allowToggle style={{ marginTop: "1vw" }}>
                <AccordionItem style={{ border: "none", outline: "none" }}>
                  <AccordionButton
                    onClick={() => setCallUpcomingBirthDayApi(true)}
                  >
                    <p className={Styles.Upcomingheader}>Upcoming Birthday</p>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {showSetting ? (
                      <Slider
                        ref={slider}
                        className={Styles.cards}
                        {...(showSetting && showSetting)}
                      >
                        {upcomingbirthday &&
                          upcomingbirthday?.staff?.map((data, id) => {
                            return (
                              <div className={Styles.card} id={id}>
                                <div className={Styles.imageContainer}>
                                  <img
                                    className={Styles.profileImage}
                                    src={
                                      data?.signed_url ||
                                      data?.personel?.profile_url.includes(
                                        "http"
                                      )
                                        ? data.url
                                          ? data.url
                                          : data?.personel?.profile_url
                                        : defaultUser
                                    }
                                    alt="profile"
                                  />
                                </div>
                                <p className={Styles.name}>{data.name}</p>
                                {/* <p className={Styles.class}>{moment(data.personel.dob).format("DD-MM-YYYY")} </p> */}
                              </div>
                            );
                          })}
                        {upcomingbirthday &&
                          upcomingbirthday?.students?.map((data, id) => {
                            return (
                              <div className={Styles.card} id={id}>
                                <div className={Styles.imageContainer}>
                                  <img
                                    className={Styles.profileImage}
                                    src={
                                      data?.signed_url ||
                                      data?.personel?.profile_url.includes(
                                        "http"
                                      )
                                        ? data.url
                                          ? data.url
                                          : data?.personel?.profile_url
                                        : defaultUser
                                    }
                                    alt="profile"
                                  />
                                </div>
                                <p className={Styles.name}>{data.name}</p>
                                {/* <p className={Styles.class}>{moment(data.personel.dob).format("DD-MM-YYYY")} </p> */}
                                <p className={Styles.class}>
                                  Grade
                                  {data.academic.class}-{data.academic.section}
                                </p>
                              </div>
                            );
                          })}
                      </Slider>
                    ) : (
                      ""
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              ""
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Birthday;

//
import React, { useState, useEffect } from "react";
import Styles from "./Notification.module.scss";
import "./Notification.css";
import { BiSearchAlt } from "react-icons/bi";
import {
  Button,
  Input,
  Textarea,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Checkbox,
  Tooltip
} from "@chakra-ui/react";
import defaultUser from "../../../assets/icons/User.png";
import { MdPreview } from "react-icons/md";

import {
  requestCreateNotification,
  requestSearchAll,
  requestSearchClassAndSection,
} from "services/admin-dashboard/notification.service";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useClassAndSections2 } from "hooks/classAndSection";
import Stepper from "./Stepper";
import TeacherSteppers from "./TeacherStepper";
import { requestUploadNotification } from "services/common.service";
const Notification = () => {
  const [NewInputFields, setNewInputFields] = useState({
    New_target_group: [],
    New_student_target_group: [],
  });  
  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [student, setStudent] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedManagement, setSelectedManagement] = useState("");
  const [inputFeilds, setInputFeilds] = useState({
    heading: "",
    Description: "",
  });
  const modalGrade = useDisclosure();
  const modalPreview = useDisclosure();
  const TeacherStepper = useDisclosure();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const toast = useToast();
  const [selectedPerson, setSelectedPerson] = useState("");
  const navigate = useNavigate();
  const [classSearch, setSearchClass] = useState("");
  const [sectionValue, setSectionValues] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassExist, setSelectedClassExist] = useState(false);
  const [showClassDetails, setShowClassDetails] = useState("");
  const [excludedStudentId, setExcludedStudentId] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");
  const [selectedIncludeStaff, setSelectedIncludeStaff] = useState("");
  const [excludeStaffId, setExcludeStaffId] = useState("");
  const [image, setImage] = useState("");
  const [selectAllStudent, setSelectAllStudent] = useState("");
  const [selectAllTeacher, setSelectAllTeacher] = useState("");
  const [excludeStudentDetails,setExcludeStudentDetails] = useState("")
  const [excludeStaffDetails,setExculdeStaffDetails] = useState("")
  const handleSearchStudent = async (e) => {
    try {
      setRequestInProgress(true);
      const payload = {
        name: searchName,
        global_flag: "true",
      };
      const response = await requestSearchAll(payload);
      if (response) {
        // console.log(response);
        setStudent(response);
      }
      setRequestInProgress(false);
    } catch (e) {
      console.log(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    if (searchName) {
      setRequestInProgress(true);
      const timeoutId = setTimeout(() => handleSearchStudent(), 2000);
      setRequestInProgress(false);
      return () => clearTimeout(timeoutId);
    }
  }, [searchName]);
  const handleSelectedStaff = (id, { student: student }) => {
    let people = [...selectedStaff];
    let selectedPersons = [...selectedPerson];
    if (people.includes(id) && selectedPersons.includes(student)) {
      let index = people.indexOf(id);
      let indexPerson = selectedPersons.indexOf(student);
      people.splice(index, 1);
      selectedPersons.splice(indexPerson, 1);
      setSelectedStaff(people);
      setSelectedPerson(selectedPersons);
    } else {
      setSelectedStaff([...people, id]);
      setSelectedPerson([...selectedPersons, student]);
    }
    handleRemoveSelectedPeople(id);
  };
  const handleSelectedAdmin = (id, { student: student }) => {
    let people = [...selectedAdmin];
    let selectedPersons = [...selectedPerson];
    if (people.includes(id) && selectedPersons.includes(student)) {
      let index = people.indexOf(id);
      let indexPerson = selectedPersons.indexOf(student);
      people.splice(index, 1);
      selectedPersons.splice(indexPerson, 1);
      setSelectedAdmin(people);
      setSelectedPerson(selectedPersons);
    } else {
      setSelectedAdmin([...people, id]);
      setSelectedPerson([...selectedPersons, student]);
    }
    handleRemoveSelectedPeople(id);
  };
  const handleSelectedManagement = (id, { student: student }) => {
    let people = [...selectedManagement];
    let selectedPersons = [...selectedPerson];
    if (people.includes(id) && selectedPersons.includes(student)) {
      let index = people.indexOf(id);
      let indexPerson = selectedPersons.indexOf(student);
      people.splice(index, 1);
      selectedPersons.splice(indexPerson, 1);
      setSelectedManagement(people);
      setSelectedPerson(selectedPersons);
    } else {
      setSelectedManagement([...people, id]);
      setSelectedPerson([...selectedPersons, student]);
    }
    handleRemoveSelectedPeople(id);
  };
  const handleSelectedPeople = (id, { student: student }) => {
    // console.log("student,", student);
    let people = [...selected];
    let selectedPersons = [...selectedPerson];
    if (people.includes(id) && selectedPersons.includes(student)) {
      let index = people.indexOf(id);
      let indexPerson = selectedPersons.indexOf(student);
      people.splice(index, 1);
      selectedPersons.splice(indexPerson, 1);
      setSelected(people);
      setSelectedPerson(selectedPersons);
    } else {
      setSelected([...people, id]);
      setSelectedPerson([...selectedPersons, student]);
    }
    handleRemoveSelectedPeople(id);
  };
  const handleRemoveSelectedPeople = (id) => {
    let people = [...selected];
    if (people.includes(id)) {
      let index = people.indexOf(id);
      people.splice(index, 1);
      setSelected(people);
    }
  };
  const handleExcludeStudentList = (val) => {
    if (val[0].userType != "Student") {
      setSelectedPerson(val[2].selectedStudent);
      setShowClassDetails(val[0].class[1].section);
      setExcludedStudentId(val[0].class[0].exculdeStudentId);
      setExcludeStudentDetails(val[0].class[0].exculdeStudentDetails)
      setSelected(val[1].selectedId);
      const classId = [];
      for (let i = 0; i < val[0].class[1].section.length; i++) {
        let { _id } = val[0].class[1].section[i];
        classId.push(_id);
      }
      setSelectedClass(classId);
      modalGrade.onClose();
    } else {
      setExcludedStudentId(val[1].selectedId);
      setExcludeStudentDetails(val[2].selectedStudent);
      setShowClassDetails([{ class: " All", section: " All" }]);
      setSelectAllStudent("Student");

      modalGrade.onClose();
    }
  };
  const handleCreateData = (e) => {
    setInputFeilds({ ...inputFeilds, [e.target.name]: e.target.value });
  };
  const handleCreateNotification = async () => {
    try {
      let fileResponse;
      if (image.length > 0) {
        fileResponse = await uploadFilesToCloud();
      }
      if (inputFeilds.heading.trim() === "") {
        toast({
          title: "Title is required field",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      } else if (inputFeilds.heading.trim().length < 10) {
        toast({
          title: "Title should be 10 characters or more",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom-right",
        });
      } else {
        const payload = {
          title: inputFeilds.heading,
          description: inputFeilds.Description,
          students: selected,
          user_types: [selectAllStudent, selectAllTeacher],
          staffs:selectedStaff,
          class_values: selectedClass,
          staff_department: departmentValue,
          exclude_students: excludedStudentId,
          exclude_staffs: excludeStaffId,
          managements: selectedManagement,
          admins: selectedAdmin,
          media: fileResponse ? fileResponse.file_url : [],
          
        };
        const response = await requestCreateNotification(payload);
        if (response) {
          setInputFeilds([""]);
          setShowClassDetails("");
          setSelectedPerson("");
          setSelectAllTeacher("");
          setSelectAllStudent("");
          setSelectedStaff("")
          setSelectedIncludeStaff("")
          setExcludeStaffId("")
          toast({
            title: "Notification sent successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } catch (e) {
      console.log("error", e);
      toast({
        title: "Having some issue while sending notification",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const handleTeacherModule = (val) => {
    console.log("teacherval", val)
    TeacherStepper.onClose();
    if (val[0].userType != "Teacher") {
      setDepartmentValue(val[0].class[1].DepartmentId);
      setSelectedIncludeStaff(val[2].selectedStaff);
      setExcludeStaffId(val[0].class[0].exculdeStaffId);
      setExculdeStaffDetails(val[0].class[0].excludeStaffDetails)

      setSelectedStaff(val[1].selectedId)
    } else {
      setSelectAllTeacher("Teacher");
      setDepartmentValue(["All Department"]);
      setExcludeStaffId(val[0].selectedId);
      setExculdeStaffDetails(val[2].selectedStaff);
    }
  };
  const uploadFilesToCloud = async () => {
    try {
      const response = await requestUploadNotification({
        title: "ADMIN",
        file: image,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          file_url: response.media,
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setImage(chosenFiles);
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className={Styles.stats}>NOTIFICATION</p>
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: "3vw" }}
          >
            <p className={Styles.stepperName} onClick={modalGrade.onOpen}>
              Student
            </p>
            <p className={Styles.stepperName} onClick={TeacherStepper.onOpen}>
              Staff
            </p>
            <p className={Styles.stepperName}>Management</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="container-2">
            <span className="icon">
              <BiSearchAlt className={Styles.searchIcon} />
            </span>
            <input
              value={searchName}
              type="search"
              id="search"
              onClick={() => setShowModal(true)}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              placeholder="Search..."
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button onClick={() => navigate("view")}>View Notification</Button>
          </div>
        </div>
      </div>
      {showModal ? (
        <div className={Styles.modalBox}>
          {/* <div className={Styles.search}>
        <Input value={searchName} placeholder="Search...." className={Styles.searchBox}onChange={(e)=>{handleSearchStudent(e);setSearchName(e.target.value)}} />
        </div> */}
          {requestInProgress ? (
            <Spinner />
          ) : (
            <div className={Styles.containerBox}>
              <p>
                {student.staffs && student.staffs.length >= 1 ? "Staff" : ""}
              </p>
              <div className={Styles.lists}>
                {student.staffs &&
                  student.staffs.map((student, idx) => (
                    <div
                      className={Styles.checkBox}
                      style={{
                        background: selectedStaff.includes(student._id)
                          ? "#c5e3f3"
                          : "",
                        margin: "3px",
                      }}
                      onClick={() =>
                        handleSelectedStaff(student._id, { student: student })
                      }
                    >
                      <div className={Styles.listing}>
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student.url != "N/A" ? student.url : defaultUser
                              }
                              alt="student-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>
                            <p className={Styles.class}>
                              Emp ID: {student.emp_id}
                            </p>
                            <p className={Styles.number}>
                              Department {student.department}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <p>
                {student?.students && student?.students?.length >= 1
                  ? "Students"
                  : ""}
              </p>
              <div className={Styles.lists}>
                {student?.students &&
                  student?.students.map((student, idx) => (
                    <div
                      className={Styles.checkBox}
                      style={{
                        background: selected.includes(student._id)
                          ? "#c5e3f3"
                          : "",
                        margin: "3px",
                      }}
                      onClick={() =>
                        handleSelectedPeople(student._id, { student: student })
                      }
                    >
                      <div className={Styles.listing}>
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student.url != "N/A" ? student.url : defaultUser
                              }
                              alt="student-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                            <p className={Styles.number}>
                              +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <p>
                {student.admins && student.admins.length >= 1 ? "Admin" : ""}
              </p>
              <div className={Styles.lists}>
                {student.admins &&
                  student.admins.map((student, idx) => (
                    <div
                      className={Styles.checkBox}
                      style={{
                        background: selectedAdmin.includes(student._id)
                          ? "#c5e3f3"
                          : "",
                        margin: "3px",
                      }}
                      onClick={() =>
                        handleSelectedAdmin(student._id, { student: student })
                      }
                    >
                      <div className={Styles.listing}>
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={student.url ? student.url : defaultUser}
                              alt="Admin-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <p>
                {student.managements && student.managements.length >= 1
                  ? "Management"
                  : ""}
              </p>
              <div className={Styles.lists}>
                {student.managements &&
                  student.managements.map((student, idx) => (
                    <div
                      className={Styles.checkBox}
                      style={{
                        background: selectedManagement.includes(student._id)
                          ? "#c5e3f3"
                          : "",
                        margin: "3px",
                      }}
                      onClick={() =>
                        handleSelectedManagement(student._id, {
                          student: student,
                        })
                      }
                    >
                      <div className={Styles.listing}>
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student.url != "N/A" ? student.url : defaultUser
                              }
                              alt="Management-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className={Styles.ButtonStyles}>
            <Button
              onClick={() => {
                setShowModal(false);
                setSelectedPerson([]);
                setSelected([]);
              }}
            >
              Cancel
            </Button>
            {selectedPerson.length >= 1 ? (
              <Button onClick={() => setShowModal(false)}>OK</Button>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        {selectedPerson.length >= 1 ||
        selectedStaff.length >= 1 ||
        showClassDetails.length >= 1 ||
        departmentValue.length >= 1 ||
        selectedIncludeStaff.length >= 1 ? (
          <div className={Styles.lists2}>
          <div>
          {showClassDetails?<p style={{paddingLeft:"2vw"}}>Selected Grade</p>:""}
          <div className={Styles.listParts}>
          {showClassDetails &&
              showClassDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}> 
                    <div className={Styles.list}>
                      <div className={Styles.info}>
                        <p className={Styles.name}>
                          class :{student.class || "--"} | Section :
                          {student.section}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
         
          </div>
            <div>
            {departmentValue?<p style={{paddingLeft:"2vw"}}>Selected Department</p>:""}
            <div className={Styles.listParts}> {departmentValue &&
              departmentValue.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.info}>
                        <p className={Styles.name}>Department : {student}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}</div>
           
            </div>
            <div>
            {selectedPerson?<p style={{paddingLeft:"2vw"}}>Selected Students</p>:""}
            <div className={Styles.listParts}>
            {selectedPerson &&
              selectedPerson.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.url != "N/A" ? student.url : defaultUser}
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            </div>
            <div>
            {excludeStudentDetails?<p style={{paddingLeft:"2vw"}}>Exclude Students</p>:""}
            <div className={Styles.listParts}>
            {excludeStudentDetails &&
              excludeStudentDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.url != "N/A" ? student.url : defaultUser}
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            </div>
            <div>
           {selectedIncludeStaff?<p style={{paddingLeft:"2vw"}}>Selected Staff</p>:""}
           <div className={Styles.listParts}>
           {selectedIncludeStaff &&
              selectedIncludeStaff.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.url != "N/A" ? student.url : defaultUser}
                          alt="Staff-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
           </div> 
            
            </div>
            <div>
           {excludeStaffDetails?<p style={{paddingLeft:"2vw"}}>Exclude Staff</p>:""}
           <div className={Styles.listParts}>
           {excludeStaffDetails &&
            excludeStaffDetails.map((student, idx) => (
                <div className={Styles.checkBox}>
                  <div className={Styles.listing2}>
                    <div className={Styles.list}>
                      <div className={Styles.profile}>
                        <img
                          className={Styles.profileImg}
                          src={student.url != "N/A" ? student.url : defaultUser}
                          alt="Staff-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {student.emp_id ? (
                            <p>Emp ID: {student.emp_id}</p>
                          ) : student?.academic?.class ? (
                            <p className={Styles.class}>
                              {(student?.academic?.class &&
                                student?.academic?.class) ||
                                "--"}{" "}
                              |{" "}
                              {student.academic?.section?.toUpperCase() || "--"}
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
           </div> 
            
            </div>
          </div>
        ) : (
          <p className={Styles.selectedBox}>
            Please select Student/Staff/Admin/Management for notification
          </p>
        )}
      </div>
      <div className={Styles.createBox}>
        <div className={Styles.inputContainer}>
          <p className={Styles.heading}>
            Heading<span className={Styles.asterisk}>*</span> :
          </p>
          <Input
            className={Styles.inputbox}
            placeholder="Enter Heading..."
            name="heading"
            onChange={(e) => handleCreateData(e)}
          />
        </div>
        <div className={Styles.inputContainer}>
          <p className={Styles.heading}>
            Description<span className={Styles.asterisk}>*</span> :
          </p>
          <Textarea
            className={Styles.inputbox}
            name="Description"
            placeholder="Enter Description..."
            onChange={(e) => handleCreateData(e)}
          />
        </div>
        <div 
          style={{ display: "flex", justifyContent: "center", marginRight:"14px" }}
        >
        <label
          htmlFor="notificationImage"
        >
          <div
            style={{
              borderRadius: "5px",
              marginTop: "8px",
              borderRadius: "5px",
              padding: " 8px",
              marginTop: " 8px",
              cursor: "pointer",
              background: "#228b15",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              color: "white",
              fontWeight: "700",
            }}
            
          >
            Upload Image
          </div>
          <input
            hidden
            id="notificationImage"
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => handleFileEvent(e)}
          />
        </label>

          {image?( 
                <Tooltip
                  label="Preview Selected Images/PDF."
                  placement="bottom"
                >
                  <span className={Styles.pencilIcon}>
                    <MdPreview
                      onClick={modalPreview.onOpen}
                      style={{
                        fontSize: "35px",
                        marginTop: "4px",
                      }}
                    />
                  </span>
                </Tooltip>
              ):""}
        </div>
        
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "1vw",
            marginTop: "2vw",
          }}
        >
          <Button colorScheme="teal" onClick={() => handleCreateNotification()}>
            Create Notification
          </Button>
        </div>
      </div>
      <Modal size="xl" isOpen={modalGrade.isOpen} onClose={modalGrade.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Class</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ width: "100%", overflow: "hidden" }}>
              <Stepper handleClick={handleExcludeStudentList} />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="xl"
        isOpen={TeacherStepper.isOpen}
        onClose={TeacherStepper.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Class</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ width: "100%", overflow: "hidden" }}>
              <TeacherSteppers handleClick={handleTeacherModule} />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={modalPreview.isOpen} onClose={modalPreview.onClose}>
        <ModalOverlay />
        <ModalContent style={{ position: "sticky", top: "50px" }}>
          <ModalHeader>Selected images/pdf</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            {image&&image.map((file, index) => (
              <div key={index}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Selected Image ${index}`}
                    style={{
                      border: "2px solid black",
                      width: "400px",
                      height: "200px",
                      marginBottom: "20px",
                    }}
                  />
                ) : (
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                      marginBottom: "20px",
                      border: "2px solid black",
                    }}
                  >
                    <AiOutlineFilePdf
                      style={{
                        marginRight: "5px",
                        color: "red",
                        fontSize: "20px",
                      }}
                    />
                    {file.name}
                  </div> */}
                )}
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalPreview.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Notification;

import React, { useEffect, useState } from "react";
import Styles from "./Classroom.module.scss";
import { requestGetClass } from "services/staff-dashboard/attendance.service";
import { Select, Spinner } from "@chakra-ui/react";
import { requestClassTeacher } from "services/staff-dashboard/classroom.service";
import defaultUser from "../../../assets/icons/User.png";
import { Link } from "react-router-dom";
const StaffClassroom = () => {
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClass, setSelectedClass] = useState("All");
  const [classTeacher, setClassTeacher] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handlegetClass = async () => {
    const response = await requestGetClass();
    setClasses(response);
    setIsLoading(false);
  };
  useEffect(() => {
    handlegetClass();
  }, []);
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };
  const handleClassTeacher = async () => {
    const payload = {
      class: selectedClass,
    };
    try {
      setRequestInProgress(true);
      const response = await requestClassTeacher(payload);
      setRequestInProgress(false);
      setClassTeacher(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (selectedClass) {
      handleClassTeacher();
    }
  }, [selectedClass]);

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {classes && classes?.length > 0 ? (
            <>
              <div className={Styles.headerFirst}>
                <div className={Styles.statsWrapper}>
                  <p className={Styles.stats}>CLASSROOM |</p>
                  <p className={Styles.stats}>
                    Total Classes : {classTeacher?.length}
                  </p>
                </div>
                <div className={Styles.header}>
                  <div className={Styles.headerFilterWrapper}>
                    <div className={Styles.filterWrapper}>
                      <Select
                        name="class"
                        onChange={handleClassChange}
                        value={selectedClass}
                      >
                        <option key="all" value="All">
                          Grade All
                        </option>
                        {classes.map((grade, idx) => (
                          <option key={grade.class + idx} value={grade.class}>
                            Grade {grade.class}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Styles.body}>
                <div className={Styles.lists}>
                  {requestInProgress ? (
                    <div className={Styles.loaderWrapper}>
                      <Spinner />
                    </div>
                  ) : classTeacher.length === 0 ? (
                    <div className={Styles.noResult}>No Result Found</div>
                  ) : (
                    classTeacher.map((_class, idx) => (
                      <Link
                        className={Styles.list}
                        key={_class["_id"] + idx}
                        to={_class["_id"]}
                      >
                        <div className={Styles.profile}>
                          <img
                            className={Styles.profileImg}
                            src={
                              !_class?.class_teacher?.teacher_profile_url ||
                              !_class.class_teacher.teacher_profile_url.includes(
                                "http"
                              )
                                ? defaultUser
                                : _class.class_teacher.teacher_profile_url
                            }
                            alt="profile"
                          />
                        </div>
                        <div className={Styles.info}>
                          {/* <p className={Styles.class}>
                            Grade : {_class?.class || "--"} | Section :{" "}
                            {_class?.section || "--"}
                          </p> */}
                          <p className={Styles.classes}>
                            Grade : {_class?.class || "--"}
                          </p>
                          <p className={Styles.classes}>
                            Section : {_class?.section || "--"}
                          </p>
                          <hr
                            style={{
                              color: "red",
                              borderColor: "red",
                              height: "10px",
                            }}
                          />
                          <p className={Styles.name}>
                            {_class?.class_teacher?.name || "--"}
                          </p>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "150px",
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "60%",
                color: "red",
              }}
            >
              You don't have access to the Classroom Module.
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StaffClassroom;

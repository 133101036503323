import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Select,
  Input,
  Spinner,
  Tooltip,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import {
  requestSearchTeacher,
  requestListByTeacherDepartment,
  requestSearchTeacherDetails,
} from "services/admin-dashboard/staff.service";
import { debounce } from "utilities/utils";
import { useClassAndSections } from "hooks/classAndSection";
import Styles from "./Staff.module.scss";
import defaultUser from "../../../assets/icons/User.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle, AiOutlinePlusSquare } from "react-icons/ai";

const Staff = () => {
  // const { loading } = useClassAndSections();
  const downloadModal = useDisclosure();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [teachers, setTeachersList] = useState([]);
  const [inputFields, setInputFields] = useState({
    teacherName: "",
    teacherDepart: "",
  });
  const [isSearchQuery, setSearchQuery] = useState();
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [data, setData] = React.useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const initialFieldsState = {
    employeeId: true,
    designation: false,
    department: true,
    name: true,
    email: true,
    phoneNumber: true,
    gender: true,
    qualification: true,
    aadharNumber: false,
    address: false,
    bloodGroup: true,
    martialStatus: false,
    dob: false,
    nationality: false,
    motherTongue: false,
    religion: false,
    subReligion: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [showDetails, setShowDetails] = useState({
    employee: true,
    teacher: false,
  });
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const searching = () => {
    setSearchQuery(
      inputFields.teacherName?.length === 1 ||
        inputFields.teacherDepart?.length === 1
    );
  };

  useEffect(() => {
    fetchTeachers({ saveDefault: true });
    let input_data = localStorage.getItem("staff_filter")
      ? JSON.parse(localStorage.getItem("staff_filter"))
      : { teacherName: "", teacherDepart: "All" };
    setInputFields(input_data);
    if (input_data.teacherName !== "") {
      setShowSearchIcon(false);
    }
  }, []);

  useEffect(() => {
    searching();
    if (inputFields.teacherName !== "" || inputFields.teacherDepart !== "") {
      localStorage.setItem("staff_filter", JSON.stringify(inputFields));
      searchTeacherList();
    }
  }, [inputFields.teacherDepart]);

  const searchTeacherList = debounce(function () {
    fetchTeachers();
  }, 500);
  const fetchTeachers = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      if (inputFields.teacherDepart !== "") {
        const payload = {
          ...(inputFields.teacherName !== ""
            ? { name: inputFields.teacherName }
            : {}),
          department: inputFields.teacherDepart,
        };
        const response = await requestSearchTeacher(payload);
        if (response.error) {
          console.error(response.error);
        }
        const _result =
          (response?.result &&
            Array.isArray(response?.result) &&
            response?.result) ||
          (response?.result1 &&
            Array.isArray(response?.result1) &&
            response?.result1) ||
          (response && Array.isArray(response) && response) ||
          [];
        setTeachersList(_result);
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const fetchTeachersWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.teacherDepart !== ""
          ? { department: inputFields.teacherDepart }
          : {}),
      };
      const response = await requestSearchTeacher(payload);
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setTeachersList(_result);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleSearchClick = () => {
    if (inputFields.teacherName !== "") {
      localStorage.setItem("staff_filter", JSON.stringify(inputFields));
      fetchTeachers();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("staff_filter")) || {};
    localStorageData.teacherName = "";
    localStorage.setItem("staff_filter", JSON.stringify(localStorageData));
    setInputFields((prev) => ({
      ...prev,
      teacherName: "",
    }));
    fetchTeachersWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "teacherDepart") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        teacherName: "",
      }));
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  let teachersList = teachers;
  teachersList = teachersList.filter((i) => i.is_active);
  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getTeacherDepartment();
  }, []);

  // if (loading) {
  //   return (
  //     <div className={Styles.loaderWrapper}>
  //       <Spinner />
  //     </div>
  //   );
  // }
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  const fileName = inputFields.teacherDepart
    ? `SMU-Staff-${inputFields.teacherDepart}`
    : "SMU-Staff";

  const ExportToExcel = () => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        <Tooltip label="Download Teacher Details">
          <Button colorScheme="green">Archive</Button>
        </Tooltip>
      </div>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    const payload = {
      ...(inputFields.teacherName !== ""
        ? { name: inputFields.teacherName }
        : {}),
      department: inputFields.teacherDepart,
    };
    const res = await requestSearchTeacherDetails(payload);
    if (res) {
      let newArray = [];
      res?.result?.map((item) => {
        let obj = {};
        if (fields.employeeId) {
          obj["Employee ID"] = handleNAValue(item.emp_id);
        }
        if (fields.designation) {
          obj["Designation"] = handleNAValue(item.designation);
        }
        if (fields.department) {
          obj["Department"] = handleNAValue(item.department);
        }
        if (fields.name) {
          obj["Name"] = handleNAValue(item.name);
        }
        if (fields.email) {
          obj["Email"] = handleNAValue(item.email);
        }
        if (fields.phoneNumber) {
          obj["Phone No"] = handleNAValue(item.phoneNumber);
        }
        if (fields.gender) {
          obj["Gender"] = handleNAValue(item.personel.gender);
        }
        if (fields.qualification) {
          obj["Qualification"] = handleNAValue(item.personel.qualification);
        }
        if (fields.aadharNumber) {
          obj["Aadhar Number"] = handleNAValue(item.personel.aadhar_number);
        }
        if (fields.address) {
          obj["Address"] = handleNAValue(item.personel.present_address);
        }
        if (fields.bloodGroup) {
          obj["Blood Group"] = handleNAValue(item.personel.blood_group);
        }
        if (fields.dob) {
          obj["DOB"] = handleNAValue(item.personel.dob);
        }
        if (fields.nationality) {
          obj["Nationality"] = handleNAValue(item.personel.nationality);
        }
        if (fields.motherTongue) {
          obj["Mother Tongue"] = handleNAValue(item.personel.mother_tongue);
        }
        if (fields.religion) {
          obj["Religion"] = handleNAValue(item.personel.religion);
        }
        if (fields.subReligion) {
          obj["Sub Religion"] = handleNAValue(item.personel.sub_religion);
        }
        newArray.push({ ...obj });
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      employee: true,
      teacher: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.staffCount}>
          <p className={Styles.stats}>STAFF |</p>
          <p className={Styles.stats}>Total Staff : {teachers?.length}</p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="teacherName"
                  variant="filled"
                  placeholder="Search"
                  value={inputFields.teacherName}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.teacherDepart}
              onChange={handleInputChange}
              name="teacherDepart"
            >
              <option>All</option>
              {teacherDepartment.map((data) => (
                <option value={data.department}>{data.department}</option>
              ))}
            </Select>
          </div>
          <div className={Styles.optionWrapper}>
            <Tooltip label="Add Teacher">
              <div className={Styles.addButton}>
                <Link to="add">
                  <Button colorScheme="teal">Create</Button>
                </Link>
              </div>
            </Tooltip>
            {teachers?.length >= 1 ? (
              <ExportToExcel apiData={data} fileName={fileName} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : teachers?.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            teachers?.map((teacher, idx) => {
              let academics = teacher?.academic?.filter(
                (i) => i.teacher_id === teacher["_id"]
              );
              academics =
                academics?.length > 0 &&
                academics.map((i) => i.class + " | " + i.section).join(", ");

              return (
                <Link
                  to={teacher["_id"]}
                  key={teacher["_id"] + idx}
                  className={Styles.list}
                >
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={teacher?.url !== "N/A" ? teacher?.url : defaultUser}
                      alt="profile"
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>
                      {truncateText(teacher?.name, 15)}
                    </p>
                    <p className={Styles.number}>
                      Emp. ID : {teacher?.emp_id || "--"}
                    </p>
                    <p className={Styles.number}>
                      Department : {teacher?.department || "--"}
                    </p>
                  </div>
                </Link>
              );
            })
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <Modal
        isOpen={downloadModal.isOpen}
        onClose={() => {
          downloadModal.onClose();
          setFields(initialFieldsState);
          setShowDetails((prevDetails) => ({
            employee: true,
            teacher: false,
          }));
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Employee Details
                  </button>
                  {showDetails.employee ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("employee")}
                    />
                  )}
                </div>
                {showDetails.employee && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.designation}
                      onChange={() => {
                        setFields({
                          ...fields,
                          designation: !fields.designation,
                        });
                      }}
                    >
                      Designation
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.department}
                      onChange={() => {
                        setFields({
                          ...fields,
                          department: !fields.department,
                        });
                      }}
                    >
                      Department
                    </Checkbox>
                  </div>
                )}
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Teacher Details
                  </button>
                  {showDetails.teacher ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("teacher")}
                    />
                  )}
                </div>
                {showDetails.teacher && (
                  <>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.gender}
                        onChange={() => {
                          setFields({
                            ...fields,
                            gender: !fields.gender,
                          });
                        }}
                      >
                        Gender
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.qualification}
                        onChange={() => {
                          setFields({
                            ...fields,
                            qualification: !fields.qualification,
                          });
                        }}
                      >
                        Qualification
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.aadharNumber}
                        onChange={() => {
                          setFields({
                            ...fields,
                            aadharNumber: !fields.aadharNumber,
                          });
                        }}
                      >
                        Aadhar No
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.address}
                        onChange={() => {
                          setFields({
                            ...fields,
                            address: !fields.address,
                          });
                        }}
                      >
                        Address
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.bloodGroup}
                        onChange={() => {
                          setFields({
                            ...fields,
                            bloodGroup: !fields.bloodGroup,
                          });
                        }}
                      >
                        Blood Group
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.martialStatus}
                        onChange={() => {
                          setFields({
                            ...fields,
                            martialStatus: !fields.martialStatus,
                          });
                        }}
                      >
                        Martial Status
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.dob}
                        onChange={() => {
                          setFields({
                            ...fields,
                            dob: !fields.dob,
                          });
                        }}
                      >
                        DOB
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.nationality}
                        onChange={() => {
                          setFields({
                            ...fields,
                            nationality: !fields.nationality,
                          });
                        }}
                      >
                        Nationality
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.motherTongue}
                        onChange={() => {
                          setFields({
                            ...fields,
                            motherTongue: !fields.motherTongue,
                          });
                        }}
                      >
                        Mother Tongue
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.religion}
                        onChange={() => {
                          setFields({
                            ...fields,
                            religion: !fields.religion,
                          });
                        }}
                      >
                        Religion
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.subReligion}
                        onChange={() => {
                          setFields({
                            ...fields,
                            subReligion: !fields.subReligion,
                          });
                        }}
                      >
                        Sub Religion
                      </Checkbox>
                    </div>
                  </>
                )}
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Staff;

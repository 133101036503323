import React, { useEffect, useRef } from "react";
import Styles from "./Calendar.module.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import { HiAcademicCap } from "react-icons/hi";
import {
  requestDateEvents,
  requestGetAcademicSession,
  requestMonthEvents,
} from "services/staff-dashboard/calendar.service";
import "./Calendar.module.scss";
import moment from "moment";
import { requestHolidays } from "services/staff-dashboard/attendance.service";
const StaffCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const currentYear = new Date().getFullYear();
  const modalSession = useDisclosure();
  const pastYears = 15;
  const futureYears = 15;

  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [inputFields, setInputFields] = useState({
    date: "",
    event: {
      title: "",
      description: "",
      category: "",
      event_visible: false,
    },
  });
  const [monthEvent, setMonthEvent] = useState([]);
  const [dateEvent, setDateEvent] = useState([]);
  const [year, setYear] = useState(localStorage.getItem("yearResult") || "");
  const [academicData, setAcademicData] = useState("");
  const [holidays, setHolidays] = useState([]);
  const events = [];
  const yearOptions = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 1 + index
  );
  const calendarRef = useRef(null);
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "7px",
      height: "10px",
    };
    const eventsOnDate = monthEvent.find(
      (event) => new Date(event.date).toDateString() === date.toDateString()
    );
    let eventContent = "";
    if (eventsOnDate && eventsOnDate.events > 0) {
      const dotColor = "#4e13c8";
      const eventCount = eventsOnDate.events;
      const remainingCount = eventCount - 1;
      const dotStyle = `
        background-color: ${dotColor};
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
      `;
      const remainingCountStyle = `
        display: inline-block;
        vertical-align: middle;
        line-height: 10px;
        font-size: 15px;
        font-weight: bold;
        color: ${dotColor};
      `;

      eventContent = `
        <div class="${Styles.eventContainer}">
          <div class="${Styles.eventDot}" style="${dotStyle}"></div>
          ${
            remainingCount > 0
              ? `<div class="${Styles.remainingCount}" style="${remainingCountStyle}">+${remainingCount}</div>`
              : ""
          }
        </div>
      `;

      if (eventCount === 1) {
        eventContent = `
          <div class="${Styles.eventContainer}">
            <div class="${Styles.eventDot}" style="${dotStyle}"></div>
            <div class="${Styles.remainingCount}" style="${remainingCountStyle}">+0</div>
          </div>
        `;
      }
    }

    return {
      html: `
        <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start; height: 50px;">
          <div class="${Styles.date}" style="height: ${
        isSelected ? "auto" : "auto"
      };">${date.getDate()}</div>
          ${eventContent}
        </div>
      `,
    };
  };
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setDateEvent([]);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setDateEvent([]);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };

  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setDateEvent({});
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setDateEvent({});
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };

  const renderHeader = () => {
    const monthName = new Date(
      selectedYear,
      selectedMonth,
      1
    ).toLocaleDateString("default", { month: "long" });
    const yearString = selectedYear.toString();
    const headerText = `${monthName} ${yearString}`;
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={selectedMonth === "" ? "" : Styles.selected}
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize: selectedMonth !== "" ? "1.5em" : "inherit",
              paddingLeft: "20px",
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={selectedYear === "" ? "" : Styles.selected}
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize: selectedMonth !== "" ? "1.5em" : "inherit",
              paddingLeft: "8px",
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);

    const selectedEvents = events.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getFullYear() === year &&
        eventDate.getMonth() === month &&
        eventDate.getDate() === date
      );
    });
    setSelectedEvents(selectedEvents);
  };

  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  const updateEventDetails = () => {
    if (selectedDate) {
      const selectedDateString = selectedDate.toLocaleDateString();
      const eventsOnSelectedDate = events.filter((event) => {
        const eventDateString = new Date(event.date).toLocaleDateString();
        return eventDateString === selectedDateString;
      });
      if (eventsOnSelectedDate.length > 0) {
        const selectedEventProps = {
          name: eventsOnSelectedDate[0].name,
          des: eventsOnSelectedDate[0].des,
          date: eventsOnSelectedDate[0].date,
        };
        setSelectedEvent(selectedEventProps);
      } else {
        setSelectedEvent(null);
      }
      setSelectedEvents(eventsOnSelectedDate);
    }
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    updateEventDetails();
  }, [selectedDate]);
  const handleMonthsEvents = async () => {
    var date = new Date();
    const startDate = new Date(selectedYear, selectedMonth, 1);
    const endDate = new Date(selectedYear, selectedMonth + 1, 0);
    const payload = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    const res = await requestMonthEvents(payload);
    setMonthEvent(res.data);
  };
  useEffect(() => {
    handleMonthsEvents();
    handleHolidays();
  }, [selectedMonth, selectedYear]);
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    const selectedDateString = clickedDate.toLocaleDateString();
    const eventsOnSelectedDate = events.filter((event) => {
      const eventDateString = new Date(event.date).toLocaleDateString();
      return eventDateString === selectedDateString;
    });
    setSelectedEvent(null);
    setSelectedEvents(eventsOnSelectedDate);
    calendarRef.current.getApi().unselect(); // Unselect the clicked date
    setTimeout(() => {
      calendarRef.current.getApi().updateSize(); // Update the calendar size after a delay
    }, 0);
  };
  const handleDateEvents = async () => {
    const payload = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = await requestDateEvents(payload);
    setDateEvent(res.data);
  };
  useEffect(() => {
    if (selectedDate) {
      handleDateEvents();
    }
  }, [selectedDate]);
  const handleGetSession = async () => {
    const payload = {
      session: year,
    };
    const response = await requestGetAcademicSession(payload);
    setAcademicData(response);
  };
  useEffect(() => {
    if (year) {
      handleGetSession();
      setAcademicData("");
    }
  }, [year]);
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi.batchRendering(() => {
      highlightDates();
      calendarApi.render();
    });
  }, [holidays]);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.statsWrapper}>
        <p className={Styles.stats}>ACADEMIC CALENDAR</p>
      </div>
      <div className={Styles.body}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "600px",
            }}
          >
            <div
              style={{
                border: "2px solid black",
                maxHeight: "400px",
                // position: "fixed",
              }}
            >
              {/* className={Styles.calenderStyle}> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid black",
                  padding: "5px",
                }}
              >
                <div>
                  <button onClick={handlePreviousMonth}>
                    <FaCaretSquareLeft style={{ fontSize: "30px" }} />
                  </button>
                </div>
                <div>{renderHeader()}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className={Styles.todayButton}
                    onClick={handleTodayButtonClick}
                    style={{ display: isToday() ? "none" : "block" }}
                  >
                    Today
                  </button>
                  <button onClick={handleNextMonth}>
                    <FaCaretSquareRight style={{ fontSize: "30px" }} />
                  </button>
                </div>
              </div>
              <div style={{ overflowY: "auto", maxHeight: "280px" }}>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  selectable={true}
                  dateClick={handleDateClick}
                  dayCellContent={dayCellContent}
                  dayHeaderContent={dayHeaderContent}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "10px",
                fontWeight: "650",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#9c9695",
                    marginRight: "10px",
                  }}
                ></div>
                <p>Holiday </p>
              </div>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#4e13c8",
                    marginRight: "10px",
                    borderRadius: "100%",
                  }}
                ></div>
                <p>An Event</p>
              </div>
            </div>
          </div>
          <div className={Styles.detailsContainer}>
            {dateEvent?.events && dateEvent?.events?.length !== 0 ? (
              <div className={Styles.detailsTitle}>
                <p className={Styles.heading}>
                  Date: {moment(dateEvent.date).format("DD-MM-YYYY")} | No. of
                  Events: &nbsp;{dateEvent.events.length}
                </p>
              </div>
            ) : (
              <div
                style={{
                  width: "80%",
                  textAlign: "end",
                }}
              >
                {selectedDate ? (
                  <p className={Styles.heading}>
                    Date: {moment(selectedDate).format("DD-MM-YYYY")} | No. of
                    Events: 0
                  </p>
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span className={Styles.centerText}>
                      Please Select Date
                    </span>
                  </div>
                )}
              </div>
            )}

            {dateEvent?.events &&
              dateEvent?.events?.map((item) => (
                <div className={Styles.eventWrap} key={item._id}>
                  <div>
                    <p className={Styles.subheading}>
                      Title:{" "}
                      <span className={Styles.subheadingDetail}>
                        {item.title}
                      </span>
                    </p>
                    <p className={Styles.subheading}>
                      Description:{" "}
                      <span className={Styles.subheadingDetail}>
                        {item?.description ? item.description : "-"}
                      </span>
                    </p>
                    {/* <p className={Styles.subheading}>
                      Category: {item.category}
                    </p> */}
                    {item.category === "HOLIDAY" ? (
                      <p className={Styles.subheading}>
                        Category:{" "}
                        <span className={Styles.holiday}>{item.category}</span>
                      </p>
                    ) : (
                      <p className={Styles.subheading}>
                        Category:{" "}
                        <span className={Styles.subheadingDetail}>
                          {item.category}
                        </span>
                      </p>
                    )}
                    <p className={Styles.subheading}>
                      Show in Events:
                      <Switch
                        colorScheme="green"
                        // disabled
                        readOnly
                        style={{ margin: "5px" }}
                        isChecked={item.event_visible}
                        size="md"
                      />
                    </p>
                  </div>
                </div>
              ))}
            {(selectedDate && dateEvent?.events?.length == 0) ||
            dateEvent == null ? (
              <div
                className={Styles.eventWrap}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                No Events on Selected Date
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={Styles.academicYear} onClick={modalSession.onOpen}>
          <HiAcademicCap className={Styles.add} />
        </div>
        <Modal
          size="xl"
          isOpen={modalSession.isOpen}
          onClose={modalSession.onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent className={Styles.customModalContent}>
            <ModalHeader>Academic Session</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <div className={Styles.academicSession}>
                  <div>
                    <Select
                      value={year}
                      required
                      onChange={(e) => setYear(e.target.value)}
                      name="year"
                      placeholder="Academic Year"
                    >
                      {yearOptions.map((year, index) => (
                        <option key={year} value={year}>
                          {`${year}-${(year + 1).toString().slice(-2)}`}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
                {academicData.error ? (
                  <div className={Styles.noResult}>No Data Found</div>
                ) : academicData ? (
                  <div className={Styles.sessionBody}>
                    <div className={Styles.sessionLeft}>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          Start-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].start_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          Mid-term End-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].mid_term_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginRight: "5px", fontWeight: "bold" }}>
                          End-Date
                        </p>
                        <div>
                          {new Date(
                            academicData?.data?.specification[0].end_date
                          ).toLocaleDateString("en-GB")}
                        </div>
                      </div>
                    </div>
                    <div className={Styles.sessionRight}>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginLeft: "18px", fontWeight: "bold" }}>
                          Total Mid-term Days
                        </p>
                        <div>
                          <p>
                            {academicData?.data?.specification[0].mid_term_days}
                          </p>
                        </div>
                      </div>
                      <div className={Styles.sessionLeftHead}>
                        <p style={{ marginLeft: "18px", fontWeight: "bold" }}>
                          Total Annual days
                        </p>
                        <div style={{ marginLeft: "20px" }}>
                          <p>
                            {" "}
                            {academicData?.data?.specification[0].total_days}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.noResult}>
                    Please Select Academic Year
                  </div>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={modalSession.onClose}
                >
                  Close
                </Button>
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default StaffCalendar;

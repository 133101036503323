import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

export const _createUserWithEmailAndPassword = async (payload = {}) => {
  try { 
    const { email, password } = payload
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (userCredential && !!userCredential?.user) {
      return true
    }
    return false
  } catch (e) {
    console.error(e)
    const { _tokenResponse } = e?.customData
    const { error } = _tokenResponse
    if (error.message === 'EMAIL_EXISTS') {
      return false
    }
    return false
  }
}

import axios from "axios";
import React, { useState, useEffect } from "react";
import Styles from "./Header.module.scss";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { TbUserCircle } from "react-icons/tb";
import { RiUserShared2Line } from "react-icons/ri";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { getRequestOptions } from "services/utils";
import defaultUser from "../../assets/icons/User.png";
import { useToast } from "@chakra-ui/react";
const ActionButtons = (props) => {
  const toast = useToast();
  const handleBack = () => {
    props.previousStep();
  };
  const handleSwitchStudent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login-select`,
        {
          phoneNumber: props?.completeCallback[0]?.selectedStudent?.phoneNumber,
          jwtkey: props?.completeCallback[1]?.jwtKey,
          email: props?.completeCallback[0]?.selectedStudent?.email,
          user_id: props?.completeCallback[0]?.selectedStudent?._id,
          role: "student",
          isSwitch: true,
        },
        getRequestOptions()
      );
      if (response.data.success) {
        localStorage.setItem("student_name", response.data.profile.name);
        localStorage.setItem(
          "student_class",
          response.data.profile.academic.class
        );
        localStorage.setItem(
          "student_section",
          response.data.profile.academic.section
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("studentId", response.data.payload.id);
        localStorage.setItem("Permission", response.data.permission);
        toast({
          title: "Please wait for switching the student.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleFinish = () => {
    props.lastStep();
    handleSwitchStudent();
  };
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <Button onClick={handleBack}>Back</Button>
          </Col>
        )}
        <Col>
          {props.currentStep === props.totalSteps && (
            <Button onClick={handleFinish}>Switch</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const StepOne = (props) => {
  const [studentList, setStudentList] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [jwtKey, setJwtKey] = useState("");
  const studentEmail = localStorage.getItem("student_email");
  const loginStudent = localStorage.getItem("student_name");
  const handleStudentList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/switching-account`,
        { email: studentEmail },
        getRequestOptions()
      );
      setStudentList(response.data);
      setJwtKey(response.data.jwtkey);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleStudentList();
  }, []);
  const handleNext = (student) => {
    setSelectedStudent(student);
    props.nextStep();
    props.userCallback([{ selectedStudent: student }, { jwtKey: jwtKey }]);
  };
  return (
    <div>
      <FormGroup>
        <div className={Styles.containerBox}>
          {studentList &&
            studentList.students.map((student) => (
              <div
                className={Styles.list}
                style={
                  loginStudent !== student.name
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed", background: "rgb(135 222 135)" }
                }
                onClick={() =>
                  loginStudent !== student.name ? handleNext(student) : null
                }
              >
                <div style={{ display: "flex" }}>
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={
                        // student.profile_url != "N/A"
                        //   ? student.profile_url
                        //   :
                        defaultUser
                      }
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>{student.name}</p>
                    <p className={Styles.class}>
                      {student?.class || "--"} |{" "}
                      {student?.section?.toUpperCase() || "--"}
                    </p>
                    <p className={Styles.number}>+91 {student.phoneNumber}</p>
                  </div>
                </div>
                {loginStudent === student.name ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <li style={{ color: "green", fontSize: "20px" }}></li>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </FormGroup>
      <ActionButtons {...props} />
    </div>
  );
};
const StepTwo = (props) => {
  const loginStudent = localStorage.getItem("student_name");
  return (
    <div>
      <p style={{ marginBottom: "10px" }}>
        Are you switching from Student{" "}
        <span style={{ fontWeight: "bold" }}>{loginStudent}</span> to{" "}
        <span style={{ fontWeight: "bold" }}>
          {props?.completeCallback[0]?.selectedStudent?.name}
        </span>
        ?
      </p>
      <ActionButtons {...props} />
    </div>
  );
};
const SwitchStepper = () => {
  const [stepWizard, setStepWizard] = useState(null);
  const [userData, setUserData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };
  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };
  const step1Data = (val) => {
    setUserData(val);
  };
  return (
    <>
      <div>
        <Stepper activeStep={activeStep}>
          <Step
            label="Select Student"
            children={<TbUserCircle fontSize={28} />}
          />
          <Step
            label="Switch Student"
            children={<RiUserShared2Line fontSize={20} />}
          />
        </Stepper>
        <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
          <StepOne userCallback={step1Data} />
          <StepTwo completeCallback={userData} />
        </StepWizard>
      </div>
    </>
  );
};

export default SwitchStepper;

import React, { useEffect, useState } from "react";
import Styles from "../Midterm.module.scss";
import { useParams } from "react-router-dom";
const CoscholasticRecord = ({
  gradeCategory,
  type,
  gradeList,
  attendanceList,
  grade,
}) => {
  const params = useParams();
  const [term, setTerm] = useState("");
  const [reportClass, setReportClass] = useState("");

  console.log("term", term);
  console.log("class",reportClass)
  useEffect(() => {
    setTerm(params.id);
    setReportClass(grade);
  }, [term, reportClass]);
  return (
    <div className={type === "template" ? Styles.markContainer : ""}>
      <div className={Styles.tableWrapper}>
        <section className={Styles.title2}>CO-SCHOLASTIC RECORD</section>
        <table>
          {gradeCategory.includes("Grade11") ||
          gradeCategory.includes("Grade12") ? (
            <tbody>
              <tr className={Styles.tableHeader}>
                <td className={Styles.leftcenteredColumn}>GENERAL STUDIES</td>
                <td className={Styles.leftcenteredColumn}>
                  HEALTH AND PHYSICAL EDUCATION
                </td>
                <td className={Styles.leftcenteredColumn}>WORK EDUCATION</td>
                <td>ATTENDANCE</td>
              </tr>
              <tr>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["GENERAL STUDIES"])}
                </td>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["HEALTH AND PHYSICAL EDUCATION"])}
                </td>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["WORK EDUCATION"])}
                </td>
                <td style={{ height: "10px" }}>
                  {attendanceList.student_attendace_count}/
                  {attendanceList.total_attendace_count}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr className={Styles.tableHeader}>
                <td className={Styles.leftcenteredColumn}>ART EDUCATION</td>
                <td className={Styles.leftcenteredColumn}>
                  HEALTH AND PHYSICAL EDUCATION
                </td>
                <td className={Styles.leftcenteredColumn}>WORK EDUCATION</td>
                <td className={Styles.leftcenteredColumn}>DISCIPLINE</td>
                {term == "annual" &&
                (reportClass == "3" ||
                  reportClass == "4" ||
                  reportClass == "5" ||
                  reportClass == "6" ||
                  reportClass == "7" ||
                  reportClass == "8") ? (
                  <td className={Styles.leftcenteredColumn}>COMM. ENGLISH</td>
                ) : null}
                {/* {gradeCategory.includes("Grade9") ||
                gradeCategory.includes("Grade10") ? (
                  <td className={Styles.leftcenteredColumn}>LIFE SKILL</td>
                ) : (
                  ""
                )} */}
                <td className={Styles.leftcenteredColumn}>ATTENDANCE</td>
              </tr>
              <tr>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["ART EDUCATION"])}
                </td>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["HEALTH AND PHYSICAL EDUCATION"])}
                </td>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["WORK EDUCATION"])}
                </td>
                <td className={Styles.emptyRow}>
                  {gradeList.map((i) => i["DISCIPLINE"])}
                </td>
                {term == "annual" &&
                (reportClass == "3" ||
                  reportClass == "4" ||
                  reportClass == "5" ||
                  reportClass == "6" ||
                  reportClass == "7" ||
                  reportClass == "8") ? (
                    
                  <td className={Styles.emptyRow}>
                    {gradeList.map((i) => i["COMMUNICATIVE ENGLISH"])}
                  </td>
                ) : null}
                {/* {gradeCategory.includes("Grade9") ||
                gradeCategory.includes("Grade10") ? (
                  <td className={Styles.emptyRow}>
                    {gradeList.map((i) => i["LIFE SKILL"])}
                  </td>
                ) : (
                  ""
                )} */}
                <td style={{ height: "10px" }}>
                  {attendanceList.student_attendace_count}/
                  {attendanceList.total_attendace_count}
                </td>
              </tr>
              {term == "annual" &&
              (reportClass == "9" ||
                reportClass == "11" ||
                reportClass == "1" ||
                reportClass == "2" ||
                reportClass == "3" ||
                reportClass == "4" ||
                reportClass == "5" ||
                reportClass == "6" ||
                reportClass == "7" ||
                reportClass == "8") ? (
                <tr style={{ "border-top": "solid 2px purple" }}>
                  <td
                    style={{
                      // "border-top": "solid 2px purple",
                      fontWeight: "700",
                      fontSize: "11px",
                      textAlign: "left",
                      wordSpacing: "5px",
                    }}
                    colSpan={5}
                  >
                    Congratulations !! You are promoted to Grade{" "}
                    {parseInt(reportClass) + 1}
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
export default CoscholasticRecord;

import React from "react";
import Birthday from "../../../components/Birthday/Birthday";
import DashboardFeatures from "../../../components/DashboardFeatures/DashboardFeatures";
import { useLocation } from "react-router-dom";

const AdminDashboardLanding = () => {
  const location = useLocation();
  const renderList = location;
  return (
    <>
      <Birthday />
      <DashboardFeatures page="admin" id={renderList} />
    </>
  );
};

export default AdminDashboardLanding;

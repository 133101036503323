import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Styles from "./Dashboard.module.scss";
import LogoutSmall from "../../assets/icons/Logout .png";
import {
  Tooltip,
  IconButton,
  Image,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { FcHome } from "react-icons/fc";
import help from "assets/icons/Help.png";
import { useAuth } from "contexts/auth-context";
import { CgCloseO } from "react-icons/cg";
import { BiSearchAlt, BiInfoCircle } from "react-icons/bi";
import { IoMdSettings, IoMdNotificationsOutline } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import Managementimg from "assets/icons/Management.png";
import { AiOutlineUser } from "react-icons/ai";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import {
  requestAllNotifications,
  requestSingleNotificationDetail,
} from "services/common.service";
import { academicYear } from "utilities/utils";
const ManagementDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const notificationModal = useDisclosure();
  const [isLogoutVisible, setLogoutVisible] = useState(true);
  const { isSearch, setIsSearch } = useAuth();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(
    parseInt(localStorage.getItem("notificationCount")) || 0
  );
  const [skip, setSkip] = useState(0);
  const [showLessVisible, setShowLessVisible] = useState(false);
  const [singleNotification, setSingleNotifcation] = useState([]);
  const [expandedNotificationID, setExpandedNotificationID] = useState(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [canCallNotifications, setCanCallNotifications] = useState(true);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const handleSearchChange = (event) => {
    setIsSearch(event.target.value);
  };
  const handleToggleSearch = () => {
    setIsSearchVisible((prevVisible) => !prevVisible);
    if (!isSearchVisible) {
      setIsSearch("");
    }
  };
  const handleClearSearch = () => {
    setIsSearch("");
    setIsSearchVisible(false);
  };
  const renderList = location;
  //checking current path to render logout button
  useEffect(() => {
    if (
      location.pathname === "/management/dashboard/logout" ||
      location.pathname === "/management/dashboard/logoutall"
    )
      setLogoutVisible(false);
    else setLogoutVisible(true);
  }, [location.pathname]);
  const isDashboardPage = location.pathname === "/management/dashboard";
  const handleNotifications = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        skip: skip,
      };
      const response = await requestAllNotifications(payload);
      setNotifications(response);
      setNotificationCount(response.notifications_count);
      localStorage.setItem("notificationCount", response.notifications_count);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const loadMoreNotifications = (action) => {
    if (action === "prev" && skip > 0) {
      setSkip((prevSkip) => prevSkip - 1);
    } else if (action === "next") {
      setSkip((prevSkip) => prevSkip + 1);
    }
  };

  const handleReadMore = async (id) => {
    try {
      const payload = {
        notification_id: id,
      };
      const response = await requestSingleNotificationDetail(payload);
      setSingleNotifcation(response);
      setExpandedNotificationID(id);
      if (response.notifications_count) {
        setNotificationCount(response.notifications_count);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    const savedCount = localStorage.getItem("notificationCount");
    if (savedCount) {
      setNotificationCount(parseInt(savedCount));
    }
  }, []);
  useEffect(() => {
    if (isDashboardPage) {
      handleNotifications();
    }
    setShowLessVisible(skip > 0);
  }, [skip, isDashboardPage]);
  const handleHover = () => {
    setIsNotificationsOpen(true);
    if (canCallNotifications) {
      handleNotifications();
      setCanCallNotifications(false);
      setTimeout(() => {
        setCanCallNotifications(true);
      }, 5000);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timeFormat = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${day}-${month}-${year}, ${formattedHours}:${minutes}:${seconds} ${timeFormat}`;
  };

  const yearResult = academicYear();
  localStorage.setItem("yearResult", yearResult);
  return (
    <div className={Styles.body}>
      <Header />
      <div className={Styles.bodyDiv}>
        <div className={Styles.navBar}>
          <p className={Styles.name}>Hello, Management </p>
          {screenSize && screenSize?.width >= 500 ? (
            <div className={Styles.navLeft}>
              {isDashboardPage && (
                <Tooltip label="Search">
                  <div>
                    {isSearchVisible ? (
                      <div style={{ display: "flex" }}>
                        <input
                          variant="filled"
                          placeholder="Search Module"
                          value={isSearch}
                          style={{ paddingLeft: "10px", fontWeight: "600" }}
                          onChange={handleSearchChange}
                        />
                        <CgCloseO
                          className={Styles.iconImages}
                          cursor="pointer"
                          onClick={handleClearSearch}
                        />
                      </div>
                    ) : (
                      <BiSearchAlt
                        className={Styles.iconImages}
                        cursor="pointer"
                        onClick={handleToggleSearch}
                      />
                    )}
                  </div>
                </Tooltip>
              )}
              <Tooltip label="Home">
                <Link
                  to={{ pathname: "/management/dashboard" }}
                  state={{ renderList }}
                >
                  <div className={Styles.navItem}>
                    <FcHome className={Styles.iconImages} fontSize="40" />
                  </div>
                </Link>
              </Tooltip>
              <Tooltip label="Settings">
                <Link to="/management/dashboard/settings">
                  <div className={Styles.navItem}>
                    <IoMdSettings color="#B92B2B" fontSize="40" />
                  </div>
                </Link>
              </Tooltip>
              <Tooltip
                isOpen={isNotificationsOpen}
                label="Notification"
                placement="top"
              >
                <div
                  className={Styles.navItems}
                  onMouseEnter={handleHover}
                  onMouseLeave={() => setIsNotificationsOpen(false)}
                >
                  <div className={Styles.notificationIcon}>
                    <IoMdNotificationsOutline className={Styles.iconImages} />
                    {notificationCount && notificationCount > 0 ? (
                      <span className={Styles.notificationCount}>
                        {notificationCount}
                      </span>
                    ) : null}
                    {isNotificationsOpen && (
                      <div className={Styles.notificationsDropdown}>
                        <p className={Styles.notificationHeading}>
                          NOTIFICATIONS{" "}
                          {notificationCount && notificationCount > 0
                            ? `(${notificationCount})`
                            : null}
                        </p>
                        <div className={Styles.notificationsDropdownItem}>
                          <div>
                            <div className={Styles.lists}>
                              {requestInProgress ? (
                                <Spinner />
                              ) : notifications &&
                                notifications?.notifications?.length === 0 ? (
                                <div className={Styles.noResult}>
                                  No Notification Available
                                </div>
                              ) : (
                                notifications &&
                                notifications?.notifications &&
                                notifications.notifications.map(
                                  (notification) => (
                                    <div
                                      className={
                                        notification.is_read
                                          ? Styles.list
                                          : Styles.listWithoutRead
                                      }
                                      key={notification._id}
                                    >
                                      <div className={Styles.content}>
                                        <div
                                          className={Styles.profileContainer}
                                        >
                                          <AiOutlineUser
                                            fontSize={30}
                                            style={{
                                              border: "2px solid black",
                                              borderRadius: "100%",
                                              marginRight: "10px",
                                            }}
                                          />
                                          <p className={Styles.fontSize}>
                                            {notification.user_name} |{" "}
                                            {notification.user_role.toUpperCase()}
                                          </p>
                                        </div>
                                        <p className={Styles.fontSize}>
                                          {notification.title}
                                        </p>
                                        <p className={Styles.date}>
                                          {formatDate(notification.createdAt)}
                                        </p>
                                        {notification._id ===
                                        expandedNotificationID ? (
                                          <>
                                            <p className={Styles.fontSize}>
                                              {
                                                singleNotification?.notification
                                                  ?.description
                                              }
                                            </p>
                                            <div
                                              className={Styles.delContainer}
                                            >
                                              <Tooltip label="Read Less">
                                                <div>
                                                  <MdOutlineKeyboardArrowUp
                                                    className={
                                                      Styles.iconImages
                                                    }
                                                    cursor="pointer"
                                                    onClick={() =>
                                                      setExpandedNotificationID(
                                                        null
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </>
                                        ) : (
                                          <div className={Styles.delContainer}>
                                            <Tooltip label="Read More">
                                              <div>
                                                <MdOutlineKeyboardArrowDown
                                                  className={Styles.iconImages}
                                                  cursor="pointer"
                                                  onClick={() =>
                                                    handleReadMore(
                                                      notification._id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )
                              )}
                            </div>
                            <div>
                              {notifications &&
                                notifications?.notifications?.length >= 1 && (
                                  <div className={Styles.buttonLoad}>
                                    <Button
                                      onClick={() =>
                                        loadMoreNotifications("prev")
                                      }
                                      colorScheme="teal"
                                      isDisabled={skip === 0}
                                    >
                                      Previous
                                    </Button>
                                    <p className={Styles.pageCount}>
                                      {skip === 0 ? 1 : skip + 1}
                                    </p>
                                    <Button
                                      onClick={() =>
                                        loadMoreNotifications("next")
                                      }
                                      colorScheme="teal"
                                      isDisabled={
                                        skip === notifications.page_count
                                      }
                                    >
                                      Next
                                    </Button>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
              <Tooltip label="Help">
                <Link to="/management/dashboard/help">
                  <div className={Styles.navItem}>
                    <img src={help} className={Styles.iconImages} />
                  </div>
                </Link>
              </Tooltip>
              <Tooltip label="App Info">
                <Link to="/management/dashboard/about">
                  <div className={Styles.navItem}>
                    <BiInfoCircle className={Styles.iconImages} />
                  </div>
                </Link>
              </Tooltip>
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  marginRight: "5px",
                  marginTop: "-10px",
                }}
              >
                |
              </p>
              {isLogoutVisible ? (
                <Link to="/management/dashboard/logout">
                  <Tooltip
                    label="Logout of this device."
                    aria-label="A tooltip"
                  >
                    <div className={Styles.navItem}>
                      <img
                        src={LogoutSmall}
                        alt="logout"
                        className={Styles.iconImages}
                      />
                    </div>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className={Styles.navLeft}>
              {isDashboardPage && (
                <div>
                  {isSearchVisible ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        variant="filled"
                        placeholder="Search Module"
                        value={isSearch}
                        className={Styles.crossIcon}
                        onChange={handleSearchChange}
                      />
                      <CgCloseO
                        color="#b92b2b"
                        fontSize="40"
                        onClick={handleClearSearch}
                      />
                    </div>
                  ) : (
                    <BiSearchAlt
                      color="#b92b2b"
                      fontSize="40"
                      onClick={handleToggleSearch}
                    />
                  )}
                </div>
              )}
              <Tooltip label="Notification">
                <div
                  className={Styles.navItems}
                  onClick={notificationModal.onOpen}
                >
                  <div className={Styles.notificationIcon}>
                    <IoMdNotificationsOutline className={Styles.iconImages} />
                    {notifications && notifications.notifications_count > 0 && (
                      <span className={Styles.notificationCount}>
                        {notificationCount}
                      </span>
                    )}
                  </div>
                </div>
              </Tooltip>
              <Link
                to={{ pathname: "/management/dashboard" }}
                state={{ renderList }}
              >
                <div className={Styles.navItem}>
                  <FcHome className={Styles.iconImages} />
                </div>
              </Link>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<RxHamburgerMenu />}
                  variant="outline"
                  style={{ color: "red", border: "1px solid red" }}
                />
                <MenuList>
                  <Link to="/management/dashboard/profile">
                    <MenuItem minH="48px">
                      <Image
                        boxSize="2rem"
                        borderRadius="full"
                        src={Managementimg}
                        // alt="Fluffybuns the destroyer"
                        mr="12px"
                      />

                      <span>Profile</span>
                    </MenuItem>
                  </Link>
                  <Link to="/management/dashboard/help">
                    <MenuItem minH="48px">
                      <Image
                        boxSize="2rem"
                        borderRadius="full"
                        src={help}
                        // alt="Fluffybuns the destroyer"
                        mr="12px"
                      />
                      <span>Help</span>
                    </MenuItem>
                  </Link>
                  <Link to="/management/dashboard/settings">
                    <MenuItem minH="48px">
                      <IoMdSettings
                        color="#b92b2b"
                        fontSize="40"
                        style={{ height: "2rem", marginRight: "5px" }}
                      />
                      <span>Setting</span>
                    </MenuItem>
                  </Link>
                  <Link to="/management/dashboard/about">
                    <MenuItem minH="48px">
                      <BiInfoCircle
                        color="#b92b2b"
                        fontSize="40"
                        style={{ height: "2rem", marginRight: "5px" }}
                      />
                      <span>App Info</span>
                    </MenuItem>
                  </Link>
                  <Link to="/management/dashboard/logout">
                    <MenuItem minH="48px">
                      <Image
                        boxSize="2rem"
                        borderRadius="full"
                        src={LogoutSmall}
                        // alt="Fluffybuns the destroyer"
                        mr="12px"
                      />
                      <span>Logout</span>
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.Wrapper}>
        <Outlet />
      </div>
      <Modal
        isOpen={notificationModal.isOpen}
        onClose={() => {
          notificationModal.onClose();
          handleNotifications();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent style={{ overflowY: "auto" }}>
          <ModalHeader>
            <p className={Styles.notificationHeading}>
              NOTIFICATIONS{" "}
              {notificationCount && notificationCount > 0
                ? `(${notificationCount})`
                : null}
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.modalBody}>
              <div className={Styles.lists}>
                {requestInProgress ? (
                  <Spinner />
                ) : notifications &&
                  notifications?.notifications?.length === 0 ? (
                  <div className={Styles.noResult}>
                    No Notification Available
                  </div>
                ) : (
                  notifications &&
                  notifications?.notifications &&
                  notifications.notifications.map((notification) => (
                    <div
                      className={
                        notification.is_read
                          ? Styles.list
                          : Styles.listWithoutRead
                      }
                      key={notification._id}
                    >
                      <div className={Styles.content}>
                        <div className={Styles.profileContainer}>
                          <AiOutlineUser
                            fontSize={30}
                            style={{
                              border: "2px solid black",
                              borderRadius: "100%",
                              marginRight: "10px",
                            }}
                          />
                          <p className={Styles.fontSize}>
                            {notification.user_name} |{" "}
                            {notification.user_role.toUpperCase()}
                          </p>
                        </div>
                        <p className={Styles.fontSize}>{notification.title}</p>
                        <p className={Styles.date}>
                          {formatDate(notification.createdAt)}
                        </p>
                        {notification._id === expandedNotificationID ? (
                          <>
                            <p className={Styles.fontSize}>
                              {singleNotification?.notification?.description}
                            </p>
                            <div className={Styles.delContainer}>
                              <Tooltip label="Read Less">
                                <div>
                                  <MdOutlineKeyboardArrowUp
                                    className={Styles.iconImages}
                                    cursor="pointer"
                                    onClick={() =>
                                      setExpandedNotificationID(null)
                                    }
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <div className={Styles.delContainer}>
                            <Tooltip label="Read More">
                              <div>
                                <MdOutlineKeyboardArrowDown
                                  className={Styles.iconImages}
                                  cursor="pointer"
                                  onClick={() =>
                                    handleReadMore(notification._id)
                                  }
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div>
                {notifications && notifications?.notifications?.length >= 1 && (
                  <div className={Styles.buttonLoad}>
                    <Button
                      onClick={() => loadMoreNotifications("prev")}
                      colorScheme="teal"
                      isDisabled={skip === 0}
                    >
                      Previous
                    </Button>
                    <p className={Styles.pageCount}>
                      {skip === 0 ? 1 : skip + 1}
                    </p>
                    <Button
                      onClick={() => loadMoreNotifications("next")}
                      colorScheme="teal"
                      isDisabled={skip === notifications.page_count}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManagementDashboard;

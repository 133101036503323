import React, { useEffect, useRef, useState } from "react";
import Styles from "./PreviewReportCard.module.scss";
import ReportCardPdf from "./ReportCardPdf/Components/Main/Main";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  s3Client,
  digitalOceanEndpoint,
} from "services/doSpaceGetImages/s3Client";
import { PutObjectCommand } from "@aws-sdk/client-s3";
import {
  requestExamBackupExist,
  requestGetBackupExamYear,
  requestUpdateExamBackupExist,
} from "services/management-dashboard/backup.service";
// import { academicYear } from "utilities/utils";
import { BiUpArrowCircle } from "react-icons/bi";
import { Link } from "react-scroll";
import { requestGetAcademicSession } from "services/management-dashboard/calendar.service";
const ManagementPreviewReportCard = () => {
  const { state } = useLocation();
  const location = useLocation();
  const pdfRefs = useRef([]);
  const [uploadPdfLink, setUploadPdfLink] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [years, setYears] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [academicData, setAcademicData] = useState("");
  const toast = useToast();
  const modalBackup = useDisclosure();

  const createPDF = async (index) => {
    let pdf = new jsPDF("portrait", "pt", "a4");
    let data = await document.querySelector(`#reportCardPdf_${index}`);
    pdf.html(data).then(() => {
      pdf.save(`ExamReportCard_${state.data[index].name}.pdf`);
    });
  };
  const handlePdfGeneration = () => {
    const delay = 2;
    const limit = state.data.length;
    let i = 0;
    const limitedInterval = setInterval(() => {
      createPDF(i++);
      if (i > limit) {
        toast({
          title: "Download Complete",
          position: "bottom-right",
          description: "You can now open the downloaded files",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        clearInterval(limitedInterval);
      }
    }, delay * 1000);
  };
  var uploadPdfList = [];

  const uploadPdf = () => {
    const delay = 2;
    const limit = state.data.length;
    let i = 0;
    const limitedInterval = setInterval(() => {
      handleBackupUpload(i++);
      modalBackup.onClose();
      if (i > limit) {
        // updateDocExist();
        console.log("uploadPdfList", uploadPdfList);
        clearInterval(limitedInterval);
      }
    }, delay * 2000);
  };
  const updateDocExist = async () => {
    let data = {
      class: state.data[0].class,
      section: state.data[0].section,
      reports: uploadPdfLink,
      academic_year: academicYears,
    };
    // const res = await requestUpdateExamBackupExist(data);
    // console.log("res update", res);
    try {
      const res = await requestUpdateExamBackupExist(data);
      console.log("res update", res);
      setUploadPdfLink([]);
      toast({
        title: "Backup Complete",
        position: "bottom-right",
        description: "You can now open the backup files",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log("err", error);
      setUploadPdfLink([]);
      toast({
        title: error,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const handleGetSession = async () => {
    const payload = {
      session: years,
    };
    const response = await requestGetAcademicSession(payload);
    setAcademicData(response);
  };
  const getExamAcademicYears = async () => {
    const response = await requestGetBackupExamYear();
    setAcademicYears(response);
  };
  const handleSessionExam = () => {
    const startDate = new Date(academicData.data.specification[0].start_date);
    const endDate = new Date(academicData.data.specification[0].end_date);
    const currentDate = new Date();
    console.log("startDate", startDate, endDate, currentDate);
    if (
      currentDate.getTime() === startDate.getTime() ||
      currentDate.getTime() === endDate.getTime() ||
      (currentDate > startDate && currentDate < endDate)
    ) {
      handleSubmitBackup();
    } else {
      toast({
        title: "Backup failed",
        description: "Backup can only be taken during the academic session.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      modalBackup.onClose();
    }
  };
  const handleSubmitBackup = () => {
    if (years) {
      handleBackupExist();
    }
  };

  const handleBackupExist = async () => {
    let data = {
      class: state.data[0].class,
      section: state.data[0].section,
      academic_year: academicYears,
    };
    try {
      // setTimeout(async () => {
      const exist_res = await requestExamBackupExist(data);
      console.log(exist_res);
      toast({
        title: "Exam Backup Document Exists.Please wait it backup",
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      uploadPdf();
      // }, 3000);
    } catch (error) {
      // console.log("err", error);
      toast({
        title: error,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }

    // https://smu.sgp1.digitaloceanspaces.com/Exam/2022/ExamReportCard_13B_STUDENT%20TESTING_1682924587271.pdf
    // if (exist_res.msg == "Exam Backup Document Exists") {
    //   console.log("if", exist_res);
    //   toast({
    //     title: exist_res.msg,
    //     variant: "left-accent",
    //     position: "bottom-right",
    //     status: "error",
    //     duration: 2500,
    //     isClosable: true,
    //   });
    // }
    // if (exist_res.error == "Exam Backup Doc not Exists") {
    //   console.log("else", exist_res);
    //   uploadPdf();
    //   toast({
    //     title: "Backup Successfully",
    //     variant: "left-accent",
    //     position: "bottom-right",
    //     status: "success",
    //     duration: 2500,
    //     isClosable: true,
    //   });
    // }
  };
  // https://smu.sgp1.digitaloceanspaces.com/Exam/2022/ExamReportCard_1A_AADHYA%20M%20NAIK_1682933996093.pdf

  const academicYear = () => {
    // const date = new Date().toDateString().split(' ');
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    if (month >= 1 && month < 6) {
      return year - 1;
    }
    return year;
  };

  const handleBackupButton = () => {
    modalBackup.onOpen();
  };
  React.useEffect(() => {
    getExamAcademicYears();
  }, []);
  React.useEffect(() => {
    if (years && years) {
      handleGetSession();
    }
  }, [years]);
  const handleBackupUpload = async (i) => {
    let pdf = new jsPDF("portrait", "pt", "a4");
    let data = await document.querySelector(`#reportCardPdf_${i}`);
    pdf.html(data).then(async (res) => {
      const pdfData = pdf.output("blob");
      const student_id = state.data[i].student_id;
      const key = `Exam/${years}/ExamReportCard/${state.data[i].class}/${
        state.data[i].section
      }/${state.data[i].name}_${Date.now()}.pdf`;
      const bucketParams = {
        Bucket: "smu",
        Key: key,
        Body: pdfData,
      };

      try {
        const tempdata = await s3Client.send(
          new PutObjectCommand(bucketParams)
        );
        console.log("hell", tempdata.$metadata.httpStatusCode);
        if (tempdata.$metadata.httpStatusCode == 200) {
          setUploadPdfLink((prevLinks) => [
            ...prevLinks,
            {
              student_id: student_id,
              report_url: `${digitalOceanEndpoint}/${key}`,
            },
          ]);
        }
        console.log(
          "Successfully uploaded object: " +
            bucketParams.Bucket +
            "/" +
            bucketParams.Key
        );
      } catch (err) {
        console.log("Error", err);
      }
    });
  };
  useEffect(() => {
    if (uploadPdfLink.length == state?.data?.length) {
      updateDocExist();
    }
  }, [uploadPdfLink]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={Styles.body}>
      <p className={Styles.headerText2}>
        Preview Report Cards: {state?.data?.length}
      </p>
      <div className={Styles.header}>
        <p className={Styles.headerText}>
          Selected Students :
          {state?.data?.map((i, index) => (
            <Link
              to={`reportCardPdf_${index}`}
              smooth={true}
              duration={500}
              offset={-300}
              exact="true"
            >
              <span key={index} style={{ cursor: "pointer" }}>
                {i.name} |
              </span>
            </Link>
          ))}
        </p>
      </div>
      <div className={Styles.header}>
        <button
          id="downloadReportButton"
          className={Styles.button}
          onClick={() => {
            toast({
              title: "Downloading...",
              position: "bottom-right",
              description:
                "Please open the files once the entire download process is completed",
              status: "info",
              duration: 2500,
              isClosable: true,
            });
            handlePdfGeneration();
          }}
        >
          Download
        </button>
        <button className={Styles.button} onClick={handleBackupButton}>
          Backup
        </button>
      </div>

      <div className={Styles.wrapper}>
        {state?.data?.map((i, index) => (
          <div className={Styles.reportCardDiv}>
            <div id={`reportCardPdf_${index}`}>
              <ReportCardPdf data={i} type="pdf" />
            </div>
          </div>
        ))}
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}

      <div style={{ display: "none" }}>
        {state?.data?.map((i, index) => (
          <div id={`reportCardPdf_${index}`}>
            <ReportCardPdf data={i} type="pdf" />
          </div>
        ))}
      </div>
      {/* <p className={Styles.page}>[1/3]</p> */}

      <Modal isOpen={modalBackup.isOpen} onClose={modalBackup.onClose}>
        <ModalOverlay />
        <ModalContent
          position="fixed"
          top="0"
          transform="translateX(-50%)"
          maxHeight="80vh"
          overflowY="auto"
        >
          <ModalHeader>Backup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p style={{ fontWeight: "bold", marginBottom: "20px" }}>
              Select year for Backup
            </p>
            <Select
              value={years}
              required
              onChange={(e) => setYears(e.target.value)}
              name="academicYears"
              placeholder="Academic Year"
            >
              {academicYears &&
                academicYears.map((year) => {
                  const formattedYear = `${year}-${
                    parseInt(year.toString().slice(-2)) + 1
                  }`;
                  return <option value={year}>{formattedYear}</option>;
                })}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="red"
              mr={3}
              onClick={handleSessionExam}
            >
              Submit
            </Button>
            <Button colorScheme="blue" onClick={modalBackup.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
export default ManagementPreviewReportCard;

import React, { useEffect, useState } from "react";
import Styles from "./ReportCentre.module.scss";
import Canteen from "assets/icons/Canteen .png";
import { Button, Checkbox, Select, useToast } from "@chakra-ui/react";
import { useClassAndSections } from "hooks/classAndSection";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { requestCanteenDetails } from "services/admin-dashboard/canteen.service";

const CanteenReport = () => {
  const toast = useToast();
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const initialFieldsState = {
    studentName: true,
    class: true,
    section: true,
    fee: true,
    status: true,
    duration: true,
    trialPeriod: false,
    phoneNumber: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const fileName = `SMU-Canteen-Statistics-Report-${inputFields.class}${inputFields.section}`;
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    if (!inputFields.class || !inputFields.section) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      class: inputFields.class,
      section: inputFields.section,
    };
    const res = await requestCanteenDetails(payload);
    if (res) {
      let newArray = [];
      res &&
        res.map((dataValue) => {
          let obj = {};
          if (fields.studentName) {
            obj["Name"] = handleNAValue(dataValue.name);
          }
          if (fields.class) {
            obj["Class"] = handleNAValue(dataValue.class);
          }
          if (fields.section) {
            obj["Section"] = handleNAValue(dataValue.section);
          }
          if (fields.fee) {
            obj["Total Fees"] = handleNAValue(dataValue.amount);
          }
          if (fields.status) {
            obj["Fees Status"] = handleNAValue(
              dataValue.paid == false ? "Unpaid" : "Paid"
            );
          }
          if (fields.duration) {
            obj["Duration(in months)"] = handleNAValue(dataValue.duration);
          }
          if (fields.trialPeriod) {
            obj["Trial Period"] = handleNAValue(
              dataValue.is_trial == false ? "No" : "True"
            );
          }
          if (fields.phoneNumber) {
            obj["Phone Number"] = handleNAValue(
              dataValue.phone_code + dataValue.phoneNumber
            );
          }
          newArray.push(obj);
        });
      setData(newArray);
      if (newArray.length === 0) {
        toast({
          title: "No students found.",
          variant: "left-accent",
          position: "bottom-left",
          status: "warning",
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img
                src={Canteen}
                alt="Canteen Icon"
                className={Styles.moduleIcon}
              />
              <p className={Styles.moduleName}>CANTEEN MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Student Details
                  </button>
                </div>
              </div>
              <div className={Styles.row}>
                <Checkbox
                  isChecked={fields.class}
                  onChange={() => {
                    setFields({
                      ...fields,
                      class: !fields.class,
                    });
                  }}
                >
                  Class
                </Checkbox>
                <Checkbox
                  isChecked={fields.section}
                  onChange={() => {
                    setFields({
                      ...fields,
                      section: !fields.section,
                    });
                  }}
                >
                  Section
                </Checkbox>
                <Checkbox
                  isChecked={fields.phoneNumber}
                  onChange={() => {
                    setFields({
                      ...fields,
                      phoneNumber: !fields.phoneNumber,
                    });
                  }}
                >
                  Phone Number
                </Checkbox>
                <Checkbox
                  isChecked={fields.trialPeriod}
                  onChange={() => {
                    setFields({
                      ...fields,
                      trialPeriod: !fields.trialPeriod,
                    });
                  }}
                >
                  Trial Period
                </Checkbox>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Canteen}
              alt="Canteen Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>CANTEEN MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Student Details
                  </button>
                </div>
              </div>
              <div className={Styles.mobilerow}>
                <Checkbox
                  isChecked={fields.class}
                  onChange={() => {
                    setFields({
                      ...fields,
                      class: !fields.class,
                    });
                  }}
                >
                  Class
                </Checkbox>
                <Checkbox
                  isChecked={fields.section}
                  onChange={() => {
                    setFields({
                      ...fields,
                      section: !fields.section,
                    });
                  }}
                >
                  Section
                </Checkbox>
                <Checkbox
                  isChecked={fields.phoneNumber}
                  onChange={() => {
                    setFields({
                      ...fields,
                      phoneNumber: !fields.phoneNumber,
                    });
                  }}
                >
                  Phone Number
                </Checkbox>
                <Checkbox
                  isChecked={fields.trialPeriod}
                  onChange={() => {
                    setFields({
                      ...fields,
                      trialPeriod: !fields.trialPeriod,
                    });
                  }}
                >
                  Trial Period
                </Checkbox>
              </div>
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CanteenReport;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  requestAllSubjectMarks,
  requestUpdateSubjectMarks,
  requestAllSubjects,
} from "services/staff-dashboard/classroom.service";
import Styles from "./Classroom.module.scss";
import defaultUser from "assets/icons/User.png";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";

const SingleStaffClass = () => {
  const toast = useToast();
  const params = useParams();
  const [classroomDetails, setClassroomDetails] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const {
    isOpen: maxIsOpen,
    onOpen: maxOnOpen,
    onClose: maxOnClose,
  } = useDisclosure();
  const [subjectInModal, setSubjectInModal] = useState("");
  const [requestInProgressModal, setRequestInProgressModal] = useState(false);
  const [addButton, setAddButton] = useState("");
  const [maxAnual, setMaxAnual] = useState({
    total: 100,
    internal: 20,
    external: 80,
  });

  const [maxMid, setMaxMid] = useState({
    total: 50,
    internal: 10,
    external: 40,
  });

  const [marksArray, setMarksArray] = useState([
    { maxRange: 100, minRange: 0, grade: "" },
  ]);
  const [marksMidArray, setMarksMidArray] = useState([
    { maxMidRange: 50, minMidRange: 0, grade: "" },
  ]);
  const handleAddMidObject = () => {
    const minMidValue = marksMidArray[marksMidArray.length - 1]?.minMidRange;
    const gradeValue = marksMidArray[marksMidArray.length - 1].grade;
    if (gradeValue === "") {
      toast({
        title: "Please fill in the grade field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    } else if (minMidValue > 0) {
      const newObject = {
        maxMidRange: (minMidValue - 0.5).toFixed(1),
        minMidRange: 0,
        grade: "",
      };
      const newArray = [...marksMidArray, newObject];
      setMarksMidArray(newArray);
    }
  };

  const handleRemoveMidObject = (index) => {
    const newArray = [...marksMidArray];
    newArray.splice(index, 1);
    // setMarksMidArray(newArray);
    newArray.forEach((item, i) => {
      if (i >= index) {
        if (i === 0) {
          item.maxMidRange = maxMid.total;
        } else {
          item.maxMidRange = newArray[i - 1].minMidRange - 0.5;
        }
      }
    });
    setMarksMidArray(newArray);
  };

  const handleMinMidValRange = (e, id) => {
    const newArray = [...marksMidArray];
    newArray[id][e.target.name] = e.target.value;
    setMarksMidArray(newArray);
  };

  const handleMidGrade = (e, id) => {
    const newArray = [...marksMidArray];
    newArray[id][e.target.name] = e.target.value.toUpperCase();
    setMarksMidArray(newArray);
  };
  const handleAddObject = () => {
    const minValue = marksArray[marksArray.length - 1].minRange;
    const gradeValue = marksArray[marksArray.length - 1].grade;

    if (gradeValue === "") {
      toast({
        title: "Please fill in the grade field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    } else if (minValue > 0) {
      const newObject = {
        maxRange: minValue - 1,
        minRange: 0,
        grade: "",
      };
      const newArray = [...marksArray, newObject];
      setMarksArray(newArray);
    }
  };

  const handleRemoveObject = (index) => {
    const newArray = [...marksArray];
    newArray.splice(index, 1);
    // setMarksArray(newArray);
    newArray.forEach((item, i) => {
      if (i >= index) {
        if (i === 0) {
          item.maxRange = maxAnual.total;
        } else {
          item.maxRange = newArray[i - 1].minRange - 1;
        }
      }
    });
    setMarksArray(newArray);
  };

  const handleMinValRange = (e, id) => {
    const newArray = [...marksArray];
    newArray[id][e.target.name] = e.target.value;
    setMarksArray(newArray);
  };

  const handleGrade = (e, id) => {
    const newArray = [...marksArray];
    newArray[id][e.target.name] = e.target.value.toUpperCase();
    setMarksArray(newArray);
  };
  const handleAllSubjectMarks = async () => {
    try {
      setRequestInProgressModal(true);
      const payload = {
        subject_name: subjectInModal.subject,
      };
      const response = await requestAllSubjectMarks(params.id, payload);
      if (response) {
        setRequestInProgressModal(true);
        setAddButton(response.msg);
        setRequestInProgressModal(false);
      }
      setMaxAnual({
        total: response.max_marks.annual,
        internal: response.internal_marks.annual,
        external: response.external_marks.annual,
      });
      setMaxMid({
        total: response.max_marks.mid_term,
        internal: response.internal_marks.mid_term,
        external: response.external_marks.mid_term,
      });

      const annualGrading = response.grading.filter(
        (item) => item.exam_type === "ANNUAL"
      );
      const midTermGrading = response.grading.filter(
        (item) => item.exam_type === "MID_TERM"
      );

      setMarksArray(
        annualGrading.map((item) => ({
          maxRange: item.upper_limit,
          minRange: item.lower_limit,
          grade: item.grade,
        }))
      );

      setMarksMidArray(
        midTermGrading.map((item) => ({
          maxMidRange: item.upper_limit,
          minMidRange: item.lower_limit,
          grade: item.grade,
        }))
      );
      setRequestInProgressModal(false);
    } catch (e) {}
  };
  useEffect(() => {
    if (subjectInModal && subjectInModal.is_scholastic) {
      handleAllSubjectMarks();
    }
  }, [subjectInModal]);

  const handleSubmit = async () => {
    try {
      const payload = {
        subject_name: subjectInModal?.subject,
        updateBody: {
          max_marks: {
            annual: maxAnual?.total,
            mid_term: maxMid?.total,
          },
          external_marks: {
            annual: maxAnual?.external,
            mid_term: maxMid?.external,
          },
          internal_marks: {
            annual: maxAnual?.total - maxAnual?.external,
            mid_term: maxMid?.total - maxMid?.external,
          },
          grading: marksArray
            .map((item) => ({
              exam_type: "annual",
              lower_limit: item?.minRange,
              upper_limit: item?.maxRange,
              grade: item.grade,
            }))
            .concat(
              marksMidArray.map((item) => ({
                exam_type: "mid_term",
                lower_limit: item?.minMidRange,
                upper_limit: item?.maxMidRange,
                grade: item.grade,
              }))
            ),
        },
        subject_code: subjectInModal.subject_code,
      };
      if (
        marksArray.some((item) => !item.grade) ||
        marksMidArray.some((item) => !item.grade)
      ) {
        toast({
          title: "Please fill in the grade field.",
          variant: "left-accent",
          position: "bottom-right",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      const response = await requestUpdateSubjectMarks(params.id, payload);
      setMaxAnual({
        total: 100,
        internal: 20,
        external: 80,
      });
      setMaxMid({
        total: 50,
        internal: 10,
        external: 40,
      });
      setMarksArray([{ maxRange: 100, minRange: 0, grade: "" }]);
      setMarksMidArray([{ maxMidRange: 50, minMidRange: 0, grade: "" }]);
      setSubjectInModal("");
      setAddButton("");
      maxOnClose();
      toast({
        title: response.message,
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e.msg,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const requestPageDetails = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        class_id: params.id,
      };
      const response = await requestAllSubjects(payload);
      setClassroomDetails(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    requestPageDetails();
  }, []);
  const handleMaxModal = (subj) => {
    setSubjectInModal(subj);
    maxOnOpen();
  };

  if (requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!classroomDetails) {
    return null;
  }
  return (
    <div className={Styles.body}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>
          Class : {classroomDetails?.class} | Section :{" "}
          {classroomDetails?.section}
        </p>
      </div>
      <div>
        <h1 className={Styles.heading}>
          Regular Subjects({classroomDetails?.subjects.length})
        </h1>
        <div className={Styles.lists}>
          {classroomDetails?.subjects.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            classroomDetails?.subjects?.map((subj, index) => (
              <div key={subj["teacher_id"] + index} className={Styles.list}>
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={
                      subj.teacher_profile_url !== "N/A"
                        ? subj.teacher_profile_url
                        : defaultUser
                    }
                    alt="profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>{subj.teacher_name}</p>
                  <hr
                    style={{ color: "red", borderColor: "red", height: "10px" }}
                  />
                  <div>
                    <p className={Styles.class}>{subj.subject}</p>
                  </div>

                  <div className={Styles.editbox}>
                    <p
                      className={Styles.code}
                      style={{ color: subj.is_scholastic ? "blue" : "green" }}
                    >
                      {subj.is_scholastic ? "Scholastic" : "Non Scholastic"}
                    </p>
                    <div
                      className={Styles.icon}
                      onClick={() => handleMaxModal(subj)}
                    >
                      <TbReportAnalytics cursor="pointer" />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Elective Subjects({classroomDetails.electives.length})
        </h1>
        <div className={Styles.electives}>
          {classroomDetails &&
          classroomDetails.electives &&
          classroomDetails.electives.length > 0 ? (
            classroomDetails.electives.map((elective) => {
              return (
                <div
                  className={Styles.electives_container}
                  key={elective.elective_id}
                >
                  <div className={Styles.elecBox}>
                    <h1 className={Styles.elective_heading}>
                      {elective.elective_name}
                    </h1>
                  </div>
                  <hr
                    style={{
                      color: "red",
                      borderColor: "black",
                      height: "10px",
                    }}
                  />
                  {elective.subjects.map((subject) => {
                    return (
                      <div
                        className={Styles.lists_elective}
                        key={subject.subject}
                      >
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                subject?.teacher_profile_url !== "N/A"
                                  ? subject?.teacher_profile_url
                                  : defaultUser
                              }
                              alt="profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {subject.teacher_name}
                            </p>
                            <hr
                              style={{
                                color: "red",
                                borderColor: "red",
                                height: "10px",
                              }}
                            />
                            <p className={Styles.class}>{subject.subject}</p>
                            <div className={Styles.editbox}>
                              <p
                                className={Styles.code}
                                style={{
                                  color: subject.is_scholastic
                                    ? "blue"
                                    : "green",
                                }}
                              >
                                {subject.is_scholastic
                                  ? "Scholastic"
                                  : "Non Scholastic"}
                              </p>
                              <div
                                className={Styles.icon}
                                onClick={() => {
                                  handleMaxModal(subject);
                                }}
                              >
                                <TbReportAnalytics cursor="pointer" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className={Styles.noResult}>No Results</div>
          )}
        </div>
      </div>
      <Modal
        isOpen={maxIsOpen}
        onClose={() => {
          setMaxAnual({
            total: 100,
            internal: 20,
            external: 80,
          });
          setMaxMid({
            total: 50,
            internal: 10,
            external: 40,
          });
          setMarksArray([{ maxRange: 100, minRange: 0, grade: "" }]);
          setMarksMidArray([{ maxMidRange: 50, minMidRange: 0, grade: "" }]);
          setSubjectInModal("");
          setAddButton("");
          maxOnClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            CUSTOMIZE GRADING : {subjectInModal?.subject}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {requestInProgressModal ? (
              <div className={Styles.loaderWrapper}>
                <Spinner />
              </div>
            ) : subjectInModal && subjectInModal.is_scholastic ? (
              <div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Mid-Term
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <input
                            value={maxMid.total}
                            onChange={(e) => {
                              const newTotal = e.target.value;
                              setMaxMid({ ...maxMid, total: newTotal });
                              const newMarksMidArray = [...marksMidArray];
                              newMarksMidArray[0].maxMidRange = newTotal;
                              setMarksMidArray(newMarksMidArray);
                            }}
                            name="max_mid_total"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <input
                            value={maxMid.external}
                            onChange={(e) =>
                              setMaxMid({ ...maxMid, external: e.target.value })
                            }
                            name="max_mid_external"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxMid.total - maxMid.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksMidArray.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <input
                            value={item?.minMidRange}
                            onChange={(e) => handleMinMidValRange(e, index)}
                            name="minMidRange"
                            type="number"
                            placeholder={item?.minMidRange}
                            className={Styles.textInput}
                          />

                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxMidRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            value={item.grade}
                            onChange={(e) => handleMidGrade(e, index)}
                            name="grade"
                            className={Styles.textInput}
                          />
                          {marksMidArray.length >= 2 ? (
                            <AiOutlineCloseCircle
                              className={Styles.icons}
                              onClick={() => handleRemoveMidObject(index)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {marksMidArray[marksMidArray.length - 1]?.minMidRange >
                      0 ? (
                        <MdOutlineAddBox
                          className={Styles.icons}
                          onClick={handleAddMidObject}
                        />
                      ) : null}
                    </div>
                  </div>
                  <p
                    style={{
                      borderLeft: "2px solid black",
                      marginRight: "10px",
                    }}
                  ></p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Annual
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <input
                            value={maxAnual.total}
                            onChange={(e) => {
                              const newTotal = e.target.value;
                              setMaxAnual({ ...maxAnual, total: newTotal });
                              const newMarksArray = [...marksArray];
                              newMarksArray[0].maxRange = newTotal;
                              setMarksArray(newMarksArray);
                            }}
                            name="max_annual_total"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <input
                            value={maxAnual.external}
                            onChange={(e) =>
                              setMaxAnual({
                                ...maxAnual,
                                external: e.target.value,
                              })
                            }
                            name="max_annual_external"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxAnual.total - maxAnual.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksArray.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <input
                            value={item.minRange}
                            onChange={(e) => handleMinValRange(e, index)}
                            name="minRange"
                            type="number"
                            placeholder={item.minRange}
                            className={Styles.textInput}
                          />

                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            value={item.grade}
                            onChange={(e) => handleGrade(e, index)}
                            name="grade"
                            className={Styles.textInput}
                          />
                          {marksArray.length >= 2 ? (
                            <AiOutlineCloseCircle
                              className={Styles.icons}
                              onClick={() => handleRemoveObject(index)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {marksArray[marksArray.length - 1].minRange > 0 ? (
                        <MdOutlineAddBox
                          className={Styles.icons}
                          onClick={handleAddObject}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={Styles.buttonRow}>
                  {addButton === "class or subject not found" ? (
                    <button className={Styles.button} onClick={handleSubmit}>
                      ADD
                    </button>
                  ) : null}
                </div>
              </div>
            ) : (
              <div style={{ fontWeight: "bold", color: "red" }}>
                The Max Marks feature does not apply to Non-Scholastic Subjects.
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SingleStaffClass;

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Textarea, Checkbox, Select } from "@chakra-ui/react";
import { useClassAndSections2 } from "hooks/classAndSection";
import StudentDashboard from "services/student-dashboard/circular.service";
import Styles from "./SingleCircular.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { VscFilePdf } from "react-icons/vsc";
import { MdOutlineAttachFile } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
const StudentSingleCircular = () => {
  const params = useParams();
  const { classAndSections } = useClassAndSections2();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    description: "",
    date: "",
    media_url: "",
    category: "",
    target_group: [],
  });
  const [existingFile, setExistingFile] = useState([]);
  const [images, setImages] = useState([]);
  const [allFiles, setAllFiles] = useState([]);

  useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchCircularDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const fetchCircularDetails = async () => {
    try {
      const response = await StudentDashboard.requestCircularById({
        circular_id: params.id,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        const DATE = response?.date;
        let date = DATE && new Date(DATE).toLocaleDateString();
        if (date == "Invalid Date") {
          if (DATE.includes("-")) {
            date = new Date(DATE.split("-").reverse().join("/"));
          } else if (DATE.includes("/")) {
            const [day, month, year] = DATE.split("/");
            const dateString = [month, day, year].join("/");
            date = new Date(dateString);
          }
        } else {
          if (typeof new Date(DATE) === "object") {
            date = new Date(DATE);
          } else {
            date = DATE;
          }
        }
        const mediaUrls = response?.media || [];
        let allImages = [];
        let allfiles = [];
        for (let i = 0; i < response.media.length; i++) {
          const item = response.media[i];
          const fileUrl = item.media_url;

          if (fileUrl) {
            if (fileUrl.includes(".pdf")) {
              allFiles.push(fileUrl);
            } else {
              allImages.push(fileUrl);
            }
          }
        }
        setExistingFile(allFiles);
        setImages(allImages);

        const category =
          response.category[0].toUpperCase() +
          response.category.slice(1).toLowerCase();
        setInputFields({
          title: response.title,
          description: response.description,
          date: date,
          target_group: response.target_group,
          category: category,
          media_url: mediaUrls,
        });
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const isAllSelected = inputFields.target_group.every((targetClass) =>
    classAndSections.some(
      (grade) => grade.class.toLowerCase() === targetClass.toLowerCase()
    )
  );
  return (
    <div className={Styles.body}>
      <div className={Styles.row}>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>Title</p>
          <p className={`${Styles.textInput} ${Styles.inputField}`}>
            {inputFields?.title}
          </p>
        </div>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>Description</p>
          <Textarea
            readOnly
            id="description"
            name="description"
            className={Styles.textInput}
            value={inputFields?.description}
            placeholder="Enter Description..."
            size="lg"
            resize="vertical"
            required
          />
        </div>
        <div className={Styles.form}>
          <p className={Styles.placeholder}>Category</p>
          <p className={`${Styles.textInput} ${Styles.inputField}`}>
            {inputFields?.category}
          </p>
        </div>
        <div className={Styles.formCheck}>
          <p className={Styles.placeholder}>Target Classes</p>
          <div className={Styles.checkboxes}>
            {classAndSections.map((grade, idx) => (
              <Checkbox
                readOnly
                id="target_group"
                name="target_group"
                isChecked={isAllSelected && grade.class.toLowerCase() === "all"}
                colorScheme="red"
                key={grade.class + idx}
                value={grade.class}
              >
                <p className={Styles.checkboxText}>{grade.class}</p>
              </Checkbox>
            ))}
          </div>
        </div>
        <div className={Styles.formDate}>
          <p className={Styles.placeholder}>Date</p>
          <DatePicker
            disabled
            required
            selected={inputFields.date}
            value={inputFields?.date}
            dateFormat="dd/MM/yyyy"
            className={Styles.inputField}
          />
        </div>

        <div className={Styles.coverImage}>
          {images.length > 0 ? (
            <Carousel
              showThumbs={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                      left: 25,
                      fontSize: "50px",
                      color: "#990f0d",
                    }}
                    className={Styles.leftCircle}
                  >
                    <AiFillLeftCircle />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    onClick={onClickHandler}
                    title={label}
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "calc(50% - 15px)",
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                      right: 25,
                      fontSize: "50px",
                      color: "#990f0d",
                    }}
                    className={Styles.rightCircle}
                  >
                    <AiFillRightCircle />
                  </button>
                )
              }
            >
              {images.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item}
                      className={Styles.carouselImg}
                      alt="attachment-img"
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "25px",
                        right: "25px",
                        cursor: "pointer",
                      }}
                    ></div>
                  </div>
                );
              })}
            </Carousel>
          ) : (
            ""
          )}
        </div>
        {existingFile
          ? existingFile.map((element, idx) => {
              if (element.includes(".pdf")) {
                const pdfUrl = element;
                return (
                  <a
                    href={pdfUrl}
                    target={idx === 0 ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    className={Styles.attachment}
                    key={idx}
                  >
                    <p className={Styles.placeholder}>
                      Attachment {idx + 1}
                      <MdOutlineAttachFile style={{ display: "inline" }} />
                    </p>
                    <VscFilePdf className={Styles.pdfLink} />
                  </a>
                );
              }
            })
          : ""}
      </div>
    </div>
  );
};

export default StudentSingleCircular;

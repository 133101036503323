import React, { useState,useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import { MdDescription } from "react-icons/md";
import StepWizard from "react-step-wizard";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import {requestSearchClassAndSection,requestSearchAll}  from "services/admin-dashboard/notification.service";
import Services from "../../../services/admin-dashboard/staff.service"
import Styles from "./Notification.module.scss"
import {Checkbox} from  "@chakra-ui/react"
import defaultUser from "../../../assets/icons/User.png";
const ActionButtons = (props) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div style={{display:"flex",justifyContent:"space-around"}}>
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <Button onClick={handleBack}>Back</Button>
          </Col>
        )}
        <Col>
          {props.currentStep < props.totalSteps && (
            <Button onClick={handleNext}>Next</Button>
          )}
          {props.currentStep === props.totalSteps && (
            <Button onClick={handleFinish}>Finish</Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const One = (props) => {
  const [info1, setInfo1] = useState({});
  const [error, setError] = useState("");
  const [classSearch,setSearchClass] = useState("")
  const [sectionValue,setSectionValues] = useState("")
  const [selectedClass,setSelectedClass] = useState("")
  const [seletedClassSection,setSeletedClassSection] = useState("")
  const [selectedClassExist,setSelectedClassExist] = useState(false) 

  const validate = () => {
    if (selectedClass.length==0) setError("Please Select any Department");
    else {
      setError("");
      props.nextStep();
      props.userCallback([{DepartmentId:selectedClass},{DepartmentId:selectedClass}]);
    }
  };

  const handleSearchGrade = async(e) =>{
    try{
        const payload={
        }
        const response = await Services.requestListByTeacherDepartment(payload)
        if(response){
          setSectionValues(response.result)
        }
    }
    catch(e){
      console.log(e)
    }
  }
  useEffect(()=>{
      handleSearchGrade()
  },[classSearch])

  const handleCheckClass = (id)=>{
    // console.log("sections",section)
    let people = [...selectedClass];
    // let data = [...seletedClassSection]
    if (people.includes(id)) {
      let index = people.indexOf(id);
    //   let index2 = data.indexOf(section);

      people.splice(index, 1);
    //   data.splice(index2, 1);
      setSelectedClass(people);
    //   setSeletedClassSection(data)
    } else {
      setSelectedClass([...people, id]);
    //   setSeletedClassSection([...data,section])

    }
    handleRemoveSelectedPeople(id);
  }
  useEffect(()=>{
    if(selectedClass.length>=1){
      setSelectedClassExist(true)
    }
    else{
      setSelectedClassExist(false)
    }
  },[selectedClass])
  const handleRemoveSelectedPeople = (id) => {
    let people = [...selectedClass];
    if (people.includes(id)) {
      let index = people.indexOf(id);
      people.splice(index, 1);
      setSelectedClass(people);
    }
  };
  return (
    <div>
      <span style={{ color: "red" }}>{error}</span>
      <FormGroup>
            <div className={Styles.containerBox}>
              {
                sectionValue&&sectionValue.map((section)=>
                (<>
                  <div style={{display:"flex",padding:"5px"}} > 
                  <Checkbox 
                    isChecked={ selectedClass.includes(section.department)}
                    onChange={()=>handleCheckClass(section.department)}
                    style={{marginRight:'8px'}}
                  />
                  <div  onClick={()=>handleCheckClass(section.department)}>
                    {section.department} 
                  </div>
                  </div>
                </>)
                )
              }
            </div>
      </FormGroup>
      <br />
      <ActionButtons {...props} nextStep={validate} />
    </div>
  );
};

const Two = (props) => {
  const [info2, setInfo2] = useState({});
  const [error, setError] = useState("");
  const [searchName,setSearchName] = useState("")
  const [student,setStudent] = useState("")
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedId,setSelectedId] = useState("")
  const [seletedStudent,setSelectedStudent] = useState("")
  // console.log("props",props.user[0].classid)
  // const selectedClassId = props.user[0].classid?props.user[0].classid:""
  const onInputChanged = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setInfo2((info2) => ({
      ...info2,
      [targetName]: targetValue
    }));
  };

  const validate2 = () => {
      props.nextStep();
      props.userCallback([{exculdeStaffId:selectedId,excludeStaffDetails:seletedStudent}]);
  };
  // console.log("props", props)
  const handleSearchStudent = async (e) => {
    try {
      setRequestInProgress(true);
      const payload = {
        name:searchName,
        teacher:{
          // inclusion_id_array : [],
          // exclusion_id_array :,
          dept_array: props.user[0].DepartmentId?props.user[0].DepartmentId:"",

        },
        global_flag: false,
       
      };
      const response = await requestSearchAll(payload);
      if (response) {
        // console.log(response);
        setStudent(response.staffs.priority);
      }
      setRequestInProgress(false);
    } catch (e) {
      console.log(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    if(searchName){
    setRequestInProgress(true);
    const timeoutId = setTimeout(() =>handleSearchStudent() , 2000);
    setRequestInProgress(false);
    return () => clearTimeout(timeoutId);
  }
  }, [searchName]);
  const handleCheckStudents = (id,{student:student})=>{
    let people = [...selectedId];
    let data = [...seletedStudent]
    if (people.includes()) {
      let index = people.indexOf(id);
      let index2 = data.indexOf(student);

      people.splice(index, 1);
      data.splice(index2, 1);
      setSelectedId(people);
      setSelectedStudent(data)
    } else {
      setSelectedId([...people, id]);
      setSelectedStudent([...data,student])

    }
    handleRemoveSelectedPeople(id);
  }
  const handleRemoveSelectedPeople = (id) => {
    let people = [...selectedId];
    if (people.includes(id)) {
      let index = people.indexOf(id);
      people.splice(index, 1);
      setSelectedId(people);
    }
  };
  return (
    <div>
      
      <div>
              <Input placeholder="Search Exclude Staff" onChange={(e)=>setSearchName(e.target.value)} />
      </div>
      <div className={Styles.containerBox}>
      {student&&student.map((student) => (
                <div className={Styles.searchStudentList}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <img
                      className={Styles.profileImg}
                      src={student.url != "N/A" ? student.url : defaultUser}
                      alt="student-profile"
                    />
                    <p>
                      {student.name || "--"}
                    </p>
                  </div>
                  <div>
                    <Checkbox
                      // spacing={5}
                      colorScheme="red"
                      onChange={() => handleCheckStudents(student._id,{student:student})}
                      isChecked={selectedId.includes(student._id)}
                    />
                  </div>
                </div>
              ))}
      </div>
      <br />
      <ActionButtons {...props} nextStep={validate2} />
    </div>
  );
};

const Three = (props) => {
  const [searchName,setSearchName] = useState("")
  const [student,setStudent] = useState("")
  const [selectedId,setSelectedId] = useState("")
  const [seletedStudent,setSelectedStudent] = useState("")
  const [requestInProgress, setRequestInProgress] = useState(false);
  // console.log("step3 receive user object");
  // console.log(props);

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback.handleClick([{class:props.user},{selectedId:selectedId},{selectedStaff:seletedStudent}]);
  };
  const handleSearchStudent = async (e) => {
    try {
      setRequestInProgress(true);
      const payload = {
        name:searchName,
        global_flag: true
      };
      const response = await requestSearchAll(payload);
      if (response) {
        // console.log(response.students);
        setStudent(response.staffs);
      }
      setRequestInProgress(false);
    } catch (e) {
      console.log(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    if(searchName){
    setRequestInProgress(true);
    const timeoutId = setTimeout(() =>handleSearchStudent() , 2000);
    setRequestInProgress(false);
    return () => clearTimeout(timeoutId);
  }
  }, [searchName]);
  const handleCheckStudents = (id,{student:student})=>{
    let people = [...selectedId];
    let data = [...seletedStudent]
    if (people.includes()) {
      let index = people.indexOf(id);
      let index2 = data.indexOf(student);

      people.splice(index, 1);
      data.splice(index2, 1);
      setSelectedId(people);
      setSelectedStudent(data)
    } else {
      setSelectedId([...people, id]);
      setSelectedStudent([...data,student])

    }
    handleRemoveSelectedPeople(id);
  }
  const handleRemoveSelectedPeople = (id) => {
    let people = [...selectedId];
    if (people.includes(id)) {
      let index = people.indexOf(id);
      people.splice(index, 1);
      setSelectedId(people);
    }
  };
  return (
    <div>
      <div>
      <Input placeholder="Search Include Staff" onChange={(e)=>setSearchName(e.target.value)} />
      </div>
      <div className={Styles.containerBox}>
        
          {student&&student.map((student) => (
                <div className={Styles.searchStudentList}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <img
                      className={Styles.profileImg}
                      src={student.url != "N/A" ? student.url : defaultUser}
                      alt="student-profile"
                    />
                    <p>
                      {student.name || "--"}
                    </p>
                  </div>
                  <div>
                    <Checkbox
                      // spacing={5}
                      colorScheme="red"
                      onChange={() => handleCheckStudents(student._id,{student:student})}
                      isChecked={selectedId.includes(student._id)}
                    />
                  </div>
                </div>
              ))}
        
      </div>
      <br />
      <ActionButtons {...props} lastStep={handleLastStep} />
    </div>
  );
};
const Fourth = (props) => {
  const [searchName,setSearchName] = useState("")
  const [student,setStudent] = useState("")
  const [selectedId,setSelectedId] = useState("")
  const [seletedStudent,setSelectedStudent] = useState("")
  const [requestInProgress, setRequestInProgress] = useState(false);
  // console.log("step3 receive user object");
  // console.log(props);

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback.handleClick([{userType:"Teacher"},{selectedId:selectedId},{selectedStaff:seletedStudent}]);
  };
  const handleSearchStudent = async (e) => {
    try {
      setRequestInProgress(true);
      const payload = {
        name:searchName,
        global_flag: true
      };
      const response = await requestSearchAll(payload);
      if (response) {
        // console.log(response.students);
        setStudent(response.staffs);
      }
      setRequestInProgress(false);
    } catch (e) {
      console.log(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    if(searchName){
    setRequestInProgress(true);
    const timeoutId = setTimeout(() =>handleSearchStudent() , 2000);
    setRequestInProgress(false);
    return () => clearTimeout(timeoutId);
  }
  }, [searchName]);
  const handleCheckStudents = (id,{student:student})=>{
    let people = [...selectedId];
    let data = [...seletedStudent]
    if (people.includes()) {
      let index = people.indexOf(id);
      let index2 = data.indexOf(student);

      people.splice(index, 1);
      data.splice(index2, 1);
      setSelectedId(people);
      setSelectedStudent(data)
    } else {
      setSelectedId([...people, id]);
      setSelectedStudent([...data,student])

    }
    handleRemoveSelectedPeople(id);
  }
  const handleRemoveSelectedPeople = (id) => {
    let people = [...selectedId];
    if (people.includes(id)) {
      let index = people.indexOf(id);
      people.splice(index, 1);
      setSelectedId(people);
    }
  };
  return (
    <div>
      <div>
      <Input placeholder="Search Exculde Staff" onChange={(e)=>setSearchName(e.target.value)} />
      </div>
      <div className={Styles.containerBox}>
        
          {student&&student.map((student) => (
                <div className={Styles.searchStudentList}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <img
                      className={Styles.profileImg}
                      src={student.url != "N/A" ? student.url : defaultUser}
                      alt="student-profile"
                    />
                    <p>
                      {student.name || "--"}
                    </p>
                  </div>
                  <div>
                    <Checkbox
                      // spacing={5}
                      colorScheme="red"
                      onChange={() => handleCheckStudents(student._id,{student:student})}
                      isChecked={selectedId.includes(student._id)}
                    />
                  </div>
                </div>
              ))}
        
      </div>
      <br />
      <ActionButtons {...props} lastStep={handleLastStep} />
    </div>
  );
};
const TeacherSteppers = (props) => {
  const [stepWizard, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [selectOption,setSelectOption] = useState("")

  // console.log("props line no 229",user)
  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    // console.log("parent receive user callback");
    // console.log(val);
    setUser((user) => ({
      ...user,
      ...val
    }));
  };

  const handleStepChange = (e) => {
    // console.log("step change");
    // console.log(e);
    setActiveStep(e.activeStep - 1);
  };



  return (
    <>
    {selectOption==""?<div>
    <div>
    <p>
            Do you want to select All Departments
        </p>
    </div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center", marginTop:"25px",marginBottom:"15px"}}>
        <Button style={{background:"green",marginRight:"7px",paddingLeft:"25px",paddingRight:"25px"}} onClick={()=>setSelectOption("Enable")}>Yes</Button>
        <Button style={{background:"green",marginLeft:"7px",paddingLeft:"25px",paddingRight:"25px"}} onClick={()=>setSelectOption("Disable")}>No</Button>
        </div>
      </div>:""}  
      {
    selectOption=="Enable"?<div>
      <Stepper activeStep={activeStep}>
        <Step label="Exclude Staff" />
      </Stepper>
      {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
      <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
        <Fourth user={user} completeCallback={props} />
      </StepWizard>
    </div>:selectOption=="Disable"?<div>
      <Stepper activeStep={activeStep}>
        <Step label="Select Department" children={<MdDescription />} />
        <Step label="Exclude Staff" />
        <Step label="Inculde Staff" />
      </Stepper>
      {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
      <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
        <One userCallback={assignUser} />
        <Two user={user} userCallback={assignUser} />
        <Three user={user} completeCallback={props} />
      </StepWizard>
    </div>:""}

    </>
    
  );
};

export default TeacherSteppers;

import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";
export async function requestGetCanteenFee(payload = {}) {
  const url = BASE_URL + "/management/canteen/getCanteenFees";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestGetClassListFromCategory(payload = {}) {
  const url = BASE_URL + "/management/canteen/getClassListFromCategory";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestCanteenDetails(payload = {}) {
  const url = BASE_URL + "/management/canteen/download";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
const Services = {
  requestGetCanteenFee,
  requestGetClassListFromCategory,
  requestCanteenDetails,
};
export default Services;

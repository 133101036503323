import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";

async function requestTimetableById(payload = {}) {
  const { timetable_id } = payload;

  if (!timetable_id || timetable_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/timetable/getOne/" + timetable_id;
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestSearchTimetable(payload = {}) {
  const url = BASE_URL + "/admin/timetable/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

async function requestAddSubjectAndTime(payload = {}, timetable_id) {
  // const {timetable_id} =  payload;
  console.log("timetable_id", timetable_id);

  if (!timetable_id || timetable_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/timetable/append/" + timetable_id;
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
async function requestUpdateSubjectAndTime(payload = {}) {
  const { timetable_id } = payload;

  if (!timetable_id || timetable_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/timetable/update/" + timetable_id;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
async function requestDeleteSubjectandTime(payload = {}) {
  const { timetable_id } = payload;

  if (!timetable_id || timetable_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/timetable/delete/" + timetable_id;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
async function requestCreateTimeTable(payload = {}) {
  const { class_id } = payload;

  if (!class_id || class_id === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/admin/timetable/create";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
async function getSubjectByClassID(payload = {}) {
  const url = BASE_URL + "/admin/timetable/getSubjectList";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
const Services = {
  requestTimetableById,
  requestSearchTimetable,
  requestAddSubjectAndTime,
  requestUpdateSubjectAndTime,
  requestDeleteSubjectandTime,
  requestCreateTimeTable,
  getSubjectByClassID,
};

export default Services;

import React, { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import {
  requestAllNotifications,
  requestSingleNotificationDetail,
} from "services/common.service";
import Styles from "./DashboardNotification.module.scss";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { Tooltip } from "@chakra-ui/react";
const DashboardNotification = () => {
  const [notificationsData, setNotificationsData] = useState({});
  const [selectedSection, setSelectedSection] = useState("notifications");
  const [expandedNotificationID, setExpandedNotificationID] = useState(null);
  const [singleNotification, setSingleNotifcation] = useState([]);
  const [hoveredNotification, setHoveredNotification] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);
  const handleNotifications = async () => {
    try {
      const response = await requestAllNotifications();
      setNotificationsData(response);
      localStorage.setItem("notificationCount", response.notifications_count);
    } catch (e) {
      console.error(e);
    }
  };
  const getHoverBackgroundColor = (section) => {
    switch (section) {
      case "notifications":
        return "#4caf50";
      case "events":
        return "#2196f3";
      case "circulars":
        return "#ff9800";
      case "reminders":
        return "#9c27b0";
      default:
        return "#9e9e9e";
    }
  };
  const sectionsData = {
    notifications: notificationsData?.notifications || [],
    events: notificationsData?.events || [],
    circulars: notificationsData?.circuilars || [],
    reminders: notificationsData?.reminders || [],
    notificationsCount: notificationsData?.notifications_count || 0,
    eventsCount: notificationsData?.events_count || 0,
    circularsCount: notificationsData?.circuilar_count || 0,
    remindersCount: notificationsData?.reminders_count || 0,
  };
  useEffect(() => {
    handleNotifications();
  }, []);
  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };
  const getBackgroundColor = (section) => {
    return selectedSection === section ? getSelectedColor(section) : "#9e9e9e";
  };
  const getSelectedColor = (section) => {
    switch (section) {
      case "notifications":
        return "#4caf50";
      case "events":
        return "#2196f3";
      case "circulars":
        return "#ff9800";
      case "reminders":
        return "#9c27b0";
      default:
        return "#9e9e9e";
    }
  };
  const getBoxShadow = (section) => {
    return selectedSection === section
      ? getSelectedShadow(section)
      : "0 2px 8px 0 rgba(99,99,99,.2)";
  };
  const getSelectedShadow = (section) => {
    switch (section) {
      case "notifications":
        return "0 0 10px rgba(76, 175, 80, 0.3)";
      case "events":
        return "0 0 10px rgba(33, 150, 243, 0.3)";
      case "circulars":
        return "0 0 10px rgba(255, 152, 0, 0.3)";
      case "reminders":
        return "0 0 10px rgba(156, 39, 176, 0.3)";
      default:
        return "0 2px 8px 0 rgba(99,99,99,.2)";
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const timeFormat = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${day}-${month}-${year}, ${formattedHours}:${minutes}:${seconds} ${timeFormat}`;
  };
  const handleReadMore = async (id) => {
    try {
      if (expandedNotificationID) {
        setSingleNotifcation([]);
        setExpandedNotificationID(null);
      }
      const notification = sectionsData[selectedSection].find(
        (item) => item._id === id
      );

      if (selectedSection === "circulars" || selectedSection === "events") {
        setSingleNotifcation(notification);
        setExpandedNotificationID(id);
      } else {
        const payload = {
          notification_id: id,
        };
        const response = await requestSingleNotificationDetail(payload);
        setSingleNotifcation(response);
        setExpandedNotificationID(id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleHover = (section) => {
    setHoveredSection(section);
  };
  const handleHoverLeave = () => {
    setHoveredSection(null);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "45%",
        }}
      >
        <div
          style={{
            position: "relative",
            boxShadow: getBoxShadow("notifications"),
            background:
              hoveredSection === "notifications"
                ? getHoverBackgroundColor("notifications")
                : getBackgroundColor("notifications"),
          }}
          className={`${Styles.notificationHeading} ${
            selectedSection === "notifications" ? Styles.selected : ""
          }`}
          onMouseEnter={() => handleHover("notifications")}
          onMouseLeave={() => handleHoverLeave("notifications")}
          onClick={() => handleSectionChange("notifications")}
        >
          <p className={Styles.notificationType}>NOTIFICATIONS&nbsp;</p>
          <p className={Styles.notificationCount}>
            {sectionsData.notificationsCount > 0
              ? `(${sectionsData.notificationsCount})`
              : null}
          </p>
        </div>
        <div
          style={{
            position: "relative",
            boxShadow: getBoxShadow("events"),
            background:
              hoveredSection === "events"
                ? getHoverBackgroundColor("events")
                : getBackgroundColor("events"),
          }}
          className={`${Styles.notificationHeading} ${
            selectedSection === "events" ? Styles.selected : ""
          }`}
          onMouseEnter={() => handleHover("events")}
          onMouseLeave={() => handleHoverLeave("events")}
          onClick={() => handleSectionChange("events")}
        >
          <p className={Styles.notificationType}>EVENTS&nbsp;</p>
          <p className={Styles.notificationCount}>
            {sectionsData.eventsCount > 0
              ? `(${sectionsData.eventsCount})`
              : null}
          </p>
        </div>
        <div
          style={{
            position: "relative",
            boxShadow: getBoxShadow("circulars"),
            background:
              hoveredSection === "circulars"
                ? getHoverBackgroundColor("circulars")
                : getBackgroundColor("circulars"),
          }}
          className={`${Styles.notificationHeading} ${
            selectedSection === "circulars" ? Styles.selected : ""
          }`}
          onMouseEnter={() => handleHover("circulars")}
          onMouseLeave={() => handleHoverLeave("circulars")}
          onClick={() => handleSectionChange("circulars")}
        >
          <p className={Styles.notificationType}>CIRCULARS&nbsp;</p>
          <p className={Styles.notificationCount}>
            {sectionsData.circularsCount > 0
              ? `(${sectionsData.circularsCount})`
              : null}
          </p>
        </div>
        {/* <div
          style={{
            position: "relative",
            boxShadow: getBoxShadow("reminders"),
            background:
              hoveredSection === "reminders"
                ? getHoverBackgroundColor("reminders")
                : getBackgroundColor("reminders"),
          }}
          className={`${Styles.notificationHeading} ${
            selectedSection === "reminders" ? Styles.selected : ""
          }`}
          onMouseEnter={() => handleHover("reminders")}
          onMouseLeave={() => handleHoverLeave("reminders")}
          onClick={() => handleSectionChange("reminders")}
        >
          <p className={Styles.notificationType}>REMINDERS&nbsp;</p>
          <div
            style={{
              position: "absolute",
              top: "-15px",
              right: "-5px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "4px",
              fontSize: "11px",
              zIndex: 1,
              width: "30px",
              height: "30px",
              textAlign: "center",
            }}
          >
            {sectionsData.remindersCount > 0 ? (
              <span>{sectionsData.remindersCount}</span>
            ) : null}
          </div>
        </div> */}
      </div>

      <div>
        {sectionsData[selectedSection]?.length === 0 ? (
          <p className={Styles.noResult}>No Notifications Found</p>
        ) : (
          sectionsData[selectedSection]?.map((item, index) => (
            <div
              style={{ display: "flex", justifyContent: "center" }}
              key={index}
            >
              <div
                onMouseEnter={() => setHoveredNotification(item._id)}
                onMouseLeave={() => {
                  setHoveredNotification(null);
                  setExpandedNotificationID(null);
                }}
                className={item.is_read ? Styles.list : Styles.listWithoutRead}
              >
                <div className={Styles.content}>
                  <div style={{ marginLeft: "50px" }}>
                    <p className={Styles.fontSizeTitle}>{item?.title}</p>
                    {selectedSection !== "circulars" &&
                      selectedSection !== "events" && (
                        <p className={Styles.date}>
                          {formatDate(item?.createdAt)}
                        </p>
                      )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ marginLeft: "50px" }}>
                      {item?._id === expandedNotificationID ? (
                        selectedSection !== "circulars" &&
                        selectedSection !== "events" ? (
                          <>
                            <p className={Styles.fontSizeDes}>
                              {singleNotification?.notification?.description}
                            </p>
                            <div className={Styles.delContainer}>
                              <Tooltip label="Read Less">
                                <div>
                                  <MdOutlineKeyboardArrowUp
                                    className={Styles.iconImages}
                                    cursor="pointer"
                                    onClick={() =>
                                      setExpandedNotificationID(null)
                                    }
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className={Styles.fontSizeDes}>
                              {item?.description}
                            </p>
                            <div className={Styles.delContainer}>
                              <Tooltip label="Read Less">
                                <div>
                                  <MdOutlineKeyboardArrowUp
                                    className={Styles.iconImages}
                                    cursor="pointer"
                                    onClick={() =>
                                      setExpandedNotificationID(null)
                                    }
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        )
                      ) : (
                        <div className={Styles.delContainer}>
                          <Tooltip label="Read More">
                            <div>
                              {hoveredNotification === item._id && (
                                <MdOutlineKeyboardArrowDown
                                  className={Styles.iconImages}
                                  cursor="pointer"
                                  onClick={() => handleReadMore(item._id)}
                                />
                              )}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    {selectedSection !== "circulars" &&
                      selectedSection !== "events" && (
                        <div className={Styles.profileContainer}>
                          <AiOutlineUser
                            fontSize={25}
                            style={{
                              border: "2px solid black",
                              borderRadius: "100%",
                              marginRight: "10px",
                            }}
                          />
                          <p className={Styles.fontSize}>
                            {item?.user_name} | {item?.user_role?.toUpperCase()}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardNotification;

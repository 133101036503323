import axios from "axios";
import { useClassAndSections } from "hooks/classAndSection";
import React, { useEffect, useState } from "react";
import { BASE_URL, getRequestOptions } from "services/utils";
import {
  Input,
  Select,
  Spinner,
  useDisclosure,
  useToast,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { debounce } from "utilities/utils";
import { FcCancel } from "react-icons/fc";
import { BsCheckSquare } from "react-icons/bs";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Styles from "./Canteen.module.scss";
import defaultUser from "../../../assets/icons/User.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Services from "services/admin-dashboard/canteen.service";
function Canteen() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const downloadFees = useDisclosure();
  const studentDetail = useDisclosure();
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [studentDetails, setStudentDetails] = useState("");
  const [inputFields, setInputFields] = useState({
    class: "UKG",
    section: "A",
    studentName: "",
  });
  const [canteenFees, setCanteenFees] = useState({ fees: "" });
  const [canteendetailsDownload, setCanteenDetailsDownload] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [canteenCategeory, setCanteenCategeory] = useState("");
  const [getCanteenFee, setGetCanteenFee] = useState("");
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({
    general: true,
  });
  const initialFieldsState = {
    studentName: true,
    class: true,
    section: true,
    fee: true,
    status: true,
    duration: true,
    trialPeriod: false,
    phoneNumber: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [getClassListFromCategory, setGetClassListFromCategory] = useState("");
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const updateCanteenFees = async () => {
    try {
      setRequestInProgress(true);
      const url = BASE_URL + "/admin/canteen/updateCanteenFees";
      const response = await axios.post(
        url,
        {
          canteen_fees: canteenFees.fees,
          category: canteenCategeory,
        },
        getRequestOptions()
      );
      if (response.error) {
        console.error(response.error);
      }
      if (response.status === 200) {
        toast({
          title: "Updated Succesfully",
          description: "Updated fees",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        setCanteenFees("");
        setGetCanteenFee("");
        setGetClassListFromCategory("");
      }
      onClose();
      setRequestInProgress(false);
      fetchStudents();
    } catch (e) {
      onClose();
      setRequestInProgress(false);
      toast({
        title: "Could not Update",
        description:  e?.response?.data?.error,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(e);
    }
  };
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const url = BASE_URL + "/admin/canteen/getWithFilter";
      const response = await axios.post(
        url,
        { ...payload },
        getRequestOptions()
      );
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        const _result = response.data;
        setStudentsList(_result);
        let newArray = [];
        response &&
          response.data.map((dataValue) => {
            let obj1 = {
              Name: dataValue.name,
              Class: dataValue.class,
              Section: dataValue.section,
              Fees: dataValue.amount,
              Status: dataValue.paid == false ? "Unpaid" : "Paid",
            };
            newArray.push(obj1);
            setCanteenDetailsDownload(newArray);
          });
      }

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setStudentsList([]);
      }
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const url = BASE_URL + "/admin/canteen/getWithFilter";
      const response = await axios.post(
        url,
        { ...payload },
        getRequestOptions()
      );
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        const _result = response.data;
        setStudentsList(_result);
        let newArray = [];
        response &&
          response.data.map((dataValue) => {
            let obj1 = {
              Name: dataValue.name,
              Class: dataValue.class,
              Section: dataValue.section,
              Fees: dataValue.amount,
              Status: dataValue.paid == false ? "Unpaid" : "Paid",
            };
            newArray.push(obj1);
            setCanteenDetailsDownload(newArray);
          });
      }

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setStudentsList([]);
      }
    }
  };
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleCanteenInputChange = ({ target: { name, value } }) => {
    setCanteenFees((prev) => ({ ...prev, [name]: value }));
  };
  const handleStudentDetail = (data) => {
    studentDetail.onOpen();
    setStudentDetails(data);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const getCanteenFeeDetails = async (e) => {
    try {
      setCanteenCategeory(e.target.value);
      const payload = {
        category: e.target.value,
      };
      const response = await Services.requestGetCanteenFee(payload);
      const res = await Services.requestGetClassListFromCategory(payload);
      if (response && res) {
        setGetCanteenFee(response);
        setGetClassListFromCategory(res);
        // console.log(res)
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (getCanteenFee) {
      getCanteenFeeDetails();
    }
  }, [getCanteenFee]);
  const totalStudentsCount = students.length;
  const studentsList = isQueried ? students : [];
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  const fileName = `SMU-Canteen-Statistics-${inputFields.class}${inputFields.section}`;
  const ExportToExcel = () => {
    return (
      <div onClick={() => downloadFees.onOpen()}>
        <Button colorScheme="green">Archive</Button>
      </div>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      class: inputFields.class,
      section: inputFields.section,
    };
    const res = await Services.requestCanteenDetails(payload);
    if (res) {
      let newArray = [];
      res &&
        res.map((dataValue) => {
          let obj = {};
          if (fields.studentName) {
            obj["Name"] = handleNAValue(dataValue.name);
          }
          if (fields.class) {
            obj["Class"] = handleNAValue(dataValue.class);
          }
          if (fields.section) {
            obj["Section"] = handleNAValue(dataValue.section);
          }
          if (fields.fee) {
            obj["Total Fees"] = handleNAValue(dataValue.amount);
          }
          if (fields.status) {
            obj["Fees Status"] = handleNAValue(
              dataValue.paid == false ? "Unpaid" : "Paid"
            );
          }
          if (fields.duration) {
            obj["Duration(in months)"] = handleNAValue(dataValue.duration);
          }
          if (fields.trialPeriod) {
            obj["Trial Period"] = handleNAValue(
              dataValue.is_trial == false ? "No" : "True"
            );
          }
          if (fields.phoneNumber) {
            obj["Phone Number"] = handleNAValue(
              dataValue.phone_code + dataValue.phoneNumber
            );
          }
          newArray.push(obj);
        });
      setData(newArray);
      if (newArray.length === 0) {
        toast({
          title: "No students found.",
          variant: "left-accent",
          position: "bottom-left",
          status: "warning",
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    downloadFees.onClose();
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>CANTEEN |</p>
          <p className={Styles.stats}>
            Total Students : {totalStudentsCount || 0}
          </p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.filterWrapper}>
            <Button
              colorScheme="red"
              className={Styles.updateFeesIcon}
              onClick={onOpen}
              style={{ cursor: "pointer" }}
            >
              Update Canteen Fees
            </Button>
          </div>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={inputFields.studentName}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.class ? inputFields.class : ""}
              onChange={handleInputChange}
              name="class"
              placeholder="Select Grade"
            >
              {classAndSections.map((grade, idx) => (
                <option key={grade.class + idx} value={grade.class}>
                  Grade {grade.class}
                </option>
              ))}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.section ? inputFields.section : ""}
              onChange={handleInputChange}
              name="section"
              placeholder="Select Section"
            >
              {sections
                ? sections.map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section}
                    </option>
                  ))
                : ""}
            </Select>
          </div>
          {studentsList.length >= 1 ? (
            <ExportToExcel apiData={data} fileName={fileName} />
          ) : null}
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentsList.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            studentsList.map((student, idx) => (
              <div
                className={Styles.list}
                onClick={() => handleStudentDetail(student)}
              >
                <div style={{ display: "flex" }}>
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={
                        student.profile_url != "N/A"
                          ? student.profile_url
                          : defaultUser
                      }
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>
                      {truncateText(student.name || "--", 20)}
                    </p>
                    <p className={Styles.class}>
                      {student?.class || "--"} |{" "}
                      {student?.section?.toUpperCase() || "--"}
                    </p>
                    <p className={Styles.number}>
                      +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p style={{ marginRight: "20px" }}>
                    Fee Status:{" "}
                    {student?.paid ? (
                      <BsCheckSquare
                        style={{ color: "green", display: "inline" }}
                      />
                    ) : (
                      <FcCancel style={{ display: "inline" }} />
                    )}
                  </p>
                  <p>
                    Trial Period:{" "}
                    {student?.is_trial ? (
                      <BsCheckSquare
                        style={{ color: "green", display: "inline" }}
                      />
                    ) : (
                      <FcCancel style={{ display: "inline" }} />
                    )}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setCanteenFees({ fees: "" });
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Canteen Fees</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div>
                <p>Select Category:</p>
              </div>
              <Select
                onChange={(e) => getCanteenFeeDetails(e)}
                placeholder="Select Category"
              >
                <option value="K">K</option>
                <option value="H1">H1</option>
                <option value="H2">H2</option>
                <option value="P">P</option>
              </Select>
            </div>
            <div>
              <div>
                <p>Classes</p>
              </div>
              <Input
                value={getClassListFromCategory}
                // onChange={handleCanteenInputChange}
                name="class"
                type="text"
                placeholder="Class"
                disabled
              />
            </div>
            <div>
              <div>
                <p>Canteen Fee</p>
              </div>

              <Input
                value={getCanteenFee.canteen_fees}
                // onChange={handleCanteenInputChange}
                name="fees"
                type="text"
                placeholder="Canteen Fees"
                disabled
              />
            </div>
            <div>
              <div>
                <p>New Canteen Fee</p>
              </div>
              <Input
                value={canteenFees.fees}
                onChange={handleCanteenInputChange}
                name="fees"
                type="text"
                placeholder="Canteen Fees"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <Button
                onClick={updateCanteenFees}
                colorScheme="red"
                className={Styles.updateButton}
              >
                Update Fees
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={downloadFees.isOpen} onClose={downloadFees.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Student Details
                  </button>
                </div>
                {showDetails.general && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.class}
                      onChange={() => {
                        setFields({
                          ...fields,
                          class: !fields.class,
                        });
                      }}
                    >
                      Class
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.section}
                      onChange={() => {
                        setFields({
                          ...fields,
                          section: !fields.section,
                        });
                      }}
                    >
                      Section
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.phoneNumber}
                      onChange={() => {
                        setFields({
                          ...fields,
                          phoneNumber: !fields.phoneNumber,
                        });
                      }}
                    >
                      Phone Number
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.trialPeriod}
                      onChange={() => {
                        setFields({
                          ...fields,
                          trialPeriod: !fields.trialPeriod,
                        });
                      }}
                    >
                      Trial Period
                    </Checkbox>
                  </div>
                )}
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={studentDetail.isOpen} onClose={studentDetail.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Canteen Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p style={{ fontWeight: "bold" }}>
              {studentDetails.name} | {studentDetails.class}{" "}
              {studentDetails.section}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "60%",
                marginTop: "5px",
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                <p>Amount (in INR)</p>
                <p>Duration (in Months)</p>
                <p>Fee Status</p>
                <p>Trial Period</p>
              </div>
              <div style={{ fontWeight: "bold" }}>
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
              </div>
              <div>
                <p>{studentDetails.amount}</p>
                <p>{studentDetails.duration}</p>
                <p>{studentDetails.paid ? "Paid" : "Unpaid"}</p>
                <p>{studentDetails.is_trial ? "Yes" : "No"}</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue">Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Canteen;

//Handles both mid term and annual exams martks entry
import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useToast, Button,Spinner } from "@chakra-ui/react";
import Styles from "./MarksEntrySheets.module.scss";

const MarksEntrySheetsMid = () => {
  const toast = useToast();
  const [classes, setClasses] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [examType, setExamType] = useState(null);
  const [studentMarks, setStudentMarks] = useState(null);
  const [marks, setMarks] = useState(null);
  const [save, setSave] = useState(false);
  const [edit, setEdit] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(null);
  const [error, setError] = useState("");
  const storedPermissions = localStorage.getItem("Permission");

  const co_scholastic_subject = [
    // "Discipline",
    "DISCIPLINE",
    "ART EDUCATION",
    "WORK EDUCATION",
    "HEALTH AND PHYSICAL EDUCATION",
    "GENERAL STUDIES",
    "COMMUNICATIVE ENGLISH"
    // "LIFE SKILL",
    // "LIFE SKILLS",
  ];
  const practical_subjects = [
    "PHYSICS",
    "CHEMISTRY",
    "BIOLOGY",
    "COMPUTER SCIENCE",
  ];
  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/marksEntry/classes`,
        {},
        config
      )
      .then((response) => {
        setClasses(response.data.subjects);
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  }, []);

  useEffect(() => {
    if (selectedClass !== null && selectedClass.length && examType) {
      getMarks(selectedClass, examType, config);
      setEdit(false);
    }
  }, [selectedClass, examType]);

  const getMarks = (selectedClass, examType, config) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/getBySubject`,
        {
          class_id: selectedClass[0].class_id,
          subject: selectedClass[0].subject,
          exam: examType,
        },
        config
      )
      .then((response) => {
        let data = response.data.filter((item) => item.mark !== null);
        setStudentMarks(data);
        setMarks(data);
        handleCompletionStatus(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCompletionStatus = (values) => {
    let hasCompletedStatus = !co_scholastic_subject.includes(
      selectedClass[0].subject
    )
      ? values.every((student) => student.mark.total !== "-")
      : values.every((student) => student.mark.grade !== "N/A");
    let isEmptyStatus = !co_scholastic_subject.includes(
      selectedClass[0].subject
    )
      ? values.every((student) => student.mark.total === "-")
      : values.every((student) => student.mark.grade === "N/A");
    setCompletionStatus({
      empty: isEmptyStatus,
      completed: hasCompletedStatus,
    });
  };

  const EditMarks = (field, value, stud) => {
    if (marks) {
      let editedMarks = marks.map((item) => {
        if (item._id === stud._id) {
          return { ...item, mark: { ...item.mark, [field]: value } };
        }
        return item;
      });
      setMarks(editedMarks);
    }
  };

  const handleTotal = (stud) => {
    let totalMarks;
    if (
      selectedClass &&
      selectedClass[0].class == 10 &&
      selectedClass[0].class == 12
    )
      totalMarks =
        parseInt(stud.mark.external) >= 0
          ? parseFloat(stud.mark.external)
          : "-";
    else
      totalMarks =
        parseInt(stud.mark.external) >= 0 && parseInt(stud.mark.internal) >= 0
          ? parseFloat(stud.mark.external) + parseFloat(stud.mark.internal)
          : "-";
    if (parseFloat(totalMarks) >= 0)
      return String(Math.round(parseFloat(totalMarks)));
    return totalMarks;
  };

  const handleGrade = (stud) => {
    let total;
    if (
      selectedClass &&
      (selectedClass[0].class == 10 || selectedClass[0].class == 12)
    )
      total =
        parseInt(stud.mark.external) >= 0
          ? parseFloat(stud.mark.external)
          : "-";
    else
      total =
        parseInt(stud.mark.external) >= 0 && parseInt(stud.mark.internal) >= 0
          ? parseFloat(stud.mark.external) + parseFloat(stud.mark.internal)
          : "-";
    let grade;
    total = total == "-" ? "-" : Math.round(total);
    if (
      selectedClass &&
      (selectedClass[0].class == 10 || selectedClass[0].class == 12)
    ) {
      switch (true) {
        case total >= 71 && total <= 80:
          grade = "A1";
          break;
        case total >= 61 && total <= 70:
          grade = "A2";
          break;
        case total >= 51 && total <= 60:
          grade = "B1";
          break;
        case total >= 41 && total <= 50:
          grade = "B2";
          break;
        case total >= 31 && total <= 40:
          grade = "C1";
          break;
        case total >= 21 && total <= 30:
          grade = "C2";
          break;
        case total >= 11 && total <= 20:
          grade = "D";
          break;
        case total <= 10:
          grade = "E";
          break;
        default:
          grade = "N/A";
      }
      return grade;
    }
    switch (true) {
      case total >= 91 && total <= 100:
        grade = "A1";
        break;
      case total >= 81 && total <= 90:
        grade = "A2";
        break;
      case total >= 71 && total <= 80:
        grade = "B1";
        break;
      case total >= 61 && total <= 70:
        grade = "B2";
        break;
      case total >= 51 && total <= 60:
        grade = "C1";
        break;
      case total >= 41 && total <= 50:
        grade = "C2";
        break;
      case total >= 31 && total <= 40:
        grade = "D";
        break;
      case total <= 30:
        grade = "E";
        break;
      default:
        grade = "N/A";
    }
    return grade;
  };

  const handleGrade1to5 = (stud) => {
    let total;
    if (selectedClass && selectedClass[0].class <= 5)
      total =
        parseInt(stud.mark.external) >= 0 && parseInt(stud.mark.internal) >= 0
          ? parseFloat(stud.mark.external) + parseFloat(stud.mark.internal)
          : "-";
    let grade;
    if (selectedClass && selectedClass[0].class <= 5) {
      switch (true) {
        case total >= 45.5 && total <= 50:
          grade = "A1";
          break;
        case total >= 40.5 && total <= 45:
          grade = "A2";
          break;
        case total >= 35.5 && total <= 40:
          grade = "B1";
          break;
        case total >= 30.5 && total <= 35:
          grade = "B2";
          break;
        case total >= 25.5 && total <= 30:
          grade = "C1";
          break;
        case total >= 20.5 && total <= 25:
          grade = "C2";
          break;
        case total >= 15.5 && total <= 20:
          grade = "D";
          break;
        case total <= 15:
          grade = "E";
          break;
        default:
          grade = "N/A";
      }
      return grade;
    }
  };

  const handleValidation = (val) => {
    let isValid = null;
    if (
      selectedClass &&
      co_scholastic_subject.includes(selectedClass[0].subject)
    ) {
      isValid = true;
    } else if (examType === "anual") {
      val
        .filter((m) => m.mark.internal === "" || m.mark.external === "")
        .map((i) =>
          i.mark.internal === ""
            ? (i.mark.internal = "-")
            : (i.mark.external = "-")
        );
      isValid = val.every(
        (m) =>
          (m.mark.external > 0 ||
            m.mark.external === "0" ||
            m.mark.external == "-") &&
          (m.mark.internal > 0 ||
            m.mark.internal === "0" ||
            m.mark.internal == "-") &&
          (m.mark.total > 0 || m.mark.total === "0" || m.mark.total == "-")
      );
    } else if (examType === "mid_term") {
      if (selectedClass[0].class <= 10) {
        val.filter((m) => m.mark.total === "").map((i) => (i.mark.total = "-"));
        val
          .filter((m) => m.mark.total !== "-" && m.mark.total % 1 !== 0)
          .map((i) => (i.mark.total = Math.round(i.mark.total)));
        isValid = val.every(
          (m) => m.mark.total > 0 || m.mark.total === "0" || m.mark.total == "-"
        );
      } else {
        val
          .filter((m) => m.mark.internal === "" || m.mark.external === "")
          .map((i) =>
            i.mark.internal === "" && i.mark.external === ""
              ? ((i.mark.internal = "-"), (i.mark.external = "-"))
              : i.mark.external === ""
              ? (i.mark.external = "-")
              : i.mark.internal === ""
              ? (i.mark.internal = "-")
              : ""
          );
        val
          .filter((m) => m.mark.internal !== "-" && m.mark.internal % 1 !== 0)
          .map((i) => (i.mark.internal = Math.round(i.mark.internal)));
        val
          .filter((m) => m.mark.external !== "-" && m.mark.external % 1 !== 0)
          .map((i) => (i.mark.external = Math.round(i.mark.external)));
        isValid = val.every(
          (m) =>
            (m.mark.external > 0 ||
              m.mark.external === "0" ||
              m.mark.external == "-") &&
            (m.mark.internal > 0 ||
              m.mark.internal === "0" ||
              m.mark.internal == "-") &&
            (m.mark.total > 0 || m.mark.total === "0" || m.mark.total == "-")
        );
      }
    }
    return isValid;
  };
  const handleSave = () => {
    setSave(true);
    let valid = handleValidation(marks);
    if (valid) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/teacher/exam/updateMarks`,
          {
            exam: examType,
            class: selectedClass ? selectedClass[0].class : null,
            reports: marks.map((m) => {
              return (examType === "anual" &&
                !(selectedClass
                  ? co_scholastic_subject.includes(selectedClass[0].subject)
                  : null)) ||
                (examType === "mid_term" &&
                  selectedClass[0].class > 10 &&
                  !(selectedClass
                    ? co_scholastic_subject.includes(selectedClass[0].subject)
                    : null))
                ? {
                    exam_id: m._id,
                    marks: {
                      ...m.mark,
                      total: handleTotal(m),
                      grade:
                        selectedClass && selectedClass[0].class <= 5
                          ? handleGrade1to5(m)
                          : handleGrade(m),
                    },
                  }
                : examType === "mid_term" &&
                  selectedClass[0].class <= 5 &&
                  !(selectedClass
                    ? co_scholastic_subject.includes(selectedClass[0].subject)
                    : null)
                ? {
                    exam_id: m._id,
                    marks: {
                      ...m.mark,
                      total: handleTotal(m),
                      grade: handleGrade1to5(m),
                    },
                  }
                : {
                    exam_id: m._id,
                    marks: m.mark,
                  };
            }),
          },
          config
        )
        .then((response) => {
          toast({
            title: "Saved!",
            variant: "left-accent",
            position: "bottom-right",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setSave(false);
          setEdit(false);
          getMarks(selectedClass, examType, config);
        })
        .catch((err) => {
          console.log(err);
          setSave(false);
        });
    } else {
      toast({
        title: marks.some(
          (m) => m.mark.total < 0 || m.mark.external < 0 || m.mark.internal < 0
        )
          ? "Negative Values are not allowed!!"
          : marks.some(
              (m) =>
                m.mark.total == "" ||
                m.mark.external == "" ||
                m.mark.internal == ""
            )
          ? "Empty Values are not allowed. Replace them by hyphen"
          : "Invalid characters",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const hasExamEntryPermission = storedPermissions.includes("MARK ENTRY");

  // if (!hasExamEntryPermission) {
  if (!hasExamEntryPermission || error) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "30px",
          marginTop: "150px",
          backgroundColor: "white",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: "20px",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "60%",
          color: "red",
        }}
      >
        You don't have permission to access the Mark Entry Page.
      </div>
    );
  }
  console.log("selectedClass",)
  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <h1 className={Styles.headTitle}>Marks Entry Sheets</h1>
        <div className={Styles.dropDown}>
          <Select
            onChange={(e) =>
              setSelectedClass(
                classes.filter(
                  (item) =>
                    e.target.value.split(" - ")[1].includes(item.class) &&
                    e.target.value.split(" - ")[1].includes(item.section) &&
                    e.target.value.split(" - ")[0].trim() === item.subject
                )
              )
            }
            name="class"
            placeholder="Select Subject"
          >
            {classes
              ? classes.map((item) => (
                  <option>
                    {item.subject} - {item.class}
                    {item.section}
                  </option>
                ))
              : ""}
          </Select>
        </div>

        <div className={Styles.dropDown}>
        {
          selectedClass&&selectedClass[0]?.subject == "COMMUNICATIVE ENGLISH"? <Select
            onChange={(e) => setExamType(e.target.value)}
            name="exam"
            placeholder="Select Exam Semester"
          >
            <option value="anual">Annual Exam</option>
          </Select>: <Select
            onChange={(e) => setExamType(e.target.value)}
            name="exam"
            placeholder="Select Exam Semester"
          >
            <option value="mid_term">Mid-Term Exam</option>
            <option value="anual">Annual Exam</option>
          </Select>
        }
          
        </div>

        <div className={Styles.indicator}>
          <p>
            Completion Status:
            <span>
              {completionStatus
                ? completionStatus.empty
                  ? "EMPTY"
                  : completionStatus.completed
                  ? "COMPLETE"
                  : "PARTIAL"
                : ""}
            </span>
          </p>
          <div
            className={
              completionStatus
                ? completionStatus.empty
                  ? Styles.empty
                  : completionStatus.completed
                  ? Styles.completed
                  : Styles.progress
                : Styles.empty
            }
          />
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        {examType === "mid_term" &&
        (selectedClass
          ? !co_scholastic_subject.includes(selectedClass[0]?.subject)
          : null) ? (
          selectedClass[0]?.class <= 10 ? (
            <table className={Styles.table}>
              <tr>
                <th>SL. NO.</th>
                <th>STUDENT</th>
                {/* <th>
                  MARKS OBTAINED ({selectedClass[0]?.class <= 5 ? 40 : 80})
                </th> */}
                {selectedClass[0]?.class <= 5 ? (
                  <>
                    <th>INTERNAL ASSMNT. (10)</th>
                    <th>FINAL EXAM (40)</th>
                    <th>TOTAL MARKS (50)</th>
                    <th>GRADE</th>
                  </>
                ) : (
                  <th> MARKS OBTAINED(80)</th>
                )}
              </tr>
              {marks
                ? marks.map((student, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{student.name}</td>
                        {selectedClass[0]?.class <= 5 ? (
                          <>
                            <td>
                              <input
                                type="ṭext"
                                value={student.mark.internal}
                                readOnly={!edit}
                                onChange={(e) =>
                                  EditMarks(
                                    "internal",
                                    e.target.value < 10 ||
                                      e.target.value === "-"
                                      ? e.target.value
                                      : 10,
                                    student
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="ṭext"
                                value={student.mark.external}
                                readOnly={!edit}
                                onChange={(e) =>
                                  EditMarks(
                                    "external",
                                    e.target.value < 40 ||
                                      e.target.value === "-"
                                      ? e.target.value
                                      : 40,
                                    student
                                  )
                                }
                              />
                            </td>
                            <td style={{ backgroundColor: "#D8D8D8" }}>
                              <input
                                type="text"
                                id="total"
                                value={handleTotal(student)}
                                readOnly
                              />
                            </td>
                            <td style={{ backgroundColor: "#D8D8D8" }}>
                              <input
                                type="text"
                                id="total"
                                value={handleGrade1to5(student)}
                                readOnly
                              />
                            </td>
                          </>
                        ) : (
                          <td>
                            <input
                              type="ṭext"
                              value={student.mark.total}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "total",
                                  e.target.value < 80 || e.target.value === "-"
                                    ? e.target.value
                                    : 80,
                                  student
                                )
                              }
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })
                : ""}
            </table>
          ) : (
            <table className={Styles.table}>
              <tr>
                <th>SL. NO.</th>
                <th>STUDENT</th>
                <th>
                  THEORY (
                  {practical_subjects.includes(selectedClass[0]?.subject)
                    ? 70
                    : (selectedClass[0]?.class == 11 &&
                        selectedClass[0]?.subject == "PHYSICS") ||
                      selectedClass[0]?.subject == "CHEMISTRY" ||
                      selectedClass[0]?.subject == "BIOLOGY" ||
                      selectedClass[0]?.subject == "COMPUTER SCIENCE"
                    ? 70
                    : 80}
                  )
                </th>
                {(selectedClass && selectedClass[0]?.class != 10) ||
                (selectedClass && selectedClass[0]?.class != 12) ? (
                  <th>
                    INTERNAL ASSESSMENT/ PRACTICAL (
                    {practical_subjects.includes(selectedClass[0]?.subject)
                      ? 30
                      : (selectedClass[0]?.class == 11 &&
                          selectedClass[0]?.subject == "PHYSICS") ||
                        selectedClass[0]?.subject == "CHEMISTRY" ||
                        selectedClass[0]?.subject == "BIOLOGY" ||
                        selectedClass[0]?.subject == "COMPUTER SCIENCE"
                      ? 30
                      : 20}
                    )
                  </th>
                ) : (
                  ""
                )}
                {(selectedClass && selectedClass[0]?.class != 10) ||
                selectedClass[0]?.class != 12 ? (
                  <th>TOTAL MARKS (100)</th>
                ) : (
                  <th>TOTAL MARKS (80)</th>
                )}
              </tr>
              {marks
                ? marks.map((student, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{student.name}</td>
                        {(selectedClass && selectedClass[0].class != 10) ||
                        selectedClass[0]?.class != 12 ? (
                          <td>
                            <input
                              type="ṭext"
                              value={student.mark.internal}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "internal",
                                  e.target.value <
                                    (practical_subjects.includes(
                                      student.mark.subject_name
                                    )
                                      ? 70
                                      : 80) || e.target.value === "-"
                                    ? e.target.value
                                    : practical_subjects.includes(
                                        student.mark.subject_name
                                      )
                                    ? 70
                                    : 80,
                                  student
                                )
                              }
                            />
                          </td>
                        ) : (
                          ""
                        )}

                        <td>
                          <input
                            type="ṭext"
                            value={student.mark.external}
                            readOnly={!edit}
                            onChange={(e) =>
                              EditMarks(
                                "external",
                                e.target.value <
                                  (practical_subjects.includes(
                                    student.mark.subject_name
                                  )
                                    ? 30
                                    : 20) || e.target.value === "-"
                                  ? e.target.value
                                  : practical_subjects.includes(
                                      student.mark.subject_name
                                    )
                                  ? 30
                                  : 20,
                                student
                              )
                            }
                          />
                        </td>
                        <td style={{ backgroundColor: "#D8D8D8" }}>
                          <input
                            type="text"
                            id="total"
                            value={handleTotal(student)}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </table>
          )
        ) : examType === "anual" &&
          (selectedClass
            ? !co_scholastic_subject.includes(selectedClass[0]?.subject)
            : null) ? (
          <table className={Styles.table}>
            <tr>
              <th>SL. NO.</th>
              <th>STUDENT</th>
              {selectedClass &&
              selectedClass[0].class != 10 &&
              selectedClass[0]?.class != 12 ? (
                selectedClass[0]?.class == 1 ||
                selectedClass[0]?.class == 2 ||
                selectedClass[0]?.class == 3 ||
                selectedClass[0]?.class == 4 ||
                selectedClass[0]?.class == 5 ? (
                  <th>INTERNAL ASSMNT. (10)</th>
                ) : (selectedClass[0]?.class == 11 &&
                    selectedClass[0]?.subject == "COMPUTER SCIENCE") ||
                  selectedClass[0]?.subject == "CHEMISTRY" ||
                  selectedClass[0]?.subject == "BIOLOGY" ||
                  selectedClass[0]?.subject == "PHYSICS" ? (
                  <th>INTERNAL ASSMNT. (30)</th>
                ) : selectedClass[0]?.class == 6 &&
                  selectedClass[0]?.subject == "COMPUTER SCIENCE" ? (
                  <th>INTERNAL ASSMNT. (20)</th>
                ) : (
                  <th>INTERNAL ASSMNT. (20)</th>
                )
              ) : (
                ""
              )}
              {selectedClass[0]?.class == 1 ||
              selectedClass[0]?.class == 2 ||
              selectedClass[0]?.class == 3 ||
              selectedClass[0]?.class == 4 ||
              selectedClass[0]?.class == 5 ? (
                <th>FINAL EXAM (40)</th>
              ) : (selectedClass[0]?.class == 11 &&
                  selectedClass[0]?.subject == "COMPUTER SCIENCE") ||
                selectedClass[0]?.subject == "CHEMISTRY" ||
                selectedClass[0]?.subject == "BIOLOGY" ||
                selectedClass[0]?.subject == "PHYSICS" ? (
                <th>FINAL EXAM (70)</th>
              ) : (
                <th>FINAL EXAM (80)</th>
              )}
              {selectedClass &&
              selectedClass[0].class != 10 &&
              selectedClass[0]?.class != 12 ? (
                selectedClass[0]?.class == 1 ||
                selectedClass[0]?.class == 2 ||
                selectedClass[0]?.class == 3 ||
                selectedClass[0]?.class == 4 ||
                selectedClass[0]?.class == 5 ? (
                  <th>TOTAL MARKS (50)</th>
                ) : (
                  <th>TOTAL MARKS (100)</th>
                )
              ) : (
                <th>TOTAL MARKS (80)</th>
              )}
              <th>GRADE</th>
            </tr>
            {marks
              ? marks.map((student, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{student.name}</td>
                      {selectedClass &&
                      selectedClass[0].class != 10 &&
                      selectedClass[0]?.class != 12 ? (
                        selectedClass[0]?.class <= 5 ? (
                          <td>
                            <input
                              type="text"
                              value={student.mark.internal}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "internal",
                                  e.target.value < 10 || e.target.value === "-"
                                    ? e.target.value
                                    : 10,
                                  student
                                )
                              }
                            />
                          </td>
                        ) : (selectedClass[0].class == "11" &&
                            selectedClass[0]?.subject == "COMPUTER SCIENCE") ||
                          selectedClass[0]?.subject == "CHEMISTRY" ||
                          selectedClass[0]?.subject == "BIOLOGY" ||
                          selectedClass[0]?.subject == "PHYSICS" ? (
                          <td>
                            <input
                              type="text"
                              value={student.mark.internal}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "internal",
                                  e.target.value < 30 || e.target.value === "-"
                                    ? e.target.value
                                    : 30,
                                  student
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              value={student.mark.internal}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "internal",
                                  e.target.value < 20 || e.target.value === "-"
                                    ? e.target.value
                                    : 20,
                                  student
                                )
                              }
                            />
                          </td>
                        )
                      ) : (
                        ""
                      )}

                      {/* <td>
                        <input
                          type="text"
                          value={student.mark.external}
                          readOnly={!edit}
                          onChange={(e) =>
                            EditMarks(
                              "external",
                              e.target.value < 80 || e.target.value === "-"
                                ? e.target.value
                                : 80,
                              student
                            )
                          }
                        />
                      </td> */}
                      {selectedClass &&
                      selectedClass[0].class != 10 &&
                      selectedClass[0]?.class != 12 ? (
                        selectedClass[0]?.class <= 5 ? (
                          <td>
                            <input
                              type="text"
                              value={student.mark.external}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "external",
                                  e.target.value < 40 || e.target.value === "-"
                                    ? e.target.value
                                    : 40,
                                  student
                                )
                              }
                            />
                          </td>
                        ) : (selectedClass[0].class == "11" &&
                            selectedClass[0]?.subject == "COMPUTER SCIENCE ") ||
                          selectedClass[0]?.subject == "CHEMISTRY" ||
                          selectedClass[0]?.subject == "BIOLOGY" ||
                          selectedClass[0]?.subject == "PHYSICS" ? (
                          <td>
                            <input
                              type="text"
                              value={student.mark.external}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "external",
                                  e.target.value < 70 || e.target.value === "-"
                                    ? e.target.value
                                    : 70,
                                  student
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td>
                            <input
                              type="text"
                              value={student.mark.external}
                              readOnly={!edit}
                              onChange={(e) =>
                                EditMarks(
                                  "external",
                                  e.target.value < 80 || e.target.value === "-"
                                    ? e.target.value
                                    : 80,
                                  student
                                )
                              }
                            />
                          </td>
                        )
                      ) : selectedClass[0].class == 10 ||
                        selectedClass[0].class == 12 ? (
                        <td>
                          <input
                            type="text"
                            value={student.mark.external}
                            readOnly={!edit}
                            onChange={(e) =>
                              EditMarks(
                                "external",
                                e.target.value < 80 || e.target.value === "-"
                                  ? e.target.value
                                  : 80,
                                student
                              )
                            }
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      <td style={{ backgroundColor: "#D8D8D8" }}>
                        <input
                          type="text"
                          id="total"
                          value={handleTotal(student)}
                          readOnly
                        />
                      </td>
                      {/* {selectedClass && selectedClass[0].class <= "5" ? (
                        <td style={{ backgroundColor: "#D8D8D8" }}>
                          <input
                            type="text"
                            id="total"
                            value={handleGrade1to5(student)}
                            readOnly
                          />
                        </td>
                      ) : (
                        <td style={{ backgroundColor: "#D8D8D8" }}>
                          <input
                            type="text"
                            id="total"
                            value={handleGrade(student)}
                            readOnly
                          />
                        </td>
                      )} */}
                      {(selectedClass && selectedClass[0].class == "6") ||
                      selectedClass[0].class == "7" ||
                      selectedClass[0].class == "8" ||
                      selectedClass[0].class == "9" ||
                      selectedClass[0].class == "11" ? (
                        <td style={{ backgroundColor: "#D8D8D8" }}>
                          <input
                            type="text"
                            id="total"
                            value={handleGrade(student)}
                            readOnly
                          />
                        </td>
                      ) : selectedClass && selectedClass[0].class <= "5" ? (
                        <td style={{ backgroundColor: "#D8D8D8" }}>
                          <input
                            type="text"
                            id="total"
                            value={handleGrade1to5(student)}
                            readOnly
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })
              : ""}
          </table>
        ) : (
          ""
        )}
        {examType &&
        selectedClass &&
        co_scholastic_subject.includes(selectedClass[0]?.subject) ? (
          <table className={Styles.table}>
            <tr>
              <th>SL. NO.</th>
              <th>STUDENT</th>
              <th> {selectedClass[0]?.subject}</th>
            </tr>
            {marks
              ? marks.map((student, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{student.name}</td>
                      <td>
                        {!edit ? (
                          student.mark.grade
                        ) : (
                          <Select
                            onChange={(e) =>
                              EditMarks("grade", e.target.value, student)
                            }
                            style={{ textAlign: "center" }}
                            name="discipline-grade"
                            value={student.mark.grade}
                          >
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="N/A">Select Grade</option>
                          </Select>
                        )}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </table>
        ) : (
          ""
        )}
      </div>

      <div className={Styles.buttonWrapper}>
        <div className={Styles.buttonSubWrapper}>
          <Button className={Styles.button} disabled={save?true:false} onClick={() => handleSave()}>
          {
            save?(<Spinner/>):("Save")
          }
            
          </Button>
          <Button
            className={edit ? Styles.buttonEditOn : Styles.button}
            onClick={() => {
              toast({
                title: "Edit Mode On",
                variant: "left-accent",
                position: "bottom-right",
                status: "info",
                duration: 2000,
                isClosable: true,
              });
              setEdit(true);
            }}
          >
            Edit
          </Button>
        </div>
        {/* <Link to='/staff/dashboard/exams/report' className={Styles.button}>Report card</Link> */}
      </div>
    </div>
  );
};

export default MarksEntrySheetsMid;

import React, { useRef, useState, useEffect } from "react";
import Styles from "./StatisticsReport.module.scss";
import defaultUser from "assets/icons/User.png";
import { Button, Tooltip, useToast } from "@chakra-ui/react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import {
  requestHolidays,
  requestStatsOne,
  requestUpdateOne,
} from "services/admin-dashboard/new-attendance.service";
import { useLocation } from "react-router-dom";
import { IoMdCloseCircleOutline } from "react-icons/io";
const StatisticsReport = () => {
  const location = useLocation();
  const calendarRef = useRef(null);
  const [previousStatus, setPreviousStatus] = useState("");
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const toast = useToast();
  const years = [];
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const [selectedStatus, setSelectedStatus] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [oneStatus, setOneStatus] = useState([]);
  const [isAbsentOpen, setIsAbsentOpen] = useState(false);
  const [isHalfDayOpen, setIsHalfDayOpen] = useState(false);
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
              <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                <div class="${Styles.date}">${date.getDate()}</div>
              </div>
            `,
    };
  };
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);

    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;

    if (clickedDate < new Date(2023, 5, 1)) {
      toast({
        title: "The dates beyond the session is invalid",
        description: "The academic session date range is startdate- end date. ",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // Calculate the difference in months between the clicked date and the selected month
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;

    // Calculate the new month and year based on the clicked date
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;

    // Update the state to the clicked date, month, and year
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    calendarRef.current.getApi().unselect(); // Unselect the clicked date

    setTimeout(() => {
      calendarRef.current.getApi().updateSize(); // Update the calendar size after a delay
    }, 0);
  };
  const handleStatusChange = (event) => {
    setPreviousStatus(selectedStatus);
    const newSelectedStatus = event.target.value;
    setSelectedStatus(event.target.value);
    if (newSelectedStatus !== selectedStatus) {
      setIsStatusChanged(true);
    } else {
      setIsStatusChanged(false);
    }
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  const getOneStudentStat = async () => {
    const payload = {
      student_id: location?.state?.studentId,
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const res = await requestStatsOne(payload);
    setOneStatus(res);
  };
  const handleUpdateStatus = async () => {
    const payload = {
      student_id: location?.state?.studentId,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      status: selectedStatus,
    };
    const response = await requestUpdateOne(payload);
    toast({
      title: response.msg,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom-right",
    });
    setIsStatusChanged(false);
    getOneStudentStat();
  };
  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedMorningPresent = oneStatus.morning_present_array
      ? oneStatus.morning_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAfternoonPresent = oneStatus.afternoon_present_array
      ? oneStatus.afternoon_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedPresent = oneStatus.present_array
      ? oneStatus.present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAbsent = oneStatus.absent_array
      ? oneStatus.absent_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (formattedMorningPresent.includes(date)) {
          dayEl.style.backgroundColor = "orange";
          dayEl.style.color = "white";
        } else if (formattedAfternoonPresent.includes(date)) {
          dayEl.style.backgroundColor = "#c625df8f";
          dayEl.style.color = "white";
        } else if (formattedPresent.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedAbsent.includes(date)) {
          dayEl.style.backgroundColor = "red";
          dayEl.style.color = "white";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi?.batchRendering(() => {
      highlightDates();
      calendarApi?.render();
    });
  }, [selectedMonth, holidays, oneStatus, selectedYear]);
  useEffect(() => {
    if (oneStatus) {
      if (
        oneStatus.present_array &&
        oneStatus.present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("present");
      } else if (
        oneStatus.morning_present_array &&
        oneStatus.morning_present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("morning-present");
      } else if (
        oneStatus.afternoon_present_array &&
        oneStatus.afternoon_present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("afternoon-present");
      } else if (
        oneStatus.absent_array &&
        oneStatus.absent_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("absent");
      } else {
        setSelectedStatus("");
      }
    }
  }, [selectedDate, oneStatus]);
  useEffect(() => {
    if (selectedYear && selectedMonth !== undefined) {
      handleHolidays();
      getOneStudentStat();
      setHolidays(null);
    }
  }, [selectedMonth, selectedYear]);
  function renderAbsentDays(absentDays) {
    const sortedAbsentDays = absentDays?.sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const groupedByMonth = groupAbsentDaysByMonth(sortedAbsentDays);
    return Object.keys(groupedByMonth).map((month) => {
      const monthName = new Date(month).toLocaleDateString("default", {
        month: "long",
      });
      const dates = groupedByMonth[month];
      const dateText = dates.join(", ");
      return (
        <div key={month}>
          <p style={{ fontWeight: "bold" }}>
            Month : <span style={{ fontWeight: 500 }}>{monthName}</span>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Dates :{" "}
            <span style={{ fontWeight: 500, color: "red" }}>{dateText}</span>
          </p>
          <hr
            style={{
              border: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
        </div>
      );
    });
  }
  function groupAbsentDaysByMonth(absentDays) {
    const grouped = {};
    for (const date of absentDays) {
      const month = new Date(date).toISOString().split("T")[0].substring(0, 7);
      if (grouped[month]) {
        grouped[month].push(new Date(date).getDate());
      } else {
        grouped[month] = [new Date(date).getDate()];
      }
    }
    return grouped;
  }
  function renderHalfDayPresentDays(
    morningPresentDates,
    afternoonPresentDates
  ) {
    const allHalfDayPresentDates = morningPresentDates.concat(
      afternoonPresentDates
    );
    const sortedHalfDayPresentDates = allHalfDayPresentDates?.sort(
      (a, b) => new Date(a) - new Date(b)
    );

    const groupedByMonth = groupHalfDayPresentDatesByMonth(
      sortedHalfDayPresentDates
    );

    return Object.keys(groupedByMonth).map((month) => {
      const monthName = new Date(month).toLocaleDateString("default", {
        month: "long",
      });
      const dates = groupedByMonth[month];
      const dateText = dates.join(", ");
      return (
        <div key={month}>
          <p style={{ fontWeight: "bold" }}>
            Month : <span style={{ fontWeight: 500 }}>{monthName}</span>
          </p>
          <p style={{ fontWeight: "bold" }}>
            Dates :{" "}
            <span style={{ fontWeight: 500, color: "red" }}>{dateText}</span>
          </p>
          <hr
            style={{
              border: "1px solid black",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          />
        </div>
      );
    });
  }
  function groupHalfDayPresentDatesByMonth(halfDayPresentDates) {
    const grouped = {};
    for (const date of halfDayPresentDates) {
      const month = new Date(date).toISOString().split("T")[0].substring(0, 7);
      if (grouped[month]) {
        grouped[month].push(new Date(date).getDate());
      } else {
        grouped[month] = [new Date(date).getDate()];
      }
    }
    return grouped;
  }
  return (
    <div>
      <p className={Styles.heading}>Student Attendance Report</p>
      <div>
        <div className={Styles.updateHeadingContainer}>
          <div className={Styles.profileModal}>
            <img
              className={Styles.modalProfileImg}
              src={oneStatus.url != "N/A" ? oneStatus.url : defaultUser}
              alt="student-profile"
            />
            <div>
              <p className={Styles.studentName}>
                <span style={{ fontWeight: 500 }}>Name :</span>{" "}
                {oneStatus.student_name}
              </p>
              <p className={Styles.studentName}>
                <span style={{ fontWeight: 500 }}>Grade :</span>{" "}
                {(oneStatus?.class && oneStatus?.class) || "--"}|{" "}
                {oneStatus?.section?.toUpperCase() || "--"}
              </p>
            </div>
          </div>
          <Tooltip label="Academic Attendance Summary" placement="top">
            <div
              // style={{
              //   display: "flex",
              //   background: "white",
              //   boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              //   borderRadius: "5px",
              //   flexDirection: "column",
              //   width: "60%",
              //   padding: "24px",
              //   height: "100px",
              // }}
              className={Styles.percentageContainer}
            >
              <div className={Styles.percentageSubContainer}>
                <p
                  style={{ fontWeight: 800 }}
                  className={Styles.forMarginBottom}
                >
                  Attendance Percentage :&nbsp;
                  <Tooltip
                    label={`Total Present : ${oneStatus.attendance_final_present}, Total Absent : ${oneStatus.attendance_final_absent}`}
                  >
                    <span
                      style={{
                        backgroundColor:
                          oneStatus.attendance_percentage > 85
                            ? "green"
                            : oneStatus.attendance_percentage >= 75 &&
                              oneStatus.attendance_percentage <= 84
                            ? "orange"
                            : "red",
                        padding: "3px",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "20px",
                      }}
                    >
                      {oneStatus.attendance_percentage !== undefined &&
                      oneStatus.attendance_percentage !== null
                        ? oneStatus.attendance_percentage % 1 !== 0
                          ? oneStatus.attendance_percentage.toFixed(2)
                          : oneStatus.attendance_percentage.toFixed(0)
                        : ""}
                      %
                    </span>
                  </Tooltip>
                </p>
                <div
                  onMouseEnter={() => setIsAbsentOpen(true)}
                  onMouseLeave={() => setIsAbsentOpen(false)}
                >
                  <Button colorScheme="red" className={Styles.forMarginBottom}>
                    Absent Days
                  </Button>
                  {isAbsentOpen ? (
                    <div className={Styles.absentHoverContainer}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        onClick={() => setIsAbsentOpen(false)}
                      >
                        <IoMdCloseCircleOutline fontSize={20} />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Academic Absent Dates
                      </p>
                      {renderAbsentDays(oneStatus.total_absent_array)}
                    </div>
                  ) : null}
                </div>
                <div
                  onMouseEnter={() => setIsHalfDayOpen(true)}
                  onMouseLeave={() => setIsHalfDayOpen(false)}
                >
                  <Button colorScheme="blue" className={Styles.forMarginBottom}>
                    Half Day Present
                  </Button>
                  {isHalfDayOpen ? (
                    <div className={Styles.halfDayHoverContainer}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        onClick={() => setIsHalfDayOpen(false)}
                      >
                        <IoMdCloseCircleOutline fontSize={20} />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Half Day Present Dates
                      </p>
                      {renderHalfDayPresentDays(
                        oneStatus.total_morning_present_array,
                        oneStatus.total_afternoon_present_array
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={Styles.percentageSubContainer}>
                <p>Total Added Days : {oneStatus.total_count}</p>
                <p>
                  Present :
                  <span style={{ color: "green" }}>
                    &nbsp;{oneStatus.total_present_count}
                  </span>
                </p>
                <p>
                  Absent :
                  <span style={{ color: "red" }}>
                    &nbsp;{oneStatus.total_absent_count}
                  </span>
                </p>
                <p>Half Day Present : {oneStatus.total_internal_count}</p>
              </div>
            </div>
          </Tooltip>
        </div>
        <div className={Styles.sessionContainer}>
          <div className={Styles.sessionSubContainer}>
            <p>
              Academic Session &nbsp;:{" "}
              {moment(oneStatus.start_date).format("DD/MM/YYYY")}-
              {moment(oneStatus.end_date).format("DD/MM/YYYY")}
            </p>
            <p>Total Session Days &nbsp;: {oneStatus.total_days}</p>
            <p>
              Mid-Term Date &nbsp;:{" "}
              {moment(oneStatus.mid_term_date).format("DD/MM/YYYY")}
            </p>
            <p>Mid-Term Days &nbsp;: {oneStatus.mid_term_days}</p>
          </div>
        </div>
        <Tooltip label="Monthly Attendance Summary" placement="bottom">
          <div className={Styles.monthDataContainer}>
            <p>
              Monthly Added Days :{" "}
              <span>
                {oneStatus.absent_array?.length +
                  oneStatus.present_array?.length +
                  oneStatus.morning_present_array?.length +
                  oneStatus.afternoon_present_array?.length}
              </span>
            </p>
            <p>
              Present :{" "}
              <span style={{ color: "green" }}>
                {oneStatus.present_array?.length}
              </span>
            </p>
            <p>
              Absent :{" "}
              <span style={{ color: "red" }}>
                {oneStatus.absent_array?.length}
              </span>
            </p>
            <p>
              Half Day Present :{" "}
              {oneStatus.morning_present_array?.length +
                oneStatus.afternoon_present_array?.length}
            </p>
          </div>
        </Tooltip>
        <div className={Styles.calendarMain}>
          <div className={Styles.modalCalendar}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid black",
                padding: "5px",
              }}
            >
              <div>
                <button onClick={handlePreviousMonth}>
                  <FaCaretSquareLeft className={Styles.arrows} />
                </button>
              </div>
              <div>{renderHeader()}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className={Styles.todayButton}
                  onClick={handleTodayButtonClick}
                  style={{ display: isToday() ? "none" : "block" }}
                >
                  Today
                </button>
                <button onClick={handleNextMonth}>
                  <FaCaretSquareRight className={Styles.arrows} />
                </button>
              </div>
            </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              selectable={true}
              dateClick={handleDateClick}
              dayCellContent={dayCellContent}
              dayHeaderContent={dayHeaderContent}
            />
          </div>
          <div className={Styles.calendaDataContainer}>
            <div className={Styles.dateContainer}>
              <div style={{ marginBottom: "10px", fontWeight: 800 }}>
                Date : &nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
              </div>
              {!selectedDate ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className={Styles.noDate}>No Date Selected</p>
                </div>
              ) : oneStatus &&
                (oneStatus.present_array.some(
                  (date) =>
                    moment(date).format("DD-MM-YYYY") ===
                    moment(selectedDate).format("DD-MM-YYYY")
                ) ||
                  oneStatus.morning_present_array.some(
                    (date) =>
                      moment(date).format("DD-MM-YYYY") ===
                      moment(selectedDate).format("DD-MM-YYYY")
                  ) ||
                  oneStatus.afternoon_present_array.some(
                    (date) =>
                      moment(date).format("DD-MM-YYYY") ===
                      moment(selectedDate).format("DD-MM-YYYY")
                  ) ||
                  oneStatus.absent_array.some(
                    (date) =>
                      moment(date).format("DD-MM-YYYY") ===
                      moment(selectedDate).format("DD-MM-YYYY")
                  )) ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        marginBottom: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          style={{ marginRight: 10 }}
                          checked={selectedStatus === "present"}
                          onChange={handleStatusChange}
                          value="present"
                        />
                        Present
                      </label>
                    </div>
                    <div
                      style={{
                        width: "60%",
                        marginBottom: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          style={{ marginRight: 10 }}
                          checked={selectedStatus === "morning-present"}
                          onChange={handleStatusChange}
                          value="morning-present"
                        />
                        Morning Present
                      </label>
                    </div>
                    <div
                      style={{
                        width: "60%",
                        marginBottom: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          style={{ marginRight: 10 }}
                          checked={selectedStatus === "afternoon-present"}
                          onChange={handleStatusChange}
                          value="afternoon-present"
                        />
                        Afternoon Present
                      </label>
                    </div>
                    <div
                      style={{
                        width: "60%",
                        marginBottom: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          style={{ marginRight: 10 }}
                          checked={selectedStatus === "absent"}
                          onChange={handleStatusChange}
                          value="absent"
                        />
                        Absent
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#228B15",
                        color: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        if (isStatusChanged) {
                          handleUpdateStatus();
                        }
                      }}
                      disabled={!isStatusChanged}
                    >
                      Update Status
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className={Styles.noDate}>Invalid Date</p>
                </div>
              )}
            </div>

            <div className={Styles.presentContainer}>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "orange",
                    marginRight: "5px",
                  }}
                ></div>
                <p>Morning Present</p>
              </div>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#c625df8f",
                    marginRight: "5px",
                  }}
                ></div>
                <p>Afternoon Present</p>
              </div>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "rgb(51 190 51)",
                    marginRight: "10px",
                  }}
                ></div>
                <p>Present</p>
              </div>
            </div>
            <div className={Styles.presentContainer}>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "red",
                    marginRight: "5px",
                  }}
                ></div>
                <p>Absent</p>
              </div>
              <div
                style={{
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#9c9695",
                    marginRight: "5px",
                  }}
                ></div>
                <p>Holiday / Sunday </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsReport;

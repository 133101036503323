import {
  Button,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tooltip,
  Checkbox,
  Switch,
  Box,
  Heading,
  Text,
  CloseButton,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import React, { useState, useRef, useEffect } from "react";
import Styles from "./Attendance.module.scss";
import { debounce } from "utilities/utils";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import defaultUser from "assets/icons/User.png";
import moment from "moment";
import {
  requestAddAttendance,
  requestAttendanceClass,
  requestDeleteAttendance,
  requestGetAttendance,
  requestGetClass,
  requestGetClassStats,
  requestHolidays,
  requestUpdateAttendance,
} from "services/staff-dashboard/attendance.service";
import { requestSearchStudentByFilterCommon } from "services/common.service";
import { Link } from "react-router-dom";

const NewTeacherAttendance = () => {
  const [classAndSection, setClassAndSection] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab.toString());
  }, [selectedTab]);
  const handlegetClass = async () => {
    const response = await requestGetClass();
    setClassAndSection(response);
    setIsLoading(false);
  };
  useEffect(() => {
    handlegetClass();
  }, []);
  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {classAndSection && classAndSection?.length > 0 ? (
            <div>
              <Tabs
                isLazy
                isFitted
                variant="enclosed"
                index={selectedTab}
                onChange={(index) => setSelectedTab(index)}
              >
                <TabList mb="1em">
                  <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Attendance
                  </Tab>
                  <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Attendance Statistics
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Attendance />
                  </TabPanel>
                  <TabPanel>
                    <Statistics />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "150px",
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                padding: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "60%",
                color: "red",
              }}
            >
              No data found for Attendance Module.
            </div>
          )}
        </>
      )}
    </>
  );
};
export default NewTeacherAttendance;

const Attendance = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputFields, setInputFields] = useState({});
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  const [students, setStudentsList] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [classAndSection, setClassAndSection] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [morningList, setMorningList] = useState([]);
  const [afternoonList, setAfternoonList] = useState([]);
  const [presentList, setPresentList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [classStatus, setClassStatus] = useState(null);
  const [attendanceDays, setAttendanceDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const years = [];
  const [exclude, setExclude] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const calendarRef = useRef(null);
  const modalAddAttendance = useDisclosure();
  const modalUpdateAttendance = useDisclosure();
  const modalUpdatePopup = useDisclosure();
  const modalDeletePopup = useDisclosure();
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef?.current?.getApi();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi?.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();
    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef?.current?.getApi()?.updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
                <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                  <div class="${Styles.date}">${date.getDate()}</div>
                </div>
              `,
    };
  };
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    if (clickedDate < new Date(2023, 5, 1)) {
      toast({
        title: "The dates beyond the session is invalid",
        description: "The academic session date range is startdate- end date. ",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    // Calculate the difference in months between the clicked date and the selected month
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;
    // Calculate the new month and year based on the clicked date
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;
    // Update the state to the clicked date, month, and year
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    calendarRef?.current?.getApi()?.unselect(); // Unselect the clicked date
    setTimeout(() => {
      calendarRef?.current?.getApi()?.updateSize(); // Update the calendar size after a delay
    }, 0);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "class") {
      setInputFields({
        ...inputFields,
        class: value,
        section: "",
      });
    } else {
      setInputFields({
        ...inputFields,
        [name]: value,
      });
    }
    setShowSearchIcon(true);
    if (value.trim() !== "") {
      setShowSearchIcon(true);
    } else {
      setShowSearchIcon(false);
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const studentList = isQueried ? searchResultList : students;
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  useEffect(() => {
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : "";
    setInputFields(input_data);
    setShowSearchIcon(
      !input_data ||
        !input_data.studentName ||
        input_data.studentName.trim() === ""
    );
  }, []);
  const searchStudentByQuery = debounce(function () {
    if (isClassQuery && isSectionQuery) {
      fetchStudents();
    }
  }, 500);
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  const handlegetClass = async () => {
    const response = await requestGetClass();
    setClassAndSection(response);
  };
  useEffect(() => {
    handlegetClass();
  }, []);
  const handleAttendanceForClass = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      class: inputFields.class,
      section: inputFields.section,
      year: selectedYear,
    };
    const response = await requestAttendanceClass(payload);
    setAttendanceDays(response);
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      class: inputFields.class,
      section: inputFields.section,
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  const selectDate = moment(selectedDate).format("YYYY-MM-DD");
  const formattedHolidays = holidays
    ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  const isTodayHoliday = formattedHolidays.includes(selectDate);
  const isSunday = selectedDate && selectedDate?.getDay() === 0;
  const isFutureDate = selectedDate > new Date();
  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedAttendanceDays = attendanceDays
      ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();

        if (formattedAttendanceDays.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };

    calendarApi?.batchRendering(() => {
      highlightDates();
      calendarApi.render();
    });
  }, [
    attendanceDays,
    holidays,
    inputFields.class,
    inputFields.section,
    selectedMonth,
  ]);
  useEffect(() => {
    if (
      selectedYear &&
      selectedMonth !== undefined &&
      inputFields.class &&
      inputFields.section
    ) {
      handleAttendanceForClass();
      setAttendanceDays(null);
      handleHolidays();
      setHolidays(null);
    }
  }, [selectedMonth, inputFields.class, inputFields.section]);
  const handleAddAttendance = async () => {
    let absentList = [];
    for (let i = 0; i < studentList.length; i++) {
      const element = studentList[i]._id;
      if (
        !morningList.includes(element) &&
        !afternoonList.includes(element) &&
        !presentList.includes(element)
      ) {
        absentList.push(element);
      }
    }
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      morning_present: morningList,
      afternoon_present: afternoonList,
      present: presentList,
      absent: absentList,
    };
    try {
      const res = await requestAddAttendance(data);
      if (res) {
        modalAddAttendance.onClose();
        handleGetClassStats();
        handleGetAttendance();
        setMorningList([]);
        setAfternoonList([]);
        setPresentList([]);
        toast({
          render: () => (
            <Box
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                backgroundColor: "#40a46c",
                color: "white",
              }}
              p={4}
              borderRadius="md"
              boxShadow="lg"
            >
              <Box>
                <Heading size="md" mb={2}>
                  Attendance Added Succesfully
                </Heading>
                <Text>Please wait for reflecting the date in the calendar</Text>
              </Box>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                color="white"
                onClick={() => {
                  handleClose();
                  toast.closeAll();
                }}
              />
            </Box>
          ),
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);
      }
    } catch (error) {
      modalAddAttendance.onClose();
      toast({
        title: error,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleGetAttendance = () => {
    setMorningList([]);
    setAfternoonList([]);
    setPresentList([]);
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = requestGetAttendance(data);
    res
      .then((response) => {
        setAttendanceData(response);
      })
      .catch((error) => {});
  };
  const handleGetClassStats = async () => {
    setMorningList([]);
    setAfternoonList([]);
    setPresentList([]);
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = await requestGetClassStats(data);
    setClassStatus(res);
  };
  const handleAttendanceData = () => {
    setMorningList(attendanceData.morning_present);
    setAfternoonList(attendanceData.afternoon_present);
    setPresentList(attendanceData.present);
    setAbsentList(attendanceData.absent);
  };
  useEffect(() => {
    if (selectedDate && attendanceData) {
      handleAttendanceData();
    }
  }, [attendanceData]);
  const handleMorningChange = (id) => {
    let morning = [...morningList];
    if (morning.includes(id)) {
      let index = morning.indexOf(id);
      morning.splice(index, 1);
      setMorningList(morning);
    } else {
      if (presentList.includes(id)) {
        setAfternoonList([...afternoonList, id]);
      } else {
        setMorningList([...morning, id]);
      }
    }
    handleRemovePresentChange(id);
  };
  const handleAfternoonChange = (id) => {
    let afternoon = [...afternoonList];
    if (afternoon.includes(id)) {
      let index = afternoon.indexOf(id);
      afternoon.splice(index, 1);
      setAfternoonList(afternoon);
    } else {
      if (presentList.includes(id)) {
        setMorningList([...morningList, id]);
      } else {
        setAfternoonList([...afternoon, id]);
      }
    }
    handleRemovePresentChange(id);
  };
  const handlePresentChange = () => {
    let present = [...presentList];
    let morning = [...morningList];
    let afternoon = [...afternoonList];
    for (let i = 0; i < morningList.length; i++) {
      const element1 = morningList[i];
      for (let j = 0; j < afternoonList.length; j++) {
        const element2 = afternoonList[j];
        if (element1 === element2 && !present.includes(element2)) {
          let morningIndex = morning.indexOf(element2);
          morning.splice(morningIndex, 1);
          let afternoonIndex = afternoon.indexOf(element2);
          afternoon.splice(afternoonIndex, 1);
          setMorningList(morning);
          setAfternoonList(afternoon);
          setPresentList([...present, element2]);
        }
      }
    }
  };
  const handleRemovePresentChange = (id) => {
    let present = [...presentList];
    if (present.includes(id)) {
      let index = present.indexOf(id);
      present.splice(index, 1);
      setPresentList(present);
    }
  };
  const handleCheckAll = () => {
    if (studentList.length === presentList.length) {
      setPresentList([]);
    } else {
      let tempStudentList = studentList.map((item) => item._id);
      setPresentList(tempStudentList);
    }
  };
  useEffect(() => {
    handlePresentChange();
  }, [afternoonList, morningList]);
  const handleUpdateAttendance = async () => {
    let absentList = [];
    for (let i = 0; i < studentList.length; i++) {
      const element = studentList[i]._id;
      if (
        !morningList.includes(element) &&
        !afternoonList.includes(element) &&
        !presentList.includes(element)
      ) {
        absentList.push(element);
      }
    }
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      morning_present: morningList,
      afternoon_present: afternoonList,
      present: presentList,
      absent: absentList,
    };

    const response = await requestUpdateAttendance(data);

    if (response) {
      modalUpdateAttendance.onClose();
      modalUpdatePopup.onClose();
      setMorningList([]);
      setAfternoonList([]);
      setPresentList([]);
      handleGetAttendance();
      handleGetClassStats();
      toast({
        title: "Attendance Updated Succesfully",
        description: "Updated Details",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const openUpdatePopup = () => {
    modalUpdatePopup.onOpen();
  };
  const openDeletePopup = () => {
    modalDeletePopup.onOpen();
  };
  const handleClose = () => {
    setShowToast(false);
  };
  const handleDeleteAttendance = async () => {
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const response = await requestDeleteAttendance(data);
    if (response) {
      modalUpdateAttendance.onClose();
      modalDeletePopup.onClose();
      handleGetClassStats();
      setAttendanceData("");
      setMorningList("");
      setAfternoonList("");
      setPresentList("");

      toast({
        render: () => (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              backgroundColor: "#40a46c",
              color: "white",
            }}
            p={4}
            borderRadius="md"
            boxShadow="lg"
          >
            <Box>
              <Heading size="md" mb={2}>
                Attendance Deleted Successfully
              </Heading>
              <Text>Please wait for reflecting the date in the calendar</Text>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              color="white"
              onClick={() => {
                handleClose();
                toast.closeAll();
              }}
            />
          </Box>
        ),
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    }
  };
  useEffect(() => {
    if ((inputFields.class && inputFields.section) || selectedDate) {
      handleGetClassStats();
      setClassStatus("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  const chhoseDate = moment(selectedDate).format("YYYY-MM-DD");
  const addedAttendance = attendanceDays
    ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  useEffect(() => {
    if (
      inputFields.class &&
      inputFields.section &&
      addedAttendance.includes(chhoseDate)
    ) {
      handleGetAttendance();
      setAttendanceData("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));
    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));
    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  return (
    <div className={Styles.WholeContainer}>
      <div className={Styles.calenderWidth}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid black",
              padding: "5px",
            }}
          >
            <div>
              <button onClick={handlePreviousMonth}>
                <FaCaretSquareLeft className={Styles.arrows} />
              </button>
            </div>
            <div>{renderHeader()}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className={Styles.todayButton}
                onClick={handleTodayButtonClick}
                style={{ display: isToday() ? "none" : "block" }}
              >
                Today
              </button>
              <button onClick={handleNextMonth}>
                <FaCaretSquareRight className={Styles.arrows} />
              </button>
            </div>
          </div>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            dateClick={handleDateClick}
            dayCellContent={dayCellContent}
            dayHeaderContent={dayHeaderContent}
          />
        </div>
      </div>
      <div className={Styles.BoxContainer}>
        <div className={Styles.header}>
          <div className={Styles.dateContainer}>
            <p>
              Date : &nbsp;
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </p>
          </div>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper} style={{ width: "100%" }}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
                style={{ border: "1px solid black" }}
              >
                {classAndSection.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
            <div className={Styles.filterWrapper} style={{ width: "100%" }}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
                style={{ border: "1px solid black" }}
              >
                {inputFields.class &&
                  classAndSection.map((grade) =>
                    grade.class === inputFields.class
                      ? grade.sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : null
                  )}
              </Select>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {classStatus ? (
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                marginTop: "15px",
                padding: "11px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Academic Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>
                    {moment(classStatus.start_date).format("DD/MM/YYYY")}-
                    {moment(classStatus.end_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Days in the Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.total_days}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Midterm Days in the Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.mid_term_days}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Days Attendance Entered
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.marked_days}</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className={Styles.selectGrade}>Please Select Grade</div>
            </div>
          )}
        </div>
        {selectedDate && classStatus && (
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              marginTop: "15px",
              padding: "11px",
              fontWeight: "bold",
            }}
          >
            {isTodayHoliday || isSunday ? (
              <p style={{ color: "red" }}>
                The Selected Date is a Holiday / Sunday
              </p>
            ) : isFutureDate ? (
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                Attendance not present for the upcoming date from today.
              </p>
            ) : (
              <>
                <p>
                  <strong>Total Number of Students :</strong>
                  <span style={{ fontWeight: "bold" }}>
                    &nbsp;
                    {classStatus?.total_count
                      ? classStatus?.total_count
                      : "N/A"}
                  </span>
                </p>
                <div className={Styles.attendanceData}>
                  <p>
                    Present Students :&nbsp;
                    <span style={{ color: "green" }}>
                      {classStatus?.presents ? classStatus?.presents : "N/A"}
                    </span>
                  </p>
                  <p>
                    Absent Students :&nbsp;
                    <span style={{ color: "red" }}>
                      {classStatus.absents ? classStatus?.absents : "N/A"}
                    </span>
                  </p>
                  <p>
                    Halfday Present Students :&nbsp;
                    <span>
                      {classStatus?.partial_present
                        ? classStatus?.partial_present
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        <div
          style={{
            display: " flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            marginTop: "15px",
            padding: "11px",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#9c9695",
                marginRight: "10px",
              }}
            ></div>
            <p>Holiday / Sunday</p>
          </div>
          <div
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "rgb(51 190 51)",
                marginRight: "10px",
              }}
            ></div>
            <p>Attendance Entered</p>
          </div>
        </div>
      </div>
      {selectedDate && inputFields.class && inputFields.section && (
        <Tooltip
          label={
            addedAttendance.includes(chhoseDate)
              ? "View Attendance"
              : "Add Attendance"
          }
          aria-label="A tooltip"
        >
          <div
            className={Styles.academicYear}
            onClick={
              addedAttendance.includes(chhoseDate)
                ? modalUpdateAttendance.onOpen
                : modalAddAttendance.onOpen
            }
          >
            {addedAttendance.includes(chhoseDate) ? (
              <IoMdCheckmarkCircleOutline className={Styles.add} />
            ) : (
              <MdOutlineAddCircleOutline className={Styles.add} />
            )}
          </div>
        </Tooltip>
      )}
      {isTodayHoliday || isSunday ? (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={modalAddAttendance.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Date:
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                The Selected Date is a Holiday / Sunday
              </p>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : isFutureDate ? (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={modalAddAttendance.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Date:
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                Not able to add the Attendance for the upcoming date from today.
              </p>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={() => {
            modalAddAttendance.onClose();
          }}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent
            position="fixed"
            top="10"
            transform="translateX(-50%)"
            maxWidth="70vw"
            width="70vw"
            maxHeight="150vw"
            overflowY="scroll"
          >
            <ModalHeader>Add Attendance</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <div className={Styles.updateHeadingContainer}>
                  <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Date:
                    {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
                    &nbsp;|&nbsp; Total Students:{studentList.length}
                  </p>
                  <div className={Styles.addAttendanceContainer}>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                      >
                        Check all
                      </p>
                      <Checkbox
                        isChecked={studentList.length === presentList.length}
                        onChange={handleCheckAll}
                        colorScheme="red"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ position: "relative", marginLeft: "20px" }}>
                        <Input
                          name="studentName"
                          variant="filled"
                          placeholder="Search"
                          value={inputFields.studentName}
                          onChange={handleInputChange}
                          onKeyDown={handleEnterKeyPress}
                          autoComplete="off"
                          paddingRight="3rem"
                          style={{ width: "100%" }}
                        />
                        {showSearchIcon ? (
                          <BiSearchAlt2
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "30%",
                              cursor: "pointer",
                            }}
                            fontSize={20}
                            onClick={handleSearchClick}
                          />
                        ) : (
                          <AiOutlineCloseCircle
                            onClick={handleCloseIconClick}
                            fontSize={20}
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "30%",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={Styles.body}
                  style={{ maxHeight: "42vh", overflowY: "auto" }}
                >
                  <div className={Styles.lists}>
                    {studentList.length === 0 ? (
                      <div className={Styles.noResult}>No Result Found</div>
                    ) : (
                      studentList.map((student, idx) => (
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student.url != "N/A" ? student.url : defaultUser
                              }
                              alt="student-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>

                            <div style={{ display: "flex" }}>
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  morningList.includes(student._id) ||
                                  presentList.includes(student._id)
                                }
                                onChange={() =>
                                  handleMorningChange(student._id)
                                }
                              >
                                <p className={Styles.checkboxText}>Morning</p>
                              </Checkbox>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  afternoonList.includes(student._id) ||
                                  presentList.includes(student._id)
                                }
                                onChange={() =>
                                  handleAfternoonChange(student._id)
                                }
                              >
                                <p className={Styles.checkboxText}>Afternoon</p>
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </>
            </ModalBody>

            <ModalFooter>
              <>
                <Button
                  variant="solid"
                  colorScheme="green"
                  mr={3}
                  onClick={() => handleAddAttendance()}
                >
                  Add
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={modalAddAttendance.onClose}
                >
                  Cancel
                </Button>
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal
        isOpen={modalUpdateAttendance.isOpen}
        onClose={modalUpdateAttendance.onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          position="fixed"
          top="10"
          transform="translateX(-50%)"
          maxWidth="70vw"
          width="70vw"
          maxHeight="150vw"
          overflowY="scroll"
        >
          <ModalHeader>Update/Delete Attendance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <div className={Styles.updateHeadingContainer}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Date:
                  {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
                  &nbsp;| &nbsp;Total Students: {studentList.length}
                  {/* {(() => {
                    if (classStatus?.total_count > studentList?.length) {
                      return ` | Deleted Students : ${
                        classStatus?.total_count - studentList.length
                      }`;
                    }
                    return "";
                  })()}
                  {(() => {
                    const newlyAddedCount = studentList.filter((student) => {
                      const studentId = student._id;
                      const isPresent =
                        attendanceData?.present?.includes(studentId) ||
                        attendanceData?.morning_present?.includes(studentId) ||
                        attendanceData?.afternoon_present?.includes(
                          studentId
                        ) ||
                        attendanceData?.absent?.includes(studentId);
                      return !isPresent;
                    }).length;
                    if (newlyAddedCount > 0) {
                      return ` | Newly Added Students: ${newlyAddedCount}`;
                    }
                    return "";
                  })()} */}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative", marginLeft: "20px" }}>
                    <Input
                      name="studentName"
                      variant="filled"
                      placeholder="Search"
                      value={inputFields.studentName}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      onChange={handleInputChange}
                      style={{ width: "100%" }}
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.body}>
                <div
                  className={Styles.lists}
                  style={{ maxHeight: "42vh", overflowY: "auto" }}
                >
                  {attendanceData && (
                    <>
                      {studentList.map((student) => {
                        const studentId = student._id;
                        const isPresent =
                          attendanceData?.present?.includes(studentId) ||
                          attendanceData?.morning_present?.includes(
                            studentId
                          ) ||
                          attendanceData?.afternoon_present?.includes(
                            studentId
                          ) ||
                          attendanceData?.absent?.includes(studentId);
                        return (
                          <div key={studentId} className={Styles.list}>
                            {/* <div
                            key={studentId}
                             className={
                               isPresent ? Styles.list : Styles.notFound
                             }
                           > */}
                            <div className={Styles.profile}>
                              <img
                                className={Styles.profileImg}
                                src={
                                  student.url != "N/A"
                                    ? student.url
                                    : defaultUser
                                }
                                alt="student-profile"
                              />
                            </div>
                            <div className={Styles.info}>
                              <p className={Styles.name}>
                                {student.name || "--"}
                              </p>
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  colorScheme="red"
                                  isChecked={
                                    morningList.includes(studentId) ||
                                    presentList.includes(studentId)
                                  }
                                  onChange={() =>
                                    handleMorningChange(studentId)
                                  }
                                >
                                  <p className={Styles.checkboxText}>Morning</p>
                                </Checkbox>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  colorScheme="red"
                                  isChecked={
                                    afternoonList.includes(studentId) ||
                                    presentList.includes(studentId)
                                  }
                                  onChange={() =>
                                    handleAfternoonChange(studentId)
                                  }
                                >
                                  <p className={Styles.checkboxText}>
                                    Afternoon
                                  </p>
                                </Checkbox>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              onClick={openUpdatePopup}
              mr={3}
            >
              Update
            </Button>
            <Button colorScheme="red" onClick={openDeletePopup} mr={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalUpdatePopup.isOpen}
        onClose={modalUpdatePopup.onClose}
      >
        <ModalOverlay />
        <ModalContent position="fixed" top="30%">
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p style={{ fontWeight: "bold" }}>
                Do you want to update the attendance for&nbsp;
                {inputFields.class} {inputFields.section} of&nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}?
              </p>
              {classStatus?.total_count !== studentList?.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginTop: "5px" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      NOTE :
                    </span>
                    &nbsp;Now the new students is also update when you press OK
                    button, Did you want to exclude the new students from
                    updating the attendance?
                  </p>
                  <div
                    style={{
                      marginTop: "12px",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    Exclude :&nbsp;
                    <Switch
                      defaultIsChecked
                      onChange={() => setExclude(!exclude)}
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="solid"
                colorScheme="green"
                mr={3}
                onClick={handleUpdateAttendance}
              >
                Ok
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={modalUpdatePopup.onClose}
              >
                Cancel
              </Button>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalDeletePopup.isOpen}
        onClose={modalDeletePopup.onClose}
      >
        <ModalOverlay />
        <ModalContent position="fixed" top="30%">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p style={{ fontWeight: "bold" }}>
                Are you sure you want to permanently delete the attendance
                for&nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="solid"
                colorScheme="green"
                mr={3}
                onClick={handleDeleteAttendance}
              >
                Ok
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={modalDeletePopup.onClose}
              >
                Cancel
              </Button>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
const Statistics = () => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [classAndSection, setClassAndSection] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const handlegetClass = async () => {
    const response = await requestGetClass();
    // console.log("response", response)
    setInputFields({ class: response[0].class });
    setClassAndSection(response);
  };
  useEffect(() => {
    handlegetClass();
  }, []);
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : "";
    setInputFields(input_data);
    setShowSearchIcon(
      !input_data ||
        !input_data.studentName ||
        input_data.studentName.trim() === ""
    );
  }, []);

  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter2", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  const searchStudentByQuery = debounce(function () {
    if (isClassQuery && isSectionQuery) {
      fetchStudents();
    }
  }, 500);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "class") {
      setInputFields({
        ...inputFields,
        class: value,
        section: "",
      });
    } else {
      setInputFields({
        ...inputFields,
        [name]: value,
      });
    }
    if (value.trim() !== "") {
      setShowSearchIcon(true);
    } else {
      setShowSearchIcon(false);
    }
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };

  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;
  const totalStudentsCount = studentList.length;
  const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>Total Students : {studentList.length}</p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative", marginLeft: "20px" }}>
                  <Input
                    name="studentName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields.studentName}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                  {showSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
              >
                {classAndSection.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
              >
                {inputFields.class &&
                  classAndSection.map((grade) =>
                    grade.class === inputFields.class
                      ? grade.sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : null
                  )}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No result found</div>
          ) : (
            studentList.map((student, idx) => (
              <Link
                className={Styles.list}
                to={student._id}
                state={{ studentId: student._id }}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={student.url != "N/A" ? student.url : defaultUser}
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>{student.name || "--"}</p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "../Midterm.module.scss";
import PrincipalSignature from "./FrVijay_Principal_Signature.png";
const Footer = ({ issueDate }) => {
  const params = useParams();
  const [term, setTerm] = useState("");
  const [reportClass, setReportClass] = useState("");
  useEffect(() => {
    setTerm(params.term);
    setReportClass(params.grade);
  }, [term, reportClass]);
  return (
    <>
      <div className={Styles.footer}>
        <div>
          <p style={{ padding: "5px" }}>{issueDate ? issueDate : "-"}</p>
          <p>ISSUE DATE</p>
        </div>
        <div>PARENT</div>
        <div>
          <img src={PrincipalSignature} alt="sign" />
          <p>PRINCIPAL</p>
        </div>
      </div>
      {term == "anual" && (reportClass == "9" || reportClass == "11") ? (
        <div className={Styles.gradesInfo}>
          <div className={Styles.gradesInfohead}>
            Scholastic Grades are awarded on an 8-point grading scale as given
            below:
          </div>
          <div className={Styles.gradesInfoBox}>
            A1(91-100), A2(81-90), B1(71-80), B2(61-70), C1(51-60), C2(41-50), D
            (31-40), E (30 & below- needs improvement)
          </div>
        </div>
      ) : null}
      {reportClass == "1" ||
      reportClass == "2" ||
      reportClass == "3" ||
      reportClass == "4" ||
      reportClass == "5" ? (
        <div className={Styles.gradesInfo}>
          <div className={Styles.gradesInfohead}>
            Scholastic Grades are awarded on an 8-point grading scale as given
            below:
          </div>
          <div className={Styles.gradesInfoBox}>
            A1(45.5-50), A2(40.5-45), B1(35.5-40), B2(30.5-35), C1(25.5-30),
            C2(20.5-25), D (15.5-40), E (30 & below- needs improvement)
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Footer;

import Styles from "./WorkInProgress.module.scss";

const WorkInProgress = () => {
    return(
        <div className={Styles.container}>
            <h1 className={Styles.heading}>Work in progress</h1>
        </div>
    )
}

export default WorkInProgress;
import { useClassAndSections } from "hooks/classAndSection";
import React, { useEffect, useState } from "react";
import Fees from "assets/icons/Fees.png";
import Styles from "./ReportCentre.module.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Button, Checkbox, Select, useToast } from "@chakra-ui/react";
import { feeStatistics } from "services/management-dashboard/fee.service";
import { AiOutlinePlusSquare } from "react-icons/ai";
const FeesReport = () => {
  const { classAndSections } = useClassAndSections();
  const [inputFields, setInputFields] = useState({});
  const [sections, setSections] = useState([]);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({
    general: true,
    transport: false,
    canteen: false,
    tution: false,
  });
  const initialFieldsState = {
    studentName: true,
    class: true,
    section: true,
    feeStatus: true,
    transportTotal: true,
    transportPaid: true,
    transportPending: true,
    canteenTotal: true,
    canteenPaid: true,
    canteenPending: true,
    tutionTotal: true,
    tutionPaid: true,
    tutionPending: true,
    penaltyTotal: true,
    penaltyPaid: true,
    penaltyPending: true,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [disable, setDisable] = useState(true);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const toast = useToast();
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    if (!inputFields.class || !inputFields.section) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    const payload = {
      class: inputFields.class,
      section: inputFields.section,
      include_penalty_data: disable ? false : true,
    };
    const res = await feeStatistics(payload);
    if (res) {
      let newArray = [];
      res?.data?.map((item) => {
        let obj = {};
        if (fields.studentName) {
          obj["Name"] = handleNAValue(item.student_name);
        }
        if (fields.class) {
          obj["Class"] = handleNAValue(item.class);
        }
        if (fields.section) {
          obj["Section"] = handleNAValue(item.section);
        }
        if (fields.fees) {
          obj["Total Fees"] = handleNAValue(
            +item.fees_metadata.canteen_total +
              +item.fees_metadata.tution_total +
              +item.fees_metadata.transport_total
          );
        }
        if (fields.feeStatus) {
          obj["Fee Status"] = handleNAValue(
            item.tution_fees_summary.pending == 0 &&
              item.canteen_fees_summary.pending == 0 &&
              item.transport_fees_summary.pending == 0
              ? "Paid"
              : "Unpaid"
          );
        }
        if (fields.transportTotal) {
          obj["Transport Total"] = handleNAValue(
            item.transport_fees_summary.total
          );
        }
        if (fields.transportPaid) {
          obj["Transport Paid"] = handleNAValue(
            item.transport_fees_summary.paid
          );
        }
        if (fields.transportPending) {
          obj["Transport Pending"] = handleNAValue(
            item.transport_fees_summary.pending
          );
        }
        if (fields.canteenTotal) {
          obj["Canteen Total"] = handleNAValue(item.canteen_fees_summary.total);
        }
        if (fields.canteenPaid) {
          obj["Canteen Paid"] = handleNAValue(item.canteen_fees_summary.paid);
        }
        if (fields.canteenPending) {
          obj["Canteen Pending"] = handleNAValue(
            item.canteen_fees_summary.pending
          );
        }
        if (fields.tutionTotal) {
          obj["Tution Total"] = handleNAValue(item.tution_fees_summary.total);
        }
        if (fields.tutionPaid) {
          obj["Tution Paid"] = handleNAValue(item.tution_fees_summary.paid);
        }
        if (fields.tutionPending) {
          obj["Tution Pending"] = handleNAValue(
            item.tution_fees_summary.pending
          );
        }
        if (!disable) {
          if (fields.penaltyTotal) {
            obj["Penalty Total"] = handleNAValue(
              item.penalty_fees_summary.total
            );
          }
          if (fields.penaltyPaid) {
            obj["Penalty Paid"] = handleNAValue(item.penalty_fees_summary.paid);
          }
          if (fields.penaltyPending) {
            obj["Penalty Pending"] = handleNAValue(
              item.penalty_fees_summary.pending
            );
          }
        }
        newArray.push({ ...obj });
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      general: true,
    }));
    setSelectedFileFormat("xlsx");
  };
  const fileName = `SMU-Fees-Statistics-Report-${inputFields.class}-${inputFields.section}`;
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleinputDisabled = () => {
    if (disable == false) {
      setDisable(true);
    } else if (disable == true) {
      setDisable(false);
    }
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img src={Fees} alt="Fees Icon" className={Styles.moduleIcon} />
              <p className={Styles.moduleName}>FEES MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    General Details
                  </button>
                  {showDetails.general ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("general")}
                    />
                  )}
                </div>
              </div>
              {showDetails.general && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.class}
                    onChange={() => {
                      setFields({
                        ...fields,
                        class: !fields.class,
                      });
                    }}
                  >
                    Class
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.section}
                    onChange={() => {
                      setFields({
                        ...fields,
                        section: !fields.section,
                      });
                    }}
                  >
                    Section
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.feeStatus}
                    onChange={() => {
                      setFields({
                        ...fields,
                        feeStatus: !fields.feeStatus,
                      });
                    }}
                  >
                    Fee Status
                  </Checkbox>
                  <Checkbox isChecked={!disable} onChange={handleinputDisabled}>
                    Penalty Status
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Transport Details
                  </button>
                  {showDetails.transport ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("transport")}
                    />
                  )}
                </div>
              </div>
              {showDetails.transport && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.transportTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportTotal: !fields.transportTotal,
                      });
                    }}
                  >
                    Transport Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.transportPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportPaid: !fields.transportPaid,
                      });
                    }}
                  >
                    Transport Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.transportPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportPending: !fields.transportPending,
                      });
                    }}
                  >
                    Transport Pending
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Canteen Details
                  </button>
                  {showDetails.canteen ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("canteen")}
                    />
                  )}
                </div>
              </div>
              {showDetails.canteen && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.canteenTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenTotal: !fields.canteenTotal,
                      });
                    }}
                  >
                    Canteen Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.canteenPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenPaid: !fields.canteenPaid,
                      });
                    }}
                  >
                    Canteen Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.canteenPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenPending: !fields.canteenPending,
                      });
                    }}
                  >
                    Canteen Pending
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Tution Details
                  </button>
                  {showDetails.tution ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("tution")}
                    />
                  )}
                </div>
              </div>
              {showDetails.tution && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.tutionTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionTotal: !fields.tutionTotal,
                      });
                    }}
                  >
                    Tution Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.tutionPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionPaid: !fields.tutionPaid,
                      });
                    }}
                  >
                    Tution Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.tutionPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionPending: !fields.tutionPending,
                      });
                    }}
                  >
                    Tution Pending
                  </Checkbox>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Fees}
              alt="Fees Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>FEES MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    General Details
                  </button>
                  {showDetails.general ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("general")}
                    />
                  )}
                </div>
              </div>
              {showDetails.general && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.class}
                    onChange={() => {
                      setFields({
                        ...fields,
                        class: !fields.class,
                      });
                    }}
                  >
                    Class
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.section}
                    onChange={() => {
                      setFields({
                        ...fields,
                        section: !fields.section,
                      });
                    }}
                  >
                    Section
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.feeStatus}
                    onChange={() => {
                      setFields({
                        ...fields,
                        feeStatus: !fields.feeStatus,
                      });
                    }}
                  >
                    Fee Status
                  </Checkbox>
                  <Checkbox isChecked={!disable} onChange={handleinputDisabled}>
                    Penalty Status
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Tution Details
                  </button>
                  {showDetails.tution ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("tution")}
                    />
                  )}
                </div>
              </div>
              {showDetails.tution && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.tutionTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionTotal: !fields.tutionTotal,
                      });
                    }}
                  >
                    Tution Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.tutionPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionPaid: !fields.tutionPaid,
                      });
                    }}
                  >
                    Tution Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.tutionPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        tutionPending: !fields.tutionPending,
                      });
                    }}
                  >
                    Tution Pending
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Transport Details
                  </button>
                  {showDetails.transport ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("transport")}
                    />
                  )}
                </div>
              </div>
              {showDetails.transport && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.transportTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportTotal: !fields.transportTotal,
                      });
                    }}
                  >
                    Transport Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.transportPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportPaid: !fields.transportPaid,
                      });
                    }}
                  >
                    Transport Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.transportPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        transportPending: !fields.transportPending,
                      });
                    }}
                  >
                    Transport Pending
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Canteen Details
                  </button>
                  {showDetails.canteen ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("canteen")}
                    />
                  )}
                </div>
              </div>
              {showDetails.canteen && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.canteenTotal}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenTotal: !fields.canteenTotal,
                      });
                    }}
                  >
                    Canteen Total
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.canteenPaid}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenPaid: !fields.canteenPaid,
                      });
                    }}
                  >
                    Canteen Paid
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.canteenPending}
                    onChange={() => {
                      setFields({
                        ...fields,
                        canteenPending: !fields.canteenPending,
                      });
                    }}
                  >
                    Canteen Pending
                  </Checkbox>
                </div>
              )}
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeesReport;

import axios from 'axios'
import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

export async function getStudentsCount (payload={}){
    const url = BASE_URL + "/management/statistics/student-count"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }

  export async function getSaffCount (payload={}){
    const url = BASE_URL + "/management/statistics/teacher-count"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }
  export async function getAddmissionCount (payload={}){
    const url = BASE_URL + "/management/statistics/admission-count"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }
  export async function getNoticeBoardDetails (payload={}){
    const url = BASE_URL + "/management/statistics/notice-board"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }

  export async function getAdminUserInfo (payload={}){
    const url = BASE_URL + "/management/statistics/admin-count"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }
  
  export async function managementUserInfo (payload={}){
    const url = BASE_URL + "/management/statistics/mgmt-count"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }

  export async function getLoggedUserInfo (payload={}){
    const url = BASE_URL + "/management/statistics/logged-users"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }

  export async function getFeeStatisticsDetails (payload={}){
    const url = BASE_URL + "/management/statistics/fees-stat"
    try{
        const response = await axios.post(url,{...payload},getRequestOptions())
        if(response.data?.error) {
            return {error: response.data.error}
        }
        if (response.status >= 200 && response.status < 400 && response.data) {
            return response.data
          }
    }
    catch (e){
        if (e?.response?.data?.error) {
            throw e.response.data.error
          }
          if (e?.response?.data) {
            throw e.response.data
          }
          throw e
    }
  }

  const Services ={
    getStudentsCount,
    getSaffCount,
    getAddmissionCount,
    getNoticeBoardDetails,
    getAdminUserInfo,
    managementUserInfo,
    getLoggedUserInfo,
    getFeeStatisticsDetails
  }
  export default Services
import { useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "services/admin-dashboard/events.service";
import "react-datepicker/dist/react-datepicker.css";
import Styles from "./AddEvent.module.scss";
import { useClassAndSections2 } from "hooks/classAndSection";
import { requestUploadEvents } from "services/common.service";
import { MdPreview } from "react-icons/md";
import { AiOutlineFilePdf } from "react-icons/ai";
const AddEvent = () => {
  const { classAndSections } = useClassAndSections2();
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAppend, setIsAppend] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    description: "",
    date: "",
    media_url: [],
    category: "",
    target_group: [],
  });

  const [file, setFile] = useState([]);
  const toast = useToast();
  const modalPreview = useDisclosure();
  const uploadFilesToCloud = async () => {
    try {
      const response = await requestUploadEvents({
        title: inputFields.title,
        file: file,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          file_url: Object.values(response),
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleInputChange = useCallback(
    (e) => {
      if (typeof e.currentTarget !== "undefined") {
        const {
          currentTarget: { name, value, files },
        } = e;
        const values = { [name]: value };
        if (files !== null && typeof files !== "undefined") {
          console.log(e);
          values[name] = files;
        }
        setInputFields({ ...inputFields, [name]: values[name] });
      } else {
        setInputFields({ ...inputFields, date: e });
      }
    },
    [inputFields, setInputFields]
  );

  const handleCheck = (event) => {
    let class_array = [...inputFields.target_group];
    if (event.target.checked) {
      if (event.target.value === "All") class_array = [event.target.value];
      else {
        if (class_array.includes("All")) class_array = ["All"];
        else class_array = [...inputFields.target_group, event.target.value];
      }
    } else {
      class_array.splice(class_array.indexOf(event.target.value), 1);
    }

    setInputFields({ ...inputFields, target_group: class_array });
  };

  const handleFileEvent = (e, fieldName) => {
    if (fieldName === "isAppend") {
      setIsAppend(true);
    }
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setFile(chosenFiles);
    const chosenFile = Array.from(e.target.files);
    setSelectedImages(chosenFiles);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let fileResponse;
    let medias = [];
    if (file.length > 0) {
      fileResponse = await uploadFilesToCloud();
      fileResponse?.file_url?.forEach((element) => {
        medias.push({
          media_url: element,
        });
      });
    }
    try {
      setRequestInProgress(true);
      const response = await AdminDashboard.requestAddEvent({
        ...inputFields,
        dates: inputFields.date,
        media_url: medias,
      });
      if (response) {
        if (response.error) {
          toast({
            title: response.error,
            description: "Recheck your inputs",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "bottom-right",
          });
        } else {
          toast({
            title: "Added Succesfully",
            description: "Added Event",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          navigate("/admin/dashboard/events");
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      toast({
        title: e,
        description: "Recheck your inputs",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error(e);
    }
  };

  const onDrop = (picture) => {
    setInputFields((prev) => ({
      ...prev,
      media_url: [...prev.media_url, picture],
    }));
  };

  const highlightSundays = (date) => {
    return date.getDay() === 0;
  };

  const currentMonth = new Date().getMonth();

  const getMonthDates = (month) => {
    const dates = [];
    const currentYear = new Date().getFullYear();
    const daysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
    }
    return dates;
  };

  const highlightDates = getMonthDates(currentMonth);

  return (
    <div className={Styles.body}>
      <form onSubmit={handleFormSubmit}>
        <div className={Styles.row}>
          <div className={Styles.form}>
            <p className={Styles.placeholder}>
              Title <span className={Styles.asterisk}>*</span>
            </p>
            <input
              onChange={handleInputChange}
              required
              type="text"
              id="title"
              name="title"
              placeholder="Enter Title..."
              className={Styles.textInput}
              value={inputFields.title}
            />
          </div>

          <div className={Styles.form}>
            <p className={Styles.placeholder}>
              Description <span className={Styles.asterisk}>*</span>
            </p>
            <Textarea
              id="description"
              name="description"
              className={Styles.textInput}
              value={inputFields.description}
              onChange={handleInputChange}
              placeholder="Enter Description..."
              size="lg"
              resize="vertical"
            />
          </div>
          <div className={Styles.formCheck}>
            <p className={Styles.placeholder}>Target Classes</p>
            <div className={Styles.checkboxes}>
              {classAndSections.map((grade, idx) => (
                <Checkbox
                  id="target_group"
                  disabled={
                    inputFields?.target_group?.includes("All") &&
                    grade.class !== "All"
                      ? true
                      : false
                  }
                  name="target_group"
                  onChange={handleCheck}
                  spacing={5}
                  colorScheme="red"
                  key={grade.class + idx}
                  value={grade.class}
                >
                  <p className={Styles.checkboxText}>{grade.class}</p>
                </Checkbox>
              ))}
            </div>
          </div>

          <div className={Styles.form}>
            <p className={Styles.placeholder}>Add Attachment</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <label>
                <div
                  style={{
                    backgroundColor: "#f9cbca",
                    border: "1px solid orchid",
                    borderRadius: "5px",
                    padding: " 5px",
                    marginTop: "8px",
                    cursor: "pointer",
                  }}
                >
                  Choose Attachment
                </div>
                <input
                  id="media_url"
                  name="media_url"
                  accept="image/*"
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => {
                    handleFileEvent(e, "isAppend");
                  }}
                />
              </label>
              {isAppend ? (
                <Tooltip
                  label="Preview Selected Images/PDF."
                  placement="bottom"
                >
                  <span className={Styles.pencilIcon}>
                    <MdPreview
                      onClick={modalPreview.onOpen}
                      style={{
                        fontSize: "35px",
                        marginTop: "4px",
                      }}
                    />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={Styles.formDate}>
            <p className={Styles.placeholder}>
              Date <span className={Styles.asterisk}>*</span>
            </p>
            <DatePicker
              required
              value={inputFields?.date}
              selected={inputFields.date}
              onChange={handleInputChange}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              dayClassName={(date) =>
                highlightSundays(date) ? "react-datepicker__day--sunday" : null
              }
              highlightDates={highlightDates}
            />
          </div>

          <div className={Styles.buttonRow}>
            <button type="submit" className={Styles.button}>
              {requestInProgress ? <Spinner /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
      <Modal isOpen={modalPreview.isOpen} onClose={modalPreview.onClose}>
        <ModalOverlay />
        <ModalContent style={{ position: "sticky", top: "50px" }}>
          <ModalHeader>Selected images/pdf</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            {selectedImages.map((file, index) => (
              <div key={index}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Selected Image ${index}`}
                    style={{
                      border: "2px solid black",
                      width: "400px",
                      height: "200px",
                      marginBottom: "20px",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                      marginBottom: "20px",
                      border: "2px solid black",
                    }}
                  >
                    <AiOutlineFilePdf
                      style={{
                        marginRight: "5px",
                        color: "red",
                        fontSize: "20px",
                      }}
                    />
                    {file.name}
                  </div>
                )}
              </div>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalPreview.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddEvent;

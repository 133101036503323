import { GetObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "./s3Client";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

//Get Images Uploaded into DO Space (Digital Ocean) with Proper Authentication By Signing the URL

export const getSignedUrlLink = async (inputURL) => {
  try {
    if (inputURL) {
      const bucketParams = {
        Bucket: "smu",
        Key: inputURL.split(".com/")[1],
        ResponseContentDisposition: "inline",
        ResponseContentType: "image/png",
      };
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 15 * 60 }
      ); // Adjustable expiration.
      return url;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};

export const getSignedPDFUrlLink = async (inputURL) => {
  try {
    if (inputURL) {
      const bucketParams = {
        Bucket: "smu",
        Key: inputURL.split(".com/")[1],
        ResponseContentDisposition: "inline",
        ResponseContentType: "application/pdf",
      };
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 15 * 60 }
      ); // Adjustable expiration.
      return url;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};

export const getSignedPDFUrlLinkXlsx = async (inputURL) => {
  try {
    if (inputURL) {
      const bucketParams = {
        Bucket: "smu",
        Key: inputURL.split(".com/")[1],
        ResponseContentDisposition: "inline",
        ResponseContentType: "application/xls",
      };
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 15 * 60 }
      ); // Adjustable expiration.
      return url;
    }
  } catch (err) {
    // console.log("Error", err);
  }
};

getSignedUrlLink();
getSignedPDFUrlLink();
getSignedPDFUrlLinkXlsx();

import React, { useState, useEffect } from "react";
import {
  Select,
  Input,
  Tooltip,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Styles from "./Admission.module.scss";
import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import { useToast, Spinner } from "@chakra-ui/react";
import { debounce } from "utilities/utils";
import defaultUser from "../../../assets/icons/User.png";
import { AiOutlineCloseCircle, AiOutlinePlusSquare } from "react-icons/ai";
import {
  requestSearchStudentByFilter,
  requestAdmissionStudentDetails,
  requestMigrateStudent,
} from "services/admin-dashboard/admission.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import moment from "moment";
import { FaPaperPlane } from "react-icons/fa";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const Admission = () => {
  const { classAndSections, loading } = useClassAndSectionsWithoutAll();
  const downloadModal = useDisclosure();
  const migrateModal = useDisclosure();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [enableStu, setEnableStu] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [data, setData] = useState([]);
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [selectStudent,setSelectStudent] = useState([])
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const initialFieldsState = {
    admissionNumber: true,
    admissionYear: true,
    name: true,
    class: true,
    section: true,
    email: true,
    gender: true,
    fatherName: true,
    fatherPhone: true,
    fatherQualification: false,
    fatherOccupation: false,
    motherName: true,
    motherPhone: true,
    motherQualification: false,
    motherOccupation: false,
    dob: false,
    nationality: false,
    motherTongue: false,
    religion: false,
    subReligion: false,
    bloodGroup: true,
    address: false,
    aadhaarNo: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [showDetails, setShowDetails] = useState({
    personal: true,
    father: false,
    mother: false,
    other: false,
  });
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);

    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);

  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);

  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);

  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        studentName: "",
      }));
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };

  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };

      const response = await requestSearchStudentByFilter(payload);
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        setSearchResultList(response);
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };

      const response = await requestSearchStudentByFilter(payload);
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        setSearchResultList(response);
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = searchResultList;
  const totalStudentsCount = studentList.length;
  const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);

  const onEnable = () => {
    setEnableStu(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleCheckboxChange = (studentId,{student:student}) => {
    // const data={
    //   email:student.email,
    //   number:student.phoneNumber,
    //   id : student._id
    // }
    // const updatedCheckedStudents = checkedStudents.includes(studentId)
    //   ? checkedStudents.filter((id) => id !== studentId)
    //   : [...checkedStudents, studentId];
    //  setCheckedStudents(updatedCheckedStudents);
     let people = [...checkedStudents];
    let selectedPersons = [...selectStudent];
    if (people.includes(studentId)
       && selectedPersons.includes(student)
      ) {
      let index = people.indexOf(studentId);
      let indexPerson = selectedPersons.indexOf(student);
      people.splice(index, 1);
      console.log("indexPerson", indexPerson)
      selectedPersons.splice(indexPerson, 1);
      setCheckedStudents(people);
      setSelectStudent(selectedPersons);
    } else {
      setCheckedStudents([...people, studentId]);
      setSelectStudent([...selectStudent, student]);
    }
    
    // const seletedData = selectStudent.includes(data)?selectStudent.filter((id)=>id.id!==data):
    // [...selectStudent,data]
    //  setSelectStudent(seletedData)
    // console.log("selectStudent",seletedData)
  };

  console.log("selectedStudent",checkedStudents,selectStudent)
  const migrateStudent = async () => {
    try {
      const payload = {
        class: inputFields.class,
        section: inputFields.section,
        migrations: checkedStudents,
      };
      const response = await requestMigrateStudent(payload);
      if (response) {
        setCheckedStudents("")
        const auth = getAuth()
        selectStudent&&selectStudent.map((data)=>{
          const email = data.email
          const password = data.phoneNumber
          try{
            const userCredential =  createUserWithEmailAndPassword(auth, email, password);
          }
          catch(e){
            console.log("err", e)
          }
        })

        fetchStudents();
        migrateModal.onClose();
        toast({
          title: "Migration Completed",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Migration Failed",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast({
        title: "Migration Failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-Admission-${inputFields.class}-${inputFields.section}`
      : "SMU-Admissions";

  const ExportToExcel = () => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        <Tooltip label="Download Student Details">
          <Link to="">
            <Button colorScheme="green">Archive</Button>
          </Link>
        </Tooltip>
      </div>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    const payload = {
      ...(inputFields.studentName !== ""
        ? { name: inputFields.studentName }
        : {}),
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestAdmissionStudentDetails(payload);
    if (res) {
      let newArray = [];
      res.map((item) => {
        let obj = {};
        if (fields.admissionNumber) {
          obj["Admission ID"] = handleNAValue(item.academic.admission_number);
        }
        if (fields.admissionYear) {
          obj["Admission Year"] = handleNAValue(item.academic.admission_year);
        }
        if (fields.class) {
          obj["Class"] = handleNAValue(item.academic.class);
        }
        if (fields.section) {
          obj["Section"] = handleNAValue(item.academic.section);
        }
        if (fields.name) {
          obj["Name"] = handleNAValue(item.name);
        }
        if (fields.email) {
          obj["Email"] = handleNAValue(item.email);
        }
        if (fields.gender) {
          obj["Gender"] = handleNAValue(item.personel.gender);
        }
        if (fields.aadhaarNo) {
          obj["Aadhaar No."] = handleNAValue(item.personel.aadhar_number);
        }
        if (fields.address) {
          obj["Address"] = handleNAValue(item.personel.present_address);
        }
        if (fields.bloodGroup) {
          obj["Blood Group"] = handleNAValue(item.personel.blood_group);
        }
        if (fields.dob) {
          obj["DOB"] = handleNAValue(
            moment(item.personel.dob, "MM/DD/YYYY").format("DD/MM/YYYY")
          );
        }
        if (fields.nationality) {
          obj["Nationality"] = handleNAValue(item.personel.nationality);
        }
        if (fields.motherTongue) {
          obj["Mother Tongue"] = handleNAValue(item.personel.mother_tongue);
        }
        if (fields.religion) {
          obj["Religion"] = handleNAValue(item.personel.religion);
        }
        if (fields.subReligion) {
          obj["Sub-Religion"] = handleNAValue(item.personel.sub_religion);
        }
        if (fields.fatherName) {
          obj["Father Name"] = handleNAValue(item.father.father_name);
        }
        if (fields.fatherPhone) {
          obj["Father Phone No."] = handleNAValue(item.father.father_phone);
        }
        if (fields.fatherQualification) {
          obj["Father Qualification"] = handleNAValue(
            item.father.father_qualification
          );
        }
        if (fields.fatherOccupation) {
          obj["Father Occupation"] = handleNAValue(
            item.father.father_occupation
          );
        }
        if (fields.motherName) {
          obj["Mother Name"] = handleNAValue(item.mother.mother_name);
        }
        if (fields.motherPhone) {
          obj["Mother Phone No."] = handleNAValue(item.mother.mother_phone);
        }
        if (fields.motherQualification) {
          obj["Mother Qualification"] = handleNAValue(
            item.mother.mother_qualification
          );
        }
        if (fields.motherOccupation) {
          obj["Mother Occupation"] = handleNAValue(
            item.mother.mother_occupation
          );
        }
        newArray.push({ ...obj });
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      personal: true,
      father: false,
      mother: false,
      other: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>ADMISSION |</p>
          <p className={Styles.stats}>
            Total Admission : {totalStudentsCount || 0}
          </p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={inputFields.studentName}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                  paddingRight="3rem"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.class ? inputFields.class : ""}
              onChange={handleInputChange}
              name="class"
              placeholder="Select Grade"
            >
              {classAndSections.map((grade, idx) => (
                <option key={grade.class + idx} value={grade.class}>
                  Grade {grade.class}
                </option>
              ))}
            </Select>
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.section ? inputFields.section : ""}
              onChange={handleInputChange}
              name="section"
              placeholder="Select Section"
            >
              {sections
                ? sections.map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section}
                    </option>
                  ))
                : ""}
            </Select>
          </div>
          <div className={Styles.optionWrapper}>
          {studentList.length >= 1 ? (
            <Tooltip placement="left" label="Migrate Data to Students Module">
              <div className={Styles.migrateButton}>
                {enableStu ? (
                  <button
                    style={{
                      backgroundColor: "rgb(233 25 14)",
                      color: "white",
                      padding: "5px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                    // onClick={migrateStudent}
                    onClick={migrateModal.onOpen}
                  >
                    <FaPaperPlane style={{ fontSize: "25px" }} />
                    <p style={{ fontSize: "10px" }}> Migrate Student </p>
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "rgb(110 187 22)",
                      color: "white",
                      padding: "6px",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // fontSize:"7px"
                    }}
                    onClick={onEnable}
                  >
                    <FaPaperPlane style={{ fontSize: "25px" }} />
                    <p style={{ fontSize: "10px" }}>Enable Migrate</p>
                  </button>
                )}
              </div>
            </Tooltip>
          ) : (
            ""
          )}
          <div className={Styles.addButton}>
            <Tooltip label="Add Student">
              <Link to="add">
                <Button colorScheme="teal">Create</Button>
              </Link>
            </Tooltip>
          </div>
          {studentList.length >= 1 ? (
            <ExportToExcel apiData={data} fileName={fileName} />
          ) : (
            ""
          )}
        </div>
        </div>
        
      </div>

      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            searchResultList &&
            searchResultList.map((student, idx) => (
              <div className={Styles.list}>
                <Link to={student._id} key={student["_id"] + idx}>
                  <div style={{ display: "flex" }}>
                    <div className={Styles.profile}>
                      <img
                        className={Styles.profileImg}
                        src={student.url != "N/A" ? student.url : defaultUser}
                        alt="student-profile"
                      />
                    </div>
                    <div className={Styles.info}>
                      <p className={Styles.name}>{student.name || "--"}</p>
                      <p className={Styles.class}>
                        {(student?.academic?.class &&
                          student?.academic?.class) ||
                          "--"}{" "}
                        | {student.academic?.section?.toUpperCase() || "--"}
                      </p>
                      <p className={Styles.number}>
                        +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                      </p>
                    </div>
                  </div>
                </Link>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    disabled={enableStu ? false : true}
                    isChecked={checkedStudents.includes(student._id)}
                    onChange={() => handleCheckboxChange(student._id,{student:student})}
                    colorScheme="red"
                    type="checkbox"
                    style={{
                      border: "1px solid black",
                      color: "red",
                      width: "17px",
                      marginRight: "5px",
                    }}
                  />
                  <p className={Styles.checkboxText}>Migrate</p>
                </div>
              </div>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <Modal
        isOpen={downloadModal.isOpen}
        onClose={() => {
          downloadModal.onClose();
          setFields(initialFieldsState);
          setShowDetails((prevDetails) => ({
            personal: true,
            father: false,
            mother: false,
            other: false,
          }));
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Student Details
                  </button>
                  {showDetails.personal ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("personal")}
                    />
                  )}
                </div>
                {showDetails.personal && (
                  <>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.email}
                        onChange={() => {
                          setFields({ ...fields, email: !fields.email });
                        }}
                      >
                        Email
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.aadhaarNo}
                        onChange={() => {
                          setFields({
                            ...fields,
                            aadhaarNo: !fields.aadhaarNo,
                          });
                        }}
                      >
                        Aadhaar No.
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.address}
                        onChange={() => {
                          setFields({
                            ...fields,
                            address: !fields.address,
                          });
                        }}
                      >
                        Address
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.dob}
                        onChange={() => {
                          setFields({
                            ...fields,
                            dob: !fields.dob,
                          });
                        }}
                      >
                        DOB
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.nationality}
                        onChange={() => {
                          setFields({
                            ...fields,
                            nationality: !fields.nationality,
                          });
                        }}
                      >
                        Nationality
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.motherTongue}
                        onChange={() => {
                          setFields({
                            ...fields,
                            motherTongue: !fields.motherTongue,
                          });
                        }}
                      >
                        Mother Tongue
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.religion}
                        onChange={() => {
                          setFields({
                            ...fields,
                            religion: !fields.religion,
                          });
                        }}
                      >
                        Religion
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.subReligion}
                        onChange={() => {
                          setFields({
                            ...fields,
                            subReligion: !fields.subReligion,
                          });
                        }}
                      >
                        Sub-Religion
                      </Checkbox>
                    </div>
                  </>
                )}
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Father Details
                  </button>
                  {showDetails.father ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("father")}
                    />
                  )}
                </div>
                {showDetails.father && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.fatherQualification}
                      onChange={() => {
                        setFields({
                          ...fields,
                          fatherQualification: !fields.fatherQualification,
                        });
                      }}
                    >
                      Qualification
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.fatherOccupation}
                      onChange={() => {
                        setFields({
                          ...fields,
                          fatherOccupation: !fields.fatherOccupation,
                        });
                      }}
                    >
                      Occupation
                    </Checkbox>
                  </div>
                )}

                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Mother Details
                  </button>
                  {showDetails.mother ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("mother")}
                    />
                  )}
                </div>
                {showDetails.mother && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.motherQualification}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherQualification: !fields.motherQualification,
                        });
                      }}
                    >
                      Qualification
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.motherOccupation}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherOccupation: !fields.motherOccupation,
                        });
                      }}
                    >
                      Occupation
                    </Checkbox>
                  </div>
                )}
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={migrateModal.isOpen}
        onClose={() => {
          migrateModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Migrate Data to Students Module</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {checkedStudents?.length > 0 ? (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Are you sure to proceed with migration of{" "}
                {checkedStudents?.length} students from{" "}
                {searchResultList.length} students of grade {inputFields.class}{" "}
                {inputFields.section}?
              </p>
            ) : (
              <p style={{ color: "red", fontWeight: "bold" }}>
                Please check the students
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            {checkedStudents?.length > 0 ? (
              <>
                <Button mr={2} onClick={migrateStudent} colorScheme="green">
                  OK
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => migrateModal.onClose()}
                >
                  Cancel
                </Button>
              </>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Admission;

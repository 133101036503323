import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import Styles from "../Certificate/DownloadManual.module.scss"
import { Button } from '@chakra-ui/react';
import moment from "moment";
import Header from './BonafideHeader/Header';
import jsPDF from 'jspdf';
const ManagementAutoConductDownload = () => {
    const  [values, setValues] = useState("")
    const [inputFields, setInputFields] = useState(null);
  const [data, setdata] = useState(null);

    const location = useLocation()
    useEffect(()=>{
        setValues(location.state)
    },[])
    console.log("console from AutoconductDownload ",location.state)
    const fetchStudentProfileDetails = async () => {
        try {
          const response = await requestStudentDetailsById({
            studentId:location.state.id
          });
          setdata(response);
          if (response.error) {
            console.error(response.error);
          }
          console.log(data);
          // const classroom_response = await requestAllSubjects({ class_id: response._class_id });
          // console.log('calss', classroom_response)
          // if (response && classroom_response) {
          if (response) {
            console.log("student resp", response);
            const DOB = response?.personel?.dob;
            let dob = DOB && new Date(DOB).toLocaleDateString();
            if (dob == "Invalid Date") {
              if (DOB.includes("-")) {
                dob = new Date(DOB.split("-").reverse().join("/"));
              } else if (DOB.includes("/")) {
                const [day, month, year] = DOB.split("/");
                const dateString = [month, day, year].join("/");
                dob = new Date(dateString);
              }
            } else {
              if (typeof new Date(DOB) === "object") {
                dob = new Date(DOB);
              } else {
                dob = DOB;
              }
            }
    
            const _response = {
              ...response,
              name: response.name,
              email: response.email,
              personel: {
                ...response.personel,
                dob,
                gender: response.personel.gender,
                present_address: response.personel.present_address,
                permanent_address: response.personel.permanent_address,
                mother_tongue: response.personel.mother_tongue,
                religion: response.personel.religion,
                sub_religion: response.personel.sub_religion,
                blood_group: response.personel.blood_group,
                aadhar_number: response.personel.aadhar_number,
                nationlity: response.personel.nationlity,
                signed_display_image: await getSignedUrlLink(
                  response.personel.profile_url
                ),
              },
              academic: {
                ...response.academic,
                class: response.academic.class,
                section: response.academic.section,
                admission_number: response.academic.admission_number,
                admission_year: response.academic.admission_year,
              },
              father: {
                ...response.father,
                father_name: response.father.father_name,
                father_email: response.father.father_email,
                father_phone: response.father.father_phone,
                father_occupation: response.father.father_occupation,
                father_qualification: response.father.father_qualification,
                signed_father_display_image: await getSignedUrlLink(
                  response.father.father_photo_url
                ),
              },
              mother: {
                ...response.mother,
                mother_name: response.mother.mother_name,
                mother_phone: response.mother.mother_phone,
                mother_occupation: response.mother.mother_occupation,
                mother_qualification: response.mother.mother_qualification,
                signed_mother_display_image: await getSignedUrlLink(
                  response.mother.mother_photo_url
                ),
              },
              canteen_choices: response.canteen_choices,
              account_status: response.account_status,
              transport_choices: response.transport_choices,
              // electives: classroom_response.electives,
              subject_choices: response.subject_choices,
            };
            setInputFields(_response);
            return _response;
          }
        } catch (e) {
          console.error(e);
          throw e;
        }
      };
    useEffect(()=>{
        if(location.state.id){

            fetchStudentProfileDetails()
        }
    },[location.state.id])

    console.log("inputFields",inputFields)
    const date = new Date();
    const dates = moment(date).format("DD/MM/YYYY");

    const handleCertiDownload = async () => {
        let view = new jsPDF("portrait", "pt", "a4");
        let pdf = await document.getElementById("pdfcerti");
        view
          .html(pdf)
          .then(() => {
            view.save(` ${inputFields&&inputFields.name} conduct.pdf`);
            // PdfRequestUpdate();
          })
          .catch((err) => console.log(err, "err"));
       
      };

  return<>
<div className={Styles.mainContainer}>
      <div className={Styles.conductCerticateWrapper} id="pdfcerti">
        <div>
          <Header />
        </div>
        <div>
          <div className={Styles.HeadingContainer}>
            <p className={Styles.ConductcertiHeading}>CONDUCT CERTIFICATE</p>
          </div>
          <div className={Styles.conductData} >
          <p >
            I hereby certify that&nbsp;<span className={Styles.dynaicData}> {inputFields&&inputFields.name} </span>&nbsp;was a student in this school from&nbsp;
            <span className={Styles.dynaicData}>{location.state.inputFields.start_date}</span>&nbsp;to <span className={Styles.dynaicData}>{location.state.inputFields.end_date}</span> - Grade <span className={Styles.dynaicData}>{location.state.inputFields.startingGrade}</span> to
            Grade <span className={Styles.dynaicData}>{inputFields&&inputFields.academic.class}</span>.
          </p>
          </div>
          <div className={Styles.dynaicData}>
          <p style={{marginTop:"1vw"}}>
           {inputFields&&inputFields.personel.gender=="Male"?"His":"Her"} conduct and character were
            good
          </p>
          </div>
          <div style={{marginTop:"1vw"}}>
          <p> <span className={Styles.dynaicData}>Date</span>: {dates}</p>
          </div>
          <p style={{marginTop:"1vw"}} className={Styles.principalSign}><span className={Styles.dynaicData}>Principal</span></p>
        </div>
      </div>
      <Button className={Styles.downloadButoon} onClick={handleCertiDownload}>Download</Button>
      </div>
  </>
}

export default ManagementAutoConductDownload

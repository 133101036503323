import axios from "axios";
import { Select, Switch } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./ReportCard.module.scss";
import { Marks1to4, Marks5, Marks6to7 } from "./AllMarks";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const ManagementReportCard = () => {
  const [grade, setGrade] = React.useState(null);
  const [section, setSection] = React.useState(null);
  const [classAndGradeList, setClassAndGradeList] = React.useState([]);
  const [term, setTerm] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [studentlist, setList] = React.useState(null);
  const [selectedResult, setSelectedResult] = React.useState([]);
  const [examReports, setExamReports] = React.useState(null);
  const [selectedExamReports, setSelectedExamReports] = React.useState([]);
  const [issueDate, setIssueDate] = React.useState("");
  const [selectedIssueDate, setSelectedIssueDate] = React.useState("");
  const [changeFlag, setChangeFlag] = React.useState(false);
  const [completionStatus, setCompletionStatus] = React.useState(null);
  const toast = useToast();
  // const [grade1to4, setGrade1to4] = React.useState(false);
  // const [grade5, setGrade5] = React.useState(false);
  const [grade6to7, setGrade6to7] = React.useState(false);

  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/general/getclassAndsection`,
        {},
        config
      )
      .then((response) => {
        setClassAndGradeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (localStorage.getItem("report")) {
      setGrade(JSON.parse(localStorage.getItem("report"))[0].class);
      setSection(JSON.parse(localStorage.getItem("report"))[0].section);
      setTerm(
        JSON.parse(localStorage.getItem("report"))[0].hasOwnProperty("mid_term")
          ? "mid_term"
          : "anual"
      );
    }
  }, []);

  function getExamResults(grade, section, term) {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/management/exam/getMarks`,
        {
          class: grade,
          section: section,
          exam: term,
        },
        config
      )
      .then((response) => {
        console.log("resut", response.data);
        setResults(response.data);
        setList(response.data);
        handleCompletionStatus(response.data);
      })
      .catch((err) => {
        setResults(null);
      });
  }

  const handleCompletionStatus = (values) => {
    let individual_status = values.map((val) => {
      if (Object.keys(val).includes("mid_term")) {
        let regular_grades = val.mid_term.filter(
          (m) =>
            m.subject_name !== "DISCIPLINE" &&
            m.subject_name !== "ART EDUCATION" &&
            m.subject_name !== "HEALTH AND PHYSICAL EDUCATION" &&
            m.subject_name !== "GENERAL STUDIES" &&
            m.subject_name !== "WORK EDUCATION"
        );
        let coscholastic_grades = val.mid_term.filter(
          (m) =>
            m.subject_name === "DISCIPLINE" ||
            m.subject_name === "ART EDUCATION" ||
            m.subject_name === "HEALTH AND PHYSICAL EDUCATION" ||
            m.subject_name === "GENERAL STUDIES" ||
            m.subject_name === "WORK EDUCATION"
        );
        let completion_status =
          regular_grades.every((r) => r.total !== "-") &&
          coscholastic_grades.every((r) => r.grade !== "N/A")
            ? true
            : false;
        let empty_status =
          regular_grades.every((r) => r.total === "-") &&
          coscholastic_grades.every((r) => r.grade === "N/A")
            ? true
            : false;
        return { empty: empty_status, completed: completion_status };
      }
    });
    let isEmptyStatus = individual_status.every((s) => s.empty === true);
    let hasCompletedStatus = individual_status.every(
      (s) => s.completed === true
    );
    setCompletionStatus({
      empty: isEmptyStatus,
      completed: hasCompletedStatus,
    });
  };

  const setAllFalse = () => {
    // setGrade1to4(false);
    // setGrade5(false);
    setGrade6to7(false);
  };

  useEffect(() => {
    if (
      grade &&
      section &&
      term &&
      (!changeFlag ||
        classAndGradeList
          .filter((item) => item.class === grade)
          .map((item) => item.sections.includes(section))[0])
    ) {
      getExamResults(grade, section, term);
    }
  }, [grade, section, term]);

  useEffect(() => {
    if (
      !classAndGradeList
        .filter((item) => item.class === grade)
        .map((item) => item.sections.includes(section))[0] &&
      changeFlag
    ) {
      setSection("");
      setResults(null);
    }
  }, [grade]);

  useEffect(() => {
    if (grade) {
      // if (grade >= 1 && grade <= 4) {
      // 	setAllFalse();
      // 	setGrade1to4(true);
      // } else if (grade == 5) {
      // 	setAllFalse();
      // 	setGrade5(true);
      // } else if (grade >= 6 && grade <= 7) {
      // 	setAllFalse();
      // 	setGrade6to7(true);
      // } else {
      setAllFalse();
      setGrade6to7(true); //just rename this later - Handling all classes in same name
      // }
      if (results) {
        let requiredReportIDs = results.map((report) => report._id);
        let reportWithMappedDetails = [];
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/management/exam/getReports`,
            {
              class: grade,
              exam: term,
              reports: requiredReportIDs,
            },
            config
          )
          .then((response) => {
            reportWithMappedDetails = response.data;
            setExamReports(reportWithMappedDetails);
            let latestIssueDate = reportWithMappedDetails
              .filter((i) => i.issue_date !== "N/A")
              .map((item) => item.issue_date);
            if (latestIssueDate.length) {
              latestIssueDate = latestIssueDate.sort();
              setIssueDate(latestIssueDate[latestIssueDate.length - 1]);
            }
          })
          .catch((err) => {
            setExamReports(null);
          });
      } else {
        setExamReports(null);
        setIssueDate("");
      }
    }
  }, [results]);

  useEffect(() => {
    if (selectedResult.length) {
      let requiredReportIDs = selectedResult.map((report) => report._id);
      let reportWithMappedDetails = [];
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/management/exam/getReports`,
          {
            class: grade,
            exam: term,
            reports: requiredReportIDs,
          },
          config
        )
        .then((response) => {
          reportWithMappedDetails = response.data;
          setSelectedExamReports(reportWithMappedDetails);
          let latestIssueDate = reportWithMappedDetails
            .filter((i) => i.issue_date !== "N/A")
            .map((item) => item.issue_date);
          console.log("lid", latestIssueDate);
          if (latestIssueDate.length) {
            latestIssueDate = latestIssueDate.sort();
            setSelectedIssueDate(latestIssueDate[latestIssueDate.length - 1]);
          } else {
            setSelectedIssueDate("");
          }
        })
        .catch((err) => {
          setSelectedExamReports(null);
        });
    } else {
      setSelectedIssueDate("");
      setSelectedExamReports(null);
    }
  }, [selectedResult]);

  const navigate = useNavigate();
  const goToPreviewReport = (noOfReport) => {
    let reportSelected =
      noOfReport === "all" ? examReports : selectedExamReports;
    localStorage.setItem("report", JSON.stringify(reportSelected));
    navigate("./preview/" + term + "/" + grade, {
      state: {
        data: reportSelected,
      },
    });
  };

  const goToReleaseReport = (noOfReport) => {
    let reportSelected = noOfReport === "all" ? results : selectedResult;
    console.log("here");
    console.log(reportSelected);
    let requiredReportIDs = reportSelected.map((report) => report._id);
    let reportWithMappedDetails = [];
    if (requiredReportIDs.length == 0) {
      toast({
        title: "Reports were already released",
        variant: "left-accent",
        position: "bottom-right",
        status: "info",
        duration: 1500,
        isClosable: true,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/exam/release`,
          {
            class: grade,
            exam: term,
            reports: requiredReportIDs,
          },
          config
        )
        .then((response) => {
          reportWithMappedDetails = response.data;
          toast({
            title: "Reports successfully released",
            variant: "left-accent",
            position: "bottom-right",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
          getExamResults(grade, section, term);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <h1 className={Styles.headTitle}>Report Card</h1>

        <div className={Styles.dropDown}>
          <Select
            onChange={(e) => {
              setGrade(e.target.value);
              setChangeFlag(true);
            }}
            name="class"
            placeholder="Select Grade"
            value={
              localStorage.getItem("report") && !changeFlag
                ? `${JSON.parse(localStorage.getItem("report"))[0].class}`
                : grade
            }
          >
            {classAndGradeList.map((item) => (
              <option value={item.class}>Grade {item.class}</option>
            ))}
          </Select>
        </div>

        <div className={Styles.dropDown}>
          <Select
            onChange={(e) => {
              setSection(e.target.value);
              setChangeFlag(true);
            }}
            name="class"
            placeholder="Select Section"
            value={
              localStorage.getItem("report") && !changeFlag
                ? `${JSON.parse(localStorage.getItem("report"))[0].section}`
                : section
            }
          >
            {classAndGradeList.filter((i) => i.class === grade).length
              ? classAndGradeList
                  .filter((i) => i.class === grade)[0]
                  .sections.map((item) => (
                    <option value={item}>Section {item}</option>
                  ))
              : ""}
          </Select>
        </div>

        <div className={Styles.dropDown}>
          <Select
            onChange={(e) => {
              setTerm(e.target.value);
              setChangeFlag(true);
            }}
            name="class"
            placeholder="Select Exam Semester"
            value={
              localStorage.getItem("report") && !changeFlag
                ? JSON.parse(localStorage.getItem("report"))[0].hasOwnProperty(
                    "mid_term"
                  )
                  ? "mid_term"
                  : "anual"
                : term
            }
          >
            <option value="mid_term">Mid-Term Exam</option>
            <option value="anual">Annual Exam</option>
          </Select>
        </div>

        <div className={Styles.indicator}>
          <p>
            Completion Status:
            <span>
              {completionStatus
                ? completionStatus.empty
                  ? "EMPTY"
                  : completionStatus.completed
                  ? "COMPLETE"
                  : "PARTIAL"
                : ""}
            </span>{" "}
          </p>
          <div
            className={
              completionStatus
                ? completionStatus.empty
                  ? Styles.empty
                  : completionStatus.completed
                  ? Styles.completed
                  : Styles.progress
                : Styles.empty
            }
          />
        </div>
      </div>

      {/* <MarksEntryDiscipline /> */}
      <div className={Styles.tableWrapper}>
        {grade6to7 && term && studentlist ? (
          <Marks6to7
            results={studentlist}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
            grade={grade}
          />
        ) : null}
      </div>
      {/* {(grade1to4 && results) && <Marks1to4 data={results} term={term} />}
				{(grade5 && results) && <Marks5 data={results} term={term} />} */}

      {/* {(grade6to7 && results && results.length && term=='mid_term') && <Marks6to7
			results={results}
			selectedResult={selectedResult}
			setSelectedResult={setSelectedResult} />} */}

      <div className={Styles.buttonWrapper}>
        <div className={Styles.buttonSubWrapper}>
          <button
            onClick={() => {
              selectedResult.length
                ? goToPreviewReport("selected")
                : toast({
                    title: "Please select atleast one student!",
                    variant: "left-accent",
                    position: "bottom-right",
                    status: "error",
                    duration: 1500,
                    isClosable: true,
                  });
            }}
            className={Styles.button}
          >
            Preview Selected
          </button>

          <button
            to="preview"
            onClick={() => goToPreviewReport("all")}
            className={Styles.button}
          >
            Preview All
          </button>
        </div>

        <div className={Styles.warning}>
          <div className={Styles.WarCol}>
            <div className={Styles.WarRow}>
              <p>Release Selected</p>
              <Switch
                colorScheme={"green"}
                size="md"
                onChange={(e) =>
                  e.target.checked
                    ? selectedResult.length
                      ? goToReleaseReport("selected")
                      : toast({
                          title: "Please select atleast one student!",
                          variant: "left-accent",
                          position: "bottom-right",
                          status: "error",
                          duration: 1500,
                          isClosable: true,
                        })
                    : ""
                }
              />
            </div>
            <p>
              Current Issue Date :{" "}
              {issueDate
                ? new Date(issueDate).toLocaleDateString("en-GB")
                : "NA"}
            </p>
            {/* <p>Current Issue Date : {selectedIssueDate ? (new Date(selectedIssueDate)).toLocaleDateString("en-GB") : 'NA'}</p> */}
          </div>

          <div className={Styles.WarCol}>
            <div className={Styles.WarRow}>
              <p>Release All</p>
              <Switch
                colorScheme={"green"}
                size="md"
                onChange={(e) =>
                  e.target.checked ? goToReleaseReport("all") : ""
                }
              />
            </div>
            <p>
              Current Issue Date :{" "}
              {issueDate
                ? new Date(issueDate).toLocaleDateString("en-GB")
                : "NA"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementReportCard;

import React, { useEffect, useState } from "react";
import Styles from "./Notification.module.scss";
import { FiRefreshCw } from "react-icons/fi";
import { MdCreate } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { BiUpArrowCircle } from "react-icons/bi";
import { CiCircleRemove } from "react-icons/ci";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  requestGetSendNotifications,
  requestSingleNotificationDetailAdmin,
  //   requestCreateNotification,
  requestRemoveNotification,
} from "services/admin-dashboard/notification.service";
import { requestSingleNotificationDetail } from "services/common.service";
import { NavLink } from "react-router-dom";
const SendNotification = () => {
  const toast = useToast();
  const createNotificationModal = useDisclosure();
  const singleNotificationModal = useDisclosure();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [skip, setSkip] = useState(0);
  const [showLessVisible, setShowLessVisible] = useState(false);
  const [singleNotification, setSingleNotifcation] = useState([]);
  const [singleNotificationID, setSingleNotificationID] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleRefresh = () => {
    window.location.reload(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleNotifications = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        skip: skip,
      };
      const response = await requestGetSendNotifications(payload);
      setNotifications(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  // console.log("notifications notifications ", notifications);
  useEffect(() => {
    handleNotifications();
    setShowLessVisible(skip > 0);
  }, [skip]);
  const loadMoreNotifications = () => {
    if (skip > 0) {
      setSkip((prevSkip) => prevSkip - 1);
    } else {
      setSkip((prevSkip) => prevSkip + 1);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const timeFormat = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${formattedHours}:${minutes}:${seconds} ${timeFormat}`;
  };
  const handleSingleNotification = async () => {
    try {
      const payload = {
        notification_id: singleNotificationID,
      };
      const response = await requestSingleNotificationDetailAdmin(payload);
      setSingleNotifcation(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (singleNotificationID && singleNotificationID) {
      handleSingleNotification();
    }
  }, [singleNotificationID]);
  const handleRemoveNotification = async (e, id) => {
    e.stopPropagation();
    try {
      const payload = {
        notification_id: id,
      };
      const response = await requestRemoveNotification(payload);
      toast({
        title: "Notification Removed Succesfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      handleNotifications();
    } catch (e) {
      console.error(e);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <p className={Styles.stats}>
          NOTIFICATIONS (
          {notifications&&notifications.length}
          )
        </p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists3}>
          {requestInProgress ? (
            <Spinner />
          ) : notifications && notifications?.length === 0 ? (
            <div className={Styles.noResult}>No Notification Available</div>
          ) : (
            notifications &&
            notifications &&
            notifications.map((notification) => (
              <div
              className={Styles.notificationList}
              >
              <NavLink to={notification._id}
                style={{width:"90%"}}
                key={notification._id}
              >
               
                <div className={Styles.content}>
                <AiOutlineUser
                  fontSize={30}
                  style={{ border: "1px solid black", borderRadius: "100%" }}
                />
                  <p className={Styles.fontSize}>{notification.title}</p>
                  <div className={Styles.delContainer}>
                    <p className={Styles.date}>
                      {formatDate(notification.createdAt)}
                    </p>
                    
                  </div>
                </div>
                <div>
               
                </div>
              </NavLink>
              <Tooltip label="Remove Notification" placement="left">
                      <div
                        onClick={(e) =>
                          handleRemoveNotification(e, notification._id)
                        }
                      >
                        <CiCircleRemove fontSize={25} cursor="pointer" />
                      </div>
                    </Tooltip>
              </div>
            ))
          )}
        </div>
        <div className={Styles.button}>
          {showLessVisible &&
            notifications &&
            notifications?.notifications?.length >= 1 && (
              <div>
                <Button onClick={loadMoreNotifications} colorScheme="red">
                  Show Less
                </Button>
              </div>
            )}
          {!showLessVisible &&
            notifications &&
            notifications?.notifications?.length >= 1 && (
              <div>
                <Button onClick={loadMoreNotifications} colorScheme="teal">
                  Show More
                </Button>
              </div>
            )}
        </div>
      </div>
      <Modal
        isOpen={singleNotificationModal.isOpen}
        onClose={() => {
          handleNotifications();
          singleNotificationModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p>Notification Detail</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.list}>
              <AiOutlineUser
                fontSize={30}
                style={{ border: "1px solid black", borderRadius: "100%" }}
              />
              <div className={Styles.content}>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.notifier?.user_name.toUpperCase()}{" "}
                  |{" "}
                  {singleNotification?.notification?.notifier?.user_role.toUpperCase()}
                </p>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.title}
                </p>
                <p className={Styles.fontSize}>
                  {singleNotification?.notification?.description}
                </p>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};
export default SendNotification;

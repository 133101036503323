import {
  Button,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tooltip,
  Checkbox,
  Switch,
  Box,
  Heading,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import Styles from "./NewAdminAttendance.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import { debounce } from "utilities/utils";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import defaultUser from "assets/icons/User.png";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import {
  requestAddAttendance,
  requestAttendanceClass,
  requestDeleteAttendance,
  requestGetAttendance,
  requestGetClassStats,
  requestHolidays,
  requestStatsOne,
  requestUpdateAttendance,
  requestUpdateOne,
  requestStatisticsStudentDetails,
} from "services/admin-dashboard/new-attendance.service";
import { requestSearchStudentByFilterCommon } from "services/common.service";
import { Link } from "react-router-dom";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlinePlusSquare, AiOutlineCloseCircle } from "react-icons/ai";
const NewAdminAttendence = () => {
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab.toString());
  }, [selectedTab]);
  return (
    <div>
      <Tabs
        isLazy
        isFitted
        variant="enclosed"
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
      >
        <TabList mb="1em">
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Attendance</Tab>
          <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>
            Attendance Statistics
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Attendance />
          </TabPanel>
          <TabPanel>
            <Statistics />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};
export default NewAdminAttendence;
const Attendance = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputFields, setInputFields] = useState({});
  const currentYear = new Date().getFullYear();
  const pastYears = 1;
  const futureYears = 3;
  const [students, setStudentsList] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const { classAndSections } = useClassAndSectionsWithoutAll();
  const [sections, setSections] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [morningList, setMorningList] = useState([]);
  const [afternoonList, setAfternoonList] = useState([]);
  const [presentList, setPresentList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [classStatus, setClassStatus] = useState(null);
  const [attendanceDays, setAttendanceDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [exclude, setExclude] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const calendarRef = useRef(null);
  const modalAddAttendance = useDisclosure();
  const modalUpdateAttendance = useDisclosure();
  const modalUpdatePopup = useDisclosure();
  const modalDeletePopup = useDisclosure();
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef.current.getApi();
    const newDate = new Date(year, month);

    if (!isNaN(newDate.getTime())) {
      calendarApi.gotoDate(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const renderHeader = () => {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={
              selectedMonth === ""
                ? Styles.calendarHeaderMonth
                : `${Styles.selected} ${Styles.calendarHeaderMonth}`
            }
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={
              selectedYear === ""
                ? Styles.calendarHeaderYear
                : `${Styles.selected} ${Styles.calendarHeaderYear}`
            }
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize:
                selectedMonth !== "" ? Styles.headerValSel : Styles.headerVal,
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();
    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
              <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                <div class="${Styles.date}">${date.getDate()}</div>
              </div>
            `,
    };
  };
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    if (clickedDate < new Date(2023, 5, 1)) {
      toast({
        title: "The dates beyond the session is invalid",
        description: "The academic session date range is startdate- end date. ",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // Calculate the difference in months between the clicked date and the selected month
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;

    // Calculate the new month and year based on the clicked date
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;

    // Update the state to the clicked date, month, and year
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);

    calendarRef.current.getApi().unselect(); // Unselect the clicked date

    setTimeout(() => {
      calendarRef.current.getApi().updateSize(); // Update the calendar size after a delay
    }, 0);
  };
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
          studentName: "",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          studentName: "",
        }));
      }
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const studentList = isQueried ? searchResultList : students;
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  useEffect(() => {
    let input_data = localStorage.getItem("student_filter")
      ? JSON.parse(localStorage.getItem("student_filter"))
      : "";
    setInputFields(input_data);
    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);
  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleAttendanceForClass = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      class: inputFields.class,
      section: inputFields.section,
      year: selectedYear,
    };
    const response = await requestAttendanceClass(payload);
    setAttendanceDays(response);
  };
  useEffect(() => {
    if (
      selectedYear &&
      selectedMonth !== undefined &&
      inputFields.class &&
      inputFields.section
    ) {
      handleAttendanceForClass();
      setAttendanceDays(null);
      handleHolidays();
      setHolidays(null);
    }
  }, [selectedMonth, selectedYear, inputFields.class, inputFields.section]);
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
      year: selectedYear,
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  const selectDate = moment(selectedDate).format("YYYY-MM-DD");
  const formattedHolidays = holidays
    ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  const isTodayHoliday = formattedHolidays.includes(selectDate);
  const isSunday = selectedDate && selectedDate?.getDay() === 0;
  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedAttendanceDays = attendanceDays
      ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedAttendanceDays.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };

    calendarApi.batchRendering(() => {
      highlightDates();
      calendarApi.render();
    });
  }, [
    attendanceDays,
    inputFields.class,
    inputFields.section,
    selectedMonth,
    holidays,
  ]);
  const handleAddAttendance = async () => {
    let absentList = [];
    for (let i = 0; i < studentList.length; i++) {
      const element = studentList[i]._id;
      if (
        !morningList.includes(element) &&
        !afternoonList.includes(element) &&
        !presentList.includes(element)
      ) {
        absentList.push(element);
      }
    }
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      morning_present: morningList,
      afternoon_present: afternoonList,
      present: presentList,
      absent: absentList,
    };
    try {
      const res = await requestAddAttendance(data);
      if (res) {
        modalAddAttendance.onClose();
        handleGetClassStats();
        handleGetAttendance();
        setMorningList([]);
        setAfternoonList([]);
        setPresentList([]);
        toast({
          render: () => (
            <Box
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                backgroundColor: "#40a46c",
                color: "white",
              }}
              p={4}
              borderRadius="md"
              boxShadow="lg"
            >
              <Box>
                <Heading size="md" mb={2}>
                  Attendance Added Succesfully
                </Heading>
                <Text>Please wait for reflecting the date in the calendar</Text>
              </Box>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                color="white"
                onClick={() => {
                  handleClose();
                  toast.closeAll();
                }}
              />
            </Box>
          ),
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);
      }
    } catch (error) {
      modalAddAttendance.onClose();
      toast({
        title: error,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleGetAttendance = () => {
    setMorningList([]);
    setAfternoonList([]);
    setPresentList([]);
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = requestGetAttendance(data);
    res
      .then((response) => {
        setAttendanceData(response);
      })
      .catch((error) => {});
  };
  const handleGetClassStats = async () => {
    setMorningList([]);
    setAfternoonList([]);
    setPresentList([]);
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };
    const res = await requestGetClassStats(data);
    setClassStatus(res);
  };
  const handleAttendanceData = () => {
    setMorningList(attendanceData.morning_present);
    setAfternoonList(attendanceData.afternoon_present);
    setPresentList(attendanceData.present);
    setAbsentList(attendanceData.absent);
  };
  useEffect(() => {
    if (selectedDate && attendanceData) {
      handleAttendanceData();
    }
  }, [attendanceData]);
  const handleMorningChange = (id) => {
    let morning = [...morningList];
    if (morning.includes(id)) {
      let index = morning.indexOf(id);
      morning.splice(index, 1);
      setMorningList(morning);
    } else {
      if (presentList.includes(id)) {
        setAfternoonList([...afternoonList, id]);
      } else {
        setMorningList([...morning, id]);
      }
    }
    handleRemovePresentChange(id);
  };
  const handleAfternoonChange = (id) => {
    let afternoon = [...afternoonList];
    if (afternoon.includes(id)) {
      let index = afternoon.indexOf(id);
      afternoon.splice(index, 1);
      setAfternoonList(afternoon);
    } else {
      if (presentList.includes(id)) {
        setMorningList([...morningList, id]);
      } else {
        setAfternoonList([...afternoon, id]);
      }
    }
    handleRemovePresentChange(id);
  };
  const handlePresentChange = () => {
    let present = [...presentList];
    let morning = [...morningList];
    let afternoon = [...afternoonList];
    for (let i = 0; i < morningList.length; i++) {
      const element1 = morningList[i];
      for (let j = 0; j < afternoonList.length; j++) {
        const element2 = afternoonList[j];
        if (element1 === element2 && !present.includes(element2)) {
          let morningIndex = morning.indexOf(element2);
          morning.splice(morningIndex, 1);
          let afternoonIndex = afternoon.indexOf(element2);
          afternoon.splice(afternoonIndex, 1);
          setMorningList(morning);
          setAfternoonList(afternoon);
          setPresentList([...present, element2]);
        }
      }
    }
  };
  const handleRemovePresentChange = (id) => {
    let present = [...presentList];
    if (present.includes(id)) {
      let index = present.indexOf(id);
      present.splice(index, 1);
      setPresentList(present);
    }
  };
  const handleCheckAll = () => {
    if (studentList.length === presentList.length) {
      setPresentList([]);
    } else {
      let tempStudentList = studentList.map((item) => item._id);
      setPresentList(tempStudentList);
    }
  };
  useEffect(() => {
    handlePresentChange();
  }, [afternoonList, morningList]);
  const handleUpdateAttendance = async () => {
    let absentList = [];
    for (let i = 0; i < studentList.length; i++) {
      const element = studentList[i]._id;
      if (
        !morningList.includes(element) &&
        !afternoonList.includes(element) &&
        !presentList.includes(element)
      ) {
        absentList.push(element);
      }
    }
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      morning_present: morningList,
      afternoon_present: afternoonList,
      present: presentList,
      absent: absentList,
      exclude: exclude ? exclude : false,
    };

    const response = await requestUpdateAttendance(data);

    if (response) {
      modalUpdateAttendance.onClose();
      modalUpdatePopup.onClose();
      setMorningList([]);
      setAfternoonList([]);
      setPresentList([]);
      handleGetAttendance();
      handleGetClassStats();
      setExclude("");
      toast({
        title: "Attendance Updated Succesfully",
        description: "Updated Details",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const openUpdatePopup = () => {
    modalUpdatePopup.onOpen();
  };
  const openDeletePopup = () => {
    modalDeletePopup.onOpen();
  };
  const handleClose = () => {
    setShowToast(false);
  };
  const handleDeleteAttendance = async () => {
    const data = {
      class: inputFields.class,
      section: inputFields.section,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    };

    const response = await requestDeleteAttendance(data);
    if (response) {
      modalUpdateAttendance.onClose();
      modalDeletePopup.onClose();
      handleGetClassStats();
      setAttendanceData("");
      setMorningList("");
      setAfternoonList("");
      setPresentList("");
      toast({
        render: () => (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              backgroundColor: "#40a46c",
              color: "white",
            }}
            p={4}
            borderRadius="md"
            boxShadow="lg"
          >
            <Box>
              <Heading size="md" mb={2}>
                Attendance Deleted Successfully
              </Heading>
              <Text>Please wait for reflecting the date in the calendar</Text>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              color="white"
              onClick={() => {
                handleClose();
                toast.closeAll();
              }}
            />
          </Box>
        ),
      });
      setTimeout(() => {
        // if (showToast) {
        //   handleClose();
        //   toast.closeAll();
        // }
        window.location.reload(true);
      }, 3000);
    }
  };
  useEffect(() => {
    if ((inputFields.class && inputFields.section) || selectedDate) {
      handleGetClassStats();
      setClassStatus("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  const chhoseDate = moment(selectedDate).format("YYYY-MM-DD");
  const addedAttendance = attendanceDays
    ? attendanceDays.map((date) => new Date(date).toISOString().split("T")[0])
    : [];
  useEffect(() => {
    if (
      inputFields.class &&
      inputFields.section &&
      addedAttendance.includes(chhoseDate)
    ) {
      handleGetAttendance();
      setAttendanceData("");
    }
  }, [inputFields.class, inputFields.section, selectedDate]);
  const isFutureDate = selectedDate > new Date();
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));

    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  return (
    <div className={Styles.WholeContainer}>
      <div className={Styles.calenderWidth}>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid black",
              padding: "5px",
            }}
          >
            <div>
              <button onClick={handlePreviousMonth}>
                <FaCaretSquareLeft className={Styles.arrows} />
              </button>
            </div>
            <div>{renderHeader()}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className={Styles.todayButton}
                onClick={handleTodayButtonClick}
                style={{ display: isToday() ? "none" : "block" }}
              >
                Today
              </button>
              <button onClick={handleNextMonth}>
                <FaCaretSquareRight className={Styles.arrows} />
              </button>
            </div>
          </div>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            dateClick={handleDateClick}
            dayCellContent={dayCellContent}
            dayHeaderContent={dayHeaderContent}
          />
        </div>
      </div>
      <div className={Styles.BoxContainer}>
        <div className={Styles.header}>
          <div className={Styles.dateContainer}>
            <p>
              Date : &nbsp;
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </p>
          </div>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper} style={{ width: "100%" }}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
                style={{ border: "1px solid black" }}
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
            <div className={Styles.filterWrapper} style={{ width: "100%" }}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
                style={{ border: "1px solid black" }}
              >
                {sections
                  ? sections.map((section, idx) => (
                      <option key={section + idx} value={section}>
                        Section {section}
                      </option>
                    ))
                  : ""}
              </Select>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {classStatus ? (
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                marginTop: "15px",
                padding: "11px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Academic Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>
                    {moment(classStatus.start_date).format("DD/MM/YYYY")}-
                    {moment(classStatus.end_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Days in the Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.total_days}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Midterm Days in the Session
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.mid_term_days}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    flexDirection: "row",
                  }}
                >
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Total Number of Days Attendance Entered
                  </p>
                  <p style={{ width: "10%", fontWeight: "bold" }}>:</p>
                  <p style={{ width: "40%" }}>{classStatus.marked_days}</p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className={Styles.selectGrade}>Please Select Grade</div>
            </div>
          )}
        </div>
        {selectedDate && classStatus && (
          <div
            style={{
              display: " flex",
              flexDirection: "column",
              backgroundColor: "white",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              marginTop: "15px",
              padding: "11px",
              fontWeight: "bold",
            }}
          >
            {isTodayHoliday || isSunday ? (
              <p style={{ color: "red" }}>
                The Selected Date is a Holiday / Sunday
              </p>
            ) : isFutureDate ? (
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                Attendance not present for the upcoming date from today.
              </p>
            ) : (
              <>
                <p>
                  <strong>Total Number of Students :</strong>
                  <span style={{ fontWeight: "bold" }}>
                    &nbsp;
                    {classStatus?.total_count
                      ? classStatus?.total_count
                      : "N/A"}
                  </span>
                </p>
                <div className={Styles.attendanceData}>
                  <p>
                    Present Students :&nbsp;
                    <span style={{ color: "green" }}>
                      {classStatus?.presents ? classStatus?.presents : "N/A"}
                    </span>
                  </p>
                  <p>
                    Absent Students :&nbsp;
                    <span style={{ color: "red" }}>
                      {classStatus.absents ? classStatus?.absents : "N/A"}
                    </span>
                  </p>
                  <p>
                    Halfday Present Students :&nbsp;
                    <span>
                      {classStatus?.partial_present
                        ? classStatus?.partial_present
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        <div
          style={{
            display: " flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "white",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            marginTop: "15px",
            padding: "11px",
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#9c9695",
                marginRight: "10px",
              }}
            ></div>
            <p>Holiday / Sunday</p>
          </div>
          <div
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "rgb(51 190 51)",
                marginRight: "10px",
              }}
            ></div>
            <p>Attendance Entered</p>
          </div>
        </div>
      </div>
      {selectedDate && inputFields.class && inputFields.section && (
        <Tooltip
          label={
            addedAttendance.includes(chhoseDate)
              ? "View Attendance"
              : "Add Attendance"
          }
          aria-label="A tooltip"
        >
          <div
            className={Styles.academicYear}
            onClick={
              addedAttendance.includes(chhoseDate)
                ? modalUpdateAttendance.onOpen
                : modalAddAttendance.onOpen
            }
          >
            {addedAttendance.includes(chhoseDate) ? (
              <IoMdCheckmarkCircleOutline className={Styles.add} />
            ) : (
              <MdOutlineAddCircleOutline className={Styles.add} />
            )}
          </div>
        </Tooltip>
      )}
      {isTodayHoliday || isSunday ? (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={modalAddAttendance.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Date:
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                The Selected Date is a Holiday / Sunday
              </p>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : isFutureDate ? (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={modalAddAttendance.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Date:
              {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p style={{ fontWeight: "bold", color: "red", fontSize: "18px" }}>
                Not able to add the Attendance for the upcoming date from today.
              </p>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={modalAddAttendance.isOpen}
          onClose={() => {
            modalAddAttendance.onClose();
          }}
          size="xl"
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent
            position="fixed"
            top="10"
            transform="translateX(-50%)"
            maxWidth="70vw"
            width="70vw"
            maxHeight="150vw"
            overflowY="scroll"
          >
            <ModalHeader>Add Attendance</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <>
                <div className={Styles.updateHeadingContainer}>
                  <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Date:
                    {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
                    &nbsp;|&nbsp; Total Students:{studentList.length}
                  </p>
                  <div className={Styles.addAttendanceContainer}>
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                      >
                        Check all
                      </p>
                      <Checkbox
                        isChecked={studentList.length === presentList.length}
                        onChange={handleCheckAll}
                        colorScheme="red"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ position: "relative", marginLeft: "20px" }}>
                        <Input
                          name="studentName"
                          variant="filled"
                          placeholder="Search"
                          value={inputFields.studentName}
                          onChange={handleInputChange}
                          onKeyDown={handleEnterKeyPress}
                          autoComplete="off"
                          paddingRight="3rem"
                          style={{ width: "100%" }}
                        />
                        {showSearchIcon ? (
                          <BiSearchAlt2
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "30%",
                              cursor: "pointer",
                            }}
                            fontSize={20}
                            onClick={handleSearchClick}
                          />
                        ) : (
                          <AiOutlineCloseCircle
                            onClick={handleCloseIconClick}
                            fontSize={20}
                            style={{
                              position: "absolute",
                              right: "1rem",
                              top: "30%",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={Styles.body}
                  style={{ maxHeight: "42vh", overflowY: "auto" }}
                >
                  <div className={Styles.lists}>
                    {studentList.length === 0 ? (
                      <div className={Styles.noResult}>No Result Found</div>
                    ) : (
                      studentList.map((student, idx) => (
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                student.url != "N/A" ? student.url : defaultUser
                              }
                              alt="student-profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {student.name || "--"}
                            </p>

                            <div style={{ display: "flex" }}>
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  morningList.includes(student._id) ||
                                  presentList.includes(student._id)
                                }
                                onChange={() =>
                                  handleMorningChange(student._id)
                                }
                              >
                                <p className={Styles.checkboxText}>Morning</p>
                              </Checkbox>
                            </div>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  afternoonList.includes(student._id) ||
                                  presentList.includes(student._id)
                                }
                                onChange={() =>
                                  handleAfternoonChange(student._id)
                                }
                              >
                                <p className={Styles.checkboxText}>Afternoon</p>
                              </Checkbox>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </>
            </ModalBody>

            <ModalFooter>
              <>
                <Button
                  variant="solid"
                  colorScheme="green"
                  mr={3}
                  onClick={() => handleAddAttendance()}
                >
                  Add
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={modalAddAttendance.onClose}
                >
                  Cancel
                </Button>
              </>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal
        isOpen={modalUpdateAttendance.isOpen}
        onClose={modalUpdateAttendance.onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          position="fixed"
          top="10"
          transform="translateX(-50%)"
          maxWidth="70vw"
          width="70vw"
          maxHeight="150vw"
          overflowY="scroll"
        >
          <ModalHeader>Update/Delete Attendance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <div className={Styles.updateHeadingContainer}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Date:
                  {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}
                  &nbsp;| &nbsp;Total Students: {studentList.length}
                  {/* {(() => {
                    if (classStatus?.total_count > studentList?.length) {
                      return ` | Deleted Students : ${
                        classStatus?.total_count - studentList.length
                      }`;
                    }
                    return "";
                  })()}
                  {(() => {
                    const newlyAddedCount = studentList.filter((student) => {
                      const studentId = student._id;
                      const isPresent =
                        attendanceData?.present?.includes(studentId) ||
                        attendanceData?.morning_present?.includes(studentId) ||
                        attendanceData?.afternoon_present?.includes(
                          studentId
                        ) ||
                        attendanceData?.absent?.includes(studentId);
                      return !isPresent;
                    }).length;
                    if (newlyAddedCount > 0) {
                      return ` | Newly Added Students: ${newlyAddedCount}`;
                    }
                    return "";
                  })()} */}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative", marginLeft: "20px" }}>
                    <Input
                      name="studentName"
                      variant="filled"
                      placeholder="Search"
                      value={inputFields.studentName}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      onChange={handleInputChange}
                      style={{ width: "100%" }}
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.body}>
                <div
                  className={Styles.lists}
                  style={{ maxHeight: "42vh", overflowY: "auto" }}
                >
                  {attendanceData && (
                    <>
                      {studentList.map((student) => {
                        const studentId = student._id;
                        const isPresent =
                          attendanceData?.present?.includes(studentId) ||
                          attendanceData?.morning_present?.includes(
                            studentId
                          ) ||
                          attendanceData?.afternoon_present?.includes(
                            studentId
                          ) ||
                          attendanceData?.absent?.includes(studentId);
                        return (
                          <div key={studentId} className={Styles.list}>
                            {/* <div
                            key={studentId}
                             className={
                               isPresent ? Styles.list : Styles.notFound
                             }
                           > */}
                            <div className={Styles.profile}>
                              <img
                                className={Styles.profileImg}
                                src={
                                  student.url != "N/A"
                                    ? student.url
                                    : defaultUser
                                }
                                alt="student-profile"
                              />
                            </div>
                            <div className={Styles.info}>
                              <p className={Styles.name}>
                                {student.name || "--"}
                              </p>
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  colorScheme="red"
                                  isChecked={
                                    morningList.includes(studentId) ||
                                    presentList.includes(studentId)
                                  }
                                  onChange={() =>
                                    handleMorningChange(studentId)
                                  }
                                >
                                  <p className={Styles.checkboxText}>Morning</p>
                                </Checkbox>
                              </div>
                              <div style={{ display: "flex" }}>
                                <Checkbox
                                  colorScheme="red"
                                  isChecked={
                                    afternoonList.includes(studentId) ||
                                    presentList.includes(studentId)
                                  }
                                  onChange={() =>
                                    handleAfternoonChange(studentId)
                                  }
                                >
                                  <p className={Styles.checkboxText}>
                                    Afternoon
                                  </p>
                                </Checkbox>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              onClick={openUpdatePopup}
              mr={3}
            >
              Update
            </Button>
            <Button colorScheme="red" onClick={openDeletePopup} mr={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalUpdatePopup.isOpen}
        onClose={modalUpdatePopup.onClose}
      >
        <ModalOverlay />
        <ModalContent position="fixed" top="30%">
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p style={{ fontWeight: "bold" }}>
                Do you want to update the attendance for&nbsp;
                {inputFields.class} {inputFields.section} of&nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}?
              </p>

              {classStatus?.total_count !== studentList?.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginTop: "5px" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      NOTE :
                    </span>
                    &nbsp;Some students were added to the class whose attendance
                    status were not included in the Attendance Data. Do you want
                    to include their status?
                  </p>
                  <div
                    style={{
                      marginTop: "12px",
                      fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    Include :&nbsp;
                    <Switch
                      defaultIsChecked
                      onChange={() => setExclude(!exclude)}
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="solid"
                colorScheme="green"
                mr={3}
                onClick={handleUpdateAttendance}
              >
                OK
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={modalUpdatePopup.onClose}
              >
                Cancel
              </Button>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={modalDeletePopup.isOpen}
        onClose={modalDeletePopup.onClose}
      >
        <ModalOverlay />
        <ModalContent position="fixed" top="30%">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p style={{ fontWeight: "bold" }}>
                Do you want to permanently delete the attendance for&nbsp;{" "}
                {inputFields.class} {inputFields.section} of&nbsp;
                {selectedDate && moment(selectedDate).format("DD-MM-YYYY")}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <>
              <Button
                variant="solid"
                colorScheme="red"
                mr={3}
                onClick={handleDeleteAttendance}
              >
                OK
              </Button>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={modalDeletePopup.onClose}
              >
                Cancel
              </Button>
            </>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
const Statistics = () => {
  const { classAndSections, loading } = useClassAndSectionsWithoutAll();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [singleStudentAttendanceProfile, setSingleStudentAttendanceProfile] =
    useState("");
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const StudentAttendance = useDisclosure();
  const downloadModal = useDisclosure();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);
  const [oneStatus, setOneStatus] = useState([]);
  const [previousStatus, setPreviousStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState({
    academic: true,
    student: false,
  });
  const initialFieldsState = {
    name: true,
    class: true,
    section: true,
    totalDays: true,
    startDate: false,
    endDate: false,
    percentage: true,
    addedDays: true,
    halfDay: false,
    absent: true,
    present: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const currentYear = new Date().getFullYear();
  const calendarRef = useRef(null);
  const pastYears = 1;
  const futureYears = 3;
  const years = [];
  for (let i = currentYear - pastYears; i <= currentYear + futureYears; i++) {
    years.push(i);
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };
  const updateCalendar = (year, month) => {
    const calendarApi = calendarRef?.current?.getApi?.();
    const newDate = new Date(year, month);
    if (!isNaN(newDate.getTime())) {
      calendarApi?.gotoDate?.(newDate);
    } else {
      console.error("Invalid date");
    }
  };
  const handlePreviousMonth = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleNextMonth = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    setSelectedDate(null);
    updateCalendar(newYear, newMonth);
  };
  const handleMonthChange = (e) => {
    const month = Number(e.target.value);
    setSelectedMonth(month);
    setSelectedDate(null);
    updateCalendar(selectedYear, month);
  };
  const handleYearChange = (e) => {
    const year = Number(e.target.value);
    setSelectedYear(year);
    setSelectedDate(null);
    updateCalendar(year, selectedMonth);
  };
  const renderHeader = () => {
    const monthName = new Date(
      selectedYear,
      selectedMonth,
      1
    ).toLocaleDateString("default", { month: "long" });
    const yearString = selectedYear.toString();
    const headerText = `${monthName} ${yearString}`;
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <select
            className={selectedMonth === "" ? "" : Styles.selected}
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              fontSize: selectedMonth !== "" ? "1.5em" : "inherit",
              paddingLeft: "20px",
            }}
          >
            {months.map((month, index) => (
              <option
                value={index}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {month}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            className={selectedYear === "" ? "" : Styles.selected}
            value={selectedYear}
            onChange={handleYearChange}
            style={{
              fontSize: selectedMonth !== "" ? "1.5em" : "inherit",
              paddingLeft: "8px",
            }}
          >
            {years.map((year, index) => (
              <option
                value={year}
                key={index}
                style={{
                  fontSize: "0.7em",
                }}
              >
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const handleTodayButtonClick = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();
    if (
      selectedYear === year &&
      selectedMonth === month &&
      selectedDate &&
      selectedDate.getDate() === date
    ) {
      return;
    }
    setSelectedDate(today);
    setSelectedMonth(month);
    setSelectedYear(year);
  };
  const isToday = () => {
    const today = new Date();
    return (
      selectedYear === today.getFullYear() &&
      selectedMonth === today.getMonth() &&
      selectedDate &&
      selectedDate.getDate() === today.getDate()
    );
  };
  useEffect(() => {
    updateCalendar(selectedYear, selectedMonth);
    setTimeout(() => {
      calendarRef?.current?.getApi().updateSize();
    }, 0);
  }, [selectedMonth, selectedYear]);
  const dayHeaderContent = (arg) => {
    const { text, date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;

    return {
      html: `<div style="color: ${isSunday ? "red" : "inherit"}">${
        isSunday ? "Sun" : text
      }</div>`,
    };
  };
  const dayCellContent = (arg) => {
    const { date } = arg;
    const day = date.getDay();
    const isSunday = day === 0;
    const isSelected =
      selectedDate && date.getTime() === selectedDate.getTime();
    const style = {
      color: isSunday ? "red" : isSelected ? "#4e13c8" : "inherit",
      padding: "2px",
      height: "5px",
    };
    return {
      html: `
              <div style="color: ${style.color}; background-color: ${
        style.backgroundColor
      }; padding: ${
        style.padding
      }; display: flex; flex-direction: column; justify-content: flex-start">
                <div class="${Styles.date}">${date.getDate()}</div>
              </div>
            `,
    };
  };
  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    if (clickedDate < new Date(2023, 5, 1)) {
      toast({
        title: "The dates beyond the session is invalid",
        description: "The academic session date range is startdate- end date. ",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    const monthDiff = clickedDate.getMonth() - selectedMonth;
    const yearDiff = clickedDate.getFullYear() - selectedYear;
    const newMonth = selectedMonth + monthDiff;
    const newYear = selectedYear + yearDiff;
    setSelectedDate(clickedDate);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    calendarRef.current.getApi().unselect();
    setTimeout(() => {
      calendarRef.current.getApi().updateSize();
    }, 0);
  };
  useEffect(() => {
    fetchStudents({ saveDefault: true });
    fetchStudents();
    let input_data = localStorage.getItem("student_filter2")
      ? JSON.parse(localStorage.getItem("student_filter2"))
      : { class: "1", section: "A", studentName: "" };
    setInputFields(input_data);
    if (input_data.studentName !== "") {
      setShowSearchIcon(false);
    }
  }, []);
  useEffect(() => {
    if (isClassQuery || isSectionQuery || inputFields.studentName !== "") {
      localStorage.setItem("student_filter2", JSON.stringify(inputFields));
      searchStudentByQuery();
    }
  }, [inputFields.class, inputFields.section]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const searchStudentByQuery = debounce(function () {
    fetchStudents();
  }, 500);
  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };
  const fetchStudents = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const fetchStudentsWithoutName = async ({ saveDefault } = {}) => {
    try {
      setRequestInProgress(true);
      const payload = {
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const response = !saveDefault
        ? await requestSearchStudentByFilterCommon(payload)
        : {};
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.studentName !== "") {
      localStorage.setItem("student_filter2", JSON.stringify(inputFields));
      fetchStudents();
      setShowSearchIcon(false);
    }
  };
  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("student_filter2")) || {};
    localStorageData.studentName = "";
    localStorage.setItem("student_filter2", JSON.stringify(localStorageData));
    setInputFields((prev) => ({
      ...prev,
      studentName: "",
    }));
    fetchStudentsWithoutName();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";
  const studentList = isQueried ? searchResultList : students;
  const totalStudentsCount = studentList.length;
  const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const getOneStudentStat = async () => {
    const payload = {
      student_id: singleStudentAttendanceProfile._id,
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
    };
    const res = await requestStatsOne(payload);
    setOneStatus(res);
  };
  const handleStatusChange = (event) => {
    setPreviousStatus(selectedStatus);
    const newSelectedStatus = event.target.value;
    setSelectedStatus(event.target.value);
    if (newSelectedStatus !== selectedStatus) {
      setIsStatusChanged(true);
    } else {
      setIsStatusChanged(false);
    }
  };
  const handleHolidays = async () => {
    const payload = {
      month: new Date(0, selectedMonth).toLocaleString("default", {
        month: "long",
      }),
    };
    const response = await requestHolidays(payload);
    setHolidays(response);
  };
  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const formattedHolidays = holidays
      ? holidays.map((date) => new Date(date).toISOString().split("T")[0])
      : [];
    const formattedMorningPresent = oneStatus.morning_present_array
      ? oneStatus.morning_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAfternoonPresent = oneStatus.afternoon_present_array
      ? oneStatus.afternoon_present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedPresent = oneStatus.present_array
      ? oneStatus.present_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const formattedAbsent = oneStatus.absent_array
      ? oneStatus.absent_array.map(
          (date) => new Date(date).toISOString().split("T")[0]
        )
      : [];
    const highlightDates = () => {
      const dayEls = calendarApi.el.querySelectorAll(".fc-daygrid-day");
      dayEls.forEach((dayEl) => {
        const date = dayEl.getAttribute("data-date");
        const dayOfWeek = new Date(date).getDay();
        if (formattedHolidays.includes(date)) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (dayOfWeek === 0) {
          dayEl.style.backgroundColor = "#9c9695";
          dayEl.style.color = "black";
        } else if (formattedMorningPresent.includes(date)) {
          dayEl.style.backgroundColor = "orange";
          dayEl.style.color = "white";
        } else if (formattedAfternoonPresent.includes(date)) {
          dayEl.style.backgroundColor = "orange";
          dayEl.style.color = "white";
        } else if (formattedPresent.includes(date)) {
          dayEl.style.backgroundColor = "rgb(51 190 51)";
          dayEl.style.color = "white";
        } else if (formattedAbsent.includes(date)) {
          dayEl.style.backgroundColor = "red";
          dayEl.style.color = "white";
        } else {
          dayEl.style.backgroundColor = "";
          dayEl.style.color = "";
        }
      });
    };
    calendarApi?.batchRendering(() => {
      highlightDates();
      calendarApi?.render();
    });
  }, [selectedMonth, holidays, oneStatus]);
  useEffect(() => {
    if (selectedMonth && singleStudentAttendanceProfile._id) {
      handleHolidays();
      getOneStudentStat();
      setHolidays(null);
    }
  }, [selectedMonth, singleStudentAttendanceProfile._id]);
  useEffect(() => {
    if (oneStatus) {
      if (
        oneStatus.present_array &&
        oneStatus.present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("present");
      } else if (
        oneStatus.morning_present_array &&
        oneStatus.morning_present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("morning-present");
      } else if (
        oneStatus.afternoon_present_array &&
        oneStatus.afternoon_present_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("afternoon-present");
      } else if (
        oneStatus.absent_array &&
        oneStatus.absent_array.some(
          (date) =>
            moment(date).format("DD-MM-YYYY") ===
            moment(selectedDate).format("DD-MM-YYYY")
        )
      ) {
        setSelectedStatus("absent");
      } else {
        setSelectedStatus("");
      }
    }
  }, [selectedDate, oneStatus]);
  const handleUpdateStatus = async () => {
    const payload = {
      student_id: singleStudentAttendanceProfile._id,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      status: selectedStatus,
    };
    const response = await requestUpdateOne(payload);
    toast({
      title: response.msg,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom-right",
    });
    setIsStatusChanged(false);
    getOneStudentStat();
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-Attendance-Statistics-${inputFields.class}-${inputFields.section}`
      : "SMU-Attendance-Statistics";
  const ExportToExcel = ({ apiData, fileName }) => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        <Tooltip label="Download Student Details">
          <Button colorScheme="green">Archive</Button>
        </Tooltip>
      </div>
    );
  };
  const handleExportClick = async () => {
    const payload = {
      ...(isClassQuery ? { class: inputFields.class.toLocaleUpperCase() } : {}),
      ...(isSectionQuery
        ? { section: inputFields.section.toLocaleUpperCase() }
        : {}),
    };
    const res = await requestStatisticsStudentDetails(payload);
    if (res) {
      let newArray = res.flatMap((item) => {
        return item.data.map((studentInfo) => {
          let obj = {};
          if (fields.class) {
            obj["Class"] = studentInfo.class;
          }
          if (fields.section) {
            obj["Section"] = studentInfo.section;
          }
          if (fields.name) {
            obj["Name"] = studentInfo.student_name;
          }
          if (fields.totalDays) {
            obj["Total Days"] = item.index.total_days;
          }
          if (fields.startDate) {
            obj["Start-Date"] = moment(item.index.start_date).format(
              "DD-MM-YYYY"
            );
          }
          if (fields.endDate) {
            obj["End-Date"] = moment(item.index.end_date).format("DD-MM-YYYY");
          }
          if (fields.percentage) {
            obj["Percentage"] = studentInfo.attendance_percentage;
          }
          if (fields.addedDays) {
            obj["Attendance Added Days"] = item.index.exec_days;
          }
          if (fields.halfDay) {
            obj["Total Halfday"] = studentInfo.half_day_present;
          }
          if (fields.absent) {
            obj["Total Absent"] = studentInfo.total_absent_count;
          }
          if (fields.present) {
            obj["Total Present"] = studentInfo.total_present_count;
          }
          return obj;
        });
      });

      setData(newArray);

      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }

      toast({
        title: "Downloaded Successfully.",
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });

      setFields(initialFieldsState);
      setShowDetails((prevDetails) => ({
        academic: true,
        student: false,
      }));
      setSelectedFileFormat("xlsx");
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      academic: true,
      student: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>Total Students : {studentList.length}</p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.headerFilterWrapper}>
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative", marginLeft: "20px" }}>
                  <Input
                    name="studentName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields.studentName}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    onChange={handleInputChange}
                    style={{ width: "100%" }}
                  />
                  {showSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class ? inputFields.class : ""}
                onChange={handleInputChange}
                name="class"
                placeholder="Select Grade"
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.section ? inputFields.section : ""}
                onChange={handleInputChange}
                name="section"
                placeholder="Select Section"
              >
                {sections
                  ? sections.map((section, idx) => (
                      <option key={section + idx} value={section}>
                        Section {section}
                      </option>
                    ))
                  : ""}
              </Select>
            </div>
            {studentList.length >= 1 ? (
              <ExportToExcel apiData={data} fileName={fileName} />
            ) : null}
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No result found</div>
          ) : (
            studentList.map((student, idx) => (
              <Link
                className={Styles.list}
                to={student._id}
                state={{ studentId: student._id }}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={student.url != "N/A" ? student.url : defaultUser}
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>
                    {truncateText(student.name || "--", 20)}
                  </p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <Modal
        isOpen={StudentAttendance.isOpen}
        onClose={() => {
          StudentAttendance.onClose();
          setSelectedDate(null);
          setSelectedStatus("");
          setSingleStudentAttendanceProfile("");
          setIsStatusChanged(false);
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          position="fixed"
          top="0"
          transform="translateX(-50%)"
          maxHeight="91vh"
          className={Styles.customModalContent}
        >
          <ModalHeader>
            <p className={Styles.studentName}>Student Attendance Report</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={Styles.profileModal}>
                  <img
                    className={Styles.modalProfileImg}
                    src={
                      singleStudentAttendanceProfile.url != "N/A"
                        ? singleStudentAttendanceProfile.url
                        : defaultUser
                    }
                    alt="student-profile"
                  />
                  <div>
                    <p className={Styles.studentName}>
                      <span style={{ fontWeight: 500 }}>Name :</span>{" "}
                      {singleStudentAttendanceProfile.name}
                    </p>
                    <p className={Styles.studentName}>
                      <span style={{ fontWeight: 500 }}>Grade :</span>{" "}
                      {(singleStudentAttendanceProfile?.academic?.class &&
                        singleStudentAttendanceProfile?.academic?.class) ||
                        "--"}
                      |{" "}
                      {singleStudentAttendanceProfile.academic?.section?.toUpperCase() ||
                        "--"}
                    </p>
                    <p className={Styles.studentName}>
                      <span style={{ fontWeight: 500 }}>Phone No. :</span>{" "}
                      {singleStudentAttendanceProfile.phoneNumber}
                    </p>
                  </div>
                </div>
                <Tooltip label="Academic Attendance Summary" placement="top">
                  <div
                    style={{
                      display: "flex",
                      background: "white",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      borderRadius: "5px",
                      flexDirection: "column",
                      width: "55%",
                      padding: "24px",
                      height: "100px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <p style={{ fontWeight: 800 }}>
                        Attendance Percentage :&nbsp;
                        <Tooltip
                          label={`Total Present : ${oneStatus.attendance_final_present}, Total Absent : ${oneStatus.attendance_final_absent}`}
                        >
                          <span
                            style={{
                              backgroundColor:
                                oneStatus.attendance_percentage > 85
                                  ? "green"
                                  : oneStatus.attendance_percentage >= 75 &&
                                    oneStatus.attendance_percentage <= 84
                                  ? "orange"
                                  : "red",
                              padding: "3px",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "20px",
                            }}
                          >
                            {oneStatus.attendance_percentage !== undefined &&
                            oneStatus.attendance_percentage !== null
                              ? oneStatus.attendance_percentage % 1 !== 0
                                ? oneStatus.attendance_percentage.toFixed(2)
                                : oneStatus.attendance_percentage.toFixed(0)
                              : ""}
                            %
                          </span>
                        </Tooltip>
                      </p>
                      <p>Total Added Days : {oneStatus?.total_count}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      <p>
                        Absent :
                        <span style={{ color: "red" }}>
                          &nbsp;{oneStatus.total_absent_count}
                        </span>
                      </p>
                      <p>
                        Present :
                        <span style={{ color: "green" }}>
                          &nbsp;{oneStatus.total_present_count}
                        </span>
                      </p>
                      <p>Half Day Present : {oneStatus.total_internal_count}</p>
                    </div>
                  </div>
                </Tooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "white",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  marginTop: "10px",
                  padding: "8px",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  <p>
                    Academic Session :{" "}
                    {moment(oneStatus.start_date).format("DD/MM/YYYY")}-
                    {moment(oneStatus.end_date).format("DD/MM/YYYY")}
                  </p>
                  <p>
                    Mid-Term Date :{" "}
                    {moment(oneStatus.mid_term_date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  <p>Total Session Days : {oneStatus.total_days}</p>
                  <p>Mid-Term Days : {oneStatus.mid_term_days}</p>
                </div>
              </div>
              <Tooltip label="Monthly Attendance Summary" placement="bottom">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    marginTop: "10px",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  <p>
                    Attendance Entered Days :{" "}
                    <span>
                      {oneStatus.absent_array?.length +
                        oneStatus.present_array?.length +
                        oneStatus.morning_present_array?.length +
                        oneStatus.afternoon_present_array?.length}
                    </span>
                  </p>
                  <p>
                    Absent :{" "}
                    <span style={{ color: "red" }}>
                      {oneStatus.absent_array?.length}
                    </span>
                  </p>
                  <p>
                    Present :{" "}
                    <span style={{ color: "green" }}>
                      {oneStatus.present_array?.length}
                    </span>
                  </p>
                  <p>
                    Half Day Present :{" "}
                    {oneStatus.morning_present_array?.length +
                      oneStatus.afternoon_present_array?.length}
                  </p>
                </div>
              </Tooltip>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                }}
              >
                <div className={Styles.modalCalendar}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid black",
                      padding: "5px",
                    }}
                  >
                    <div>
                      <button onClick={handlePreviousMonth}>
                        <FaCaretSquareLeft style={{ fontSize: "30px" }} />
                      </button>
                    </div>
                    <div>
                      {renderHeader()} {/* Display the month and year */}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        className={Styles.todayButton}
                        onClick={handleTodayButtonClick}
                        style={{ display: isToday() ? "none" : "block" }}
                      >
                        Today
                      </button>
                      <button onClick={handleNextMonth}>
                        <FaCaretSquareRight style={{ fontSize: "30px" }} />
                      </button>
                    </div>
                  </div>
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    selectable={true}
                    dateClick={handleDateClick}
                    dayCellContent={dayCellContent}
                    dayHeaderContent={dayHeaderContent}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "white",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      padding: "8px",
                      fontWeight: "bold",
                      marginLeft: "20px",
                      height: "220px",
                      fontSize: "16px",
                    }}
                  >
                    <div style={{ marginBottom: "10px", fontWeight: 800 }}>
                      Date : &nbsp;
                      {selectedDate &&
                        moment(selectedDate).format("DD-MM-YYYY")}
                    </div>

                    {!selectedDate ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "50px",
                          backgroundColor: "#ea3c53",
                          width: "180px",
                          color: "white",
                          padding: "10px",
                          marginLeft: "100px",
                          fontWeight: "bold",
                        }}
                      >
                        No Date Selected
                      </div>
                    ) : oneStatus &&
                      (oneStatus.present_array.some(
                        (date) =>
                          moment(date).format("DD-MM-YYYY") ===
                          moment(selectedDate).format("DD-MM-YYYY")
                      ) ||
                        oneStatus.morning_present_array.some(
                          (date) =>
                            moment(date).format("DD-MM-YYYY") ===
                            moment(selectedDate).format("DD-MM-YYYY")
                        ) ||
                        oneStatus.afternoon_present_array.some(
                          (date) =>
                            moment(date).format("DD-MM-YYYY") ===
                            moment(selectedDate).format("DD-MM-YYYY")
                        ) ||
                        oneStatus.absent_array.some(
                          (date) =>
                            moment(date).format("DD-MM-YYYY") ===
                            moment(selectedDate).format("DD-MM-YYYY")
                        )) ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "60%",
                              marginBottom: "10px",
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                style={{ marginRight: 10 }}
                                checked={selectedStatus === "present"}
                                onChange={handleStatusChange}
                                value="present"
                              />
                              Present
                            </label>
                          </div>
                          <div
                            style={{
                              width: "60%",
                              marginBottom: "10px",
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                style={{ marginRight: 10 }}
                                checked={selectedStatus === "morning-present"}
                                onChange={handleStatusChange}
                                value="morning-present"
                              />
                              Morning Present
                            </label>
                          </div>
                          <div
                            style={{
                              width: "60%",
                              marginBottom: "10px",
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                style={{ marginRight: 10 }}
                                checked={selectedStatus === "afternoon-present"}
                                onChange={handleStatusChange}
                                value="afternoon-present"
                              />
                              Afternoon Present
                            </label>
                          </div>
                          <div
                            style={{
                              width: "60%",
                              marginBottom: "10px",
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                style={{ marginRight: 10 }}
                                checked={selectedStatus === "absent"}
                                onChange={handleStatusChange}
                                value="absent"
                              />
                              Absent
                            </label>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "#228B15",
                              color: "white",
                              borderRadius: "5px",
                              padding: "5px",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              if (isStatusChanged) {
                                handleUpdateStatus();
                              }
                            }}
                            disabled={!isStatusChanged}
                          >
                            Update Status
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "50px",
                          backgroundColor: "#ea3c53",
                          width: "180px",
                          color: "white",
                          padding: "10px",
                          marginLeft: "100px",
                          fontWeight: "bold",
                        }}
                      >
                        Invalid Date
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: " flex",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      marginTop: "15px",
                      padding: "8px",
                      fontWeight: "bold",
                      marginLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: " flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "orange",
                          marginRight: "5px",
                        }}
                      ></div>
                      <p>Morning Present</p>
                    </div>
                    <div
                      style={{
                        display: " flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "orange",
                          marginRight: "5px",
                        }}
                      ></div>
                      <p>Afternoon Present</p>
                    </div>
                    <div
                      style={{
                        display: " flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "rgb(51 190 51)",
                          marginRight: "10px",
                        }}
                      ></div>
                      <p>Present</p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: " flex",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      marginTop: "15px",
                      padding: "8px",
                      fontWeight: "bold",
                      marginLeft: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: " flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "red",
                          marginRight: "5px",
                        }}
                      ></div>
                      <p>Absent</p>
                    </div>
                    <div
                      style={{
                        display: " flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#9c9695",
                          marginRight: "5px",
                        }}
                      ></div>
                      <p>Holiday / Sunday </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={downloadModal.isOpen}
        onClose={() => {
          downloadModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Academic Session Details
                  </button>
                  {showDetails.academic ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("academic")}
                    />
                  )}
                </div>
                {showDetails.academic && (
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.startDate}
                      onChange={() => {
                        setFields({
                          ...fields,
                          startDate: !fields.startDate,
                        });
                      }}
                    >
                      Start Date
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.endDate}
                      onChange={() => {
                        setFields({
                          ...fields,
                          endDate: !fields.endDate,
                        });
                      }}
                    >
                      End Date
                    </Checkbox>
                  </div>
                )}
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Student Details
                  </button>
                  {showDetails.student ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("student")}
                    />
                  )}
                </div>
                {showDetails.student && (
                  <>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.addedDays}
                        onChange={() => {
                          setFields({
                            ...fields,
                            addedDays: !fields.addedDays,
                          });
                        }}
                      >
                        Attendance Added Days
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.absent}
                        onChange={() => {
                          setFields({
                            ...fields,
                            absent: !fields.absent,
                          });
                        }}
                      >
                        Total Absent
                      </Checkbox>
                    </div>
                    <div className={Styles.row}>
                      <Checkbox
                        isChecked={fields.halfDay}
                        onChange={() => {
                          setFields({
                            ...fields,
                            halfDay: !fields.halfDay,
                          });
                        }}
                      >
                        Total Halfday
                      </Checkbox>
                      <Checkbox
                        isChecked={fields.present}
                        onChange={() => {
                          setFields({
                            ...fields,
                            present: !fields.present,
                          });
                        }}
                      >
                        Total Present
                      </Checkbox>
                    </div>
                  </>
                )}
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import defaultUser from "assets/icons/User.png";
import { requestClasses } from "services/student-dashboard/classroom.service";
import Styles from "./Classroom.module.scss";
import { Spinner } from "@chakra-ui/react";
const StudentClassroom = () => {
  const [classData, setClassData] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleClasses = async () => {
    setRequestInProgress(true);
    try {
      const response = await requestClasses();
      setClassData(response);
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    handleClasses();
  }, []);
  if (requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={Styles.body}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>
          Class : {classData?.subjects?.class} | Section :{" "}
          {classData?.subjects?.section}
        </p>
      </div>
      <div>
        <h1 className={Styles.heading}>Class Teacher</h1>
        <div className={Styles.Classlists}>
          <div className={Styles.list}>
            <div className={Styles.profile}>
              <img
                className={Styles.profileImg}
                src={
                  classData?.class_teacher?.teacher_profile_url !== "N/A"
                    ? classData?.class_teacher?.teacher_profile_url
                    : defaultUser
                }
                alt="profile"
              />
            </div>
            <div className={Styles.info}>
              <p className={Styles.name}>{classData?.class_teacher?.name}</p>
            </div>
          </div>
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Regular Subjects({classData?.subjects?.subjects?.length})
        </h1>
        <div className={Styles.lists}>
          {classData?.subjects?.subjects?.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            classData?.subjects?.subjects?.map((subj, index) => (
              <div key={subj["teacher_id"] + index} className={Styles.list}>
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={
                      subj.teacher_profile_url !== "N/A"
                        ? subj.teacher_profile_url
                        : defaultUser
                    }
                    alt="profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>{subj.teacher_name}</p>
                  <hr
                    style={{ color: "red", borderColor: "red", height: "10px" }}
                  />
                  <p className={Styles.class}>{subj.subject}</p>
                </div>
              </div>
            ))
          )}
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Elective Subjects({classData?.subjects?.electives?.length})
        </h1>
        <div className={Styles.electives}>
          {classData &&
          classData.subjects &&
          classData.subjects.electives &&
          classData.subjects.electives.length > 0 ? (
            classData.subjects.electives.map((elective) => {
              return (
                <div
                  className={Styles.electives_container}
                  key={elective.elective_id}
                >
                  <div className={Styles.elecBox}>
                    <h1 className={Styles.elective_heading}>
                      {elective.elective_name}
                    </h1>
                  </div>
                  <hr
                    style={{
                      color: "red",
                      borderColor: "black",
                      height: "10px",
                    }}
                  />
                  {elective.subjects.map((subject) => {
                    return (
                      <div
                        className={Styles.lists_elective}
                        key={subject.subject}
                      >
                        <div className={Styles.list}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                subject?.teacher_profile_url !== "N/A"
                                  ? subject?.teacher_profile_url
                                  : defaultUser
                              }
                              alt="profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {subject.teacher_name}
                            </p>
                            <hr
                              style={{
                                color: "red",
                                borderColor: "red",
                                height: "10px",
                              }}
                            />
                            <p className={Styles.class}>{subject.subject}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className={Styles.noResult}>No Results</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentClassroom;

import { Icons } from "utilities/assets";

const {
  add,
  edit,
  Attachment,
  deleteIcon,
  direction,
  donwload,
  Circular,
  Student,
  Certificate,
  Teacher,
  TimeTable,
  SMS,
  SchoolBus,
  AcademicCalendar,
  Admin,
  Admission,
  Attendance,
  Events,
  Classroom,
  Logout,
  Fees,
  Exams,
  Library,
  RecycleBin,
  Canteen,
  Statistics,
  Backup,
  Permissions,
  Branding,
  Help,
  Notification,
  Report_Centre,
  Assignment,
  Knowledge_Centre,
  Tools,
  Management,
  Managementimg,
} = Icons;
export const DashboardFeatureList = {
  student: [
    {
      id: 16,
      name: "Profile",
      icon: Student,
      path: "profile",
    },
    {
      id: 2,
      name: "Events",
      icon: Events,
      path: "events",
    },
    {
      id: 3,
      name: "Circular",
      icon: Circular,
      path: "circular",
    },
    {
      id: 57,
      name: "Classroom",
      icon: Classroom,
      path: "classroom",
    },
    // {
    //   id: 61,
    //   name: "Assignments",
    //   icon: Assignment,
    //   path: "assignments",
    // },
    // {
    //   id: 62,
    //   name: "Knowledge Centre",
    //   icon: Knowledge_Centre,
    //   path: "knowledgeCentre",
    // },
    {
      id: 4,
      name: "Academic Calendar",
      icon: AcademicCalendar,
      path: "calendar",
    },
    // {
    //   id: 66,
    //   name: "Library",
    //   icon: Library,
    //   path: "library",
    // },
    {
      id: 7,
      name: "Bus Transport",
      icon: SchoolBus,
      path: "transport",
    },
    {
      id: 7,
      name: "Canteen",
      icon: Canteen,
      path: "canteen",
    },
    {
      id: 8,
      name: "Exam Report",
      icon: Exams,
      path: "report",
    },
    {
      id: 6,
      name: "Attendance",
      icon: Attendance,
      path: "attendance",
    },
    {
      id: 5,
      name: "School Fees",
      icon: Fees,
      path: "fee",
    },
    {
      id: 555,
      name: "Timetable",
      icon: TimeTable,
      path: "timetable",
    },

    {
      id: 888,
      name: "Help",
      icon: Help,
      path: "help",
    },
    {
      id: 99,
      name: "Logout",
      icon: Logout,
      path: "logoutall",
    },
  ],
  staff: [
    {
      id: 1,
      name: "Profile",
      icon: Teacher,
      path: "profile",
    },
    {
      id: 18,
      name: "Students",
      icon: Student,
      path: "students",
    },
    {
      id: 2,
      name: "Events",
      icon: Events,
      path: "events",
    },
    {
      id: 3,
      name: "Circular",
      icon: Circular,
      path: "circular",
    },
    {
      id: 58,
      name: "Classroom",
      icon: Classroom,
      path: "classroom",
    },
    {
      id: 60,
      name: "Assignments",
      icon: Assignment,
      path: "assignments",
    },
    {
      id: 64,
      name: "Knowledge Centre",
      icon: Knowledge_Centre,
      path: "knowledgeCentre",
    },
    {
      id: 4,
      name: "Academic Calendar",
      icon: AcademicCalendar,
      path: "calendar",
    },
    {
      id: 5,
      name: "Exam Report",
      icon: Exams,
      path: "exams",
    },
    {
      id: 6,
      name: "Attendance",
      icon: Attendance,
      path: "attendance",
    },
    {
      id: 7,
      name: "Timetable",
      icon: TimeTable,
      path: "timetable",
    },
    {
      id: 8,
      name: "Help",
      icon: Help,
      path: "help",
    },
    {
      id: 999,
      name: "Logout",
      icon: Logout,
      path: "logoutall",
    },
  ],
  admin: [
    {
      id: 18,
      name: "Students",
      icon: Student,
      path: "students",
    },
    {
      id: 2,
      name: "Staff",
      icon: Teacher,
      path: "staff",
    },
    {
      id: 3,
      name: "Admission",
      icon: Admission,
      path: "admission",
    },
    {
      id: 4,
      name: "Events",
      icon: Events,
      path: "events",
    },
    {
      id: 5,
      name: "Circular",
      icon: Circular,
      path: "circular",
    },
    {
      id: 14,
      name: "Classroom",
      icon: Classroom,
      path: "classroom",
    },
    {
      id: 97,
      name: "Report Centre",
      icon: Report_Centre,
      path: "reportCentre",
    },
    {
      id: 6,
      name: "Academic Calendar",
      icon: AcademicCalendar,
      path: "calendar",
    },
    {
      id: 11,
      name: "Library",
      icon: Library,
      path: "library",
    },
    // {
    //   id: 11,
    //   name: "Library Checkout",
    //   icon: Library,
    //   path: "libraryRecord",
    // },
    {
      id: 9,
      name: "Bus Transport",
      icon: SchoolBus,
      path: "transport",
    },
    // {
    //   id: 9,
    //   name: "Transport Records  ",
    //   icon: SchoolBus,
    //   path: "transport",
    // },

    {
      id: 20,
      name: "Canteen",
      icon: Canteen,
      path: "canteen",
    },
    {
      id: 33,
      name: "Permission",
      icon: Permissions,
      path: "permissions",
    },
    {
      id: 10,
      name: "Exam Report",
      icon: Exams,
      path: "report",
    },
    {
      id: 888,
      name: "Certificate",
      icon: Certificate,
      path: "certificate",
    },
    {
      id: 8,
      name: "Attendance",
      icon: Attendance,
      path: "attendance",
    },
    {
      id: 81,
      name: "School Fees",
      icon: Fees,
      path: "fees",
    },

    {
      id: 7,
      name: "Timetable",
      icon: TimeTable,
      path: "timetable",
    },
    {
      id: 13,
      name: "SMS",
      icon: SMS,
      path: "sms",
    },
    {
      id: 223,
      name: "Notification",
      icon: Notification,
      path: "notification",
    },
    {
      id: 88,
      name: "Recycle Bin",
      icon: RecycleBin,
      path: "recylcebin",
    },

    {
      id: 12,
      name: "Admin/ Management",
      icon: Admin,
      path: "admin-management",
    },
    {
      id: 49,
      name: "Backup",
      icon: Backup,
      path: "backup",
    },
    {
      id: 5555,
      name: "Branding",
      icon: Branding,
      path: "branding",
    },
    {
      id: 56,
      name: "Tools",
      icon: Tools,
      path: "tools",
    },
    {
      id: 444,
      name: "Help",
      icon: Help,
      path: "help",
    },

    {
      id: 999,
      name: "Logout",
      icon: Logout,
      path: "logoutall",
    },
  ],
  management: [
    {
      id: 1,
      name: "Profile",
      icon: Managementimg,
      path: "profile",
    },
    {
      id: 15,
      name: "Statistics",
      icon: Statistics,
      path: "statistics",
    },
    {
      id: 111,
      name: "Students",
      icon: Student,
      path: "students",
    },
    {
      id: 2,
      name: "Staff",
      icon: Teacher,
      path: "staff",
    },
    {
      id: 3,
      name: "Admission",
      icon: Admission,
      path: "admission",
    },

    {
      id: 41,
      name: "Events",
      icon: Events,
      path: "events",
    },
    {
      id: 51,
      name: "Circular",
      icon: Circular,
      path: "circular",
    },
    {
      id: 52,
      name: "Classroom",
      icon: Classroom,
      path: "classroom",
    },
    {
      id: 91,
      name: "Report Centre",
      icon: Report_Centre,
      path: "reportCentre",
    },
    {
      id: 6,
      name: "Academic Calendar",
      icon: AcademicCalendar,
      path: "calendar",
    },
    {
      id: 92,
      name: "Bus Transport",
      icon: SchoolBus,
      path: "transport",
    },
    {
      id: 202,
      name: "Canteen",
      icon: Canteen,
      path: "canteen",
    },
    {
      id: 101,
      name: "Exam Report",
      icon: Exams,
      path: "report",
    },
    {
      id: 889,
      name: "Certificate",
      icon: Certificate,
      path: "certificate",
    },
    {
      id: 81,
      name: "Attendance",
      icon: Attendance,
      path: "attendance",
    },

    {
      id: 55,
      name: "School Fees",
      icon: Fees,
      path: "fees",
    },
    {
      id: 82,
      name: "Timetable",
      icon: TimeTable,
      path: "timetable",
    },
    {
      id: 13,
      name: "SMS",
      icon: SMS,
      path: "sms",
    },
    {
      id: 88,
      name: "Recycle Bin",
      icon: RecycleBin,
      path: "recylcebin",
    },
    {
      id: 44,
      name: "Backup",
      icon: Backup,
      path: "backup",
    },
    {
      id: 333,
      name: "Help",
      icon: Help,
      path: "help",
    },
    {
      id: 10,
      name: "Logout",
      icon: Logout,
      path: "logoutall",
    },
  ],
};

import React, { useState } from "react";
import Styles from "./FeePayment.module.scss";
import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Spinner,
  useDisclosure,
  useToast,
  ModalFooter,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import Services from "services/student-dashboard/fee.service";
import moment from "moment";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Fullpaid from "assets/icons/paid-rupee-indian.png"
import Feedocimg from "assets/icons/Full-rupee-indian.png"
import RemainingFee from "assets/icons/due-rupee-indian.png"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
const FeePayment = () => {
  const handlePenalty = useDisclosure();
  const [feepenaltyResponse, setFeePenaltyResponse] = useState("");
  const [feeDetails, setFeeDetails] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const penaltyDetails = async () => {
    try {
      const penaltyRes = await Services.getFeePenaltyDetails();
      if (penaltyRes) {
        setFeePenaltyResponse(penaltyRes.data.penalty_list);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const StudentFeeDetails = async () => {
    try {
      const response = await Services.getStudentFeesDetails();
      if (response) {
        setFeeDetails(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    penaltyDetails();
    StudentFeeDetails();
  }, []);
  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <h1 className={Styles.header}>Fee Details</h1>
        <div className={Styles.add}>
        <Tooltip label="Penalty/Fine">
        <p
          className={Styles.fine}
          onClick={() => handlePenalty.onOpen()}
        >
          P/F
        </p>
        </Tooltip>
        </div>
      </div>
      <p className={Styles.fessFormetdesign}>* All the fee amount are in INR (Indian National Rupee)</p>
      <div className={Styles.paidContainer}>
        <div className={Styles.paidUpper}>
        <div className={Styles.PaidFee}
         style={{background:"rgb(225,239,251)"}}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={Feedocimg} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Total Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
        {feeDetails &&
                feeDetails.fees_metadata.tution_total +
                  feeDetails.fees_metadata.canteen_total +
                  feeDetails.fees_metadata.transport_total}
            </p>
          </div>
        </div>
         
        <div className={Styles.PaidFee} style={{backgroundColor:"#fae0e0e0"}}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={RemainingFee} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Pending Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
        {feeDetails &&
                feeDetails.fees_metadata.tution_total_pending +
                  feeDetails.fees_metadata.canteen_total_pending +
                  feeDetails.fees_metadata.transport_total_pending}
            </p>
           
           
          </div>
        </div>
       
       <div className={Styles.PaidFee} style={{
          // width:"70%",
          backgroundColor:"rgb(101 246 108 / 47%)"
        }}>
        <div style={{display:"flex", alignItems:"center"}}>
        <div className={Styles.feeImages}>
          <img src={Fullpaid} alt="" />
        </div>
        <p style={{marginLeft:"10px"}}>Paid Fee</p>
        </div>
        
        <div className={Styles}>
        <p className={Styles.heading}>
        {feeDetails &&
                +feeDetails.fees_metadata.tution_total_paid +
                  feeDetails.fees_metadata.canteen_total_paid +
                  feeDetails.fees_metadata.transport_total_paid}
            </p>
          </div>
        </div>
        </div>
        </div>
      <div className={Styles.horizontalContainer}>
      <Swiper
        slidesPerView={screenSize&&screenSize?.width>=800?2:screenSize&&screenSize?.width>=550?1.5:1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        // className="mySwiper"
      >
      <SwiperSlide  >
        <div className={Styles.lists}>
          <h2 className={Styles.listHeader}>Tuition Fee</h2>

          <div className={Styles.list}>
            <p className={Styles.name}>Total</p>
            <p className={Styles.value}>
              ₹ {feeDetails && feeDetails.fees_metadata.tution_total}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePaid}>Paid</p>
            <p className={Styles.valuePaid}>
              ₹ {feeDetails && feeDetails.fees_metadata.tution_total_paid}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePending}>Pending</p>
            <p className={Styles.valuePending}>
              ₹ {feeDetails && feeDetails.fees_metadata.tution_total_pending}
            </p>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide  >
        <div className={Styles.lists}>
          <h2 className={Styles.listHeader}>Canteen Fee</h2>

          <div className={Styles.list}>
            <p className={Styles.name}>Total</p>
            <p className={Styles.value}>
              ₹ {feeDetails && feeDetails.fees_metadata.canteen_total}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePaid}>Paid</p>
            <p className={Styles.valuePaid}>
              ₹ {feeDetails && feeDetails.fees_metadata.canteen_total_paid}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePending}>Pending</p>
            <p className={Styles.valuePending}>
              ₹ {feeDetails && feeDetails.fees_metadata.canteen_total_pending}
            </p>
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide  >
        <div className={Styles.lists}>
          <h2 className={Styles.listHeader}>Transport Fee</h2>

          <div className={Styles.list}>
            <p className={Styles.name}>Total</p>
            <p className={Styles.value}>
              ₹ {feeDetails && feeDetails.fees_metadata.transport_total}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePaid}>Paid</p>
            <p className={Styles.valuePaid}>
              ₹ {feeDetails && feeDetails.fees_metadata.transport_total_paid}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePending}>Pending</p>
            <p className={Styles.valuePending}>
              ₹ {feeDetails && feeDetails.fees_metadata.transport_total_pending}
            </p>
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide  >
        <div className={Styles.lists}>
          <h2 className={Styles.listHeader}>Penalty Fee</h2>

          <div className={Styles.list}>
            <p className={Styles.name}>Total</p>
            <p className={Styles.value}>
              ₹ {feeDetails&&feeDetails?.penalty_summary?.total}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePaid}>Paid</p>
            <p className={Styles.valuePaid}>
              ₹ {feeDetails&&feeDetails?.penalty_summary.paid}
            </p>
          </div>
          <div className={Styles.list}>
            <p className={Styles.namePending}>Pending</p>
            <p className={Styles.valuePending}>
              ₹ {feeDetails&&feeDetails?.penalty_summary.pending}
            </p>
          </div>
        </div>
        </SwiperSlide>
        </Swiper>
      </div>

      <h2 className={Styles.subHeading}>Payment Cards</h2>

      <div className={Styles.verticalContainer}>
        {feeDetails &&
          feeDetails.fees.map((data) => {
            return (
              <>
                <div className={Styles.receiptCard}>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Bill Number</p>
                    <p className={Styles.value}>{data.bill_number}</p>
                  </div>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Fees Paid Amount</p>
                    <p className={Styles.value}>₹ {data.total_amount}</p>
                  </div>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Canteen Paid Amount</p>
                    <p className={Styles.value}>₹ {data.canteen_amount}</p>
                  </div>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Transport Paid Amount</p>
                    <p className={Styles.value}>₹ {data.transport_amount}</p>
                  </div>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Tution Paid Amount</p>
                    <p className={Styles.value}>₹ {data.tution_amount}</p>
                  </div>
                  <div className={Styles.receiptCardRow}>
                    <p className={Styles.name1}>Date Paid</p>
                    <p className={Styles.value}>
                      {moment(data.paid_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
       
      </div>

      <Modal
        isOpen={handlePenalty.isOpen}
        onClose={handlePenalty.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div className={Styles.headerSectionModal}>
                <p className={Styles.fineSection1}>S.no</p>
                <p className={Styles.fineSection2}>Penalty/Fine</p>
                <p className={Styles.fineSection2}>Amount</p>
                <p className={Styles.fineSection2}>Date</p>
                <p className={Styles.fineSection2}>Bill no.</p>
                <p className={Styles.fineSection2}>Date of pay</p>
              </div>
              <div>
                {feepenaltyResponse &&
                  feepenaltyResponse.map((fee, id) => {
                    return (
                      <>
                        <div className={Styles.headerSectionModal2}>
                          <p className={Styles.fineSection1}>{id + 1}</p>
                          <p className={Styles.fineSection2}>
                            {fee.penalty_type}
                          </p>
                          <p className={Styles.fineSection2}>{fee.amount}</p>
                          <p className={Styles.fineSection2}>
                            {fee.date == null
                              ? "-"
                              : moment(fee.date).format("DD/MM/YYYY")}
                          </p>
                          <p className={Styles.fineSection2}>
                            {fee.bill_number}
                          </p>
                          <p className={Styles.fineSection2}>
                            {fee.payment_pay_date == null
                              ? "-"
                              : moment(fee.payment_pay_date).format(
                                  "DD/MM/YYYY"
                                )}
                          </p>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          ></ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FeePayment;

import axios from 'axios'
import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

export async function certificateDownload (payload={}) {
const url = BASE_URL + "/admin/certificate/create"
try{
    const response = await axios.post(url,{...payload},getRequestOptions())
    if(response.data.error){
        return{error:response.data.error}
    }
    if(response.status >= 200 && response.status<400 && response.data){
        return response.data
    }
}catch(e){
    if(e?.response?.data?.error){
        throw e.response.data.error
    }
    if (e?.response?.data){
        throw e.response.data
    }
    throw e
}
}

const certificateService = {
    certificateDownload
}
export default certificateService
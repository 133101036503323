import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import "react-datepicker/dist/react-datepicker.css";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import Cerificateservice from "services/admin-dashboard/certificate.service";
import { useToast } from "@chakra-ui/react";
// import "./"
// import {ref, getDownloadURL, uploadBytesResumable,getStorage} from "firebase/storage"
// import {storage} from "../../../config/firebaseInitialize"
// import 'firebase/storage';
import "./TcCertificate.module.scss";
import Header from "../CertificatePdfs/BonafideHeader/Header";
import A3Header from "./Header/Header";
import jsPDF from "jspdf";
import moment from "moment";
import { ToWords } from "to-words";
import Styles from "./TcCertificate.module.scss";
import backimg from "assets/logos/SMU_Watermark3.png";
const ManagementTcCertificate = () => {
  const params = useParams();
  const [inputFields, setInputFields] = useState(null);
  const [data, setdata] = useState(null);
  const [certificateType, setcertificateType] = useState(null);
  const [moreData, setMoreData] = useState("");
  const [tcFormetSize, setTcFormetSize] = useState(false);
  const [edit, setEdit] = useState(false);
  const toast = useToast();
  // const location = useLocation()

  const location = useLocation();
  console.log("location", location);
  // const moreData = location.state.transferInfo
  const fetchStudentProfileDetails = async () => {
    try {
      const response = await requestStudentDetailsById({
        studentId: location.state.id,
      });
      setdata(response);
      if (response.error) {
        console.error(response.error);
      }

      console.log(data);
      // const classroom_response = await requestAllSubjects({ class_id: response._class_id });
      // console.log('calss', classroom_response)
      // if (response && classroom_response) {
      if (response) {
        console.log("student resp", response);
        const DOB = response?.personel?.dob;
        let dob = DOB && new Date(DOB).toLocaleDateString();
        if (dob == "Invalid Date") {
          if (DOB.includes("-")) {
            dob = new Date(DOB.split("-").reverse().join("/"));
          } else if (DOB.includes("/")) {
            const [day, month, year] = DOB.split("/");
            const dateString = [month, day, year].join("/");
            dob = new Date(dateString);
          }
        } else {
          if (typeof new Date(DOB) === "object") {
            dob = new Date(DOB);
          } else {
            dob = DOB;
          }
        }

        const _response = {
          ...response,
          name: response.name,
          email: response.email,
          personel: {
            ...response.personel,
            dob,
            gender: response.personel.gender,
            present_address: response.personel.present_address,
            permanent_address: response.personel.permanent_address,
            mother_tongue: response.personel.mother_tongue,
            religion: response.personel.religion,
            sub_religion: response.personel.sub_religion,
            blood_group: response.personel.blood_group,
            aadhar_number: response.personel.aadhar_number,
            nationlity: response.personel.nationlity,
            signed_display_image: await getSignedUrlLink(
              response.personel.profile_url
            ),
          },
          academic: {
            ...response.academic,
            class: response.academic.class,
            section: response.academic.section,
            admission_number: response.academic.admission_number,
            admission_year: response.academic.admission_year,
          },
          father: {
            ...response.father,
            father_name: response.father.father_name,
            father_email: response.father.father_email,
            father_phone: response.father.father_phone,
            father_occupation: response.father.father_occupation,
            father_qualification: response.father.father_qualification,
            signed_father_display_image: await getSignedUrlLink(
              response.father.father_photo_url
            ),
          },
          mother: {
            ...response.mother,
            mother_name: response.mother.mother_name,
            mother_phone: response.mother.mother_phone,
            mother_occupation: response.mother.mother_occupation,
            mother_qualification: response.mother.mother_qualification,
            signed_mother_display_image: await getSignedUrlLink(
              response.mother.mother_photo_url
            ),
          },
          canteen_choices: response.canteen_choices,
          account_status: response.account_status,
          transport_choices: response.transport_choices,
          // electives: classroom_response.electives,
          subject_choices: response.subject_choices,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  console.log("inputfeilds", inputFields);

  useEffect(() => {
    fetchStudentProfileDetails();
    setcertificateType(location.state.data);
    setMoreData(location.state.transferInfo);
  }, []);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const A4handleCertiDownload = async () => {
    console.log("download function called size a3");

    var view = new jsPDF("portrait", "pt", "a4");

    let pdf = await document.getElementById("pdfcerti");
    let downloadResponse = await view
      .html(pdf)
      .then(() => {
        view.save(`${inputFields?.name} Transfer Certificate.pdf`);
        setTcFormetSize("");
        // PdfRequestUpdate()
      })
      .catch((err) => console.log(err, "err"));
    // uploadPdfToFirebase()
    // const pdfBlob  = view.output('blob');

    // const pdfBlob = await handleCertiDownload()
    // const storageRef = ref(getStorage,"handleCertiDownload");
    //     const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

    //     uploadTask.on("state_changed",
    //         (snapshot) => {
    //             const progress =
    //                 Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

    //         },
    // )
    // const storageRef = firebasepropery();
    // var pdfRef = storageRef.child('pdfs/my-pdf.pdf');
    // pdfRef.put(pdfBlob).then(function(snapshot) {
    //   console.log('Uploaded PDF successfully!');
    // });
  };
  const A3handleCertiDownload = async () => {
    // console.log("download function called size a3")

    var view = new jsPDF("portrait", "pt", "a3");

    let pdf = await document.getElementById("pdfcerti");
    let downloadResponse = await view
      .html(pdf)
      .then(() => {
        view.save(`${inputFields?.name} Transfer Certificate.pdf`);
        setTcFormetSize("");
        // PdfRequestUpdate()
      })
      .catch((err) => console.log(err, "err"));
    // uploadPdfToFirebase()
    // const pdfBlob  = view.output('blob');

    // const pdfBlob = await handleCertiDownload()
    // const storageRef = ref(getStorage,"handleCertiDownload");
    //     const uploadTask = uploadBytesResumable(storageRef, pdfBlob);

    //     uploadTask.on("state_changed",
    //         (snapshot) => {
    //             const progress =
    //                 Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

    //         },
    // )
    // const storageRef = firebasepropery();
    // var pdfRef = storageRef.child('pdfs/my-pdf.pdf');
    // pdfRef.put(pdfBlob).then(function(snapshot) {
    //   console.log('Uploaded PDF successfully!');
    // });
  };
  const PdfRequestUpdate = async () => {
    let certificateData = {
      certificate_name: certificateType,
      issue_date: currentDate,
      student_id: inputFields?._id,
    };
    const response = await Cerificateservice.certificateDownload(
      certificateData
    );
    console.log("response", response);
  };
  const formattedDate = moment(inputFields?.personel?.dob, "MM/DD/YYYY").format(
    "DD/MM/YYYY"
  );

  const toWords = new ToWords();
  if (inputFields?.personel?.dob) {
    const convertedYear = moment(
      inputFields?.personel?.dob,
      "MM/DD/YYYY"
    ).format("YYYY");
    var wordsYear = toWords.convert(convertedYear);
  }
  if (inputFields?.personel?.dob) {
    const convertedDay = moment(
      inputFields?.personel?.dob && inputFields?.personel?.dob,
      "MM/DD/YYYY"
    ).format("DD");
    var wordsDay = toWords.convert(convertedDay);
  }
  if (inputFields?.personel?.dob) {
    const d = moment(
      inputFields?.personel?.dob && inputFields?.personel?.dob,
      "MM/DD/YYYY"
    ).format("MMMM");
    var monthName = d;
  }

  // console.log("TcFormetSize",tcFormetSize)
  const valueChange = (e) => {
    setMoreData({ ...moreData, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };
  const editMode = () => {
    toast({
      title: "Edit",
      description: "Now you can edit Certificate",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
    setEdit(true);
  };
  const saveEdit = () => {
    toast({
      title: "Save",
      description: "Certificate edit saved ",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
    setEdit(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          // marginLeft: "5vw",
          // flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        {tcFormetSize == false ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "64%",
              marginLeft: "1vw",
              // display:"none"
            }}
            id="pdfcerti"
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div className={Styles.backgroundUrl}>
                  <Header />
                  <div style={{ marginTop: ".5vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.1rem",
                          textAlign: "center",
                          fontWeight: 700,
                          marginTop: ".5vw",
                          fontFamily: "sans",
                          borderBottom: "1px solid black",
                          paddingBottom: "5px",
                        }}
                      >
                        TRANSFER CERTIFICATE
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: ".5vw",
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>Admission No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            borderBottom: "1px solid ",
                            borderColor: "#00000059",
                            paddingBottom: "3px",
                            fontSize: ".65rem",
                          }}
                        >
                          {inputFields?.academic?.admission_number}
                        </span>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>STS &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.STSNo ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="STSNo"
                                value={moreData.STSNo}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                              >
                                {moreData.STSNo} &nbsp;&nbsp;&nbsp;
                              </p>
                            )
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>TC &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.TCNo ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="TCNo"
                                value={moreData.TCNo}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                              >
                                {moreData.TCNo} &nbsp;&nbsp;&nbsp;
                              </p>
                            )
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          1 Name of Pupil
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid ",
                            fontSize: ".65rem",
                            borderColor: "#00000059",
                            paddingBottom: "3px",
                            wordSpacing: "7px",
                          }}
                        >
                          {inputFields?.name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          2 Sex
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".65rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.personel?.gender}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          3 Mother's Name
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".65rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.mother?.mother_name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          4 Father's Name
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".65rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.father?.father_name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          5 Date of birth (in Christian Era) according to the
                          Admission Register (in figures and in words)
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".65rem",
                            display: "flex",
                            alignItems: "flex-end",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {formattedDate} ({wordsDay} {monthName} {wordsYear})
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          6 Nationality / Religion / Caste / Mother Tongue
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.caste ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="caste"
                                value={moreData.caste}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.caste}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          7 Date of admission in school with class
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.addmissiondate ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="addmissiondate"
                                value={moreData.addmissiondate}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.addmissiondate}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          8 Class in which the pupil last studied (in Figure and
                          in words)
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.preClass ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="preClass"
                                value={moreData.preClass}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.preClass}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          9 School / Board Examination last taken
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.bExam ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="bExam"
                                value={moreData.bExam}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.bExam}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          10 Subject
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.subject ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="subject"
                                value={moreData.subject}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.subject}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          11 Whether qualified for promotion to the higher class
                          if so, to which class
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.qualified ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="qualified"
                                value={moreData.qualified}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.qualified}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          12 Month upto which the pupil has paid the school dues
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.fees ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="fees"
                                value={moreData.fees}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.fees}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          13 Any fee concession availed of if so, the nature of
                          so concession
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.feesc ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="feesc"
                                value={moreData.feesc}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.feesc}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          14 Total No. of present Days / Total No. Working Days
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.days ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="days"
                                value={moreData.days}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.days}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          15 Whether NCC Cadet / Boy Scout / Girl Guide
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.camp ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="camp"
                                value={moreData.camp}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.camp}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          16 Games played or extra-curricular activities in
                          which the pupil usually took part (Mention achievement
                          level there in)
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.games ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="games"
                                value={moreData.games}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.games}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          17 General conduct
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.genral ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="genral"
                                value={moreData.genral}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.genral}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          18 Date of application for certificate
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.certificateDate ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="certificateDate"
                                value={moreData.certificateDate}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.certificateDate}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          19 Date of issue of certificate
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.dateIssue ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="dateIssue"
                                value={moreData.dateIssue}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.dateIssue}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          20 Reasons&nbsp; for leaving the school
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.reason ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="reason"
                                value={moreData.reason}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid #00000099",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.reason}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".65rem" }}>
                          21 Any other remarks
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.remark ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid ",
                                  borderColor: "#00000059",
                                  paddingBottom: "3px",
                                  fontSize: ".95rem",
                                }}
                                name="remark"
                                value={moreData.remark}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.remark}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1vw" }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: ".65rem",
                          fontWeight: "bold",
                        }}
                      >
                        I hereby certify and confirm that I had personally
                        verified all the entries made in this T.C. and entries
                        made in the <br /> Admission register as well as the
                        O.C. of the T.C. and they tally each other.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Prepared by{" "}
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Checked by
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Receiver's signature with Date
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Full Name
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <span>___________</span>
                        <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                          Rev. Fr Vijay J. D'Souza
                        </p>
                        <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                          Principal
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{position:"absolute",zIndex:"0"}} >
        <div style={{width:"20vw",height:"20vw"}}>
          <img style={{width:"100%",height:"100%"}} src={backimg}/>
        </div>
        </div> */}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "76%",
              marginLeft: "1vw",
              // display:"none"
            }}
            id="pdfcerti"
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div className={Styles.backgroundUrl}>
                  <A3Header />
                  <div style={{ marginTop: ".5vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.3rem",
                          textAlign: "center",
                          fontWeight: 700,
                          marginTop: ".5vw",
                          fontFamily: "sans",
                          borderBottom: "1px solid black",
                          paddingBottom: "5px",
                        }}
                      >
                        TRANSFER CERTIFICATE
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: ".5vw",
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>Admission No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            borderBottom: "1px solid ",
                            borderColor: "#00000059",
                            paddingBottom: "3px",
                            fontSize: ".95rem",
                          }}
                        >
                          {inputFields?.academic?.admission_number}
                        </span>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>STS &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.STSNo ? (
                            <p
                              style={{
                                borderBottom: "1px solid ",
                                borderColor: "#00000059",
                                paddingBottom: "3px",
                                fontSize: ".95rem",
                              }}
                            >
                              {moreData.STSNo} &nbsp;&nbsp;&nbsp;
                            </p>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>TC &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.TCNo ? (
                            <p
                              style={{
                                borderBottom: "1px solid ",
                                borderColor: "#00000059",
                                paddingBottom: "3px",
                                fontSize: ".95rem",
                              }}
                            >
                              {moreData.TCNo} &nbsp;&nbsp;&nbsp;
                            </p>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          1 Name of Pupil
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid ",
                            fontSize: ".95rem",
                            borderColor: "#00000059",
                            paddingBottom: "3px",
                            wordSpacing: "7px",
                          }}
                        >
                          {inputFields?.name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          2 Sex
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".95rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.personel?.gender}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          3 Mother's Name
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".95rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.mother?.mother_name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          4 Father's Name
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".95rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {inputFields?.father?.father_name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          5 Date of birth (in Christian Era) according to the
                          Admission Register (in figures and in words)
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".95rem",
                            display: "flex",
                            alignItems: "flex-end",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {formattedDate} ({wordsDay} {monthName} {wordsYear})
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          6 Nationality / Religion / Caste / Mother Tongue
                        </p>
                        <p
                          style={{
                            width: "35%",
                            borderBottom: "1px solid black",
                            fontSize: ".95rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {moreData.caste}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          7 Date of admission in school with class
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.addmissiondate ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.addmissiondate}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          8 Class in which the pupil last studied (in Figure and
                          in words)
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.preClass ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.preClass}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          9 School / Board Examination last taken
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.bExam ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.bExam}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          10 Subject
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.subject ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.subject}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          11 Whether qualified for promotion to the higher class
                          if so, to which class
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.qualified ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.qualified}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          12 Month upto which the pupil has paid the school dues
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.fees ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.fees}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          13 Any fee concession availed of if so, the nature of
                          so concession
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.feesc ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.feesc}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          14 Total No. of present Days / Total No. Working Days
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.days ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.days}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          15 Whether NCC Cadet / Boy Scout / Girl Guide
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.camp ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.camp}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          16 Games played or extra-curricular activities in
                          which the pupil usually took part (Mention achievement
                          level there in)
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.games ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.games}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          17 General conduct
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.genral ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.genral}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          18 Date of application for certificate
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.certificateDate ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.certificateDate}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          19 Date of issue of certificate
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.dateIssue ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.dateIssue}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          20 Reasons&nbsp; for leaving the school
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.reason ? (
                            <p
                              style={{
                                borderBottom: "1px solid #00000099",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.reason}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          21 Any other remarks
                        </p>
                        <p style={{ width: "35%" }}>
                          {moreData.remark ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.remark}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1vw" }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: ".95rem",
                          fontWeight: "bold",
                        }}
                      >
                        I hereby certify and confirm that I had personally
                        verified all the entries made in this T.C. and entries
                        made in the <br /> Admission register as well as the
                        O.C. of the T.C. and they tally each other.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Prepared by{" "}
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Checked by
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Receiver's signature with Date
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Full Name
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <span>___________</span>
                        <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                          Rev. Fr Vijay J. D'Souza
                        </p>
                        <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                          Principal
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{position:"absolute",zIndex:"0"}} >
        <div style={{width:"20vw",height:"20vw"}}>
          <img style={{width:"100%",height:"100%"}} src={backimg}/>
        </div>
        </div> */}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
            marginRight: "2vw",
          }}
          onClick={() => {
            A3handleCertiDownload();
            setTcFormetSize(true);
          }}
        >
          Download (A3 Format)
        </button>
        <button
          style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }}
          onClick={() => {
            A4handleCertiDownload();
            setTcFormetSize(false);
          }}
        >
          Download (A4 Format)
        </button>
        {edit == false ? (
          <button
            style={{
              marginTop: "1vw",
              backgroundColor: "green",
              color: "white",
              borderRadius: "4px",
              padding: "5px 20px 5px 20px",
              fontWeight: "bold",
              marginLeft: "2vw",
            }}
            onClick={editMode}
          >
            Edit
          </button>
        ) : (
          <button
            style={{
              marginTop: "1vw",
              backgroundColor: "red",
              color: "white",
              borderRadius: "4px",
              padding: "5px 20px 5px 20px",
              fontWeight: "bold",
              marginLeft: "2vw",
            }}
            onClick={saveEdit}
          >
            Save
          </button>
        )}
      </div>
    </>
  );
};

export default ManagementTcCertificate;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  useToast,
  Input,
  Button,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import NothingFound from "assets/icons/NothingFound.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  requestAddStep,
  requestDeleteStep,
  requestEditStep,
  requestGetOneModule,
  requestOneStepData,
  requestAddStepValues,
  requestEditStepValues,
  requestDeleteStepValues,
} from "services/admin-dashboard/help.service";
import Styles from "./Help.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  AiFillLeftCircle,
  AiFillRightCircle,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineCheck,
} from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { requestUploadHelpImages } from "services/common.service";
import { MdPreview } from "react-icons/md";
const NewHelp = () => {
  const params = useParams();
  const toast = useToast();
  const deleteStep = useDisclosure();
  const deleteStepValues = useDisclosure();
  const modalPreview = useDisclosure();
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "bullet" }, { list: "ordered" }],
    ],
  };
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [moduleName, setModuleName] = useState([]);
  const [hoveredStepIndex, setHoveredStepIndex] = useState(null);
  const [clickedStepIndex, setClickedStepIndex] = useState(null);
  const [newStepTitle, setNewStepTitle] = useState("");
  const [isAddingStep, setIsAddingStep] = useState(false);
  const [editingStep, setEditingStep] = useState(null);
  const [deletingStep, setDeletingStep] = useState(null);
  const [stepData, setStepData] = useState({});
  const [addingMedia, setAddingMedia] = useState(false);
  const [addingNonMedia, setAddingNonMedia] = useState(false);
  const [quillText, setQuillText] = useState("");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null);
  const [showQuillForSection, setShowQuillForSection] = useState(false);
  const [editingValue, setEditingValue] = useState(null);
  const [updatedQuillText, setUpdatedQuillText] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [file, setFile] = useState([]);
  const [imageData, setImageData] = useState("");
  const [hoveredSection, setHoveredSection] = useState(null);
  const [hoveredContent, setHoveredContent] = useState(null);
  const [deletingStepValue, setDeletingStepValue] = useState(null);
  const [isAppend, setIsAppend] = useState(false);
  const [appendImages, setAppendImages] = useState([]);
  const [isReplace, setIsReplace] = useState(false);
  const handleModuleData = async () => {
    try {
      setRequestInProgress(true);
      const payload = { help_id: params.id };
      const response = await requestGetOneModule(payload);
      setModuleName(response.details);
      setModuleData(response.data);
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    handleModuleData();
  }, []);

  const handleAddStep = async () => {
    try {
      setRequestInProgress(true);
      const payload = { step_name: newStepTitle, help_id: params.id };
      const response = await requestAddStep(payload);
      setIsAddingStep(false);
      setRequestInProgress(false);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      setHoveredStepIndex(null);
      handleModuleData();
      setNewStepTitle("");
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Not able to perform the operation",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const handleEditStep = (step, stepNumber) => {
    if (editingStep === null) {
      setEditingStep({
        _id: step._id,
        step_name: step.step_name,
        step_number: stepNumber,
      });
    }
  };
  const confirmEditStep = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        step_name: editingStep.step_name,
        step_id: editingStep._id,
      };
      const response = await requestEditStep(payload);
      setRequestInProgress(false);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      setEditingStep(null);
      handleModuleData();
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Not able to perform the operation",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const handleDeleteStep = (step, stepNumber) => {
    setDeletingStep({
      _id: step._id,
      step_number: stepNumber,
    });
    deleteStep.onOpen();
  };
  const confirmDeleteStep = async () => {
    try {
      setRequestInProgress(true);
      const payload = { step_id: deletingStep._id };
      const response = await requestDeleteStep(payload);
      setRequestInProgress(false);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      deleteStep.onClose();
      setDeletingStep(null);
      setClickedStepIndex(null);
      setHoveredStepIndex(null);
      handleModuleData();
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      toast({
        title: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const handleStepData = async (stepId, index) => {
    try {
      const payload = { step_id: stepId };
      const response = await requestOneStepData(payload);
      setStepData(response.data);
      setImageData(response.details);
      setClickedStepIndex(index);
    } catch (e) {
      console.error(e);
      toast({
        title: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const handleAddMedia = () => {
    setAddingMedia(true);
    setAddingNonMedia(false);
    setShowQuillForSection(false);
  };

  const handleAddNonMedia = () => {
    setQuillText("");
    setAddingNonMedia(true);
    setAddingMedia(false);
    setShowQuillForSection(false);
  };
  const handleaddStepdata = async () => {
    try {
      let fileResponse;
      if (file.length > 0 && addingMedia) {
        fileResponse = await uploadFilesToCloud();
      }
      let sectionNumber;
      if (
        (addingNonMedia && !showQuillForSection) ||
        (addingMedia && !showQuillForSection)
      ) {
        sectionNumber =
          stepData.length > 0
            ? Math.max(...stepData.map((section) => section.section)) + 1
            : 1;
      } else {
        sectionNumber =
          selectedSectionIndex !== null
            ? stepData[selectedSectionIndex].section
            : 1;
      }
      const payload = {
        step_id: moduleData[clickedStepIndex]._id,
        values: [
          {
            value: addingNonMedia ? quillText : "",
            is_media: fileResponse ? true : false,
            media: fileResponse ? fileResponse.file_url : [],
            section: sectionNumber,
            sequence: calculateSequenceInSection(sectionNumber),
          },
        ],
      };
      const response = await requestAddStepValues(payload);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });

      handleStepData(moduleData[clickedStepIndex]._id, clickedStepIndex);
      setAddingNonMedia(false);
      setAddingMedia(false);
      setShowQuillForSection(false);
      setSelectedImages([]);
    } catch (e) {
      console.error(e);
      toast({
        title: "Error adding step data",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  const uploadFilesToCloud = async () => {
    try {
      const response = await requestUploadHelpImages({
        moduleName: imageData.module_name,
        stepName: imageData.step_name,
        file: file,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          file_url: response.media,
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setSelectedImages(chosenFiles);
    setFile(chosenFiles);
  };
  const handleAddValueInSection = (sectionIndex) => {
    setAddingMedia(false);
    setAddingNonMedia(false);
    setSelectedSectionIndex(sectionIndex);
    setShowQuillForSection(!showQuillForSection);
    setQuillText("");
  };
  const calculateSequenceInSection = (sectionNumber) => {
    if (stepData.length === 0 || sectionNumber === null) {
      return 1;
    }

    const sequencesInSection = stepData
      .filter((section) => section.section === sectionNumber)
      .flatMap((section) =>
        section.contents.map((content) => content.sequence)
      );

    if (sequencesInSection.length === 0) {
      return 1;
    }

    const maxSequenceInSection = Math.max(...sequencesInSection);

    return maxSequenceInSection + 1;
  };
  const handleEditValue = (sectionIndex, contentIndex) => {
    const valueToEdit = stepData[sectionIndex].contents[contentIndex];
    setQuillText(valueToEdit.value);
    setUpdatedQuillText(valueToEdit.value);
    setEditingValue({
      sectionIndex,
      contentIndex,
      value: valueToEdit.value,
    });
  };
  const handleUpdateValue = async (sectionIndex) => {
    try {
      let fileResponse;
      if (appendImages.length > 0) {
        fileResponse = await uploadFilesToCloud();
      }

      const valueToEdit =
        stepData[editingValue.sectionIndex].contents[editingValue.contentIndex];
      valueToEdit.value = updatedQuillText;
      valueToEdit.section = sectionIndex + 1;

      const payload = {
        value_id: valueToEdit._id,
        value: {
          ...valueToEdit,
          _id: undefined,
          media: isAppend
            ? [...valueToEdit.media, ...fileResponse.file_url]
            : isReplace
            ? fileResponse.file_url
            : [...valueToEdit.media],
        },
      };
      const response = await requestEditStepValues(payload);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      handleStepData(moduleData[clickedStepIndex]._id, clickedStepIndex);
      setEditingValue(null);
      setUpdatedQuillText("");
    } catch (error) {
      console.error(error);
    }
  };

  // const handleUpdateValue = async (sectionIndex) => {
  //   try {
  //     let fileResponse;
  //     if (selectedImages.length > 0) {
  //       fileResponse = await uploadFilesToCloud();
  //     }
  //     const valueToEdit =
  //       stepData[editingValue.sectionIndex].contents[editingValue.contentIndex];
  //     valueToEdit.value = updatedQuillText;
  //     valueToEdit.section = sectionIndex + 1;
  //     const payload = {
  //       value_id: valueToEdit._id,
  //       value: {
  //         ...valueToEdit,
  //         _id: undefined,
  //       },
  //     };
  //     console.log("payload", payload);
  //     const response = await requestEditStepValues(payload);
  //     toast({
  //       title: response.msg,
  //       status: "success",
  //       duration: 2000,
  //       isClosable: true,
  //       position: "bottom-right",
  //     });
  //     setEditingValue(null);
  //     setUpdatedQuillText("");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleCancelEdit = () => {
    setEditingValue(null);
    setUpdatedQuillText("");
  };
  const handleDeleteStepValue = (content) => {
    deleteStepValues.onOpen();
    setDeletingStepValue(content);
  };
  const confirmDeleteValue = async () => {
    try {
      const payload = {
        value_id: deletingStepValue._id,
      };
      const response = await requestDeleteStepValues(payload);
      toast({
        title: response.msg,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
      setDeletingStepValue(null);
      handleStepData(moduleData[clickedStepIndex]._id, clickedStepIndex);
      deleteStepValues.onClose();
    } catch (error) {
      console.error(error);
    }
  };
  // const handleFileChange = (e, fieldName) => {
  //   if (fieldName === "isAppend") {
  //     setIsAppend(true);
  //   }
  //   const chosenFile = Array.prototype.slice.call(e.target.files);
  //   setFile(chosenFile);
  //   const chosenFiles = Array.from(e.target.files);
  //   setSelectedImages(chosenFiles);
  // };
  const handleFileChange = (e, fieldName) => {
    if (fieldName === "isAppend") {
      setIsReplace(false);
      setIsAppend(true);
    }
    if (fieldName === "isReplace") {
      setIsReplace(true);
      setIsAppend(false);
    }
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setAppendImages((prevImages) =>
      isAppend ? [...prevImages, ...chosenFiles] : chosenFiles
    );
    setFile(chosenFiles);
  };

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.wrap}>
        {requestInProgress ? (
          <Spinner />
        ) : (
          <>
            <div className={Styles.forWidthLeft}>
              <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                {moduleName.module_name} | Queries
              </p>
              {moduleData.map((step, index) => (
                <div
                  className={`${Styles.stepHead} ${
                    index === clickedStepIndex || index === hoveredStepIndex
                      ? Styles.hoveredStep
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredStepIndex(index)}
                  onMouseLeave={() => {
                    setHoveredStepIndex(null);
                    setEditingStep(null);
                  }}
                  onClick={() => {
                    if (clickedStepIndex !== index) {
                      handleStepData(step._id, index);
                      setAddingNonMedia(false);
                      setAddingMedia(false);
                    }
                  }}
                  key={index}
                >
                  <p className={Styles.stepNo}>{index + 1}</p>
                  <div className={Styles.stepTitle}>
                    {editingStep && editingStep.step_number === index + 1 ? (
                      <Input
                        placeholder="Enter Step Title"
                        value={editingStep.step_name}
                        style={{ border: "1px solid black" }}
                        onChange={(e) =>
                          setEditingStep({
                            ...editingStep,
                            step_name: e.target.value.toUpperCase(),
                          })
                        }
                      />
                    ) : (
                      step.step_name
                    )}
                  </div>
                  {hoveredStepIndex === index && (
                    <div className={Styles.stepActions}>
                      {editingStep && editingStep.step_number === index + 1 ? (
                        <AiOutlineCheck
                          className={Styles.checkIcon}
                          onClick={confirmEditStep}
                          fontSize={30}
                        />
                      ) : (
                        <>
                          <AiOutlineEdit
                            className={Styles.icon}
                            onClick={() => handleEditStep(step, index + 1)}
                          />
                          <AiOutlineDelete
                            className={Styles.icon}
                            onClick={() => handleDeleteStep(step, index + 1)}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {isAddingStep ? (
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    placeholder="Enter Step Title"
                    width="80%"
                    value={newStepTitle}
                    onChange={(e) =>
                      setNewStepTitle(e.target.value.toUpperCase())
                    }
                  />
                  <AiOutlineCheck
                    fontSize={30}
                    className={Styles.checkIcon}
                    onClick={handleAddStep}
                  />
                  <IoMdClose
                    fontSize={30}
                    className={Styles.closeIcon}
                    onClick={() => {
                      setIsAddingStep(false);
                      setNewStepTitle("");
                    }}
                  />
                </div>
              ) : (
                <Tooltip placement="right" label="Add Step">
                  <Button
                    colorScheme="green"
                    mt={2}
                    onClick={() => setIsAddingStep(true)}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </div>
            <div className={Styles.forWidthRight}>
              {clickedStepIndex !== null ? (
                stepData?.length > 0 ? (
                  stepData.map((section, sectionIndex) => (
                    <div
                      key={sectionIndex}
                      onMouseEnter={() => setHoveredSection(sectionIndex)}
                      onMouseLeave={() => setHoveredSection(null)}
                      className={Styles.sectionContainer}
                    >
                      {section.contents.map((content, contentIndex) => (
                        <div
                          key={contentIndex}
                          onMouseEnter={() => setHoveredContent(contentIndex)}
                          onMouseLeave={() => {
                            setHoveredContent(null);
                            setEditingValue(null);
                            setUpdatedQuillText("");
                          }}
                          className={
                            content.is_media
                              ? Styles.mediaContainer
                              : Styles.valueContainer
                          }
                        >
                          {editingValue &&
                          editingValue.sectionIndex === sectionIndex &&
                          editingValue.contentIndex === contentIndex ? (
                            !content.is_media ? (
                              <ReactQuill
                                modules={modules}
                                value={updatedQuillText}
                                onChange={(value) => setUpdatedQuillText(value)}
                              />
                            ) : (
                              <div>
                                <label htmlFor="append_image">
                                  <div
                                    style={{
                                      backgroundColor: "#f9cbca",
                                      border: "1px solid orchid",
                                      borderRadius: "5px",
                                      padding: " 5px",
                                      marginTop: "8px",
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Append Attachment
                                  </div>
                                  <input
                                    id="append_image"
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={(e) => {
                                      handleFileChange(e, "isAppend");
                                    }}
                                  />
                                </label>
                                <label htmlFor="replace_image">
                                  <div
                                    style={{
                                      backgroundColor: "#f9cbca",
                                      border: "1px solid orchid",
                                      borderRadius: "5px",
                                      padding: " 5px",
                                      marginTop: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Replace Attachment
                                  </div>
                                  <input
                                    id="replace_image"
                                    type="file"
                                    multiple
                                    hidden
                                    onChange={(e) => {
                                      handleFileChange(e, "isReplace");
                                      setIsReplace(true);
                                    }}
                                  />
                                </label>
                              </div>
                            )
                          ) : (
                            <div>
                              {content.is_media ? (
                                <div className={Styles.carouselmediaContainer}>
                                  <Carousel
                                    showThumbs={false}
                                    swipeable={true}
                                    emulateTouch={true}
                                    renderArrowPrev={(
                                      onClickHandler,
                                      hasPrev,
                                      label
                                    ) =>
                                      hasPrev && (
                                        <button
                                          type="button"
                                          onClick={onClickHandler}
                                          title={label}
                                          style={{
                                            position: "absolute",
                                            zIndex: 2,
                                            top: "calc(50% - 15px)",
                                            width: 20,
                                            height: 20,
                                            cursor: "pointer",
                                            left: 5,
                                            fontSize: "30px",
                                            color: "#990f0d",
                                          }}
                                          className={Styles.leftCircle}
                                        >
                                          <AiFillLeftCircle />
                                        </button>
                                      )
                                    }
                                    renderArrowNext={(
                                      onClickHandler,
                                      hasNext,
                                      label
                                    ) =>
                                      hasNext && (
                                        <button
                                          type="button"
                                          onClick={onClickHandler}
                                          title={label}
                                          style={{
                                            position: "absolute",
                                            zIndex: 2,
                                            top: "calc(50% - 15px)",
                                            width: 20,
                                            height: 20,
                                            cursor: "pointer",
                                            right: 10,
                                            fontSize: "30px",
                                            color: "#990f0d",
                                          }}
                                          className={Styles.rightCircle}
                                        >
                                          <AiFillRightCircle />
                                        </button>
                                      )
                                    }
                                  >
                                    {content.media.map((mediaUrl, index) => (
                                      <div key={index}>
                                        <img
                                          src={mediaUrl}
                                          alt={`Media ${index + 1}`}
                                          className={Styles.onemedia}
                                          style={{
                                            width: "800px",
                                            height: "250px",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </Carousel>
                                </div>
                              ) : (
                                <div
                                  className={Styles.stepContent}
                                  dangerouslySetInnerHTML={{
                                    __html: content.value,
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {hoveredSection === sectionIndex &&
                            hoveredContent === contentIndex && (
                              <div className={Styles.editButton}>
                                {editingValue &&
                                editingValue.sectionIndex === sectionIndex &&
                                editingValue.contentIndex === contentIndex ? (
                                  <>
                                    <AiOutlineCheck
                                      fontSize={30}
                                      className={Styles.checkIcon}
                                      onClick={() =>
                                        handleUpdateValue(section.section - 1)
                                      }
                                    />
                                    <IoMdClose
                                      fontSize={30}
                                      className={Styles.closeIcon}
                                      onClick={handleCancelEdit}
                                    />
                                  </>
                                ) : (
                                  <AiOutlineEdit
                                    className={Styles.icon}
                                    onClick={() =>
                                      handleEditValue(
                                        sectionIndex,
                                        contentIndex
                                      )
                                    }
                                  />
                                  // <Button>Edit</Button>
                                )}
                                {editingValue &&
                                editingValue.sectionIndex === sectionIndex &&
                                editingValue.contentIndex ===
                                  contentIndex ? null : (
                                  <AiOutlineDelete
                                    className={Styles.icon}
                                    onClick={() =>
                                      handleDeleteStepValue(content)
                                    }
                                  />
                                )}
                              </div>
                            )}
                        </div>
                      ))}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={() => handleAddValueInSection(sectionIndex)}
                          colorScheme="green"
                          m={2}
                        >
                          Add Value
                        </Button>
                      </div>
                      {showQuillForSection &&
                        selectedSectionIndex === sectionIndex && (
                          <div>
                            <div className={Styles.sequenceAddContainer}>
                              <Button
                                disabled={addingNonMedia ? true : false}
                                onClick={() => setAddingMedia(true)}
                                colorScheme="teal"
                                m={2}
                              >
                                Media
                              </Button>
                              <p style={{ border: "2px solid black" }}></p>
                              <Button
                                disabled={addingMedia ? true : false}
                                onClick={() => setAddingNonMedia(true)}
                                colorScheme="teal"
                                m={2}
                              >
                                Non-Media
                              </Button>
                            </div>
                            {addingMedia && (
                              <div className={Styles.addsequenceMediaContainer}>
                                <div className={Styles.chooseImageBox}>
                                  {selectedImages.length > 0 ? (
                                    <span>
                                      <MdPreview
                                        onClick={modalPreview.onOpen}
                                        fontSize={25}
                                      />
                                    </span>
                                  ) : null}
                                  <label>
                                    <div className={Styles.chooseImageButton}>
                                      Choose Image
                                    </div>
                                    <input
                                      type="file"
                                      id="image"
                                      name="image"
                                      accept="image/*"
                                      hidden
                                      multiple
                                      onChange={(e) => {
                                        handleFileEvent(e);
                                      }}
                                    />
                                  </label>
                                </div>
                                <div className={Styles.addImageButtons}>
                                  {selectedImages.length > 0 ? (
                                    <AiOutlineCheck
                                      className={Styles.checkIcon}
                                      onClick={() => handleaddStepdata()}
                                      fontSize={30}
                                    />
                                  ) : null}
                                  <IoMdClose
                                    onClick={() => {
                                      setSelectedImages([]);
                                      setAddingMedia(false);
                                    }}
                                    className={Styles.closeIcon}
                                    fontSize={30}
                                    cursor="pointer"
                                  />
                                </div>
                              </div>
                            )}
                            {addingNonMedia && (
                              <div
                                style={{
                                  backgroundColor: "rgb(252, 240, 172)",
                                  padding: "2px",
                                }}
                              >
                                <ReactQuill
                                  modules={modules}
                                  value={quillText}
                                  onChange={(value) => setQuillText(value)}
                                />
                                <div className={Styles.addTextButtons}>
                                  <AiOutlineCheck
                                    className={Styles.checkIcon}
                                    onClick={() =>
                                      handleaddStepdata("non-media")
                                    }
                                    fontSize={30}
                                  />
                                  <IoMdClose
                                    onClick={() => {
                                      setAddingNonMedia(false);
                                      setQuillText("");
                                    }}
                                    className={Styles.closeIcon}
                                    fontSize={30}
                                    cursor="pointer"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  ))
                ) : null
              ) : (
                <div className={Styles.nothingFoundContainer}>
                  <img
                    src={NothingFound}
                    alt="Nothing Found"
                    className={Styles.nothingFoundImage}
                  />
                  <p className={Styles.nothingFoundText}>
                    Please Select One Step
                  </p>
                </div>
              )}
              {clickedStepIndex !== null ? (
                <div>
                  <div className={Styles.addSectionContainer}>
                    <Button
                      disabled={addingNonMedia ? true : false}
                      onClick={handleAddMedia}
                      colorScheme="teal"
                      m={2}
                    >
                      Media
                    </Button>
                    <p style={{ border: "2px solid black" }}></p>
                    <Button
                      disabled={addingMedia ? true : false}
                      onClick={handleAddNonMedia}
                      colorScheme="teal"
                      m={2}
                    >
                      Non-Media
                    </Button>
                  </div>
                  <div>
                    {addingMedia && !showQuillForSection && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          background: "#fcf0ac",
                          borderRadius: "5px",
                          margin: "2px 10px 15px",
                          padding: "10px",
                          border: "2px solid #fcf0ac",
                          fontWeight: "bold",
                        }}
                        className={Styles.sectionImageContainer}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedImages.length > 0 ? (
                            <span>
                              <MdPreview
                                onClick={modalPreview.onOpen}
                                fontSize={25}
                              />
                            </span>
                          ) : null}
                          <label>
                            <div className={Styles.chooseImageButton}>
                              Choose Image
                            </div>
                            <input
                              type="file"
                              id="image"
                              name="image"
                              accept="image/*"
                              hidden
                              multiple
                              onChange={(e) => {
                                handleFileEvent(e);
                              }}
                            />
                          </label>
                        </div>
                        <div className={Styles.addImageButtons}>
                          {selectedImages.length > 0 ? (
                            <AiOutlineCheck
                              className={Styles.checkIcon}
                              onClick={() => handleaddStepdata()}
                              fontSize={30}
                            />
                          ) : null}
                          <IoMdClose
                            fontSize={30}
                            className={Styles.closeIcon}
                            onClick={() => {
                              setSelectedImages([]);
                              setAddingMedia(false);
                            }}
                            cursor="pointer"
                          />
                        </div>
                      </div>
                    )}
                    {addingNonMedia && !showQuillForSection && (
                      <div className={Styles.sectionTextContainer}>
                        <ReactQuill
                          modules={modules}
                          value={quillText}
                          onChange={(value) => setQuillText(value)}
                        />
                        <div className={Styles.addImageButtons}>
                          <AiOutlineCheck
                            className={Styles.checkIcon}
                            onClick={() => handleaddStepdata()}
                            fontSize={30}
                          />
                          <IoMdClose
                            fontSize={30}
                            className={Styles.closeIcon}
                            onClick={() => {
                              setAddingNonMedia(false);
                              setQuillText("");
                            }}
                            cursor="pointer"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      <Modal isOpen={modalPreview.isOpen} onClose={modalPreview.onClose}>
        <ModalOverlay />
        <ModalContent style={{ position: "sticky", top: "50px" }}>
          <ModalHeader>Selected images</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ maxHeight: "400px", overflowY: "auto" }}>
            {selectedImages.map((file, index) => (
              <div key={index}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Selected Image ${index}`}
                    style={{
                      border: "2px solid black",
                      width: "400px",
                      height: "200px",
                      marginBottom: "20px",
                    }}
                  />
                ) : null}
              </div>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={modalPreview.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteStep.isOpen}
        onClose={() => {
          deleteStep.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>Delete Step</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ color: "red", fontWeight: 600 }}>
            Are you sure to proceed with deletion of Step{" "}
            {deletingStep?.step_number}?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={2}
              onClick={() => {
                deleteStep.onClose();
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDeleteStep}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteStepValues.isOpen}
        onClose={() => {
          deleteStepValues.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            Delete Step Value
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p style={{ color: "red", fontWeight: 600 }}>
              Are you sure to proceed with deletion of Step Value?
            </p>
            {/* <div
              style={{
                padding: "5px",
                marginLeft: "15px",
                textAlign: "justify",
              }}
              dangerouslySetInnerHTML={{
                __html: deletingStepValue?.value,
              }}
            /> */}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={2}
              onClick={() => {
                deleteStepValues.onClose();
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDeleteValue}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default NewHelp;

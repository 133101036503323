import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Button, Select, Tooltip } from "@chakra-ui/react";
import {
  requestManagementDetailsById,
  requestUpdateManagementDetailsById,
  requestDeleteManagementById,
} from "services/admin-dashboard/admin-management.service";
import {
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
} from "@chakra-ui/react";
import Styles from "./SingleManagementPage.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fileToURI } from "utilities/utils";
import { MdContentPaste } from "react-icons/md";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { requestUploadManagementImages } from "services/common.service";
const PLACEHOLDER_IMAGE_URL =
  "https://betachon.com/wp-content/uploads/2019/03/upload-your-logo-here-21052-p-800x800.png";
const SingleManagementPage = () => {
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const [imageInputField, setImageInputFields] = useState({
    profile_url: "",
    file: null,
  });
  const [operationType, setOperationType] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState(null);
  const [removeProfile, setRemoveProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [isEmployeeDetailsVisible, setIsEmployeeDetailsVisible] =
    useState(true);
  const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] =
    useState(true);
  const [isOtherDetailsVisible, setIsOtherDetailsVisible] = useState(true);
  React.useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchManagementProfileDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    setOperationType("EDIT");
    let imageResponse;
    if (removeProfile) {
      imageResponse = {
        profile_url: "N/A",
      };
    } else if (imageInputField.file) {
      imageResponse = await uploadImagesToCloud();
    } else {
      imageResponse = { profile_url: inputFields?.personel?.profile_url };
    }

    if (imageResponse) {
      updateManagementHandler(imageResponse, removeProfile);
    }
  };

  const uploadImagesToCloud = async () => {
    try {
      const response = await requestUploadManagementImages({
        profile_file: imageInputField.file,
        managementId: inputFields.name,
        EMP_ID: inputFields.emp_id,
      });

      if (response.error) {
        setRequestInProgress(false);
        return false;
      }

      if (response) {
        return { profile_url: response.management_profile || "" };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleDelete = async () => {
    setOperationType("DELETE");
    setRequestInProgress(true);
    try {
      const response = await requestDeleteManagementById({
        management_id: params.id,
      });
      if (response) {
        setOperationType("");
        setRequestInProgress(false);
        toast({
          title: "Deleted Succesfully",
          description: "Deleted Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/admin/dashboard/admin-management");
        onAlertClose();
      }
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Delete request failed",
        description: "Delete Failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      onAlertClose();
    }
  };

  const updateManagementHandler = async (
    imageUrls = {},
    shouldRemoveProfile = false
  ) => {
    try {
      const payload = {
        email: inputFields.email,
        name: inputFields.name,
        mgmt_role: inputFields.mgmt_role,
        user_info: {
          present_address: inputFields.user_info.present_address,
          emp_id: inputFields.user_info.emp_id,
          dob: inputFields.user_info.dob,
          permanent_address: inputFields.user_info.permanent_address,
          nationlity: inputFields.user_info.nationlity,
          mother_tongue: inputFields.user_info.mother_tongue,
          qualification: inputFields.user_info.qualification,
          gender: inputFields.user_info.gender,
          religion: inputFields.user_info.religion,
          sub_religion: inputFields.user_info.sub_religion,
          aadhar_number: inputFields.user_info.aadhar_number,
          martial_status: inputFields.user_info.martial_status,
          profile_url: shouldRemoveProfile
            ? "N/A"
            : imageUrls?.profile_url || inputFields.profile_url,
        },
      };
      const response = await requestUpdateManagementDetailsById({
        management_id: params.id,
        ...payload,
      });
      if (response) {
        navigate("/admin/dashboard/admin-management");
        toast({
          title: "Submitted Succesfully",
          description: "Updated Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
      setRequestInProgress(false);
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        description: "Credentials already exist",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

      console.error(error);
    }
  };

  const fetchManagementProfileDetails = async () => {
    try {
      const response = await requestManagementDetailsById({
        management_id: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        const _response = {
          ...response,
          name: response.name,
          email: response.email,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const handleInputChange = (e, parentField) => {
    if (parentField === "pastePermanentAddress") {
      setInputFields((prev) => ({
        ...prev,
        user_info: {
          ...prev.user_info,
          present_address: prev.user_info.permanent_address,
        },
      }));
      return;
    }
    if (typeof e?.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, type, files, checked },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        file: files,
      }[type];

      if (parentField && typeof parentField === "string") {
        setInputFields((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else if (parentField && typeof parentField === "object") {
      const [parent, child] = parentField;
      setInputFields((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: e,
        },
      }));
    }
  };

  if (requestInProgress || (requestInProgress && operationType === "")) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  if (!inputFields) {
    return null;
  }
  const handleSetImage = (e, fieldName) => {
    if (fieldName === "isUpload") {
      setIsUpload(true);
      setRemoveProfile(false);
    }
    const {
      target: { name, files },
    } = e;
    if (files && files[0]) {
      const selectedImageURL = URL.createObjectURL(files[0]);
      setSelectedImage(selectedImageURL);
      setImageInputFields((prev) => ({
        ...prev,
        [name]: files && files[0],
      }));
      fileToURI(files[0]).then((uri) => {
        setImageInputFields((prev) => ({
          ...prev,
          profile_url: uri,
        }));
      });
    }
  };
  const handleRemoveProfile = () => {
    setIsUpload(false);
    setRemoveProfile(true);
  };
  return (
    <div className={Styles.body}>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        MANAGEMENT UPDATE FORM
      </p>
      <div className={Styles.profile}>
        <div
          style={{
            padding: "4px",
            border: "2px solid black",
            borderRadius: "50%",
          }}
        >
          <img
            className={Styles.profileImage}
            src={
              removeProfile
                ? PLACEHOLDER_IMAGE_URL
                : isUpload
                ? selectedImage || PLACEHOLDER_IMAGE_URL
                : inputFields.url !== "N/A"
                ? inputFields.url
                : selectedImage || PLACEHOLDER_IMAGE_URL
            }
            alt="profile"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <label htmlFor="file">
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: "#228b15",
              }}
            >
              Select Profile
            </div>
            <input
              type="file"
              id="file"
              name="file"
              accept="image/*"
              hidden
              onChange={(e) => handleSetImage(e, "isUpload")}
            />
          </label>
          {!removeProfile && (inputFields.url !== "N/A" || selectedImage) && (
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                background: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: " #990f0d",
              }}
              onClick={() => handleRemoveProfile()}
            >
              Remove Profile
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Personal Details
            </button>
            {isPersonalDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(true)}
              />
            )}
          </div>
          {isPersonalDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Name <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    onChange={(e) => handleInputChange(e, "")}
                    value={inputFields?.name}
                    name="name"
                    type="text"
                    placeholder="Enter management name"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Email <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleInputChange(e, "")}
                    required
                    name="email"
                    value={inputFields.email}
                    type="text"
                    placeholder="value"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Phone No. <span className={Styles.asterisk}>*</span>
                  </p>
                  <div style={{ display: "flex" }}>
                    <select className={Styles.optionLists}>
                      <option>+91</option>
                    </select>
                    <input
                      name="phoneNumber"
                      onChange={(e) => handleInputChange(e, "user_info")}
                      value={inputFields.phoneNumber}
                      required
                      type="text"
                      placeholder="XXXXX XXXXX"
                      className={Styles.textInput}
                    />
                  </div>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Gender <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    name="gender"
                    onChange={(e) => handleInputChange(e, "user_info")}
                    value={inputFields.user_info.gender}
                    required
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Qualification <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleInputChange(e, "user_info")}
                    required
                    name="qualification"
                    value={inputFields.user_info.qualification}
                    type="text"
                    placeholder="value"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Blood Group <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    onChange={(e) => handleInputChange(e, "user_info")}
                    name="blood_group"
                    required
                    value={inputFields?.user_info?.blood_group}
                    placeholder="Select Blood Group"
                  >
                    <option value="A(+VE)">A(+VE)</option>
                    <option value="B(+VE)">B(+VE)</option>
                    <option value="A(-VE)">A(-VE)</option>
                    <option value="B(-VE)">B(-VE)</option>
                    <option value="O(+ve)">O(+ve)</option>
                    <option value="O(-ve)">O(-ve)</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Permanent Address <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleInputChange(e, "user_info")}
                    required
                    name="permanent_address"
                    value={inputFields?.user_info?.permanent_address}
                    type="text"
                    placeholder="Enter Permanent Address"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p className={Styles.placeholder}>
                      Present Address <span className={Styles.asterisk}>*</span>
                    </p>
                    <Tooltip
                      placement="top"
                      label="Paste the same data of Permanent Address"
                    >
                      <div
                        onClick={() =>
                          handleInputChange(null, "pastePermanentAddress")
                        }
                      >
                        <MdContentPaste fontSize={22} cursor="pointer" />
                      </div>
                    </Tooltip>
                  </div>
                  <textarea
                    required
                    name="present_address"
                    onChange={(e) => handleInputChange(e, "user_info")}
                    value={inputFields.user_info.present_address}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                    placeholder="Enter Present Address"
                  />
                </div>
              </div>
            </>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Employee Details
            </button>
            {isEmployeeDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(true)}
              />
            )}
          </div>
          {isEmployeeDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Employee ID <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "user_info")}
                  value={inputFields?.user_info?.emp_id}
                  name="emp_id"
                  type="text"
                  placeholder="Enter employee id"
                  className={Styles.textInput}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Management Type <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "")}
                  value={inputFields?.mgmt_role}
                  name="mgmt_role"
                  type="text"
                  placeholder="Enter Management Role"
                  className={Styles.textInput}
                />
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Other Details
            </button>
            {isOtherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(true)}
              />
            )}
          </div>
          {isOtherDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Aadhaar Number</p>
                  <input
                    onChange={(e) => handleInputChange(e, "user_info")}
                    name="aadhar_number"
                    type="text"
                    placeholder="XXXX XXXX XXXX"
                    value={inputFields.user_info.aadhar_number}
                    className={Styles.textInput}
                    minlength="12"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Martial Status <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    name="martial_status"
                    onChange={(e) => handleInputChange(e, "user_info")}
                    value={inputFields.user_info.martial_status}
                    placeholder="Select Marital Status"
                    required
                    className={Styles.textInput}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Other">Other</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Date of Birth </p>
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="dob"
                    onChange={(e) => handleInputChange(e, "user_info")}
                    value={inputFields.user_info.dob}
                    // selected={inputFields.user_info.dob}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Nationality</p>
                  <Select
                    name="nationlity"
                    onChange={(e) => handleInputChange(e, "user_info")}
                    value={inputFields?.user_info?.nationlity}
                    placeholder="Select Religion"
                  >
                    <option value="INDIAN">INDIAN</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}> Mother Tongue</p>
                  <input
                    onChange={(e) => handleInputChange(e, "user_info")}
                    name="mother_tongue"
                    value={inputFields.user_info.mother_tongue}
                    type="text"
                    placeholder="value"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Religion </p>
                  <Select
                    name="religion"
                    value={inputFields.user_info.religion}
                    onChange={(e) => handleInputChange(e, "user_info")}
                    placeholder="Select Religion"
                  >
                    <option value="HINDU">HINDU</option>
                    <option value="MUSLIM">MUSLIM</option>
                    <option value="CHRISTIAN">CHRISTIAN</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Sub-Religion</p>
                  <input
                    onChange={(e) => handleInputChange(e, "user_info")}
                    name="sub_religion"
                    value={inputFields.user_info.sub_religion}
                    type="text"
                    placeholder="Enter Sub-Religion"
                    className={Styles.textInput}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className={Styles.buttonRow}>
          <button type="submit" className={Styles.button2}>
            {requestInProgress && operationType === "ADD" ? (
              <Spinner />
            ) : (
              "Update"
            )}
          </button>
          <button
            type="button"
            onClick={() => setAlertOpen(true)}
            className={Styles.button}
          >
            {requestInProgress && operationType === "DELETE" ? (
              <Spinner />
            ) : (
              "Delete"
            )}
          </button>
          <AlertDialog
            isOpen={isAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onAlertClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Admin
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onAlertClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      </form>
    </div>
  );
};

export default SingleManagementPage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Select, Input, Spinner, Tooltip, Button } from "@chakra-ui/react";
// import {
//   requestSearchStudentByFilter,
//   requestSearchStudentDetails,
// } from "services/admin-dashboard/student.service";
import Services from "services/staff-dashboard/student.service"
import { debounce } from "utilities/utils";
// import { useClassAndSections } from "hooks/classAndSection";
import { useToast } from "@chakra-ui/react";
import Styles from "./StudentTeacher.module.scss";
import defaultUser from "../../../assets/icons/User.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IoMdMale } from "react-icons/io";
import { IoMdFemale } from "react-icons/io";
import { BiUpArrowCircle } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import add_New from "assets/icons/Add_new.png";
import download_new from "assets/icons/Download_new.png";
import moment from "moment";
import { BiSearchAlt2 } from "react-icons/bi";
const StudentTeacher = () => {
  const { classAndSections, setClassAndSections } = useState("");
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [students, setStudentsList] = useState([]);
  const [searchResultList, setSearchResultList] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const isClassQuery = inputFields.class !== "";
  const isSectionQuery = inputFields.section !== "";
  const toast = useToast();
  const [data, setData] = React.useState([]);
  const [stuCount, setStuCount] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [classdata,setClassData]= useState("")
  const [loading, setloading] = useState(false)
  const getSectionAndClassromm=async()=>{
    try{
        const payload ={}
        const res = await Services.requestClassSectionDropdownTeacherSide(payload)
        if(res){
            console.log(res)
            setInputFields({class:res[0].class,section:res[0].sections[0]})
            // setClassAndSections(res)
            setClassData(res)
        }
    }
    catch(e){
        console.log(e)
    }
  }
  useEffect(()=>{
    getSectionAndClassromm()
  },[])
// console.log("inputFields",inputFields)
 useEffect(()=>{
  if(inputFields.class&&inputFields.section&&classdata){
    fetchStudents()
  }
 },[inputFields.class,inputFields.section,classdata])

  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };
  const fetchStudents = async ({ saveDefault } = {}) => {
    // console.log("inputFields.section", inputFields)
    try {
      setRequestInProgress(true);
      const payload = {
        // ...(searchQuery !== "" ? { name: searchQuery } : {}),
        name: searchQuery,
        class: inputFields.class,
        section: inputFields.section,
      };
      const response = await Services.requestSearchStudentByFilter(payload)
      setStuCount(response);
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        if (saveDefault) {
          setStudentsList(response);
        } else {
          // let dataWithSignedProfileURL = await Promise.all(
          //   response.map(async (item) => {
          //     let signedURL = await getSignedUrlLink(item.personel.profile_url);
          //     let data = { ...item, signed_url: signedURL };
          //     return data;
          //   })
          // );
          setSearchResultList(response);
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      if (e.msg === "This Combination is Invalid") {
        toast({
          title: e.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        setSearchResultList([]);
      }
    }
  };
  const maleCount =
    Array.isArray(stuCount) &&
    stuCount.reduce((count, student) => {
      if (
        student.personel.gender === "Male" ||
        student.personel.gender === "male"
      ) {
        return count + 1;
      }
      return count;
    }, 0);

  const femaleCount =
    Array.isArray(stuCount) &&
    stuCount.reduce((count, student) => {
      if (
        student.personel.gender === "Female" ||
        student.personel.gender === "female"
      ) {
        return count + 1;
      }
      return count;
    }, 0);

  const handleSearchClick = () => {
    if (searchQuery.trim() !== "") {
      fetchStudents();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    setSearchQuery("");
    fetchStudents();
    setShowSearchIcon(true);
  };

  const isQueried =
    isClassQuery || isSectionQuery || inputFields.studentName !== "";

  const studentList = isQueried ? searchResultList : students;
  // const totalStudentsCount = studentList.length;
  // const selectedGrade = inputFields?.class?.toUpperCase() + " Grade";
  // const selectedSection = inputFields?.section?.toUpperCase();
  const totalStudentsInGrade =
    isQueried &&
    studentList.filter((i) => i.academic?.class == inputFields.class);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
   if(classdata){
    let requiredSections = classdata&&classdata.length
    ? classdata&&classdata
        .filter((item) => item.class == inputFields.class)
        .map((item) => item.sections)
    : "";
    setInputFields({...inputFields,section:requiredSections})
    // console.log("requiredSections",requiredSections)
  setSections(requiredSections[0]);
  setloading(false)
   }
    
    // console.log("section ", sections,classdata )
  }, [inputFields.class, classAndSections,classdata]);
  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  const fileName =
    inputFields.class && inputFields.section
      ? `smu-student-class-${inputFields.class}-section-${inputFields.section}`
      : "smu-students";

  const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    const handleExportClick = async () => {
      const payload = {
        ...(inputFields.studentName !== ""
          ? { name: inputFields.studentName }
          : {}),
        ...(isClassQuery
          ? { class: inputFields.class.toLocaleUpperCase() }
          : {}),
        ...(isSectionQuery
          ? { section: inputFields.section.toLocaleUpperCase() }
          : {}),
      };
      const res = await Services.requestSearchStudentDetails(payload);
      setStuCount(res);
      if (res) {
        let newArray = [];
        res.map((item) => {
          let obj = {
            "Addmission ID": item.academic.admission_number,
            Name: item.name,
            Class: item.academic.class,
            Section: item.academic.section,
            "Phone Number": item.phoneNumber,
            "Father Name": item.father.father_name,
            "Father Phone No.": item.father.father_phone,
            "Mother Name": item.mother.mother_name,
            "Mother Phone No.": item.mother.mother_phone,
            DOB: moment(item.personel.dob, "MM/DD/YYYY").format("DD/MM/YYYY"),
            "Blood Group": item.personel.blood_group,
            Address: item.personel.present_address,
          };
          newArray.push(obj);
          setData(newArray);
        });
        exportToCSV(newArray, fileName);
      }
    };
    return (
      <div className={Styles.downloadButton} onClick={handleExportClick}>
        <Tooltip label="Download Student Details">
          <Link to="">
            <img
              className={Styles.download}
              src={download_new}
              alt="download"
            />
          </Link>
        </Tooltip>
      </div>
    );
  };
  // console.log("sections",sections)
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.header}>
          <div>
            <div className={Styles.statsWrapper}>
              <p className={Styles.stats}>STUDENTS |</p>
              <div className={Styles.iconMale}>
                <IoMdMale />
              </div>
              <p className={Styles.stats}>Boys -{maleCount || 0}</p>
              <div className={Styles.iconFemale} style={{ marginLeft: "10px" }}>
                <IoMdFemale />
              </div>
              <p className={Styles.stats}>Girls -{femaleCount || 0}</p>
            </div>
          </div>
        </div>
        <div className={Styles.headerFilterWrapper}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  paddingRight="3rem"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.class ? inputFields.class : ""}
              onChange={handleInputChange}
              name="class"
              placeholder="Select Grade"
            >
              {classdata&&classdata.map((grade, idx) => (
                <option key={grade.class + idx} value={grade.class}>
                  Grade {grade.class}
                </option>
              ))}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.section ? inputFields.section :inputFields.section }
              onChange={handleInputChange}
              name="section"
              placeholder="Select Section"
            >
              {sections&&sections.map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section&&section}
                    </option>
                  ))
                }
            </Select>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : studentList.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            studentList.map((student, idx) => (
              <Link
                to={student._id}
                key={student["_id"] + idx}
                className={Styles.list}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={student.url != "N/A" ? student.url : defaultUser}
                    alt="student-profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>{student.name || "--"}</p>
                  <p className={Styles.class}>
                    {(student?.academic?.class && student?.academic?.class) ||
                      "--"}{" "}
                    | {student.academic?.section?.toUpperCase() || "--"}
                  </p>
                  <p className={Styles.number}>
                    +91 {student.phoneNumber || "XXX-XXX-XXXX"}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentTeacher;

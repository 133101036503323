import axios from "axios";
import { getRequestOptions, BASE_URL } from "services/utils";
export async function requestSearchStudentByFilter(payload = {}) {
    const url = BASE_URL + "/general/searchStudentWithFilter";
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions());
      if (response.data.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error;
      }
      if (e?.response?.data) {
        throw e.response.data;
      }
      throw e;
    }
  }
  export async function requestSearchStudentDetails(payload = {}) {
    const url = BASE_URL + "/teacher/student/downloadStudentWithFilter";
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions());
      if (response.data.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error;
      }
      if (e?.response?.data) {
        throw e.response.data;
      }
      throw e;
    }
  }
  export async function requestClassSectionDropdownTeacherSide(payload = {}) {
    const url = BASE_URL + "/teacher/student/all-classes";
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions());
      if (response.data.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error;
      }
      if (e?.response?.data) {
        throw e.response.data;
      }
      throw e;
    }
  }
  export async function requestStudentDetailsById(payload = {}) {
    const { studentId } = payload;
    if (!studentId && studentId === "") {
      return { error: "Field missing" };
    }
    const url = BASE_URL + "/teacher/student/getStudent/" + studentId;
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions());
      if (response.data?.error) {
        return { error: response.data.error };
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error;
      }
      if (e?.response?.data) {
        throw e.response.data;
      }
      throw e;
    }
  }
  const Services = {
    requestSearchStudentByFilter,
    requestSearchStudentDetails,
    requestClassSectionDropdownTeacherSide,
    requestStudentDetailsById
  }
  export default Services
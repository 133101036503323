import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Select, Button, Tooltip, Switch } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FaBus, FaCalendarAlt } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { BsCheckSquare } from "react-icons/bs";
import { useClassAndSections } from "hooks/classAndSection";
import {
  //   requestDeleteStudentById,
  requestStudentDetailsById,
  //   requestUpdateElectiveById,
  //   requestUpdateStudentDetailsById,
  //   requestUpdateTransportDetails,
  //   requestUpdateCanteenDetails,
  //   requestUpdatePeriodChoice,
} from "services/staff-dashboard/student.service";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { AiFillRightCircle, AiFillDollarCircle } from "react-icons/ai";
import { requestUploadStudentImages } from "services/common.service";
// import { requestAllSubjects } from "services/admin-dashboard/classroom.service";
import { fileToURI } from "utilities/utils";
import { MdFastfood } from "react-icons/md";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import Styles from "./SingleStudentPage.module.scss";
import {
  requestRouteById,
  //   requestAllRoutes,
} from "services/admin-dashboard/transport.service";
import moment from "moment";
import defaultUser from "../../../assets/icons/User.png";
import { requestCheckStudentPhoneNumber } from "services/admin-dashboard/student.service";
const PLACEHOLDER_IMAGE_URL =
  "https://betachon.com/wp-content/uploads/2019/03/upload-your-logo-here-21052-p-800x800.png";

const SingleStudentTeacher = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const [isCanteenAlertOpen, setCanteenAlertOpen] = useState(false);
  const onCanteenAlertClose = () => setCanteenAlertOpen(false);
  const cancelRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const checkPhoneNumber = useDisclosure();
  const periodCheckBox = useDisclosure();
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [operationType, setOperationType] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState(null);
  const [userWithSamePhoneNumber, SetUserWithSamePhoneNumber] = useState("");
  const [busChoiceInput, setBusChoiceInput] = useState({
    route: "",
    stop: "",
    bus: "",
    duration: "",
  });
  const [isCanteenChosen, setCanteenChosen] = useState(false);
  const [changeValue, setChangeValue] = useState(false);
  const [isBusChosen, setBusChosen] = useState(false);
  const [imageInputField, setImageInputFields] = useState({
    father: { url: "", file: null },
    mother: { url: "", file: null },
    student: { url: "", file: null },
  });
  const [routes, setRoutes] = useState([]);
  const [routeDetails, setRouteDetails] = useState(null);
  const [enableElective, setEnableElective] = useState("");
  const [disable, setDisable] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [removeProfile, setRemoveProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [periodData, setPeriodData] = useState({
    startingDate: "",
    mid_term_days: "",
    midterm_present_days: "",
    totalDays: "",
    PresentDays: "",
  });
  const [isAdmissionDetailsVisible, setIsAdmissionDetailsVisible] =
  useState(true);
const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] =
  useState(true);
const [isOtherDetailsVisible, setIsOtherDetailsVisible] = useState(true);
const [isFatherDetailsVisible, setIsFatherDetailsVisible] = useState(true);
const [isMotherDetailsVisible, setIsMotherDetailsVisible] = useState(true);
const [isAcademicDetailsVisible, setIsAcademicDetailsVisible] =
  useState(true);
  React.useEffect(() => {
    setRequestInProgress(true);
    Promise.all([
      fetchStudentProfileDetails(),
      // fetchAllRoutes(),
    ])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  React.useEffect(() => {
    if (inputFields?.academic?.class) {
      let requiredSections = classAndSections.length
        ? classAndSections
            .filter((item) => item.class == inputFields.academic.class)
            .map((item) => item.sections)
        : [[inputFields.academic.section]];
      setSections(requiredSections[0]);
    }
  }, [inputFields?.academic?.class, classAndSections]);

  function openBusModal() {
    if (inputFields && busChoiceInput.route === "") {
      //   fetchAllRoutes();
      onOpen();
    }

    if (!!busChoiceInput.route && operationType === "") {
      fetchRouteById();
    }
  }
  React.useEffect(() => {
    openBusModal();
  }, [busChoiceInput.route]);

  //   const fetchAllRoutes = async () => {
  //     try {
  //       const response = await requestAllRoutes();
  //       if (response.error) {
  //         console.error(response.error);
  //       }
  //       if (response && Array.isArray(response)) {
  //         setRoutes(response);
  //         return response;
  //       }
  //     } catch (e) {
  //       console.error(e);
  //       throw e;
  //     }
  //   };

  const uploadImagesToCloud = async () => {
    try {
      const response = await requestUploadStudentImages({
        student_photo_file: imageInputField.student.file,
        mother_photo_file: imageInputField.mother.file,
        father_photo_file: imageInputField.father.file,
        studentId: inputFields.name,
        phone: inputFields.phoneNumber,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          profile_url: response.student_profile || "",
          father_photo_url: response.father_profile || "",
          mother_photo_url: response.mother_profile || "",
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOperationType("ADD");
    setRequestInProgress(true);
    let imageResponse;
    if (removeProfile) {
      imageResponse = {
        profile_url: "N/A",
      };
    } else if (
      imageInputField.student.file &&
      imageInputField.mother.file &&
      imageInputField.father.file
    ) {
      imageResponse = await uploadImagesToCloud();
    } else if (
      imageInputField.student.file ||
      imageInputField.mother.file ||
      imageInputField.father.file
    ) {
      let uploadedResponse = await uploadImagesToCloud();
      imageResponse = {
        profile_url: uploadedResponse.profile_url
          ? uploadedResponse.profile_url
          : inputFields.personel.profile_url,
        father_photo_url: uploadedResponse.father_photo_url
          ? uploadedResponse.father_photo_url
          : inputFields.father.father_photo_url,
        mother_photo_url: uploadedResponse.mother_photo_url
          ? uploadedResponse.mother_photo_url
          : inputFields.mother.mother_photo_url,
      };
    } else {
      imageResponse = {
        profile_url: inputFields?.personel?.profile_url,
        father_photo_url: inputFields?.father?.father_photo_url,
        mother_photo_url: inputFields?.mother?.mother_photo_url,
      };
    }

    if (imageResponse) {
      //   updateStudentHandler(imageResponse, removeProfile);
    }
  };
  //   const handleDelete = async () => {
  //     setOperationType("DELETE");
  //     setRequestInProgress(true);
  //     try {
  //       const response = await requestDeleteStudentById({
  //         studentId: params.id,
  //       });
  //       if (response) {
  //         setOperationType("");
  //         setRequestInProgress(false);
  //         toast({
  //           title: "Deleted Succesfully",
  //           description: "Deleted Details",
  //           status: "success",
  //           duration: 4000,
  //           isClosable: true,
  //           position: "top-right",
  //         });
  //         navigate("/admin/dashboard/students");
  //         onAlertClose();
  //       }
  //     } catch (e) {
  //       console.error(e);
  //       setRequestInProgress(false);
  //       toast({
  //         title: e,
  //         description: "Delete Failed",
  //         status: "error",
  //         duration: 4000,
  //         isClosable: true,
  //         position: "top-bottom",
  //       });
  //       onAlertClose();
  //     }
  //   };

  //   const updateStudentHandler = async (
  //     imageUrls = {},
  //     shouldRemoveProfile = false
  //   ) => {
  //     try {
  //       const {
  //         email,
  //         name,
  //         personel,
  //         father,
  //         mother,
  //         academic,
  //         subject_choices,
  //       } = inputFields;
  //       const dob =
  //         personel?.dob &&
  //         moment(personel?.dob, "DD/MM/YYYY").format("MM/DD/YYYY");
  //       const payload = {
  //         email,
  //         name,
  //         phoneNumber: father?.father_phone || mother?.mother_phone,
  //         academic: {
  //           admission_number: academic.admission_number,
  //           section: academic.section,
  //           stream: "-",
  //           admission_year: academic.admission_year,
  //         },
  //         personel: {
  //           ...personel,
  //           dob,
  //           profile_url: shouldRemoveProfile
  //             ? "N/A"
  //             : imageUrls?.profile_url || inputFields.profile_url,
  //           // profile_url: imageUrls?.profile_url || inputFields.profile_url,
  //           nationlity: personel.nationlity,
  //         },
  //         father: {
  //           ...father,
  //           father_photo_url:
  //             imageUrls?.father_photo_url || father.father_photo_url,
  //         },
  //         mother: {
  //           ...mother,
  //           mother_photo_url:
  //             imageUrls?.mother_photo_url || mother.mother_photo_url,
  //         },
  //         guardian: {
  //           guardian_name: father.father_name || mother.mother_name,
  //           guardian_phone: father.father_phone || mother.mother_phone,
  //           guardian_occupation:
  //             father.father_occupation || mother.mother_occupation,
  //           guardian_photo_url:
  //             imageUrls?.father_photo_url ||
  //             father.father_photo_url ||
  //             imageUrls?.mother_photo_url ||
  //             mother.mother_photo_url,
  //         },
  //         // subject_choices: subject_choices
  //       };
  //       console.log("request payload", payload);
  //       const response = await requestUpdateStudentDetailsById({
  //         studentId: params.id,
  //         ...payload,
  //       });
  //       if (response) {
  //         navigate("/admin/dashboard/students");
  //         toast({
  //           title: "Student Updated Succesfully",
  //           description: "Updated Details",
  //           status: "success",
  //           duration: 4000,
  //           isClosable: true,
  //           position: "top-right",
  //         });
  //       }
  //       setRequestInProgress(false);
  //     } catch (error) {
  //       setRequestInProgress(false);
  //       toast({
  //         title: error.msg,
  //         description: "Failed to update details",
  //         status: "error",
  //         duration: 4000,
  //         isClosable: true,
  //         position: "bottom-right",
  //       });

  //       console.error(error);
  //     }
  //   };
  const handleRemoveProfile = () => {
    setIsUpload(false);
    setRemoveProfile(true);
  };
  //   const updateElective = async (e) => {
  //     let elective_choices = inputFields.subject_choices.filter(
  //       (el) => el.elective_name === e.target.name
  //     ).length
  //       ? inputFields.subject_choices.map((ele) => {
  //           if (ele.elective_name === e.target.name) {
  //             return { ...ele, chosen_subject: e.target.value };
  //           } else {
  //             return ele;
  //           }
  //         })
  //       : inputFields.subject_choices;

  //     setInputFields({ ...inputFields, subject_choices: elective_choices });

  //     if (elective_choices.length > 0) {
  //       try {
  //         await Promise.all(
  //           elective_choices.map(async (sub) => {
  //             let elective_response = await requestUpdateElectiveById({
  //               studentId: params.id,
  //               elective_name: sub.elective_name,
  //               chosen_subject: sub.chosen_subject,
  //             });
  //           })
  //         );

  //         toast({
  //           title: "Updated Electives Successfully!!",
  //           variant: "left-accent",
  //           position: "bottom-right",
  //           status: "success",
  //           duration: 1500,
  //           isClosable: true,
  //         });
  //       } catch (error) {
  //         toast({
  //           title: "Not Updating Electives",
  //           description: error.msg,
  //           variant: "left-accent",
  //           position: "bottom-right",
  //           status: "error",
  //           duration: 5000,
  //           isClosable: true,
  //         });
  //       }
  //     }
  //   };

  const fetchStudentProfileDetails = async () => {
    try {
      const response = await requestStudentDetailsById({
        studentId: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        setCanteenChosen(response?.canteen_choices[0]?.opted_value);
        setBusChosen(response?.transport_choices[0]?.opted_value);
        const DOB = response?.personel?.dob;
        let dob = DOB && moment(DOB, "DD/MM/YYYY").format("MM/DD/YYYY");
        if (dob == "Invalid Date") {
          if (DOB.includes("-")) {
            dob = new Date(DOB.split("-").reverse().join("/"));
          } else if (DOB.includes("/")) {
            const [day, month, year] = DOB.split("/");
            const dateString = [month, day, year].join("/");
            dob = new Date(dateString);
          }
        } else {
          if (typeof new Date(DOB) === "object") {
            dob = new Date(DOB);
          } else {
            dob = DOB;
          }
        }

        const _response = {
          ...response,
          name: response.name,
          email: response.email,
          personel: {
            ...response.personel,
            dob,
            gender: response.personel.gender,
            present_address: response.personel.present_address,
            permanent_address: response.personel.permanent_address,
            mother_tongue: response.personel.mother_tongue,
            religion: response.personel.religion,
            sub_religion: response.personel.sub_religion,
            blood_group: response.personel.blood_group,
            aadhar_number: response.personel.aadhar_number,
            nationlity: response.personel.nationlity,
            // signed_display_image: await getSignedUrlLink(
            //   response.personel.profile_url
            // ),
          },
          academic: {
            ...response.academic,
            class: response.academic.class,
            section: response.academic.section,
            admission_number: response.academic.admission_number,
            admission_year: response.academic.admission_year,
          },
          father: {
            ...response.father,
            father_name: response.father.father_name,
            father_email: response.father.father_email,
            father_phone: response.father.father_phone,
            father_occupation: response.father.father_occupation,
            father_qualification: response.father.father_qualification,
            // signed_father_display_image: await getSignedUrlLink(
            //   response.father.father_photo_url
            // ),
          },
          mother: {
            ...response.mother,
            mother_name: response.mother.mother_name,
            mother_phone: response.mother.mother_phone,
            mother_occupation: response.mother.mother_occupation,
            mother_qualification: response.mother.mother_qualification,
            // signed_mother_display_image: await getSignedUrlLink(
            //   response.mother.mother_photo_url
            // ),
          },
          canteen_choices: response.canteen_choices,
          account_status: response.account_status,
          transport_choices: response.transport_choices,
          // electives: classroom_response.electives,
          // subject_choices: response.subject_choices,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const handleSetImage = (e, fieldName) => {
    if (fieldName === "isUpload") {
      setIsUpload(true);
      setRemoveProfile(false);
    }
    const {
      target: { name, files },
    } = e;
    if (files && files[0]) {
      // Update selectedImage here with the selected image
      const selectedImageURL = URL.createObjectURL(files[0]);
      setSelectedImage(selectedImageURL);

      setImageInputFields((prev) => ({
        ...prev,
        [name]: { ...prev[name], file: files[0] },
      }));
      fileToURI(files[0]).then((uri) => {
        setImageInputFields((prev) => ({
          ...prev,
          [name]: { ...prev[name], url: uri },
        }));
      });
    }
  };

  const handleBusChoiceInputChange = ({ target: { name, value } }) => {
    setBusChoiceInput((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const handleInputChange = (e, parentField) => {
    if (typeof e?.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, type, files, checked },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        file: files,
      }[type];

      if (parentField && typeof parentField === "string") {
        setInputFields((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else if (parentField && typeof parentField === "object") {
      const [parent, child] = parentField;
      setInputFields((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: e,
        },
      }));
    }
  };
  //   const handleAddCanteen = async (e) => {
  //     e.preventDefault();
  //     setRequestInProgress(true);
  //     try {
  //       const resp = await requestUpdateCanteenDetails({
  //         studentId: params.id,
  //         // year: 202,
  //         opted_value: true,
  //         duration: busChoiceInput.duration,
  //       });
  //       if (resp) {
  //         setOperationType("");
  //         setRequestInProgress(false);
  //         fetchStudentProfileDetails();
  //         onCanteenAlertClose();
  //       }
  //     } catch (e) {
  //       setOperationType("");
  //       setRequestInProgress(false);
  //       console.error(e);
  //     }
  //   };
  //   const handleRemoveCanteen = async (e) => {
  //     e.preventDefault();
  //     setRequestInProgress(true);
  //     try {
  //       const resp = await requestUpdateCanteenDetails({
  //         studentId: params.id,
  //         opted_value: false,
  //         // year: new Date().getFullYear(),
  //       });
  //       if (resp) {
  //         setOperationType("");
  //         setRequestInProgress(false);
  //         onCanteenAlertClose();
  //         fetchStudentProfileDetails();
  //       }
  //     } catch (e) {
  //       setOperationType("");
  //       setRequestInProgress(false);
  //       console.error(e);
  //     }
  //   };
  //   const handleRemoveBus = async (e) => {
  //     e.preventDefault();
  //     setRequestInProgress(true);
  //     try {
  //       const resp = await requestUpdateTransportDetails({
  //         studentId: params.id,
  //         opted_value: false,
  //       });
  //       if (resp) {
  //         setOperationType("");
  //         setRequestInProgress(false);
  //         fetchStudentProfileDetails();
  //         toast({
  //           title: "Removed Succesfully",
  //           description: "Removed Bus",
  //           status: "success",
  //           duration: 4000,
  //           isClosable: true,
  //           position: "bottom-right",
  //         });
  //       }
  //     } catch (e) {
  //       setOperationType("");
  //       setRequestInProgress(false);
  //       toast({
  //         title: "Failed to remove",
  //         description: { e },
  //         status: "error",
  //         duration: 4000,
  //         isClosable: true,
  //         position: "bottom-right",
  //       });
  //       console.error(e);
  //     }
  //   };

  //   const handleAddTransport = async (e) => {
  //     e.preventDefault();
  //     const route = routes.find((i) => i._id === busChoiceInput.route);
  //     const stop = routeDetails?.particulars?.find(
  //       (i) => i.stop === busChoiceInput.stop
  //     );

  //     if (route && stop) {
  //       setRequestInProgress(true);
  //       setOperationType("ADD_BUS");
  //       try {
  //         const resp = await requestUpdateTransportDetails({
  //           studentId: params.id,
  //           route_id: route._id,
  //           bus_number: Number(busChoiceInput.bus),
  //           location: stop.location,
  //           opted_value: true,
  //           duration: busChoiceInput.duration,
  //         });
  //         if (resp) {
  //           setOperationType("");
  //           setRequestInProgress(false);
  //           onClose();
  //           fetchStudentProfileDetails();
  //         }
  //       } catch (e) {
  //         setOperationType("");
  //         setRequestInProgress(false);
  //         console.error(e);
  //       }
  //     }
  //   };

  const fetchRouteById = async () => {
    setRequestInProgress(true);
    setOperationType("GET_ROUTE");
    try {
      const response = await requestRouteById({
        routeId: busChoiceInput.route,
      });
      if (response?.error) {
        console.error(response.error);
      }
      if (response) {
        setRouteDetails(response);
        setOperationType("");
        setRequestInProgress(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (loading || (requestInProgress && operationType === "")) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  if (!inputFields) {
    return null;
  }

  let testBool = true;

  function toggle() {
    testBool = testBool ? false : true;
    setEnableElective(testBool);
  }
  const checkExistingPhoneNumber = async () => {
    try {
      const payload = {
        phoneNumber: inputFields.father.father_phone,
      };
      const response = await requestCheckStudentPhoneNumber(payload);
      if (response) {
        SetUserWithSamePhoneNumber(response);
        checkPhoneNumber.onOpen();
      }
    } catch (err) {
      toast({
        title: "Phone Number",
        description: err.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleinputDisabled = (e, inputName) => {
    if (disable == false) {
      setDisable(true);
    } else if (disable == true) {
      setDisable(false);
    }
  };
  const periodInputChange = (e) => {
    setPeriodData({ ...periodData, [e.target.name]: e.target.value });
  };
  //   const handlePeriodData = async () => {
  //     try {
  //       const id = params.id;

  //       const payload = {
  //         start_date: moment(periodData.startingDate).toISOString(),
  //         midterm_present_days: periodData.midterm_present_days,
  //         mid_term_days: periodData.mid_term_days,
  //         total_days: periodData.totalDays,
  //         present_day: periodData.PresentDays,
  //         // academic_year:
  //       };
  //       const response = await requestUpdatePeriodChoice(payload, id);
  //       if (response) {
  //         toast({
  //           title: "Period Data",
  //           description: "Period data added",
  //           status: "success",
  //           duration: 4000,
  //           isClosable: true,
  //           position: "top-right",
  //         });
  //       }
  //     } catch (err) {
  //       console.log("err", err.message);
  //       toast({
  //         title: "Period Data",
  //         description: err.message,
  //         status: "error",
  //         duration: 4000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //     }
  //   };
  return (
    <div className={Styles.body}>
      <div>
        <div className={Styles.profiles}>
          <div className={Styles.profile}>
            <div
              style={{
                padding: "4px",
                border: "2px solid black",
                borderRadius: "50%",
              }}
            >
              <img
                className={Styles.profileImage}
                src={
                  removeProfile
                    ? PLACEHOLDER_IMAGE_URL
                    : isUpload
                    ? selectedImage || PLACEHOLDER_IMAGE_URL
                    : inputFields.url !== "N/A"
                    ? inputFields.url
                    : selectedImage || PLACEHOLDER_IMAGE_URL
                }
                alt={removeProfile ? "placeholder" : "profile"}
              />
            </div>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label htmlFor="student">
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                background: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: "#228b15",
              }}
            >
              Upload Profile
            </div>
            <input
              type="file"
              id="student"
              name="student"
              accept="image/*"
              hidden
              onChange={(e) => handleSetImage(e, "isUpload")}
            />
          </label>
          {!removeProfile && (inputFields.url !== "N/A" || selectedImage) && (
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                background: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: " #990f0d",
              }}
              onClick={() => handleRemoveProfile()}
            >
              Remove Profile
            </div>
          )}
        </div> */}
      </div>

      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Personal Details
            </button>
            {isPersonalDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(true)}
              />
            )}
          </div>
          {isPersonalDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Name <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    onChange={(e) => handleInputChange(e, "")}
                    value={inputFields?.name}
                    name="name"
                    type="text"
                    pattern="[A-Za-z.'\s]{4,}"
                    placeholder="Enter Student Name"
                    className={Styles.textInput}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                      )
                    }
                    onInput={(e) => {
                      e.target.setCustomValidity("");
                      // e.target.reportValidity();
                    }}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Email <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    onChange={(e) => handleInputChange(e, "")}
                    required
                    name="email"
                    value={inputFields.email}
                    type="text"
                    placeholder="Enter Email"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Gender <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    onChange={(e) => handleInputChange(e, "personel")}
                    name="gender"
                    value={inputFields.personel.gender}
                    required
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Aadhaar Number <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    name="aadhar_number"
                    type="text"
                    placeholder="XXXX XXXX XXXX"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.aadhar_number}
                    className={Styles.textInput}
                    minlength="12"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/-/g, "");
                    }}
                  />
                </div>

                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Permanent Address <span className={Styles.asterisk}>*</span>
                  </p>
                  <textarea
                    required
                    name="permanent_address"
                    value={inputFields.personel.permanent_address}
                    onChange={(e) => handleInputChange(e, "personel")}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                    placeholder="Enter Permanent Address"
                  />
                </div>
                <div className={Styles.form}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p className={Styles.placeholder}>
                      Present Address <span className={Styles.asterisk}>*</span>
                    </p>
                
                  </div>
                  <textarea
                    required
                    name="present_address"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.present_address}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                    placeholder="Enter Present Address"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Blood Group <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    name="blood_group"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.blood_group}
                    placeholder="Select Blood Group"
                  >
                    <option value="A(+VE)">A(+VE)</option>
                    <option value="B(+VE)">B(+VE)</option>
                    <option value="A(-VE)">A(-VE)</option>
                    <option value="B(-VE)">B(-VE)</option>
                    <option value="O(+VE)">O(+VE)</option>
                    <option value="O(-VE)">O(-VE)</option>
                    <option value="AB(+VE)">AB(+VE)</option>
                    <option value="AB(-VE)">AB(-VE)</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Date of Birth <span className={Styles.asterisk}>*</span>
                  </p>
                  <DatePicker
                    required
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="dob"
                    onChange={(e) => handleInputChange(e, ["personel", "dob"])}
                    value={inputFields?.personel?.dob}
                    selected={inputFields?.personel?.dob}
                  />
                </div>
              </div>
            </>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Admission Details
            </button>
            {isAdmissionDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsAdmissionDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsAdmissionDetailsVisible(true)}
              />
            )}
          </div>
          {isAdmissionDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Admission ID <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "academic")}
                  value={inputFields?.academic?.admission_number}
                  name="admission_number"
                  className={Styles.textInput}
                  placeholder="Enter Admission ID "
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Admission Year <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "academic")}
                  value={inputFields.academic.admission_year}
                  name="admission_year"
                  placeholder="Enter Admission Year "
                  className={Styles.textInput}
                  type="number"
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Class <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  onChange={(e) => handleInputChange(e, "academic")}
                  name="class"
                  value={inputFields.academic.class}
                  placeholder="Select Grade"
                  disabled
                  style={{ opacity: 1 }}
                >
                  {classAndSections
                    .filter((item) => item.class !== "All")
                    .map((item, idx) => (
                      <option key={item.class + idx} value={item.class}>
                        Grade {item.class}
                      </option>
                    ))}
                </Select>
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Section <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  isRequired
                  onChange={(e) => {
                    handleInputChange(e, "academic");
                    setChangeValue(true);
                  }}
                  value={inputFields.academic.section}
                  name="section"
                  placeholder="Select Section"
                >
                  {sections
                    .filter((item) => item !== "All")
                    .map((section, idx) => (
                      <option key={section + idx} value={section}>
                        Section {section}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Other Details
            </button>
            {isOtherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(true)}
              />
            )}
          </div>
          {isOtherDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Nationality <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  onChange={(e) => handleInputChange(e, "personel")}
                  value={inputFields.personel.nationlity}
                  placeholder="Select Nationality"
                  name="nationlity"
                >
                  <option value="INDIAN">INDIAN</option>
                </Select>
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Mother Tongue <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  type="text"
                  required
                  placeholder="Enter Mother Tongue"
                  name="mother_tongue"
                  onChange={(e) => handleInputChange(e, "personel")}
                  value={inputFields.personel.mother_tongue}
                  className={Styles.textInput}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/-/g, "");
                  }}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Religion <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  name="religion"
                  value={inputFields.personel.religion}
                  placeholder="Select Religion"
                  onChange={(e) => handleInputChange(e, "personel")}
                >
                  <option value="HINDU">HINDU</option>
                  <option value="MUSLIM">MUSLIM</option>
                  <option value="CHRISTIAN">CHRISTIAN</option>
                  <option value="OTHER">OTHER</option>
                </Select>
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>Sub-Religion</p>
                <input
                  onChange={(e) => handleInputChange(e, "personel")}
                  name="sub_religion"
                  value={inputFields.personel.sub_religion}
                  type="text"
                  placeholder="Enter Sub-Religion"
                  className={Styles.textInput}
                />
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button className={Styles.heading} type="button">
              Father Details
            </button>
            {isFatherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsFatherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsFatherDetailsVisible(true)}
              />
            )}
          </div>
          {isFatherDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Father Name <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  name="father_name"
                  onChange={(e) => handleInputChange(e, "father")}
                  value={inputFields.father.father_name}
                  required
                  type="text"
                  pattern="[A-Za-z.'\s]{4,}"
                  placeholder="Enter Father Name"
                  className={Styles.textInput}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                    )
                  }
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                    // e.target.reportValidity();
                  }}
                />
              </div>

              <div className={Styles.form}>
                <p className={Styles.placeholder}>Qualification</p>
                <input
                  type="text"
                  placeholder="Enter Qualification"
                  name="father_qualification"
                  onChange={(e) => handleInputChange(e, "father")}
                  value={
                    inputFields.father.father_qualification
                      ? inputFields.father.father_qualification
                      : "-"
                  }
                  className={Styles.textInput}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Occupation <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  onChange={(e) => handleInputChange(e, "father")}
                  value={
                    inputFields.father.father_occupation
                      ? inputFields.father.father_occupation
                      : "-"
                  }
                  required
                  type="text"
                  placeholder="Enter Occupation"
                  name="father_occupation"
                  className={Styles.textInput}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Phone No. <span className={Styles.asterisk}>*</span>
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className={Styles.optionLists}
                    onChange={(e) => handleInputChange(e, "father")}
                    name="father_phone_code"
                    value={inputFields.father.father_phone_code}
                  >
                    <option value="+91">+91</option>
                  </select>

                  <input
                    onChange={(e) => handleInputChange(e, "father")}
                    name="father_phone"
                    value={inputFields.father.father_phone}
                    required
                    type="number"
                    placeholder="value"
                    className={Styles.textInput}
                  />
                  <Button
                    onClick={checkExistingPhoneNumber}
                    className={Styles.phoneCheckBtn}
                  >
                    <AiFillRightCircle className={Styles.phoneCheckBtnImg} />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button className={Styles.heading} type="button">
              Mother Details
            </button>
            {isMotherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsMotherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsMotherDetailsVisible(true)}
              />
            )}
          </div>
          {isMotherDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Mother Name <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  name="mother_name"
                  onChange={(e) => handleInputChange(e, "mother")}
                  value={inputFields.mother.mother_name}
                  required
                  type="text"
                  pattern="[A-Za-z.'\s]{4,}"
                  placeholder="Enter Mother Name"
                  className={Styles.textInput}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                    )
                  }
                  onInput={(e) => {
                    e.target.setCustomValidity("");
                    // e.target.reportValidity();
                  }}
                />
              </div>

              <div className={Styles.form}>
                <p className={Styles.placeholder}>Qualification</p>
                <input
                  name="mother_qualification"
                  onChange={(e) => handleInputChange(e, "mother")}
                  value={
                    inputFields.mother.mother_qualification
                      ? inputFields.mother.mother_qualification
                      : "-"
                  }
                  type="text"
                  placeholder="Enter Qualification"
                  className={Styles.textInput}
                />
              </div>

              <div className={Styles.form}>
                <p className={Styles.placeholder}>Occupation</p>
                <input
                  name="mother_occupation"
                  onChange={(e) => handleInputChange(e, "mother")}
                  value={
                    inputFields.mother.mother_occupation
                      ? inputFields.mother.mother_occupation
                      : "-"
                  }
                  type="text"
                  placeholder="Enter Occupation"
                  className={Styles.textInput}
                />
              </div>

              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Phone No. <span className={Styles.asterisk}>*</span>
                </p>
                <div style={{ display: "flex" }}>
                  <select
                    className={Styles.optionLists}
                    onChange={(e) => handleInputChange(e, "mother")}
                    name="mother_phone_code"
                    value={inputFields.father.mother_phone_code}
                  >
                    <option value="+91">+91</option>
                  </select>
                  <input
                    name="mother_phone"
                    onChange={(e) => handleInputChange(e, "mother")}
                    value={inputFields.mother.mother_phone}
                    required
                    type="number"
                    placeholder="value"
                    className={Styles.textInput}
                  />
                </div>
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button className={Styles.heading} type="button">
              Academic Details
            </button>
            {isAcademicDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsAcademicDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsAcademicDetailsVisible(true)}
              />
            )}
          </div>
          {isAcademicDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.formc}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "5px",
                      alignItems: "center",
                    }}
                  >
                    <AiFillDollarCircle
                      style={{ height: "22px", width: "18px" }}
                    />
                    <label>&nbsp;&nbsp;Account Status</label>
                    <BsCheckSquare
                      style={{ "margin-left": "10px", color: "green" }}
                    />
                  </div>
                  <label>
                  
                    <Tooltip
                      label={
                        isCanteenChosen ? "Canteen Opted" : "Canteen Abstained"
                      }
                      aria-label="A tooltip"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{ display: "flex", marginLeft: "5px" }}
                          onClick={() => {
                            setCanteenAlertOpen(true);
                            // getCanteenDurationDetails();
                          }}
                          name="canteen_choices"
                          checked={isCanteenChosen}
                          value={inputFields.canteen_choices}
                          onChange={(e) => handleInputChange(e, "")}
                        >
                          <MdFastfood
                            style={{ height: "22px", width: "18px" }}
                          />
                          &nbsp;&nbsp;Canteen Status
                        </div>
                        {isCanteenChosen ? (
                          <BsCheckSquare
                            style={{ "margin-left": "10px", color: "green" }}
                          />
                        ) : (
                          <FcCancel style={{ "margin-left": "10px" }} />
                        )}
                      </div>
                    </Tooltip>
                  </label>
                  <Tooltip
                    label={isBusChosen ? "Bus Opted" : "Bus Abstained"}
                    aria-label="A tooltip"
                  >
                    <div
                      className={Styles.busIcon}
                      onClick={() => {
                        openBusModal();
                        onOpen();
                        // getTranspoartRealatedDetails();
                      }}
                    >
                      <FaBus style={{ cursor: "pointer" }} />
                      &nbsp;&nbsp; <p>Bus Status</p>
                      {isBusChosen ? (
                        <BsCheckSquare
                          style={{ "margin-left": "10px", color: "green" }}
                        />
                      ) : (
                        <FcCancel style={{ "margin-left": "10px" }} />
                      )}
                    </div>
                  </Tooltip>
                  <label
                    onClick={() => periodCheckBox.onOpen()}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FaCalendarAlt style={{ "margin-left": "10px" }} />
                    &nbsp;&nbsp;Session Status
                 
                    {/* <input
                      // disabled
                      checked={true}
                      name="account_status"
                      value={inputFields.account_status}
                      type="checkbox"
                      style={{ "margin-left": "10px" }}
                    /> */}
                  </label>
                </div>
              </div>
              {inputFields?.subject_choices?.length > 0 && (
                <>
                  <h3 className={Styles.Elecheading}>Electives</h3>

                  <div className={Styles.row}>
                    {inputFields.subject_choices.map((ele) => (
                      <div className={Styles.formElec} style={{ width: 300 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItem: "center",
                          }}
                        >
                          <p style={{ paddingTop: "8px" }}>
                            {ele.elective_name}
                          </p>
                        </div>
                        {ele.subjects.map((sub) => (
                          <label style={{display:"flex" , alignItems:"center",marginBottom:5}}>
                            <input
                              type="radio"
                              style={{ marginRight: 10, height:22,width:22 }}
                              name={ele.elective_name}
                              value={sub}
                              disabled={disable == false ? true : false}
                              checked={sub === ele.chosen_subject}
                              // onChange={(e) => handleInputChange(e, 'electives')}
                              // onChange={(e) => updateElective(e)}
                            />
                            {sub}
                          </label>
                        ))}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Switch onChange={handleinputDisabled} />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
       
      </form>
      {/* <Modal
        isOpen={isOpen}
        onClose={() => {
          setOperationType("");
          setBusChoiceInput({
            route: "",
            stop: "",
            bus: "",
            duration: "",
          });
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Route, Stop and Bus</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form 
            // onSubmit={handleAddTransport}
            >
              <div className={Styles.formGroup}>
                <label className={Styles.formLabel}>Choose Route</label>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={busChoiceInput.route}
                    onChange={handleBusChoiceInputChange}
                    name="route"
                    placeholder="Select Route"
                  >
                    {routes.map((r, i) => (
                      <option key={r._id + i} value={r._id}>
                        {r.route}
                      </option>
                    ))}
                  </Select>
                </div>
                <label className={Styles.formLabel}>Choose Stop</label>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={busChoiceInput.stop}
                    disabled={!routeDetails}
                    placeholder={
                      requestInProgress &&
                      operationType === "GET_ROUTE" &&
                      !routeDetails
                        ? "Loading..."
                        : "Select Stop"
                    }
                    onChange={handleBusChoiceInputChange}
                    name="stop"
                  >
                    {routeDetails?.particulars?.map((p, i) => (
                      <option key={"stop" + i} value={p?.stop}>
                        {p?.location}
                      </option>
                    ))}
                  </Select>
                </div>
                <label className={Styles.formLabel}>Choose Bus</label>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={busChoiceInput.bus}
                    onChange={handleBusChoiceInputChange}
                    name="bus"
                    disabled={!routeDetails}
                    placeholder={
                      requestInProgress &&
                      operationType === "GET_ROUTE" &&
                      !routeDetails
                        ? "Loading..."
                        : "Select Bus"
                    }
                  >
                    {routeDetails?.buses?.map((b, i) => (
                      <option key={b + "" + i} value={b}>
                        {b}
                      </option>
                    ))}
                  </Select>
                </div>
                <label className={Styles.formLabel}>Duration</label>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={busChoiceInput.duration}
                    onChange={handleBusChoiceInputChange}
                    name="duration"
                    disabled={!routeDetails}
                    placeholder="Duration"
                  >
                    <option value="1">1</option>
                    <option value="3">3</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="9">9</option>
                    <option value="12">12</option>
                  </Select>
                </div>
                <div className={Styles.modalButtons}>
                  {" "}
                  <button type="submit" className={Styles.formButton}>
                    {requestInProgress && operationType === "ADD_BUS" ? (
                      <Spinner />
                    ) : (
                      "Add"
                    )}
                  </button>
                  {isBusChosen ? (
                    <button
                      className={Styles.formButton}
                      onClick={handleRemoveBus}
                      style={{ backgroundColor: "#990f0d", color: "white" }}
                    >
                      {requestInProgress ? <Spinner /> : "Remove Bus"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      {/* <Modal
        isOpen={checkPhoneNumber.isOpen}
        onClose={checkPhoneNumber.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Students exists with same phone number </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              {userWithSamePhoneNumber.length > 1 ? (
                <div>
                  {userWithSamePhoneNumber.map((student) => (
                    <div className={Styles.list}>
                      <div className={Styles.profiles}>
                        <img
                          className={Styles.profileImg}
                          src={
                            student.profile_url != "N/A"
                              ? student.profile_url
                              : defaultUser
                          }
                          alt="student-profile"
                        />
                      </div>
                      <div className={Styles.info}>
                        <p className={Styles.name}>{student.name || "--"}</p>
                        <p className={Styles.class}>
                          {(student?.class && student?.class) || "--"} |{" "}
                          {student?.section?.toUpperCase() || "--"}
                        </p>
                        <p className={Styles.class}>
                          Father Name : {student.father_name || "--"}
                        </p>
                        <p className={Styles.class}>
                          Mother Name : {student.mother_name || "--"}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className={Styles.noResult}>No Students Found</p>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => checkPhoneNumber.onClose()}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
      {/* <Modal isOpen={periodCheckBox.isOpen} onClose={periodCheckBox.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div className={Styles.periodData}>
                <p> Start date :</p>
                <div>
                  <input
                    className={Styles.periodinput}
                    type="date"
                    format="DD/MM/YYYY"
                    name="startingDate"
                    onChange={(e) => {
                      periodInputChange(e);
                    }}
                  />
                </div>
              </div>
              <div className={Styles.periodData}>
                <p> Present Days :</p>
                <div className={Styles.periodinput2}>
                  <input
                    className={Styles.periodinput}
                    type="number"
                    name="PresentDays"
                    onChange={(e) => {
                      periodInputChange(e);
                    }}
                  />
                </div>
              </div>
              <div className={Styles.periodData}>
                <p> Mid Term Days :</p>
                <div className={Styles.periodinput2}>
                  <input
                    className={Styles.periodinput}
                    type="number"
                    name="mid_term_days"
                    onChange={(e) => {
                      periodInputChange(e);
                    }}
                  />
                </div>
              </div>
              <div className={Styles.periodData}>
                <p> Mid Term Present Days :</p>
                <div className={Styles.periodinput2}>
                  <input
                    className={Styles.periodinput}
                    type="number"
                    name="midterm_present_days"
                    onChange={(e) => {
                      periodInputChange(e);
                    }}
                  />
                </div>
              </div>
              <div className={Styles.periodData}>
                <p> Total Days :</p>
                <div className={Styles.periodinput2}>
                  <input
                    className={Styles.periodinput}
                    type="number"
                    name="totalDays"
                    onChange={(e) => {
                      periodInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
            //   onClick={() => handlePeriodData()}
            >
              Add Period
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </div>
  );
};

export default SingleStudentTeacher;

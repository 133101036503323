import React from "react";
import Styles from "../Midterm.module.scss";
import Header from "../Header/Header";
import StudentInfo from "../StudentInfo/StudentInfo";
import ScholasticRecord from "../ScholasticRecord/ScholasticRecord";
import CoscholasticRecord from "../CoscholasticRecord/CoscholasticRecord";
import Footer from "../Footer/Footer";

const Main = ({ data, type }) => {
  console.log("data in main", data);
  let grade = parseInt(data.class);
  let gradeCategory = "";
  if (grade <= 5) {
    gradeCategory = "Grade1to5";
  } else if (grade === 6) {
    gradeCategory = "Grade6";
  } else if (grade === 8) {
    gradeCategory = "Grade8";
  } else if (grade === 7) {
    gradeCategory = "Grade7";
  } else if (grade === 9) {
    gradeCategory = "Grade9";
  } else if (grade === 10) {
    gradeCategory = "Grade10";
  } else if (grade === 11) {
    gradeCategory = data.section == "A" ? "Grade11Science" : "Grade11Commerce";
  } else if (grade === 12) {
    gradeCategory = data.section == "A" ? "Grade12Science" : "Grade12Commerce";
  }

  let info = {
    student_name: data ? data.name.toUpperCase() : "TEST",
    father_name: data
      ? data.student_father.toUpperCase()
      : "ALWIN PETER D SOUZA",
    mother_name: data ? data.student_mother.toUpperCase() : "JOSLINE D SOUZA",
    address: data
      ? data.student_address.toUpperCase()
      : "MORNING CLOUD ANAND RAO ROAD AMBALAPADI",
    student_grade: data ? data.class : grade,
    dob: data
      ? new Date(data.student_dob).toLocaleDateString("en-GB")
      : "18/05/1998",
    admission_no: data ? data.student_admission : "01/2013-14",
  };

  let marksList = {};
  marksList =
    data.class <= 10
      ? data.mid_term
        ? data.mid_term.map((i) => {
            return {
              ...marksList,
              [i?.elective_name === "VALUE EDUCATION"
                ? i.elective_name
                : i.subject_name]: i.total,
            };
          })
        : data.anual.map((i) => {
            return {
              ...marksList,
              [Object.keys(i).includes("elective_name")
                ? i.elective_name
                : i.subject_name]: i.total,
            };
          })
      : data.mid_term
      ? data.mid_term.map((i) => {
          return {
            ...marksList,
            [i.subject_name]: [i.internal, i.external, i.total],
          };
        })
      : data.anual.map((i) => {
          return {
            ...marksList,
            [i.subject_name]: [i.internal, i.external, i.total],
          };
        });

  let subcodeList = {};
  subcodeList = data.mid_term
    ? data.mid_term.map((i) => {
        return { [i.subject_name]: i.subject_code };
      })
    : data.anual.map((i) => {
        return { [i.subject_name]: i.subject_code };
      });

  let gradeList = {};
  gradeList = data.mid_term
    ? data.mid_term.map((i) => {
        return { ...marksList, [i.subject_name]: i.grade };
      })
    : data.anual.map((i) => {
        return { ...marksList, [i.subject_name]: i.grade };
      });

  let attendanceList = {
    student_attendace_count: data ? data.student_attendace_count : "38",
    total_attendace_count: data ? data.total_attendace_count : "40",
  };

  let issueDate =
    data.issue_date !== "N/A" && data.issue_date !== "-"
      ? new Date(data.issue_date).toLocaleDateString("en-GB")
      : data.issue_date;

  return (
    <div className={Styles.body}>
      <div className={Styles.borderContainer}>
        <div className={Styles.container}>
          <div className={type === "template" ? Styles.margin : ""}>
            <Header />
            <StudentInfo info={info} />
            <ScholasticRecord
              gradeCategory={gradeCategory}
              type={type}
              marksList={marksList}
              subcodeList={subcodeList}
              grade={grade}
              completeData={data.anual ? data.anual : data.mid_term}
            />
            <CoscholasticRecord
              gradeCategory={gradeCategory}
              type={type}
              grade={grade}
              gradeList={gradeList}
              attendanceList={attendanceList}
            />
            <Footer issueDate={issueDate} grade={grade} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;

import React, { useCallback, useRef, useState, useEffect } from "react";
import Styles from "../Event/Event.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import ManagementDashboard from "services/management-dashboard/events.service";
import { useClassAndSections2 } from "hooks/classAndSection";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Spinner,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { requestUploadEvents } from "services/common.service";
import DatePicker from "react-datepicker";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { GrEdit, GrDocumentPdf } from "react-icons/gr";
import { GiCancel } from "react-icons/gi";
import moment from "moment";
import { MdPreview, MdOutlineAttachFile } from "react-icons/md";
import { VscWarning, VscFilePdf } from "react-icons/vsc";
const StatisticsRecentActivity = () => {
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const { classAndSections } = useClassAndSections2();
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    description: "",
    date: "",
    media_url: [],
    target_group: [],
  });
  const toast = useToast();
  const params = useParams();
  const [file, setFile] = useState(null);
  const [existingFile, setExistingFile] = useState([]);
  const [images, setImages] = useState([]);
  const [NewInputFields, setNewInputFields] = useState({
    New_target_group: [],
  });
  const [OriginalTargetGroup, setOriginalTargetGroup] = useState([]);
  const [isReplace, setIsReplace] = useState(false);
  const [isAppend, setIsAppend] = useState(false);
  const [newAdd, setNewAdd] = useState(false);
  const [oldImages, setOldImages] = useState([]);
  const [isNewTGUpdated, setNewTGUpdated] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalPreview = useDisclosure();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const uploadFilesToCloud = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestUploadEvents({
        title: inputFields.title,
        file: file,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        setRequestInProgress(false);
        return {
          file_url: Object.values(response),
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchEventDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const fetchEventDetails = async () => {
    try {
      const response = await ManagementDashboard.requestEventsById({
        eventId: params.id,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        const DATE = response?.date;
        let date = DATE && new Date(DATE).toLocaleDateString();
        if (date == "Invalid Date") {
          if (DATE.includes("-")) {
            date = new Date(DATE.split("-").reverse().join("/"));
          } else if (DATE.includes("/")) {
            const [day, month, year] = DATE.split("/");
            const dateString = [month, day, year].join("/");
            date = new Date(dateString);
          }
        } else {
          if (typeof new Date(DATE) === "object") {
            date = new Date(DATE);
          } else {
            date = DATE;
          }
        }

        let tempImage = response.media.map((item) => {
          return item.media_url;
        });
        setOldImages(tempImage);
        setExistingFile(response.media); // response.media already contains signed URLs
        let allImages = [];
        for (let i = 0; i < response.media.length; i++) {
          if (!response.media[i]?.media_url?.includes(".pdf")) {
            allImages.push(response.media[i].media_url);
          }
        }
        setImages(allImages);
        setInputFields({
          title: response.title,
          description: response.description,
          date: date,
          target_group: response.target_group,
          media_url: response.media,
          calendar_replica: response.calendar_replica,
        });
        setOriginalTargetGroup(response.target_group);
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };
  const handleSave = () => {
    if (NewInputFields.New_target_group.length > 0) {
      setInputFields({
        ...inputFields,
        target_group: NewInputFields.New_target_group,
      });
      setNewTGUpdated(true);
      onClose();
    }
  };
  const handleDiscardChanges = () => {
    setInputFields({ ...inputFields, target_group: OriginalTargetGroup });
    setNewTGUpdated(false);
    setNewInputFields({ New_target_group: [] });
  };
  const handleCloseModal = () => {
    setNewInputFields({ New_target_group: [] });
    onClose();
  };
  const handleCheckNewTargetGroup = (event) => {
    let class_array = [...NewInputFields.New_target_group];
    if (event.target.checked) {
      if (event.target.value === "All") class_array = [event.target.value];
      else {
        if (class_array.includes("All")) class_array = ["All"];
        else
          class_array = [
            ...NewInputFields.New_target_group,
            event.target.value,
          ];
      }
    } else {
      class_array.splice(class_array.indexOf(event.target.value), 1);
    }

    setNewInputFields({ ...NewInputFields, New_target_group: class_array });
  };
  const handleDelete = async (event) => {
    event.preventDefault();
    setRequestInProgress(true);

    try {
      const response = await ManagementDashboard.requestDeleteEvent({
        eventId: params.id,
      });
      if (response) {
        toast({
          title: "Deleted Succesfully",
          description: "Deleted Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/management/dashboard/events");
      }
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Delete request failed",
        description: e.msg,
        status: "error",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleInputChange = useCallback(
    (e) => {
      if (typeof e?.currentTarget !== "undefined") {
        const {
          currentTarget: { name, value, files },
        } = e;
        const values = { [name]: value };
        if (files !== null && typeof files !== "undefined") {
          values[name] = files;
        }
        setInputFields({ ...inputFields, [name]: values[name] });
      } else {
        setInputFields({ ...inputFields, date: e });
      }
    },
    [inputFields, setInputFields]
  );

  const handleCheck = (event) => {
    let class_array = [...inputFields.target_group];
    if (event.target.checked) {
      if (event.target.value === "All") class_array = [event.target.value];
      else {
        if (class_array.includes("All")) class_array = ["All"];
        else class_array = [...inputFields.target_group, event.target.value];
      }
    } else {
      class_array.splice(class_array.indexOf(event.target.value), 1);
    }

    setInputFields({ ...inputFields, target_group: class_array });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let fileResponse;
    let medias = [];
    if (file?.length > 0) {
      fileResponse = await uploadFilesToCloud();
      fileResponse?.file_url?.forEach((element) => {
        medias.push({
          media_url: element,
        });
      });
    }
    if (!isReplace) {
      oldImages.forEach((val) => {
        const partBeforeQuestionMark = val.split("?")[0];
        const decodedURL = decodeURIComponent(partBeforeQuestionMark);
        medias.push({ media_url: decodedURL });
      });
    }
    try {
      setRequestInProgress(true);
      const response = await ManagementDashboard.requestUpdateEvent({
        ...inputFields,
        date: moment(inputFields.date && inputFields.date).format("MM/DD/YYYY"),
        media_url: medias,
        eventID: params.id,
      });
      if (response) {
        if (response.error) {
          console.error(response.error);
          toast({
            title: response.error,
            description: "Recheck your inputs",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "bottom-right",
          });
        } else {
          toast({
            title: "Updated Succesfully",
            description: " Event Updated Succesfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          navigate("/management/dashboard/events");
        }
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      toast({
        title: e,
        description: "Recheck your inputs",
        description: e,
        status: "error",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
      console.error(e);
    }
  };
  const highlightSundays = (date) => {
    return date.getDay() === 0;
  };

  const currentMonth = new Date().getMonth();

  const getMonthDates = (month) => {
    const dates = [];
    const currentYear = new Date().getFullYear();
    const daysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
    }
    return dates;
  };

  const highlightDates = getMonthDates(currentMonth);

  const handleFileChange = (e, fieldName) => {
    if (fieldName === "isReplace") {
      setIsReplace(true);
      setIsAppend(false);
    }
    if (fieldName === "isAppend") {
      setIsReplace(false);
      setIsAppend(true);
    }
    if (fieldName === "isAddNew") {
      setNewAdd(true);
    }
    const chosenFile = Array.prototype.slice.call(e.target.files);
    setFile(chosenFile);
    const chosenFiles = Array.from(e.target.files);
    setSelectedImages(chosenFiles);
  };
  const handleRemoveAttachment = () => {
    setImages([]);
    setSelectedImages([]);
    setOldImages([]);
    setIsReplace(false);
    setIsAppend(false);
    setNewAdd(true);
    setExistingFile([]);
    setFile(null);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className={Styles.body}>
      {inputFields.calendar_replica === true ? (
        <div className={Styles.eventNote}>
          <VscWarning
            color="#000080"
            fontSize="30"
            cursor="pointer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isHovered && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                backgroundColor: "rgba(10, 10, 10, 0.84)",
                color: "white",
                padding: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Note:
              </p>
              <p>This event is a replica of Academic Calendar.</p>
              <p>Cannot update the Title, Description and Date fields.</p>
            </div>
          )}
        </div>
      ) : null}
      <div className={Styles.coverImage}>
        {inputFields.media_url?.length > 0 ? (
          <Carousel
            showThumbs={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    left: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.leftCircle}
                >
                  <AiFillLeftCircle />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                    right: 25,
                    fontSize: "50px",
                    color: "#990f0d",
                  }}
                  className={Styles.rightCircle}
                >
                  <AiFillRightCircle />
                </button>
              )
            }
            swipeable={true}
            emulateTouch={true}
          >
            {inputFields.media_url.map((item, index) => {
              return (
                <div key={index} style={{ border: "2px solid black" }}>
                  <img
                    src={item.media_url}
                    className={Styles.carouselImg}
                    alt="attachment-img"
                  ></img>
                </div>
              );
            })}
          </Carousel>
        ) : null}
      </div>
      <div className={Styles.form}>
        <p className={Styles.placeholder}>
          Title <span className={Styles.asterisk}>*</span>
        </p>
        <Textarea
          onChange={handleInputChange}
          required
          type="text"
          id="title"
          name="title"
          placeholder="Enter Title...."
          className={`${Styles.textInput} ${Styles.titleArea}`}
          value={inputFields?.title}
          resize="vertical"
          minHeight="30px"
          overflow="hidden"
        //   isReadOnly={inputFields?.calendar_replica === true}
        isReadOnly
        />
      </div>
      <div className={Styles.form}>
        <p className={Styles.placeholder}>
          Description <span className={Styles.asterisk}>*</span>
        </p>
        <Textarea
          id="description"
          name="description"
          className={Styles.textInput}
          value={inputFields?.description}
          onChange={handleInputChange}
          placeholder="Enter Description...."
          size="lg"
          resize="vertical"
          required
        //   isReadOnly={inputFields?.calendar_replica === true}
        isReadOnly
        />
      </div>

      {isNewTGUpdated ? (
        <div className={Styles.formCheck}>
          <p className={Styles.placeholder}>Target Classes</p>
          <div className={Styles.checkboxes}>
            {classAndSections.map((grade, idx) => (
              <Checkbox
                readOnly
                id="target_group"
                name="target_group"
                isChecked={inputFields?.target_group.includes(grade.class)}
                colorScheme="red"
                key={grade.class + idx}
                value={grade.class}
              >
                <p className={Styles.checkboxText}>{grade.class}</p>
              </Checkbox>
            ))}
          </div>
          <div onClick={handleDiscardChanges} className={Styles.editIcon}>
            <span className={Styles.pencilIcon}>
              <GiCancel />
            </span>
            <span>Discard Changes</span>
          </div>
        </div>
      ) : (
        <div className={Styles.formCheck}>
          <p className={Styles.placeholder}>Target Classes</p>
          <div className={Styles.checkboxes}>
            {classAndSections.map((grade, idx) => (
              <Checkbox
                disabled
                id="target_group"
                name="target_group"
                isChecked={inputFields?.target_group.includes(grade.class)}
                onChange={handleCheck}
                colorScheme="red"
                key={grade.class + idx}
                value={grade.class}
              >
                <p className={Styles.checkboxText}>{grade.class}</p>
              </Checkbox>
            ))}
          </div>

        </div>
      )}

      <div className={Styles.formDate}>
        <p className={Styles.placeholder}>
          Date <span className={Styles.asterisk}>*</span>
        </p>
        <DatePicker
          selected={inputFields.date}
          value={inputFields?.date}
          onChange={handleInputChange}
          placeholderText="DD/MM/YYYY"
          dateFormat="dd/MM/yyyy"
          dayClassName={(date) =>
            highlightSundays(date) ? "react-datepicker__day--sunday" : null
          }
          highlightDates={highlightDates}
          className={Styles.inputField}
        //   disabled={inputFields?.calendar_replica === true}
        disabled
        />
      </div>

      {existingFile
        ? existingFile.map((element, idx) => {
            if (typeof element === "string" && element.includes(".pdf")) {
              return (
                <a
                  className={Styles.attachment}
                  href={element}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <p className={Styles.placeholder}>
                    Attachment {idx + 1}
                    <MdOutlineAttachFile style={{ display: "inline" }} />
                  </p>
                  <VscFilePdf className={Styles.pdfLink} />
                </a>
              );
            }
          })
        : ""}
    </div>
  );
};

export default StatisticsRecentActivity;

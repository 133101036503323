import React, { useEffect, useState } from "react";
import Styles from "./ReportCentre.module.scss";
import Teacher from "assets/icons/teacher.png";
import { Button, Checkbox, Select, useToast } from "@chakra-ui/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { AiOutlinePlusSquare } from "react-icons/ai";
import {
  requestListByTeacherDepartment,
  requestSearchTeacherDetails,
} from "services/admin-dashboard/staff.service";
const StaffReport = () => {
  const [inputFields, setInputFields] = useState({
    teacherDepart: "",
  });
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [data, setData] = useState([]);
  const initialFieldsState = {
    employeeId: true,
    designation: false,
    department: true,
    name: true,
    email: true,
    phoneNumber: true,
    gender: true,
    qualification: true,
    aadharNumber: false,
    address: false,
    bloodGroup: true,
    martialStatus: false,
    dob: false,
    nationality: false,
    motherTongue: false,
    religion: false,
    subReligion: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [showDetails, setShowDetails] = useState({
    employee: true,
    teacher: false,
  });
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const toast = useToast();
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };
  useEffect(() => {
    getTeacherDepartment();
  }, []);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "teacherDepart") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        teacherName: "",
      }));
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const toggleSection = (section) => {
    setShowDetails((prevDetails) => ({
      ...Object.keys(prevDetails).reduce((acc, key) => {
        acc[key] = key === section;
        return acc;
      }, {}),
    }));
  };

  const fileName = inputFields.teacherDepart
    ? `SMU-Staff-Report-${inputFields.teacherDepart}`
    : "SMU-Staff-Report";
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleNAValue = (value) => {
    return value !== "N/A" && value !== "-" ? value : "";
  };
  const handleExportClick = async () => {
    const payload = {
      ...(inputFields.teacherName !== ""
        ? { name: inputFields.teacherName }
        : {}),
      department: inputFields.teacherDepart,
    };
    const res = await requestSearchTeacherDetails(payload);
    if (res) {
      let newArray = [];
      res?.result?.map((item) => {
        let obj = {};
        if (fields.employeeId) {
          obj["Employee ID"] = handleNAValue(item.emp_id);
        }
        if (fields.designation) {
          obj["Designation"] = handleNAValue(item.designation);
        }
        if (fields.department) {
          obj["Department"] = handleNAValue(item.department);
        }
        if (fields.name) {
          obj["Name"] = handleNAValue(item.name);
        }
        if (fields.email) {
          obj["Email"] = handleNAValue(item.email);
        }
        if (fields.phoneNumber) {
          obj["Phone No"] = handleNAValue(item.phoneNumber);
        }
        if (fields.gender) {
          obj["Gender"] = handleNAValue(item.personel.gender);
        }
        if (fields.qualification) {
          obj["Qualification"] = handleNAValue(item.personel.qualification);
        }
        if (fields.aadharNumber) {
          obj["Aadhar Number"] = handleNAValue(item.personel.aadhar_number);
        }
        if (fields.address) {
          obj["Address"] = handleNAValue(item.personel.present_address);
        }
        if (fields.bloodGroup) {
          obj["Blood Group"] = handleNAValue(item.personel.blood_group);
        }
        if (fields.dob) {
          obj["DOB"] = handleNAValue(item.personel.dob);
        }
        if (fields.nationality) {
          obj["Nationality"] = handleNAValue(item.personel.nationality);
        }
        if (fields.motherTongue) {
          obj["Mother Tongue"] = handleNAValue(item.personel.mother_tongue);
        }
        if (fields.religion) {
          obj["Religion"] = handleNAValue(item.personel.religion);
        }
        if (fields.subReligion) {
          obj["Sub Religion"] = handleNAValue(item.personel.sub_religion);
        }
        newArray.push({ ...obj });
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    toast({
      title: "Downloaded Successfully.",
      variant: "left-accent",
      position: "bottom-right",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
    setFields(initialFieldsState);
    setShowDetails((prevDetails) => ({
      employee: true,
      teacher: false,
    }));
    setSelectedFileFormat("xlsx");
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img
                src={Teacher}
                alt="Teacher Icon"
                className={Styles.moduleIcon}
              />
              <p className={Styles.moduleName}>STAFF MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Department <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.teacherDepart}
                    onChange={handleInputChange}
                    name="teacherDepart"
                    className={Styles.dropdownContainer}
                  >
                    <option>All</option>
                    {teacherDepartment.map((data) => (
                      <option value={data.department}>{data.department}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Employee Details
                  </button>
                  {showDetails.employee ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("employee")}
                    />
                  )}
                </div>
              </div>
              {showDetails.employee && (
                <div className={Styles.row}>
                  <Checkbox
                    isChecked={fields.designation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        designation: !fields.designation,
                      });
                    }}
                  >
                    Designation
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.department}
                    onChange={() => {
                      setFields({
                        ...fields,
                        department: !fields.department,
                      });
                    }}
                  >
                    Department
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Teacher Details
                  </button>
                  {showDetails.teacher ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("teacher")}
                    />
                  )}
                </div>
              </div>
              {showDetails.teacher && (
                <>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.gender}
                      onChange={() => {
                        setFields({
                          ...fields,
                          gender: !fields.gender,
                        });
                      }}
                    >
                      Gender
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.qualification}
                      onChange={() => {
                        setFields({
                          ...fields,
                          qualification: !fields.qualification,
                        });
                      }}
                    >
                      Qualification
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.aadharNumber}
                      onChange={() => {
                        setFields({
                          ...fields,
                          aadharNumber: !fields.aadharNumber,
                        });
                      }}
                    >
                      Aadhar No
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.address}
                      onChange={() => {
                        setFields({
                          ...fields,
                          address: !fields.address,
                        });
                      }}
                    >
                      Address
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.bloodGroup}
                      onChange={() => {
                        setFields({
                          ...fields,
                          bloodGroup: !fields.bloodGroup,
                        });
                      }}
                    >
                      Blood Group
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.martialStatus}
                      onChange={() => {
                        setFields({
                          ...fields,
                          martialStatus: !fields.martialStatus,
                        });
                      }}
                    >
                      Martial Status
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.dob}
                      onChange={() => {
                        setFields({
                          ...fields,
                          dob: !fields.dob,
                        });
                      }}
                    >
                      DOB
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.nationality}
                      onChange={() => {
                        setFields({
                          ...fields,
                          nationality: !fields.nationality,
                        });
                      }}
                    >
                      Nationality
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.motherTongue}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherTongue: !fields.motherTongue,
                        });
                      }}
                    >
                      Mother Tongue
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.religion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          religion: !fields.religion,
                        });
                      }}
                    >
                      Religion
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subReligion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subReligion: !fields.subReligion,
                        });
                      }}
                    >
                      Sub Religion
                    </Checkbox>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Teacher}
              alt="Teacher Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>STAFF MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Department <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.mobilefilterWrapper}>
                <Select
                  value={inputFields.teacherDepart}
                  onChange={handleInputChange}
                  name="teacherDepart"
                  className={Styles.dropdownContainer}
                >
                  <option>All</option>
                  {teacherDepartment.map((data) => (
                    <option value={data.department}>{data.department}</option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Employee Details
                  </button>
                  {showDetails.employee ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("employee")}
                    />
                  )}
                </div>
              </div>
              {showDetails.employee && (
                <div className={Styles.mobilerow}>
                  <Checkbox
                    isChecked={fields.designation}
                    onChange={() => {
                      setFields({
                        ...fields,
                        designation: !fields.designation,
                      });
                    }}
                  >
                    Designation
                  </Checkbox>
                  <Checkbox
                    isChecked={fields.department}
                    onChange={() => {
                      setFields({
                        ...fields,
                        department: !fields.department,
                      });
                    }}
                  >
                    Department
                  </Checkbox>
                </div>
              )}
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Teacher Details
                  </button>
                  {showDetails.teacher ? null : (
                    <AiOutlinePlusSquare
                      className={Styles.icons}
                      onClick={() => toggleSection("teacher")}
                    />
                  )}
                </div>
              </div>
              {showDetails.teacher && (
                <>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.gender}
                      onChange={() => {
                        setFields({
                          ...fields,
                          gender: !fields.gender,
                        });
                      }}
                    >
                      Gender
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.qualification}
                      onChange={() => {
                        setFields({
                          ...fields,
                          qualification: !fields.qualification,
                        });
                      }}
                    >
                      Qualification
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.aadharNumber}
                      onChange={() => {
                        setFields({
                          ...fields,
                          aadharNumber: !fields.aadharNumber,
                        });
                      }}
                    >
                      Aadhar No
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.address}
                      onChange={() => {
                        setFields({
                          ...fields,
                          address: !fields.address,
                        });
                      }}
                    >
                      Address
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.bloodGroup}
                      onChange={() => {
                        setFields({
                          ...fields,
                          bloodGroup: !fields.bloodGroup,
                        });
                      }}
                    >
                      Blood Group
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.martialStatus}
                      onChange={() => {
                        setFields({
                          ...fields,
                          martialStatus: !fields.martialStatus,
                        });
                      }}
                    >
                      Martial Status
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.dob}
                      onChange={() => {
                        setFields({
                          ...fields,
                          dob: !fields.dob,
                        });
                      }}
                    >
                      DOB
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.nationality}
                      onChange={() => {
                        setFields({
                          ...fields,
                          nationality: !fields.nationality,
                        });
                      }}
                    >
                      Nationality
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.motherTongue}
                      onChange={() => {
                        setFields({
                          ...fields,
                          motherTongue: !fields.motherTongue,
                        });
                      }}
                    >
                      Mother Tongue
                    </Checkbox>
                  </div>
                  <div className={Styles.mobilerow}>
                    <Checkbox
                      isChecked={fields.religion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          religion: !fields.religion,
                        });
                      }}
                    >
                      Religion
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subReligion}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subReligion: !fields.subReligion,
                        });
                      }}
                    >
                      Sub Religion
                    </Checkbox>
                  </div>
                </>
              )}
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.fileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffReport;

import axios from "axios";
import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";

export async function inActiveTeacherSearch(payload = {}) {
  const url = BASE_URL + "/management/teacher/search-teacher ";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function restoreDeleteTeacher(payload = {}) {
  const url = BASE_URL + "/management/bin/restore-teacher ";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function permanentTeacherDelete(payload = {}) {
  const url = BASE_URL + "/management/teacher/permanently-delete-teacher ";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function renderInactiveTeacherList(payload = {}) {
  const url = BASE_URL + "/management/bin/inactive-teachers";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function renderInactiveStudentList(payload = {}) {
  const url = BASE_URL + "/management/bin/getAll";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

// export async function serachInactiveTeacher (payload={}){
//   const url = BASE_URL + "/management/teacher/search-teacher"
//   try{
//       const response = await axios.post(url,{...payload},getRequestOptions())
//       if(response.data?.error) {
//           return {error: response.data.error}
//       }
//       if (response.status >= 200 && response.status < 400 && response.data) {
//           return response.data
//         }
//   }
//   catch (e){
//       if (e?.response?.data?.error) {
//           throw e.response.data.error
//         }
//         if (e?.response?.data) {
//           throw e.response.data
//         }
//         throw e
//   }
// }

export async function restoreInactiveStudent(payload = {}) {
  const url = BASE_URL + "/management/student/restore-delete-student";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function permanentDeleteInactiveStudent(payload = {}) {
  const url = BASE_URL + "/management/student/permanently-delete-student";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

export async function restoreDepartmentList(payload = {}) {
  const url = BASE_URL + "/management/teacher/list-teacher-department";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function renderDataDepartmentList(payload = {}) {
  const url = BASE_URL + "/management/teacher/search-teacher";
  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

const Services = {
  inActiveTeacherSearch,
  restoreDeleteTeacher,
  permanentTeacherDelete,
  renderInactiveTeacherList,
  renderInactiveStudentList,
  // serachInactiveTeacher,
  restoreInactiveStudent,
  permanentDeleteInactiveStudent,
  restoreDepartmentList,
  renderDataDepartmentList,
};
export default Services;

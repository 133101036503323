import React, { useState } from "react";
import Styles from "./ManualTcPage.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import "react-datepicker/dist/react-datepicker.css";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import { Button, useToast } from "@chakra-ui/react";

const initailvalue = {
  addmissionNo: "",
  STSNo: "",
  TCNo: "",
  name: "",
  gender: "",
  motherName: "",
  fatherName: "",
  dob: "",
  caste: "",
  addmissiondate: "",
  preClass: "",
  bExam: "",
  subject: "",
  qualified: "",
  fees: "",
  feesc: "",
  days: "",
  camp: "",
  games: "",
  genral: "",
  certificateDate: "",
  dateIssue: "",
  reason: "",
  remark: "",
};

const ManualTcPage = () => {
  const params = useParams();
  const toast = useToast();
  const [inputFields, setInputFields] = useState(null);
  const [transferInfo, settransferInfo] = useState(initailvalue);
  const [studentData, setStudentData] = useState("");
  const navigate = useNavigate();
  const fetchStudentProfileDetails = async () => {
    try {
      const response = await requestStudentDetailsById({
        studentId: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }

      // const classroom_response = await requestAllSubjects({ class_id: response._class_id });
      // console.log('calss', classroom_response)
      // if (response && classroom_response) {
      if (response) {
        console.log("student resp", response);
        const DOB = response?.personel?.dob;
        let dob = DOB && new Date(DOB).toLocaleDateString();
        if (dob == "Invalid Date") {
          if (DOB.includes("-")) {
            dob = new Date(DOB.split("-").reverse().join("/"));
          } else if (DOB.includes("/")) {
            const [day, month, year] = DOB.split("/");
            const dateString = [month, day, year].join("/");
            dob = new Date(dateString);
          }
        } else {
          if (typeof new Date(DOB) === "object") {
            dob = new Date(DOB);
          } else {
            dob = DOB;
          }
        }

        const _response = {
          ...response,
          name: response.name,
          email: response.email,
          personel: {
            ...response.personel,
            dob,
            gender: response.personel.gender,
            present_address: response.personel.present_address,
            permanent_address: response.personel.permanent_address,
            mother_tongue: response.personel.mother_tongue,
            religion: response.personel.religion,
            sub_religion: response.personel.sub_religion,
            blood_group: response.personel.blood_group,
            aadhar_number: response.personel.aadhar_number,
            nationlity: response.personel.nationlity,
            signed_display_image: await getSignedUrlLink(
              response.personel.profile_url
            ),
          },
          academic: {
            ...response.academic,
            class: response.academic.class,
            section: response.academic.section,
            admission_number: response.academic.admission_number,
            admission_year: response.academic.admission_year,
          },
          father: {
            ...response.father,
            father_name: response.father.father_name,
            father_email: response.father.father_email,
            father_phone: response.father.father_phone,
            father_occupation: response.father.father_occupation,
            father_qualification: response.father.father_qualification,
            signed_father_display_image: await getSignedUrlLink(
              response.father.father_photo_url
            ),
          },
          mother: {
            ...response.mother,
            mother_name: response.mother.mother_name,
            mother_phone: response.mother.mother_phone,
            mother_occupation: response.mother.mother_occupation,
            mother_qualification: response.mother.mother_qualification,
            signed_mother_display_image: await getSignedUrlLink(
              response.mother.mother_photo_url
            ),
          },
          canteen_choices: response.canteen_choices,
          account_status: response.account_status,
          transport_choices: response.transport_choices,
          // electives: classroom_response.electives,
          subject_choices: response.subject_choices,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  console.log("input feilds", inputFields);

  const transferData = (e) => {
    settransferInfo({ ...transferInfo, [e.target.name]: e.target.value });
    // console.log("transferInfo transferInfo", transferInfo);
  };

  // useEffect(()=>{
  //   fetchStudentProfileDetails()
  // },[])
  // const onSubmit = () => {
  //   //   console.log("id is here",id)
  //   //   console.log("id", studentData)
  //   navigate(`download`, {
  //     state: {
  //       transferInfo,
  //     },
  //   });
  // };
  const onSubmit = () => {
    if (
      !transferInfo.addmissionNo ||
      !transferInfo.STSNo ||
      !transferInfo.TCNo ||
      !transferInfo.name ||
      !transferInfo.gender ||
      !transferInfo.motherName ||
      !transferInfo.fatherName ||
      !transferInfo.dob ||
      !transferInfo.caste ||
      !transferInfo.addmissiondate ||
      !transferInfo.preClass ||
      !transferInfo.bExam ||
      !transferInfo.subject ||
      !transferInfo.qualified ||
      !transferInfo.fees ||
      !transferInfo.feesc ||
      !transferInfo.days ||
      !transferInfo.camp ||
      !transferInfo.games ||
      !transferInfo.genral ||
      !transferInfo.certificateDate ||
      !transferInfo.dateIssue ||
      !transferInfo.reason ||
      !transferInfo.remark
    ) {
      toast({
        title: "Please fill in required fields",
        description: "All fields marked with an asterisk (*) are required.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    navigate(`download`, {
      state: {
        transferInfo,
      },
    });
  };

  return (
    <div className={Styles.allField}>
      <div className={Styles.certificateName}>Transfer Certificate Form</div>
      <div className={Styles.tagContanier}>
        <p>
          Addmission Number <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="123456"
            type="text"
            name="addmissionNo"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          STS Number <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="123456"
            type="text"
            name="STSNo"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          TC Number <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="123456"
            type="text"
            name="TCNo"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Name of Pupil <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="Name"
            type="text"
            name="name"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Gender <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="Gender"
            type="text"
            name="gender"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Mother's Name <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="Mother's Name"
            type="text"
            name="motherName"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>

      <div className={Styles.tagContanier}>
        <p>
          Father's Name <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="Father's Name"
            type="text"
            name="fatherName"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Date of birth (in Chiristian Era) According to the Addmission Register
          (in figures and words) <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            required
            placeholder="15-12-2015 (Fiftheenth day of December Two thousand fifteen)"
            type="text"
            name="dob"
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Nationality / Religion / Caste / Mother Tongue{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Nationality / Religion / Caste / Mother Tongue"
            type="text"
            name="caste"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Date of admission in school with class{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="28-05-2001 Grade 1"
            type="text"
            name="addmissiondate"
            required
            onChange={(e) => transferData(e)}
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Class in which the pupil last studied (in Figure and in words){" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Grade 1"
            type="text"
            name="preClass"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          School / Board Examination last taken{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="March 2023"
            type="text"
            name="bExam"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Subject studied <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="HINDI, ENGLISH, MATHEMATICS, etc "
            type="text"
            name="subject"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Whether qualified for promotion to the higher class if so, to which
          class <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Yes, Grade 2"
            type="text"
            name="qualified"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Month upto which the pupil has paid the school dues{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="March 2023 "
            type="text"
            name="fees"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Any fee concession availed of if so, the nature of so concession{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="0000"
            type="text"
            name="feesc"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Total No. of Present Days / Total No. Working Days{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="198 / 240"
            type="text"
            name="days"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Whether NCC Cadet / Boy Scout / Girl Guide{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder=" Boy Scout / Girl Guide"
            type="text"
            name="camp"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Games played or extra-curricular activities in which the pupil usually
          took part (Mention achievement level there in){" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Mention achievement level "
            type="text"
            name="games"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          General conduct <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Good / Bad"
            type="text"
            name="genral"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Date of application for certificate{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="25-08-2018"
            type="text"
            name="certificateDate"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Date of issue of certificate{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="30-8-2018"
            type="text"
            name="dateIssue"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Reasons for leaving the school{" "}
          <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Reasons "
            type="text"
            name="reason"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Any other remarks <span className={Styles.asterisk}>*</span> :
        </p>
        <div className={Styles.inputTags}>
          <input
            placeholder="Remarks "
            type="text"
            name="remark"
            onChange={(e) => transferData(e)}
            required
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            onSubmit();
          }}
          colorScheme="green"
          mt={2}
          // style={{
          //   marginTop: "2vw",
          //   backgroundColor: "red",
          //   color: "white",
          //   borderRadius: "4px",
          //   padding: "5px 8px 5px 8px",
          //   fontWeight: "bold",
          // }}
        >
          Generate
        </Button>
      </div>
    </div>
  );
};

export default ManualTcPage;

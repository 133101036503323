import React, { useEffect } from 'react'
import Birthday from '../../../components/Birthday/Birthday'
import DashboardFeatures from '../../../components/DashboardFeatures/DashboardFeatures'
import { deleteDoc, doc } from "firebase/firestore";
import { firebaseDB } from "config/firebaseInitialize";


const DashboardLanding = () => {
  const deleteCollections= async()=>{
    const response =await deleteDoc(doc(firebaseDB, "login", "phoneNumber"));

  }
  useEffect(()=>{
    deleteCollections()
  },[])
  return (
    <>
        <Birthday />
        <DashboardFeatures page="student"/>
    </>
  )
}

export default DashboardLanding
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Select, useToast, Tooltip } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import { requestUploadStudentImages } from "services/common.service";
import { requestAdmitStudent } from "services/admin-dashboard/admission.service";
import { fileToURI } from "utilities/utils";

import "react-datepicker/dist/react-datepicker.css";
import Styles from "./admission.module.scss";
import moment from "moment";
import { AiOutlineCheck } from "react-icons/ai";
import { MdContentPaste } from "react-icons/md";

const PLACEHOLDER_IMAGE_URL =
  "https://betachon.com/wp-content/uploads/2019/03/upload-your-logo-here-21052-p-800x800.png";

const AddStudentProfile = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { classAndSections, loading } = useClassAndSectionsWithoutAll();
  const [sections, setSections] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [imageInputField, setImageInputFields] = useState({
    father: { url: "", file: null },
    mother: { url: "", file: null },
    student: { url: "", file: null },
  });
  const [isUpload, setIsUpload] = useState(false);

  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    personel: {
      gender: "",
      dob: "",
      present_address: "",
      permanent_address: "",
      mother_tongue: "",
      religion: "",
      sub_religion: "",
      aadhar_number: "",
      nationlity: "",
    },
    academic: {
      class: "",
      section: "",
      admission_number: "",
      admission_year: "",
    },
    father: {
      father_name: "",
      father_phone: "",
      father_occupation: "",
      father_qualification: "",
    },
    mother: {
      mother_name: "",
      mother_phone: "",
      mother_occupation: "",
      mother_qualification: "",
    },
    canteen_choices: false,
    transport_choices: false,
    account_status: [],
  });

  React.useEffect(() => {
    if (inputFields?.academic?.class) {
      let requiredSections = classAndSections.length
        ? classAndSections
            .filter((item) => item.class == inputFields.academic.class)
            .map((item) => item.sections)
        : [[inputFields.academic.section]];
      setSections(requiredSections[0]);
    }
  }, [inputFields?.academic?.class]);

  const uploadImagesToCloud = async () => {
    try {
      const response = await requestUploadStudentImages({
        student_photo_file: imageInputField.student.file,
        mother_photo_file: imageInputField.mother.file,
        father_photo_file: imageInputField.father.file,
        studentId: inputFields.name,
        phone: inputFields.phoneNumber,
      });
      if (response.error) {
        setRequestInProgress(false);
        return false;
      }
      if (response) {
        return {
          profile_url: response.student_profile || "",
          father_photo_url: response.father_profile || "",
          mother_photo_url: response.mother_profile || "",
        };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    let imageResponse = {};
    if (
      imageInputField.student.file &&
      imageInputField.mother.file &&
      imageInputField.father.file
    ) {
      imageResponse = await uploadImagesToCloud();
    } else if (
      imageInputField.student.file ||
      imageInputField.mother.file ||
      imageInputField.father.file
    ) {
      let uploadedResponse = await uploadImagesToCloud();
      imageResponse = {
        profile_url: uploadedResponse.profile_url
          ? uploadedResponse.profile_url
          : inputFields.personel.profile_url,
        father_photo_url: uploadedResponse.father_photo_url
          ? uploadedResponse.father_photo_url
          : inputFields.father.father_photo_url,
        mother_photo_url: uploadedResponse.mother_photo_url
          ? uploadedResponse.mother_photo_url
          : inputFields.mother.mother_photo_url,
      };
    } else {
      imageResponse = {
        profile_url: inputFields?.personel?.profile_url,
        father_photo_url: inputFields?.father?.father_photo_url,
        mother_photo_url: inputFields?.mother?.mother_photo_url,
      };
    }
    if (imageResponse) {
      createStudentHandler(imageResponse);
    }
  };

  const createStudentHandler = async (imageUrls = {}) => {
    try {
      const {
        email,
        name,
        personel,
        father,
        mother,
        academic,
        account_status,
      } = inputFields;
      const { profile_url, father_photo_url, mother_photo_url } = imageUrls;

      const payload = {
        email,
        name,
        role: "student",
        password: father?.father_phone || mother?.mother_phone,
        phoneNumber: father?.father_phone || mother?.mother_phone,
        academic: { ...academic, stream: "-" },
        personel: {
          ...personel,
          profile_url: imageUrls?.profile_url,
          nationlity: personel.nationlity,
          dob:
            personel.dob &&
            moment(personel.dob, "DD/MM/YYYY").format("MM/DD/YYYY"),
        },
        father: { ...father, father_photo_url },
        mother: { ...mother, mother_photo_url },
        guardian: {
          guardian_name: father.father_name || mother.mother_name,
          guardian_phone: father.father_phone || mother.mother_phone,
          guardian_occupation:
            father.father_occupation || mother.mother_occupation,
          guardian_photo_url: father_photo_url || mother_photo_url,
        },
        account_status,
      };
      const admissionYear = inputFields?.academic?.admission_year;
      if (admissionYear < 2022 || admissionYear > 2024) {
        setRequestInProgress(false);
        toast({
          title: "Invalid Admission Year",
          description: "Admission Year must be between 2022 and 2024.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "bottom-right",
        });
        return;
      }
      const response = await requestAdmitStudent(payload);
      if (response) {
        setRequestInProgress(false);
        toast({
          title: "Student Added",
          description: "Admitted new student",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });

        navigate("/admin/dashboard/admission");
      }
      setRequestInProgress(false);
    } catch (error) {
      console.error(error);
      toast({
        title: "Request Failed",
        description: error,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      setRequestInProgress(false);
    }
  };

  const handleSetImage = (e, fieldName) => {
    if (fieldName === "isUpload") {
      setIsUpload(true);
    }
    const {
      target: { name, files },
    } = e;
    if (files && files[0]) {
      setImageInputFields((prev) => ({
        ...prev,
        [name]: { ...prev[name], file: files[0] },
      }));
      fileToURI(files[0]).then((uri) => {
        setImageInputFields((prev) => ({
          ...prev,
          [name]: { ...prev[name], url: uri },
        }));
      });
    }
  };

  const handleInputChange = (e, parentField) => {
    if (parentField === "pastePermanentAddress") {
      setInputFields((prev) => ({
        ...prev,
        personel: {
          ...prev.personel,
          present_address: prev.personel.permanent_address,
        },
      }));
      return;
    }
    if (typeof e.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, checked, type },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        "select-one": value,
      }[type];

      if (parentField && typeof parentField === "string") {
        setInputFields((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else {
      if (parentField && typeof parentField === "object") {
        const [parent, child] = parentField;
        setInputFields((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: e,
          },
        }));
      }
    }
  };

  if (loading) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={Styles.body}>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        STUDENT ADMISSION FORM
      </p>
      <div className={Styles.profiles}>
        <div className={Styles.profile}>
          <img
            className={Styles.profileImage}
            src={imageInputField.student.url || PLACEHOLDER_IMAGE_URL}
            alt="profile"
          />
          {isUpload ? (
            <span className={Styles.pencilIcon}>
              <AiOutlineCheck />
            </span>
          ) : (
            ""
          )}
          <label htmlFor="student">
            <div
              style={{
                borderRadius: "5px",
                marginTop: "8px",
                borderRadius: "5px",
                padding: "8px",
                marginTop: "8px",
                cursor: "pointer",
                background: "#228b15",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                color: "white",
                fontWeight: "700",
              }}
            >
              Add Profile
            </div>
            <input
              type="file"
              id="student"
              name="student"
              accept="image/*"
              hidden
              onChange={(e) => handleSetImage(e, "isUpload")}
            />
          </label>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <button type="button" className={Styles.heading}>
            Personal Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Name <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.name}
                name="name"
                type="text"
                placeholder="Enter Student Name"
                className={Styles.textInput}
                pattern="[A-Za-z.'\s]{4,}"
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                  )
                }
                onInput={(e) => {
                  e.target.setCustomValidity("");
                }}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Email <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                name="email"
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields.email}
                type="email"
                placeholder="Enter Email"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Gender <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                name="gender"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.gender}
                required
                placeholder="Select Gender"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Aadhaar Number <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                name="aadhar_number"
                type="number"
                placeholder="XXXX XXXX XXXX"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.aadhar_number}
                className={Styles.textInput}
                minlength="12"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Permanent Address <span className={Styles.asterisk}>*</span>
              </p>
              <textarea
                required
                name="permanent_address"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.permanent_address}
                rows="4"
                cols="50"
                className={Styles.textInput}
                placeholder="Enter Permanent Address"
              />
            </div>
            <div className={Styles.form}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p className={Styles.placeholder}>
                  Present Address <span className={Styles.asterisk}>*</span>
                </p>
                <Tooltip
                  placement="top"
                  label="Paste the same data of Permanent Address"
                >
                  <div
                    onClick={() =>
                      handleInputChange(null, "pastePermanentAddress")
                    }
                  >
                    <MdContentPaste fontSize={22} cursor="pointer" />
                  </div>
                </Tooltip>
              </div>
              <textarea
                required
                name="present_address"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.present_address}
                rows="4"
                cols="50"
                className={Styles.textInput}
                placeholder="Enter Present Address"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Blood Group <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                onChange={(e) => handleInputChange(e, "personel")}
                name="blood_group"
                value={inputFields.personel?.blood_group}
                placeholder="Select Blood Group"
                required
              >
                <option value="A(+VE)">A(+VE)</option>
                <option value="B(+VE)">B(+VE)</option>
                <option value="A(-VE)">A(-VE)</option>
                <option value="B(-VE)">B(-VE)</option>
                <option value="O(+VE)">O(+VE)</option>
                <option value="O(-VE)">O(-VE)</option>
                <option value="AB(+VE)">AB(+VE)</option>
                <option value="AB(-VE)">AB(-VE)</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Date of Birth <span className={Styles.asterisk}>*</span>
              </p>
              <DatePicker
                required
                name="dob"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                onChange={(e) => handleInputChange(e, ["personel", "dob"])}
                value={inputFields.personel.dob}
                selected={inputFields.personel.dob}
              />
            </div>
          </div>
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />

          <button type="button" className={Styles.heading}>
            Admission Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Admission ID <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "academic")}
                value={inputFields.academic.admission_number}
                name="admission_number"
                placeholder="Enter Admission ID "
                className={Styles.textInput}
              />
            </div>

            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Admission Year <span className={Styles.asterisk}>*</span>
              </p>
              <input
                required
                onChange={(e) => handleInputChange(e, "academic")}
                value={inputFields.academic.admission_year}
                name="admission_year"
                placeholder="Enter Admission Year "
                className={Styles.textInput}
              />
            </div>

            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Class <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                required
                name="class"
                onChange={(e) => handleInputChange(e, "academic")}
                value={inputFields.academic.class}
                placeholder="Select Grade"
              >
                {classAndSections
                  .filter((item) => item.class !== "All")
                  .map((item, idx) => (
                    <option key={item.class + idx} value={item.class}>
                      Grade {item.class}
                    </option>
                  ))}
              </Select>
            </div>

            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Section <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                isRequired
                onChange={(e) => handleInputChange(e, "academic")}
                value={inputFields.academic.section}
                name="section"
                placeholder="Select Section"
              >
                {sections
                  .filter((item) => item !== "All")
                  .map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <button className={Styles.heading} type="button">
            Other Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Nationality <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                required
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.nationlity}
                placeholder="Select Nationality"
                name="nationlity"
              >
                <option value="INDIAN">INDIAN</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Mother Tongue <span className={Styles.asterisk}>*</span>
              </p>
              <input
                type="text"
                required
                placeholder="Enter Mother Tongue"
                name="mother_tongue"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.mother_tongue}
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Religion <span className={Styles.asterisk}>*</span>
              </p>
              <Select
                required
                name="religion"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.religion}
                placeholder="Select Religion"
              >
                <option value="HINDU">HINDU</option>
                <option value="MUSLIM">MUSLIM</option>
                <option value="CHRISTIAN">CHRISTIAN</option>
                <option value="OTHER">OTHER</option>
              </Select>
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Sub-Religion <span className={Styles.asterisk}>*</span>
              </p>
              <input
                name="sub_religion"
                onChange={(e) => handleInputChange(e, "personel")}
                value={inputFields.personel.sub_religion}
                required
                type="text"
                placeholder="Enter Sub-Religion"
                className={Styles.textInput}
              />
            </div>
          </div>
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <button type="button" className={Styles.heading}>
            Father Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Father Name <span className={Styles.asterisk}>*</span>
              </p>
              <input
                name="father_name"
                onChange={(e) => handleInputChange(e, "father")}
                value={inputFields.father.father_name}
                required
                type="text"
                className={Styles.textInput}
                pattern="[A-Za-z.'\s]{4,}"
                placeholder="Enter Father Name"
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                  )
                }
                onInput={(e) => {
                  e.target.setCustomValidity("");
                }}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Qualification</p>
              <input
                type="text"
                placeholder="Enter Qualification"
                name="father_qualification"
                onChange={(e) => handleInputChange(e, "father")}
                value={inputFields.father.father_qualification}
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Occupation <span className={Styles.asterisk}>*</span>
              </p>
              <input
                onChange={(e) => handleInputChange(e, "father")}
                value={inputFields.father.father_occupation}
                required
                type="text"
                name="father_occupation"
                className={Styles.textInput}
                placeholder="Enter Occupation"
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Phone No. <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex" }}>
                <select className={Styles.optionLists}>
                  <option value="+91">+91</option>
                </select>
                <input
                  name="father_phone"
                  onChange={(e) => handleInputChange(e, "father")}
                  value={inputFields.father.father_phone}
                  required
                  type="number"
                  placeholder="XXXXX XXXXX"
                  className={Styles.textInput}
                  maxLength="10"
                />
              </div>
            </div>
          </div>
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <button type="button" className={Styles.heading}>
            Mother Details
          </button>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Mother Name <span className={Styles.asterisk}>*</span>
              </p>
              <input
                name="mother_name"
                onChange={(e) => handleInputChange(e, "mother")}
                value={inputFields.mother.mother_name}
                required
                type="text"
                placeholder="Enter Mother Name"
                className={Styles.textInput}
                minlength="4"
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Name must be at least 4 characters and can only contain alphabets, dots, and apostrophes."
                  )
                }
                onInput={(e) => {
                  e.target.setCustomValidity("");
                }}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Qualification</p>
              <input
                name="mother_qualification"
                onChange={(e) => handleInputChange(e, "mother")}
                value={inputFields.mother.mother_qualification}
                type="text"
                placeholder="Enter Qualification"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Occupation</p>
              <input
                name="mother_occupation"
                onChange={(e) => handleInputChange(e, "mother")}
                value={inputFields.mother.mother_occupation}
                type="text"
                placeholder="Enter Occupation"
                className={Styles.textInput}
              />
            </div>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>
                Phone No. <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex" }}>
                <select className={Styles.optionLists}>
                  <option value="+91">+91</option>
                </select>
                <input
                  name="mother_phone"
                  onChange={(e) => handleInputChange(e, "mother")}
                  value={inputFields.mother.mother_phone}
                  required
                  type="number"
                  placeholder="XXXXX XXXXX"
                  className={Styles.textInput}
                  maxLength="10"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.buttonRow}>
          <button
            type="submit"
            className={Styles.button}
            disabled={requestInProgress}
          >
            {requestInProgress ? <Spinner /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddStudentProfile;

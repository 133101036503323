import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import {
  requestAddElectiveSubject,
  requestAddSubject,
  requestAllSubjects,
  requestDeleteClassroom,
  requestDeleteElective,
  requestDeleteSubject,
  requestEditElective,
  requestEditSubject,
  requestUpdateClassTeacher,
  requestUpdateSubjectMarks,
  requestAllSubjectMarks,
} from "services/admin-dashboard/classroom.service";
import Add_new from "assets/icons/Add_new.png";
import editIcon from "../../../assets/icons/editIcon.png";
import deleteIcon from "../../../assets/icons/deleteIcon.png";
import defaultUser from "assets/icons/User.png";
import { TbReportAnalytics, TbExternalLink } from "react-icons/tb";
import Styles from "./Classroom.module.scss";
import { requestSearchTeacher } from "services/admin-dashboard/staff.service";

const SingleClass = () => {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const chooseSubj = useDisclosure();
  const addRegular = useDisclosure();
  const addElective = useDisclosure();
  const editElectiveModal = useDisclosure();
  const {
    isOpen: maxIsOpen,
    onOpen: maxOnOpen,
    onClose: maxOnClose,
  } = useDisclosure();
  const updateClassTeacherModal = useDisclosure();
  const delClass = useDisclosure();
  const editRegular = useDisclosure();
  const delRegular = useDisclosure();
  const delElective = useDisclosure();
  const [classroomDetails, setClassroomDetails] = useState(null);
  const [inputFields, setInputFields] = useState({
    regular: { subject: "", facultyId: "" },
  });
  const [inputFieldsElectives, setInputFieldsElectives] = useState({
    electiveName: "",
    subjectOne: "",
    subjectCodeOne: "",
    facultyOne: "",
    subjectTwo: "",
    subjectCodeTwo: "",
    facultyTwo: "",
  });
  const [deleteFields, setDeleteFields] = useState({});
  const [updateFields, setUpdateFields] = useState({});
  const [updateElectives, setUpdateElectives] = useState([]);
  const [deleteElectives, setDeleteElectives] = useState({});
  const [allTeachers, setAllTeachers] = useState([]);
  const [showAddSearchIcon, setShowAddSearchIcon] = useState(true);
  const [showAddEleSearchIcon, setShowAddEleSearchIcon] = useState(true);
  const [showAddEle2SearchIcon, setShowAddEle2SearchIcon] = useState(true);
  const [showEditEleSearchIcon, setShowEditEleSearchIcon] = useState(true);
  const [showEditEle2SearchIcon, setShowEditEle2SearchIcon] = useState(true);
  const [searchTeacherList, setSearchTeacherList] = useState([]);
  const [searchTeacherElecList, setSearchTeacherElecList] = useState([]);
  const [searchTeachers, setSearchTeachers] = useState("");
  const [searchElective2Teachers, setSearchElective2Teachers] = useState("");
  const [subjectInModal, setSubjectInModal] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showClsTeacherUpdateSearchIcon, setShowClsTeacherUpdateSearchIcon] =
    useState(true);
  const [updateTeacherSearchIcon, setUpdateTeacherSearchIcon] = useState(true);
  const [radioValue, setRadioValue] = useState("");
  const [updateClassTeacher, setUpdateClassTeacher] = useState({});
  const [addButton, setAddButton] = useState("");
  const [requestInProgressModal, setRequestInProgressModal] = useState(false);
  const [maxAnual, setMaxAnual] = useState({
    total: 100,
    internal: 20,
    external: 80,
  });

  const [maxMid, setMaxMid] = useState({
    total: 50,
    internal: 10,
    external: 40,
  });

  const [marksArray, setMarksArray] = useState([
    { maxRange: 100, minRange: 0, grade: "" },
  ]);
  const [marksMidArray, setMarksMidArray] = useState([
    { maxMidRange: 50, minMidRange: 0, grade: "" },
  ]);
  const handleAddMidObject = () => {
    const minMidValue = marksMidArray[marksMidArray.length - 1]?.minMidRange;
    const gradeValue = marksMidArray[marksMidArray.length - 1].grade;
    if (gradeValue === "") {
      toast({
        title: "Please fill in the grade field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    } else if (minMidValue > 0) {
      const newObject = {
        maxMidRange: (minMidValue - 0.5).toFixed(1),
        minMidRange: 0,
        grade: "",
      };
      const newArray = [...marksMidArray, newObject];
      setMarksMidArray(newArray);
    }
  };

  const handleRemoveMidObject = (index) => {
    const newArray = [...marksMidArray];
    newArray.splice(index, 1);
    // setMarksMidArray(newArray);
    newArray.forEach((item, i) => {
      if (i >= index) {
        if (i === 0) {
          item.maxMidRange = maxMid.total;
        } else {
          item.maxMidRange = newArray[i - 1].minMidRange - 0.5;
        }
      }
    });
    setMarksMidArray(newArray);
  };

  const handleMinMidValRange = (e, id) => {
    const newArray = [...marksMidArray];
    newArray[id][e.target.name] = e.target.value;
    setMarksMidArray(newArray);
  };

  const handleMidGrade = (e, id) => {
    const newArray = [...marksMidArray];
    newArray[id][e.target.name] = e.target.value.toUpperCase();
    setMarksMidArray(newArray);
  };
  const handleAddObject = () => {
    const minValue = marksArray[marksArray.length - 1].minRange;
    const gradeValue = marksArray[marksArray.length - 1].grade;

    if (gradeValue === "") {
      toast({
        title: "Please fill in the grade field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    } else if (minValue > 0) {
      const newObject = {
        maxRange: minValue - 1,
        minRange: 0,
        grade: "",
      };
      const newArray = [...marksArray, newObject];
      setMarksArray(newArray);
    }
  };

  const handleRemoveObject = (index) => {
    const newArray = [...marksArray];
    newArray.splice(index, 1);
    // setMarksArray(newArray);
    newArray.forEach((item, i) => {
      if (i >= index) {
        if (i === 0) {
          item.maxRange = maxAnual.total;
        } else {
          item.maxRange = newArray[i - 1].minRange - 1;
        }
      }
    });
    setMarksArray(newArray);
  };

  const handleMinValRange = (e, id) => {
    const newArray = [...marksArray];
    newArray[id][e.target.name] = e.target.value;
    setMarksArray(newArray);
  };

  const handleGrade = (e, id) => {
    const newArray = [...marksArray];
    newArray[id][e.target.name] = e.target.value.toUpperCase();
    setMarksArray(newArray);
  };
  const handleAllSubjectMarks = async () => {
    try {
      setRequestInProgressModal(true);
      const payload = {
        subject_name: subjectInModal.subject,
      };
      const response = await requestAllSubjectMarks(params.id, payload);
      if (response) {
        setRequestInProgressModal(true);
        setAddButton(response.msg);
        setRequestInProgressModal(false);
      }
      setMaxAnual({
        total: response.max_marks.annual,
        internal: response.internal_marks.annual,
        external: response.external_marks.annual,
      });
      setMaxMid({
        total: response.max_marks.mid_term,
        internal: response.internal_marks.mid_term,
        external: response.external_marks.mid_term,
      });

      const annualGrading = response.grading.filter(
        (item) => item.exam_type === "ANNUAL"
      );
      const midTermGrading = response.grading.filter(
        (item) => item.exam_type === "MID_TERM"
      );

      setMarksArray(
        annualGrading.map((item) => ({
          maxRange: item.upper_limit,
          minRange: item.lower_limit,
          grade: item.grade,
        }))
      );

      setMarksMidArray(
        midTermGrading.map((item) => ({
          maxMidRange: item.upper_limit,
          minMidRange: item.lower_limit,
          grade: item.grade,
        }))
      );
      setRequestInProgressModal(false);
    } catch (e) {}
  };
  useEffect(() => {
    if (subjectInModal && subjectInModal.is_scholastic) {
      handleAllSubjectMarks();
    }
  }, [subjectInModal]);

  const handleSubmit = async () => {
    try {
      const payload = {
        subject_name: subjectInModal?.subject,
        updateBody: {
          max_marks: {
            annual: maxAnual?.total,
            mid_term: maxMid?.total,
          },
          external_marks: {
            annual: maxAnual?.external,
            mid_term: maxMid?.external,
          },
          internal_marks: {
            annual: maxAnual?.total - maxAnual?.external,
            mid_term: maxMid?.total - maxMid?.external,
          },
          grading: marksArray
            .map((item) => ({
              exam_type: "annual",
              lower_limit: item?.minRange,
              upper_limit: item?.maxRange,
              grade: item.grade,
            }))
            .concat(
              marksMidArray.map((item) => ({
                exam_type: "mid_term",
                lower_limit: item?.minMidRange,
                upper_limit: item?.maxMidRange,
                grade: item.grade,
              }))
            ),
        },
        subject_code: subjectInModal.subject_code,
      };
      if (
        marksArray.some((item) => !item.grade) ||
        marksMidArray.some((item) => !item.grade)
      ) {
        toast({
          title: "Please fill in the grade field.",
          variant: "left-accent",
          position: "bottom-right",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
        return;
      }
      const response = await requestUpdateSubjectMarks(params.id, payload);
      setMaxAnual({
        total: 100,
        internal: 20,
        external: 80,
      });
      setMaxMid({
        total: 50,
        internal: 10,
        external: 40,
      });
      setMarksArray([{ maxRange: 100, minRange: 0, grade: "" }]);
      setMarksMidArray([{ maxMidRange: 50, minMidRange: 0, grade: "" }]);
      setSubjectInModal("");
      setAddButton("");
      maxOnClose();
      toast({
        title: response.message,
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e.msg,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const requestPageDetails = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        class_id: params.id,
      };
      const response = await requestAllSubjects(payload);
      setClassroomDetails(response);
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  const fetchTeachers = async () => {
    try {
      const response = await requestSearchTeacher({
        name: searchTeachers,
      });
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setAllTeachers(_result);
      setSearchTeacherList(searchTeachers == "" ? [] : _result);
      return _result;
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTeachersElectives = async () => {
    try {
      const response = await requestSearchTeacher({
        name: searchElective2Teachers,
      });
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setAllTeachers(_result);
      setSearchTeacherElecList(searchElective2Teachers == "" ? [] : _result);
      return _result;
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    requestPageDetails();
    fetchTeachers();
  }, [params.id]);
  const getSubjectPayload = (
    type,
    subjectKey,
    subjectCodeKey,
    scholasticKey,
    teacherIdKey
  ) => {
    let inputType =
      type === "elective" ? inputFieldsElectives : inputFields.regular;
    const payload = {
      class_id: classroomDetails._id,
      section: classroomDetails.section,
      class: classroomDetails.class,
      subject: inputType[subjectKey],
      subject_code: inputType[subjectCodeKey],
      is_scholastic: inputType[scholasticKey],
      teacher_name:
        allTeachers.find((i) => i._id === inputType[teacherIdKey])?.name || "",
      teacher_id: inputType[teacherIdKey],
    };

    return payload;
  };
  const handleMaxModal = (subj) => {
    setSubjectInModal(subj);
    maxOnOpen();
  };

  const handleRegularInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({
      ...prev,
      regular: { ...prev.regular, [name]: value },
    }));
  };
  const addRegularSubjectForm = async (e) => {
    // e.preventDefault();
    if (!classroomDetails._id) {
      console.error("Classroom ID is required.");
      return;
    }
    const subjectPayload = getSubjectPayload(
      "regular",
      "subject",
      "subject_code",
      "is_scholastic",
      "facultyId"
    );
    if (!subjectPayload.subject) {
      toast({
        title: "Subject name is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!subjectPayload.is_scholastic) {
      toast({
        title: "Subject type is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!subjectPayload.teacher_id) {
      toast({
        title: "Faculty is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      setRequestInProgress(true);
      const response = await requestAddSubject({
        class_id: classroomDetails._id,
        subject: {
          ...getSubjectPayload(
            "regular",
            "subject",
            "subject_code",
            "is_scholastic",
            "facultyId"
          ),
        },
      });

      if (response) {
        setRequestInProgress(false);
        addRegular.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (e) {
      setRequestInProgress(false);
      toast({
        title: e,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(e);
    }
    addRegular.onClose();
    chooseSubj.onClose();
  };
  const handleAddSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setShowAddSearchIcon(false);
    }
  };
  const handleEnterKeyPressAdd = (event) => {
    if (event.key === "Enter") {
      handleAddSearchClick();
    }
  };
  const handleAddCloseIconClick = () => {
    setSearchTeachers("");
    setSearchTeacherList([]);
    setShowAddSearchIcon(true);
  };
  const handleAddEleSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setShowAddEleSearchIcon(false);
    }
  };
  const handleEnterKeyPressAddEle = (event) => {
    if (event.key === "Enter") {
      handleAddEleSearchClick();
    }
  };
  const handleAddEleCloseIconClick = () => {
    setSearchTeachers("");
    setSearchTeacherList([]);
    setShowAddEleSearchIcon(true);
  };
  const handleAddEle2SearchClick = () => {
    if (searchElective2Teachers !== "") {
      fetchTeachersElectives();
      setShowAddEle2SearchIcon(false);
    }
  };
  const handleEnterKeyPressAddEle2 = (event) => {
    if (event.key === "Enter") {
      handleAddEle2SearchClick();
    }
  };
  const handleAddEle2CloseIconClick = () => {
    setSearchElective2Teachers("");
    setSearchTeacherElecList([]);
    setShowAddEle2SearchIcon(true);
  };
  const handleEditEleSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setShowEditEleSearchIcon(false);
    }
  };
  const handleEnterKeyPressEditEle = (event) => {
    if (event.key === "Enter") {
      handleEditEleSearchClick();
    }
  };
  const handleEditEleCloseIconClick = () => {
    setSearchTeachers("");
    setSearchTeacherList([]);
    setShowEditEleSearchIcon(true);
  };
  const handleEditEle2SearchClick = () => {
    if (searchElective2Teachers !== "") {
      fetchTeachersElectives();
      setShowEditEle2SearchIcon(false);
    }
  };
  const handleEnterKeyPressEditEle2 = (event) => {
    if (event.key === "Enter") {
      handleEditEleSearchClick();
    }
  };
  const handleEditEle2CloseIconClick = () => {
    setSearchElective2Teachers("");
    setSearchTeacherElecList([]);
    setShowEditEle2SearchIcon(true);
  };
  const handleUpdateClassTeacherSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setShowClsTeacherUpdateSearchIcon(false);
    }
  };
  const handleEnterKeyPressUpdateClsTeacher = (event) => {
    if (event.key === "Enter") {
      handleUpdateClassTeacherSearchClick();
    }
  };
  const handleUpdateClassTeacherCloseIconClick = () => {
    setSearchTeachers("");
    setSearchTeacherList([]);
    setShowClsTeacherUpdateSearchIcon(true);
  };
  const handleUpdateTeacherSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setUpdateTeacherSearchIcon(false);
    }
  };
  const handleEnterKeyPressUpdate = (event) => {
    if (event.key === "Enter") {
      handleUpdateTeacherSearchClick();
    }
  };
  const handleUpdateTeacherCloseIconClick = () => {
    setSearchTeachers("");
    setSearchTeacherList([]);
    setUpdateTeacherSearchIcon(true);
  };
  const handleUpdateClassTeacher = async (e) => {
    e.preventDefault();

    try {
      setRequestInProgress(true);
      const payload = {
        class_id: params.id,
        teacher_id: updateClassTeacher?.facultyId,
      };
      const response = await requestUpdateClassTeacher(payload);
      if (response) {
        setRequestInProgress(false);
        requestPageDetails();
        updateClassTeacherModal.onClose();
        toast({
          title: "Updated class teacher Successfully!!",
          variant: "left-accent",
          position: "top-right",
          status: "success",
          duration: 1500,
          isClosable: true,
        });
      }
    } catch (e) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        variant: "left-accent",
        position: "top-right",
        status: "error",
        duration: 1500,
        isClosable: true,
      });
      console.error(e);
    }
  };
  const handleUpdateTeacherInputChange = ({ target: { name, value } }) => {
    setUpdateClassTeacher((p) => ({
      ...p,
      [name]: value,
    }));
  };
  const requestClassDelete = () => {
    delClass.onOpen();
  };
  const handleDeleteClass = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestDeleteClassroom({ classId: params.id });
      if (response) {
        if (response?.error) {
          console.error(response.error);
        }
        setRequestInProgress(false);
        navigate("/admin/dashboard/classroom");
      }
      delClass.onClose();
    } catch (e) {
      delClass.onClose();
      console.error(e);
      setRequestInProgress(false);
    }
  };

  const handleEditSubject = (subj) => {
    setUpdateFields({ ...subj, new_subject: subj.subject });
    setRadioValue(subj.is_scholastic);
    editRegular.onOpen();
  };
  const handleEditRegularInputChange = ({ target: { name, value, id } }) => {
    if (name === "teacher_name") {
      let selectedTeacher = allTeachers.find(
        (teacher) => teacher.name === value
      );
      if (selectedTeacher) {
        setUpdateFields((prev) => ({
          ...prev,
          [name]: value,
          teacher_id: selectedTeacher._id,
        }));
      }
    } else {
      setUpdateFields((prev) => ({ ...prev, [name]: value }));
    }
  };
  const updateRegularSubjectForm = async (e) => {
    // e.preventDefault();
    if (!updateFields.subject) {
      toast({
        title: "Subject name is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!updateFields.teacher_id) {
      toast({
        title: "Faculty is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      setRequestInProgress(true);
      const payload = {
        subject: updateFields.subject,
        new_subject: updateFields.new_subject,
        subject_code: updateFields.subject_code || "-",
        is_scholastic: updateFields.is_scholastic,
        class_id: classroomDetails._id,
        teacher_id: updateFields.teacher_id,
      };
      const update_response = await requestEditSubject(payload);
      if (update_response) {
        setRequestInProgress(false);
        setUpdateFields({});
        editRegular.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleDeleteSubject = (subj) => {
    setDeleteFields(subj);
    delRegular.onOpen();
  };
  const deleteRegularSubjectForm = async (e) => {
    e.preventDefault();
    try {
      setRequestInProgress(true);
      const payload = {
        class: classroomDetails.class,
        section: classroomDetails.section,
        subject: deleteFields.subject,
      };
      const del_response = await requestDeleteSubject(payload);
      if (del_response) {
        setRequestInProgress(false);
        delRegular.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
    delRegular.onClose();
    chooseSubj.onClose();
  };
  const handleElectivesInputChange = ({ target: { name, value } }) => {
    setInputFieldsElectives({
      ...inputFieldsElectives,
      [name]: value,
    });
  };
  const addElectiveSubjectForm = async (e) => {
    // e.preventDefault();
    if (!classroomDetails._id) {
      console.error("Classroom ID is required.");
      return;
    }
    if (!inputFieldsElectives || !inputFieldsElectives.electiveName) {
      toast({
        title: "Elective name is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    const subjectOnePayload = getSubjectPayload(
      "elective",
      "subjectOne",
      "subjectCodeOne",
      "isScholasticOne",
      "facultyOne"
    );

    const subjectTwoPayload = getSubjectPayload(
      "elective",
      "subjectTwo",
      "subjectCodeTwo",
      "isScholasticTwo",
      "facultyTwo"
    );
    if (!subjectOnePayload.is_scholastic || !subjectTwoPayload.is_scholastic) {
      toast({
        title: "Subject type is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!subjectOnePayload.subject || !subjectTwoPayload.subject) {
      toast({
        title: "Subject name is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    if (!subjectOnePayload.teacher_id || !subjectTwoPayload.teacher_id) {
      toast({
        title: "Faculty is required field.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      setRequestInProgress(true);
      const payload = {
        class_id: classroomDetails._id,
        elective_name: inputFieldsElectives.electiveName,
        subjects: [subjectOnePayload, subjectTwoPayload],
      };
      const response = await requestAddElectiveSubject(payload);
      if (response) {
        setRequestInProgress(false);
        addElective.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: e,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
    }
  };
  const handleEditElective = (elec) => {
    editElectiveModal.onOpen();
    let tempData = {
      electiveHead: elec.elective_name,
      electiveName: elec.elective_name,
      subjectOne: elec.subjects[0].subject,
      subjectCodeOne: elec.subjects[0].subject_code || "-",
      facultyOne: elec.subjects[0].teacher_name,
      scholasticOne: elec.subjects[0].is_scholastic.toString(),
      subjectTwo: elec.subjects[1].subject,
      subjectCodeTwo: elec.subjects[1].subject_code || "-",
      facultyTwo: elec.subjects[1].teacher_name,
      scholasticTwo: elec.subjects[1].is_scholastic.toString(),
    };
    setUpdateElectives(tempData);
  };
  const handleEditElectiveInputChange = ({ target: { name, value } }) => {
    setUpdateElectives({
      ...updateElectives,
      [name]: value,
    });
  };
  const updateElectiveForm = async (e) => {
    // e.preventDefault();

    try {
      setRequestInProgress(true);
      const facultyOneId = allTeachers.find(
        (teacher) => teacher.name === updateElectives.facultyOne
      )?._id;
      const facultyTwoId = allTeachers.find(
        (teacher) => teacher.name === updateElectives.facultyTwo
      )?._id;
      const payload = {
        class_id: classroomDetails._id,
        elective_name: updateElectives.electiveHead,
        new_elective_name: updateElectives.electiveName,
        subjects: [
          {
            subject: updateElectives.subjectOne,
            subject_code: updateElectives.subjectCodeOne,
            is_scholastic: updateElectives.scholasticOne,
            teacher_name: updateElectives.facultyOne,
            teacher_id: facultyOneId,
          },
          {
            subject: updateElectives.subjectTwo,
            subject_code: updateElectives.subjectCodeTwo,
            is_scholastic: updateElectives.scholasticTwo,
            teacher_name: updateElectives.facultyTwo,
            teacher_id: facultyTwoId,
          },
        ],
      };
      const update_response = await requestEditElective(payload);
      if (update_response) {
        setRequestInProgress(false);
        editElectiveModal.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleDeleteElective = (elec) => {
    setDeleteElectives(elec);
    delElective.onOpen();
  };
  const deleteElectiveForm = async (e) => {
    e.preventDefault();
    try {
      setRequestInProgress(true);
      const payload = {
        class: classroomDetails.class,
        section: classroomDetails.section,
        elective_name: deleteElectives.elective_name,
      };
      const del_response = await requestDeleteElective(payload);
      if (del_response) {
        setRequestInProgress(false);
        delElective.onClose();
        chooseSubj.onClose();
        requestPageDetails();
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
    delRegular.onClose();
    chooseSubj.onClose();
  };
  const handleAddRegularSubject = () => {
    addRegular.onOpen();
  };
  const handleAddElectiveSubject = () => {
    addElective.onOpen();
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  if (requestInProgress) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }
  if (!classroomDetails) {
    return null;
  }
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.body}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>
          Class : {classroomDetails?.class} | Section :{" "}
          {classroomDetails?.section}
        </p>
        <div className={Styles.header}>
          <div className={Styles.add} onClick={chooseSubj.onOpen}>
            <img className={Styles.addImage} src={Add_new} alt="add" />
          </div>
          <Button
            onClick={() => updateClassTeacherModal.onOpen()}
            colorScheme="green"
          >
            Update
          </Button>
          <Button onClick={requestClassDelete} colorScheme="red">
            Delete
          </Button>
        </div>
      </div>
      <div>
        <h1 className={Styles.heading}>Class Teacher</h1>
        <div className={Styles.Classlists}>
          <div className={Styles.list}>
            <div className={Styles.profile}>
              <img
                className={Styles.profileImg}
                src={
                  classroomDetails?.class_teacher?.teacher_profile_url != "N/A"
                    ? classroomDetails?.class_teacher?.teacher_profile_url
                    : defaultUser
                }
                alt="profile"
              />
              <Tooltip label="Staff Profile" placement="bottom">
                <Link to={classroomDetails?.class_teacher?.teacher_id}>
                  <TbExternalLink fontSize={25} style={{ marginTop: "10px" }} />
                </Link>
              </Tooltip>
            </div>
            <div className={Styles.info}>
              <p className={Styles.name}>
                {classroomDetails?.class_teacher?.name}
              </p>
            </div>
          </div>
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Regular Subjects({classroomDetails?.subjects.length})
        </h1>
        <div className={Styles.lists}>
          {classroomDetails?.subjects.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            classroomDetails?.subjects?.map((subj, index) => (
              <div key={subj["teacher_id"] + index} className={Styles.list}>
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={
                      subj.teacher_profile_url !== "N/A"
                        ? subj.teacher_profile_url
                        : defaultUser
                    }
                    alt="profile"
                  />
                  <Tooltip label="Staff Profile" placement="bottom">
                    <Link to={subj.teacher_id}>
                      <TbExternalLink fontSize={25} />
                    </Link>
                  </Tooltip>
                </div>
                <div className={Styles.info}>
                  <p className={Styles.name}>
                    {truncateText(subj.teacher_name, 15)}
                  </p>
                  <hr
                    style={{ color: "red", borderColor: "red", height: "10px" }}
                  />
                  <p className={Styles.class}>{subj.subject}</p>
                  <div className={Styles.editbox}>
                    <p
                      className={Styles.code}
                      style={{ color: subj.is_scholastic ? "blue" : "green" }}
                    >
                      {subj.is_scholastic ? "Scholastic" : "Non Scholastic"}
                    </p>
                    <Tooltip label="Customize Grading" placement="bottom">
                      <div
                        className={Styles.icon}
                        onClick={() => {
                          handleMaxModal(subj);
                        }}
                      >
                        <TbReportAnalytics cursor="pointer" />
                      </div>
                    </Tooltip>
                    <Tooltip label="Edit Subject" placement="bottom">
                      <img
                        className={Styles.editImage}
                        src={editIcon}
                        alt="edit"
                        onClick={() => handleEditSubject(subj)}
                      />
                    </Tooltip>
                    <Tooltip label="Delete Subject" placement="bottom">
                      <img
                        className={Styles.editImage}
                        src={deleteIcon}
                        alt="del"
                        onClick={() => handleDeleteSubject(subj)}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <hr style={{ border: "2px solid black", marginTop: "15px" }} />
        <h1 className={Styles.heading}>
          Elective Subjects({classroomDetails.electives.length})
        </h1>
        <div className={Styles.electives}>
          {classroomDetails &&
          classroomDetails.electives &&
          classroomDetails.electives.length > 0 ? (
            classroomDetails.electives.map((elective) => {
              return (
                <div
                  className={Styles.electives_container}
                  key={elective.elective_id}
                >
                  <div className={Styles.elecBox}>
                    <h1 className={Styles.elective_heading}>
                      {elective.elective_name}
                    </h1>
                    <div className={Styles.elecimgBox}>
                      <Tooltip label="Edit Subject" placement="bottom">
                        <img
                          className={Styles.editImage}
                          src={editIcon}
                          alt="edit"
                          onClick={() => handleEditElective(elective)}
                        />
                      </Tooltip>
                      <Tooltip label="Delete Subject" placement="bottom">
                        <img
                          className={Styles.editImage}
                          src={deleteIcon}
                          alt="del"
                          onClick={() => handleDeleteElective(elective)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <hr
                    style={{
                      color: "red",
                      borderColor: "black",
                      height: "10px",
                    }}
                  />
                  {elective.subjects.map((subject) => {
                    return (
                      <div
                        className={Styles.lists_elective}
                        key={subject.subject}
                      >
                        <div className={Styles.list}>
                          {/* <Link to={subject.teacher_id}> */}
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                subject?.teacher_profile_url !== "N/A"
                                  ? subject?.teacher_profile_url
                                  : defaultUser
                              }
                              alt="profile"
                            />
                            <Tooltip label="Staff Profile" placement="bottom">
                              <Link to={subject.teacher_id}>
                                <TbExternalLink
                                  fontSize={25}
                                  style={{ marginTop: "10px" }}
                                />
                              </Link>
                            </Tooltip>
                          </div>
                          {/* </Link> */}
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {truncateText(subject.teacher_name, 15)}
                            </p>
                            <hr
                              style={{
                                color: "red",
                                borderColor: "red",
                                height: "10px",
                              }}
                            />
                            <p className={Styles.class}>{subject.subject}</p>
                            <div className={Styles.editbox}>
                              <p
                                className={Styles.code}
                                style={{
                                  color: subject.is_scholastic
                                    ? "blue"
                                    : "green",
                                }}
                              >
                                {subject.is_scholastic
                                  ? "Scholastic"
                                  : "Non Scholastic"}
                              </p>
                              <Tooltip
                                label="Customize Grading"
                                placement="bottom"
                              >
                                <div
                                  className={Styles.icon}
                                  onClick={() => {
                                    handleMaxModal(subject);
                                  }}
                                >
                                  <TbReportAnalytics cursor="pointer" />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className={Styles.noResult}>No Results</div>
          )}
        </div>
      </div>
      <Modal isOpen={chooseSubj.isOpen} onClose={chooseSubj.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose Type of Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              onClick={handleAddRegularSubject}
              variant="solid"
              colorScheme="skyblue"
              className={Styles.button}
            >
              Regular Subject
            </Button>
            <Button
              onClick={handleAddElectiveSubject}
              className={Styles.button}
              colorScheme="skyblue"
              variant="solid"
            >
              Elective Subject
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={addRegular.isOpen}
        onClose={() => {
          addRegular.onClose();
          handleAddCloseIconClick();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Regular Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <form> */}
            <div className={Styles.formGroup}>
              <label className={Styles.formLabel}>
                Subject <span className={Styles.asterisk}>*</span>
              </label>
              <input
                name="subject"
                value={inputFields.regular.subject}
                onChange={handleRegularInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject"
              />
              <label className={Styles.formLabel}>Subject Code</label>
              <input
                name="subject_code"
                value={inputFields.regular.subject_code}
                onChange={handleRegularInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject Code"
              />
              <label className={Styles.formLabel}>
                Subject Type <span className={Styles.asterisk}>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="is_scholastic"
                    value={true}
                    onChange={handleRegularInputChange}
                  />
                  Scholastic
                </div>
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="is_scholastic"
                    value={false}
                    onChange={handleRegularInputChange}
                  />
                  Non Scholastic
                </div>
              </div>
              <label className={Styles.formLabel}>
                Faculty <span className={Styles.asterisk}>*</span>
              </label>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setSearchTeachers(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowAddSearchIcon(true);
                    } else {
                      setShowAddSearchIcon(false);
                    }
                  }}
                  type="text"
                  id="search_teachers"
                  name="search_teachers"
                  placeholder="Search Faculty"
                  className={Styles.formInput}
                  value={searchTeachers}
                  onKeyDown={handleEnterKeyPressAdd}
                  autoComplete="off"
                />
                {showAddSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleAddSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleAddCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {searchTeacherList.length === 0 ? (
                <div></div>
              ) : (
                <div
                  style={{
                    height: "150px",
                    marginTop: "15px",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  {searchTeacherList.map((teacher) => (
                    <div className={Styles.searchTeacherList}>
                      <div style={{ display: "flex", gap: 10 }}>
                        <img
                          className={Styles.profileImgsearch}
                          src={teacher.url != "N/A" ? teacher.url : defaultUser}
                          alt="teacher-profile"
                        />
                        <div>
                          <p>{teacher.name || "--"}</p>
                          <p>
                            {teacher.emp_id} | | {teacher.department}
                          </p>
                        </div>
                      </div>
                      <div>
                        <input
                          type="radio"
                          style={{ marginRight: "5px" }}
                          name="facultyId"
                          value={teacher._id}
                          checked={
                            inputFields.regular.facultyId === teacher._id
                          }
                          onChange={handleRegularInputChange}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <Button
                type="submit"
                colorScheme="green"
                disabled={requestInProgress}
                onClick={addRegularSubjectForm}
                className={Styles.formButton}
              >
                {requestInProgress ? <Spinner /> : "Add"}
              </Button>
            </div>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={editRegular.isOpen}
        onClose={() => {
          editRegular.onClose();
          handleUpdateTeacherCloseIconClick();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Regular Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <form> */}
            <div className={Styles.formGroup}>
              <label className={Styles.formLabel}>
                Subject <span className={Styles.asterisk}>*</span>
              </label>
              <input
                name="new_subject"
                value={updateFields.new_subject}
                onChange={handleEditRegularInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject"
              />
              <label className={Styles.formLabel}>Subject Code</label>
              <input
                name="subject_code"
                value={updateFields.subject_code}
                onChange={handleEditRegularInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject Code"
              />
              <label className={Styles.formLabel}>
                Subject Type <span className={Styles.asterisk}>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="is_scholastic"
                    defaultChecked={radioValue === true}
                    value={true}
                    onChange={(e) => {
                      setRadioValue(e.target.value);
                      setUpdateFields({
                        ...updateFields,
                        is_scholastic: JSON.parse(e.target.value),
                      });
                    }}
                  />
                  Scholastic
                </div>
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="is_scholastic"
                    value={false}
                    defaultChecked={radioValue === false}
                    onChange={(e) => {
                      setRadioValue(e.target.value);
                      setUpdateFields({
                        ...updateFields,
                        is_scholastic: JSON.parse(e.target.value),
                      });
                    }}
                  />
                  Non Scholastic
                </div>
              </div>
              <label className={Styles.formLabel}>
                Faculty <span className={Styles.asterisk}>*</span>
              </label>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setSearchTeachers(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setUpdateTeacherSearchIcon(true);
                    } else {
                      setUpdateTeacherSearchIcon(false);
                    }
                  }}
                  type="text"
                  id="search_teachers"
                  name="search_teachers"
                  placeholder={updateFields.teacher_name}
                  className={Styles.formInput}
                  value={searchTeachers}
                  onKeyDown={handleEnterKeyPressUpdate}
                  autoComplete="off"
                />
                {updateTeacherSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleUpdateTeacherSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleUpdateTeacherCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {searchTeacherList.length === 0 ? (
                <div></div>
              ) : (
                <div
                  style={{ height: "150px", overflow: "auto", width: "100%" }}
                >
                  {searchTeacherList.map((teacher) => (
                    <div className={Styles.searchTeacherList}>
                      <div style={{ display: "flex", gap: 10 }}>
                        <img
                          className={Styles.profileImgsearch}
                          src={teacher.url != "N/A" ? teacher.url : defaultUser}
                          alt="teacher-profile"
                        />
                        <div>
                          <p>{teacher.name || "--"}</p>
                          <p>
                            {teacher.emp_id} | | {teacher.department}
                          </p>
                        </div>
                      </div>
                      <div>
                        <input
                          type="radio"
                          style={{ marginRight: "5px" }}
                          name="teacher_id"
                          value={teacher._id}
                          checked={updateFields.teacher_id === teacher._id}
                          onChange={handleEditRegularInputChange}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Button
                colorScheme="green"
                type="submit"
                onClick={updateRegularSubjectForm}
                className={Styles.formButton}
              >
                {requestInProgress ? <Spinner /> : "Update"}
              </Button>
            </div>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={delRegular.isOpen} onClose={delRegular.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p style={{ color: "red", textAlign: "center" }}>
              <span className={Styles.asterisk}>*****</span> CRITICAL OPERATION{" "}
              <span className={Styles.asterisk}>*****</span>
            </p>
            <p style={{ color: "red" }}>Delete Regular Subject</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ color: "red", fontWeight: "700" }}>
              Are you sure to proceed with deletion?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={requestInProgress}
              onClick={deleteRegularSubjectForm}
              colorScheme="red"
              mr={3}
            >
              {requestInProgress ? <Spinner /> : "OK"}
            </Button>
            <Button onClick={delRegular.onClose} colorScheme="blue">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={addElective.isOpen}
        onClose={() => {
          addElective.onClose();
          handleAddEleCloseIconClick();
          handleAddEle2CloseIconClick();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Elective Subjects</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <form> */}
            <div className={Styles.formGroup}>
              <label className={Styles.formLabel}>
                Elective Name <span className={Styles.asterisk}>*</span>
              </label>
              <input
                name="electiveName"
                value={inputFieldsElectives.electiveName}
                onChange={handleElectivesInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Elective name"
              />
              <label className={Styles.formLabel}>
                Elective Subject 1 <span className={Styles.asterisk}>*</span>
              </label>
              <input
                name="subjectOne"
                value={inputFieldsElectives.subjectOne}
                onChange={handleElectivesInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject"
              />
              <label className={Styles.formLabel}>Elective Subject Code</label>
              <input
                name="subjectCodeOne"
                value={inputFieldsElectives.subjectCodeOne}
                onChange={handleElectivesInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject Code"
              />
              <label className={Styles.formLabel}>
                Subject Type <span className={Styles.asterisk}>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="isScholasticOne"
                    value={true}
                    onChange={handleElectivesInputChange}
                  />
                  Scholastic
                </div>
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="isScholasticOne"
                    value={false}
                    onChange={handleElectivesInputChange}
                  />
                  Non Scholastic
                </div>
              </div>

              <label className={Styles.formLabel}>
                Faculty <span className={Styles.asterisk}>*</span>
              </label>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setSearchTeachers(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowAddEleSearchIcon(true);
                    } else {
                      setShowAddEleSearchIcon(false);
                    }
                  }}
                  type="text"
                  id="search_teachers"
                  name="search_teachers"
                  placeholder="Search Faculty"
                  className={Styles.formInput}
                  value={searchTeachers}
                  onKeyDown={handleEnterKeyPressAddEle}
                  autoComplete="off"
                />
                {showAddEleSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleAddEleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleAddEleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {searchTeacherList.length === 0 ? (
                <div></div>
              ) : (
                <div
                  style={{ height: "150px", overflow: "auto", width: "100%" }}
                >
                  {searchTeacherList.map((teacher) => (
                    <div className={Styles.searchTeacherList}>
                      <div style={{ display: "flex", gap: 10 }}>
                        <img
                          className={Styles.profileImgsearch}
                          src={teacher.url != "N/A" ? teacher.url : defaultUser}
                          alt="teacher-profile"
                        />
                        <div>
                          <p>{teacher.name || "--"}</p>
                          <p>
                            {teacher.emp_id} | | {teacher.department}
                          </p>
                        </div>
                      </div>
                      <div>
                        <input
                          type="radio"
                          style={{ marginRight: "5px" }}
                          name="facultyOne"
                          value={teacher._id}
                          checked={
                            inputFieldsElectives.facultyOne === teacher._id
                          }
                          onChange={handleElectivesInputChange}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <label className={Styles.formLabel}>
                Elective Subject 2 <span className={Styles.asterisk}>*</span>
              </label>
              <input
                name="subjectTwo"
                value={inputFieldsElectives.subjectTwo}
                onChange={handleElectivesInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject"
              />
              <label className={Styles.formLabel}>Elective Subject Code</label>
              <input
                name="subjectCodeTwo"
                value={inputFieldsElectives.subjectCodeTwo}
                onChange={handleElectivesInputChange}
                className={Styles.formInput}
                type="text"
                placeholder="Enter Subject Code"
              />
              <label className={Styles.formLabel}>
                Subject Type <span className={Styles.asterisk}>*</span>
              </label>
              <div
                style={{
                  display: "flex",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="isScholasticTwo"
                    value={true}
                    onChange={handleElectivesInputChange}
                  />
                  Scholastic
                </div>
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    style={{ marginRight: "5px" }}
                    name="isScholasticTwo"
                    value={false}
                    onChange={handleElectivesInputChange}
                  />
                  Non Scholastic
                </div>
              </div>
              <label className={Styles.formLabel}>
                Faculty <span className={Styles.asterisk}>*</span>
              </label>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setSearchElective2Teachers(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowAddEle2SearchIcon(true);
                    } else {
                      setShowAddEle2SearchIcon(false);
                    }
                  }}
                  type="text"
                  id="search_teachers"
                  name="search_teachers"
                  placeholder="Search Faculty"
                  className={Styles.formInput}
                  value={searchElective2Teachers}
                  onKeyDown={handleEnterKeyPressAddEle2}
                  autoComplete="off"
                />
                {showAddEle2SearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleAddEle2SearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleAddEle2CloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "20%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {searchTeacherElecList.length === 0 ? (
                <div></div>
              ) : (
                <div
                  style={{ height: "150px", overflow: "auto", width: "100%" }}
                >
                  {searchTeacherElecList.map((teacher) => (
                    <div className={Styles.searchTeacherList}>
                      <div style={{ display: "flex", gap: 10 }}>
                        <img
                          className={Styles.profileImgsearch}
                          src={teacher.url != "N/A" ? teacher.url : defaultUser}
                          alt="teacher-profile"
                        />
                        <div>
                          <p>{teacher.name || "--"}</p>
                          <p>
                            {teacher.emp_id} | | {teacher.department}
                          </p>
                        </div>
                      </div>
                      <div>
                        <input
                          type="radio"
                          style={{ marginRight: "5px" }}
                          name="facultyTwo"
                          value={teacher._id}
                          checked={
                            inputFieldsElectives.facultyTwo === teacher._id
                          }
                          onChange={handleElectivesInputChange}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <button
                type="submit"
                disabled={requestInProgress}
                onClick={addElectiveSubjectForm}
                className={Styles.formButton}
              >
                {requestInProgress ? <Spinner /> : "Add"}
              </button>
            </div>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={updateClassTeacherModal.isOpen}
        onClose={() => {
          updateClassTeacherModal.onClose();
          handleUpdateClassTeacherCloseIconClick();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Class Teacher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ position: "relative", width: "100%" }}>
              <input
                onChange={(e) => {
                  setSearchTeachers(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setShowClsTeacherUpdateSearchIcon(true);
                  } else {
                    setShowClsTeacherUpdateSearchIcon(false);
                  }
                }}
                type="text"
                id="search_teachers"
                name="search_teachers"
                placeholder="Search Faculty"
                className={Styles.formInput}
                value={searchTeachers}
                onKeyDown={handleEnterKeyPressUpdateClsTeacher}
                autoComplete="off"
              />
              {showClsTeacherUpdateSearchIcon ? (
                <BiSearchAlt2
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "20%",
                    cursor: "pointer",
                  }}
                  fontSize={20}
                  onClick={handleUpdateClassTeacherSearchClick}
                />
              ) : (
                <AiOutlineCloseCircle
                  onClick={handleUpdateClassTeacherCloseIconClick}
                  fontSize={20}
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "20%",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            {searchTeacherList.length === 0 ? (
              <div></div>
            ) : (
              <div
                style={{
                  height: "150px",
                  marginTop: "15px",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {searchTeacherList.map((teacher) => (
                  <div className={Styles.searchTeacherList}>
                    <div style={{ display: "flex", gap: 10 }}>
                      <img
                        className={Styles.profileImgsearch}
                        src={teacher.url != "N/A" ? teacher.url : defaultUser}
                        alt="teacher-profile"
                      />
                      <div>
                        <p>{teacher.name || "--"}</p>
                        <p>
                          {teacher.emp_id} | | {teacher.department}
                        </p>
                      </div>
                    </div>
                    <div>
                      <input
                        type="radio"
                        style={{ marginRight: "5px" }}
                        name="facultyId"
                        value={teacher._id}
                        checked={updateClassTeacher.facultyId === teacher._id}
                        onChange={handleUpdateTeacherInputChange}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              colorScheme="green"
              style={{ alignSelf: "center" }}
              onClick={handleUpdateClassTeacher}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={maxIsOpen}
        onClose={() => {
          setMaxAnual({
            total: 100,
            internal: 20,
            external: 80,
          });
          setMaxMid({
            total: 50,
            internal: 10,
            external: 40,
          });
          setMarksArray([{ maxRange: 100, minRange: 0, grade: "" }]);
          setMarksMidArray([{ maxMidRange: 50, minMidRange: 0, grade: "" }]);
          setSubjectInModal("");
          setAddButton("");
          maxOnClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            CUSTOMIZE GRADING : {subjectInModal?.subject}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {requestInProgressModal ? (
              <div className={Styles.loaderWrapper}>
                <Spinner />
              </div>
            ) : subjectInModal && subjectInModal.is_scholastic ? (
              <div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Mid-Term
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <input
                            value={maxMid.total}
                            onChange={(e) => {
                              const newTotal = e.target.value;
                              setMaxMid({ ...maxMid, total: newTotal });
                              const newMarksMidArray = [...marksMidArray];
                              newMarksMidArray[0].maxMidRange = newTotal;
                              setMarksMidArray(newMarksMidArray);
                            }}
                            name="max_mid_total"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <input
                            value={maxMid.external}
                            onChange={(e) =>
                              setMaxMid({ ...maxMid, external: e.target.value })
                            }
                            name="max_mid_external"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxMid.total - maxMid.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksMidArray.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <input
                            value={item?.minMidRange}
                            onChange={(e) => handleMinMidValRange(e, index)}
                            name="minMidRange"
                            type="number"
                            placeholder={item?.minMidRange}
                            className={Styles.textInput}
                          />

                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxMidRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            value={item.grade}
                            onChange={(e) => handleMidGrade(e, index)}
                            name="grade"
                            className={Styles.textInput}
                          />
                          {marksMidArray.length >= 2 ? (
                            <AiOutlineCloseCircle
                              className={Styles.icons}
                              onClick={() => handleRemoveMidObject(index)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {marksMidArray[marksMidArray.length - 1]?.minMidRange >
                      0 ? (
                        <MdOutlineAddBox
                          className={Styles.icons}
                          onClick={handleAddMidObject}
                        />
                      ) : null}
                    </div>
                  </div>
                  <p
                    style={{
                      borderLeft: "2px solid black",
                      marginRight: "10px",
                    }}
                  ></p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "5px",
                      width: "50%",
                    }}
                  >
                    <div>
                      <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                        Annual
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginRight: "15px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Total</p>
                          <input
                            value={maxAnual.total}
                            onChange={(e) => {
                              const newTotal = e.target.value;
                              setMaxAnual({ ...maxAnual, total: newTotal });
                              const newMarksArray = [...marksArray];
                              newMarksArray[0].maxRange = newTotal;
                              setMarksArray(newMarksArray);
                            }}
                            name="max_annual_total"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>External</p>
                          <input
                            value={maxAnual.external}
                            onChange={(e) =>
                              setMaxAnual({
                                ...maxAnual,
                                external: e.target.value,
                              })
                            }
                            name="max_annual_external"
                            className={Styles.textInput}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p>Internal</p>
                          <p className={Styles.textInputInternal}>
                            {maxAnual.total - maxAnual.external}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      style={{
                        border: "1px solid black",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ fontWeight: "bold", width: "60%" }}>Range</p>
                      <p style={{ fontWeight: "bold", width: "40%" }}>Grade</p>
                    </div>
                    {marksArray.map((item, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                          }}
                        >
                          <input
                            value={item.minRange}
                            onChange={(e) => handleMinValRange(e, index)}
                            name="minRange"
                            type="number"
                            placeholder={item.minRange}
                            className={Styles.textInput}
                          />

                          <p
                            style={{
                              borderBottom: "2px solid black",
                              width: "5%",
                              marginBottom: "15px",
                            }}
                          ></p>
                          <p className={Styles.textInputInternal}>
                            {item.maxRange}
                          </p>
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            value={item.grade}
                            onChange={(e) => handleGrade(e, index)}
                            name="grade"
                            className={Styles.textInput}
                          />
                          {marksArray.length >= 2 ? (
                            <AiOutlineCloseCircle
                              className={Styles.icons}
                              onClick={() => handleRemoveObject(index)}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {marksArray[marksArray.length - 1].minRange > 0 ? (
                        <MdOutlineAddBox
                          className={Styles.icons}
                          onClick={handleAddObject}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={Styles.buttonRow}>
                  <button className={Styles.button} onClick={handleSubmit}>
                    {addButton === "class or subject not found"
                      ? "ADD"
                      : "UPDATE"}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ fontWeight: "bold", color: "red" }}>
                The Max Marks feature does not apply to Non-Scholastic Subjects.
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={delClass.isOpen} onClose={delClass.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p style={{ color: "red", textAlign: "center" }}>
              <span className={Styles.asterisk}>*****</span> CRITICAL OPERATION{" "}
              <span className={Styles.asterisk}>*****</span>
            </p>
            <p style={{ color: "red" }}>Delete Class</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ color: "red", fontWeight: "700" }}>
              Are you sure to proceed with deletion of grade &nbsp;
              {classroomDetails.class} {classroomDetails.section}?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={requestInProgress}
              onClick={handleDeleteClass}
              colorScheme="red"
              mr={3}
            >
              {requestInProgress ? <Spinner /> : "OK"}
            </Button>
            <Button onClick={delClass.onClose} colorScheme="blue">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={editElectiveModal.isOpen}
        onClose={() => {
          editElectiveModal.onClose();
          handleEditEleCloseIconClick();
          handleEditEle2CloseIconClick();
        }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Elective Subject</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <form> */}
            <div className={Styles.formGroup}>
              <div>
                <label className={Styles.formLabel}>
                  Elective Name <span className={Styles.asterisk}>*</span>
                </label>
                <input
                  name="electiveName"
                  value={updateElectives.electiveName}
                  onChange={handleEditElectiveInputChange}
                  className={Styles.formInput}
                  type="text"
                  placeholder="Enter Elective Name"
                />

                <div>
                  <label className={Styles.formLabel}>
                    Elective Subject 1<span className={Styles.asterisk}>*</span>
                  </label>
                  <input
                    name="subjectOne"
                    value={updateElectives.subjectOne}
                    onChange={handleEditElectiveInputChange}
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Subject 1"
                  />
                  <label className={Styles.formLabel}>Subject Code 1</label>
                  <input
                    name="subjectCodeOne"
                    value={updateElectives.subjectCodeOne}
                    onChange={handleEditElectiveInputChange}
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Subject Code 1"
                  />
                  <label className={Styles.formLabel}>
                    Subject Type 1<span className={Styles.asterisk}>*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "7px",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ marginRight: "20px" }}>
                      <input
                        type="radio"
                        style={{ marginRight: "5px" }}
                        name="scholasticOne"
                        value={true}
                        onChange={handleEditElectiveInputChange}
                        checked={updateElectives.scholasticOne === "true"}
                      />
                      Scholastic
                    </div>
                    <div style={{ marginRight: "20px" }}>
                      <input
                        type="radio"
                        style={{ marginRight: "5px" }}
                        name="scholasticOne"
                        value={false}
                        onChange={handleEditElectiveInputChange}
                        checked={updateElectives.scholasticOne === "false"}
                      />
                      Non Scholastic
                    </div>
                  </div>
                  <label className={Styles.formLabel}>
                    Faculty <span className={Styles.asterisk}>*</span>
                  </label>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      onChange={(e) => {
                        setSearchTeachers(e.target.value);
                        if (e.target.value.trim() !== "") {
                          setShowEditEleSearchIcon(true);
                        } else {
                          setShowEditEleSearchIcon(false);
                        }
                      }}
                      type="text"
                      id="search_teachers"
                      name="search_teachers"
                      placeholder={updateElectives.facultyOne}
                      className={Styles.formInput}
                      value={searchTeachers}
                      onKeyDown={handleEnterKeyPressEditEle}
                      autoComplete="off"
                    />
                    {showEditEleSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "20%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleEditEleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleEditEleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "20%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {searchTeacherList.length === 0 ? (
                    <div></div>
                  ) : (
                    <div
                      style={{
                        height: "150px",
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      {searchTeacherList.map((teacher) => (
                        <div className={Styles.searchTeacherList}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <img
                              className={Styles.profileImgsearch}
                              src={
                                teacher.url != "N/A" ? teacher.url : defaultUser
                              }
                              alt="teacher-profile"
                            />
                            <div>
                              <p>{teacher.name || "--"}</p>
                              <p>
                                {teacher.emp_id} | | {teacher.department}
                              </p>
                            </div>
                          </div>
                          <div>
                            <input
                              type="radio"
                              style={{ marginRight: "5px" }}
                              name="facultyOne"
                              value={teacher.name}
                              checked={
                                updateElectives.facultyOne === teacher.name
                              }
                              onChange={handleEditElectiveInputChange}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <label className={Styles.formLabel}>
                    Elective Subject 2<span className={Styles.asterisk}>*</span>
                  </label>
                  <input
                    name="subjectTwo"
                    value={updateElectives.subjectTwo}
                    onChange={handleEditElectiveInputChange}
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Subject 2"
                  />
                  <label className={Styles.formLabel}>Subject Code 2</label>
                  <input
                    name="subjectCodeTwo"
                    value={updateElectives.subjectCodeTwo}
                    onChange={handleEditElectiveInputChange}
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Subject Code 2 "
                  />
                  <label className={Styles.formLabel}>
                    Subject Type 2<span className={Styles.asterisk}>*</span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "7px",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ marginRight: "20px" }}>
                      <input
                        type="radio"
                        style={{ marginRight: "5px" }}
                        name="scholasticTwo"
                        value={true}
                        onChange={handleEditElectiveInputChange}
                        checked={updateElectives.scholasticTwo === "true"}
                      />
                      Scholastic
                    </div>
                    <div style={{ marginRight: "20px" }}>
                      <input
                        type="radio"
                        style={{ marginRight: "5px" }}
                        name="scholasticTwo"
                        value={false}
                        onChange={handleEditElectiveInputChange}
                        checked={updateElectives.scholasticTwo === "false"}
                      />
                      Non Scholastic
                    </div>
                  </div>
                  <label className={Styles.formLabel}>
                    Faculty <span className={Styles.asterisk}>*</span>
                  </label>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      onChange={(e) => {
                        setSearchElective2Teachers(e.target.value);
                        if (e.target.value.trim() !== "") {
                          setShowEditEle2SearchIcon(true);
                        } else {
                          setShowEditEle2SearchIcon(false);
                        }
                      }}
                      type="text"
                      id="search_teachers"
                      name="search_teachers"
                      placeholder={updateElectives.facultyTwo}
                      className={Styles.formInput}
                      value={searchElective2Teachers}
                      onKeyDown={handleEnterKeyPressEditEle2}
                      autoComplete="off"
                    />
                    {showEditEle2SearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "20%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleEditEle2SearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleEditEle2CloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "20%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {searchTeacherElecList.length === 0 ? (
                    <div></div>
                  ) : (
                    <div
                      style={{
                        height: "150px",
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      {searchTeacherElecList.map((teacher) => (
                        <div className={Styles.searchTeacherList}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <img
                              className={Styles.profileImgsearch}
                              src={
                                teacher.url != "N/A" ? teacher.url : defaultUser
                              }
                              alt="teacher-profile"
                            />
                            <div>
                              <p>{teacher.name || "--"}</p>
                              <p>
                                {teacher.emp_id} | | {teacher.department}
                              </p>
                            </div>
                          </div>
                          <div>
                            <input
                              type="radio"
                              style={{ marginRight: "5px" }}
                              name="facultyTwo"
                              value={teacher.name}
                              checked={
                                updateElectives.facultyTwo === teacher.name
                              }
                              onChange={handleEditElectiveInputChange}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <Button
                type="submit"
                colorScheme="green"
                onClick={updateElectiveForm}
                className={Styles.formButton}
              >
                {requestInProgress ? <Spinner /> : "Update"}
              </Button>
            </div>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={delElective.isOpen} onClose={delElective.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p style={{ color: "red", textAlign: "center" }}>
              <span className={Styles.asterisk}>*****</span> CRITICAL OPERATION{" "}
              <span className={Styles.asterisk}>*****</span>
            </p>
            <p style={{ color: "red" }}> Delete Elective Subject</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ color: "red", fontWeight: "700" }}>
              Are you sure to proceed with deletion?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={requestInProgress}
              onClick={deleteElectiveForm}
              colorScheme="red"
              mr={3}
            >
              {requestInProgress ? <Spinner /> : "OK"}
            </Button>
            <Button onClick={delElective.onClose} colorScheme="blue">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

export default SingleClass;

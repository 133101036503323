import React, { useState, useEffect } from "react";
import Styles from "./Settings.module.scss";
const StaffSettings = () => {
  const [selectedStep, setSelectedStep] = useState(1);
  const [themeType, setThemeType] = useState(true);
  const [todayBirthdayType, setTodayBirthdayType] = useState(true);
  const [upcomingBirthdayType, setUpcomingBirthdayType] = useState(true);
  const [searchModule, setSearchModule] = useState(true);

  const handleStepClick = (step) => {
    setSelectedStep(step);
  };
  useEffect(() => {
    const value = localStorage.getItem("theme");
    const value2 = localStorage.getItem("UpcomingBirthday");
    const value3 = localStorage.getItem("SearchType");
    const value4 = localStorage.getItem("TodayBirthday");
    if (value == "dark") {
      setThemeType(false);
    } else {
      setThemeType(true);
    }
    if (value2 == "enable") {
      setUpcomingBirthdayType(false);
    } else {
      setUpcomingBirthdayType(true);
    }
    if (value3 == "SingleModule") {
      setSearchModule(false);
    } else {
      setSearchModule(true);
    }
    if (value4 == "disable") {
      setTodayBirthdayType(false);
    } else {
      setTodayBirthdayType(true);
    }
  }, []);
  const handleLightTheme = () => {
    document.getElementById("root").classList.remove("dark");
    document.getElementById("root").classList.add("light");
    localStorage.setItem("theme", "light");
    setThemeType(true);
  };
  const handleDarkTheme = () => {
    document.getElementById("root").classList.remove("light");
    document.getElementById("root").classList.add("dark");
    localStorage.setItem("theme", "dark");
    setThemeType(false);
  };
  const handleTodayBirthdayEnable = () => {
    localStorage.setItem("TodayBirthday", "enable");
    setTodayBirthdayType(true);
  };
  const handleTodayBirthdayDisable = () => {
    localStorage.setItem("TodayBirthday", "disable");
    setTodayBirthdayType(false);
  };
  const handleUpcomingBirthdayEnable = () => {
    localStorage.setItem("UpcomingBirthday", "enable");
    setUpcomingBirthdayType(false);
  };
  const handleUpcomingBirthdayDisable = () => {
    localStorage.setItem("UpcomingBirthday", "disable");
    setUpcomingBirthdayType(true);
  };
  const handleHighlightModulename = () => {
    localStorage.setItem("SearchType", "Highlightname");
    setSearchModule(false);
  };
  const handleSearchSingleModule = () => {
    localStorage.setItem("SearchType", "SingleModule");
    setSearchModule(true);
  };
  return (
    <div className={Styles.helpmenu}>
      <div className={Styles.wrap}>
        <div className={Styles.forWidthLeft}>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 1 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(1)}
          >
            <div className={Styles.stepNo}>1</div>
            <div className={Styles.stepTitle}>Dashboard Feature's</div>
          </div>
          <div
            className={`${Styles.stepHead} ${
              selectedStep === 2 ? Styles.clicked : ""
            }`}
            onClick={() => handleStepClick(2)}
          >
            <div className={Styles.stepNo}>2</div>
            <div className={Styles.stepTitle}>Appearance</div>
          </div>
        </div>
        {selectedStep === 1 && (
          <div className={Styles.forWidthRight}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "5px",
              }}
            >
              <p style={{ marginRight: "20px" }}>1.</p>
              <p>SEARCH</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="searchDeafult"
                name="search"
                checked={searchModule ? true : false}
                onChange={handleSearchSingleModule}
                style={{ marginRight: "20px" }}
              />
              <label for="searchDeafult">
                Display Filtered Modules (default)
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="searchColor"
                name="search"
                checked={searchModule ? false : true}
                onChange={handleHighlightModulename}
                style={{ marginRight: "20px" }}
              />
              <label for="searchColor">
                Display all modules, with a focus on highlighting the search
                modules.
              </label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <p style={{ marginRight: "20px" }}>2.</p>
              <p>TODAY'S BIRTHDAY</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="todayEnable"
                checked={todayBirthdayType ? true : false}
                onChange={handleTodayBirthdayEnable}
                name="todaybirthday"
                style={{ marginRight: "20px" }}
              />
              <label for="todayEnable">Enable (default)</label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="todayDisable"
                onChange={handleTodayBirthdayDisable}
                checked={todayBirthdayType ? false : true}
                name="todaybirthday"
                style={{ marginRight: "20px" }}
              />
              <label for="todayDisable">Disable</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <p style={{ marginRight: "20px" }}>3.</p>
              <p>UPCOMING BIRTHDAY</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="upcomingDisable"
                checked={upcomingBirthdayType ? true : false}
                onChange={handleUpcomingBirthdayDisable}
                name="birthday"
                style={{ marginRight: "20px" }}
              />
              <label for="upcomingDisable">Disable (default)</label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="upComingEnable"
                checked={upcomingBirthdayType ? false : true}
                onChange={handleUpcomingBirthdayEnable}
                name="birthday"
                style={{ marginRight: "20px" }}
              />
              <label for="upComingEnable">Enable</label>
            </div>
          </div>
        )}
        {selectedStep === 2 && (
          <div className={Styles.forWidthRight}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "5px",
              }}
            >
              <p style={{ marginRight: "20px" }}>1.</p>
              <p>THEME</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="themeLight"
                checked={themeType ? true : false}
                onChange={handleLightTheme}
                name="theme"
                style={{ marginRight: "20px" }}
              />
              <label for="themeLight">Light (default)</label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                id="themeDark"
                checked={themeType ? false : true}
                onChange={handleDarkTheme}
                name="theme"
                style={{ marginRight: "20px" }}
              />
              <label for="themeDark">Dark</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffSettings;

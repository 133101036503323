import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SMU from "assets/logos/smu.png";
import Styles from "./Logout.module.scss";
import { Spinner, Button, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import { getRequestOptions } from "services/utils";

const Logout = (props) => {
  const [inProgress, setinProgress] = useState(false);
  const url = "https://smudev.smuapp.org/dev/api";
  console.log("logging out");
  const navigate = useNavigate();

  //logout all api
  const LogOutAll = async () => {
    try {
      console.log("logging out all");
      // to be done
      const response = await axios.post(
        url + "/v1/auth/logoutAll",
        {},
        getRequestOptions()
      );
      console.log(response);
      pushPage();
    } catch (error) {
      console.log(error);
    }
  };

  const LogOut = async () => {
    try {
      console.log("logging out from this device");
      const response = await axios.post(
        url + "/v1/auth/logout",
        {},
        getRequestOptions()
      );
      console.log(response);
      pushPage();
    } catch (error) {
      console.log(error);
    }
  };

  //on cancel action
  const onCancel = () => {
    navigate(-1);
  };
  //clearing out local storage
  const pushPage = () => {
    setinProgress(true);
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInOn");
      localStorage.removeItem("multipleStudent");
      localStorage.removeItem("student_email");
      !!localStorage.getItem("studentId") &&
        localStorage.removeItem("studentId");

      !!localStorage.getItem("role") && localStorage.removeItem("role");

      !!localStorage.getItem("admin_name") &&
        localStorage.removeItem("admin_name");
      localStorage.removeItem("Permission");
      localStorage.removeItem("staff_filter");
      localStorage.removeItem("student_filter");
      localStorage.removeItem("event_filter");
      localStorage.removeItem("circular_filter");
      localStorage.removeItem("classroom_filter");
      localStorage.removeItem("showSearchField");
      localStorage.removeItem("report");
      localStorage.removeItem("teacher_name");
      localStorage.removeItem("teacher_id");
      localStorage.removeItem("userType");
      localStorage.removeItem("selectedClass");
      localStorage.removeItem("student_class");
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("student_name");
      localStorage.removeItem("student_section");
      localStorage.removeItem("notificationCount");
      // localStorage.clear()
      setinProgress(false);
      navigate("/");
    }, 1000);
  };

  return (
    <div className={Styles.body}>
      {inProgress ? (
        <Spinner color="red.500" size="xl" />
      ) : (
        <div className={Styles.container}>
          <div className={Styles.content}>
            <span className={Styles.actionHeading}>Logout</span>
            <span className={Styles.actionPhrase}>
              Are you sure you want to logout ?
            </span>
          </div>
          <div className={Styles.buttons}>
            <span>
              <Tooltip label="Logout of this device">
                <Button colorScheme="red" onClick={LogOut}>
                  Logout
                </Button>
              </Tooltip>
            </span>

            {props.allRequest ? (
              <Tooltip label="Logout of all devices">
                <span>
                  <Button colorScheme="teal" onClick={LogOutAll}>
                    Logout All
                  </Button>
                </span>
              </Tooltip>
            ) : (
              ""
            )}

            <Tooltip label="Go back">
              <span>
                <Button onClick={onCancel}>Cancel</Button>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;

// import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import SMU from "assets/logos/smu.png";
// import Styles from "./Logout.module.scss";
// import { Spinner, Button, Tooltip } from "@chakra-ui/react";
// import axios from "axios";
// import { getRequestOptions, logoutUser } from "services/utils";

// const Logout = (props) => {
//   const [inProgress, setinProgress] = useState(false);
//   const url = "https://smuapp.org/dev/api";
//   console.log("logging out");
//   const navigate = useNavigate();

//   //logout all api
//   const LogOutAll = async () => {
//     try {
//       console.log("logging out all");
//       // to be done
//       const response = await axios.post(
//         url + "/v1/auth/logoutAll",
//         {},
//         getRequestOptions()
//       );
//       console.log(response);
//     } catch (error) {
//       // logoutUser(error);
//       console.log(error);
//     }
//   };

//   const LogOut = async () => {
//     try {
//       const response = await axios.post(
//         url + "/v1/auth/logout",
//         {},
//         getRequestOptions()
//       );
//     } catch (error) {
//       // logoutUser(error);
//     }
//   };

//   //on cancel action
//   const onCancel = () => {
//     navigate(-1);
//   };
//   //clearing out local storage
//   // const pushPage = () => {
//   //   setinProgress(true);
//   //   setTimeout(() => {
//   //     localStorage.removeItem("token");
//   //     localStorage.removeItem("loggedInOn");
//   //     !!localStorage.getItem("studentId") &&
//   //       localStorage.removeItem("studentId");

//   //     !!localStorage.getItem("role") && localStorage.removeItem("role");

//   //     !!localStorage.getItem("admin_name") &&
//   //       localStorage.removeItem("admin_name");
//   //     setinProgress(false);
//   //     navigate("/");
//   //   }, 1000);
//   // };

//   return (
//     <div className={Styles.body}>
//       {inProgress ? (
//         <Spinner color="red.500" size="xl" />
//       ) : (
//         <div className={Styles.container}>
//           <div className={Styles.content}>
//             <span className={Styles.actionHeading}>Logout</span>
//             <span className={Styles.actionPhrase}>
//               Are you sure you want to logout ?
//             </span>
//           </div>
//           <div className={Styles.buttons}>
//             <span>
//               <Tooltip label="Logout of this device">
//                 <Button colorScheme="red" onClick={LogOut}>
//                   Logout
//                 </Button>
//               </Tooltip>
//             </span>

//             {props.allRequest ? (
//               <Tooltip label="Logout of all devices">
//                 <span>
//                   <Button colorScheme="teal" onClick={LogOutAll}>
//                     Logout All
//                   </Button>
//                 </span>
//               </Tooltip>
//             ) : (
//               ""
//             )}

//             <Tooltip label="Go back">
//               <span>
//                 <Button onClick={onCancel}>Cancel</Button>
//               </span>
//             </Tooltip>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Logout;

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { requestStudentDetailsById } from "services/admin-dashboard/student.service";
import "react-datepicker/dist/react-datepicker.css";
import { getSignedUrlLink } from "services/doSpaceGetImages/getSignedUrlLink";
import Cerificateservice from "services/admin-dashboard/certificate.service";
import { useToast } from "@chakra-ui/react";
// import "./"
// import {ref, getDownloadURL, uploadBytesResumable,getStorage} from "firebase/storage"
// import {storage} from "../../../config/firebaseInitialize"
// import 'firebase/storage';
// import "./TcCertificate.module.scss";
import Header from "../CertificatePdfs/BonafideHeader/Header";
import A3Header from "../CertificatePdfs/Header/Header";
import jsPDF from "jspdf";
import moment from "moment";
import { ToWords } from "to-words";
import Styles from "../CertificatePdfs/TcCertificate.module.scss";

import backimg from "assets/logos/SMU_Watermark3.png";
const initailvalue = {
  addmissionNo: "",
  STSNo: "",
  TCNo: "",
  name: "",
  gender: "",
  motherName: "",
  fatherName: "",
  dob: "",
  caste: "",
  addmissiondate: "",
  preClass: "",
  bExam: "",
  subject: "",
  qualified: "",
  fees: "",
  feesc: "",
  days: "",
  camp: "",
  games: "",
  genral: "",
  certificateDate: "",
  dateIssue: "",
  reason: "",
  remark: "",
};

const DownloadManualTc = () => {
  const params = useParams();
  const toast = useToast();
  //   const [inputFields, setInputFields] = useState(null);
  //   const [data, setdata] = useState(null);
  //   const [certificateType, setcertificateType] = useState(null);
  const [moreData, setMoreData] = useState("");
  const [pdfFormetDownload, setPdfFormetDownload] = useState(false);
  const [edit, setEdit] = useState(false);
  // const location = useLocation()

  const location = useLocation();
  console.log("location", location);
  // const moreData = location.state.transferInfo

  //   console.log("inputfeilds", inputFields);

  useEffect(() => {
    // fetchStudentProfileDetails();
    // setcertificateType(location.state.data);
    setMoreData(location.state.transferInfo);
  }, []);

  const A4handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a4");
    let pdf = await document.getElementById("pdfcerti");
    let downloadResponse = await view
      .html(pdf)
      .then(() => {
        view.save(`${moreData.name} Transfer Certificate (A4).pdf `);
        // PdfRequestUpdate()
      })
      .catch((err) => console.log(err, "err"));
  };

  const A3handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a3");
    let pdf = await document.getElementById("pdfcerti");
    let downloadResponse = await view
      .html(pdf)
      .then(() => {
        view.save(`${moreData.name} Transfer Certificate (A3).pdf `);
        // PdfRequestUpdate()
      })
      .catch((err) => console.log(err, "err"));
  };
  const valueChange = (e) => {
    setMoreData({ ...moreData, [e.target.name]: e.target.value });
  };
  const onEditChnage = () => {
    toast({
      title: "Edit",
      description: "Now you can edit Certificate",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
    setEdit(true);
  };
  const onChangeSave = () => {
    toast({
      title: "Save",
      description: "Certificate edit saved ",
      status: "success",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });
    setEdit(false);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          overflowX: "hidden",
        }}
      >
        {pdfFormetDownload == false ? (
          <div
            className={Styles.A4downloadStyle}
            id="pdfcerti"
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div>
                  <Header />
                  <div style={{ marginTop: ".5vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.1rem",
                          textAlign: "center",
                          fontWeight: 700,
                          marginTop: ".5vw",
                          fontFamily: "sans",
                          borderBottom: "1px solid black",  fontWeight:"700",
                          paddingBottom: "5px",
                        }}
                      >
                        TRANSFER CERTIFICATE
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: ".5vw",
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>Admission No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.addmissionNo ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                                name="addmissionNo"
                                value={moreData.addmissionNo}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                              >
                                {moreData.addmissionNo} &nbsp;&nbsp;&nbsp;
                              </p>
                            )
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>STS &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.STSNo ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                                name="STSNo"
                                value={moreData.STSNo}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                              >
                                {moreData.STSNo} &nbsp;&nbsp;&nbsp;
                              </p>
                            )
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".65rem" }}>TC &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.TCNo ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                                name="TCNo"
                                value={moreData.TCNo}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  paddingBottom: "3px",
                                  fontSize: ".65rem",
                                }}
                              >
                                {moreData.TCNo} &nbsp;&nbsp;&nbsp;
                              </p>
                            )
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          1&nbsp;&nbsp;
                        </p>
                        <p >
                           Name of Pupil
                        </p>
                      </div>
                       
                        <p style={{ width: "40%" }}>
                          {moreData.name ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="name"
                                // readOnly
                                value={moreData.name}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.name}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          2&nbsp;&nbsp;
                        </p>
                        <p>
                           Sex
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.gender ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="gender"
                                value={moreData.gender}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.gender}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          3&nbsp;&nbsp;
                        </p>
                        <p>
                           Mother's Name
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.motherName ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="motherName"
                                value={moreData.motherName}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.motherName}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          4&nbsp;&nbsp;
                        </p>
                        <p>
                           Father's Name
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.fatherName ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="fatherName"
                                value={moreData.fatherName}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.fatherName}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>5&nbsp;&nbsp;</p>
                        <p>
                           Date of birth (in Christian Era) according to the
                          Admission Register (in figures and in words)
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.dob ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="dob"
                                value={moreData.dob}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.dob}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          6&nbsp;&nbsp;
                        </p>
                        <p>
                          Nationality / Religion / Caste / Mother Tongue
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.caste ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="caste"
                                value={moreData.caste}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.caste}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>7&nbsp;&nbsp;</p>
                        <p>
                          Date of admission in school with class
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.addmissiondate ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="addmissiondate"
                                value={moreData.addmissiondate}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.addmissiondate}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>8&nbsp;&nbsp;</p>
                        <p>
                           Class&nbsp;in which the pupil last studied (in Figure and
                          in words)
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.preClass ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="preClass"
                                value={moreData.preClass}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.preClass}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>9&nbsp;&nbsp;</p>
                        <p>
                          School / Board Examination last taken
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.bExam ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="bExam"
                                value={moreData.bExam}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.bExam}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          10&nbsp;
                        </p>
                        <p>
                          Subject's studied
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.subject ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="subject"
                                value={moreData.subject}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.subject}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          11&nbsp;
                        </p>
                        <p>
                           Whether qualified for promotion to the higher class&nbsp;
                          if so, to which class
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.qualified ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="qualified"
                                value={moreData.qualified}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.qualified}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>12&nbsp;</p>
                        <p>
                          Month upto which the pupil has paid the school dues
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.fees ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="fees"
                                value={moreData.fees}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.fees}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>
                          13&nbsp;
                        </p>
                        <p>
                           Any fee concession availed of if so, the nature of
                          such concession
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.feesc ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="feesc"
                                value={moreData.feesc}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.feesc}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>14&nbsp;</p>
                        <p>
                          Total No. of present Days / Total No. Working Days
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.days ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                value={moreData.days}
                                name="days"
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.days}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>15&nbsp;</p>
                        <p>
                          Whether NCC Cadet / Boy Scout / Girl Guide
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.camp ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="camp"
                                value={moreData.camp}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.camp}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>16&nbsp;</p>
                        <p>
                          Games played or extra-curricular activities in
                          which the pupil usually took part (Mention achievement
                          level there in)
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.games ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="games"
                                value={moreData.games}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.games}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>17&nbsp;</p>
                        <p>
                          General conduct
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.genral ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="genral"
                                value={moreData.genral}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.genral}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>18&nbsp;</p>
                        <p>
                          Date of application for certificate
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.certificateDate ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="certificateDate"
                                value={moreData.certificateDate}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.certificateDate}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>19&nbsp;</p>
                        <p>
                        Date of issue of certificate
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.dateIssue ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="dateIssue"
                                value={moreData.dateIssue}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.dateIssue}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className={Styles.fontContainer}>
                          <p>20&nbsp;</p>
                        <p>
                          Reasons&nbsp; for leaving the school
                        </p>
                        </div>
                        <p style={{ width: "40%" }}>
                          {moreData.reason ? 
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="reason"
                                value={moreData.reason}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.reason}
                              </p>
                            )
                           : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                      <div className={Styles.fontContainer}>
                        <p>21&nbsp;</p>
                        <p>
                           Any other remarks
                        </p>
                      </div>
                        <p style={{ width: "40%" }}>
                          {moreData.remark ? (
                            edit == true ? (
                              <input
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                                name="remark"
                                value={moreData.remark}
                                onChange={(e) => valueChange(e)}
                              />
                            ) : (
                              <p
                                style={{
                                  borderBottom: "1px solid black",  fontWeight:"700",
                                  fontSize: ".65rem",
                                  paddingBottom: "3px",
                                  wordSpacing: "4px",
                                }}
                              >
                                {moreData.remark}
                              </p>
                            )
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1vw" }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: ".65rem",
                          fontWeight: "bold",
                        }}
                      >
                        I hereby certify and confirm that I had personally
                        verified all the entries made in this T.C. and entries
                        made in the <br /> Admission register as well as the
                        O.C. of the T.C. and they tally each other.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Prepared by{" "}
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Checked by
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Receiver's signature with Date
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                            Full Name
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <span>___________</span>
                        <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                          Rev. Fr Vijay J. D'Souza
                        </p>
                        <p style={{ fontSize: ".65rem", fontWeight: "bold" }}>
                          Principal
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{position:"absolute",zIndex:"0"}} >
        <div style={{width:"20vw",height:"20vw"}}>
          <img style={{width:"100%",height:"100%"}} src={backimg}/>
        </div>
        </div> */}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "77%",
              marginLeft: "1vw",
            }}
            id="pdfcerti"
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div>
                  <A3Header />
                  <div style={{ marginTop: ".5vw" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.3rem",
                          textAlign: "center",
                          fontWeight: 700,
                          marginTop: ".5vw",
                          fontFamily: "sans",
                          borderBottom: "1px solid black",  fontWeight:"700",
                          paddingBottom: "5px",
                        }}
                      >
                        TRANSFER CERTIFICATE
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: ".5vw",
                      }}
                    >
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>Admission No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.transferInfo ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                paddingBottom: "3px",
                                fontSize: ".95rem",
                              }}
                            >
                              {moreData.transferInfo} &nbsp;&nbsp;&nbsp;
                            </p>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>STS &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.STSNo ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                paddingBottom: "3px",
                                fontSize: ".95rem",
                              }}
                            >
                              {moreData.STSNo} &nbsp;&nbsp;&nbsp;
                            </p>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                      <span style={{ display: "flex" }}>
                        <p style={{ fontSize: ".95rem" }}>TC &nbsp;No.</p>
                        &nbsp;&nbsp;&nbsp;
                        <p>
                          {moreData.TCNo ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                paddingBottom: "3px",
                                fontSize: ".95rem",
                              }}
                            >
                              {" "}
                              {moreData.TCNo} &nbsp;&nbsp;&nbsp;
                            </p>
                          ) : (
                            <span>&nbsp;&nbsp;&nbsp;______________</span>
                          )}
                        </p>
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          1 Name of Pupil
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.name ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.name}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          2 Sex
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.gender ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.gender}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          3 Mother's Name
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.motherName ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.motherName}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          4 Father's Name
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.fatherName ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.fatherName}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          5 Date of birth (in Christian Era) according to the
                          Admission Register (in figures and in words)
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.dob ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.dob}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          6 Nationality / Religion / Caste / Mother Tongue
                        </p>
                        <p
                          style={{
                            width: "40%",
                            borderBottom: "1px solid black",  fontWeight:"700",
                            fontSize: ".95rem",
                            paddingBottom: "3px",
                            wordSpacing: "4px",
                          }}
                        >
                          {moreData.caste}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          7 Date of admission in school with class
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.addmissiondate ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.addmissiondate}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          8 Class in which the pupil last studied (in Figure and
                          in words)
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.preClass ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.preClass}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          9 School / Board Examination last taken
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.bExam ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.bExam}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          10 Subject
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.subject ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.subject}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          11 Whether qualified for promotion to the higher class
                          if so, to which class
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.qualified ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.qualified}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          12 Month upto which the pupil has paid the school dues
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.fees ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.fees}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          13 Any fee concession availed of if so, the nature of
                          so concession
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.feesc ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.feesc}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          14 Total No. of present Days / Total No. Working Days
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.days ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.days}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          15 Whether NCC Cadet / Boy Scout / Girl Guide
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.camp ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.camp}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          16 Games played or extra-curricular activities in
                          which the pupil usually took part (Mention achievement
                          level there in)
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.games ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.games}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          17 General conduct
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.genral ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.genral}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          18 Date of application for certificate
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.certificateDate ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.certificateDate}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          19 Date of issue of certificate
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.dateIssue ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.dateIssue}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          20 Reasons&nbsp; for leaving the school
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.reason ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.reason}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p style={{ width: "50%", fontSize: ".95rem" }}>
                          21 Any other remarks
                        </p>
                        <p style={{ width: "40%" }}>
                          {moreData.remark ? (
                            <p
                              style={{
                                borderBottom: "1px solid black",  fontWeight:"700",
                                fontSize: ".95rem",
                                paddingBottom: "3px",
                                wordSpacing: "4px",
                              }}
                            >
                              {moreData.remark}
                            </p>
                          ) : (
                            " ______________________________"
                          )}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1vw" }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: ".95rem",
                          fontWeight: "bold",
                        }}
                      >
                        I hereby certify and confirm that I had personally
                        verified all the entries made in this T.C. and entries
                        made in the <br /> Admission register as well as the
                        O.C. of the T.C. and they tally each other.
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Prepared by{" "}
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Checked by
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Receiver's signature with Date
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                            Full Name
                          </p>
                          <span>&nbsp;&nbsp;___________</span>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <span>___________</span>
                        <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                          Rev. Fr Vijay J. D'Souza
                        </p>
                        <p style={{ fontSize: ".95rem", fontWeight: "bold" }}>
                          Principal
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{position:"absolute",zIndex:"0"}} >
        <div style={{width:"20vw",height:"20vw"}}>
          <img style={{width:"100%",height:"100%"}} src={backimg}/>
        </div>
        </div> */}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <button
          style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
            marginRight: "2vw",
          }}
          onClick={() => {
            A3handleCertiDownload();
            setPdfFormetDownload(true);
          }}
        >
          Download (A3 Format)
        </button>
        <button
          style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
            marginRight: "2vw",
          }}
          onClick={() => {
            A4handleCertiDownload();
            setPdfFormetDownload(false);
          }}
        >
          Download (A4 Format)
        </button>
        {edit == false ? (
        <button  style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }} onClick={onEditChnage}>Edit</button>
      ) : (
        <button  style={{
            marginTop: "1vw",
            backgroundColor: "green",
            color: "white",
            borderRadius: "4px",
            padding: "5px 8px 5px 8px",
            fontWeight: "bold",
          }} onClick={onChangeSave}>Save</button>
      )}
      </div>
     
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></div>
    </>
  );
};

export default DownloadManualTc;

import React, { useState, useEffect } from "react";
import StudentDashboard from "services/student-dashboard/circular.service";
import { Link } from "react-router-dom";
import { Input, Select, Spinner, useToast } from "@chakra-ui/react";
import Styles from "./Circular.module.scss";
import { debounce } from "utilities/utils";
import { IoMdLink } from "react-icons/io";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { MdFileDownload } from "react-icons/md";
import { FaFileAlt, FaMoneyBillAlt } from "react-icons/fa";
import { MdOutlineEvent } from "react-icons/md";
import { Tooltip, Button } from "@chakra-ui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";

function StudentCircular() {
  const toast = useToast();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState({
    title: "",
    month: "",
    year: "",
  });
  const [circulars, setCirculars] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [totalCirculars, setTotalCirculars] = useState(0);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("pageSize") || 10
  );
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const yearOptions = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 1 + index
  );
  useEffect(() => {
    if (pageSize) {
      localStorage.setItem("pageSize", pageSize);
    }
  }, [pageSize]);
  useEffect(() => {
    const storedPageSize = localStorage.getItem("pageSize");
    if (storedPageSize) {
      setPageSize(parseInt(storedPageSize));
    }
  }, []);
  useEffect(() => {
    let input_data = localStorage.getItem("circular_filter")
      ? JSON.parse(localStorage.getItem("circular_filter"))
      : {
          year: localStorage.getItem("yearResult") || new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          title: "",
        };
    setInputFields(input_data);

    if (input_data.title !== "") {
      setShowSearchIcon(false);
    }
  }, []);
  useEffect(() => {
    if (inputFields.month || inputFields.title !== "") {
      localStorage.setItem("circular_filter", JSON.stringify(inputFields));
      searchCircularList();
    }
  }, [inputFields.month, inputFields.year]);
  const fetchCirculars = async () => {
    try {
      setRequestInProgress(true);

      const payload = {
        ...inputFields,
      };
      const response = await StudentDashboard.requestSearchCircular(payload);
      if (response.error) {
        console.error(response.error);
      }
      setCirculars(response);

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  useEffect(() => {
    const pageSizeFromLocalStorage = localStorage.getItem("pageSize");
    if (!pageSizeFromLocalStorage && inputFields.month === "All") {
      setPageSize(10);
    }
  }, [inputFields.month]);
  useEffect(() => {
    setTotalCirculars(circulars.length);
  }, [circulars]);
  const fetchCircularsWithoutTitle = async () => {
    try {
      setRequestInProgress(true);
      const { title, ...payloadWithoutTitle } = inputFields;

      const response = await StudentDashboard.requestSearchCircular(
        payloadWithoutTitle
      );
      if (response.error) {
        console.error(response.error);
      }
      setCirculars(response);

      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };

  const searchCircularList = debounce(function () {
    fetchCirculars();
  }, 500);
  const handleReadMoreClick = () => {
    setPageSize((prevPageSize) => prevPageSize + 10);
    localStorage.setItem("pageSize", pageSize + 10);
  };
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "month" || name === "year") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        title: "",
      }));
      setPageSize(10);
      localStorage.setItem("pageSize", 10);
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  const handleSearchClick = () => {
    if (inputFields.title !== "") {
      localStorage.setItem("circular_filter", JSON.stringify(inputFields));
      fetchCirculars();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("circular_filter")) || {};
    localStorageData.title = "";
    localStorage.setItem("circular_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      title: "",
    }));

    fetchCircularsWithoutTitle();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleImageDownload = async (itemId) => {
    if (downloadInProgress) {
      return;
    }
    setDownloadInProgress(true);

    try {
      const response = await StudentDashboard.requestCircularById({
        circular_id: itemId,
      });
      const mediaUrls = response?.media || [];

      if (mediaUrls.length === 0) {
        toast({
          title: "No attachments",
          description: "No attachments for this circular",
          status: "error",
          position: "bottom-right",
          duration: 2000,
          isClosable: true,
        });
        setDownloadInProgress(false);
        return;
      }
      for (let i = 0; i < mediaUrls.length; i++) {
        const fileUrl = mediaUrls[i]?.media_url;
        const circularTitle = response.title;
        if (fileUrl) {
          const fileExtension = getFileExtension(fileUrl);
          const fileName = `${circularTitle}_Circular_${
            i + 1
          }.${fileExtension}`;
          await downloadFile(fileUrl, fileName);
        }
      }
      toast({
        title: "Attachments downloaded",
        description: "Attachments downloaded successfully",
        status: "success",
        position: "bottom-right",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  const getFileExtension = (url) => {
    const queryStringIndex = url.indexOf("?");
    const extension = url
      .substring(0, queryStringIndex !== -1 ? queryStringIndex : undefined)
      .split(".")
      .pop();
    return extension.toLowerCase();
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>CIRCULAR |</p>
          <p className={Styles.stats}>Total Circular : {circulars?.length}</p>
        </div>
        <div className={Styles.header}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  variant="filled"
                  placeholder="Search Title"
                  value={inputFields.title}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                  name="title"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.month}
              required
              onChange={handleInputChange}
              name="month"
            >
              <option value="All">All</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields.year}
              required
              onChange={handleInputChange}
              name="year"
            >
              {yearOptions.map((year, index) => (
                <option key={year} value={year}>
                  {`${year}-${(year + 1).toString().slice(-2)}`}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        {requestInProgress ? (
          <Spinner />
        ) : (
          <>
            <div className={Styles.lists}>
              {circulars.length == 0 ? (
                <div className={Styles.noResult}>No Circulars Found</div>
              ) : (
                ""
              )}
              {circulars.slice(0, pageSize).map((item) => (
                <Tooltip
                  label={`Circular No. : ${item.circular_number} | Category : ${item.category} `}
                  placement="top"
                >
                  <Link className={Styles.list} to={item._id}>
                    <div className={Styles.icon}>
                      {item.category === "FEES" ? (
                        <FaMoneyBillAlt />
                      ) : item.category === "EVENT" ? (
                        <MdOutlineEvent />
                      ) : item.category === "EXAM" ? (
                        <FaFileAlt />
                      ) : (
                        <IoMdLink />
                      )}
                    </div>
                    <div className={Styles.content}>
                      <p className={Styles.fontSize}>
                        Circular No. : {item.circular_number}
                      </p>
                      <p className={`${Styles.name} ${Styles.fontSize}`}>
                        {item.title}
                      </p>
                      <p className={Styles.date}>{formatDate(item.date)}</p>
                    </div>
                    <div style={{ marginTop: "auto", marginBottom: " 5px" }}>
                      <Tooltip
                        label="Downloading Circular Media Files"
                        placement="top"
                      >
                        <button
                          className={Styles.downloadButton}
                          onClick={(e) => {
                            e.preventDefault();
                            handleImageDownload(item._id);
                          }}
                          disabled={downloadInProgress}
                        >
                          <MdFileDownload style={{ fontSize: "35px" }} />
                        </button>
                      </Tooltip>
                    </div>
                  </Link>
                </Tooltip>
              ))}
            </div>
            {totalCirculars > pageSize && (
              <div className={Styles.readMoreButton}>
                <Button onClick={handleReadMoreClick} colorScheme="blue">
                  Read More
                </Button>
              </div>
            )}
          </>
        )}
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentCircular;

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { requestEnrolledStudentById } from "services/management-dashboard/transport.service";
import { useParams } from "react-router-dom";
import Styles from "./SingleEnrolledStudent.module.scss";
import moment from "moment";

function ManagementSingleEnrolledStudentPage() {
  const [inputFields, setInputFields] = useState({});
  const [eveningTime,setEveningTime] = useState("")
  const params = useParams();
  const fetchEnrolledStudent = async () => {
    try {
      const response = await requestEnrolledStudentById({
        studentId: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        setInputFields(response);
        let event= response?.enroll?.evening&&response?.enroll?.evening
        let idx =event.indexOf(":");
        let hour = event.substring(0, idx);
        let minutes = event.substring(5, idx);
        // hour = Number(hour);
        let ampm;
        if (hour >= 0 && hour <= 11) {
          ampm = "AM";
        } else {
          if (hour == 12) {
            ampm = "PM";
            hour = parseInt(event);
            event = hour + minutes;
          } else {
            ampm = "PM";
            hour = parseInt(event) - 12;
            event = hour + minutes;
          }
        }
        let fullTime = event + " " + ampm;
        setEveningTime(fullTime)
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchEnrolledStudent();
  }, []);

  return (
    <div>
      <p className={Styles.Modalname}>
        {inputFields?.enroll?.name} | {inputFields?.enroll?.class}{" "}
        {inputFields?.enroll?.section}
      </p>
      <div className={Styles.container}>
        <div className={Styles.containerItem}>
          <span className={Styles.itemHead}>Student Details</span>
          <div className={Styles.list}>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Date</p>
              <p>{moment(inputFields?.enroll?.date).format("DD-MM-YYYY")}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Morning Time</p>
              <p>{inputFields?.enroll?.morning} AM</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Evening Time</p>
              <p>{eveningTime}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}> Fee</p>
              <p>₹ {inputFields?.enroll?.amount}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Duration</p>
              <p>{inputFields?.enroll?.duration} Months</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Stop</p>
              <p>{inputFields?.enroll?.stop}</p>
            </div>
          </div>
        </div>
        <div className={Styles.containerItem}>
          <span className={Styles.itemHead}>Transport Details</span>
          <div className={Styles.list}>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Route Number</p>
              <p>{inputFields?.enroll?.route_number}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Bus Number</p>
              <p>{inputFields?.bus?.bus_number}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Bus Plate Number</p>
              <p>{inputFields?.bus?.bus_plate_number}</p>
            </div>
            {/* <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Route</p>
              <p>
                {inputFields?.bus?.from} to {inputFields?.bus?.to}
              </p>
            </div> */}
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Driver Name</p>
              <p>{inputFields?.bus?.driver_name}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Driver Phone</p>
              <p>{inputFields?.bus?.driver_phone}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Starting Location</p>
              <p>{inputFields?.bus?.from}</p>
            </div>
            <div className={Styles.listItem}>
              <p className={Styles.listItemKey}>Ending Location</p>
              <p>{inputFields?.bus?.to}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementSingleEnrolledStudentPage;

import axios from "axios";
import { isInvalid, getRequestOptions, BASE_URL } from "services/utils";

export async function requestClassTeacher(payload = {}) {
  const url = BASE_URL + "/teacher/class/getAll";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestAllSubjects(payload = {}) {
  const url = BASE_URL + "/teacher/class/getSubjects";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestUpdateSubjectMarks(classId, payload = {}) {
  if (!classId && classId === "") {
    return { error: "Field missing" };
  }
  const url = BASE_URL + "/teacher/subjectMarks/update-marks/" + classId;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestAllSubjectMarks(classId, payload = {}) {
  if (!classId && classId === "") {
    return { error: "Field Missing" };
  }
  const url = BASE_URL + "/teacher/subjectMarks/getOne-subject/" + classId;

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}

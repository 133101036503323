import React, { useState, useEffect } from "react";
import Styles from "./Permissions.module.scss";
import Events from "../../../assets/icons/Events .png";
import Circulars from "../../../assets/icons/Circulars.png";
import Classroom from "../../../assets/icons/Classroom .png";
import Library from "../../../assets/icons/Library.png";
import Exams from "../../../assets/icons/Exams.png";
import Attendance from "../../../assets/icons/Attendance.png";
import TimeTable from "../../../assets/icons/Time Table.png";
import AcademicCalendar from "../../../assets/icons/Academic Calendar.png";
import BusTransport from "../../../assets/icons/School Bus.png";
import Canteen from "../../../assets/icons/Canteen .png";
import Permissions from "../../../assets/icons/Permissions.png";
import Certificate from "../../../assets/icons/Certificates.png";
import SchoolFee from "../../../assets/icons/Fees.png";
import SMS from "../../../assets/icons/SMS.png";
import RecycleBin from "../../../assets/icons/Recycle Bin .png";
import Backup from "../../../assets/icons/Backup.png";
import Branding from "../../../assets/icons/Branding.png";
import Admission from "../../../assets/icons/Admission .png";
import Admin from "../../../assets/icons/Admin .png";
import Staff from "../../../assets/icons/teacher.png";
import Student from "../../../assets/icons/student.png";
import Report_Centre from "../../../assets/icons/Report_Centre.png";
import Notification from "../../../assets/icons/Notification.png";
import Tools from "../../../assets/icons/Tools.png";
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {
  requestGetOneUser,
  requestUpdateUserPermission,
} from "services/admin-dashboard/permission.service";

const SingleAdminPermission = () => {
  const location = useLocation();
  const toast = useToast();
  const updateModal = useDisclosure();
  const [uncheckedModules, setUncheckedModules] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [payloadData, setPayloadData] = useState({
    BACKUP: false,
    PERMISSION: false,
    BRANDING: false,
    TOOLS: false,
    NOTIFICATION: false,
    "ADMIN/ MANAGEMENT": false,
    "RECYCLE BIN": false,
    ADMISSION: false,
    STUDENTS: false,
    STAFF: false,
    EVENTS: false,
    CIRCULAR: false,
    CLASSROOM: false,
    "ACADEMIC CALENDAR": false,
    LIBRARY: false,
    "BUS TRANSPORT": false,
    CANTEEN: false,
    "EXAM REPORT": false,
    ATTENDANCE: false,
    "SCHOOL FEES": false,
    TIMETABLE: false,
    CERTIFICATE: false,
    SMS: false,
    "REPORT CENTRE": false,
  });

  const modules = [
    "BACKUP",
    "BRANDING",
    "TOOLS",
    "ADMIN/ MANAGEMENT",
    "RECYCLE BIN",
    "NOTIFICATION",
    "ADMISSION",
    "STUDENTS",
    "STAFF",
    "EVENTS",
    "CIRCULAR",
    "PERMISSION",
    "CLASSROOM",
    "ACADEMIC CALENDAR",
    "LIBRARY",
    "BUS TRANSPORT",
    "CANTEEN",
    "EXAM REPORT",
    "ATTENDANCE",
    "SCHOOL FEES",
    "TIMETABLE",
    "CERTIFICATE",
    "SMS",
    "REPORT CENTRE",
  ];
  const storedPermissions = localStorage.getItem("Permission");
  const handleUpdateUserPermission = async () => {
    const allowedKeys = Object.entries(payloadData)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);
    let payload = {
      user_id: location?.state?.id,
      allowed: allowedKeys,
    };
    try {
      const response = await requestUpdateUserPermission(payload);
      setRequestInProgress(true);
      setTimeout(() => {
        setRequestInProgress(false);
        toast({
          title: "Updated Succesfully",
          description: "Updated Admin",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }, 1000);
      getOneUserDetail();
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
    updateModal.onClose();
  };

  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setPayloadData({ ...payloadData, [name]: checked });

  //   if (!checked && !uncheckedModules.includes(name)) {
  //     setUncheckedModules([...uncheckedModules, name]);
  //   } else if (checked && uncheckedModules.includes(name)) {
  //     const updatedModules = uncheckedModules.filter(
  //       (module) => module !== name
  //     );
  //     setUncheckedModules(updatedModules);
  //   }
  // };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const isModuleInLocalStorage = localStorage
      .getItem("Permission")
      .includes(name);
    if (!isModuleInLocalStorage) {
      return;
    }
    setPayloadData({ ...payloadData, [name]: checked });
    if (!checked && !uncheckedModules.includes(name)) {
      setUncheckedModules([...uncheckedModules, name]);
    } else if (checked && uncheckedModules.includes(name)) {
      const updatedModules = uncheckedModules.filter(
        (module) => module !== name
      );
      setUncheckedModules(updatedModules);
    }
  };

  const getOneUserDetail = async () => {
    const payload = {
      user_id: location?.state?.id,
    };
    try {
      const response = await requestGetOneUser(payload);
      let tempModuleData = {};
      modules.forEach((val) => {
        if (response.allowed.includes(val)) {
          tempModuleData[val] = true;
        } else {
          tempModuleData[val] = false;
        }
      });
      setPayloadData(tempModuleData);
    } catch (e) {
      console.log("error", e);
    }
  };
  const trueCount = Object.values(payloadData).filter(
    (value) => value === true
  ).length;
  useEffect(() => {
    getOneUserDetail();
  }, []);

  return (
    <div>
      <div className={Styles.headerWrapper}>
        <div className={Styles.heading}>
          PERMISSION&nbsp;| {location?.state?.name}
        </div>
        <p
          className={Styles.summaryheading}
          style={{ color: trueCount === modules.length ? "green" : "orange" }}
        >
          Permission Summary :- ({trueCount} / {modules.length})
        </p>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Backup} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Backup</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  name="BACKUP"
                  colorScheme="red"
                  isChecked={payloadData.BACKUP}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Permissions} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Permissions</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="PERMISSION"
                  isChecked={payloadData.PERMISSION}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Branding} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Branding</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="BRANDING"
                  isChecked={payloadData.BRANDING}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Tools} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Tools</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="TOOLS"
                  isChecked={payloadData.TOOLS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Admin} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Admin/Management</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ADMIN/ MANAGEMENT"
                  isChecked={payloadData["ADMIN/ MANAGEMENT"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={Notification}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Notification</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="NOTIFICATION"
                  isChecked={payloadData["NOTIFICATION"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Student} alt="iconImg" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Students</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  name="STUDENTS"
                  colorScheme="red"
                  isChecked={payloadData.STUDENTS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Staff} alt="iconImg" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Staff</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  name="STAFF"
                  colorScheme="red"
                  isChecked={payloadData.STAFF}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Admission} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Admission</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ADMISSION"
                  isChecked={payloadData.ADMISSION}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Events} alt="iconImg" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Events</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  name="EVENTS"
                  colorScheme="red"
                  isChecked={payloadData.EVENTS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Circulars} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Circular</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CIRCULAR"
                  isChecked={payloadData.CIRCULAR}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Classroom} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Classroom</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CLASSROOM"
                  isChecked={payloadData.CLASSROOM}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={Report_Centre}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Report Centre</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="REPORT CENTRE"
                  isChecked={payloadData["REPORT CENTRE"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={AcademicCalendar}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Academic Calendar</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ACADEMIC CALENDAR"
                  isChecked={payloadData["ACADEMIC CALENDAR"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Library} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Library</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="LIBRARY"
                  isChecked={payloadData.LIBRARY}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img
                className={Styles.iconImg}
                src={BusTransport}
                alt="profile"
              />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Bus Transport</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="BUS_TRANSPORT"
                  isChecked={payloadData["BUS TRANSPORT"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Canteen} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Canteen</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CANTEEN"
                  isChecked={payloadData.CANTEEN}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Exams} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Exam Report</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="EXAM REPORT"
                  isChecked={payloadData["EXAM REPORT"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Certificate} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Certificate</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="CERTIFICATE"
                  isChecked={payloadData.CERTIFICATE}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={Attendance} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Attendance</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="ATTENDANCE"
                  isChecked={payloadData.ATTENDANCE}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={SchoolFee} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>School Fees</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="SCHOOL FEES"
                  isChecked={payloadData["SCHOOL FEES"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={TimeTable} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Time table</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="TIMETABLE"
                  isChecked={payloadData.TIMETABLE}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={SMS} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>SMS</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="SMS"
                  isChecked={payloadData.SMS}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
          <div className={Styles.userList}>
            <div className={Styles.profile}>
              <img className={Styles.iconImg} src={RecycleBin} alt="profile" />
            </div>
            <div className={Styles.infoIcon}>
              <p className={Styles.name}>Recycle Bin</p>
              <p className={Styles.hr}>
                <hr />
              </p>
              <div className={Styles.permission}>
                <p className={Styles.number}>Permission</p>
                <Checkbox
                  spacing={5}
                  colorScheme="red"
                  name="RECYCLE BIN"
                  isChecked={payloadData["RECYCLE BIN"]}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.buttonRow}>
        <Button
          colorScheme="green"
          onClick={() => updateModal.onOpen()}
          className={Styles.button}
        >
          {requestInProgress ? <Spinner /> : "Update"}
        </Button>
      </div>
      <Modal isOpen={updateModal.isOpen} onClose={updateModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Permission</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to update the permission?
            {uncheckedModules.length > 0 && (
              <div>
                <p style={{ fontWeight: "bold", color: "red" }}>
                  Remove the following modules :
                </p>
                <ul>
                  {uncheckedModules.map((module) => (
                    <p key={module}>{module}</p>
                  ))}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="solid"
              mr={3}
              onClick={() => handleUpdateUserPermission()}
            >
              OK
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                updateModal.onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SingleAdminPermission;

import axios from "axios";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./ReportCard.module.scss";
// import AllMarks from './AllMarks';
import { Marks1to4, Marks5, Marks6to7 } from "./AllMarks";
// import { Link } from 'react-router-dom';
import { Checkbox, Divider } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const ReportCard = () => {
  const [classes, setClasses] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [examType, setExamType] = useState(null);
  const [isLoad, setLoad] = useState(false);
  const [post, setPost] = useState(null);
  const [selectedPost, setselectedPost] = useState([]);
  const [changeFlag, setChangeFlag] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(null);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const [data, setData] = useState([]);
  const initialFieldsState = {
    name: true,
    class: true,
    section: true,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const toast = useToast();
  const downloadModal = useDisclosure();
  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
    // headers: { "Authorization": `bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMzEyN2EzOTllNjI2NDRmMzgzMzk3NiIsImVtYWlsIjoic2hlcmxpbmVAZ21haWwuY29tIiwicm9sZSI6InRlYWNoZXIiLCJqd3RLZXkiOiJiOWE0MjAyYi04NGYyLTQ4MTAtODdjMy04YmIyNjExZmQ5NGEiLCJpYXQiOjE2NjQxOTA2MzIsImV4cCI6MTY5NTcyNjYzMn0.DSEzXZqb7sefBswZUUiedlBr-ioBvc_RL8cElO3DbmQ` }
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/classes`,
        {},
        config
      )
      .then((response) => {
        setClasses(response.data.class_teacher);
      })
      .catch((err) => {
        console.log(err);
      });

    if (localStorage.getItem("report")) {
      setSelectedClass(JSON.parse(localStorage.getItem("report")));
      setExamType(
        JSON.parse(localStorage.getItem("report"))[0].hasOwnProperty("mid_term")
          ? "mid_term"
          : "anual"
      );
    }
  }, []);

  function getExamResults(selectedClass, examType) {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/getMarks`,
        {
          class_id: selectedClass[0].class_id,
          exam: examType,
        },
        config
      )
      .then((response) => {
        setPost(response.data);
        setLoad(false);
        handleCompletionStatus(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleCompletionStatus = (values) => {
    let individual_status = values.map((val) => {
      if (Object.keys(val).includes("mid_term")) {
        let regular_grades = val.mid_term.filter(
          (m) => m.subject_name !== "DISCIPLINE"
        );
        let coscholastic_grades = val.mid_term.filter(
          (m) => m.subject_name === "DISCIPLINE"
        );
        let completion_status =
          regular_grades.every((r) => r.total !== "-") &&
          coscholastic_grades.every((r) => r.grade !== "N/A")
            ? true
            : false;
        let empty_status =
          regular_grades.every((r) => r.total === "-") &&
          coscholastic_grades.every((r) => r.grade === "N/A")
            ? true
            : false;
        return { empty: empty_status, completed: completion_status };
      }
    });
    let isEmptyStatus = individual_status.every((s) => s.empty === true);
    let hasCompletedStatus = individual_status.every(
      (s) => s.completed === true
    );
    setCompletionStatus({
      empty: isEmptyStatus,
      completed: hasCompletedStatus,
    });
  };

  const [grade1to4, setGrade1to4] = React.useState(false);
  const [grade5, setGrade5] = React.useState(false);
  const [grade6to7, setGrade6to7] = React.useState(false);

  const setAllFalse = () => {
    setGrade1to4(false);
    setGrade5(false);
    setGrade6to7(false);
  };
  useEffect(() => {
    if (selectedClass) {
      // if (selectedClass[0].class >= 1 && selectedClass[0].class <= 4) {
      // 	setAllFalse();
      // 	setGrade1to4(true);
      // } else if (selectedClass[0].class == 5) {
      // 	setAllFalse();
      // 	setGrade5(true);
      // } else if (selectedClass[0].class >= 6 && selectedClass[0].class <= 7) {
      // 	setAllFalse();
      // 	setGrade6to7(true);
      // } else {
      setAllFalse();
      setGrade6to7(true);
      // }
    }
  }, [post]);

  useEffect(() => {
    if (selectedClass && examType) {
      setLoad(true);
      getExamResults(selectedClass, examType);
    }
  }, [selectedClass, examType]);

  const navigate = useNavigate();

  const goToPreviewReport = (noOfReport) => {
    let reportSelected = noOfReport === "all" ? post : selectedPost;
    let requiredReportIDs = reportSelected.map((report) => report._id);
    let reportWithMappedDetails = [];
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/getReports`,
        {
          class: selectedClass[0].class,
          exam: examType,
          reports: requiredReportIDs,
        },
        config
      )
      .then((response) => {
        reportWithMappedDetails = response.data;
        localStorage.setItem("report", JSON.stringify(reportWithMappedDetails));
        navigate("./preview/" + examType + "/" + selectedClass[0].class, {
          state: {
            data: reportWithMappedDetails,
          },
        });
      })
      .catch((err) => {
        setPost(null);
      });
  };
  const getExamMarks = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/teacher/exam/getMarks`,
        {
          class_id: selectedClass[0].class_id,
          exam: examType,
        },
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const fileName =
    examType && selectedClass
      ? `SMU-${examType === "mid_term" ? "MidTerm" : "Annual"}-Marks-Report-${
          selectedClass[0]?.class
        }-${selectedClass[0]?.section}`
      : "SMU-Marks-Report";
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" ml={2} onClick={downloadModal.onOpen}>
        Archive
      </Button>
    );
  };
  const handleExportClick = async () => {
    try {
      const res = await getExamMarks();
      if (res) {
        let newArray = [];
        let electiveSubjects = [];
        res.forEach((item) => {
          let obj = {};
          if (fields.class) {
            obj["Class"] = item.class;
          }
          if (fields.section) {
            obj["Section"] = item.section;
          }
          if (fields.name) {
            obj["Name"] = item.name;
          }
          const examData = examType === "mid_term" ? item.mid_term : item.anual;
          examData
            .filter((subject) => !subject.optional_sub)
            .forEach((subject, index) => {
              obj[`Regular Subject ${index + 1} Name`] = subject.subject_name;
              obj[`Regular Subject ${index + 1} Code`] = subject.subject_code;
              obj[`Regular Subject ${index + 1} Internal`] = subject.internal;
              obj[`Regular Subject ${index + 1} External`] = subject.external;
              obj[`Regular Subject ${index + 1} Total`] = subject.total;
              obj[`Regular Subject ${index + 1} Grade`] = subject.grade;
            });
          const studentOptionalSubjects = res.flatMap((student) =>
            (examType === "mid_term" ? student.mid_term : student.anual)
              .filter((subject) => subject.optional_sub)
              .map((subject) => ({ name: student.name, ...subject }))
          );
          electiveSubjects = studentOptionalSubjects.reduce((acc, subject) => {
            if (subject.subject_name in acc) {
              acc[subject.subject_name].push(subject);
            } else {
              acc[subject.subject_name] = [subject];
            }
            return acc;
          }, {});
          newArray.push(obj);
        });
        setData(newArray);
        if (selectedFileFormat === "xlsx") {
          exportToXLSX(newArray, electiveSubjects, fileName);
        } else if (selectedFileFormat === "csv") {
          exportToCSV(newArray, electiveSubjects, fileName);
        }
      }
      toast({
        title: "Downloaded Successfully.",
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      downloadModal.onClose();
      setFields(initialFieldsState);
      setSelectedFileFormat("xlsx");
    } catch (error) {
      console.error(error);
    }
  };
  const exportToXLSX = (apiData, electiveSubjects, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = XLSX.utils.book_new();
    const indexData = apiData.map((studentData) => {
      const indexRow = {
        Name: studentData.Name,
        Class: studentData.Class,
        Section: studentData.Section,
      };
      Object.entries(studentData)
        .filter(([key]) => key.startsWith("Regular") && key.includes("Name"))
        .forEach(([key, value], idx) => {
          indexRow[`Subject ${idx + 1} Name`] = value;
        });
      Object.keys(electiveSubjects).forEach((electiveName, idx) => {
        const electiveStudents = electiveSubjects[electiveName];
        const hasStudent = electiveStudents.some(
          (student) => student.name === studentData.Name
        );
        if (hasStudent) {
          const elective = electiveStudents[0];
          indexRow[`Elective ${elective.elective_name}`] =
            elective.subject_name;
        }
      });
      return indexRow;
    });
    const indexWs = XLSX.utils.json_to_sheet(indexData);
    XLSX.utils.book_append_sheet(wb, indexWs, "Index");
    const subjects = Object.keys(apiData[0] || {}).filter(
      (key) => key.startsWith("Regular") && key.includes("Name")
    );
    subjects.forEach((subject, index) => {
      const subjectName = apiData[0][subject];
      const subjectData = apiData.map((studentData) => {
        const data = {
          Name: studentData.Name,
          [`${subjectName} CODE`]:
            studentData[`${subject.replace("Name", "Code")}`], // Modified this line
          Internal: studentData[`Regular Subject ${index + 1} Internal`],
          External: studentData[`Regular Subject ${index + 1} External`],
          Total: studentData[`Regular Subject ${index + 1} Total`],
          Grade: studentData[`Regular Subject ${index + 1} Grade`],
        };
        return data;
      });
      const ws = XLSX.utils.json_to_sheet(subjectData);
      XLSX.utils.book_append_sheet(wb, ws, subjectName);
    });
    for (const electiveName in electiveSubjects) {
      const electiveData = electiveSubjects[electiveName].map((elective) => {
        return {
          Name: elective.name,
          "Elective Name": elective.elective_name,
          "Subject Name": elective.subject_name,
          "Subject Code": elective.subject_code,
          Internal: elective.internal,
          External: elective.external,
          Total: elective.total,
          Grade: elective.grade,
        };
      });
      const ws = XLSX.utils.json_to_sheet(electiveData);
      XLSX.utils.book_append_sheet(wb, ws, electiveName);
    }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, electiveSubjects, fileName) => {
    let csvData = apiData.map((studentData) => {
      let row = {
        Name: studentData.Name,
        Class: studentData.Class,
        Section: studentData.Section,
      };
      Object.entries(studentData)
        .filter(([key]) => key.startsWith("Regular") && key.includes("Name"))
        .forEach(([key, value], idx) => {
          row[`Subject ${idx + 1} Name`] = value;
          row[`Subject ${idx + 1} Code`] =
            studentData[`Regular Subject ${idx + 1} Code`];
          row[`Regular Subject ${idx + 1} Internal`] =
            studentData[`Regular Subject ${idx + 1} Internal`];
          row[`Regular Subject ${idx + 1} External`] =
            studentData[`Regular Subject ${idx + 1} External`];
          row[`Regular Subject ${idx + 1} Total`] =
            studentData[`Regular Subject ${idx + 1} Total`];
          row[`Regular Subject ${idx + 1} Grade`] =
            studentData[`Regular Subject ${idx + 1} Grade`];
        });
      Object.keys(electiveSubjects).forEach((electiveName, idx) => {
        const electiveStudents = electiveSubjects[electiveName];
        if (Array.isArray(electiveStudents)) {
          const electiveStudent = electiveStudents.find(
            (student) => student.name === studentData.Name
          );
          if (electiveStudent) {
            row[`Elective ${idx + 1} Name`] = electiveName;
            row[`Subject ${idx + 1} Name`] = electiveStudent.subject_name;
            row[`Subject ${idx + 1} Code`] = electiveStudent.subject_code;
            row[`Internal ${idx + 1}`] = electiveStudent.internal;
            row[`External ${idx + 1}`] = electiveStudent.external;
            row[`Total ${idx + 1}`] = electiveStudent.total;
            row[`Grade ${idx + 1}`] = electiveStudent.grade;
          }
        }
      });
      return row;
    });
    const csv = Papa.unparse(csvData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // const exportToCSV = (apiData, fileName) => {
  //   const csv = Papa.unparse(apiData, {
  //     quotes: true,
  //     header: true,
  //     delimiter: ",",
  //   });
  //   const fileType = "text/csv;charset=UTF-8";
  //   const fileExtension = ".csv";
  //   const data = new Blob([csv], { type: fileType });
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };
  return (
    <div className={Styles.body}>
      {classes?.length > 0 ? (
        <>
          <div className={Styles.HeaderDiv}>
            <h1 className={Styles.headTitle}>Report Card</h1>
            <div className={Styles.header}>
              <div className={Styles.dropDown}>
                <Select
                  onChange={(e) => {
                    setSelectedClass(
                      classes.filter(
                        (item) =>
                          e.target.value.includes(item.class) &&
                          e.target.value.includes(item.section)
                      )
                    );
                    setChangeFlag(true);
                  }}
                  name="class"
                  placeholder="Select Class"
                  value={
                    localStorage.getItem("report") && !changeFlag
                      ? `${
                          JSON.parse(localStorage.getItem("report"))[0].class
                        }${
                          JSON.parse(localStorage.getItem("report"))[0].section
                        }`
                      : selectedClass
                      ? `${selectedClass[0].class}${selectedClass[0].section}`
                      : null
                  }
                >
                  {classes
                    ? classes.map((item) => (
                        <option value={`${item.class}${item.section}`}>
                          Class Teacher - {item.class}
                          {item.section}
                        </option>
                      ))
                    : null}
                </Select>
              </div>
              <div className={Styles.dropDown}>
                <Select
                  onChange={(e) => {
                    setExamType(e.target.value);
                    setChangeFlag(true);
                  }}
                  value={
                    localStorage.getItem("report") && !changeFlag
                      ? JSON.parse(
                          localStorage.getItem("report")
                        )[0].hasOwnProperty("mid_term")
                        ? "mid_term"
                        : "anual"
                      : examType
                  }
                  name="exam"
                  placeholder="Select Exam Semester"
                >
                  <option value="mid_term">Mid-Term Exam</option>
                  <option value="anual">Annual Exam</option>
                </Select>
              </div>
              <div className={Styles.indicator}>
                <p>
                  Completion Status:
                  <span>
                    {completionStatus
                      ? completionStatus.empty
                        ? "EMPTY"
                        : completionStatus.completed
                        ? "COMPLETE"
                        : "PARTIAL"
                      : ""}
                  </span>{" "}
                </p>
                <div
                  className={
                    completionStatus
                      ? completionStatus.empty
                        ? Styles.empty
                        : completionStatus.completed
                        ? Styles.completed
                        : Styles.progress
                      : Styles.empty
                  }
                />
              </div>
              {selectedClass && selectedClass[0] && examType?.length > 0 ? (
                <ExportToExcel apiData={data} fileName={fileName} />
              ) : null}
            </div>
          </div>
          <div className={Styles.tableWrapper}>
            {/* {(grade1to4 && post) && <Marks1to4 data={post} term={examType} />}
				{(grade5 && post) && <Marks5 data={post} term={examType} />} */}
            {grade6to7 && post && !isLoad ? (
              <Marks6to7
                data={post}
                selectedPost={selectedPost}
                setselectedPost={setselectedPost}
              />
            ) : (
              ""
            )}
          </div>
          <div className={Styles.buttonWrapper}>
            <div className={Styles.buttonSubWrapper}>
              <button
                onClick={() => {
                  selectedPost.length
                    ? goToPreviewReport("selected")
                    : toast({
                        title: "Please select atleast one student!",
                        variant: "left-accent",
                        position: "bottom-right",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                }}
                className={Styles.button}
              >
                Preview Selected
              </button>
              <button
                to="preview"
                onClick={() => goToPreviewReport("all")}
                className={Styles.button}
              >
                Preview All
              </button>
            </div>
            <Modal
              isOpen={downloadModal.isOpen}
              onClose={() => {
                downloadModal.onClose();
              }}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Downloading the Marks</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      File Format
                    </p>
                    <div className={Styles.fileFormatContainer}>
                      <Checkbox
                        isChecked={selectedFileFormat === "xlsx"}
                        onChange={() => {
                          setSelectedFileFormat("xlsx");
                        }}
                      >
                        XLSX File
                      </Checkbox>
                      <Checkbox
                        ml={4}
                        isChecked={selectedFileFormat === "csv"}
                        onChange={() => {
                          setSelectedFileFormat("csv");
                        }}
                      >
                        CSV File
                      </Checkbox>
                    </div>
                  </>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={handleExportClick} colorScheme="green">
                    Archive
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "30px",
            marginTop: "150px",
            backgroundColor: "white",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "60%",
            color: "red",
          }}
        >
          No data found for Report Card.
        </div>
      )}
    </div>
  );
};

export default ReportCard;

import React, { useEffect, useState } from "react";
import { requestAllStudentTimeTable } from "services/student-dashboard/timetable.service";
import Styles from "./StudentTimetable.module.scss";
const StudentTimetable = () => {
  const [timetable, setTimetable] = useState({});

  const fetchTimetableDetails = async () => {
    try {
      const payload = {};
      const response = await requestAllStudentTimeTable(payload);
      if (response.error) {
        return false;
      }
      if (response) {
        let sundayRemoved = response.dayTimeTable;
        sundayRemoved.shift();
        setTimetable({ ...response, dayTimeTable: sundayRemoved });
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
  useEffect(() => {
    fetchTimetableDetails();
  }, []);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>
            TIMETABLE ({timetable.class} | {timetable.section})
          </p>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.cards}>
          <div className="scroll-container" style={{ display: "inline-flex" }}>
            {timetable?.dayTimeTable?.map((item) => (
              <div className={Styles.card}>
                <div className={Styles.header}>
                  <p className={Styles.title}>{item.day}</p>
                </div>
                <div className={Styles.AllSubjects}>
                  {item?.subjects?.map((sub) => (
                    <div className={Styles.content}>
                      <div className={Styles.contentItem}>
                        <div className={Styles.left}>
                          <p className={Styles.name}>{sub.subject_name}</p>
                          <p className={Styles.class}>
                            {sub.startTime} - {sub.endTime}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentTimetable;

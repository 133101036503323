import React from "react";
import { Link } from "react-router-dom";
import Styles from "./ReportCard.module.scss";
const AdminReportCard = () => {
  return (
    <div className={Styles.body}>
      <div className={Styles.header}>
        <h1 className={Styles.headTitle}>Exam Report</h1>
      </div>
      <div className={Styles.wrapper}>
        <div className={Styles.row}>
          <Link to="mid">
            <div className={Styles.button}>Mid-Term Exam</div>
          </Link>
          {/* <Link to='null'>  */}
          {/* null for not released */}
          <Link to="annual">
            <div className={Styles.button}>Annual Exam</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminReportCard;

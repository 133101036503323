import React, { useState, useEffect } from "react";
import Styles from "./Permissions.module.scss";
import { Select, useToast, Tooltip, Input } from "@chakra-ui/react";
import {
  requestGetOneUser,
  requestGetUsers,
} from "services/admin-dashboard/permission.service";
import defaultUser from "../../../assets/icons/User.png";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { requestListByTeacherDepartment } from "services/admin-dashboard/staff.service";
const Permissions = () => {
  const toast = useToast();
  const [userType, setUserType] = useState(
    localStorage.getItem("userType") || "Teacher"
  );
  const [userData, setUserData] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [allowedModules, setAllowedModules] = useState("");
  const [selectedTeacherID, setSelectedTeacherID] = useState(null);
  const [selectedMgmtID, setSelectedMgmtID] = useState(null);
  const [selectedAdminID, setSelectedAdminID] = useState(null);
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState(
    localStorage.getItem("selectDepartment") || "All"
  );
  const [teacherName, setTeacherName] = useState(
    localStorage.getItem("teacherName") || ""
  );
  const [showSearchIcon, setShowSearchIcon] = useState(
    localStorage.getItem("teacherName") ? false : true
  );
  const [showFilteredList, setShowFilteredList] = useState(
    localStorage.getItem("showFilteredList") === "true" || false
  );
  useEffect(() => {
    if (userType) {
      localStorage.setItem("userType", userType);
    }
  }, [userType]);
  useEffect(() => {
    if (selectDepartment) {
      localStorage.setItem("selectDepartment", selectDepartment);
    }
  }, [selectDepartment]);
  const getUsers = async () => {
    const users = {
      user_type: userType,
    };

    if (userType === "Teacher") {
      users.department = selectDepartment;
    }

    try {
      if (users.user_type !== "") {
        const res = await requestGetUsers(users);
        setUserData(res);
      }
    } catch (e) {
      toast({
        title: e,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };
  useEffect(() => {
    if (userType === "Teacher") {
      getTeacherDepartment();
    }
  }, [userType]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  useEffect(() => {
    if (userType !== "") {
      getUsers();
    }
  }, [userType, selectDepartment]);
  const handlePreview = (event, userId) => {
    event.preventDefault();
    setSelectedTeacherID(userId);
    setSelectedMgmtID(userId);
    setSelectedAdminID(userId);
  };

  const getOneUserDetail = async () => {
    const payload = {
      user_id: selectedTeacherID || selectedMgmtID || selectedAdminID,
    };
    try {
      const response = await requestGetOneUser(payload);
      setAllowedModules(response.allowed);
    } catch (e) {
      console.log("error", e);
    }
  };
  useEffect(() => {
    if (selectedTeacherID || selectedMgmtID || selectedAdminID) {
      getOneUserDetail();
    }
  }, [selectedTeacherID, selectedMgmtID, selectedAdminID]);
  const handleSearchClick = () => {
    if (teacherName !== "") {
      setShowFilteredList(true);
      setShowSearchIcon(false);
    } else {
      setShowFilteredList(false);
    }
  };
  const handleCloseIconClick = () => {
    setShowSearchIcon(true);
    setTeacherName("");
    setShowFilteredList(false);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  useEffect(() => {
    localStorage.setItem("teacherName", teacherName);
  }, [teacherName]);
  useEffect(() => {
    localStorage.setItem("showFilteredList", showFilteredList);
  }, [showFilteredList]);
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <div className={Styles.staffCount}>
          <p className={Styles.stats}>
            PERMISSION | {userType}:&nbsp;
            {userData.length}
          </p>
        </div>
        <div className={Styles.header}>
          {userType == "Teacher" ? (
            <>
              <div className={Styles.filterWrapper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <Input
                      variant="filled"
                      placeholder="Search"
                      value={teacherName}
                      onChange={(e) => setTeacherName(e.target.value)}
                      onKeyDown={handleEnterKeyPress}
                      autoComplete="off"
                      name="teacherName"
                    />
                    {showSearchIcon ? (
                      <BiSearchAlt2
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                        fontSize={20}
                        onClick={handleSearchClick}
                      />
                    ) : (
                      <AiOutlineCloseCircle
                        onClick={handleCloseIconClick}
                        fontSize={20}
                        style={{
                          position: "absolute",
                          right: "1rem",
                          top: "30%",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={Styles.filterWrapper}>
                <Select
                  value={selectDepartment}
                  onChange={(e) => setSelectDepartment(e.target.value)}
                >
                  <option>All</option>
                  {teacherDepartment.map((data) => (
                    <option value={data.department}>{data.department}</option>
                  ))}
                </Select>
              </div>
            </>
          ) : null}
          <div className={Styles.filterWrapper}>
            <Select
              name="userType"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              placeholder="Select User Type"
            >
              <option value="Admin">Admin</option>
              <option value="Teacher">Staff</option>
              <option value="Management">Management</option>
            </Select>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {userType == "Admin"
            ? userData.map((admin, idx) => {
                return (
                  <div className={Styles.list}>
                    <Link
                      to={`admin/${admin._id}`}
                      key={admin["_id"] + idx}
                      state={{ name: admin.name, id: admin._id }}
                    >
                      <div style={{ display: "flex" }}>
                        <div className={Styles.profile}>
                          <img
                            className={Styles.profileImg}
                            src={defaultUser}
                            alt="profile"
                          />
                        </div>
                        <div className={Styles.info}>
                          <p className={Styles.name}>
                            {truncateText(admin?.name, 20)}
                          </p>
                          <p className={Styles.number}>{admin.email}</p>
                        </div>
                      </div>
                    </Link>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip label="View Permission" placement="left">
                        <div
                          style={{
                            border: " 2px solid black",
                            padding: "3px",
                            borderRadius: "50%",
                          }}
                        >
                          <FaEye
                            cursor="pointer"
                            onClick={(event) => handlePreview(event, admin._id)}
                          />
                        </div>
                      </Tooltip>
                      {selectedAdminID === admin._id && (
                        <div className={Styles.tooltipStyles}>
                          <div className={Styles.tooltipHeader}>
                            <IoIosClose
                              className={Styles.closeButton}
                              fontSize={30}
                              onClick={(event) => {
                                event.preventDefault();
                                setSelectedAdminID(null);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "14px" }}>
                            <p
                              style={{
                                fontSize: "9.5px",
                                fontWeight: "bold",
                              }}
                            >
                              {allowedModules && allowedModules?.join(", ")}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : null}
          {userType === "Teacher" &&
            (showFilteredList
              ? userData
                  .filter((teacher) => {
                    const matchesSearch = teacher.name
                      .toLowerCase()
                      .includes(teacherName.toLowerCase());
                    const matchesDepartment =
                      selectDepartment === "All" ||
                      teacher.department === selectDepartment;

                    return matchesSearch && matchesDepartment;
                  })
                  .map((teacher, idx) => (
                    <div className={Styles.list} key={teacher._id + idx}>
                      <Link
                        to={`teacher/${teacher._id}`}
                        state={{
                          name: teacher.name,
                          id: teacher._id,
                          department: teacher.department,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div className={Styles.profile}>
                            <img
                              className={Styles.profileImg}
                              src={
                                teacher?.url !== "N/A"
                                  ? teacher?.url
                                  : defaultUser
                              }
                              alt="profile"
                            />
                          </div>
                          <div className={Styles.info}>
                            <p className={Styles.name}>
                              {truncateText(teacher?.name, 20)}
                            </p>
                            <p className={Styles.number}>
                              Emp. ID : {teacher?.emp_id || "--"}
                            </p>
                            <p className={Styles.number}>
                              Department : {teacher?.department || "--"}
                            </p>
                          </div>
                        </div>
                      </Link>

                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip label="View Permission" placement="left">
                          <div
                            style={{
                              border: " 2px solid black",
                              padding: "3px",
                              borderRadius: "50%",
                            }}
                          >
                            <FaEye
                              cursor="pointer"
                              onClick={(event) =>
                                handlePreview(event, teacher._id)
                              }
                            />
                          </div>
                        </Tooltip>
                        {selectedTeacherID === teacher._id && (
                          <div className={Styles.tooltipStyles}>
                            <div className={Styles.tooltipHeader}>
                              <IoIosClose
                                className={Styles.closeButton}
                                fontSize={25}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setSelectedTeacherID(null);
                                }}
                              />
                            </div>
                            <div style={{ marginTop: "14px" }}>
                              <p
                                style={{
                                  fontSize: "9.5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {allowedModules && allowedModules?.join(", ")}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
              : userData.map((teacher, idx) => (
                  <div className={Styles.list} key={teacher._id + idx}>
                    <Link
                      to={`teacher/${teacher._id}`}
                      state={{
                        name: teacher.name,
                        id: teacher._id,
                        department: teacher.department,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div className={Styles.profile}>
                          <img
                            className={Styles.profileImg}
                            src={
                              teacher?.url !== "N/A"
                                ? teacher?.url
                                : defaultUser
                            }
                            alt="profile"
                          />
                        </div>
                        <div className={Styles.info}>
                          <p className={Styles.name}>
                            {truncateText(teacher?.name, 20)}
                          </p>
                          <p className={Styles.number}>
                            Emp. ID : {teacher?.emp_id || "--"}
                          </p>
                          <p className={Styles.number}>
                            Department : {teacher?.department || "--"}
                          </p>
                        </div>
                      </div>
                    </Link>

                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip label="View Permission" placement="left">
                        <div
                          style={{
                            border: " 2px solid black",
                            padding: "3px",
                            borderRadius: "50%",
                          }}
                        >
                          <FaEye
                            cursor="pointer"
                            onClick={(event) =>
                              handlePreview(event, teacher._id)
                            }
                          />
                        </div>
                      </Tooltip>
                      {selectedTeacherID === teacher._id && (
                        <div className={Styles.tooltipStyles}>
                          <div className={Styles.tooltipHeader}>
                            <IoIosClose
                              className={Styles.closeButton}
                              fontSize={25}
                              onClick={(event) => {
                                event.preventDefault();
                                setSelectedTeacherID(null);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "14px" }}>
                            <p
                              style={{
                                fontSize: "9.5px",
                                fontWeight: "bold",
                              }}
                            >
                              {allowedModules && allowedModules?.join(", ")}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )))}

          {userType == "Management"
            ? userData.map((management, idx) => {
                return (
                  <div className={Styles.list}>
                    <Link
                      to={`management/${management._id}`}
                      key={management["_id"] + idx}
                      state={{ name: management.name, id: management._id }}
                    >
                      <div style={{ display: "flex" }}>
                        <div className={Styles.profile}>
                          <img
                            className={Styles.profileImg}
                            src={
                              management?.url !== "N/A"
                                ? management?.url
                                : defaultUser
                            }
                            alt="profile"
                          />
                        </div>
                        <div className={Styles.info}>
                          <p className={Styles.name}>
                            {truncateText(management?.name, 20)}
                          </p>
                          <p className={Styles.number}>{management.email}</p>
                        </div>
                      </div>
                    </Link>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip label="View Permission" placement="left">
                        <div
                          style={{
                            border: " 2px solid black",
                            padding: "3px",
                            borderRadius: "50%",
                          }}
                        >
                          <FaEye
                            cursor="pointer"
                            onClick={(event) =>
                              handlePreview(event, management._id)
                            }
                          />
                        </div>
                      </Tooltip>
                      {selectedMgmtID === management._id && (
                        <div className={Styles.tooltipStyles}>
                          <div className={Styles.tooltipHeader}>
                            <IoIosClose
                              className={Styles.closeButton}
                              fontSize={30}
                              onClick={(event) => {
                                event.preventDefault();
                                setSelectedMgmtID(null);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "14px" }}>
                            <p
                              style={{
                                fontSize: "9.5px",
                                fontWeight: "bold",
                              }}
                            >
                              {allowedModules && allowedModules?.join(", ")}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

export default Permissions;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Select,
  Spinner,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { requestListByTeacherDepartment } from "services/management-dashboard/staff.service";
import {
  requestUpdateTeacherDetails,
  requestTeacherDetailsById,
  requestDeleteStaff,
} from "services/management-dashboard/staff.service";
import { fileToURI } from "utilities/utils";

import "react-datepicker/dist/react-datepicker.css";
import Styles from "./SingleStaffPage.module.scss";
import { requestUploadTeacherImages } from "services/common.service";
import { useRef } from "react";
import moment from "moment";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { MdContentPaste } from "react-icons/md";
const PLACEHOLDER_IMAGE_URL =
  "https://betachon.com/wp-content/uploads/2019/03/upload-your-logo-here-21052-p-800x800.png";

const ManagementSingleStaffPage = () => {
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [operationType, setOperationType] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [imageInputField, setImageInputFields] = useState({
    profile_url: "",
    file: null,
  });
  const [teacherDepartment, setTeacherDepartment] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const classTeacher = useDisclosure();
  const classesHandled = useDisclosure();
  const [removeProfile, setRemoveProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    employeeId: "",
    designation: "",
    department: "",
    phoneNumber: "",
    personel: {
      gender: "",
      dob: "",
      present_address: "",
      permanent_address: "",
      mother_tongue: "",
      religion: "",
      sub_religion: "",
      aadhar_number: "",
      nationlity: "",
      qualification: "",
      martial_status: "",
    },
  });
  const [isEmployeeDetailsVisible, setIsEmployeeDetailsVisible] =
    useState(true);
  const [isPersonalDetailsVisible, setIsPersonalDetailsVisible] =
    useState(true);
  const [isOtherDetailsVisible, setIsOtherDetailsVisible] = useState(true);
  const [isAcademicDetailsVisible, setIsAcademicDetailsVisible] =
    useState(true);
  React.useEffect(() => {
    fetchTeacherProfileDetails();
  }, []);

  const fetchTeacherProfileDetails = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestTeacherDetailsById({
        teacherId: params.id,
      });
      if (response.error) {
        console.error(response.error);
      }

      if (response) {
        const DOB = response?.personel?.dob;
        let dob = new Date(DOB);

        if (dob == "Invalid Date") {
          if (DOB.includes("-")) {
            dob = new Date(DOB.split("-").reverse().join("/"));
          } else if (DOB.includes("/")) {
            const [day, month, year] = DOB.split("/");
            const dateString = [month, day, year].join("/");
            dob = new Date(dateString);
          }
        } else {
          dob = new Date(DOB);
        }

        const _response = {
          ...response,
          name: response.name,
          email: response.email,
          emp_id: response.emp_id,
          personel: {
            ...response?.personel,
            dob,
            gender: response.personel.gender,
            present_address: response.personel.present_address,
            permanent_address: response.personel.permanent_address,
            mother_tongue: response.personel.mother_tongue,
            religion: response.personel.religion,
            sub_religion: response.personel.sub_religion,
            blood_group: response.personel.blood_group,
            aadhar_number: response.personel.aadhar_number,
            nationlity: response.personel.nationlity,
          },
        };
        setInputFields(_response);
        setRequestInProgress(false);
      }
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };

  const handleDelete = async () => {
    setOperationType("DELETE");
    setRequestInProgress(true);
    try {
      const response = await requestDeleteStaff({
        teacher_id: params.id,
      });

      if (response) {
        setOperationType("");
        setRequestInProgress(false);
        toast({
          title: "Staff deleted",
          description: "Deleted details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/management/dashboard/staff");
        onAlertClose();
      }
    } catch (e) {
      toast({
        title: e.msg,
        description: "Staff delete failed",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      setRequestInProgress(false);
      onAlertClose();
    }
  };

  const uploadImagesToCloud = async () => {
    try {
      const response = await requestUploadTeacherImages({
        profile_file: imageInputField.file,
        teacherId: inputFields.name,
        EMP_ID: inputFields.emp_id,
      });

      if (response.error) {
        setRequestInProgress(false);
        return false;
      }

      if (response) {
        return { profile_url: response.teacher_profile || "" };
      }
      return false;
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestInProgress(true);
    setOperationType("EDIT");
    let imageResponse;
    if (removeProfile) {
      imageResponse = {
        profile_url: "N/A",
      };
    } else if (imageInputField.file) {
      imageResponse = await uploadImagesToCloud();
    } else {
      imageResponse = { profile_url: inputFields?.personel?.profile_url };
    }

    if (imageResponse) {
      updateTeacherHandler(imageResponse, removeProfile);
    }
  };

  const getTeacherDepartment = async () => {
    const response = await requestListByTeacherDepartment();
    setTeacherDepartment(response.result);
  };

  React.useEffect(() => {
    getTeacherDepartment();
  }, []);

  const updateTeacherHandler = async (
    imageUrls = {},
    shouldRemoveProfile = false
  ) => {
    try {
      const {
        email,
        name,
        personel,
        password,
        phoneNumber,
        emp_id,
        department,
        designation,
      } = inputFields;
      const { nationlity, dob, ...rest } = personel;
      const payload = {
        teacherId: params.id,
        email,
        name,
        password,
        phoneNumber,
        emp_id,
        department,
        designation,
        personel: {
          ...rest,
          profile_url: shouldRemoveProfile
            ? "N/A"
            : imageUrls?.profile_url || inputFields.profile_url,
          nationlity: personel.nationlity,
          dob: dob && moment(dob, "DD/MM/YYYY").format("MM/DD/YYYY"),
        },
      };
      const response = await requestUpdateTeacherDetails(payload);
      setRequestInProgress(false);
      if (response) {
        toast({
          title: "Staff details updated",
          description: "Updated details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/management/dashboard/staff");
      }
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        description: "Update Request failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.error(error);
    }
  };
  const handleRemoveProfile = () => {
    setIsUpload(false);
    setRemoveProfile(true);
  };
  const handleSetImage = (e, fieldName) => {
    if (fieldName === "isUpload") {
      setIsUpload(true);
      setRemoveProfile(false);
    }
    const {
      target: { name, files },
    } = e;
    if (files && files[0]) {
      const selectedImageURL = URL.createObjectURL(files[0]);
      setSelectedImage(selectedImageURL);
      setImageInputFields((prev) => ({
        ...prev,
        [name]: files && files[0],
      }));
      fileToURI(files[0]).then((uri) => {
        setImageInputFields((prev) => ({
          ...prev,
          profile_url: uri,
        }));
      });
    }
  };

  const handleInputChange = (e, parentField) => {
    if (parentField === "pastePermanentAddress") {
      setInputFields((prev) => ({
        ...prev,
        personel: {
          ...prev.personel,
          present_address: prev.personel.permanent_address,
        },
      }));
      return;
    }
    if (typeof e.target !== "undefined") {
      const {
        target: { name, value, valueAsNumber, type, checked },
      } = e;
      const __value = {
        text: value,
        number: valueAsNumber,
        checkbox: checked,
        password: value,
        "select-one": value,
      }[type];

      if (parentField && typeof parentField === "string") {
        setInputFields((prev) => ({
          ...prev,
          [parentField]: {
            ...prev[parentField],
            [name]: __value || value,
          },
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: __value || value,
        }));
      }
    } else {
      if (parentField && typeof parentField === "object") {
        const [parent, child] = parentField;
        setInputFields((prev) => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: e,
          },
        }));
      }
    }
  };

  if (requestInProgress && operationType === "") {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  if (!inputFields) {
    return null;
  }
  return (
    <div className={Styles.body}>
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>STAFF UPDATE FORM</p>
      <div className={Styles.profile}>
        <div
          style={{
            padding: "4px",
            border: "2px solid black",
            borderRadius: "50%",
          }}
        >
          <img
            className={Styles.profileImage}
            src={
              removeProfile
                ? PLACEHOLDER_IMAGE_URL
                : isUpload
                ? selectedImage || PLACEHOLDER_IMAGE_URL
                : inputFields.url !== "N/A"
                ? inputFields.url
                : selectedImage || PLACEHOLDER_IMAGE_URL
            }
            alt="profile"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <label htmlFor="file">
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: "#228b15",
              }}
            >
              Select Profile
            </div>
            <input
              type="file"
              id="file"
              name="file"
              accept="image/*"
              hidden
              onChange={(e) => handleSetImage(e, "isUpload")}
            />
          </label>
          {!removeProfile && (inputFields.url !== "N/A" || selectedImage) && (
            <div
              style={{
                borderRadius: "5px",
                padding: "15px",
                marginTop: "8px",
                color: "white",
                borderRadius: "5px",
                padding: " 8px",
                marginTop: " 8px",
                cursor: "pointer",
                background: "white",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                fontWeight: "700",
                marginLeft: "10px",
                background: " #990f0d",
              }}
              onClick={() => handleRemoveProfile()}
            >
              Remove Profile
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Personal Details
            </button>
            {isPersonalDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsPersonalDetailsVisible(true)}
              />
            )}
          </div>
          {isPersonalDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Name <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    onChange={(e) => handleInputChange(e, "")}
                    value={inputFields.name}
                    name="name"
                    type="text"
                    placeholder="Enter Teacher Name"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Email <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    name="email"
                    onChange={(e) => handleInputChange(e, "")}
                    value={inputFields.email}
                    type="text"
                    placeholder="Email"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Phone No. <span className={Styles.asterisk}>*</span>
                  </p>
                  <div style={{ display: "flex" }}>
                    <select className={Styles.optionLists}>
                      <option>+91</option>
                    </select>
                    <input
                      name="phoneNumber"
                      onChange={(e) => handleInputChange(e, "")}
                      value={inputFields.phoneNumber}
                      required
                      type="text"
                      placeholder="XXXXX XXXXX"
                      className={Styles.textInput}
                    />
                  </div>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Gender <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    name="gender"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.gender}
                    required
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Qualification <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    onChange={(e) => handleInputChange(e, "personel")}
                    type="text"
                    placeholder="Enter Qualification"
                    name="qualification"
                    value={inputFields.personel.qualification}
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Blood Group <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    onChange={(e) => handleInputChange(e, "personel")}
                    name="blood_group"
                    value={inputFields.personel?.blood_group}
                    placeholder="Select Blood Group"
                  >
                    <option value="A(+VE)">A(+VE)</option>
                    <option value="B(+VE)">B(+VE)</option>
                    <option value="A(-VE)">A(-VE)</option>
                    <option value="B(-VE)">B(-VE)</option>
                    <option value="O(+VE)">O(+VE)</option>
                    <option value="O(-VE)">O(-VE)</option>
                    <option value="AB(+VE)">AB(+VE)</option>
                    <option value="AB(-VE)">AB(-VE)</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Permanent Address <span className={Styles.asterisk}>*</span>
                  </p>
                  <textarea
                    required
                    onChange={(e) => handleInputChange(e, "personel")}
                    name="permanent_address"
                    value={inputFields.personel.permanent_address}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                    placeholder="Enter Permanent Address"
                  />
                </div>
                <div className={Styles.form}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p className={Styles.placeholder}>
                      Present Address <span className={Styles.asterisk}>*</span>
                    </p>
                    <Tooltip
                      placement="top"
                      label="Paste the same data of Permanent Address"
                    >
                      <div
                        onClick={() =>
                          handleInputChange(null, "pastePermanentAddress")
                        }
                      >
                        <MdContentPaste fontSize={22} cursor="pointer" />
                      </div>
                    </Tooltip>
                  </div>
                  <textarea
                    required
                    onChange={(e) => handleInputChange(e, "personel")}
                    name="present_address"
                    value={inputFields.personel.present_address}
                    rows="4"
                    cols="50"
                    className={Styles.textInput}
                    placeholder="Enter Present Address"
                  />
                </div>
              </div>
            </>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button type="button" className={Styles.heading}>
              Employee Details
            </button>
            {isEmployeeDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsEmployeeDetailsVisible(true)}
              />
            )}
          </div>
          {isEmployeeDetailsVisible && (
            <div className={Styles.row}>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Employee ID <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "")}
                  value={inputFields.emp_id}
                  name="emp_id"
                  // type="number"
                  placeholder="Enter Employee ID"
                  className={Styles.textInput}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Designation <span className={Styles.asterisk}>*</span>
                </p>
                <input
                  required
                  onChange={(e) => handleInputChange(e, "")}
                  value={inputFields.designation}
                  name="designation"
                  type="text"
                  placeholder="Enter Designation"
                  className={Styles.textInput}
                />
              </div>
              <div className={Styles.form}>
                <p className={Styles.placeholder}>
                  Department <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  value={inputFields.department}
                  required
                  onChange={(e) => handleInputChange(e, "")}
                  name="department"
                  placeholder="Select Department"
                >
                  <option value="Driver">Driver</option>
                  <option value="Office">Office</option>
                  <option value="Teaching">Teaching</option>
                  <option value="Non-Teaching">Non-Teaching</option>
                  <option value="Test Teacher">Test Teacher</option>
                </Select>
              </div>
            </div>
          )}
          <hr
            style={{
              border: "2px solid black",
              width: "100%",
              margin: "5px 0",
            }}
          />
          <div className={Styles.headerContainer}>
            <button className={Styles.heading} type="button">
              Other Details
            </button>
            {isOtherDetailsVisible ? (
              <AiOutlineMinusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(false)}
              />
            ) : (
              <AiOutlinePlusSquare
                className={Styles.icons}
                onClick={() => setIsOtherDetailsVisible(true)}
              />
            )}
          </div>
          {isOtherDetailsVisible && (
            <>
              <div className={Styles.row}>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Aadhaar Number <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    required
                    onChange={(e) => handleInputChange(e, "personel")}
                    name="aadhar_number"
                    type="text"
                    placeholder="XXXX XXXX XXXX"
                    value={inputFields.personel.aadhar_number}
                    className={Styles.textInput}
                    minlength="12"
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Martial Status <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    required
                    name="martial_status"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.martial_status}
                    placeholder="Select Marital Status"
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Other">Other</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Date of Birth <span className={Styles.asterisk}>*</span>
                  </p>
                  <DatePicker
                    required
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    name="dob"
                    onChange={(e) => handleInputChange(e, ["personel", "dob"])}
                    value={inputFields.personel.dob}
                    selected={inputFields.personel.dob}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Nationality <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    required
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.nationlity}
                    placeholder="Select Nationality"
                    name="nationlity"
                  >
                    <option value="INDIAN">INDIAN</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Mother Tongue <span className={Styles.asterisk}>*</span>
                  </p>
                  <input
                    name="mother_tongue"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.mother_tongue}
                    required
                    type="text"
                    placeholder="Enter Mother Tongue"
                    className={Styles.textInput}
                  />
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>
                    Religion <span className={Styles.asterisk}>*</span>
                  </p>
                  <Select
                    required
                    name="religion"
                    value={inputFields.personel.religion}
                    onChange={(e) => handleInputChange(e, "personel")}
                    placeholder="Select Religion"
                  >
                    <option value="HINDU">HINDU</option>
                    <option value="MUSLIM">MUSLIM</option>
                    <option value="CHRISTIAN">CHRISTIAN</option>
                    <option value="OTHER">OTHER</option>
                  </Select>
                </div>
                <div className={Styles.form}>
                  <p className={Styles.placeholder}>Sub-Religion</p>
                  <input
                    name="sub_religion"
                    onChange={(e) => handleInputChange(e, "personel")}
                    value={inputFields.personel.sub_religion}
                    type="text"
                    placeholder="Enter Sub-Religion"
                    className={Styles.textInput}
                  />
                </div>
              </div>
            </>
          )}
          {inputFields.department === "Teaching" ? (
            <>
              <hr
                style={{
                  border: "2px solid black",
                  width: "100%",
                  margin: "5px 0",
                }}
              />
              <div className={Styles.headerContainer}>
                <button type="button" className={Styles.heading}>
                  Academic Details
                </button>
                {isAcademicDetailsVisible ? (
                  <AiOutlineMinusSquare
                    className={Styles.icons}
                    onClick={() => setIsAcademicDetailsVisible(false)}
                  />
                ) : (
                  <AiOutlinePlusSquare
                    className={Styles.icons}
                    onClick={() => setIsAcademicDetailsVisible(true)}
                  />
                )}
              </div>
              {isAcademicDetailsVisible && (
                <div className={Styles.row}>
                  <div className={Styles.formClassConatiner}>
                    <Button
                      mr={3}
                      colorScheme="blue"
                      onClick={() => classTeacher.onOpen()}
                    >
                      Classes Handled
                    </Button>
                    <Button
                      colorScheme="blue"
                      onClick={() => classesHandled.onOpen()}
                    >
                      Subjects Handled
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className={Styles.buttonRow}>
          <button type="submit" className={Styles.button2}>
            {requestInProgress && operationType === "EDIT" ? (
              <Spinner />
            ) : (
              "Update"
            )}
          </button>
          <button
            type="button"
            onClick={() => setAlertOpen(true)}
            className={Styles.button}
          >
            {requestInProgress && operationType === "DELETE" ? (
              <Spinner />
            ) : (
              "Delete"
            )}
          </button>
          <AlertDialog
            isOpen={isAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onAlertClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Staff
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onAlertClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      </form>
      <Modal isOpen={classTeacher.isOpen} onClose={classTeacher.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Class Teacher ({inputFields?.class_teacher?.length})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {inputFields?.class_teacher?.length > 0 ? (
              inputFields.class_teacher.map((teacher, index) => (
                <div key={index} className={Styles.classTeacher}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Class :</span>{" "}
                    {teacher.class}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Section :</span>{" "}
                    {teacher.section}
                  </p>
                </div>
              ))
            ) : (
              <p style={{ fontWeight: "bold" }}>
                Not a class teacher of any class.
              </p>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={classesHandled.isOpen} onClose={classesHandled.onClose}>
        <ModalOverlay />
        <ModalContent className={Styles.customModalContent}>
          <ModalHeader style={{ textAlign: "center" }}>
            Subjects Handled ({inputFields?.academic?.length})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {inputFields?.academic?.length > 0 ? (
              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                {inputFields?.academic?.map((teacher, index) => (
                  <div className={Styles.handleClassList}>
                    <div key={index} className={Styles.handleClasses}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Subject Name :
                        </span>{" "}
                        {teacher.subject}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Class : </span>
                        {teacher.class} | {teacher.section}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ fontWeight: "bold" }}>Not handled any class.</p>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManagementSingleStaffPage;

import React, { useState } from "react";
import Styles from "./StudentLogin.module.scss";
import { auth, firebaseDB } from "../../config/firebaseInitialize";
import {
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "contexts/auth-context";
import {
  useToast,
  Button,
  Input,
  InputGroup,
  Stack,
  InputRightElement,
  Tooltip,
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const Login = () => {
  const navigate = useNavigate();

  const url = "https://smudev.smuapp.org/dev/api";
  const [otpSent, setOtpSent] = useState(false);
  const toast = useToast();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otpValues, setOtpValues] = useState("");
  const { setUser } = useAuth();
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    if (token) {
      navigate("/student/dashboard");
    }
  }, [token]);
  const [multipleUserLogin, setMultipleUserLogin] = useState({ exists: false });

  const signInAuth = (data) => {
    const { token, payload } = data;
    if (payload && payload.id) {
      localStorage.setItem("studentId", payload.id);
    }

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("student_name", data.payload.name);
      localStorage.setItem("student_class", data.profile.academic.class);
      localStorage.setItem("student_section", data.profile.academic.section);
      localStorage.setItem("Permission", data.permission);
      localStorage.setItem("role", "student");
      const loggedInOn = new Date();
      localStorage.setItem("loggedInOn", loggedInOn);
      setUser(true);
      toast({
        title: "Login Successful.",
        description: "Logged in as student.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/student/dashboard");
    }
  };

  const handleMultipleLogin = async (user) => {
    const multiple_users = await axios.post(url + "/v1/auth/login-select", {
      phoneNumber: user.phoneNumber,
      jwtkey: multipleUserLogin.jwtkey,
      user_id: user._id,
      role: "student",
      email:email,
    });
    if (multiple_users.data.success) {
      localStorage.setItem("multipleStudent", multiple_users.data.success);
      localStorage.setItem("student_email", multiple_users.data.profile.email);
      signInAuth(multiple_users.data);
    }
  };
  const signIn = async (user) => {
    try {
      const { data } = await axios.post(url + "/v1/auth/student-login/new", {
        email: email,
        role: "student",
      },{headers:{"Authorization":`Bearer ${user}`}});
      if (data.success) {
        signInAuth(data);
      } else if (data.success == false) {
        const { jwtkey, students } = data;
        setMultipleUserLogin({
          exists: true,
          students: students,
          jwtkey: jwtkey,
        });
        toast({
          title: "Multiple Student Found",
          description: "Please Select one Student for Login",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      setError("Invalid username/password");
      toast({
        title: "Login Failed.",
        description: "User doesn't exist",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      console.error(error);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
        { returnSecureToken: true,}

      );
      const user = userCredential.user.accessToken
      signIn(user);
    } catch (e) {
      console.log(e);
      toast({
        title: "Login Failed.",
        description: "Please Enter Valid Email and Password",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // console.log("random number", ()

  return (
    <div className={Styles.body}>
      <div className={Styles.wrapper}>
        <div className={Styles.cover}></div>
        <div className={Styles.inputDiv}>
          {!multipleUserLogin.exists ? (
            <form>
              <p className={Styles.heading}>STUDENT LOGIN</p>
              <div className={Styles.inputContainer}>
                <Stack
                  spacing={3}
                  mb="3"
                  mt="3"
                  className={Styles.inputDivContainer}
                >
                  <InputGroup className={Styles.InputDiv}>
                    <Input
                      pr="4.5rem"
                      value={email}
                      type="text"
                      placeholder="Enter email address..."
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className={Styles.InputDiv}>
                    <Input
                      pr="4.5rem"
                      type={passwordType === "password" ? "password" : "text"}
                      placeholder="Enter password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={togglePassword}>
                        {/* {passwordType === "password" ? <p>👁️</p> : <p>🙈</p>} */}
                        {passwordType === "password" ? (
                          <BsFillEyeFill style={{ fontSize: "20px" }} />
                        ) : (
                          <BsFillEyeSlashFill style={{ fontSize: "20px" }} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {error.state === true ? (
                    <p className={Styles.error}>{error.message}</p>
                  ) : null}
                </Stack>
                <button
                  id="sign-in-button"
                  onClick={verifyOtp}
                  className={Styles.submitButton}
                >
                  <p className={Styles.submitText}>LOGIN</p>
                </button>
                <NavLink to="/forgotPassword" className={Styles.forgetPassword}>
                  Forgot password?
                </NavLink>
              </div>
              <NavLink to="/chooseLogin" className={Styles.changeType}>
                Change UserType
              </NavLink>
            </form>
          ) : (
            <div className={Styles.multiLoginContainer}>
              <p className={Styles.heading}>Select Student</p>
              {multipleUserLogin.students.map((user) => (
                <>
                  <div
                    className={Styles.thumbnail}
                    onClick={() => handleMultipleLogin(user)}
                  >
                    <div>
                      <Avatar
                        bg="teal.500"
                        src={user.profile_url}
                        className={Styles.profileImage}
                      />
                    </div>
                    <div className={Styles.profile}>
                      <p>{user.name}</p>
                      <p>
                        {user.class} - {user.section}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;

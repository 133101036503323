import axios from 'axios'
import { isInvalid, getRequestOptions, BASE_URL } from 'services/utils'

export async function getAllFeesMaster (payload = {}) {
    const url = BASE_URL + `/management/fees/master/class`
  
    try {
      const response = await axios.post(url, {...payload},getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function getAllFeePayments (payload = {}) {
    const url = BASE_URL + '/management/fees/all'
  
    try {
      const response = await axios.post(url, { ...payload },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function getStudentPayment (payload = {}) {
    console.log('oasd', payload)
    const url = BASE_URL + '/management/fees/one/' + payload
  
    try {
      const response = await axios.post(url, { ...payload },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function addStudentPayment (payload = {}) {
    console.log('add payload', payload)
    const url = BASE_URL + '/management/fees/add/' + payload.stud_id
  
    try {
      const response = await axios.post(url, { ...payload.data },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function deleteFeesPayment (payload = {}) {
    console.log('del payload', payload)
    const url = BASE_URL + '/management/fees/remove/' + payload.stud_id
  
    try {
      const response = await axios.post(url, { ...payload.data },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function updateFeesMaster (payload = {}) {
    console.log('add payload', payload)
    const url = BASE_URL + '/management/fees/master/update'
  
    try {
      const response = await axios.post(url, { ...payload.data },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function updateCanteenFees (payload = {}) {
    console.log('canteenfees payload', payload)
    const url = BASE_URL + '/management/canteen/updateCanteenFees'
  
    try {
      const response = await axios.post(url, { ...payload },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function feePaneltyDetails (payload = {}) {
    const url = BASE_URL + '/management/penalty/view-one-penalty/'+ payload.student_id
  
    try {
      const response = await axios.get(url, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function addStudentPenalty (payload = {}) {
    const url = BASE_URL + '/management/penalty/add-penalty'
  
    try {
      const response = await axios.post(url, { ...payload },getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function paidPenaltyUpdate (payload = {}) {
    const url = BASE_URL + "/management/penalty/pay-penalty"
  
    try {
      const response = await axios.patch(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function deleteStudentPenalty (payload = {}) {
    const payloadData= {...payload}
    console.log("payLoad for delete", payloadData)

    const url = BASE_URL + "/management/penalty/delete-penalty-for-student"
    try {
      const response = await axios.post(url,{...payload}, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function penaltyUpdate (payload = {}) {
    const url = BASE_URL + "/management/penalty/update-penalty-for-student"
  
    try {
      const response = await axios.patch(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  
  export async function feeStatistics (payload = {}) {
    const url = BASE_URL + "/management/fees/all-fees-statistics"
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function paidFeeDetails (payload = {}) {
    const url = BASE_URL + "/management/fees/paid-fees-list"
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function ConcessionFeeDetails (payload = {}) {
    const url = BASE_URL + "/management/fees/concession-fees-list"
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function partialPaidFeeDetails (payload = {}) {
    const url = BASE_URL + "/management/fees/partial-paid-fees"
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  export async function getClassAndSectionForFees (payload = {}) {
    const url = BASE_URL + "/management/fees/getClass"
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }


const Services = {
  getAllFeesMaster,
    getAllFeePayments,
    getStudentPayment,
    addStudentPayment,
    updateFeesMaster,
    deleteFeesPayment,
    updateCanteenFees,
    addStudentPenalty,
    feePaneltyDetails,
    paidPenaltyUpdate,
    deleteStudentPenalty,
    penaltyUpdate,
    feeStatistics,
    paidFeeDetails,
    getClassAndSectionForFees
  }
  
  export default Services
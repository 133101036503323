import React, { useEffect, useState } from "react";
import Styles from "../Library.module.scss";
import {
  Button,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Modal,
  ModalFooter,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import editIcon from "assets/icons/editIcon.png";
import deleteIcon from "assets/icons/deleteIcon.png";
import Add from "assets/icons/addWhite.png";
import Services from "services/admin-dashboard/library.service";
const CategoriesBook = () => {
  const [categories, setCategories] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [addCategories, setAddCategory] = useState({
    categoryId: "",
    category_name: "",
    category_Discrip: "",
  });
  const [getCategoryDetails, setGetCategoryDetails] = useState({
    category_code: "",
    category_name: "",
    description: "",
  });
  const deleteCategoryModal = useDisclosure();
  const addCategoryModal = useDisclosure();
  const updateCategoryModal = useDisclosure();
  const toast = useToast();
  const getAllCategories = async () => {
    try {
      const categoryRes = await Services.requestAllCategory();
      // console.log("categoryRes",categoryRes)
      if (categoryRes) {
        setCategories(categoryRes);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  const handleDeleteCategory = async ({ id }) => {
    try {
      const payload = {
        category_id: id,
      };
      const res = await Services.requestDeleteCategory(payload);
      if (res) {
        toast({
          title: "Message",
          description: "Category Deleted Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        getAllCategories();
      }
    } catch (e) {
      console.log(e);
      toast({
        title: e.message,
        // description: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleCategoryInputChange = (e) => {
    setAddCategory({ ...addCategories, [e.target.name]: e.target.value });
    // console.log("add",addCategories)
  };
  const handleAddCategory = async () => {
    try {
      const payload = {
        category_id: addCategories.categoryId,
        category_name: addCategories.category_name,
        description: addCategories.category_Discrip,
      };
      const response = await Services.requestAddCategory(payload);
      if (response) {
        toast({
          title: "Created",
          description: "Category Added Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        addCategoryModal.onClose();
        getAllCategories();
      }
    } catch (e) {
      console.log(e);
      toast({
        title: e.message,
        // description: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      addCategoryModal.onClose();
    }
  };
  const getCategoryDetailsByID = async (id) => {
    try {
      const payload = {
        category_id: id,
      };
      const response = await Services.requestCategoryByID(payload);
      if (response) {
        setGetCategoryDetails(response);
        updateCategoryModal.onOpen();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateCategoryInputChange = (e) => {
    setGetCategoryDetails({
      ...getCategoryDetails,
      [e.target.name]: e.target.value,
    });
    // console.log("add",addCategories)
  };
  const handleUpdateCategory = async (id) => {
    try {
      const payload = {
        category_id: id,
        updateBody: {
          category_code: getCategoryDetails.category_code,
          category_name: getCategoryDetails.category_name,
          description: getCategoryDetails.description,
        },
      };
      const res = await Services.requestUpdateCategory(payload);
      if (res) {
        toast({
          title: "Updated",
          description: "Category Update Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        updateCategoryModal.onClose();
        getAllCategories();
      }
    } catch (err) {
      console.log(err);
      toast({
        title: err.message,
        // description: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      updateCategoryModal.onClose();
    }
  };
  return (
    <>
      <div className={Styles.CategoriesBody}>
        <div className={Styles.header}>
          <div className={Styles.title}>
            <p>Category</p>
          </div>
          <div className={Styles.search}>
            <Input type="text" placeholder="Search" />
            <div onClick={() => addCategoryModal.onOpen()}>
              <Button colorScheme="teal">Create</Button>
            </div>
            <div>
              <Button colorScheme="green">Archive</Button>
            </div>
          </div>
        </div>
        <div className={Styles.allCategory}>
          {categories &&
            categories.map((data) => {
              return (
                <>
                  <div className={Styles.singleCategory}>
                    <div>
                      <p className={Styles.bookName}>{data.category_name}</p>
                    </div>
                    <div className={Styles.icons}>
                      <div
                        className={Styles.icon}
                        onClick={() => getCategoryDetailsByID(data._id)}
                      >
                        <img src={editIcon} />
                      </div>
                      <div
                        className={Styles.icon}
                        onClick={() => {
                          deleteCategoryModal.onOpen();
                          setCategoryId(data._id);
                        }}
                      >
                        <img src={deleteIcon} />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <Modal
          isOpen={deleteCategoryModal.isOpen}
          onClose={deleteCategoryModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <p>Are you sure you want to delete this category ?</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                variant="solid"
                mr={3}
                onClick={() => {
                  handleDeleteCategory({ id: categoryId });
                }}
              >
                Delete
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => deleteCategoryModal.onClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={addCategoryModal.isOpen}
          onClose={addCategoryModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <div className={Styles.InputGroup}>
                  <p>Category Name :</p>
                  <div>
                    <Input
                      placeholder="Category Name"
                      name="category_name"
                      required
                      onChange={(e) => handleCategoryInputChange(e)}
                    />
                  </div>
                </div>
                <div className={Styles.InputGroup}>
                  <p>Description :</p>
                  <div>
                    <Input
                      placeholder="Description"
                      name="category_Discrip"
                      required
                      onChange={(e) => handleCategoryInputChange(e)}
                    />
                  </div>
                </div>
                <div className={Styles.InputGroup}>
                  <p>Category Id :</p>
                  <div>
                    <Input
                      placeholder="Category Id"
                      name="categoryId"
                      required
                      onChange={(e) => handleCategoryInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                variant="solid"
                mr={3}
                onClick={() => {
                  handleAddCategory({ id: categoryId });
                }}
              >
                Add
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => addCategoryModal.onClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={updateCategoryModal.isOpen}
          onClose={updateCategoryModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <div className={Styles.InputGroup}>
                  <p>Category Name :</p>
                  <div>
                    <Input
                      placeholder="Category Name"
                      name="category_name"
                      required
                      onChange={(e) => handleUpdateCategoryInputChange(e)}
                      value={getCategoryDetails.category_name}
                    />
                  </div>
                </div>
                <div className={Styles.InputGroup}>
                  <p>Description :</p>
                  <div>
                    <Input
                      placeholder="Description"
                      name="description"
                      required
                      onChange={(e) => handleUpdateCategoryInputChange(e)}
                      value={getCategoryDetails.description}
                    />
                  </div>
                </div>
                <div className={Styles.InputGroup}>
                  <p>Category Id :</p>
                  <div>
                    <Input
                      placeholder="Category Id"
                      name="category_code"
                      required
                      onChange={(e) => handleUpdateCategoryInputChange(e)}
                      value={getCategoryDetails.category_code}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                variant="solid"
                mr={3}
                onClick={() => {
                  handleUpdateCategory(getCategoryDetails._id);
                }}
              >
                Update
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => updateCategoryModal.onClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default CategoriesBook;

import React, { useState } from "react";
import Header from "../CertificatePdfs/BonafideHeader/Header";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import Styles from "./DownloadManual.module.scss";
import jsPDF from "jspdf";
import { Button } from "@chakra-ui/react";

const ManualConductDownload = () => {
  const [data, setData] = useState([]);
  const loaction = useLocation();
  useEffect(() => {
    setData(loaction.state.inputFields);
  }, []);
  // console.log("data",data)
  const date = new Date();
  const dates = moment(date).format("DD/MM/YYYY");
  const handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a4");
    let pdf = await document.getElementById("pdfcerti");
    view
      .html(pdf)
      .then(() => {
        view.save(`${data.name} conduct.pdf`);
        // PdfRequestUpdate();
      })
      .catch((err) => console.log(err, "err"));
   
  };

  return (
    <>
    <div className={Styles.mainContainer}>
      <div className={Styles.conductCerticateWrapper} id="pdfcerti">
        <div>
          <Header />
        </div>
        <div>
          <div className={Styles.HeadingContainer}>
            <p className={Styles.ConductcertiHeading}>CONDUCT CERTIFICATE</p>
          </div>
          <div className={Styles.conductData} >
          <p >
            I hereby certify that <span className={Styles.dynaicData}> {data.name} </span> was a student in this school from&nbsp;
            <span className={Styles.dynaicData}>{data.start_date}</span>&nbsp;to <span className={Styles.dynaicData}>{data.end_date}</span> - Grade <span className={Styles.dynaicData}>{data.startingGrade}</span> to
            Grade <span className={Styles.dynaicData}>{data.lastGrade}</span>.
          </p>
          </div>
          <div className={Styles.dynaicData}>
          <p style={{marginTop:"1vw"}}>
            {data.gender == "male" ? "His" : "Her"} conduct and character were
            good
          </p>
          </div>
          <div style={{marginTop:"1vw"}}>
          <p> <span className={Styles.dynaicData}>Date</span>: {dates}</p>
          </div>
          <p style={{marginTop:"1vw"}} className={Styles.principalSign}><span className={Styles.dynaicData}>Principal</span></p>
        </div>
      </div>
      <Button className={Styles.downloadButoon} onClick={handleCertiDownload}>Download</Button>
      </div>
    </>
  );
};

export default ManualConductDownload;

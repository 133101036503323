import { S3 } from "@aws-sdk/client-s3";

//DO (DIGITAL OCEAN) SPACE AUTHENTICATION

const s3Client = new S3({
  endpoint: "https://sgp1.digitaloceanspaces.com",
  region: "sgp1",
  credentials: {
    accessKeyId: "DO009872CM23XFJB4XZ3",
    secretAccessKey: "DPJ8AnhQI37tWtO2LRZ3ayqTqdsyUo1gIKK1Rwwq4Q0",
  },
});
const digitalOceanEndpoint = "https://sgp1.digitaloceanspaces.com";

export { s3Client, digitalOceanEndpoint };

import { Input, Tab, TabList, TabPanel, TabPanels, Tabs,Button, Textarea } from "@chakra-ui/react";
import React from "react";
import Styles from "./About.module.scss";
const About = () => {
  return (
    <Tabs isLazy isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>About</Tab>
        <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Contact Us</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <AboutUs />
        </TabPanel>
        <TabPanel>
          <ContactUs />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default About;

const AboutUs = () => {
  return (
    <div className={Styles.body}>
      <p>
        This portal, comprising of a host of salient features, is designed to
        facilitate school management, administration, teaching and non-teaching
        staff, the student body and their parents. It allows an efficient upkeep
        of data, maintenance of attendance logs, issuance of certificates,
        allotment of assignments and updates to the notice board.
      </p>
      <p>
        The app aids school management and administration in accessing and
        amending student and faculty information, fees structures, the academic
        calendar and other provisions the school proffers. It enables teachers
        and the admin to create classrooms, share synchronous video lectures,
        documents and visual aids with students and assign homework to them. It
        also allows them to mark students’ attendance and monitor their
        performance.
      </p>
      <p>
        Likewise, the app is just as advantageous for non-teaching staff such as
        the school librarian, in editing, adding or removing library book
        details. Correspondingly it allows parents to avail their wards’
        academic conduct, attendance record, keep track of classroom
        notifications and other key particulars under a unified umbrella.
      </p>
      <p style={{ fontSize: "16px", fontWeight: "bold" }}>
        Designed & Developed at TFN Enterprise Pvt Ltd.
      </p>
    </div>
  );
};
const ContactUs = () => {
  return (
    <div>
      <p className={Styles.heading}>Contact us</p>
      <div className={Styles.mainContainer}>
        <div className ={Styles.leftSection}>
        <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
                Email
              </div>
              <p className={Styles.Description}>	director@tfnofficial.com </p>
                </div>
              </div>
              <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
              Phone
              </div>
              <p className={Styles.Description}>	8880296868 | 9739132434 </p>
                </div>
              </div>
              <div className={Styles.componentBody}>
              <div className={Styles.containerData}>
              <div className={Styles.imageSize}>
              Address
              </div>
              <p className={Styles.Description}>	1st Floor, Baliga Towers, Opp Kediyoor Hotels,
            Udupi, Karnataka, India - 576101 </p>
                </div>
              </div>
        </div>
        <div className={Styles.rightSection}>
          <div className={Styles.componentBody2}>
            <div className={Styles.NameBox}>
              <div>
                <p>
                  First Name
                </p>
                <Input required/>
              </div>
              <div>
                <p>
                  Last Name
                </p>
                <Input required/>
              </div>
            </div>
            <div>
              <p>Email</p>
              <Input required/>
            </div>
            <div>
              <p>Message</p>
              <Textarea required/>
            </div>
            
            <Button colorScheme="blue" style={{marginTop:"2vw"}} >Submit</Button>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

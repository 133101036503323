import React, { useEffect, useState } from "react";
import Styles from "./Classroom.module.scss";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle, AiOutlinePlusSquare } from "react-icons/ai";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import defaultUser from "../../../assets/icons/User.png";
import {
  requestAddClass,
  requestAllClassroom,
  requestClassDetails,
} from "services/management-dashboard/classroom.service";
import { useClassAndSections } from "hooks/classAndSection";
import { requestSearchTeacher } from "services/management-dashboard/staff.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";
const ManagementClassroom = () => {
  const [classesList, setClassesList] = useState([]);
  const [inputFields, setInputFields] = useState({
    class: "",
    teacherName: "",
  });
  const [addInputFields, setAddInputFields] = useState({
    class: "",
    section: "",
    facultyId: "",
  });
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { classAndSections } = useClassAndSections();
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showAddSearchIcon, setShowAddSearchIcon] = useState(true);
  const [searchTeachers, setSearchTeachers] = useState("");
  const [searchTeacherList, setSearchTeacherList] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSearchField, setShowSearchField] = useState(false);
  const [data, setData] = useState([]);
  const otherOptions = useDisclosure();
  const initialFieldsState = {
    class: true,
    section: true,
    classTeacherName: true,
    subjectName: true,
    subjectCategory: false,
    subjectTeacherName: true,
    subjectType: true,
    subjectCode: true,
    electiveName: false,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const downloadModal = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    let input_data = localStorage.getItem("classroom_filter")
      ? JSON.parse(localStorage.getItem("classroom_filter"))
      : { class: "All", teacherName: "" };
    setInputFields(input_data);
    if (input_data.teacherName.trim() !== "") {
      setShowSearchIcon(false);
    } else {
      setShowSearchIcon(true);
    }
  }, []);
  useEffect(() => {
    if (inputFields.class !== "" || inputFields.teacherName != "") {
      localStorage.setItem("classroom_filter", JSON.stringify(inputFields));
      requestPageDetails();
    }
  }, [inputFields.class]);
  const requestPage = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestAllClassroom({});
      setRequestInProgress(false);
      setClassesList(response);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  const requestPageDetails = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        class: inputFields.class,
        search_term: inputFields.teacherName,
      };
      const response = await requestAllClassroom(payload);
      setRequestInProgress(false);
      setClassesList(response);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  const requestPageDetailsWithName = async () => {
    try {
      setRequestInProgress(true);
      const payload = {
        search_term: inputFields.teacherName,
      };
      const response = await requestAllClassroom(payload);
      setRequestInProgress(false);
      setClassesList(response);
    } catch (e) {
      setRequestInProgress(false);
    }
  };
  const handleSearchClick = () => {
    if (inputFields.teacherName !== "") {
      localStorage.setItem("classroom_filter", JSON.stringify(inputFields));
      requestPageDetailsWithName();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    const localStorageData =
      JSON.parse(localStorage.getItem("classroom_filter")) || {};
    localStorageData.teacherName = "";
    localStorage.setItem("classroom_filter", JSON.stringify(localStorageData));

    setInputFields((prev) => ({
      ...prev,
      teacherName: "",
    }));
    requestPage();
    setShowSearchIcon(true);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class") {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
        teacherName: "",
      }));
      setShowSearchIcon(true);
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (value.trim() !== "") {
        setShowSearchIcon(true);
      } else {
        setShowSearchIcon(false);
      }
    }
  };
  const handleAddInputChange = ({ target: { name, value } }) => {
    setAddInputFields((prev) => ({ ...prev, [name]: value }));
  };
  const handleAddSearchClick = () => {
    if (searchTeachers !== "") {
      fetchTeachers();
      setShowAddSearchIcon(false);
    }
  };

  const handleAddCloseIconClick = () => {
    setSearchTeachers("");
    setShowAddSearchIcon(true);
  };
  const fetchTeachers = async () => {
    try {
      const response = await requestSearchTeacher({
        name: searchTeachers,
      });
      if (response.error) {
        console.error(response.error);
      }
      const _result =
        (response?.result &&
          Array.isArray(response?.result) &&
          response?.result) ||
        (response?.result1 &&
          Array.isArray(response?.result1) &&
          response?.result1) ||
        (response && Array.isArray(response) && response) ||
        [];
      setSearchTeacherList(_result);
      return _result;
    } catch (e) {
      console.error(e);
    }
  };
  const handleSwitchChange = () => {
    const updatedShowSearchField = !showSearchField;
    setShowSearchField(updatedShowSearchField);
    localStorage.setItem("showSearchField", updatedShowSearchField.toString());

    if (updatedShowSearchField) {
      const localStorageData =
        JSON.parse(localStorage.getItem("classroom_filter")) || {};
      localStorageData.class = "All";
      localStorage.setItem(
        "classroom_filter",
        JSON.stringify(localStorageData)
      );
      setInputFields({
        class: "All",
        teacherName: "",
      });
    }
    if (!updatedShowSearchField) {
      const localStorageData =
        JSON.parse(localStorage.getItem("classroom_filter")) || {};
      localStorageData.teacherName = "";
      localStorage.setItem(
        "classroom_filter",
        JSON.stringify(localStorageData)
      );
      setInputFields({
        class: "All",
        teacherName: "",
      });
    }
    requestPage();
    setShowSearchIcon(true);
    otherOptions.onClose();
  };
  useEffect(() => {
    const storedShowSearchField = localStorage.getItem("showSearchField");
    setShowSearchField(storedShowSearchField === "true");
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const fileName = inputFields.teacherName
    ? `SMU-Class-${inputFields.teacherName}`
    : `SMU-Class-${inputFields.class}`;
  const ExportToExcel = () => {
    return (
      <div className={Styles.downloadButton} onClick={downloadModal.onOpen}>
        {/* <Tooltip label="Download Student Details">
        <img className={Styles.download} src={download_new} alt="download" />
      </Tooltip> */}
        <Button colorScheme="green">Archive</Button>
      </div>
    );
  };
  const handleExportClick = async () => {
    const payload = {
      class: inputFields.teacherName ? "" : inputFields.class,
      search_term: inputFields.teacherName,
    };
    const res = await requestClassDetails(payload);
    if (res) {
      let newArray = [];
      res.forEach((item) => {
        if (item.subject_details) {
          item.subject_details.forEach((subjectDetail) => {
            processDetails(item, subjectDetail, newArray);
          });
        }
        if (item.elective_details) {
          item.elective_details.forEach((electiveDetail) => {
            processDetails(item, electiveDetail, newArray);
          });
        }
      });
      setData(newArray);
      if (selectedFileFormat === "xlsx") {
        exportToXLSX(newArray, fileName);
      } else if (selectedFileFormat === "csv") {
        exportToCSV(newArray, fileName);
      }
    }
    downloadModal.onClose();
    setFields(initialFieldsState);
    setSelectedFileFormat("xlsx");
  };
  const processDetails = (item, detail, newArray) => {
    let obj = {};
    if (fields.class) {
      obj["Class"] = item.class;
    }
    if (fields.section) {
      obj["Section"] = item.section;
    }
    if (fields.classTeacherName) {
      obj["Class Teacher Name"] = item.class_teacher.name;
    }
    if (fields.subjectName) {
      obj["Subject Name"] = detail.subject;
    }
    if (fields.subjectTeacherName) {
      obj["Teacher Name"] = detail.teacher_name;
    }
    if (fields.subjectCode) {
      obj["Subject Code"] = detail.subject_code;
    }
    if (fields.subjectType) {
      obj["Subject Type"] = detail.is_scholastic
        ? "Scholastic"
        : "Non-Scholastic";
    }
    if (fields.subjectCategory) {
      obj["Subject Category"] = item.subject_details
        ? "Regular Subject"
        : "Elective Subject";
      if (item.elective_details && item.elective_details.includes(detail)) {
        obj["Subject Category"] = "Elective Subject";
        const electiveDetail = item.elective_details.find(
          (elective) => elective === detail
        );
        obj["Elective Name"] = electiveDetail
          ? electiveDetail.elective_name
          : "-";
      }
    }
    newArray.push({ ...obj });
  };
  const exportToXLSX = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const wb = XLSX.utils.book_new();
    const indexData = apiData.map((item) => ({
      Class: item["Class"],
      Section: item["Section"],
      "Class Teacher Name": item["Class Teacher Name"],
    }));
    const wsIndex = XLSX.utils.json_to_sheet(indexData, {
      header: Object.keys(indexData[0]),
    });
    XLSX.utils.book_append_sheet(wb, wsIndex, "Index");
    const uniqueClasses = [
      ...new Set(
        apiData.map((item) => `${item["Class"]} - ${item["Section"]}`)
      ),
    ];
    uniqueClasses.forEach((classSection) => {
      const [className, sectionName] = classSection.split(" - ");
      const classData = apiData.filter(
        (item) => item["Class"] === className && item["Section"] === sectionName
      );
      const nonIndexData = classData.map((item) => {
        const { Class, Section, "Class Teacher Name": _, ...rest } = item;
        return rest;
      });
      const wsClass = XLSX.utils.json_to_sheet(nonIndexData, {
        header: Object.keys(nonIndexData[0]),
      });
      XLSX.utils.book_append_sheet(wb, wsClass, `${className}-${sectionName}`);
    });
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div>
      <div className={Styles.headerFirst}>
        <div className={Styles.statsWrapper}>
          <p className={Styles.stats}>CLASSROOM |</p>
          <p className={Styles.stats}>Total Classes : {classesList?.length}</p>
        </div>

        <div className={Styles.header}>
          {!showSearchField && (
            <div className={Styles.filterWrapper}>
              <Select
                value={inputFields.class}
                onChange={handleInputChange}
                name="class"
              >
                {classAndSections.map((grade, idx) => (
                  <option key={grade.class + idx} value={grade.class}>
                    Grade {grade.class}
                  </option>
                ))}
              </Select>
            </div>
          )}
          {showSearchField && (
            <div className={Styles.filterWrapper}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                  <Input
                    name="teacherName"
                    variant="filled"
                    placeholder="Search"
                    value={inputFields.teacherName}
                    onChange={handleInputChange}
                    onKeyDown={handleEnterKeyPress}
                    autoComplete="off"
                    paddingRight="3rem"
                  />
                  {showSearchIcon ? (
                    <BiSearchAlt2
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                      fontSize={20}
                      onClick={handleSearchClick}
                    />
                  ) : (
                    <AiOutlineCloseCircle
                      onClick={handleCloseIconClick}
                      fontSize={20}
                      style={{
                        position: "absolute",
                        right: "1rem",
                        top: "30%",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div>
            <Button
              colorScheme="green"
              mr={2}
              onClick={() => otherOptions.onOpen()}
            >
              Filter Options
            </Button>
          </div>
          {classesList.length >= 1 ? (
            <ExportToExcel apiData={data} fileName={fileName} />
          ) : null}
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : classesList?.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            classesList.map((_class, idx) => (
              <Link
                key={_class["_id"] + idx}
                to={_class["_id"]}
                state={{
                  name: _class.class_teacher.name,
                  id: _class.class_teacher.id,
                  teacher_profile_url: _class.class_teacher.teacher_profile_url,
                }}
                className={Styles.list}
              >
                <div className={Styles.profile}>
                  <img
                    className={Styles.profileImg}
                    src={
                      !_class?.class_teacher?.teacher_profile_url ||
                      !_class.class_teacher.teacher_profile_url.includes("http")
                        ? defaultUser
                        : _class.class_teacher.teacher_profile_url
                    }
                    alt="profile"
                  />
                </div>
                <div className={Styles.info}>
                  <p className={Styles.class}>
                    Grade : {_class?.class || "--"} | Section :{" "}
                    {_class?.section || "--"}
                  </p>
                  <hr
                    style={{ color: "red", borderColor: "red", height: "10px" }}
                  />
                  <p className={Styles.name}>
                    Class Teacher : {_class?.class_teacher?.name || "--"}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
        {showTopBtn && (
          <div
            onClick={scrollToTop}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: "#990f0d",
              borderRadius: "20px",
              display: " flex",
              alignItems: "flex-end",
            }}
          >
            <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
          </div>
        )}
      </div>
      <Modal isOpen={otherOptions.isOpen} onClose={otherOptions.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <p>
                Would you like to view the search option?{" "}
                <Switch
                  isChecked={showSearchField}
                  ml={2}
                  onChange={handleSwitchChange}
                />
              </p>
            </div>
            <p style={{ marginTop: "5px" }}>
              <span style={{ fontWeight: "bold", color: "red" }}>Note :</span>{" "}
              It will temporarily remove the dropdown.
            </p>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={downloadModal.isOpen}
        onClose={() => {
          downloadModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Please check the fields that you want to include in Downloading File
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <form>
                <div className={Styles.headerContainer}>
                  <button type="button" className={Styles.heading}>
                    Subject Details
                  </button>
                  <AiOutlinePlusSquare className={Styles.icons} />
                </div>
                <>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.subjectCode}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCode: !fields.subjectCode,
                        });
                      }}
                    >
                      Subject Code
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.subjectType}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectType: !fields.subjectType,
                        });
                      }}
                    >
                      Subject Type
                    </Checkbox>
                  </div>
                  <div className={Styles.row}>
                    <Checkbox
                      isChecked={fields.subjectCategory}
                      onChange={() => {
                        setFields({
                          ...fields,
                          subjectCategory: !fields.subjectCategory,
                        });
                      }}
                    >
                      Subject Category
                    </Checkbox>
                    <Checkbox
                      isChecked={fields.electiveName}
                      onChange={() => {
                        setFields({
                          ...fields,
                          electiveName: !fields.electiveName,
                        });
                      }}
                    >
                      Elective Name
                    </Checkbox>
                  </div>
                </>
              </form>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: "5px",
                }}
              >
                File Format
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleExportClick} colorScheme="green">
              Archive
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ManagementClassroom;


import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
import { getFirestore } from "firebase/firestore";
// import {getMessaging,onMessage,getToken} from 'firebase/messaging'
const firebaseConfig = {

    apiKey: "AIzaSyAF__M_bD1yFGfROdUM0imAezM5yisSU6Y",
    authDomain: "smu-saas.firebaseapp.com",
    projectId: "smu-saas",
    storageBucket: "smu-saas.appspot.com",
    messagingSenderId: "1019386361302",
    appId: "1:1019386361302:web:cf3a7507cfaebbeec96077"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firebasestorage = getStorage(app)
const firebaseDB = getFirestore(app) 
// const messaging = getMessaging()

// export const requestForToken = ()=>{
//     return getToken(messaging, {vapidKey:"BG_S2m-Jr5IjGpWst31HPHdqPB9_gEWU5awaVqPDisMdZKiCiig0T9WMrCqGYEhmXH37-FgpFpAfDQ3-RsnwksA"})
//     .then((currentToken)=>{
//         if(currentToken){
//             console.log("currentToken :", currentToken)
//         }else{
//             console.log("No Registration Token Available")
//         }
//     })
//     .catch(err =>{
//         console.log("error while register a token", err)
//     })
// }
// export const onMessageListener =()=>{
//     return new Promise((resolve)=>{
//         onMessage(messaging,(payload)=>{
//             console.log("OnMessage Payload", payload)

//             resolve(payload)
//         })
//     })
// }

export {auth};
export {firebasestorage};
export {firebaseDB}
export default app
import {
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Modal,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import defaultUser from "../../../assets/icons/User.png";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { useClassAndSections } from "hooks/classAndSection";
import Styles from "./RecycleBin.module.scss";
import Services from "services/management-dashboard/recycleBin.service";
const SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE = {
  class: "LKG",
  section: "A",
  studentName: "",
};

const RecycleBin = () => {
  return (
    <div>
      <div className={Styles.body}>
        <Tabs isLazy isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Students</Tab>
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Staff</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Students />
            </TabPanel>
            <TabPanel>
              <Staff />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};
export default RecycleBin;

const Students = () => {
  const { classAndSections, loading } = useClassAndSections();
  const [sections, setSections] = useState([]);
  const [searchFilterInputFields, setSearchFilterInputFields] = useState(
    SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE
  );
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [studentListRender, setStudentListRender] = useState("");
  const [studentIds, setStudentId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [totalStudents, setTotalStudents] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const restoreDeletedStudent = useDisclosure();
  const permanentDeletedStudent = useDisclosure();
  const toast = useToast();
  useEffect(() => {
    setTotalStudents(studentListRender.length);
  }, [studentListRender]);
  const isClassQuery =
    searchFilterInputFields.class !== "" &&
    searchFilterInputFields.class !== "all";
  const isSectionQuery =
    searchFilterInputFields.section !== "" &&
    searchFilterInputFields.section !== "all";
  const isQueried =
    isClassQuery ||
    isSectionQuery ||
    searchFilterInputFields.studentName !== "";
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == searchFilterInputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [searchFilterInputFields.class, classAndSections]);

  const handleSearchFilterInputChange = ({ target: { name, value } }) => {
    if (value === "ALL") {
      setSearchFilterInputFields((prev) => ({
        ...prev,
        [name]: value,
        section: "ALL",
        studentName: "",
      }));
    } else {
      setSearchFilterInputFields((prev) => ({ ...prev, [name]: value }));
      setSearchQuery("");
      setShowSearchIcon(true);
      setPageSize(10);
    }
  };

  const renderinactiveStudentlist = async () => {
    const payload = {
      class: searchFilterInputFields.class,
      section: searchFilterInputFields.section,
      ...(showSearchIcon && searchQuery !== "" ? { name: searchQuery } : {}),
    };
    let response = await Services.renderInactiveStudentList(payload);
    if (response) {
      setStudentListRender(response);
    }
  };

  useEffect(() => {
    renderinactiveStudentlist();
  }, [searchFilterInputFields.class, searchFilterInputFields.section]);
  const handleSearchClick = () => {
    if (searchQuery !== "") {
      renderinactiveStudentlist();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    setSearchQuery("");
    renderinactiveStudentlist();
    setShowSearchIcon(true);
  };
  const handleDeleteStudentPermanent = async ({ id }) => {
    try {
      const payload = {
        studentId: id,
      };
      let response = await Services.permanentDeleteInactiveStudent(payload);
      if (response) {
        renderinactiveStudentlist();
        permanentDeletedStudent.onClose();
        toast({
          title: "Message",
          description: "Deleted Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (e) {
      toast({
        title: "",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.log(e);
    }
  };

  const handleStudentRestore = async ({ id }) => {
    try {
      const payload = {
        studentId: id,
      };
      let response = await Services.restoreInactiveStudent(payload);
      if (response) {
        renderinactiveStudentlist();
        restoreDeletedStudent.onClose();
        toast({
          title: "Message",
          description: "Restore Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (e) {
      toast({
        title: "",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleReadMoreClick = () => {
    setPageSize((prevPageSize) => prevPageSize + 10);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={Styles.subWrapper}>
      <div className={Styles.header}>
        <p className={Styles.title}>
          Total Students: {studentListRender.length}
        </p>
        <div className={Styles.headerOption}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={searchQuery}
                  onKeyDown={handleEnterKeyPress}
                  autoComplete="off"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowSearchIcon(true);
                    } else {
                      setShowSearchIcon(false);
                    }
                  }}
                  paddingRight="3rem"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              onChange={handleSearchFilterInputChange}
              name="class"
              value={searchFilterInputFields.class}
              placeholder="Select Grade"
            >
              {classAndSections.map((grade, idx) => (
                <option key={grade.class + idx} value={grade.class}>
                  Grade {grade.class}
                </option>
              ))}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              onChange={handleSearchFilterInputChange}
              name="section"
              value={searchFilterInputFields.section}
              placeholder="Select Section"
            >
              {sections
                ? sections.map((section, idx) => (
                    <option key={section + idx} value={section}>
                      Section {section}
                    </option>
                  ))
                : ""}
            </Select>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {studentListRender.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            studentListRender &&
            studentListRender.slice(0, pageSize).map((data) => (
              <div className={Styles.list}>
                <div style={{ display: "flex" }}>
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={data?.url != "N/A" ? data?.url : defaultUser}
                      alt="student-profile"
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>{data.name}</p>
                    <p className={Styles.class}>
                      {data.academic.class}-{data.academic.section}
                    </p>
                    <p className={Styles.number}>+91 {data.phoneNumber}</p>
                  </div>
                </div>
                <div className={Styles.btns}>
                  <Button
                    variant="solid"
                    colorScheme="green"
                    mb={2}
                    onClick={() => {
                      restoreDeletedStudent.onOpen();
                      setStudentId(data._id);
                    }}
                  >
                    Restore
                  </Button>
                  <Button
                    variant="solid"
                    colorScheme="red"
                    onClick={() => {
                      permanentDeletedStudent.onOpen();
                      setStudentId(data._id);
                    }}
                    className={Styles.dltbtn}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {totalStudents > pageSize && (
        <div className={Styles.readMoreButton}>
          <Button onClick={handleReadMoreClick} colorScheme="blue">
            Read More
          </Button>
        </div>
      )}
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal
        isOpen={restoreDeletedStudent.isOpen}
        onClose={restoreDeletedStudent.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restore Deleted Student</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to restore the deleted student ?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              variant="solid"
              mr={3}
              onClick={() => handleStudentRestore({ id: studentIds })}
            >
              Restore
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                restoreDeletedStudent.onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={permanentDeletedStudent.isOpen}
        onClose={permanentDeletedStudent.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Student Permanently</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to delete student permanently ?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              variant="solid"
              mr={3}
              onClick={() => handleDeleteStudentPermanent({ id: studentIds })}
            >
              Delete
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                permanentDeletedStudent.onClose();
                setStudentId([]);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

const Staff = () => {
  const restoreDeletedTeacher = useDisclosure();
  const permanentdeletedTeacher = useDisclosure();
  const [deletedTeacher, setDeletedTeacher] = useState("");
  const [deltedtecaherId, setDeletedteacherrId] = useState("");
  const [searchTeacherInput, setSearchTeacherInput] = useState("");
  const [staffDepartment, setStaffDepartment] = useState("");
  const [inputFields, setInputFields] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [totalTeachers, setTotalTeachers] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useToast();
  useEffect(() => {
    setTotalTeachers(deletedTeacher.length);
  }, [deletedTeacher]);
  const getDeletedTeacherList = async () => {
    const payload = {
      listDeleteTeacher: true,
      ...(showSearchIcon && searchQuery !== "" ? { name: searchQuery } : {}),
      department: inputFields,
    };
    const response = await Services.renderInactiveTeacherList(payload);
    if (response) {
      setDeletedTeacher(response.result);
    }
  };
  useEffect(() => {
    getDeletedTeacherList();
  }, [searchTeacherInput, inputFields]);
  const handleTeacherRestore = async ({ id }) => {
    try {
      const payload = {
        teacher_id: id,
      };
      const response = await Services.restoreDeleteTeacher(payload);
      if (response) {
        getDeletedTeacherList();
        toast({
          title: "Message",
          description: "Restore Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        restoreDeletedTeacher.onClose();
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleSearchClick = () => {
    if (searchQuery !== "") {
      getDeletedTeacherList();
      setShowSearchIcon(false);
    }
  };

  const handleCloseIconClick = () => {
    setSearchQuery("");
    getDeletedTeacherList();
    setShowSearchIcon(true);
  };
  const handleTeacherPermanentDelete = async ({ id }) => {
    try {
      const payload = {
        teacher_id: id,
      };
      const res = await Services.permanentTeacherDelete(payload);
      if (res) {
        getDeletedTeacherList();
        permanentdeletedTeacher.onClose();
        toast({
          title: "Message",
          description: "Delete Successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (e) {
      toast({
        title: "",
        description: e.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      console.log(e);
    }
  };
  const handlecancelpermanentdelete = () => {
    permanentdeletedTeacher.onClose();
    restoreDeletedTeacher.onClose();
    setDeletedteacherrId([]);
  };
  const showDepartmentList = async () => {
    const payloadDepartment = {
      listDeleteTeacherDepartment: true,
    };
    const departmentResponse = await Services.restoreDepartmentList(
      payloadDepartment
    );
    if (departmentResponse) {
      setStaffDepartment(departmentResponse.result);
    }
  };

  useEffect(() => {
    showDepartmentList();
  }, []);
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleReadMoreClick = () => {
    setPageSize((prevPageSize) => prevPageSize + 10);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <p className={Styles.title}>Total Staff: {deletedTeacher.length}</p>
        <div className={Styles.teacherFilter}>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="staffName"
                  variant="filled"
                  placeholder="Search"
                  value={searchQuery}
                  autoComplete="off"
                  onKeyDown={handleEnterKeyPress}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    if (e.target.value.trim() !== "") {
                      setShowSearchIcon(true);
                    } else {
                      setShowSearchIcon(false);
                    }
                  }}
                  paddingRight="3rem"
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={inputFields}
              onChange={(e) => {
                setInputFields(e.target.value);
                setSearchQuery("");
                setShowSearchIcon(true);
              }}
              name="teacherDepart"
            >
              <option>All</option>
              {staffDepartment &&
                staffDepartment.map((data) => (
                  <option value={data.department}>{data.department}</option>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.lists}>
          {deletedTeacher.length === 0 ? (
            <div className={Styles.noResult}>No Result Found</div>
          ) : (
            deletedTeacher &&
            deletedTeacher.map((data) => (
              <div className={Styles.list}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={Styles.profile}>
                    <img
                      className={Styles.profileImg}
                      src={data?.url !== "N/A" ? data?.url : defaultUser}
                      alt="Profile"
                    />
                  </div>
                  <div className={Styles.info}>
                    <p className={Styles.name}>{data.name}</p>
                    <p className={Styles.number}>
                      <span style={{ fontWeight: "bold" }}>Emp ID. :</span>{" "}
                      {data?.emp_id || "--"}
                    </p>
                    <p className={Styles.number}>
                      <span style={{ fontWeight: "bold" }}>Department :</span>{" "}
                      {data?.department || "--"}
                    </p>
                  </div>
                </div>
                <div className={Styles.btns}>
                  <Button
                    colorScheme="green"
                    mb={2}
                    onClick={() => {
                      restoreDeletedTeacher.onOpen();
                      setDeletedteacherrId(data._id);
                    }}
                  >
                    Restore
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      permanentdeletedTeacher.onOpen();
                      setDeletedteacherrId(data._id);
                    }}
                    className={Styles.dltbtn}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {totalTeachers > pageSize && (
        <div className={Styles.readMoreButton}>
          <Button onClick={handleReadMoreClick} colorScheme="blue">
            Read More
          </Button>
        </div>
      )}
      <Modal
        isOpen={restoreDeletedTeacher.isOpen}
        onClose={restoreDeletedTeacher.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Restore Deleted Staff</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to restore the deleted staff ?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              mr={3}
              onClick={() => handleTeacherRestore({ id: deltedtecaherId })}
            >
              Restore
            </Button>
            <Button
              colorScheme="blue"
              onClick={() =>
                handlecancelpermanentdelete({ id: deltedtecaherId })
              }
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={permanentdeletedTeacher.isOpen}
        onClose={permanentdeletedTeacher.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Staff Permanently</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>Are you sure you want to delete the staff permanently ?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() =>
                handleTeacherPermanentDelete({
                  id: deltedtecaherId,
                })
              }
            >
              Delete
            </Button>
            <Button
              colorScheme="blue"
              onClick={() =>
                handlecancelpermanentdelete({ id: deltedtecaherId })
              }
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
    </div>
  );
};

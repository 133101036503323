import axios from "axios";
import { getRequestOptions, BASE_URL, isInvalid } from "services/utils";

export async function requestAdmitStudent(payload = {}) {
  const { email, name, father, role } = payload;
  const { father_name, father_phone } = father || {};

  if (role === "student") {
    if (isInvalid([email, name, father_phone, father_name, role])) {
      return { type: "validation", error: "Field missing" };
    }
  }

  const url = BASE_URL + "/admin/admission/add";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }

    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response && e.response?.data?.error) {
      throw e.response.data.error;
    }

    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestAdmissionDetailsById(payload = {}) {
  const { student_id } = payload;
  if (!student_id && student_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/admission/getOne";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestUpdateAdmissionDetailsById(payload = {}) {
  const { studentId, ...rest } = payload;
  if (!studentId && studentId === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/admission/update/" + studentId;

  const {
    personel,
    father,
    mother,
    academic,
    guardian,
    email,
    phoneNumber,
    name,
  } = rest;

  try {
    const response = await axios.post(
      url,
      {
        personel,
        father,
        mother,
        academic,
        guardian,
        email,
        phoneNumber,
        name,
      },
      getRequestOptions()
    );
    console.log("response", response);
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestDeleteAdmissionById(payload = {}) {
  const { student_id } = payload;
  if (!student_id && student_id === "") {
    return { error: "Field missing" };
  }

  const url = BASE_URL + "/admin/admission/remove";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data?.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }
    throw e;
  }
}
export async function requestSearchStudentByFilter(payload = {}) {
  const url = BASE_URL + "/admin/admission/serachWithFilter";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}
export async function requestAdmissionStudentDetails(payload = {}) {
  const url = BASE_URL + "/admin/admission/downloadWithFilter";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

export async function requestMigrateStudent(payload = {}) {
  const url = BASE_URL + "/admin/admission/migration";

  try {
    const response = await axios.post(url, { ...payload }, getRequestOptions());
    if (response.data.error) {
      return { error: response.data.error };
    }
    if (response.status >= 200 && response.status < 400 && response.data) {
      return response.data;
    }
  } catch (e) {
    if (e?.response?.data?.error) {
      throw e.response.data.error;
    }
    if (e?.response?.data) {
      throw e.response.data;
    }

    throw e;
  }
}

const Services = {
  requestAdmitStudent,
  requestUpdateAdmissionDetailsById,
  requestAdmissionDetailsById,
  requestDeleteAdmissionById,
  requestSearchStudentByFilter,
  requestAdmissionStudentDetails,
  requestMigrateStudent,
};

export default Services;

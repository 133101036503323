import React, { useState, useEffect } from "react";
import {
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Modal,
  ModalFooter,
  useToast,
  Button,
} from "@chakra-ui/react";
import Styles from "./Backup.module.scss";
import {
  requestAllAcademicYear,
  requestBackupAttendance,
  requestBackupFeePayment,
  requestBackupStaff,
  requestBackupStatistics,
  requestBackupStudents,
  requestCreateDoc,
  requestCreateExamDoc,
  requestGetBackupExamYear,
  requestGetBackupGrades,
  requestGetOneDoc,
  requestGetOneExamDoc,
} from "services/admin-dashboard/backup.service";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiUpArrowCircle, BiSearchAlt2 } from "react-icons/bi";
import Student from "assets/icons/student.png";
import Teacher from "assets/icons/teacher.png";
import Fees from "assets/icons/Fees.png";
import Statistics from "assets/icons/statistics.png";
import Attendance from "assets/icons/Attendance.png";
import { Icons } from "utilities/assets";
import { requestGetAcademicSession } from "services/admin-dashboard/calendar.service";
import defaultUser from "assets/icons/User.png";
const NewBackup = () => {
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = localStorage.getItem("selectedTab");
    return storedTab ? parseInt(storedTab, 10) : 0;
  });
  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab.toString());
  }, [selectedTab]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.body}>
        <Tabs
          isLazy
          isFitted
          variant="enclosed"
          index={selectedTab}
          onChange={(index) => setSelectedTab(index)}
        >
          <TabList mb="1em">
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>General</Tab>
            <Tab style={{ fontWeight: "bold", fontSize: "16px" }}>Exam</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <General />
            </TabPanel>
            <TabPanel>
              <Exam />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};
export default NewBackup;

const General = () => {
  const moduleIcons = {
    STUDENT: Icons.Student,
    STAFF: Icons.Teacher,
    FEEPAYMENT: Icons.Fees,
    STATISTICS: Icons.Statistics,
    ATTENDANCE: Icons.Attendance,
  };
  const modalBackupYearGeneral = useDisclosure();
  const backupModal = useDisclosure();
  const [year, setYear] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [years, setYears] = useState("");
  const [oneYearDoc, setOneYearDoc] = useState("");
  const [academicData, setAcademicData] = useState("");
  const [clickedItem, setClickedItem] = useState(null);
  const [signedXls, setSignedXls] = useState("");
  const toast = useToast();

  const createDoc = async () => {
    let createYear = {
      academic_year: year,
    };
    try {
      if (createYear.academic_year != "") {
        const response = await requestCreateDoc(createYear);
        toast({
          title: "Created Successfully",
          variant: "left-accent",
          position: "bottom-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        window.location.reload(true);
      }
    } catch (e) {
      toast({
        title: e.message,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
    modalBackupYearGeneral.onClose();
  };
  const getAcademicYears = async () => {
    const response = await requestAllAcademicYear();
    setAcademicYears(response);
  };
  useEffect(() => {
    getAcademicYears();
  }, []);
  const handleGetSession = async () => {
    const payload = {
      session: years,
    };
    const response = await requestGetAcademicSession(payload);
    setAcademicData(response);
  };
  const getOneDoc = async () => {
    let getoneYear = {
      academic_year: years,
    };
    const response = await requestGetOneDoc(getoneYear);
    setOneYearDoc(response);
    setSignedXls(response.backup_unit);
  };
  useEffect(() => {
    if (years != "") {
      getOneDoc();
      handleGetSession();
    }
  }, [years]);
  const handleItemClick = (item) => {
    setClickedItem(item);
    backupModal.onOpen();
  };
  const getBackup = async () => {
    let getoneYear = {
      academic_year: years,
    };
    try {
      if (getoneYear.academic_year !== "") {
        let res;
        switch (clickedItem?.unit_name) {
          case "STUDENT":
            res = await requestBackupStudents(getoneYear);
            break;
          case "STAFF":
            res = await requestBackupStaff(getoneYear);
            break;
          case "FEEPAYMENT":
            res = await requestBackupFeePayment(getoneYear);
            break;
          case "STATISTICS":
            res = await requestBackupStatistics(getoneYear);
            break;
          case "ATTENDANCE":
            res = await requestBackupAttendance(getoneYear);
            break;
          default:
            break;
        }
        toast({
          title: res.msg,
          variant: "left-accent",
          position: "bottom-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        getOneDoc();
        backupModal.onClose();
      }
    } catch (e) {
      toast({
        title: e,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const handleSession = () => {
    const startDate = new Date(academicData.data.specification[0].start_date);
    const endDate = new Date(academicData.data.specification[0].end_date);
    const currentDate = new Date();
    if (
      currentDate.getTime() === startDate.getTime() ||
      currentDate.getTime() === endDate.getTime() ||
      (currentDate > startDate && currentDate < endDate)
    ) {
      getBackup();
    } else {
      toast({
        title: "Backup failed",
        description: "Backup can only be taken during the academic session.",
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      backupModal.onClose();
    }
  };
  const createZipFolder = async () => {
    const zip = new JSZip();
    for (const item of oneYearDoc.backup_unit) {
      const fileName = `${item.unit_name}_backup.xlsx`;

      try {
        const response = await fetch(item.retrieve_url);
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        zip.file(fileName, arrayBuffer);
      } catch (error) {
        console.error(`Error fetching ${item.unit_name} backup:`, error);
      }
    }
    const content = await zip.generateAsync({ type: "blob" });
    const downloadUrl = URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = downloadUrl;
    const startYear = years;
    const nextYear = parseInt(startYear.slice(-2)) + 1;
    const yearsRange = `${startYear}-${nextYear.toString().padStart(2, "0")}`;
    const zipFilename = `General Backup ${yearsRange}.zip`;
    link.download = zipFilename;
    link.click();
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.header}>
        <div>
          <Button colorScheme="teal" onClick={modalBackupYearGeneral.onOpen}>
            Create Archive
          </Button>
        </div>
        <div className={Styles.filterWrapperGeneral}>
          <Select
            value={years}
            required
            onChange={(e) => setYears(e.target.value)}
            name="academicYears"
            placeholder="Academic Year"
          >
            {academicYears &&
              academicYears.map((year) => {
                const formattedYear = `${year}-${
                  parseInt(year.toString().slice(-2)) + 1
                }`;
                return <option value={year}>{formattedYear}</option>;
              })}
          </Select>
        </div>
        <Button
          colorScheme="green"
          onClick={createZipFolder}
          isDisabled={!years}
          style={{ cursor: years ? "pointer" : " not-allowed" }}
        >
          Archive
        </Button>
      </div>
      <div className={Styles.body1}>
        <div className={Styles.lists}>
          <div className={Styles.mainList}>
            {oneYearDoc.length === 0 ? (
              <p
                style={{
                  marginTop: "20px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Please select Academic Year
              </p>
            ) : (
              oneYearDoc &&
              oneYearDoc?.backup_unit.map((item) => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "10px 0",
                    alignItems: "center",
                  }}
                >
                  <div className={Styles.listing}>
                    <div className={Styles.backupFor}>
                      <img
                        src={
                          moduleIcons[item.unit_name]
                            ? moduleIcons[item.unit_name]
                            : defaultUser
                        }
                        alt={item.module_name}
                        className={Styles.moduleIcon}
                      />
                      <p>{item.unit_name}</p>
                    </div>
                    <div className={Styles.generalListing}>
                      <Button
                        disabled={years ? false : true}
                        onClick={() => handleItemClick(item)}
                        colorScheme="red"
                        mb={3}
                        style={{
                          cursor: years ? "pointer" : " not-allowed",
                        }}
                      >
                        Backup
                      </Button>
                      <a href={item.retrieve_url}>
                        <Button
                          disabled={
                            years && item.unit_status == true ? false : true
                          }
                          colorScheme="green"
                          style={{
                            cursor: years ? "pointer" : " not-allowed",
                          }}
                        >
                          Retrieve
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div className={Styles.backupDatesContainer}>
                    <p className={Styles.backupDates}>Last Backup Time:</p>
                    <p className={Styles.backupDates}>
                      {years && item.unit_status == true
                        ? moment(item.unit_time).format("LLLL")
                        : "N/A"}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalBackupYearGeneral.isOpen}
        onClose={modalBackupYearGeneral.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Select
              value={year}
              required
              onChange={(e) => setYear(e.target.value)}
              name="year"
              placeholder="Backup Year"
            >
              <option value="2022">2022-23</option>
              <option value="2023">2023-24</option>
              <option value="2024">2024-25</option>
              <option value="2025">2025-26</option>
              <option value="2026">2026-27</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#1e9b1e",
                color: "white",
                border: "1px solid #022a02",
                borderRadius: "5px",
                padding: " 5px",
                paddingLeft: "13px",
                paddingRight: "13px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <button onClick={() => createDoc()}>Submit</button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={backupModal.isOpen} onClose={backupModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            {clickedItem?.unit_name} BACKUP
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p style={{ fontWeight: "bold" }}>
                It's take some time are you sure backup for the{" "}
                {clickedItem?.unit_name}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                backgroundColor: "#dd1414",
                color: "white",
                border: "1px solid #022a02",
                borderRadius: "5px",
                padding: " 5px",
                paddingLeft: "13px",
                paddingRight: "13px",
                marginRight: "30px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <button onClick={() => handleSession()}>Ok</button>
            </div>
            <div
              style={{
                backgroundColor: "#1e9b1e",
                color: "white",
                border: "1px solid #022a02",
                borderRadius: "5px",
                padding: " 5px",
                paddingLeft: "13px",
                paddingRight: "13px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <button onClick={backupModal.onClose}>Cancel</button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
const Exam = () => {
  const modalBackupYearExam = useDisclosure();
  const [year, setYear] = useState("");
  const [years, setYears] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [grade, setGrade] = useState(null);
  const [section, setSection] = useState(null);
  const [classAndGradeList, setClassAndGradeList] = useState([]);
  const [backupGrade, setBackupGrade] = useState(null);
  const [backupSection, setBackupSection] = useState(null);
  const [classAndGrade, setClassAndGrade] = useState([]);
  const [studentName, setStudentName] = useState("");
  const [examDoc, setExamDoc] = useState([]);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showFilteredList, setShowFilteredList] = useState(false);
  const [filteredStudentList, setFilteredStudentList] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const toast = useToast();
  const createDoc = async () => {
    let createYear = {
      academic_year: year,
      class: grade,
      section: section,
    };
    try {
      if (
        createYear.academic_year != "" &&
        createYear.class != "" &&
        createYear.section != ""
      ) {
        const response = await requestCreateExamDoc(createYear);
        toast({
          title: "Created Successfully",
          variant: "left-accent",
          position: "top-right",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        window.location.reload(true);
      }
    } catch (e) {
      toast({
        title: e,
        variant: "left-accent",
        position: "bottom-right",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
    modalBackupYearExam.onClose();
  };
  const getExamAcademicYears = async () => {
    const response = await requestGetBackupExamYear();
    setAcademicYears(response);
  };
  const getBackupGrades = async () => {
    const payload = {
      academic_year: years,
    };
    const res = await requestGetBackupGrades(payload);
    setClassAndGrade(res);
  };
  useEffect(() => {
    getBackupGrades();
  }, [years]);
  useEffect(() => {
    getExamAcademicYears();
  }, []);
  const getOneDoc = async () => {
    let data = {
      academic_year: years,
      class: backupGrade,
      section: backupSection,
    };
    try {
      const response = await requestGetOneExamDoc(data);
      setExamDoc(response.student_list);
    } catch (e) {
      console.log("error", e);
    }
  };
  const length = examDoc.length;
  const sortedStudents = examDoc.sort((a, b) =>
    a.student_name.localeCompare(b.student_name)
  );
  useEffect(() => {
    if (years && backupGrade && backupSection) {
      getOneDoc();
    }
  }, [years, backupGrade, backupSection]);
  const handleSinglePdfGenerate = async (url, name) => {
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileName = name + ".pdf";
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  const handlePdfGeneration = async (url, name, zip) => {
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileName = name + ".pdf";
      zip.file(fileName, blob);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownloadAllClick = async () => {
    const zip = new JSZip();

    try {
      await Promise.all(
        sortedStudents
          .filter((element) => element.report_status)
          .map((element) =>
            handlePdfGeneration(element.report_url, element.student_name, zip)
          )
      );
    } catch (error) {
      console.error("Error generating PDFs:", error);
    }

    try {
      const startYear = years;
      const nextYear = parseInt(startYear.slice(-2)) + 1;
      const yearsRange = `${startYear}-${nextYear.toString().padStart(2, "0")}`;
      const zipFilename = `Exam Report Backup${backupGrade}-${backupSection} ${yearsRange}.zip`;
      const content = await zip.generateAsync({ type: "blob" }); // Generate the zip folder
      saveAs(content, zipFilename);
    } catch (error) {
      console.error("Error generating zip folder:", error);
    }
  };
  const config = {
    headers: {
      Authorization: `bearer ${window.localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/general/getclassAndsection`,
        {},
        config
      )
      .then((response) => {
        setClassAndGradeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (localStorage.getItem("report")) {
      setGrade(JSON.parse(localStorage.getItem("report"))[0].class);
      setSection(JSON.parse(localStorage.getItem("report"))[0].section);
    }
  }, []);
  const numericClasses = classAndGradeList.filter((item) =>
    /^\d+$/.test(item.class)
  );
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const openReportInNewTab = (url) => {
    window.open(url, "_blank");
  };
  useEffect(() => {
    const filteredList = examDoc.filter((student) =>
      student.student_name.toLowerCase().includes(studentName.toLowerCase())
    );
    setFilteredStudentList(filteredList);
  }, [studentName, examDoc]);

  const handleSearchClick = () => {
    if (studentName !== "") {
      setShowFilteredList(true);
      setShowSearchIcon(false);
    } else {
      setShowFilteredList(false);
    }
  };
  const handleCloseIconClick = () => {
    setShowSearchIcon(true);
    setShowFilteredList(false);
    setStudentName("");
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.examHeader}>
        <div style={{ fontWeight: "bold" }}>Total Students:&nbsp;{length}</div>
        <div className={Styles.header}>
          <Button colorScheme="teal" onClick={modalBackupYearExam.onOpen}>
            Create Archive
          </Button>
          <div className={Styles.filterWrapper}>
            <Select
              value={years}
              required
              onChange={(e) => {
                setYears(e.target.value);
                setStudentName("");
              }}
              name="academicYears"
              placeholder="Academic Year"
            >
              {academicYears &&
                academicYears.map((year) => {
                  const nextYear = parseInt(year) + 1;
                  const formattedYear = `${year}-${nextYear
                    .toString()
                    .slice(-2)}`;
                  return <option value={year}>{formattedYear}</option>;
                })}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative" }}>
                <Input
                  name="studentName"
                  variant="filled"
                  placeholder="Search"
                  value={studentName}
                  onKeyDown={handleEnterKeyPress}
                  onChange={(e) => setStudentName(e.target.value)}
                />
                {showSearchIcon ? (
                  <BiSearchAlt2
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                    fontSize={20}
                    onClick={handleSearchClick}
                  />
                ) : (
                  <AiOutlineCloseCircle
                    onClick={handleCloseIconClick}
                    fontSize={20}
                    style={{
                      position: "absolute",
                      right: "1rem",
                      top: "30%",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={backupGrade}
              onChange={(e) => {
                setBackupGrade(e.target.value);
                setStudentName("");
              }}
              name="class"
              placeholder="Select Grade"
            >
              {classAndGrade.map((item) => (
                <option value={item.class}>Grade{item.class}</option>
              ))}
            </Select>
          </div>
          <div className={Styles.filterWrapper}>
            <Select
              value={backupSection}
              onChange={(e) => {
                setBackupSection(e.target.value);
                setStudentName("");
              }}
              name="section"
              placeholder="Select Section"
            >
              {classAndGrade.filter((i) => i.class === backupGrade).length
                ? classAndGrade
                    .filter((i) => i.class === backupGrade)[0]
                    .sections.map((item) => (
                      <option value={item}>Section {item}</option>
                    ))
                : ""}
            </Select>
          </div>
          <Button
            colorScheme="green"
            onClick={handleDownloadAllClick}
            isDisabled={!years || !backupGrade || !backupSection}
            style={{
              cursor:
                years && backupGrade && backupSection
                  ? "pointer"
                  : " not-allowed",
            }}
          >
            Archive
          </Button>
        </div>
      </div>
      {(showFilteredList ? filteredStudentList : examDoc).length == 0 &&
      years &&
      backupGrade &&
      backupSection ? (
        <div className={Styles.noResult}>No Result Found</div>
      ) : (
        <div className={Styles.body1}>
          {(showFilteredList ? filteredStudentList : examDoc).map((student) => {
            return (
              <div className={Styles.listingExam}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p className={Styles.students}>{student.student_name}</p>
                  <p className={Styles.students}>
                    Last Backup Time:{" "}
                    {student.report_time
                      ? moment(student.report_time).format("LLLL")
                      : "N/A"}
                  </p>
                </div>
                <div className={Styles.examList}>
                  {student.report_status == true ? (
                    <a href={student.report_url} target="_blank">
                      <Button
                        colorScheme="blue"
                        mb={3}
                        className={Styles.previewBtn}
                        onClick={() => openReportInNewTab(student.report_url)}
                      >
                        Preview
                      </Button>
                    </a>
                  ) : null}

                  {student.report_status == true ? (
                    <Button
                      colorScheme="green"
                      onClick={() =>
                        handleSinglePdfGenerate(
                          student.report_url,
                          student.student_name
                        )
                      }
                    >
                      Download
                    </Button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {showTopBtn && (
        <div
          onClick={scrollToTop}
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#990f0d",
            borderRadius: "20px",
            display: " flex",
            alignItems: "flex-end",
          }}
        >
          <BiUpArrowCircle style={{ color: "white", fontSize: "40px" }} />
        </div>
      )}
      <Modal
        isOpen={modalBackupYearExam.isOpen}
        onClose={modalBackupYearExam.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.filterWrapper} style={{ marginTop: "10px" }}>
              <Select
                value={grade}
                onChange={(e) => {
                  setGrade(e.target.value);
                }}
                name="class"
                placeholder="Select Grade"
              >
                {numericClasses.map((item) => (
                  <option value={item.class}>Grade {item.class}</option>
                ))}
              </Select>
            </div>

            <div className={Styles.filterWrapper} style={{ marginTop: "10px" }}>
              <Select
                value={section}
                onChange={(e) => {
                  setSection(e.target.value);
                }}
                name="section"
                placeholder="Select Section"
              >
                {classAndGradeList.filter((i) => i.class === grade).length
                  ? classAndGradeList
                      .filter((i) => i.class === grade)[0]
                      .sections.map((item) => (
                        <option value={item}>Section {item}</option>
                      ))
                  : ""}
              </Select>
            </div>
            <div className={Styles.filterWrapper} style={{ marginTop: "10px" }}>
              <Select
                value={year}
                required
                onChange={(e) => setYear(e.target.value)}
                name="year"
                placeholder="Backup Year"
              >
                <option value="2022">2022-23</option>
                <option value="2023">2023-24</option>
                <option value="2024">2024-25</option>
                <option value="2025">2025-26</option>
                <option value="2026">2026-27</option>
              </Select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#1e9b1e",
                color: "white",
                border: "1px solid #022a02",
                borderRadius: "5px",
                padding: " 5px",
                paddingLeft: "13px",
                paddingRight: "13px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <button onClick={() => createDoc()}>Submit</button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

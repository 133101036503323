import React, { useEffect, useState } from "react";
import { requestCanteenData } from "services/student-dashboard/canteen.service";
import Styles from "./Canteen.module.scss";
import { Spinner } from "@chakra-ui/react";
import caution from "../../../assets/icons/caution.png";
const StudentCanteen = () => {
  const [canteenDetails, setCanteenDetails] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(true);
  const [notOpted, setNotOpted] = useState(false);
  const requestPageDetails = async () => {
    try {
      setRequestInProgress(true);
      const response = await requestCanteenData();
      setCanteenDetails(response);
      if (response.error === "Canteen Not opted for student") {
        setNotOpted(true);
      }
      setRequestInProgress(false);
    } catch (e) {
      setRequestInProgress(false);
      setNotOpted(true);
      console.error(e);
    }
  };
  useEffect(() => {
    requestPageDetails();
  }, []);

  return (
    <div>
      <div className={Styles.statsWrapper}>
        <p className={Styles.stats}>CANTEEN</p>
      </div>
      <div className={Styles.body}>
        {requestInProgress ? (
          <div className={Styles.loaderWrapper}>
            <Spinner />
          </div>
        ) : (
          <>
            {notOpted ? (
              <div className={Styles.wrapperCenter}>
                <img
                  className={Styles.cautionImg}
                  src={caution}
                  alt="caution"
                />
                <p className={Styles.cautionText}>
                  Student Not Opted for Canteen
                </p>
              </div>
            ) : (
              <div className={Styles.subWrapper}>
                <div className={Styles.lists}>
                  <div className={Styles.listSelected}>
                    <div className={Styles.listItem}>
                      <p className={Styles.listItemText}>Amount (in INR)</p>
                      <p className={Styles.listItemVal}>
                      ₹  {canteenDetails.amount}
                      </p>
                    </div>

                    <div className={Styles.listItem}>
                      <p className={Styles.listItemText}>
                        Duration (in Months)
                      </p>
                      <p className={Styles.listItemVal}>
                        {canteenDetails.duration}
                      </p>
                    </div>
                    <div className={Styles.listItem}>
                      <p className={Styles.listItemText}>Fee Status</p>
                      <p className={Styles.listItemVal}>
                        {canteenDetails.paid ? "Paid" : "Unpaid"}
                      </p>
                    </div>
                    <div className={Styles.listItem}>
                      <p className={Styles.listItemText}>Trial Period</p>
                      <p className={Styles.listItemVal}>
                        {canteenDetails.is_trial ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default StudentCanteen;

import {
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Spinner,
    useDisclosure,
    Button,
    ModalFooter,
  } from "@chakra-ui/react";
  import React, { useState, useEffect } from "react";
  import { GrClear } from "react-icons/gr";
  import * as FileSaver from "file-saver";
  import * as XLSX from "xlsx";
  import { Icons } from "utilities/assets";
  import {
    requestAllRoutes,
    requestAllBuses,
    requestAddBus,
    requestUpdateBusById,
    requestAddRoute,
    requestSearchEnrolledStudents,
    requestDeleteBusById,
    requestDeleteRouteById,
    requestUpdateRouteById,
    requestRouteById,
  } from "services/admin-dashboard/transport.service";
  import { useClassAndSections } from "hooks/classAndSection";
  import { Link } from "react-router-dom";
  import Styles from "./BusDetails.module.scss";
  import { debounce } from "utilities/utils";
  
  const PARTICULAR_INPUT_FIELDS = {
    stopNumber: 1,
    morningTiming: "",
    eveningTiming: "",
    termOneFee: "",
    termTwoFee: "",
  };
  
  const ADD_BUS_INPUT_FIELD_INITIAL_STATE = {
    route: "",
    driverPhone: "",
    driverName: "",
    busNumber: "",
    busPlateNumber: "",
  };
  
  const BUS_MODAL_CONTENT = {
    ADD: {
      head: "Add Bus",
      cta: "Add Bus",
    },
    EDIT: {
      head: "Edit Bus",
      cta: "Edit Bus",
    },
  };
  
  const SEARCH_FILTER_INPUT_FIELD_INITIAL_STATE = {
    route: "",
    bus: "",
    class: "LKG",
    section: "A",
    studentName: "",
  };
  
  const InputSelect = ({
    labelKey = "label",
    valueKey = "value",
    isCustomLabel = false,
    labelChild = null,
    options,
    ...props
  }) => {
    return (
      <Select {...props}>
        {options.map((v, idx) => (
          <option key={v[valueKey] + idx} value={v[valueKey]}>
            {isCustomLabel ? labelChild(v) : v[labelKey]}
          </option>
        ))}
      </Select>
    );
  };

const BusDetails = () => {
  return (
    <div className={Styles.wrapper}>
    <Tabs isLazy isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab>Buses</Tab>
        <Tab>Routes</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <AddBus />
        </TabPanel>
        <TabPanel>
          <AddRoute />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
  )
}

export default BusDetails

const AddBus = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [allBuses, setBuses] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [operationType, setOperationType] = useState("");
    const [inputFields, setInputFields] = useState(
      ADD_BUS_INPUT_FIELD_INITIAL_STATE
    );
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [busDetailsDownload, setBusDetailsDownload] = useState("");
    const busDownload = useDisclosure();
    useEffect(() => {
      requestPageDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const requestPageDetails = () => {
      setRequestInProgress(true);
      Promise.all([fetchAllBuses(), fetchAllRoutes()])
        .then((resp) => {
          setRequestInProgress(false);
        })
        .catch((err) => {
          setRequestInProgress(false);
        });
    };
  
    const fetchAllRoutes = async () => {
      try {
        const response = await requestAllRoutes();
        if (response.error) {
          console.error(response.error);
        }
        if (response && Array.isArray(response)) {
          setRoutes(response);
        }
      } catch (e) {
        console.error(e);
      }
    };
  
    const fetchAllBuses = async () => {
      setRequestInProgress(true);
      try {
        const response = await requestAllBuses();
        if (response.error) {
          console.error(response.error);
        }
        if (response && Array.isArray(response)) {
          setBuses(response);
          let newArray1 = [];
          response &&
            response.map((data) => {
              let obj1 = {
                "Driver Name": data.driver_name,
                "Bus Number": data.bus_number,
                "Driver Number": data.driver_phone,
                "Bus Number Plate": data.bus_plate_number,
                "Starting Loaction": data.from,
                "Ending Location": data.to,
              };
              newArray1.push(obj1);
              setBusDetailsDownload(newArray1);
            });
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
      }
    };
  
    const resetInputFields = () => {
      setInputFields(ADD_BUS_INPUT_FIELD_INITIAL_STATE);
    };
  
    const updateBusOperations = async () => {
      try {
        switch (operationType) {
          case "ADD": {
            const route = routes.find((i) => i._id === inputFields.route) || {};
            const { source, destination, route_number } = route;
            const payload = {
              ...inputFields,
              source,
              destination,
              routeNumber: route_number,
            };
            const resp = await requestAddBus(payload);
            if (resp) {
              setOperationType("");
              resetInputFields();
              onClose();
              setRequestInProgress(false);
              fetchAllBuses();
            }
            break;
          }
          case "EDIT": {
            const resp = await requestUpdateBusById({ ...inputFields });
            if (resp) {
              setOperationType("");
              resetInputFields();
              onClose();
              setRequestInProgress(false);
              fetchAllBuses();
            }
            break;
          }
          default: {
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
  
    const handleInputChange = ({
      target: { type, value, name, valueAsNumber },
    }) => {
      const _values = {
        number: valueAsNumber,
        text: value,
      };
  
      setInputFields((prev) => ({
        ...prev,
        [name]: _values[type] || value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      updateBusOperations();
    };
  
    const handleAddBusClick = () => {
      setOperationType("ADD");
      onOpen();
    };
  
    const handleEditBus = (busDetails) => {
      const _busDetails = {
        driverName: busDetails.driver_name,
        driverPhone: busDetails.driver_phone,
        busPlateNumber: busDetails.bus_plate_number,
        busId: busDetails["_id"],
      };
  
      setOperationType("EDIT");
      setInputFields((prev) => ({
        ...prev,
        ..._busDetails,
      }));
      onOpen();
    };
  
    const editBus = (bus) => {
      handleEditBus(bus);
    };
  
    const deleteBus = async (bus) => {
      setRequestInProgress(true);
      try {
        const response = await requestDeleteBusById({ busId: bus["_id"] });
        if (response) {
          setRequestInProgress(false);
          fetchAllBuses();
        }
      } catch (e) {
        console.error(e);
        setRequestInProgress(false);
      }
    };
  
    const fileName = "Bus Details";
  
    // inputFields.class && inputFields.section
    //   ? `smu-student-class-${inputFields.class}-section-${inputFields.section}`
    //   : "smu-students";
    const ExportToExcel = ({ apiData, fileName }) => {
      // console.log(" apiData", apiData)
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
  
      const exportToCSV = (apiData, fileName) => {
        // console.log("apidataaaaaaaa", apiData);
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };
  
      return (
        // <button onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
        <div className={Styles.downloadButton}>
          <Button onClick={(e) => exportToCSV(apiData, fileName)}>Yes</Button>
        </div>
      );
    };
  
    return (
      <div className={Styles.subWrapper}>
        <div className={Styles.header}>
          <p className={Styles.title}>All Buses</p>
          <div style={{ display: "flex" }}>
            <div className={Styles.add}>
              <img
                className={Styles.addImage}
                onClick={handleAddBusClick}
                src={Icons.add}
                alt="add"
              />
            </div>
            <button
              className={Styles.downloadBtn}
              onClick={() => busDownload.onOpen()}
            >
              Download
            </button>
          </div>
        </div>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : allBuses.length === 0 ? (
            <div style={{ color: "tomato" }}>No bus found</div>
          ) : (
            allBuses.map((bus, index) => (
              <div key={bus["_id"] + index} className={Styles.list}>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Bus Number</p>
                  <p className={Styles.listItemVal}>{bus.bus_number}</p>
                </div>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Route</p>
                  <p className={Styles.listItemVal}>
                    {bus.from} - {bus.to}
                  </p>
                </div>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Number Plate</p>
                  <p className={Styles.listItemVal}>{bus.bus_plate_number}</p>
                </div>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Driver Name</p>
                  <p className={Styles.listItemVal}>{bus.driver_name}</p>
                </div>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Driver Phone</p>
                  <p className={Styles.listItemVal}>{bus.driver_phone}</p>
                </div>
                <div className={Styles.edit}>
                  <img
                    className={Styles.editImage}
                    onClick={() => editBus(bus)}
                    src={Icons.edit}
                    alt="edit"
                  />
                  <img
                    className={Styles.editImage}
                    onClick={() => deleteBus(bus)}
                    src={Icons.deleteIcon}
                    alt="delete"
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            resetInputFields();
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {BUS_MODAL_CONTENT[operationType || "ADD"]?.head || ""}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <div className={Styles.formGroup}>
                  {operationType !== "EDIT" && (
                    <>
                      <label className={Styles.formLabel}>Bus Number</label>
                      <input
                        className={Styles.formInput}
                        type="number"
                        name="busNumber"
                        value={inputFields.busNumber}
                        onChange={handleInputChange}
                        placeholder="Enter Bus Number"
                      />
                      <label className={Styles.formLabel}>Route</label>
                      <InputSelect
                        onChange={handleInputChange}
                        name="route"
                        value={inputFields.route}
                        placeholder="Select Route"
                        options={routes}
                        isCustomLabel
                        labelChild={(prop) => (
                          <>
                            {prop.route} - {prop.route_number}
                          </>
                        )}
                        valueKey="_id"
                      />
                    </>
                  )}
                  <label className={Styles.formLabel}>Number Plate</label>
                  <input
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Number Plate"
                    name="busPlateNumber"
                    value={inputFields.busPlateNumber}
                    onChange={handleInputChange}
                  />
                  <label className={Styles.formLabel}>Driver Name</label>
                  <input
                    className={Styles.formInput}
                    type="text"
                    placeholder="Enter Driver Name"
                    name="driverName"
                    value={inputFields.driverName}
                    onChange={handleInputChange}
                  />
                  <label className={Styles.formLabel}>Driver Phone</label>
                  <input
                    className={Styles.formInput}
                    type="number"
                    placeholder="Enter Driver Phone"
                    name="driverPhone"
                    value={inputFields.driverPhone}
                    onChange={handleInputChange}
                  />
                  <button type="submit" className={Styles.formButton}>
                    {requestInProgress && operationType !== "" ? (
                      <Spinner />
                    ) : (
                      BUS_MODAL_CONTENT[operationType || "ADD"]?.cta || ""
                    )}
                  </button>
                </div>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal isOpen={busDownload.isOpen} onClose={busDownload.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> Download </ModalHeader>
            <ModalCloseButton />
            <ModalBody>Would you like to download bus details</ModalBody>
            <ModalFooter>
              <ExportToExcel apiData={busDetailsDownload} fileName={fileName} />
              <Button
                style={{
                  backgroundColor: "#990f0d",
                  color: "white",
                  marginLeft: "1vw",
                }}
                onClick={() => busDownload.onClose()}
              >
                No
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  };
  
  const AddRoute = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEditOpen, onEditOpen] = useState(false);
    const [operationType, setOperationType] = useState("");
    const [inputFields, setInputFields] = useState({
      routeName: "",
      routeNumber: "",
    });
    const [particulars, setParticulars] = useState([PARTICULAR_INPUT_FIELDS]);
    const [routeId, setrouteId] = useState();
    const [allRoutes, setRoutes] = useState([]);
    const [requestInProgress, setRequestInProgress] = useState(false);
  
    useEffect(() => {
      fetchAllRoutes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const fetchAllRoutes = async () => {
      setRequestInProgress(true);
      try {
        const response = await requestAllRoutes();
        if (response.error) {
          console.error(response.error);
        }
        if (response && Array.isArray(response)) {
          setRoutes(response);
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
      }
    };
  
    const handleParticularsInputChange = (
      { target: { name, value, valueAsNumber, type } },
      i
    ) => {
      let _particulars = [...particulars];
      const _value = { text: value, number: valueAsNumber, time: value }[type];
      _particulars[i][name] = _value;
      setParticulars([..._particulars]);
    };
  
    const handleInputFieldsChange = ({
      target: { value, name, valueAsNumber, valueAsDate, type },
    }) => {
      const _value = { text: value, number: valueAsNumber, time: valueAsDate }[
        type
      ];
      setInputFields((prev) => ({ ...prev, [name]: _value }));
    };
  
    const addParticular = () => {
      let _particular = [...particulars];
      _particular.push({
        PARTICULAR_INPUT_FIELDS,
        stopNumber: particulars.length + 1,
      });
      setParticulars([..._particular]);
    };
  
    const resetInputFields = () => {
      setInputFields({ routeName: "", routeNumber: "" });
      setParticulars([PARTICULAR_INPUT_FIELDS]);
    };
  
    const removeParticular = (i) => {
      let _particulars = [...particulars];
      _particulars.splice(i, 1);
      if (_particulars.length > 0) {
        setParticulars(_particulars);
      }
    };
  
    const handleAdd = () => {
      setOperationType("ADD");
      onOpen();
    };
  
    const fetchRouteDetails = async (id) => {
      setRequestInProgress(true);
      try {
        const response = await requestRouteById({ routeId: id });
        if (response.error) {
          console.error(response.error);
        }
        if (response) {
          console.log("route:", response);
  
          const otherFields = {
            routeName: response.route,
            routeNumber: response.route_number,
            source: response.source,
            destination: response.destination,
            ...response,
          };
          const particulars = response.particulars.map(
            ({ stop, location, term1, term2, timing, ...rest }) => ({
              ...rest,
              stopNumber: stop,
              termOneFee: term1,
              termTwoFee: term2,
              morningTiming: timing.morning,
              eveningTiming: timing.evening,
              location: location,
            })
          );
          setInputFields(otherFields);
          setParticulars(particulars);
        }
        setRequestInProgress(false);
      } catch (e) {
        setRequestInProgress(false);
        console.error(e);
      }
    };
  
    const handleEdit = (routeId) => {
      setOperationType("EDIT");
      setrouteId(routeId);
      fetchRouteDetails(routeId);
      onOpen();
    };
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (particulars.length < 2) {
        return;
      }
  
      const { 0: source, length, [length - 1]: destination } = particulars;
      const payload = {
        route: inputFields.routeName,
        route_number: inputFields.routeNumber,
        source: source.location,
        destination: destination.location,
        particulars: particulars.map(
          ({
            morningTiming,
            eveningTiming,
            termOneFee,
            termTwoFee,
            stopNumber,
            location,
            ...i
          }) => ({
            ...i,
            stop: stopNumber,
            term1: termOneFee,
            term2: termTwoFee,
            location: location,
            timing: {
              morning: morningTiming,
              evening: eveningTiming,
            },
          })
        ),
      };
  
      setRequestInProgress(true);
      switch (operationType) {
        case "ADD":
          try {
            const res = await requestAddRoute(payload);
            if (res) {
              setOperationType("");
              resetInputFields();
              onClose();
              setRequestInProgress(false);
              fetchAllRoutes();
            }
          } catch (e) {
            console.error(e);
            setRequestInProgress(false);
          }
          break;
        case "EDIT":
          try {
            const _payload = { ...payload, routeId };
            const res = await requestUpdateRouteById(_payload);
            if (res) {
              setOperationType("");
              resetInputFields();
              onClose();
              setRequestInProgress(false);
              fetchAllRoutes();
            }
          } catch (e) {
            console.error(e);
            setRequestInProgress(false);
          }
          break;
        default:
          break;
      }
    };
  
    const deleteRoute = async (route) => {
      setRequestInProgress(true);
      try {
        const response = await requestDeleteRouteById({ routeId: route["_id"] });
        if (response) {
          setRequestInProgress(false);
          fetchAllRoutes();
        }
      } catch (e) {
        console.error(e);
        setRequestInProgress(false);
      }
    };
  
    const handleCloseModal = () => {
      resetInputFields();
      setrouteId(null);
      onClose();
    };
  
    return (
      <div className={Styles.subWrapper}>
        <div className={Styles.header}>
          <p className={Styles.title}>All Routes</p>
          <div className={Styles.add} onClick={handleAdd}>
            <img className={Styles.addImage} src={Icons.add} alt="add" />
          </div>
        </div>
        <div className={Styles.lists}>
          {requestInProgress ? (
            <div className={Styles.loaderWrapper}>
              <Spinner />
            </div>
          ) : allRoutes.length === 0 ? (
            <div style={{ color: "tomato" }}>No routes found</div>
          ) : (
            allRoutes.map((route, idx) => (
              <div key={route["_id"] + idx} className={Styles.list}>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Route Number</p>
                  <p className={Styles.listItemVal}>
                    {`${route.route_number}` || "--"}
                  </p>
                </div>
                <div className={Styles.listItem}>
                  <p className={Styles.listItemText}>Route Name</p>
                  <p className={Styles.listItemVal}>{route.route || "--"}</p>
                </div>
                <div className={Styles.directionRow}>
                  <div className={Styles.directionCol}>
                    <p className={Styles.listItemText}>{route.source || "--"}</p>
                  </div>
                  <img
                    className={Styles.direction}
                    src={Icons.direction}
                    alt="direction"
                  />
                  <div className={Styles.directionCol}>
                    <p className={Styles.listItemText}>
                      {route.destination || "--"}
                    </p>
                  </div>
                </div>
                <div className={Styles.edit}>
                  <img
                    className={Styles.editImage}
                    onClick={() => handleEdit(route._id)}
                    src={Icons.edit}
                    alt="edit"
                  />
                  <img
                    className={Styles.editImage}
                    onClick={() => deleteRoute(route._id)}
                    src={Icons.deleteIcon}
                    alt="delete"
                  />
                </div>
              </div>
            ))
          )}
        </div>
  
        <Modal size={"full"} isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{operationType ? operationType : ""} Route</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className={Styles.note}>
                <p>
                  <b>Note:</b> NA/NYD - Transport Fee for this term is Not
                  Applicable / Not Yet Decided
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className={Styles.formGroup}>
                  <label className={Styles.formLabel}>Route Name</label>
                  <input
                    onChange={handleInputFieldsChange}
                    className={Styles.formInput}
                    type="text"
                    name="routeName"
                    value={inputFields.routeName}
                    placeholder="Enter Route Name"
                  />
                  <label className={Styles.formLabel}>Route Number</label>
                  <input
                    onChange={handleInputFieldsChange}
                    className={Styles.formInput}
                    type="text"
                    name="routeNumber"
                    value={inputFields.routeNumber}
                    placeholder="Enter Route Number"
                  />
                  {particulars.map((item, i) => (
                    <div key={i} className={Styles.formRow}>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Stop Number</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.stopNumber}
                          name="stopNumber"
                          className={Styles.formInput}
                          type="number"
                          placeholder="Enter Stop Number"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Location</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.location}
                          name="location"
                          className={Styles.formInput}
                          type="text"
                          placeholder="Enter Location"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Morning timing</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.morningTiming}
                          className={Styles.formInput}
                          name="morningTiming"
                          type="time"
                          placeholder="Enter Morning timing"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Evening timing</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.eveningTiming}
                          className={Styles.formInput}
                          name="eveningTiming"
                          type="time"
                          placeholder="Enter Evening timing"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Term 1 Fee</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.termOneFee}
                          className={Styles.formInput}
                          name="termOneFee"
                          type="number"
                          placeholder="Enter Term One Fee"
                        />
                      </div>
                      <div className={Styles.formCol}>
                        <label className={Styles.formLabel}>Term 2 Fee</label>
                        <input
                          onChange={(e) => handleParticularsInputChange(e, i)}
                          value={item.termTwoFee}
                          name="termTwoFee"
                          type="number"
                          className={Styles.formInput}
                          placeholder="Enter Term Two Fee"
                        />
                      </div>
                      <div
                        onClick={() => removeParticular(i)}
                        className={Styles.delete}
                      >
                        <img
                          className={Styles.deleteImage}
                          src={Icons.deleteIcon}
                          alt="delete"
                        />
                      </div>
                    </div>
                  ))}
  
                  <div className={Styles.buttonRow}>
                    <button
                      type="button"
                      onClick={addParticular}
                      className={Styles.formButton}
                    >
                      Add
                    </button>
                    <button type="submit" className={Styles.formButton}>
                      {requestInProgress && operationType !== "" ? (
                        <Spinner />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    );
  };

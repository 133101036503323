import React, { useState, useEffect } from "react";
import Styles from "./Help.module.scss";
import { Icons } from "utilities/assets";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  requestCreateModule,
  requestGetAddedModule,
  requestDeleteModule,
  requestGetListModule,
} from "services/admin-dashboard/help.service";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import defaultUser from "assets/icons/User.png";
const Help = () => {
  const moduleIcons = {
    "ACADEMIC CALENDAR": Icons.AcademicCalendar,
    "ADMIN/ MANAGEMENT": Icons.Admin,
    ADMISSION: Icons.Admission,
    ASSIGNMENTS: Icons.Assignment,
    ATTENDANCE: Icons.Attendance,
    BACKUP: Icons.Backup,
    BRANDING: Icons.Branding,
    "BUS TRANSPORT": Icons.SchoolBus,
    CANTEEN: Icons.Canteen,
    CERTIFICATE: Icons.Certificate,
    CIRCULAR: Icons.Circular,
    CLASSROOM: Icons.Classroom,
    EVENTS: Icons.Events,
    "EXAM REPORT": Icons.Exams,
    HELP: Icons.Help,
    "KNOWLEDGE CENTRE": Icons.Knowledge_Centre,
    LIBRARY: Icons.Library,
    LOGOUT: Icons.Logout,
    NOTIFICATION: Icons.Notification,
    PERMISSION: Icons.Permissions,
    PROFILE: [Icons.Student, Icons.Teacher, Icons.Admin, Icons.Management],
    "RECYCLE BIN": Icons.RecycleBin,
    "REPORT CENTRE": Icons.Report_Centre,
    "SCHOOL FEES": Icons.Fees,
    SMS: Icons.SMS,
    STUDENTS: Icons.Student,
    STAFF: Icons.Teacher,
    STATISTICS: Icons.Statistics,
    TIMETABLE: Icons.TimeTable,
    TOOLS: Icons.Tools,
  };
  const toast = useToast();
  const addModule = useDisclosure();
  const deleteModule = useDisclosure();
  const [moduleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [addedModule, setAddedModule] = useState("");
  const [moduleToDelete, setModuleToDelete] = useState("");
  const [selectedModuleDetail, setSelectedModuleDetail] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };
  const handleListModule = async () => {
    try {
      const payload = {};
      const response = await requestGetListModule(payload);
      setModuleList(response);
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
    }
  };
  const handleAddModule = async () => {
    try {
      const payload = { module_name: selectedModule };
      const response = await requestCreateModule(payload);
      toast({
        title: response.msg,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      addModule.onClose();
      handleGetAddedModule();
      setSelectedModule("");
    } catch (e) {
      setRequestInProgress(false);
      console.error(e);
      addModule.onClose();
    }
  };
  const handleGetAddedModule = async () => {
    try {
      setRequestInProgress(true);
      const payload = {};
      const response = await requestGetAddedModule(payload);
      setAddedModule(response);
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    handleGetAddedModule();
  }, []);
  const handleDeleteModule = async () => {
    try {
      setRequestInProgress(true);
      const payload = { help_id: moduleToDelete._id };
      const response = await requestDeleteModule(payload);
      toast({
        title: response.msg,
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
      setRequestInProgress(false);
      handleGetAddedModule();
      deleteModule.onClose();
      setModuleToDelete("");
    } catch (e) {
      deleteModule.onClose();
      console.error(e);
      setRequestInProgress(false);
    }
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerFirst}>
        <p className={Styles.stats}>HELP |</p>
        <p className={Styles.stats}>
          Total Added Modules : {addedModule?.length}
        </p>
        <div className={Styles.header}>
          <Tooltip label="Create Help Module">
            <div className={Styles.filterWrapper}>
              <Button
                onClick={() => {
                  addModule.onOpen();
                  handleListModule();
                }}
                colorScheme="teal"
              >
                Create
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={Styles.body}>
        {requestInProgress ? (
          <Spinner />
        ) : (
          <div className={Styles.lists}>
            {addedModule && addedModule.length > 0
              ? addedModule.map((module) => (
                  <Link
                    to={module._id}
                    className={Styles.list}
                    key={module._id}
                  >
                    <div className={Styles.profile}>
                      {module.module_name === "PROFILE" ? (
                        <div className={Styles.profileIconsContainer}>
                          {Icons.Student && (
                            <img
                              src={Icons.Student}
                              alt="Student Icon"
                              className={Styles.profileStudentIcon}
                            />
                          )}
                          {Icons.Teacher && (
                            <img
                              src={Icons.Teacher}
                              alt="Teacher Icon"
                              className={Styles.profileTeacherIcon}
                            />
                          )}
                          {Icons.Admin && (
                            <img
                              src={Icons.Admin}
                              alt="Admin Icon"
                              className={Styles.profileAdminIcon}
                            />
                          )}
                          {Icons.Management && (
                            <img
                              src={Icons.Management}
                              alt="Management Icon"
                              className={Styles.profileManagementIcon}
                            />
                          )}
                        </div>
                      ) : (
                        <img
                          src={
                            moduleIcons[module.module_name]
                              ? moduleIcons[module.module_name]
                              : defaultUser
                          }
                          alt={module.module_name}
                          className={Styles.profileImg}
                        />
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                      }}
                    >
                      <div className={Styles.info}>
                        <p className={Styles.name}>{module.module_name}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Tooltip label="View Details" placement="top">
                          <div style={{ marginRight: "5px" }}>
                            <FaEye
                              onClick={(event) => {
                                event.preventDefault();
                                setSelectedModuleDetail(module.module_name);
                              }}
                              fontSize={25}
                              cursor="pointer"
                            />
                          </div>
                        </Tooltip>
                        {selectedModuleDetail === module.module_name && (
                          <div className={Styles.tooltipStyles}>
                            <div>
                              <IoIosClose
                                className={Styles.closeButton}
                                fontSize={30}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setSelectedModuleDetail(null);
                                }}
                              />
                            </div>
                            <div
                              style={{ marginTop: "14px", fontWeight: "bold" }}
                            >
                              <p>{module.module_name}</p>
                              <p>{module.user_role}</p>
                            </div>
                          </div>
                        )}
                        <Tooltip label="Delete Help Module">
                          <div>
                            <AiOutlineDelete
                              cursor="pointer"
                              fontSize={25}
                              onClick={(event) => {
                                event.preventDefault();
                                setModuleToDelete(module);
                                deleteModule.onOpen();
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </Link>
                ))
              : null}
          </div>
        )}
      </div>
      <Modal
        isOpen={addModule.isOpen}
        onClose={() => {
          addModule.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            Add Help Module
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className={Styles.modalContainer}>
              <div className={Styles.filterWrapper}>
                <p className={Styles.formLabel}>
                  Module <span className={Styles.asterisk}>*</span>
                </p>
                <Select
                  required
                  value={selectedModule}
                  onChange={handleModuleChange}
                >
                  <option>Select Module</option>{" "}
                  {moduleList.map((module) => (
                    <option key={module} value={module}>
                      {module}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
            <Button colorScheme="green" onClick={handleAddModule}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteModule.isOpen}
        onClose={() => {
          setModuleToDelete("");
          deleteModule.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ textAlign: "center" }}>
            Delete Help Module
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ color: "red", fontWeight: 600 }}>
            Are you sure to proceed with deletion?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={4}
              onClick={() => {
                setModuleToDelete("");
                deleteModule.onClose();
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteModule}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Help;
// import React, { useState } from "react";
// import Exam from "../../../assets/icons/Exams.png";
// import Backup from "../../../assets/icons/Backup.png";
// import Calendar from "../../../assets/icons/Academic Calendar.png";
// import Statistics from "../../../assets/icons/statistics.png";
// import Styles from "./Help.module.scss";
// import { Link } from "react-router-dom";

// const Help = () => {
//   return (
//     <div className={Styles.body}>
//       <div className={Styles.lists}>
//         <Link className={Styles.list} to={`examReportBackup`}>
//           <div className={Styles.profile}>
//             <img src={Exam} alt="Exam" className={Styles.profileImg} />
//           </div>
//           <div className={Styles.info}>
//             <p className={Styles.name}>Exam Report Backup</p>
//           </div>
//         </Link>
//         <Link className={Styles.list} to={`generalBackup`}>
//           <div className={Styles.profile}>
//             <img src={Backup} alt="Backup" className={Styles.profileImg} />
//           </div>
//           <div className={Styles.info}>
//             <p className={Styles.name}>Backup for General Panel</p>
//           </div>
//         </Link>
//         <Link className={Styles.list} to={`AcademicCalendarhelp`}>
//           <div className={Styles.profile}>
//             <img src={Calendar} alt="Calendar" className={Styles.profileImg} />
//           </div>
//           <div className={Styles.info}>
//             <p className={Styles.name}>Academic Calendar</p>
//           </div>
//         </Link>
//         <Link className={Styles.list} to={`Statisticshelp`}>
//           <div className={Styles.profile}>
//             <img
//               src={Statistics}
//               alt="Calendar"
//               className={Styles.profileImg}
//             />
//           </div>
//           <div className={Styles.info}>
//             <p className={Styles.name}>Statistics</p>
//           </div>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default Help;

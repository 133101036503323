import React from "react";
import smuLogo from "./smuSchoolLogo.png";
import smuBlueLogo from "./smuNewBLueLogo.png";
import Styles from "../Midterm.module.scss";

const Header = () => {
  return (
    <section className={Styles.header}>
      <img className={Styles.headerBlueLogo} src={smuBlueLogo} />
      <div>
        <h1>ST MARY'S ENGLISH MEDIUM SCHOOL</h1>
        <p>
          (Affiliated to CBSE, New Delhi No. 830490, School No. 45410) <br />
          <h4> Kannarpady, Udupi-576103, Karnataka, India</h4>
        </p>

        <p>Email: office@stmarysudupi.org</p>
        <p> Website: www.stmarysudupi.org</p>
        <p>Contact no: 9481509322, 0820-2524455</p>
      </div>
      <img className={Styles.headerLogo} src={smuLogo} />
    </section>
  );
};
export default Header;

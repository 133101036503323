import { useClassAndSectionsWithoutAll } from "hooks/classAndSection";
import React, { useState, useEffect } from "react";
import Exam from "assets/icons/Exams.png";
import Styles from "./ReportCentre.module.scss";
import { Button, Checkbox, Select, useToast } from "@chakra-ui/react";
import { getRequestOptions } from "services/utils";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const ExamReport = () => {
  const { classAndSections } = useClassAndSectionsWithoutAll();
  const [inputFields, setInputFields] = useState({});
  const [sections, setSections] = useState([]);
  const [data, setData] = useState([]);
  const [selectedFileFormat, setSelectedFileFormat] = useState("xlsx");
  const toast = useToast();
  const initialFieldsState = {
    midTerm: false,
    annual: false,
    name: true,
    class: true,
    section: true,
  };
  const [fields, setFields] = useState(initialFieldsState);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  useEffect(() => {
    let requiredSections = classAndSections.length
      ? classAndSections
          .filter((item) => item.class == inputFields.class)
          .map((item) => item.sections)
      : [["A"]];
    setSections(requiredSections[0]);
  }, [inputFields.class, classAndSections]);
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "class" || name === "section") {
      if (value === "ALL") {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
          section: "ALL",
        }));
      } else {
        setInputFields((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setInputFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const fileName =
    inputFields.class && inputFields.section
      ? `SMU-${fields.midTerm ? "MidTerm" : "Annual"}-Marks-Report-${
          inputFields.class
        }-${inputFields.section}`
      : "SMU-Marks-Report";

  const getExamResults = async () => {
    const selectedTerm = fields.midTerm ? "mid_term" : "anual";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/management/exam/getMarks`,
        {
          class: inputFields.class,
          section: inputFields.section,
          exam: selectedTerm,
        },
        getRequestOptions()
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const ExportToExcel = () => {
    return (
      <Button colorScheme="green" mt={1} onClick={handleExportClick}>
        Download
      </Button>
    );
  };
  const handleExportClick = async () => {
    if (!inputFields.class || !inputFields.section) {
      toast({
        title: "Please Select Grade and Section.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    if (!fields.midTerm && !fields.annual) {
      toast({
        title: "Please Select Exam Semester.",
        variant: "left-accent",
        position: "bottom-left",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    try {
      const res = await getExamResults();
      if (res) {
        let newArray = [];
        let electiveSubjects = [];
        res.forEach((item) => {
          let obj = {};
          if (fields.class) {
            obj["Class"] = item.class;
          }
          if (fields.section) {
            obj["Section"] = item.section;
          }
          if (fields.name) {
            obj["Name"] = item.name;
          }
          const examData = fields.midTerm ? item.mid_term : item.anual;
          examData
            .filter((subject) => !subject.optional_sub)
            .forEach((subject, index) => {
              obj[`Regular Subject ${index + 1} Name`] = subject.subject_name;
              obj[`Regular Subject ${index + 1} Code`] = subject.subject_code;
              obj[`Regular Subject ${index + 1} Internal`] = subject.internal;
              obj[`Regular Subject ${index + 1} External`] = subject.external;
              obj[`Regular Subject ${index + 1} Total`] = subject.total;
              obj[`Regular Subject ${index + 1} Grade`] = subject.grade;
            });
          const studentOptionalSubjects = res.flatMap((student) =>
            (fields.midTerm ? student.mid_term : student.anual)
              .filter((subject) => subject.optional_sub)
              .map((subject) => ({ name: student.name, ...subject }))
          );
          electiveSubjects = studentOptionalSubjects.reduce((acc, subject) => {
            if (subject.subject_name in acc) {
              acc[subject.subject_name].push(subject);
            } else {
              acc[subject.subject_name] = [subject];
            }
            return acc;
          }, {});
          newArray.push(obj);
        });
        setData(newArray);
        if (selectedFileFormat === "xlsx") {
          exportToXLSX(newArray, electiveSubjects, fileName);
        } else if (selectedFileFormat === "csv") {
          exportToCSV(newArray, fileName);
        }
      }
      toast({
        title: "Downloaded Successfully.",
        variant: "left-accent",
        position: "bottom-right",
        status: "success",
        duration: 2500,
        isClosable: true,
      });
      setFields(initialFieldsState);
      setSelectedFileFormat("xlsx");
    } catch (error) {
      console.error(error);
    }
  };
  const exportToXLSX = (apiData, electiveSubjects, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = XLSX.utils.book_new();
    const indexData = apiData.map((studentData) => {
      const indexRow = {
        Name: studentData.Name,
        Class: studentData.Class,
        Section: studentData.Section,
      };
      Object.entries(studentData)
        .filter(([key]) => key.startsWith("Regular") && key.includes("Name"))
        .forEach(([key, value], idx) => {
          indexRow[`Subject ${idx + 1} Name`] = value;
        });
      Object.keys(electiveSubjects).forEach((electiveName, idx) => {
        const electiveStudents = electiveSubjects[electiveName];
        const hasStudent = electiveStudents.some(
          (student) => student.name === studentData.Name
        );
        if (hasStudent) {
          const elective = electiveStudents[0];
          indexRow[`Elective ${elective.elective_name}`] =
            elective.subject_name;
        }
      });
      return indexRow;
    });
    const indexWs = XLSX.utils.json_to_sheet(indexData);
    XLSX.utils.book_append_sheet(wb, indexWs, "Index");
    const subjects = Object.keys(apiData[0] || {}).filter(
      (key) => key.startsWith("Regular") && key.includes("Name")
    );
    subjects.forEach((subject, index) => {
      const subjectName = apiData[0][subject];
      const subjectData = apiData.map((studentData) => {
        const data = {
          Name: studentData.Name,
          [`${subjectName} CODE`]:
            studentData[`${subject.replace("Name", "Code")}`], // Modified this line
          Internal: studentData[`Regular Subject ${index + 1} Internal`],
          External: studentData[`Regular Subject ${index + 1} External`],
          Total: studentData[`Regular Subject ${index + 1} Total`],
          Grade: studentData[`Regular Subject ${index + 1} Grade`],
        };
        return data;
      });
      const ws = XLSX.utils.json_to_sheet(subjectData);
      XLSX.utils.book_append_sheet(wb, ws, subjectName);
    });
    for (const electiveName in electiveSubjects) {
      const electiveData = electiveSubjects[electiveName].map((elective) => {
        return {
          Name: elective.name,
          "Elective Name": elective.elective_name,
          "Subject Name": elective.subject_name,
          "Subject Code": elective.subject_code,
          Internal: elective.internal,
          External: elective.external,
          Total: elective.total,
          Grade: elective.grade,
        };
      });
      const ws = XLSX.utils.json_to_sheet(electiveData);
      XLSX.utils.book_append_sheet(wb, ws, electiveName);
    }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToCSV = (apiData, fileName) => {
    const csv = Papa.unparse(apiData, {
      quotes: true,
      header: true,
      delimiter: ",",
    });
    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const data = new Blob([csv], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className={Styles.mainContainer}>
      {screenSize && screenSize?.width >= 500 ? (
        <div className={Styles.subContainer}>
          <div className={Styles.leftContainer}>
            <div className={Styles.moduleContainer}>
              <img src={Exam} alt="Exam Icon" className={Styles.moduleIcon} />
              <p className={Styles.moduleName}>EXAM REPORT MODULE</p>
            </div>
            <div className={Styles.filterContainer}>
              <p className={Styles.criteriaText}>Filter Criteria</p>
              <div className={Styles.filterSubContainer}>
                <p className={Styles.filterGrade}>
                  Grade <span className={Styles.asterisk}>*</span>
                </p>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.filterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
            <div className={Styles.formatContainer}>
              <p className={Styles.criteriaText}>
                Archive File Format <span className={Styles.asterisk}>*</span>
              </p>
              <div className={Styles.fileFormatContainer}>
                <Checkbox
                  isChecked={selectedFileFormat === "xlsx"}
                  onChange={() => {
                    setSelectedFileFormat("xlsx");
                  }}
                >
                  XLSX File
                </Checkbox>
                <Checkbox
                  ml={4}
                  isChecked={selectedFileFormat === "csv"}
                  onChange={() => {
                    setSelectedFileFormat("csv");
                  }}
                >
                  CSV File
                </Checkbox>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ExportToExcel apiData={data} fileName={fileName} />
              </div>
            </div>
          </div>
          <div className={Styles.selectionContainer}>
            <form>
              <p className={Styles.criteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.heading}>
                  <button type="button" className={Styles.btn}>
                    Exam Semester
                  </button>
                </div>
              </div>
              <div className={Styles.row}>
                <Checkbox
                  isChecked={fields.midTerm}
                  onChange={() => {
                    setFields({
                      ...fields,
                      midTerm: !fields.midTerm,
                      annual: false,
                    });
                  }}
                >
                  Mid-Term
                </Checkbox>
                <Checkbox
                  isChecked={fields.annual}
                  onChange={() => {
                    setFields({
                      ...fields,
                      annual: !fields.annual,
                      midTerm: false,
                    });
                  }}
                >
                  Annual
                </Checkbox>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.moduleContainer}>
            <img
              src={Exam}
              alt="Exam Icon"
              className={Styles.mobilemoduleIcon}
            />
            <p className={Styles.mobilemoduleName}>EXAM REPORT MODULE</p>
          </div>
          <div className={Styles.filterContainer}>
            <p className={Styles.mobilecriteriaText}>Filter Criteria</p>
            <div>
              <p className={Styles.mobilefilterGrade}>
                Grade <span className={Styles.asterisk}>*</span>
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.class ? inputFields.class : ""}
                    onChange={handleInputChange}
                    name="class"
                    placeholder="Select Grade"
                    className={Styles.dropdownContainer}
                  >
                    {classAndSections.map((grade, idx) => (
                      <option key={grade.class + idx} value={grade.class}>
                        Grade {grade.class}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className={Styles.mobilefilterWrapper}>
                  <Select
                    value={inputFields.section ? inputFields.section : ""}
                    onChange={handleInputChange}
                    name="section"
                    placeholder="Select Section"
                    className={Styles.dropdownContainer}
                  >
                    {sections
                      ? sections.map((section, idx) => (
                          <option key={section + idx} value={section}>
                            Section {section}
                          </option>
                        ))
                      : ""}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.mobileselectionContainer}>
            <form>
              <p className={Styles.mobilecriteriaText}>Selection Criteria</p>
              <div className={Styles.headerContainer}>
                <div className={Styles.mobileheading}>
                  <button type="button" className={Styles.mobilebtn}>
                    Exam Semester
                  </button>
                </div>
              </div>
              <div className={Styles.mobilerow}>
                <Checkbox
                  isChecked={fields.midTerm}
                  onChange={() => {
                    setFields({
                      ...fields,
                      midTerm: !fields.midTerm,
                      annual: false,
                    });
                  }}
                >
                  Mid-Term
                </Checkbox>
                <Checkbox
                  isChecked={fields.annual}
                  onChange={() => {
                    setFields({
                      ...fields,
                      annual: !fields.annual,
                      midTerm: false,
                    });
                  }}
                >
                  Annual
                </Checkbox>
              </div>
            </form>
          </div>
          <div className={Styles.mobileformatContainer}>
            <p className={Styles.mobilecriteriaText}>
              Archive File Format <span className={Styles.asterisk}>*</span>
            </p>
            <div className={Styles.mobilefileFormatContainer}>
              <Checkbox
                isChecked={selectedFileFormat === "xlsx"}
                onChange={() => {
                  setSelectedFileFormat("xlsx");
                }}
              >
                XLSX File
              </Checkbox>
              <Checkbox
                ml={4}
                isChecked={selectedFileFormat === "csv"}
                onChange={() => {
                  setSelectedFileFormat("csv");
                }}
              >
                CSV File
              </Checkbox>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportToExcel apiData={data} fileName={fileName} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamReport;

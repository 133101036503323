import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner, Button } from "@chakra-ui/react";
import {
  requestAdminDetailsById,
  requestDeleteAdminById,
  requestUpdateAdminDetailsById,
} from "services/admin-dashboard/admin-management.service";
import {
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
} from "@chakra-ui/react";
import Styles from "./SingleAdminPage.module.scss";

const SingleAdminPage = () => {
  const [isAlertOpen, setAlertOpen] = useState();
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const [operationType, setOperationType] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputFields, setInputFields] = useState(null);

  React.useEffect(() => {
    setRequestInProgress(true);
    Promise.all([fetchAdminProfileDetails()])
      .then((resp) => {
        setRequestInProgress(false);
      })
      .catch((err) => {
        setRequestInProgress(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOperationType("ADD");
    setRequestInProgress(true);
    updateAdminHandler();
  };

  const handleDelete = async () => {
    setOperationType("DELETE");
    setRequestInProgress(true);
    try {
      const response = await requestDeleteAdminById({
        admin_id: params.id,
      });
      if (response) {
        setOperationType("");
        setRequestInProgress(false);
        toast({
          title: "Deleted Succesfully",
          description: "Deleted Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/admin/dashboard/admin-management");
        onAlertClose();
      }
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
      toast({
        title: "Delete request failed",
        description: "Delete Failed",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      onAlertClose();
    }
  };

  const updateAdminHandler = async () => {
    try {
      const { email, name } = inputFields;

      const payload = {
        email,
        name,
      };
      console.log("request payload", payload);
      const response = await requestUpdateAdminDetailsById({
        admin_id: params.id,
        ...payload,
      });
      if (response) {
        navigate("/admin/dashboard/admin-management");
        toast({
          title: "Submitted Succesfully",
          description: "Updated Details",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
      setRequestInProgress(false);
    } catch (error) {
      setRequestInProgress(false);
      toast({
        title: "Request Failed",
        description: "Credentials already exist",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });

      console.error(error);
    }
  };

  const fetchAdminProfileDetails = async () => {
    try {
      const response = await requestAdminDetailsById({ admin_id: params.id });
      if (response.error) {
        console.error(response.error);
      }
      if (response) {
        console.log("admin resp", response);
        const _response = {
          ...response,
          name: response.name,
          email: response.email,
        };
        setInputFields(_response);
        return _response;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setInputFields((prev) => ({ ...prev, [name]: value }));
  };

  if (requestInProgress || (requestInProgress && operationType === "")) {
    return (
      <div className={Styles.loaderWrapper}>
        <Spinner />
      </div>
    );
  }

  if (!inputFields) {
    return null;
  }

  return (
    <div className={Styles.body}>
      <form onSubmit={handleSubmit}>
        <div className={Styles.forms}>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Name</p>
              <input
                required
                onChange={(e) => handleInputChange(e, "")}
                value={inputFields?.name}
                name="name"
                type="text"
                placeholder="Enter student name"
                className={Styles.textInput}
              />
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.form}>
              <p className={Styles.placeholder}>Email</p>
              <input
                onChange={(e) => handleInputChange(e, "")}
                required
                name="email"
                value={inputFields.email}
                type="text"
                placeholder="value"
                className={Styles.textInput}
              />
            </div>
          </div>
        </div>
        <div className={Styles.buttonRow}>
          <button type="submit" className={Styles.button}>
            {requestInProgress && operationType === "ADD" ? (
              <Spinner />
            ) : (
              "Submit"
            )}
          </button>
          <button
            type="button"
            onClick={() => setAlertOpen(true)}
            className={Styles.button}
          >
            {requestInProgress && operationType === "DELETE" ? (
              <Spinner />
            ) : (
              "Delete"
            )}
          </button>
          <AlertDialog
            isOpen={isAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onAlertClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Admin
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onAlertClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      </form>
    </div>
  );
};

export default SingleAdminPage;

import React, { useState } from "react";
import Styles from "./ForgetPassword.module.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";

// import { createUserWithEmailAndPassword } from "firebase/auth";
// import { auth } from '../../config/firebaseInitialize';
import axios from "axios";
import { useAuth } from "contexts/auth-context";
import {
  getAuth,
  sendPasswordResetEmail
} from "firebase/auth";

const ForgetPassword = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const [error, setError] = useState({
    state: false,
    message: "",
  });
  const url = "https://smudev.smuapp.org/dev/api";

  const submitHandler = (e) => {
    e.preventDefault();

    if (userName.length === 0) {
      setError({
        state: true,
        message: "Please enter your email",
      });
      return;
    } 
    signIn(userName );
  };

  const signIn = async (email) => {
    //authenticating user with firebase
    try {
      const Auth = getAuth();
      sendPasswordResetEmail(Auth, email)
      .then(() => {
        toast({
          title: "We've sent an email on your account",
          // description: "Email Updated",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        toast({
          title: error.message,
          // description: "Update Request failed",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        // ..
      });
      // console.log("userCredential", user);

      //if user exist in firebase calling api
    } catch (error) {
      console.log("Failed");
      toast({
        title: "having a some while sending a email",
        // description: "User doesn't exist.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.wrapper}>
        <div className={Styles.cover}></div>
        <div className={Styles.inputDiv}>
          <form>
            <p className={Styles.heading}>Forgot password </p>
            <div className={Styles.inputContainer}>
              <Stack spacing={3} mb="3" mt="3" className={Styles.inputDivContainer}>
                <InputGroup className={Styles.InputDiv}>
                  <Input
                    pr="4.5rem"
                    value={userName}
                    type="text"
                    placeholder="Enter email address..."
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </InputGroup>
              </Stack>
              <Button
                onClick={submitHandler}
                size="lg"
                width="40%"
                _hover={{ background: "#990f0d", color: "#fff" }}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

import React, { useState } from "react";
import Header from "../CertificatePdfs/BonafideHeader/Header";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import Styles from "./DownloadManual.module.scss";
import jsPDF from "jspdf";
import { Button,useToast } from "@chakra-ui/react";
import { ToWords } from 'to-words';
const initailvalue = {
  name: "",
  Fathername: "",
  Mothername: "",
  Grade: "",
  dob: "",
  gender: "",
  address: "",
};
const ManualStudyDownload = () => {
  const [data, setData] = useState([]);
  const [edit , setEdit] = useState(false)
  const toast = useToast()
  const loaction = useLocation();
  useEffect(() => {
    setData(loaction.state.inputFields);
  }, []);
  // console.log("data",data)
  const date = new Date();
  const dates = moment(date).format("DD/MM/YYYY");
  const handleCertiDownload = async () => {
    let view = new jsPDF("portrait", "pt", "a4");
    let pdf = await document.getElementById("pdfcerti");
    view
      .html(pdf)
      .then(() => {
        view.save(`${data.name} conduct.pdf`);
        // PdfRequestUpdate();
      })
      .catch((err) => console.log(err, "err"));
   
  };
  const toWords = new ToWords();
  if(data?.dob){
     const convertedYear = moment(data?.dob, "MM/DD/YYYY").format("YYYY");
    var wordsYear = toWords.convert(convertedYear);
  }
  if(data?.dob){
    const convertedDay = moment(data?.dob&&data?.dob, "MM/DD/YYYY").format("DD");
    var wordsDay = toWords.convert(convertedDay);
  }
  if(data?.dob){
    const d = moment(data?.dob&&data?.dob, "MM/DD/YYYY").format("MMMM")
    var monthName = d
  }
  // const dates = new Date();
let currentDate = moment(date,"MM/DD/YYYY").format("DD/MM/YYYY")

const upadtedData = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
  // console.log("transferInfo transferInfo", transferInfo);
};
const onEditMode =()=>{
  setEdit(true)
  toast({
    title: "Edit",
    description: "Now you can edit Certificate",
    status: "success",
    duration: 4000,
    isClosable: true,
    position: "top-right",
  });
}
const onSaveChanges =()=>{
  setEdit(false)
  toast({
    title: "Save",
    description: "Certificate edit saved ",
    status: "success",
    duration: 4000,
    isClosable: true,
    position: "top-right",
  });
}
  return (
    <>
    <div className={Styles.mainContainer}>
      <div className={Styles.conductCerticateWrapper} id="pdfcerti">
        <div>
          <Header />
        </div>
        {/* <p style={{ textAlign: "right" ,fontSize: ".6rem",}}>{currentDate}</p> */}
        <div>
          <div className={Styles.HeadingContainer}>
            <p className={Styles.ConductcertiHeading}>STUDY CERTIFICATE</p>
          </div>
          <div className={Styles.conductData} >
          <p>
                  This is to certify that&nbsp;
                {edit == true ? <input className={Styles.inputFieldTag} name="name" value={data.name} onChange={(e) => upadtedData(e)}/>:
                <span style={{ fontWeight: "bold" }}>
                  {data?.name}
                </span>}
                  &nbsp;{data?.gender == "male"||"Male"||"MALE"
                          ? "S/o" 
                          : "D/o"}&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    MR {edit == true ?<input className={Styles.inputFieldTag} name="FatherName" onChange={(e) => upadtedData(e)} value={data?.FatherName}/>:<span>{data?.FatherName} </span>}and MRS&nbsp;
                    {edit == true ?<input className={Styles.inputFieldTag} onChange={(e) => upadtedData(e)} name="Mothername" value={data?.Mothername}/>:<span>{data?.Mothername} </span>}
                  </span>
                  &nbsp; is a bonafide student of our school presently studying
                  in grade&nbsp;
                  {edit == true ?<input className={Styles.inputFieldTag} name="Grade" value={data?.Grade} onChange={(e) => upadtedData(e)}/>: <span style={{ fontWeight: "bold" }}> 
                    {data?.Grade}
                  </span>}                 
                  . As per our records {data?.gender == "male"||"Male"||"MALE"
                          ? "his"
                          : "her"} date of birth is &nbsp;
                          {edit == true ?<input className={Styles.inputFieldTag} name="dob" value={data?.dob} onChange={(e) => upadtedData(e)}/>: <span style={{ fontWeight: "bold" }}>
                   {data.dob}
                  </span>} 
                  . 
                </p>
          </div>
          {/* <div className={Styles.dynaicData}>
          <p style={{marginTop:"1vw"}}>
            {data.gender == "male"||"Male"||"MALE" ? "His" : "Her"} conduct and character were
            good
          </p>
          </div> */}
          {/* <div style={{marginTop:"1vw"}}>
          <p> <span className={Styles.dynaicData}>Date</span>: {dates}</p>
          </div> */}
          <div
              style={{
                // textAlign: "right",
                marginTop: "1.5vw",
                fontSize: ".8rem",
                fontFamily: "sans",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Rev. Fr Vijay J. D'Souza</p>
              <p>Principal</p>
            </div>
        </div>
      </div>
      <div>
      <Button className={Styles.downloadButoon} onClick={handleCertiDownload}>Download</Button>
      {edit == true ? (
            <Button
              className={Styles.downloadButoon}
              onClick={onSaveChanges}
            >
              {" "}
              Save{" "}
            </Button>
          ) : (
            <Button className={Styles.downloadButoon} onClick={onEditMode}>
              Edit
            </Button>
          )}
          </div>
      </div>
    </>
  );
};

export default ManualStudyDownload;

import axios from 'axios'
import { getRequestOptions, BASE_URL } from 'services/utils'

export async function requestAlladmin (payload = {}) {
    const url = BASE_URL + '/admin/admin/getAll'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
    }
  }

  export async function requestAdminDetailsById(payload = {}) {
    const { admin_id } = payload
    if (!admin_id && admin_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL+'/admin/admin/getOne'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function requestDeleteAdminById(payload = {}) {
    const { admin_id } = payload
    if (!admin_id && admin_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL + '/admin/admin/delete'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function requestUpdateAdminDetailsById(payload = {}) {
    const { admin_id, ...rest } = payload
    if (!admin_id && admin_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL + '/admin/admin/update/' + admin_id
  
    const requestBody = rest;
  
    try {
      const response = await axios.post(url, { ...requestBody }, getRequestOptions())
      console.log('response', response)
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function requestAllmanagement (payload = {}) {
    const url = BASE_URL + '/admin/management/getAll'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
    }
  }

  export async function requestManagementDetailsById(payload = {}) {
    const { management_id } = payload
    if (!management_id && management_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL+'/admin/management/getOne'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  export async function requestDeleteManagementById(payload = {}) {
    const { management_id } = payload
    if (!management_id && management_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL + '/admin/management/delete'
  
    try {
      const response = await axios.post(url, { ...payload }, getRequestOptions())
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }

  
  export async function requestUpdateManagementDetailsById(payload = {}) {
    const { management_id, ...rest } = payload
    if (!management_id && management_id === '') {
      return { error: 'Field missing' }
    }
  
    const url = BASE_URL + '/admin/management/update/' + management_id
  
    const requestBody = rest;
  
    try {
      const response = await axios.post(url, { ...requestBody }, getRequestOptions())
      console.log('response', response)
      if (response.data?.error) {
        return { error: response.data.error }
      }
      if (response.status >= 200 && response.status < 400 && response.data) {
        return response.data
      }
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      if (e?.response?.data) {
        throw e.response.data
      }
      throw e
    }
  }
  

const Services = {
 requestAlladmin,
 requestAdminDetailsById,
 requestDeleteAdminById,
 requestUpdateAdminDetailsById,
 requestAllmanagement,
 requestDeleteManagementById,
 requestUpdateManagementDetailsById,
 requestManagementDetailsById
}

export default Services

import { Spinner, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Styles from "./Help.module.scss";
import {
  requestGetOneModule,
  requestOneStepData,
} from "services/staff-dashboard/help.service";
import { useParams } from "react-router-dom";
import NothingFound from "assets/icons/NothingFound.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";

const SingleTeacherHelp = () => {
  const params = useParams();
  const toast = useToast();
  const [moduleData, setModuleData] = useState([]);
  const [moduleName, setModuleName] = useState([]);
  const [hoveredStepIndex, setHoveredStepIndex] = useState(null);
  const [clickedStepIndex, setClickedStepIndex] = useState(null);
  const [stepData, setStepData] = useState({});
  const [requestInProgress, setRequestInProgress] = useState(false);
  const handleModuleData = async () => {
    try {
      setRequestInProgress(true);
      const payload = { help_id: params.id };
      const response = await requestGetOneModule(payload);
      setModuleData(response.data);
      setModuleName(response.details);
      setRequestInProgress(false);
    } catch (e) {
      console.error(e);
      setRequestInProgress(false);
    }
  };
  useEffect(() => {
    handleModuleData();
  }, []);
  const handleStepData = async (stepId, index) => {
    try {
      const payload = { step_id: stepId };
      const response = await requestOneStepData(payload);
      setStepData(response.data);
      setClickedStepIndex(index);
    } catch (e) {
      console.error(e);
      toast({
        title: e,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.wrap}>
        {requestInProgress ? (
          <Spinner />
        ) : (
          <>
            {moduleData && moduleData.length == 0 ? (
              <div className={Styles.noModuleData}>
                <p>Step Not Added</p>
              </div>
            ) : (
              <>
                <div className={Styles.forWidthLeft}>
                  <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                    {moduleName.module_name} | Queries
                  </p>
                  {moduleData.map((step, index) => (
                    <div
                      className={`${Styles.stepHead} ${
                        index === clickedStepIndex || index === hoveredStepIndex
                          ? Styles.hoveredStep
                          : ""
                      }`}
                      onMouseEnter={() => setHoveredStepIndex(index)}
                      onMouseLeave={() => {
                        setHoveredStepIndex(null);
                      }}
                      onClick={() => {
                        if (clickedStepIndex !== index) {
                          handleStepData(step._id, index);
                        }
                      }}
                      key={index}
                    >
                      <p className={Styles.stepNo}>{index + 1}</p>
                      <div className={Styles.stepTitle}>{step.step_name}</div>
                    </div>
                  ))}
                </div>
                <div className={Styles.forWidthRight}>
                  {clickedStepIndex !== null ? (
                    stepData?.length > 0 ? (
                      stepData.map((section, sectionIndex) => (
                        <div
                          key={sectionIndex}
                          className={Styles.sectionContainer}
                        >
                          {section.contents.map((content, contentIndex) => (
                            <div
                              key={contentIndex}
                              className={
                                content.is_media
                                  ? Styles.mediaContainer
                                  : Styles.valueContainer
                              }
                            >
                              <div>
                                {content.is_media ? (
                                  <div
                                    className={Styles.carouselmediaContainer}
                                  >
                                    <Carousel
                                      showThumbs={false}
                                      swipeable={true}
                                      emulateTouch={true}
                                      renderArrowPrev={(
                                        onClickHandler,
                                        hasPrev,
                                        label
                                      ) =>
                                        hasPrev && (
                                          <button
                                            type="button"
                                            onClick={onClickHandler}
                                            title={label}
                                            style={{
                                              position: "absolute",
                                              zIndex: 2,
                                              top: "calc(50% - 15px)",
                                              width: 20,
                                              height: 20,
                                              cursor: "pointer",
                                              left: 5,
                                              fontSize: "30px",
                                              color: "#990f0d",
                                            }}
                                            className={Styles.leftCircle}
                                          >
                                            <AiFillLeftCircle />
                                          </button>
                                        )
                                      }
                                      renderArrowNext={(
                                        onClickHandler,
                                        hasNext,
                                        label
                                      ) =>
                                        hasNext && (
                                          <button
                                            type="button"
                                            onClick={onClickHandler}
                                            title={label}
                                            style={{
                                              position: "absolute",
                                              zIndex: 2,
                                              top: "calc(50% - 15px)",
                                              width: 20,
                                              height: 20,
                                              cursor: "pointer",
                                              right: 10,
                                              fontSize: "30px",
                                              color: "#990f0d",
                                            }}
                                            className={Styles.rightCircle}
                                          >
                                            <AiFillRightCircle />
                                          </button>
                                        )
                                      }
                                    >
                                      {content.media.map((mediaUrl, index) => (
                                        <div key={index}>
                                          <img
                                            src={mediaUrl}
                                            alt={`Media ${index + 1}`}
                                            className={Styles.onemedia}
                                            style={{
                                              width: "800px",
                                              height: "250px",
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </Carousel>
                                  </div>
                                ) : (
                                  <div
                                    className={Styles.stepContent}
                                    dangerouslySetInnerHTML={{
                                      __html: content.value,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                    ) : (
                      <p className={Styles.noModuleData}>Step Data Not Added</p>
                    )
                  ) : (
                    <div className={Styles.nothingFoundContainer}>
                      <img
                        src={NothingFound}
                        alt="Nothing Found"
                        className={Styles.nothingFoundImage}
                      />
                      <p className={Styles.nothingFoundText}>
                        Please Select One Step
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleTeacherHelp;

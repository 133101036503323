import { Input, Select } from "@chakra-ui/react";
import Styles from "./IssueBook.module.scss"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import Services from "services/admin-dashboard/library.service";

const IssueBook = () => {
    const [categories, setCategories] = useState()
    const [selectCategory, setSelectCategory] = useState()
    const [books,setAllBooks] = useState()
     const getCategories = async () => {
       try {
         const response = await Services.requestAllCategory();
         if (response) {
           setCategories(response);
         }
       } catch (err) {
         console.log("error", err);
       }
     };
     useEffect(() => {
       getCategories();
     }, []);
    
    const categoryChange = (e) => {
        setSelectCategory(e.target.value)
    }
     const requestBookByCategory = async () => {
       try {
         const payload = {
           category: selectCategory,
         };
         const res = await Services.requestBookByCategory(payload);
         if (res) {
           setAllBooks(res);
         }
       } catch (err) {
         console.log(err);
       }
     };
    useEffect(() => {
        if (selectCategory) {
             
            requestBookByCategory();
        }
     }, [selectCategory]);
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.tagContanier}>
        <p>
          Categories<span className={Styles.asterisk}>*</span> :
        </p>
        <Select
          placeholder="Select Book Categories"
          type="text"
          name="book_category_id_array"
          onChange={(e) => categoryChange(e)}
        >
          {categories &&
            categories.map((data) => (
              <option value={data.category_name}>{data.category_name}</option>
            ))}
        </Select>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Book<span className={Styles.asterisk}>*</span> :
        </p>
        <Select placeholder="Select Book " type="text">
          {books &&
            books.map((data) => (
              <option value={data._id}>{data.book_title}</option>
            ))}
        </Select>
      </div>
      <div className={Styles.tagContanier}>
        <p>
          Student<span className={Styles.asterisk}>*</span> :
        </p>
        <Input />
      </div>
      <div className={Styles.dateTagContanier}>
        <p>
          Date of submission<span className={Styles.asterisk}>*</span> :
        </p>
        <DatePicker
          required
          name="dob"
          dateFormat="dd/MM/yyyy"
          placeholderText="DD/MM/YYYY"
          className={Styles.datePicker}
          //   onChange={(e) => handleInputChange(e, ["personel", "dob"])}
          //   value={inputFields.personel.dob}
          //   selected={inputFields.personel.dob}
        />
      </div>
    </div>
  );
}

export default IssueBook
